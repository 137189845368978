import {concat, findKey, flattenDeep, has, set, uniq} from 'lodash';

import rules from '../config/rules';

function findMapperFromRules(capability) {
  return findKey(rules, {
      'mapper': capability
    }
  );
}

function mergeContexts(contextList1, contextList2) {
  return (uniq(flattenDeep(concat(contextList1, contextList2))))
}

export function getCapabilityContextAccessList(userWithRoleAssignments) {
  let response = {};

  if (!userWithRoleAssignments) {
    return response;
  }

  if (!has(userWithRoleAssignments, 'roleAssignments')) {
    return response;
  }

  let userAccess = userWithRoleAssignments['roleAssignments'];

  userAccess.forEach((ua) => {
    if (has(ua, 'contextIds') && has(ua, 'capabilityNames')) {
      let contextList = ua['contextIds'];
      let capabilityNameList = ua['capabilityNames'];
      capabilityNameList.forEach((name) => {
        let key = findMapperFromRules(name);
        let obj = {
          'capability': name,
          'contexts': has(response, key) ? mergeContexts(contextList, response[key]['contexts']) : contextList,
        };
        set(response, key, obj);
      });
    }
  });

  return response;
}
