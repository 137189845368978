import React from 'react';
import Typography from '@mui/material/Typography';

const EndedSessionText = () => {
  return (
    <div id="finished-text">
      <Typography variant="h3" component="p">Your exam session and recording have now finished.</Typography>
      <Typography variant="body1">You can close this tab at any time.</Typography>
      <Typography variant="body1">If you have any questions or concerns, let us know and we'll get back to you as soon as possible.</Typography>
    </div>
  );
};

export default EndedSessionText;