import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import {
  AccountTree as ContextIcon,
  AssignmentTurnedIn as RoleIcon,
  Computer as ExamSessionIcon,
  Event as EventIcon,
  Home as HomeIcon,
  People as UserIcon,
  List as SupervisorSessionsIcon,
  NotificationsNone as NoticesIcon,
  VideoLibrary as VideoLibraryIcon,
  WarningRounded as WarningIcon,
  SupervisedUserCircleOutlined as AllocationIcon
} from '@mui/icons-material';

import { AuthConsumer } from '../../authContext';
import Can from '../Can';
import Routing from '../../utils/Routing';
import NavigationMenuItem from './NavigationMenuItem';
import { CAPABILITIES } from '../../constants/capabilities';

const styles = {
  nav: {
    backgroundColor: 'secondary.main',
    minHeight: 'calc(100vh - 64px)',
  },
  divider: {
    backgroundColor: 'secondary.contrastText',
  },
};

/**
 * The navigation menu is a list of links to various kick-off pages in eVigilation.
 *
 * It can be expanded or mimised through a parent prop, but will always expand
 * when hovered.
 */
class NavigationMenu extends React.Component {

  state = {
    hovered: false,
  };

  expandFromHoverIfNotExpanded = () => {
    if (!this.props.expanded) {
      this.setState({hovered: true});
    }
  };

  shrinkFromHoverIfNotExpanded = () => {
    if (!this.props.expanded) {
      this.setState({hovered: false});
    }
  };

  render() {
    const { expanded } = this.props;

    const isExpanded = expanded || this.state.hovered;

    const homeNav = (
      <NavigationMenuItem
        label="Home"
        ariaLabel="Go to the home page"
        isExpanded={isExpanded}
        listIcon={<HomeIcon />}
      />
    );

    const manageUserNav = (
      <NavigationMenuItem
        label="Users"
        ariaLabel="User accounts"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_USERS}
        listIcon={<UserIcon />}
      />
    );

    const manageRoleNav = (
      <NavigationMenuItem
        label="Roles"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_ROLES}
        listIcon={<RoleIcon />}
      />
    );

    const manageContextNav = (
      <NavigationMenuItem
        label="Contexts"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_CONTEXTS}
        listIcon={<ContextIcon />}
      />
    );

    const manageExamNav = (
      <NavigationMenuItem
        label="Exam sessions"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_EXAM_SESSIONS}
        listIcon={<ExamSessionIcon />}
      />
    );

    const manageSupervisorSessionsNav = (
      <NavigationMenuItem
        label="Supervisor sessions"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_SUPERVISOR_SESSIONS}
        listIcon={<SupervisorSessionsIcon />}
      />
    );

    const manageRosterNav = (
      <NavigationMenuItem
        label="Staff roster"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_ROSTER}
        listIcon={<EventIcon />}
      />
    );

    const unresolvedNoticeReportNav = (
      <NavigationMenuItem
        label="Unresolved notice report"
        isExpanded={isExpanded}
        routing={Routing.UNRESOLVED_NOTICE_REPORT}
        listIcon={<NoticesIcon />}
      />
    );

    const manageRecordingNav = (
      <NavigationMenuItem
        label="Exam recordings"
        ariaLabel="Recordings"
        isExpanded={isExpanded}
        routing={Routing.MANAGE_RECORDING}
        listIcon={<VideoLibraryIcon />}
      />
    );

    const manageAllocationErrorsNav = (
      <NavigationMenuItem
        label="Unsuccessful allocations"
        isExpanded={isExpanded}
        id="manage-allocation-errors-nav"
        routing={Routing.MANAGE_ALLOCATION_ERRORS}
        listIcon={<WarningIcon />}
      />
    );

    const manageAllocationPoolsNav = (
      <NavigationMenuItem
        label="Allocation pools"
        isExpanded={isExpanded}
        id="manage-allocation-pools-nav"
        routing={Routing.MANAGE_ALLOCATION_POOLS}
        listIcon={<AllocationIcon />}
      />
    );

    return (
      <AuthConsumer>
        {({ capabilityContextAccess, features }) => (
          <>
            <Box component="nav"sx={styles.nav}
            >
              <List>
                {homeNav}
                <Divider sx={styles.divider}/>
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={["VIEW_USER", "MANAGE_USER", "VIEW_AAA", "MANAGE_AAA"]}
                  yes={() => manageUserNav}
                />
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform="MANAGE_ROLE"
                  yes={() => manageRoleNav}
                />
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform="MANAGE_CONTEXT"
                  yes={() => manageContextNav}
                />
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={[CAPABILITIES.manageAssignments, CAPABILITIES.manageExam, CAPABILITIES.viewExam, CAPABILITIES.manageIntegrity, CAPABILITIES.viewIntegrity]}
                  yes={() => manageExamNav}
                />
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={["MANAGE_ASSIGNMENTS","VIEW_EXAM"]}
                  yes={() => manageSupervisorSessionsNav}
                />
                {features?.jita && <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={["MANAGE_SHIFT","VIEW_SHIFT"]}
                  yes={() => manageRosterNav}
                />}
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={["MANAGE_ASSIGNMENTS"]}
                  yes={() => unresolvedNoticeReportNav}
                />
                <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={["REVIEW_EXAM","MANAGE_INTEGRITY", "VIEW_INTEGRITY"]}
                  yes={() => manageRecordingNav}
                  />
                {/*<Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform="MANAGE_ROOM_SCANS"
                  yes={() => manageRoomScanNav}
                />*/}
                {features?.jita && <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={["MANAGE_ASSIGNMENTS","VIEW_EXAM"]}
                  yes={() => manageAllocationErrorsNav}
                />}
                {features?.jita && <Can
                  capabilityContextAccess={capabilityContextAccess}
                  contextRequired={false}
                  perform={[CAPABILITIES.manageAllocationPools]}
                  yes={() => manageAllocationPoolsNav}
                />}
              </List>
            </Box>
          </>
        )}
      </AuthConsumer>
    );
  }
}

NavigationMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default NavigationMenu;
