import { ACTIONS } from '../../../constants/joinSession';
import { AUDIO_MEDIA_EVENTS } from '../../../constants/mediaStates';
import { cloneDeep } from 'lodash';

const onRemoteAudioChange = (dispatch, getParticipantMedia) => {
  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their audio state in the call.
   * @param {string} type Type of change, one of 'start', 'stop', 'mute', 'unmute', 'active' or 'inactive'.
   * @param {string} userId The eVig user ID who is sending audio to the meeting.
   * @param {object} updatedParticipant The updated user and media data for this change from the gateway.
   */
  return (type, userId, updatedParticipant) => {
    let newMedia = cloneDeep(getParticipantMedia());
    console.info(`Received audio ${type} request for ${userId}`);

    switch (type) {
      case AUDIO_MEDIA_EVENTS.START:
        newMedia.audio.streaming = true;
        break;
      case AUDIO_MEDIA_EVENTS.STOP:
        newMedia.audio.streaming = false;
        break;
      case AUDIO_MEDIA_EVENTS.MUTE:
        newMedia.audio.muted = true;
        break;
      case AUDIO_MEDIA_EVENTS.UNMUTE:
        newMedia.audio.muted = false;
        break;
      case AUDIO_MEDIA_EVENTS.ACTIVE:
        newMedia.audio.active = true;
        break;
      case AUDIO_MEDIA_EVENTS.INACTIVE:
        newMedia.audio.active = false;
        break;
      default:
        return;
    }

    // Update the participant list with latest audio details
    if (type === AUDIO_MEDIA_EVENTS.STOP) {
      if (newMedia.audio.participants.hasOwnProperty(userId)) {
        delete newMedia.audio.participants[userId];
      }
    } else {
      newMedia.audio.participants[userId] = {
        ...updatedParticipant?.audio,
        userName: updatedParticipant?.userName,
        userType: updatedParticipant?.userType,
      };
    }

    dispatch({ type: ACTIONS.SET_PARTICIPANT_MEDIA, value: newMedia });
  }
};

export default onRemoteAudioChange;