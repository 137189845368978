import React, { useContext, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import { Done, ErrorOutline } from '@mui/icons-material';
import { joinContext } from '../context/JoinContext';
import EndedSessionText from '../content/EndedSessionText';
import ExamWaitingForSupervisorText from '../content/ExamWaitingForSupervisorText';
import NotificationBar from '../notification/NotificationBar';
import ScrollableFadeBox from '../container/ScrollableFadeBox';
import TerminatedSessionText from '../content/TerminatedSessionText';
import STEPS from './';
import OnboardService from '../../services/OnboardService';
import evaluateStepToShow from './helper/evaluateStepToShow';
import { ACTIONS, ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import { MEETING_ENDED_STATES } from '../../constants/session';
import { NOTICE_TYPES } from '../../constants/noticeTypes';

function StepAction(_props) {
  const { state, dispatch } = useContext(joinContext);
  const [pagePosition, setPagePosition] = useState(null);
  const [requestMessage, setRequestMessage] = useState({});
  const defaultProgressFailedMessage = 'Sorry, we were unable to update your onboarding progress, please try again';
  const controllerRef = useRef(new AbortController());
  const messageDisplayAutoHideInMilliseconds = requestMessage?.type === 'success' ? 3000 : null;

  const updateSelfReportableProgress = async (progressDetails, additionalPathParam = undefined, progressFailedMessage = defaultProgressFailedMessage) => {
    try {
      setRequestMessage({});
      if (isEmpty(progressDetails)) {
        throw new Error('Unable to update progress, details not supplied');
      }
      const progressId = state.onboardProgressSteps[progressDetails?.flowStepIndex]?.progress?.id;
      if (!progressId) {
        throw new Error('Unable to update progress, progress not found');
      }
      const progressToUpdate = {
        onboardAssignmentId: state.onboardAssignmentId,
        onboardProgressId: progressId,
        progressDetails: {
          complete: true,
          progressStatus: progressDetails?.progressStatus,
        },
        additionalPathParam: additionalPathParam,
      };
      const updatedProgress = await OnboardService.updateOnboardProgress(progressToUpdate, controllerRef.current?.signal);
      dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: { ...updatedProgress, mustRecomplete: false } });
      return { success: true };
    } catch (error) {
      if (!controllerRef.current?.signal?.aborted) {
        setRequestMessage({ type: NOTICE_TYPES.error, message: progressFailedMessage });
        console.log('Update progress failed', error);
      }
      return { success: false };
    }
  };

  useEffect(() => {
    if (state.gatewayConnectionOpen &&
        !MEETING_ENDED_STATES.includes(ONBOARD_ACTION_TYPES[state.currentActionType]?.meetingStateKey)) {
      const nextStepActionIndex = evaluateStepToShow(state.currentFlowStepIndex, state.onboardProgressSteps);
      if (nextStepActionIndex !== -1 && nextStepActionIndex !== state.currentFlowStepIndex) {
        dispatch({
          type: ACTIONS.SET_CURRENT_ACTION,
          value: {
            actionType: state.onboardProgressSteps[nextStepActionIndex]?.flowStep?.onboardStep?.actionType,
            actionStepIndex: nextStepActionIndex,
          }
        });
        // when the step changes, we want the page to be at the top
        setPagePosition(0);
      }
    }
  }, [dispatch, state.currentActionType, state.currentFlowStepIndex, state.gatewayConnectionOpen, state.onboardProgressSteps]);

  useEffect(() => {
    const controller = controllerRef.current;
    return () => controller.abort();
  }, []);

  const componentMapper = {
    [ONBOARD_ACTION_TYPES.WELCOME.mapper]: <STEPS.WelcomeStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.TERMS_CONDITIONS.mapper]: <STEPS.TermsConditionsStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.MEDIA_CHECK_INFO.mapper]: <STEPS.MediaConnectInfoStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.MEDIA_CHECK_AUDIO.mapper]:
      <STEPS.MediaConnectStep
        flowStepIndex={state.currentFlowStepIndex}
        mediaType={ONBOARD_ACTION_TYPES.MEDIA_CHECK_AUDIO.mediaTypeName}
      />,
    [ONBOARD_ACTION_TYPES.MEDIA_CHECK_VIDEO.mapper]:
      <STEPS.MediaConnectStep
        flowStepIndex={state.currentFlowStepIndex}
        mediaType={ONBOARD_ACTION_TYPES.MEDIA_CHECK_VIDEO.mediaTypeName}
      />,
    [ONBOARD_ACTION_TYPES.MEDIA_CHECK_SCREEN.mapper]:
      <STEPS.MediaConnectStep
        flowStepIndex={state.currentFlowStepIndex}
        mediaType={ONBOARD_ACTION_TYPES.MEDIA_CHECK_SCREEN.mediaTypeName}
      />,
    [ONBOARD_ACTION_TYPES.ID_VERIFICATION_INFO.mapper]: <STEPS.IdVerificationInfoStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.ID_VERIFICATION_ID.mapper]: <STEPS.IdVerificationStep setRequestMessage={setRequestMessage} />,
    [ONBOARD_ACTION_TYPES.ID_VERIFICATION_FACE.mapper]: <STEPS.IdVerificationStep setRequestMessage={setRequestMessage} />,
    [ONBOARD_ACTION_TYPES.ID_ANALYSIS.mapper]: <STEPS.IdVerificationAnalysisStep flowStepIndex={state.currentFlowStepIndex} setRequestMessage={setRequestMessage} />,
    [ONBOARD_ACTION_TYPES.ROOM_SCAN.mapper]: <STEPS.RoomScanStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.AUTHORISED_MATERIALS.mapper]: <STEPS.AuthorisedMaterialsStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.OPEN_EXAM_GATE.mapper]: <STEPS.OpenExamGateStep onComplete={updateSelfReportableProgress} flowStepIndex={state.currentFlowStepIndex} />,
    [ONBOARD_ACTION_TYPES.EXAM_START.mapper]: <STEPS.ExamStartStep />,
    [ONBOARD_ACTION_TYPES.SUPERVISOR_CONNECT.mapper]: <ExamWaitingForSupervisorText />,
    [ONBOARD_ACTION_TYPES.TERMINATED.mapper]: <TerminatedSessionText />,
    [ONBOARD_ACTION_TYPES.ENDED.mapper]: <EndedSessionText />,
  };

  const notificationContent = (
    <Box display="flex" alignItems="center">
      {requestMessage?.type === NOTICE_TYPES.error && <ErrorOutline color="error" sx={{ pr: 2 }} />}
      {requestMessage?.type === NOTICE_TYPES.success && <Done color="success" sx={{ pr: 2 }} />}
      {requestMessage?.message}
    </Box>
  );

  return (
    <>
      <ScrollableFadeBox pagePosition={pagePosition} resetPagePosition={() => setPagePosition(null)}>
        <Box py={2} px={6}>
          {componentMapper[state.currentActionType] || null}
        </Box>
      </ScrollableFadeBox>
      <NotificationBar
        autoHideDuration={messageDisplayAutoHideInMilliseconds}
        message={notificationContent}
        onClose={() => { setRequestMessage({}) }}
        show={!isEmpty(requestMessage)}
      />
    </>
  )
}

export default StepAction;