import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { authContext } from '../../authContext';
import { joinContext } from '../context/JoinContext';

import SingleAudioConnect from './SingleAudioConnect';
import SingleVideoConnect from './SingleVideoConnect';

import handler from './handler/';
import addConferenceError from './helper/addConferenceError';
import clearConferenceError from './helper/clearConferenceError';
import { formatErrorForDisplay } from '../../utils/formatErrorForDisplay';
import PromiseWithAbort from '../../utils/PromiseWithAbort';
import ExamSessionService from '../../services/ExamSessionService';
import AudioStreamService from '../../services/AudioStreamService';
import GatewayService from '../../services/GatewayService';
import VideoStreamService from '../../services/VideoStreamService';
import onIdVerificationAnalysisUpdate from '../step/handler/onIdVerificationAnalysisUpdate';
import onOnboardProgressUpdate from '../step/handler/onOnboardProgressUpdate';
import onSkipOnboardStep from '../step/handler/onSkipOnboardStep';
import { ACTIONS, SERVICES, VIDEO_TYPE_CONFIG } from '../../constants/joinSession';
import { CONFERENCE_ERROR_CATEGORIES } from '../../constants/errors';
import { MEETING_ENDED_STATES } from '../../constants/session';
import { MEDIA_STREAM_CONNECTION_STATES, SHARED_MEDIA_STATES as MEDIA_STATE } from '../../constants/mediaStates';

function JoinMeetingConnect(props) {
  const { slotId } = props;
  const { user } = useContext(authContext)
  const { state, dispatch } = useContext(joinContext);
  const asyncAbort = useRef(new PromiseWithAbort());
  const examSession = useRef(state.examSession);
  const onboardProgressStepsDetails = useRef(state.onboardProgressSteps);
  const audioService = useRef(state.audioService);
  const gatewayService = useRef(state.gatewayService);
  const videoService = useRef(state.videoService);
  const participantMedia = useRef(state.participantMedia);
  const videoConnectionState = useRef(state.videoConnectionState);
  const [hasMeetingDetails, setHasMeetingDetails] = useState(false);
  const [isGatewayConnected, setIsGatewayConnected] = useState(false);

  /**
   * Wrapper for displaying a recoverable connection error to the user on a failure, that checks whether
   * the component has been unmounted before updating the state. This error can be removed when the connection
   * is restored.
   * @param {string} category The type of connection that failed.
   * @param {object} errorObject The error details to display.
   * @param {boolean} clear True if the error has been resolved.
   */
  const setConnectionError = useCallback((category, errorObject, clear = false) => {
    const thisExamSession = examSession.current;
    if (isEmpty(thisExamSession)) {
      return;
    }
    const currentConnectionErrors = thisExamSession.connectionDetails?.connectionErrors || [];
    let updatedErrors = [];
    if (!clear) {
      updatedErrors = addConferenceError(category, errorObject, currentConnectionErrors);
    } else {
      updatedErrors = clearConferenceError(category, errorObject?.errorCode, currentConnectionErrors);
    }
    dispatch({
      type: ACTIONS.UPDATE_EXAM_SESSION,
      value: {
        connectionDetails: {
          ...thisExamSession.connectionDetails,
          connectionErrors: updatedErrors,
        }
      }
    });
  }, [dispatch, examSession]);

  const setGatewayConnectionOpen = useCallback((isOpen) => {
    dispatch({ type: ACTIONS.SET_GATEWAY_CONNECTION_OPEN, value: isOpen });
  }, [dispatch]);

  const getServices = useCallback(() => {
    return {
      audioService: audioService.current,
      gatewayService: gatewayService.current,
      videoService: videoService.current,
    };
  }, [audioService, gatewayService, videoService]);

  const getConnectionState = useCallback(() => {
    return videoConnectionState.current;
  }, [videoConnectionState]);

  const getParticipantMedia = useCallback(() => {
    return participantMedia.current;
  }, [participantMedia]);

  const getProgressStepsDetails = useCallback(() => {
    return onboardProgressStepsDetails.current;
  }, [onboardProgressStepsDetails]);

  // Update ref vars from context
  useEffect(() => {
    examSession.current = state.examSession;
  }, [state.examSession]);

  useEffect(() => {
    audioService.current = state.audioService;
  }, [state.audioService]);

  useEffect(() => {
    gatewayService.current = state.gatewayService;
  }, [state.gatewayService]);

  useEffect(() => {
    videoService.current = state.videoService;
  }, [state.videoService]);

  useEffect(() => {
    participantMedia.current = state.participantMedia;
  }, [state.participantMedia]);

  useEffect(() => {
    videoConnectionState.current = state.videoConnectionState;
  }, [state.videoConnectionState]);

  useEffect(() => {
    onboardProgressStepsDetails.current = state.onboardProgressSteps;
  }, [state.onboardProgressSteps]);

  // Use effect to get the meeting details
  // Will be triggered only if hasMeetingDetails is false
  // sets ['connectionDetails']['connectionProps']
  useEffect(() => {
    const getMeetingDetails = async () => {
      const controller = new AbortController();

      try {
        if (!user) {
          throw new Error('No logged in user yet');
        }
        // Obtain meeting details via API calls
        const meetingDetails = await ExamSessionService.joinMeeting(
          slotId,
          controller.signal
        );

        if (controller.signal.aborted) {
          return;
        }

        setMeetingDetailsInContext({
          connectionProps: meetingDetails,
        });
        setHasMeetingDetails(true);
      } catch (error) {
        // Could not obtain meeting details.
        setConnectionError(
          CONFERENCE_ERROR_CATEGORIES.CONNECT,
          formatErrorForDisplay(error, CONFERENCE_ERROR_CATEGORIES.CONNECT, 'joinMeetingFail'),
          false
        );
        console.error('Could not join eVigilation session', { error });
        dispatch({
          type: ACTIONS.SET_SHARED_MEDIA_STATUS,
          value: {
            webcam: MEDIA_STATE.DISABLED,
            audio: MEDIA_STATE.DISABLED,
            desktop: MEDIA_STATE.DISABLED
          }
        });
        setHasMeetingDetails(true);
      }
    };

    const setMeetingDetailsInContext = (connectionDetails) => {
      let sessionToUpdate = examSession.current;
      sessionToUpdate['connectionDetails'] = { ...sessionToUpdate?.connectionDetails, ...connectionDetails };
      dispatch({ type: ACTIONS.UPDATE_EXAM_SESSION, value: sessionToUpdate });
    };

    !hasMeetingDetails && getMeetingDetails();
  }, [dispatch, hasMeetingDetails, setConnectionError, slotId, user]);

  // Use effect to initialise the gateway service
  // Will be triggered only if hasMeetingDetails is true and meeting details is not null
  useEffect(() => {
    const initialiseGateway = () => {

      let thisExamSession = examSession.current;
      const meetingDetails = thisExamSession?.connectionDetails?.connectionProps;

      const onGatewayDisconnectedHandler = handler.onGatewayDisconnected(setConnectionError, setGatewayConnectionOpen);
      const onGatewayReconnectedHandler = handler.onGatewayReconnected(setConnectionError, setGatewayConnectionOpen);
      const onMeetingTerminatedHandler = handler.onMeetingTerminate(dispatch, getServices, setConnectionError);
      const onMeetingUpdateHandler = handler.onMeetingUpdate(dispatch);
      const onRemoteAudioChangeHandler = handler.onRemoteAudioChange(dispatch, getParticipantMedia);
      const onRemoteParticipantVideoChangeHandler = handler.onRemoteParticipantVideoChange(dispatch, getConnectionState, getParticipantMedia);
      const onOnboardProgressUpdateHandler = onOnboardProgressUpdate(dispatch, getProgressStepsDetails);
      const onIdVerificationAnalysisUpdateHandler = onIdVerificationAnalysisUpdate(dispatch, getProgressStepsDetails);

      if (meetingDetails !== undefined) {
        // Assuming we have successfully joined, open websocket session.
        let gatewayService = new GatewayService();
        console.debug(`Attaching gateway event handlers for slot [${slotId}]`)
        // Tell the supervisor if we are unexpectedly disconnected
        gatewayService.registerDisconnectionHandler(onGatewayDisconnectedHandler);
        // Clear the gateway error if the student is successfully reconnected
        gatewayService.registerConnectionHandler(onGatewayReconnectedHandler);
        // Set where the student is up to in the exam for what the next step should be after onboard steps
        gatewayService.registerMeetingChangeEvent('update', onMeetingUpdateHandler);
        // When the gateway sends a terminate message, smoothly disconnect the current session (we can reuse the meeting
        // end function since we just need to close all things down).
        gatewayService.registerMeetingChangeEvent('terminated', () => onMeetingTerminatedHandler('terminated'));
        // Shut everything down when the meeting is closed on BBB
        gatewayService.registerMeetingChangeEvent('end', () => onMeetingTerminatedHandler('end'));

        // Include gateway handlers for collecting the info about other participants in the meeting
        gatewayService.registerMediaChangeEvent('audio',
          (type, userId, userChangeData) => onRemoteAudioChangeHandler(type, userId, userChangeData)
        );
        gatewayService.registerMediaChangeEvent('video',
          (type, userId, userChangeData) => onRemoteParticipantVideoChangeHandler(type, userId, userChangeData)
        );
        // When the gateway sends a skip onboard step message, update the step progress for the corressponding progress and step pair
        gatewayService.registerSkipOnboardStepEvent((data) => onSkipOnboardStep(data, dispatch, getProgressStepsDetails, getServices));
        // Update onboard progress as needed when an update is received
        gatewayService.registerOnboardProgressUpdateEvent(onOnboardProgressUpdateHandler);
        // Update the onboard progress when an id analysis update is received
        gatewayService.registerIdAnalysisUpdateEvent(onIdVerificationAnalysisUpdateHandler);

        asyncAbort.current.wrap(gatewayService.connect(user.id, slotId, meetingDetails))
          .then(() => {
            console.debug(`Gateway connection is complete for ${slotId}`);
            const gatewayProps = {
              connectionDetails: {
                ...thisExamSession.connectionDetails,
                statusMessage: 'gatewayConnected',
                connectionSetUpComplete: true,
                videoConnection: MEDIA_STREAM_CONNECTION_STATES.CLOSED,
                connectionErrors: [],
              },
            };

            // set all initial services as some gateway handlers use them
            dispatch({ type: ACTIONS.SET_SERVICE, value: { serviceType: SERVICES.GATEWAY_SERVICE, service: gatewayService } });
            dispatch({ type: ACTIONS.SET_SERVICE, value: { serviceType: SERVICES.AUDIO_SERVICE, service: new AudioStreamService() } });
            dispatch({ type: ACTIONS.UPDATE_EXAM_SESSION, value: { ...thisExamSession, ...gatewayProps } });
            setIsGatewayConnected(true);
            // open video connection to receive participant video
            dispatch({ type: ACTIONS.SET_VIDEO_STATE, value: MEDIA_STREAM_CONNECTION_STATES.CONNECTING });
          })
          .catch((error) => {
            //Could not authenticate with gateway server.
            gatewayService.unregisterHandler('all');
            setConnectionError("connection", formatErrorForDisplay(error, "connection", "gatewayAuthenticationFail"), false);
            console.error('Could not connect to gateway', { error });
            dispatch({
              type: ACTIONS.SET_SHARED_MEDIA_STATUS,
              value: {
                webcam: MEDIA_STATE.DISABLED,
                audio: MEDIA_STATE.DISABLED,
                desktop: MEDIA_STATE.DISABLED
              }
            });
          });
      }
    };

    if (hasMeetingDetails && !state.videoService) {
      // setup initial services so when the gateway is connected, the handlers will work immediately as it receives messages
      dispatch({ type: ACTIONS.SET_SERVICE, value: { serviceType: SERVICES.VIDEO_SERVICE, service: new VideoStreamService() } });
    }

    if (hasMeetingDetails && !isGatewayConnected && !state.gatewayService && state.videoService
      && !MEETING_ENDED_STATES.includes(state.currentActionType)) {
      initialiseGateway();
    }
  }, [dispatch, getConnectionState,  getParticipantMedia, getProgressStepsDetails, getServices,
    hasMeetingDetails, isGatewayConnected, setConnectionError, setGatewayConnectionOpen, slotId,
    state.currentActionType, state.gatewayService, state.videoService, user]);

  useEffect(() => {
    const asyncAbortRef = asyncAbort.current;
    return () => {
      if (gatewayService) {
        gatewayService.leave('Student leaving page');
      }
      asyncAbortRef.abort();
    }
  }, [])

  return (
    <>
      {state.gatewayConnectionOpen &&
        <>
          {state.audioService && state.audioConnectionState !== null &&
            <SingleAudioConnect />
          }
          {state.videoService && state.videoConnectionState !== null &&
            <SingleVideoConnect setConnectionError={setConnectionError} videoTypeConfig={VIDEO_TYPE_CONFIG.WEBCAM} />
          }
          {state.videoService && state.screenShareConnectionState !== null &&
            <SingleVideoConnect setConnectionError={setConnectionError} videoTypeConfig={VIDEO_TYPE_CONFIG.SCREEN_SHARE} />
          }
        </>
      }
    </>
  );
}

export default JoinMeetingConnect;
