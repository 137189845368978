import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExamSessionSummaryTable from './table/ExamSessionSummaryTable';
import ExamSessionTableContainer from './container/ExamSessionTableContainer';
import ExamSessionService from '../services/ExamSessionService';
import Notice from './notification/Notice';
import RequestStatusIndicator from './notification/RequestStatusIndicator';
import Section from './Section';
import { MSG_404 } from '../constants/login';
import { SEARCH_BY_TYPES, SEARCH_GROUP_OPTIONS, SESSION_TIMES } from '../constants/examSessions';
import { requestStatusIndicatorContainer } from '../config/theme';
import { isValidExamSessionSearch } from '../utils/isValidSearch';

class ExamSessionBySearchOption extends React.Component {

  state = {
    examSessionList: undefined,
    examSummary: undefined,
    hasSlotUpdated: false,
    isRequestPending: true,
    hasRequestErrored: false,
    hasDataProcessingErrored: false,
    errorMessage: '',
    filteredExamSlotList: undefined,
    filteredByContextId: undefined,
    filteredBySession: undefined,
    filteredByStudentId: undefined,
    hasFilteredExamSlotList: false,
    isFilteredRequestPending: true,
    hasFilteredRequestErrored: false,
    showSummary: true,
  };
  controller = new AbortController();

  setHasFilteredExamSlotList = (contextId, selectedSession) => {
    const { filters, searchText, selectedDate, selectedOption } = this.props;
    if (contextId !== undefined) {
      this.setState({
        filteredByContextId: contextId,
        filteredBySession: selectedSession
      });

      let searchParams = {
        contextId: contextId,
        filters: filters,
        extraData: 'deleted-slots,scheduled-delete-slots',
        ...SEARCH_BY_TYPES[selectedOption].additionalParams,
      };

      if (isEmpty(selectedSession)) {
        searchParams['examDate'] = selectedDate;
      } else {
        this.getSessionTimes(selectedDate, selectedSession, searchParams);
      }

      if (selectedOption === 'mediaHost') {
        searchParams[SEARCH_BY_TYPES['mediaHost'].endpointMapper] = searchText;
      }

      this.loadFilteredExamSlot(searchParams, selectedOption);
    }
  };

  loadFilteredExamSlot = async (searchParams, selectedOption) => {
    this.setState({
      isFilteredRequestPending: true,
      hasFilteredRequestErrored: false,
      errorMessage: '',
      hasFilteredExamSlotList: false,
    });

    try {
      let examSessions = [];
      if (isValidExamSessionSearch(searchParams, selectedOption)) {
        examSessions = await ExamSessionService.getExamSessionsForSearchOptions(searchParams, 'manage', this.controller.signal);
      } else {
        throw new Error('Search option not available, please try a new search');
      }

      this.setState({
        filteredExamSlotList: examSessions,
        isFilteredRequestPending: false,
        hasFilteredRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            filteredExamSlotList: [],
            isFilteredRequestPending: false,
            hasFilteredRequestErrored: false,
          });
        } else {
          this.setState({
            isFilteredRequestPending: false,
            hasFilteredRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  setHasSlotUpdated = (hasSlotUpdated) => {
    this.setState({
      hasSlotUpdated: hasSlotUpdated
    });
  };

  refreshSlots = (forceUpdate) => {
    const { hasSlotUpdated, filteredByContextId, filteredByStudentId, filteredBySession, showSummary } = this.state;
    const { filters, selectedDate, selectedOption } = this.props;

    if (hasSlotUpdated || forceUpdate) {

      let combinedSearchParams = { filters: filters, ...SEARCH_BY_TYPES[selectedOption].additionalParams, extraData: 'deleted-slots,scheduled-delete-slots' };

      const { searchParams, error } = this.validateFields();

      if (!filteredByStudentId && filters.groupBy !== SEARCH_GROUP_OPTIONS.NONE.value) {
        this.loadSummaryData({ ...combinedSearchParams, ...searchParams }, error);
      }

      if (filteredByContextId || filteredByStudentId) {
        if (isEmpty(filteredBySession)) {
          combinedSearchParams['examDate'] = selectedDate;
        } else {
          this.getSessionTimes(selectedDate, filteredBySession, combinedSearchParams);
        }
        if (filteredByContextId !== undefined) {
          combinedSearchParams['contextId'] = filteredByContextId;
          if (selectedOption === 'mediaHost') {
            const mapper = SEARCH_BY_TYPES['mediaHost'].endpointMapper;
            combinedSearchParams[mapper] = searchParams[mapper];
          }
        } else if (filteredByStudentId !== undefined) {
          combinedSearchParams['studentId'] = filteredByStudentId;
        }
        this.loadFilteredExamSlot(combinedSearchParams, selectedOption);
      } else if (!showSummary) {
        combinedSearchParams = { ...combinedSearchParams, ...searchParams };
        this.loadFilteredExamSlot(combinedSearchParams, selectedOption);
      }
    }
  };

  updateData = () => {
    const { filters, selectedOption, studentId } = this.props;
    const { searchParams, error } = this.validateFields();

    if (error === undefined &&
      ((selectedOption === 'student' && !isEmpty(studentId)) || filters.groupBy === 'none' || selectedOption === 'report')) {
      this.setState({
        isRequestPending: false,
        hasRequestErrored: false,
        filteredByStudentId: studentId,
        showSummary: false,
      });
      this.loadFilteredExamSlot(searchParams, selectedOption);
    } else {
      this.loadSummaryData(searchParams, error);
    }
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    !isEqual(prevProps, this.props) && this.updateData();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  getSessionTimes = (selectedDate, selectedSession, searchParams) => {
    const startFormat = "T" + SESSION_TIMES[selectedSession]['startTime'];
    const endFormat = "T" + SESSION_TIMES[selectedSession]['endTime'];
    searchParams['examStartDateAfter'] = selectedDate + startFormat;
    searchParams['examStartDateBefore'] = selectedDate + endFormat;
  };

  validateFields() {
    const { filters, selectedDate, selectedOption, searchText, studentId, selectedSession } = this.props;

    let searchParams = { filters: filters, extraData: 'deleted-slots,scheduled-delete-slots', ...SEARCH_BY_TYPES[selectedOption].additionalParams };
    if (!SEARCH_BY_TYPES[selectedOption]) {
      return {
        searchParams: searchParams,
        error: new Error('Search option not available, please try a new search')
      };
    }

    if ((selectedOption === 'context' || selectedOption === 'mediaHost') && !isEmpty(searchText)) {
      searchParams['examDate'] = selectedDate;
      searchParams[SEARCH_BY_TYPES[selectedOption].endpointMapper] = searchText;
    } else if (selectedOption === 'student' && !isEmpty(studentId)) {
      searchParams['examDate'] = selectedDate;
      searchParams[SEARCH_BY_TYPES[selectedOption].endpointMapper] = studentId;
    } else if (selectedOption === 'session' && SESSION_TIMES[selectedSession]) {
      searchParams[SEARCH_BY_TYPES[selectedOption].endpointMapper] = selectedSession;
      this.getSessionTimes(selectedDate, selectedSession, searchParams)
    } else if (selectedOption === 'report') {
      searchParams['examDate'] = selectedDate;
    } else {
      return {
        searchParams: searchParams,
        error: new Error('Search option value not available, please try a new search')
      };
    }

    return {
      searchParams: searchParams,
      error: undefined
    }
  }

  loadSummaryData = async (searchParams, error) => {
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: '',
      hasSlotUpdated: false,
    });
    try {
      if (error !== undefined) {
        throw error;
      }

      const examSummary = await ExamSessionService.getExamSessionsSummaryToManageForSearchOptions(
        searchParams, this.controller.signal);

      this.setState({
        examSummary: examSummary,
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (respError) {
      if (respError.name !== 'AbortError') {
        if (respError.message === MSG_404) {
          this.setState({
            examSummary: [],
            examSessionList: [],

            isRequestPending: false,
            hasRequestErrored: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: respError.message,
          });
        }
      }
    }
  };

  render() {
    const {
      examSummary,
      examSessionList,
      isRequestPending,
      hasRequestErrored,
      errorMessage,
      filteredExamSlotList,
      isFilteredRequestPending,
      hasFilteredRequestErrored,
      showSummary
    } = this.state;
    const { filters, selectedSession } = this.props;

    const displayData = !showSummary || (!isRequestPending && !hasRequestErrored && (examSummary.length > 0 || examSessionList.length > 0));

    const displayFilteredData = !isFilteredRequestPending && !hasFilteredRequestErrored && filteredExamSlotList.length > 0;

    return (
      <Box position="relative">
        <Box sx={requestStatusIndicatorContainer}>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </Box>
        {!displayData && !isRequestPending && !hasRequestErrored &&
          <Section>
            <Notice noticeType="notice">There are no exam sessions that match the search criteria</Notice>
          </Section>
        }
        {(hasRequestErrored || hasFilteredRequestErrored) &&
          <Section>
            <Notice noticeType="error">{errorMessage}</Notice>
          </Section>
        }
        {displayData &&
          <>
            {showSummary &&
              <Section>
                <Typography variant="h3">Exam session summary</Typography>
                <ExamSessionSummaryTable
                  examSummary={examSummary}
                  examSessionList={examSessionList}
                  setHasSlotUpdated={this.setHasSlotUpdated}
                  refreshSlots={this.refreshSlots}
                  setHasFilteredExamSlotList={this.setHasFilteredExamSlotList}
                  selectedSession={selectedSession}
                />
              </Section>
            }
            {!isFilteredRequestPending && !hasFilteredRequestErrored && filteredExamSlotList.length === 0 &&
              <Section>
                <Notice noticeType="notice">There are no exam sessions that match the search criteria</Notice>
              </Section>
            }
            {displayFilteredData &&
              <div>
                <ExamSessionTableContainer
                  examSessionList={filteredExamSlotList}
                  displayJoinButton={false}
                  displayActionButton={true}
                  filters={filters}
                  setHasSlotUpdated={this.setHasSlotUpdated}
                  refreshSlots={this.refreshSlots}
                />
              </div>
            }
          </>
        }
      </Box>
    )
  }
}

ExamSessionBySearchOption.propTypes = {
  filters: PropTypes.object,
  selectedDate: PropTypes.string.isRequired,
  selectedOption: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  studentId: PropTypes.string,
  selectedSession: PropTypes.string,
};

export default ExamSessionBySearchOption;
