import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material';
import { join } from 'lodash';
import Notice from '../notification/Notice';
import Section from '../Section';
import UploadService from '../../services/UploadService';

const styles = {
  fieldsetContainer: {
    display: 'block',
    margin: '0 auto',
    zIndex: 1200,
  },
  legend: {
    color: 'primary.main',
    margin: 1,
  },
};

class UploadForm extends React.Component {
  state = {
    isRequestSuccessful: false,
    hasRequestErrored: false,
    isPartialSuccess: false,
    uploadFile: undefined,
    params: undefined,
    responseErrors: [],
  };

  controller = new AbortController();

  componentWillUnmount() {
    this.controller.abort();
  }

  uploadFile = async () => {
    const { evigFileType, setHasUpdated  } = this.props;
    const { uploadFile } = this.state;
    if (!uploadFile) { return; }

    setHasUpdated(false);

    try {
      const response = await UploadService.uploadFile(uploadFile, evigFileType, this.state.params, this.controller.signal);
      this.setState({
        hasRequestErrored: false,
        isRequestSuccessful: response?.status === 200,
        isPartialSuccess: response?.status === 206,
        responseErrors: response?.status === 206 ? await response.json() : [],
        errorMessage: '',
      });
      this.props.setHasUpdated(true);
    } catch(error) {
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        isPartialSuccess: false,
        responseErrors: [],
        errorMessage: error.message,
      });
    }
  };

  setUploadFile = (event) => {
    if (event.target.files.length > 0) {
      this.setState({uploadFile: event.target.files.item(0)});
    }
  };

  setParams = (params) => {
    this.setState({params});
  };


  render() {
    const { additionalFieldsForm, evigFileType } = this.props;
    const {
      isRequestSuccessful,
      isPartialSuccess,
      hasRequestErrored,
      responseErrors,
      errorMessage
    } = this.state;

    return (
      <Section>
        <FormControl component="fieldset" sx={styles.fieldsetContainer}>
          <FormLabel component="legend" sx={styles.legend}>Upload {evigFileType.display} file</FormLabel>
          {evigFileType.warningText &&
            <Notice noticeType="warning"><strong>{evigFileType.warningText}</strong></Notice>
          }
          {isRequestSuccessful &&
            <Notice noticeType="success">The file uploaded successfully</Notice>
          }
          {isPartialSuccess &&
            <Notice noticeType="warning">
              {responseErrors.errorInCsv ? evigFileType.badRequestText : evigFileType.partialSuccessText}
              <br/>
              {responseErrors.unknownIds && join(responseErrors.unknownIds, ', ')}
            </Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {additionalFieldsForm &&
            <Box pt={3} pb={3} display="flex">
              {React.cloneElement(additionalFieldsForm, { setParams: this.setParams })}
            </Box>
          }
          <Box pt={3} pb={3} display="flex">
            <TextField
              type="file"
              id="upload-file"
              label="Select file"
              onChange={this.setUploadFile}
              required={true}
              size="medium"
              variant='outlined'
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: evigFileType.allowedFileTypes }}
             />
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              color='primary'
              variant='contained'
              onClick={this.uploadFile}
            >Upload</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

UploadForm.propTypes = {
  additionalFieldsForm: PropTypes.object,
  evigFileType: PropTypes.object.isRequired,
  setHasUpdated: PropTypes.func,
};

export default UploadForm;
