import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import Typography from '@mui/material/Typography';
import { authContext } from '../../authContext';
import OrderedStepsList from './OrderedStepsList';
import { AUTO_ACTIVE_STEPS_MAPPER } from '../../constants/contentOverrides';

function ExamInProgressText (_props) {
  const { contentOverrides } = useContext(authContext);

  const steps = isEmpty(contentOverrides?.[AUTO_ACTIVE_STEPS_MAPPER])
    ? {
      1: 'Keep this tab open and go back to your eExam landing page. Closing this tab may be construed as misconduct.',
      2: 'At your scheduled start time, <strong>refresh your eExam screen</strong> and click the <strong>Start exam</strong> button when it turns blue.',
    }
    : contentOverrides[AUTO_ACTIVE_STEPS_MAPPER];
  return (
    <div id="auto-onboard-active-text">
      <Typography variant="h2" color="primary">
        You're now ready to start your eExam
      </Typography>
      <OrderedStepsList steps={steps}/>
      <Typography variant="body1">Good luck with your exam!</Typography>
    </div>
  )
}

export default ExamInProgressText;