import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns/parseISO';
import { format as dateFnsFormat } from 'date-fns/format';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';
import { has, isEmpty, orderBy } from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ButtonBase } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Section from '../Section';
import Notice from '../notification/Notice';
import ImageFromDataPopup from '../popup/ImageFromDataPopup';
import RefreshButton from '../form/RefreshButton';
import FlagService from '../../services/FlagService';
import { MSG_404 } from '../../constants/login';
import FLAGS from '../../constants/flags';
import { scrollBoxStyle } from '../../config/theme';

const styles = {
  listIcon: {
    paddingTop: 0.25,
    paddingLeft: 0.25,
  },
  primaryText: {
    fontSize: '0.9rem',
  },
  secondaryText: {
    color: 'grey.700',
  },
  note: {
    overflowWrap: 'break-word',
  },
  frameButton: {
    display: 'block',
    p: 0,
    b: 0,
    m: 0,
    textAlign: 'left'
  },
  frameLink: {
    color: 'primary.main',
    textDecoration: 'underline',
  },
};

function FlagHistory(props) {
  const { slotId } = props;
  const [examSessionFlags, setExamSessionFlags] = useState();
  const [requestError, setRequestError] = useState();

  const frameCache = useRef({});

  const [flagFrameViewer, setFlagFrameViewer] = useState({
    slotFlag: null,
    imageSource: null,
    imageAlt: '',
    imageText: null,
    title: '',
    open: false,
  });

  const getFlagHistory = useCallback(async () => {
    try {
      setExamSessionFlags(await FlagService.getExamSessionFlags(slotId));
    } catch (error) {
      if (error.message === MSG_404) {
        setExamSessionFlags([])
      } else {
        console.log(error);
        setRequestError('Error retrieving flag history');
      }
    }
  }, [slotId]);

  useEffect(() => {
    getFlagHistory();
  }, [slotId, getFlagHistory]);

  const primaryText = (flagName, timestamp) => {
    const flaggedDateTime = timestamp && parseISO(timestamp);
    return (
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="body1" sx={styles.primaryText}>{flagName}</Typography>
        <Typography
          variant="body2"
          sx={styles.secondaryText}
        >
          {dateFnsIsValid(flaggedDateTime) && dateFnsFormat(flaggedDateTime, 'h:mma')}
        </Typography>
      </Box>
    )
  }

  const getFlagFrame = async (slotFlag, controllerSignal) => {
    if (!slotFlag || !controllerSignal) {
      return
    }

    if (has(frameCache.current, slotFlag.id)) {
      return frameCache.current[slotFlag.id];
    }

    const frameResponse = await FlagService.getFlagFrame(slotId, slotFlag.id, controllerSignal, 'image/jpeg');
    const frameBlob = await frameResponse.blob();
    frameCache.current[slotFlag.id] = frameBlob;

    return frameBlob;
  }

  const handleFlagFrameButtonClick = (evt, slotFlag) => {
    evt.preventDefault();
    // If we are re-opening the same image again, no need to send another GET request
    showFlagFrame(slotFlag);
  }

  const showFlagFrame = (slotFlag) => {
    const displayDate = slotFlag.timestamp ? dateFnsFormat(parseISO(slotFlag.timestamp), 'HH:mm:ss') : 'unknown';

    setFlagFrameViewer({
      open: true,
      title: `Flagged image for '${slotFlag.flag.name}' taken at ${displayDate}`,
      slotFlag: slotFlag,
      imageAlt: 'flagged image',
      imageText: slotFlag.note ? slotFlag.note : null,
    });
  }

  const hideFlagFrame = () => {
    setFlagFrameViewer({
      ...flagFrameViewer,
      open: false,
    });
  }

  return (
    <Section sx={{ ...scrollBoxStyle, p: 2, boxShadow: 'none' }}>
      <ImageFromDataPopup
        open={flagFrameViewer.open}
        onClose={hideFlagFrame}
        imageRetriever={(signal) => getFlagFrame(flagFrameViewer.slotFlag, signal)}
        imageText={flagFrameViewer.imageText}
        imageTitle={flagFrameViewer.title}
        imageAlt={flagFrameViewer.imageAlt}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" ml={0.5}>
        <Typography variant="h5" component="h3" sx={styles.primaryText}>Flagging history</Typography>
        <RefreshButton refreshCallback={getFlagHistory} />
      </Box>
      <span style={styles.primaryText}>
        {requestError &&
          <Notice noticeType="error">{requestError}</Notice>
        }
        {isEmpty(examSessionFlags) &&
          <Notice noticeType="notice">No flags found</Notice>
        }
      </span>
      {!isEmpty(examSessionFlags) &&
        <List dense={true}>
          {orderBy(examSessionFlags, ['timestamp'], ['desc']).map((slotFlag) => {
            return (
              <ListItem
                key={slotFlag.id}
                alignItems="center"
                disableGutters={true}
                sx={{ mb: 1 }}
              >
                <ListItemIcon sx={styles.listIcon}>{FLAGS[slotFlag.flag.eventType].icon}</ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary={primaryText(slotFlag.flag.name, slotFlag.timestamp)}
                  secondary={
                    <>
                      {!!slotFlag.frameUrl &&
                      <ButtonBase
                        sx={{display: 'block', p: 0, b: 0, m: 0, textAlign: 'left'}}
                        onClick={(evt) => handleFlagFrameButtonClick(evt, slotFlag)}
                        aria-label={`View image for ${slotFlag.flag.name}`}
                        focusRipple
                      >
                        <Typography
                          variant="body2"
                          sx={{ ...styles.note, ...styles.frameLink }}>
                          {slotFlag.note}
                        </Typography>
                      </ButtonBase>
                      }
                      {!slotFlag.frameUrl &&
                        <Typography variant="body2" sx={styles.note}>{slotFlag.note}</Typography>
                      }
                    </>
                  }
                  sx={{ my: 0 }}
                />
              </ListItem>
            )
          })}
        </List>
      }
    </Section>
  );
}

FlagHistory.propTypes = {
  slotId: PropTypes.string.isRequired,
};

export default FlagHistory;
