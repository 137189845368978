import { has, isEmpty, omit } from 'lodash';
import { SCREEN_SHARE_TYPES } from '../constants/mediaStates';
import { ACTIONS } from '../constants/monitorSessions';

const reconnectStudent = (reconnectionStatus, reconnectionDetails, globalState, dispatch, setReconnectionStatus) => {
  switch (reconnectionStatus) {
    case 'completed' :
      break;
    case 'removeSession' :
      dispatch({type: ACTIONS.REMOVE_EXAM_SESSION, value: {id: reconnectionDetails.current['slotId']}});
      setReconnectionStatus('addSession');
      break;
    case 'addSession' :
      dispatch({type: ACTIONS.ADD_EXAM_SESSION, value: reconnectionDetails.current['examDetails']});
      setReconnectionStatus('waitForServiceInit');
      break;
    case 'waitForServiceInit' :
      //don't wait for a slot to connect that has already errored
      if(has(globalState.examSessions[reconnectionDetails.current.slotId], 'connectionDetails') &&
        (!isEmpty(globalState.examSessions[reconnectionDetails.current.slotId].connectionDetails.connectionErrors) ||
         !isEmpty(globalState.examSessions[reconnectionDetails.current.slotId].connectionDetails.requestError)
        )
      ) {
        setReconnectionStatus('completed');
        return;
      }
      if(has(globalState, reconnectionDetails.current.slotId)) {
        let reconnectingServices = globalState[reconnectionDetails.current.slotId];

        if (isEmpty(reconnectingServices.gatewayService)
          || isEmpty(reconnectingServices.videoService)
          || isEmpty(reconnectingServices.audioService)
        ) {
          // Wait for state to be updated and rerendered
        } else {
          setReconnectionStatus('addActiveSlot');
        }
      }
      break;
    case 'addActiveSlot' :
      dispatch({type: ACTIONS.SET_ACTIVE_CONNECTION, value: reconnectionDetails.current['slotId']});
      setReconnectionStatus('completed');
      break;
    default:
      console.warn(`[SuperviseInSession]: Ignoring unknown reconnection status ${reconnectionStatus}`);
      break;
  }
};

export const getReconnectObject = (origObject) => {
  let reconnectingObj = {...origObject};
  // The following values will be reinitialised in the monitoring context
  reconnectingObj = omit(reconnectingObj, 'connectionDetails');
  reconnectingObj = omit(reconnectingObj, 'chat');
  reconnectingObj = omit(reconnectingObj, 'studentMedia');
  reconnectingObj.screenShareType = SCREEN_SHARE_TYPES.NONE.mapper;

  return {
    'slotId': reconnectingObj.id,
    'examDetails': reconnectingObj
  };
};

export default reconnectStudent;
