import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { AccountBoxOutlined, Person, School } from '@mui/icons-material';

function UserIdentityText(props) {
  const { sx, user } = props;
  const avatarStyle = { bgcolor: 'grey.300', color: 'secondary.dark', height: '32px', width: '32px' }
  return (
    <List dense={true} disablePadding={true} sx={{ ...sx }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={avatarStyle}><AccountBoxOutlined fontSize="small" /></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Legal name"
          secondary={user.legalName ? user.legalName : 'Not specified'}
        />
      </ListItem>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={avatarStyle}><School fontSize="small" /></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Student ID"
          secondary={user.externalId ? user.externalId : 'Not specified'}
        />
      </ListItem>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={avatarStyle}><Person fontSize="small" /></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Preferred name"
          secondary={user.fullName}
        />
      </ListItem>
    </List>
  )
}

UserIdentityText.propTypes = {
  sx: PropTypes.object,
  user: PropTypes.object,
}

export default UserIdentityText;