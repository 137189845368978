import streamSettings from '../config/streamSettings';
import audioSettings from '../config/audio';
import { 
  stripMDnsCandidates, 
  filterValidIceCandidates, 
  isUnifiedPlan 
} from '../utils/sdpUtils';

// Import webRTC shim methods for browser support
// eslint-disable-next-line
import adapter from 'webrtc-adapter';
import { formatErrorForDisplay } from '../utils/formatErrorForDisplay';

// TODO: We can't use the default npm library for SIP.js here because BBB have implemented a custom
// ICE gathering modifier for 'sessionDescriptionHandlerModifiersPostICEGathering' in their static
// js include. Eventually, we should re-implement this without SIP.js customization so that we can
// use the latest release version.
//import { UserAgent, Inviter, SessionState } from "sip.js";
const { UserAgent, Inviter, SessionState } = window.SIP;

const AudioContext = window.AudioContext || window.webkitAudioContext;

export default class AudioStreamService {

  // The audioContext to manage audio sources and destinations on the browser side
  static audioContext = null;

  /**
   * Constructor initialises the common class properties.
   */
  constructor() {

    // Track the devices being used, as well as media streams
    // and audio context processing chains.
    this.media = {
      inputDevice: {
        id: undefined,
        stream: undefined,
        audioNodeReferences: [],
      },
      outputDevice: {
        id: undefined,
      },
    };

    // Any audio worklets to process audio data changes
    this.audioWorkletHandlers = {
      input: {},
      output: {}
    };

    // Placeholders for audio connection properties
    this.audioTag = undefined;
    this.callActive = false;
    this.startingCall = false;
    this.extension = undefined;
    this.handlers = {
      onMediaStarted: [],
      onMediaStopped: [],
      onError: [],
    };

    // Active audio connection flags
    this.inAudioConference = false;
    this.inEchoTest = false;

    // state used for assisted type only
    this.currentAudioState = "closed";

    // If a connect request comes through when the state is in 'exiting' the connect request is stored in the
    // trigger 'connectAfterExitCompleted' and is executed after it is in 'closed' state
    this.connectAfterExitCompleted = null;

    // If an exit request comes through when the state is in 'opening' the exit request is stored in the
    // trigger 'exitAfterConnectCompleted' and is executed after it is in 'connected' state
    this.exitAfterConnectCompleted = null;

    // Internal flag to indicate that a reconnection attempt is in progress.
    this._reconnecting = false;

    // Internal tracking of the SIP connection call state 
    this._currentSessionState = null;

    // Internal flag to indicate the client is trying to hang up the call
    this._hangupFlag = false;

    // Internal variable to track the start time of the SIP connection attempt
    this._sessionStartTime = null;

    // TODO: Investigate implementation of Trickle ICE using this array
    this.validIceCandidates = [];

    // Define a global function to be used by the SIP implementation.
    window.isUnifiedPlan = isUnifiedPlan;
  }

  /**
   * Initialise the connection properties. This won't actually communicate any
   * data until the 'joinAudioConference' method is called for a specific stream.
   * @param {object} connectionProps Connection properties for the BBB meeting.
   * @param {string} connectionProps.remoteMediaServer The endpoint details that will be used for webRTC connections.
   * @param {string} connectionProps.meeting.sessionToken The BBB session token used for opening the media connection websocket.
   * @param {string} connectionProps.meeting.internalMeetingID BBB internal meeting ID.
   * @param {string} connectionProps.meeting.voiceBridge BBB voice bridge ID for audio conference.
   * @param {string} connectionProps.userId BBB user ID for the current meeting participant
   * @param {string} connectionProps.userName BBB user full name for the current meeting participant.
   * @param {object} connectionProps.connectionDetails STUN/TURN server information for routing webRTC.
   * @param {object} audioTag HTML5 <audio> element reference for rendering output.
   * @param {string} defaultInputDeviceId the device ID of input audio device to use.
   * @param {string} defaultOutputDeviceId the device ID of output audio device to use.
   */
  open(connectionProps, audioTag, defaultInputDeviceId = 'default', defaultOutputDeviceId = 'default') {
    return new Promise(async (resolve, reject) => {
      this.connectionProps = connectionProps;
      this.audioTag = audioTag;

      // Format the relay server information for webRTC requests
      this.connectionProps.audioStunTurnServers = this.formatStunTurnServers(connectionProps.connectionDetails);

      // Set up the audio context if we don't already have one.
      if (AudioStreamService.audioContext === null) {
        AudioStreamService.audioContext = await AudioStreamService.initAudioContext();
      }

      console.debug('[AudioStreamService] - open - defaultInputDeviceId', defaultInputDeviceId)

      // Choose the default speakers and microphone input to start with
      if (defaultInputDeviceId !== 'default') {
        await this.changeInputDevice(defaultInputDeviceId)
          .catch((err) => reject(formatErrorForDisplay(err, 'audio', 'setInputDevice')));
      } else {
        await this.setDefaultInputDevice()
          .catch((err) => reject(formatErrorForDisplay(err, 'audio', 'setInputDevice')));
      }

      if (defaultOutputDeviceId !== 'default') {
        await this.changeOutputDevice(defaultOutputDeviceId)
          .catch((err) => reject(formatErrorForDisplay(err, 'audio', 'setOutputDevice')));
      } else {
        await this.setDefaultOutputDevice()
          .catch((err) => reject(formatErrorForDisplay(err, 'audio', 'setOutputDevice')));
      }

      resolve();
    });
  }

  /**
   * Note this function is currently used for Dashboard to connect multiple students
   * This function is used by connectAssistedHandler and should not be called directly
   * Initialise the connection properties and
   * Start a two way audio connection using a SIP call made to the media server.
   * @param {object} connectionProps Connection properties for the BBB meeting.
   * @param {object} audioTag HTML5 <audio> element reference for rendering output.
   * @param {boolean} isListenOnly True if this connection is only receiving audio, not sending.
   * @param {string} extension Optional extension prefix - can be 'echo' for echo test server.
   * @param {string} inputDeviceId The input device to use for microphone audio.
   * @param {string} outputDeviceId The output device to use for speakers.
   * @param {function} Promise resolve
   * @param {function} Promise reject
   */
  connectAssisted(connectionProps, audioTag, isListenOnly, extension, resolve, reject,
    inputDeviceId = 'default', outputDeviceId = 'default') {
    this.currentAudioState = 'opening';
    this.open(connectionProps, audioTag, inputDeviceId, outputDeviceId)
      .then(() => this.start(isListenOnly, extension))
      .then(() => {
        this.currentAudioState = 'connected';
        if (this.exitAfterConnectCompleted !== null) {
          console.log("[AudioStreamService]: audio 'connected' and triggering exit after connect completed");
          this.exitAfterConnectCompleted();
          this.exitAfterConnectCompleted = null;
        }
        resolve({ status: this.currentAudioState, error: undefined });
      })
      .catch((err) => {
        this.currentAudioState = 'closed';
        reject({ status: this.currentAudioState, error: err });
      });
  }

  /**
   * Note this function is currently used for Dashboard to connect multiple students
   * It Initialises and Starts a SIP call based on the currentAudioState
   * @param {object} connectionProps Connection properties for the BBB meeting.
   * @param {object} audioTag HTML5 <audio> element reference for rendering output.
   * @param {boolean} isListenOnly True if this connection is only receiving audio, not sending.
   * @param {string} extension Optional extension prefix - can be 'echo' for echo test server.
   * @param {string} inputDeviceId The input device to use for microphone audio.
   * @param {string} outputDeviceId The output device to use for speakers.
   * @returns {Promise} Resolves when connection is started. Rejects on failure.
   */
  connectAssistedHandler(connectionProps, audioTag, isListenOnly, extension, inputDeviceId = 'default',
    outputDeviceId = 'default') {
    return new Promise((resolve, reject) => {
      switch (this.currentAudioState) {
        case 'closed':
          this.connectAssisted(connectionProps, audioTag, isListenOnly, extension, resolve, reject, inputDeviceId, outputDeviceId);
          break;
        case 'opening':
          this.exitAfterConnectCompleted = null;
          break;
        case 'connected':
          break;
        case 'exiting':
          console.log("[AudioStreamService]: inside (connectAssistedHandler) setting trigger to connect after exit completed");
          this.connectAfterExitCompleted = () => this.connectAssisted(
            connectionProps, audioTag, isListenOnly, extension, resolve, reject, inputDeviceId, outputDeviceId);
          break;
        default:
          console.log("[AudioStreamService]: inside (connectAssistedHandler) current audio state is unknown:", this.currentAudioState);
      }
    });
  }

  /**
   * Note this function is currently used for Dashboard to exit the connected audio
   * This function is used by exitAudioAssistedHandler and should not be called directly
   * End the audio conference.
   * @param {function} Promise resolve
   * @param {function} Promise reject
   */

  exitAudioAssisted(resolve, reject) {
    this.currentAudioState = 'exiting';
    this.exitAudio()
      .then(() => {
        this.currentAudioState = 'closed';
        // after exiting and closed if any pending connects trigger it
        if (this.connectAfterExitCompleted !== null) {
          console.log("[AudioStreamService]: in (exitAudioAssisted) audio 'closed' and triggering connect after exit completed");
          this.connectAfterExitCompleted();
          this.connectAfterExitCompleted = null;
        }
        resolve({ status: this.currentAudioState, error: undefined });
      })
      .catch((err) => {
        this.currentAudioState = 'connected';
        reject({ status: this.currentAudioState, error: err });
      });
  }

  /**
   * Note this function is currently used for Dashboard to exit the connected audio
   * This executes the exit based on the currentAudioState to end the conference
   * @returns {Promise} Resolves when call is successfully ended.
   */

  exitAudioAssistedHandler() {
    return new Promise((resolve, reject) => {
      switch (this.currentAudioState) {
        case 'opening':
          console.log("[AudioStreamService]: inside (exitAudioAssistedHandler) setting trigger to exit after connect completed");
          this.exitAfterConnectCompleted = () => this.exitAudioAssisted(resolve, reject);
          break;
        case 'connected':
          this.exitAudioAssisted(resolve, reject);
          break;
        case 'exiting':
        case 'closed':
          this.connectAfterExitCompleted = null;
          break;
        default:
          console.log("[AudioStreamService]: inside (exitAudioAssistedHandler) current audio state is unknown:", this.currentAudioState);
      }
    });
  }

  /**
   * Start a two way audio connection using a SIP call made to the media server.
   * @param {boolean} isListenOnly True if this connection is only receiving audio, not sending.
   * @param {string} extension Optional extension prefix - can be 'echo' for echo test server.
   * @returns {Promise} Resolves when connection is open or it was unable to succeed.
   */
  start(isListenOnly, extension) {
    return new Promise((resolve, reject) => {
      if (this.callActive) {
        return reject(formatErrorForDisplay(
          `Cannot start a new session: already connected to ${this.extension === 'echo' ? 'echo server' : 'conference'}`, 
          'audio', 
          'callActive', 
          {echoTest: this.extension === 'echo'}));
      }
      const { voiceBridge } = this.connectionProps.meeting;
      this.extension = extension;
      this.isListenOnly = isListenOnly;
      this.alreadyErrored = false;
      this.userRequestedHangup = false;

      const callExtension = extension ? `${extension}${voiceBridge}` : voiceBridge;

      // Starting call flags whether we are expecting to resolve/reject from the initial
      // start method call.
      this.startingCall = true;

      const callback = (message) => {
        // There will sometimes we erroneous errors put out like timeouts and improper shutdowns,
        // but only the first error ever matters
        if (this.alreadyErrored) {
          return;
        }

        if (message.status === 'failed') {
          this.alreadyErrored = true;
          this.callActive = false;
          if (this.startingCall) {
            console.error('[AudioStreamService]: Failed to start audio VOIP session', message);
            this.startingCall = false;
            return reject(formatErrorForDisplay(message, 'audio', 'sipSession', {code: message.error}));
          }
        } else if (message.status === 'connected') {

          // Update the stream information from the peer
          this.setRemotePeerInfo();

          const callbackSuccess = () => {
            this.audioCallJoinDelay = null;
            this.handleMediaChange('start');
            if (this.startingCall) {
              this.startingCall = false;
              return resolve(message);
            }
          };

          // Wait a couple of seconds before resolving, since we need to give the server time
          // to reach the correct state. 
          // TODO: Make this check smarter, possibly on server side.
          this.audioCallJoinDelay = setTimeout(callbackSuccess, audioSettings.audioJoinConnectionDelay);
        }
      };

      this.callback = callback;

      return this.doCall({
        callExtension,
        isListenOnly,
        inputStream: this.media.inputDevice.mediaStream
      })
        .catch((reason) => {
          this.callActive = false;
          this.startingCall = false;
          reject(reason);
        });
    });
  }

  /**
   * Open the SIP call to the media server given certain option settings.
   * @param {object} options The options array for this call
   * @param {string} options.callExtension The extension string to use
   * @param {boolean} options.isListenOnly True if this connection will receive only.AudioConnection
   * @param {object} options.inputStream The input audio stream selected from navigator.mediaDevices for sending.
   */
  doCall(options) {
    const {
      isListenOnly,
    } = options;

    const {
      userId,
      userName,
      audioStunTurnServers
    } = this.connectionProps;

    const callerIdName = [
      `${userId}_${this.getAudioSessionNumber()}`,
      'bbbID',
      isListenOnly ? `LISTENONLY-${userName}` : userName,
    ].join('-').replace(/"/g, "'");

    this.callerIdName = callerIdName;
    this.callOptions = options;
    this.callActive = true;
    return this.createUserAgent(audioStunTurnServers)
      .then(this.inviteUserAgent.bind(this));
  }

  /**
   * If the audio session is currently active but connected to the echo server (or another extension),
   * transfer the call to the main room conference.
   */
  transferToConference() {
    return new Promise((resolve, reject) => {

      if (!this.callActive) {
        return reject(`Inactive VOIP call cannot be transferred`);
      }

      const timeout = setTimeout(() => {
        this.exitAudio();
        this.callback({
          status: 'failed',
          error: 1008,
          bridgeError: 'Timeout on call transfer',
        });
        reject('REQUEST_TIMEOUT');
      }, audioSettings.callTransferTimeout);


      // For now, just assume we are successfully transfered after a few seconds
      // Todo: Check for Redis notification of voice call state.
      setTimeout(() => {
        clearTimeout(timeout);
        this.extension = null;
        this.handleMediaChange('start');
        resolve();
      }, 2000);

      // We should stop the audio while we are being transferred.
      this.handleMediaChange('stop');

      // This is is the call transfer tone code
      this.sendDtmf(1);
    });
  }

  /**
    * sendDtmf - send DTMF Tones using INFO message
    *
    * same as SimpleUser's dtmf
    */
  sendDtmf(tone) {
    const dtmf = tone;
    const duration = 2000;
    const body = {
      contentDisposition: 'render',
      contentType: 'application/dtmf-relay',
      content: `Signal=${dtmf}\r\nDuration=${duration}`,
    };
    const requestOptions = { body };
    return this.currentSession.info({ requestOptions });
  }

  /**
   * Create a new user agent for managing the SIP call.
   * @param {object} stun The STUN servers to use for peer connections.
   * @param {object} turn The TURN servers to use for peer connections.
   */
  createUserAgent({ stun, turn }) {
    const { remoteMediaServer, sessionToken } = this.connectionProps;
    return new Promise((resolve, reject) => {
      if (this.userRequestedHangup === true) reject();

      const { callerIdName } = this;

      if (this.userAgent && this.userAgent.isConnected()) {
        if (this.userAgent.configuration.hostPortParams === this.hostname) {
          resolve(this.userAgent);
          return;
        }
      }

      let userAgentConnected = false;
      this.userAgent = new UserAgent({
        uri: UserAgent.makeURI(`sip:${encodeURIComponent(callerIdName)}@${remoteMediaServer}`),
        transportOptions: {
          server: `wss://${remoteMediaServer}${streamSettings.bbbSipPath}?sessionToken=${sessionToken}`,
          connectionTimeOut: audioSettings.audioAgentConnectionTimeout,
          keepAliveInterval: streamSettings.websocketKeepAliveInterval,
          keepAliveDebounce: streamSettings.websocketKeepAliveDebounce,
          traceSip: false, // Whether to output debug SIP messages in browser console
        },
        sessionDescriptionHandlerFactoryOptions: {
          peerConnectionConfiguration: {
            iceServers: this.mapStunTurn({ stun, turn }),
            sdpSemantics: streamSettings.sdpSemantics,
          },
        },
        displayName: callerIdName,
        register: false,
        userAgentString: 'BigBlueButton',
        hackViaWs: false
      });

      const handleUserAgentConnection = () => {
        userAgentConnected = true;
        resolve(this.userAgent);
      };

      const handleUserAgentDisconnection = () => {
        this.callActive = false;
        this.handleMediaChange('stop');
        if (this.userAgent) {
          if (this.userRequestedHangup) {
            userAgentConnected = false;
            return;
          }

          let error;
          let bridgeError;

          if (!this._reconnecting) {
            console.log('SIP user agent disconnected: trying to reconnect...'
              + ` (userHangup = ${!!this.userRequestedHangup})`);

            this.reconnect().then(() => {
              console.log('SIP user agent successfully reconnected');
              this.callActive = true;
              this.handleMediaChange('start');
            }).catch(() => {
              if (userAgentConnected) {
                error = 1001;
                bridgeError = 'Audio SIP websocket disconnected';
              } else {
                error = 1002;
                bridgeError = 'Audio SIP websocket failed to connect';
              }

              this.stopUserAgent();

              this.callback({
                status: this.baseCallStates.failed,
                error,
                bridgeError,
              });
              reject('CONNECTION_ERROR');
            });
          }
        }
      };

      this.userAgent.transport.onConnect = handleUserAgentConnection;
      this.userAgent.transport.onDisconnect = handleUserAgentDisconnection;

      return this.userAgent.start().then(() => {
        console.log('SIP user agent successfully connected');

        window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));

        resolve();
      }).catch((error) => {
        console.log('SIP user agent failed to connect, reconnecting');

        const code = this.getErrorCode(error);

        // Websocket's 1006 is currently mapped to BBB's 1002
        if (code === 1006) {
          this.stopUserAgent();

          this.callback({
            status: 'failed',
            error: 1002,
            bridgeError: 'Websocket failed to connect',
          });
          return reject({
            type: 'CONNECTION_ERROR',
          });
        }

        this.reconnect().then(() => {
          console.log('SIP user agent succesfully reconnected');

          resolve();
        }).catch(() => {
          this.stopUserAgent();

          console.log('SIP user agent failed to reconnect after'
            + ` ${audioSettings.callReconnectionAttempts} attempts`);

          this.callback({
            status: 'failed',
            error: 1002,
            bridgeError: 'Websocket failed to connect',
          });

          reject({
            type: 'CONNECTION_ERROR',
          });
        });
      });

    });
  }

  reconnect(attempts = 1) {
    return new Promise((resolve, reject) => {
      if (this._reconnecting) {
        return resolve();
      }

      if (attempts > audioSettings.callReconnectionAttempts) {
        return reject({
          type: 'CONNECTION_ERROR',
        });
      }

      this._reconnecting = true;

      console.debug(`User agent reconnection attempt ${attempts}`);

      this.userAgent.reconnect().then(() => {
        this._reconnecting = false;
        resolve();
      }).catch(() => {
        setTimeout(() => {
          this._reconnecting = false;
          this.reconnect(++attempts).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }, audioSettings.callReconnectionDelay);
      });
    });
  }

  /**
   * Send an INVITE request to start the SIP call.
   * @param {object} userAgent The user agent managing the SIP call.
   */
  inviteUserAgent(userAgent) {
    return new Promise((resolve, reject) => {
      if (this.userRequestedHangup === true)
        reject();

      const { remoteMediaServer } = this.connectionProps;

      const { callExtension } = this.callOptions;

      this._sessionStartTime = new Date();

      const target = UserAgent.makeURI(`sip:${callExtension}@${remoteMediaServer}`);

      console.debug('[AudioStreamService] inviteUserAgent - inputDevice', this.media.inputDevice)
      const audioConstraints = this.media.inputDevice.id === 'default'
        ? true : {
          deviceId: {
            exact: this.media.inputDevice.id,
          }
        };

      const inviterOptions = {
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: audioConstraints,
            video: false,
          },
          iceGatheringTimeout: streamSettings.iceGatheringTimeout,
        },
        sessionDescriptionHandlerModifiersPostICEGathering: [
          stripMDnsCandidates,
          filterValidIceCandidates.bind(this, this.validIceCandidates),
        ],
        delegate: {
          onSessionDescriptionHandler:
            this.initSessionDescriptionHandler.bind(this),
        },
      };

      const inviter = new Inviter(userAgent, target, inviterOptions);
      this.currentSession = inviter;

      this.setupEventHandlers(inviter).then(() => {
        inviter.invite().then(() => {
          resolve();
        }).catch(e => reject(e));
      });
    });
  }

  /**
   * Setup the event handlers to attach to SIP call management.
   * @param {object} currentSession Session object for current user agent manager.
   * @returns {Promise} Resolves when call is negotiated.
   */
  setupEventHandlers(currentSession) {
    return new Promise((resolve, reject) => {
      if (this.userRequestedHangup === true) reject();

      let iceCompleted = false;
      let fsReady = false;
      let sessionTerminated = false;

      const setupRemoteMedia = () => {

        this.remoteStream = new MediaStream();

        this.currentSession.sessionDescriptionHandler
          .peerConnection.getReceivers().forEach((receiver) => {
            if (receiver.track) {
              this.remoteStream.addTrack(receiver.track);
            }
          });

        console.debug('Audio call - playing remote media');

        this.audioTag.srcObject = this.remoteStream;
        this.audioTag.play();
      };

      const checkIfCallReady = (peer) => {
        if (this.userRequestedHangup === true) {
          this.exitAudio();
          resolve();
        }
        if (iceCompleted) {
          this.webrtcConnected = true;
          setupRemoteMedia();
          this.callback({
            status: 'connected',
            peer,
          });
          resolve();
        }
      };

      // Sometimes FreeSWITCH just won't respond with anything and hangs. This timeout is to
      // avoid that issue
      const callTimeout = setTimeout(() => {
        this.callback({
          status: 'failed',
          error: 1006,
          bridgeError: `Call timed out on start (no answer) after ${audioSettings.callConnectTimeout / 1000}s`,
        });
        this.exitAudio();
      }, audioSettings.callConnectTimeout);

      let iceNegotiationTimeout;
      const handleSessionAccepted = () => {
        clearTimeout(callTimeout);

        // If ICE isn't connected yet then start timeout waiting for ICE to finish
        if (!iceCompleted) {
          iceNegotiationTimeout = setTimeout(() => {
            this.callback({
              status: 'failed',
              error: 1010,
              bridgeError: `Call timed out on start (ice timeout) after ${streamSettings.iceNegotiationTimeout / 1000}s`,
            });
            this.exitAudio();
          }, streamSettings.iceNegotiationTimeout);
        }

        checkIfCallReady();
      };

      const handleIceNegotiationFailed = (peer) => {
        clearTimeout(callTimeout);
        clearTimeout(iceNegotiationTimeout);
        this.callback({
          status: 'failed',
          error: 1007,
          bridgeError: `ICE negotiation failed. Current state - ${peer.iceConnectionState}`,
        });
      };

      const handleIceConnectionTerminated = (peer) => {
        if (!this.userRequestedHangup) {
          console.debug('SIP ICE connection closed');
        } else {
          return;
        }

        this.callback({
          status: 'failed',
          error: 1012,
          bridgeError: 'ICE connection closed. Current state -'
            + `${peer.iceConnectionState}`,
        });
      };

      const handleSessionProgress = (_update) => {
        this.currentSession.sessionDescriptionHandler.peerConnectionDelegate
          .onconnectionstatechange = (event) => {
            const peer = event.target;
            if (peer.connectionState === 'failed') {
              handleIceNegotiationFailed(peer);
            }
          };

        this.currentSession.sessionDescriptionHandler.peerConnectionDelegate
          .oniceconnectionstatechange = (event) => {
            const peer = event.target;

            switch (peer.iceConnectionState) {
              case 'completed':
              case 'connected':
                if (iceCompleted) {
                  console.debug('ICE connection success, but user is already connected'
                    + 'ignoring it...'
                    + `${peer.iceConnectionState}`);
                  return;
                }

                console.debug('ICE connection success. Current ICE Connection state - '
                  + `${peer.iceConnectionState}`);

                clearTimeout(callTimeout);
                clearTimeout(iceNegotiationTimeout);

                iceCompleted = true;

                checkIfCallReady();
                break;
              case 'failed':
                handleIceNegotiationFailed(peer);
                break;

              case 'closed':
                handleIceConnectionTerminated(peer);
                break;
              default:
                break;
            }
          };
      };

      const checkIfCallStopped = (message) => {
        if (fsReady || !sessionTerminated) return null;

        if (!message && !!this.userRequestedHangup) {
          return this.callback({
            status: 'ended',
          });
        }

        // if session hasn't even started, we let audio-modal to handle
        // any possile errors
        if (!this._currentSessionState) return false;

        let mappedCause;
        let cause;
        if (!iceCompleted) {
          mappedCause = '1004';
          cause = 'ICE error';
        } else {
          cause = 'Audio Conference Error';
          mappedCause = '1005';
        }

        console.warn(`Audio call terminated. cause=${cause}`);

        return this.callback({
          status: 'failed',
          error: mappedCause,
          bridgeError: cause,
        });
      };

      const handleSessionTerminated = (_message, _cause) => {
        clearTimeout(callTimeout);
        clearTimeout(iceNegotiationTimeout);

        sessionTerminated = true;

        checkIfCallStopped();
      };

      currentSession.stateChange.addListener((state) => {
        switch (state) {
          case SessionState.Initial:
            break;
          case SessionState.Establishing:
            handleSessionProgress();
            break;
          case SessionState.Established:
            handleSessionAccepted();
            break;
          case SessionState.Terminating:
            break;
          case SessionState.Terminated:
            handleSessionTerminated();
            break;
          default:
            console.warn('SIP.js unknown session state');
            break;
        }
        this._currentSessionState = state;
      });

      resolve();
    });
  }

  /**
   * End the audio conference.
   * @returns {Promise} Resolves when call is successfully ended.
   */
  exitAudio() {
    return new Promise((resolve, reject) => {
      let hangupRetries = 0;
      this._hangupFlag = false;

      this.userRequestedHangup = true;
      const { media } = this;

      // Remove any existing audioNodes information from the context
      if (AudioStreamService.audioContext) {
        if (media.inputDevice) {
          this.disconnectAllAudioNodesFromGraph(media.inputDevice.audioNodeReferences);
          media.inputDevice.source = null;
          media.inputDevice.audioNodeReferences = [];
        }
      }

      const tryHangup = () => {

        this.callActive = false;

        const hangupDetails = () => {
          this._hangupFlag = true;
          this.handleMediaChange("stop");
          return resolve();
        }

        if ((this.currentSession
          && (this.currentSession.state === SessionState.Terminated))
          || (this.userAgent && (!this.userAgent.isConnected()))) {
          return hangupDetails();
        }

        if (this.currentSession
          && (this.currentSession.state === SessionState.Establishing)) {
          this.currentSession.cancel().then(() => {
            return hangupDetails();
          });
        }

        if (this.currentSession
          && (this.currentSession.state === SessionState.Established)) {
          this.currentSession.bye().then(() => {
            return hangupDetails();
          });
        }

        if (this.userAgent && this.userAgent.isConnected()) {
          this.userAgent.stop();
          window.removeEventListener('beforeunload', this.onBeforeUnload);
        }

        hangupRetries += 1;

        setTimeout(() => {
          if (hangupRetries > audioSettings.callHangupMaxRetries) {
            this.callback({
              status: 'failed',
              error: 1006,
              bridgeError: 'Timeout on call hangup',
            });
            return reject('REQUEST_TIMEOUT');
          }

          if (!this._hangupFlag) return tryHangup();

          this.handleMediaChange('stop');

          return resolve();
        }, audioSettings.callHangupTimeout);
      };

      return tryHangup();
    });
  }

  /**
   * Set the default input audio device being used by the browser to the first
   * available microphone found.
   */
  setDefaultInputDevice() {
    const handleMediaSuccess = (mediaStream) => {
      const deviceLabel = mediaStream.getAudioTracks()[0].label;
      window.defaultInputStream = mediaStream.getTracks();
      return navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        console.debug('[AudioStreamService] - setDefaultInputDevice - enumerateDevices.then, mediaDevices',
          mediaDevices)
        const device = mediaDevices.find(d => d.label === deviceLabel);
        console.debug('AudioStreamService - setDefaultInputDevice - enumerateDevices.then, found device, label', device,
          deviceLabel)
        return this.changeInputDevice(device.deviceId, deviceLabel);
      });
    };

    const constraints = { audio: this.getMicrophoneAudioConstraints() };

    return navigator.mediaDevices.getUserMedia(constraints).then(handleMediaSuccess)
      .catch((error) => {
        console.error('[AudioStreamService] - on setDefaultInputDevice -> getUserMedia error', error,
          'constraints=', constraints);
        throw error;
      });
  }

  /**
   * Change the input audio device being used by the browser, updating the audio
   * source in place without ending the call.
   * @param {string} deviceId
   * @param {string} deviceLabel
   * @returns {Promise} Resolves when new call has been connected successfully.
   */
  changeInputDevice(deviceId, deviceLabel) {
    return new Promise( async (resolve, reject) => {
      try {
        const { media } = this;
        if (!media.hasOwnProperty('inputDevice')) {
          media.inputDevice = {};
        }
        console.debug('[AudioStreamService] - changeInputDevice - deviceId', deviceId);
        media.inputDevice.id = deviceId;
        media.inputDevice.label = deviceLabel;
  
        const constraints = {
          audio: this.getMicrophoneAudioConstraints(),
        };
  
        if (this.media.inputDevice.stream) {
          this.media.inputDevice.stream.getAudioTracks().forEach((t) => t.stop());
        }
  
        navigator.mediaDevices.getUserMedia(constraints)
          .then(this.updateAudioGraph.bind(this))
          .then(this.setInputStream.bind(this))
          .then(resolve)
          .catch((error) => {
            console.error('[AudioStreamService] - on changeInputDevice -> getUserMedia error', error,
              'constraints=', constraints);
            throw error;
          });

      } catch (error) {
        console.error('Failed to change input device (mic)', error);
        return reject('Failed to change input device (mic)');
      }
    });
  }

  /**
   * Set the input stream for the peer that represents the current session.
   * Internally, this will call the sender's replaceTrack function.
   * @param  {MediaStream}  stream The MediaStream object to be used as input
   *                               stream
   * @return {Promise}            A Promise that is resolved with the
   *                              MediaStream object that was set.
   */
   setInputStream(stream) {
     return new Promise( async (resolve, reject) => {
      try {
        if (!this.currentSession
          || !this.currentSession.sessionDescriptionHandler
        ) {
          return resolve(null);
        }
        
        if (this.currentSession.sessionDescriptionHandler.peerConnection) {
          await this.currentSession.sessionDescriptionHandler
          .setLocalMediaStream(stream); 
        }
  
        this.media.inputDevice.stream = stream;
  
        return resolve(stream);
      } catch (error) {
        console.error('Failed to set input stream (mic)', error);
        return reject('Failed to set input stream (mic)');
      }
     });
  }

  /**
   * Change the audio node graph to reflect a change to a new input device.
   * @param {MediaStream} stream The new media stream for microphone input
   * @returns {Promise<Object>} Resolves with media stream when graph has been updated
   */
  updateAudioGraph(stream) {
    const { media } = this;
    return new Promise((resolve, _reject) => {
      if (!stream) {
        return resolve(null);
      }

      if (!this.media.inputDevice) {
        this.media.inputDevice = {};
      }

      if (media.inputDevice.audioNodeReferences && media.inputDevice.audioNodeReferences.length) {
        // Remove any nodes linked to the chain from any previous input device
        this.disconnectAllAudioNodesFromGraph(media.inputDevice.audioNodeReferences);
      }

      // Set the new source of the graph to the new input stream
      media.inputDevice.source = AudioStreamService.audioContext.createMediaStreamSource(stream);

      // Set up initial audioNodes array
      let sourceNodeRef = {
        id: 'source',
        node: media.inputDevice.source,
      };
      media.inputDevice.audioNodeReferences = [
        sourceNodeRef
      ];

      this.initAudioWorkerNodes();
      return resolve(stream);
    });
  }

  /**
   * Set the default audio output device.
   */
  setDefaultOutputDevice() {
    return this.changeOutputDevice('default');
  }

  /**
   * Change the output device through which sound is playing.
   * @param {string} deviceId
   */
  async changeOutputDevice(deviceId) {
    const { audioTag, media } = this;

    if (audioTag.setSinkId) {
      try {
        //audioTag.srcObject = null;
        await audioTag.setSinkId(deviceId);

        if (audioTag && audioTag.readyState > 0) {
          // Reload the audio element
          audioTag.load();
        }

        media.outputDevice.id = deviceId;
        if (audioTag.srcObject) {
          media.outputDevice.stream = audioTag.srcObject;
        } else {
          media.outputDevice.stream = null;
        }
      } catch (err) {
        console.warn('MEDIA_ERROR', err);
        throw new Error('MEDIA_ERROR');
      }
    }
    return media.outputDevice.id || deviceId;
  }

  /**
   * Execute any handlers for starting or stopping the audio connection.
   * @param {string} type Either 'start', 'mute', 'unmute' or 'stop'.
   */
  handleMediaChange(type) {
    const { handlers, inAudioConference, inEchoTest, extension } = this;

    switch (type) {
      case 'start':
        // If there's an extension passed it means that we're joining the echo test first
        const joiningEchoServer = !!extension;
        if ((joiningEchoServer && !inEchoTest)
          || (!joiningEchoServer && !inAudioConference)) {
          this.inEchoTest = joiningEchoServer;
          this.inAudioConference = !joiningEchoServer;
          if ('onMediaStarted' in handlers) {
            for (const callbackFunc of handlers.onMediaStarted) {
              callbackFunc(!!extension);
            }
          }
        }
        break;

      case 'stop':
        if (inAudioConference || inEchoTest) {
          this.inAudioConference = false;
          this.inEchoTest = false;
          if ('onMediaStopped' in handlers) {
            for (const callbackFunc of handlers.onMediaStopped) {
              callbackFunc(!!extension);
            }
          }
        }
        break;

      default:
        break;
    }
  }

  /**
   * Register an external function handler to update the client when an event occurs
   * @param {string} eventType Can be one of onMediaStarted, onMediaStopped or OnError
   * @param {function} handlerFunc Callback function to be registered when that event occurs
   */
  registerHandler(eventType, handlerFunc) {
    if (eventType in this.handlers) {
      this.handlers[eventType].push(handlerFunc);
    } else {
      this.handlers[eventType] = [handlerFunc];
    }
  }

  /**
   * Unregister all external function handler to update the client when an event occurs
   */
  unregisterHandlers() {
    Object.keys(this.handlers).forEach((h) => {
      this.handlers[h] = [];
    });
  }

  /**
   * Remove a specific function handler of a given type
   * @param {string} eventType Can be one of onMediaStarted, onMediaStopped or OnError
   * @param {function} handlerFunc Callback function to be removed
   */
  unregisterHandler(eventType, handlerFunc) {
    const removeIndex = this.handlers[eventType].indexOf(handlerFunc);
    if (removeIndex >= 0) {
      this.handlers[eventType].splice(removeIndex, 1);
    }
  }

  /**
   * Handler for when the audio connection has dropped out for any reason, and the reconnect
   * attempts have expired. Sets up a timer for trying to reconnect.
   */
  tryReconnect() {
    setTimeout(() => {
      this.start(this.isListenOnly, this.extension, this.audioTag)
        .catch(() => {
          this.tryReconnect();
        });
    }, streamSettings.websocketConnectionTimeout);
  }

  /**
   * When we have successfully connected to the audio conference, update any held information
   * from the remote peer.
   */
  setRemotePeerInfo() {
    const { audioTag, media } = this;
    const { outputDevice } = media;
    outputDevice.stream = audioTag.srcObject;
  }

  /**
   * Set the audio call output volume in the browser to a new value.
   * This can only be called once the audio conference has successfully started.
   * @param {number} percent The desired output volume percent, from 0 to 100.
   */
  setOutputVolume(percent) {
    let { audioTag } = this;
    if (audioTag) {
      audioTag.volume = percent / 100.0;
    }
  }

  /**
   * Attach an audioWorklet processor handler to the input stream audio context chain, which
   * will execute whenever the microphone input changes.
   * @param {string} identifier A unique identifier for this handler
   * @param {function} processFunc The callback function on a change event
   * @param {boolean} reattachOnly If true, just reattach the handler without storing it as a new item
   */
  attachVolumeAudioWorklet(identifier, processFunc, reattachOnly = false) {
    const { inputDevice } = this.media;

    if (!reattachOnly) {
      // Store the function for later reattaching
      this.audioWorkletHandlers.input[identifier] = processFunc;
    }

    if (AudioStreamService.audioContext && inputDevice.audioNodeReferences.length) {

      if (AudioStreamService.hasAudioWorkletSupport(AudioStreamService.audioContext)) {
        // Create new audio node
        const newAudioNode = new AudioWorkletNode(AudioStreamService.audioContext, 'microphone-volume-processor', {
          processorOptions: {
            updateIntervalInMs: audioSettings.microphoneVolume.updateIntervalInMs,
            smoothingFactor: audioSettings.microphoneVolume.smoothingFactor,
          }
        });

        // An update message from the audio processor will contain the new volume value to use
        newAudioNode.port.onmessage = processFunc;

        // Set the state of the audio processor to active
        newAudioNode.port.postMessage({ changeState: 'running' });

        // Insert the new node into the chain
        this.addAudioNodeToEndOfGraph(inputDevice.audioNodeReferences, newAudioNode, identifier);
      }
    }
  }

  /**
   * Remove a given audioWorklet handler processor from the audio chain if found
   * @param identifier The identifier of the processor to remove
   */
  detachVolumeAudioWorklet(identifier) {
    const { inputDevice } = this.media;
    delete this.audioWorkletHandlers.input[identifier];

    // Set the processor to stopped state
    this.changeStateOfAudioWorkletNode(inputDevice.audioNodeReferences, identifier, 'stopped');

    // Remove the node from the connected graph
    if (!this.removeAudioNodeFromGraph(inputDevice.audioNodeReferences, identifier)) {
      console.warn(`[AudioStreamService]: Unable to detach worklet '${identifier}': not found in audio chain.`);
    }
  }

  /**
   * Insert a new audio node reference container between two existing nodes
   * @param {array} referenceArray The array of existing audioNodes to be updated
   * @param {object} newAudioNode The new audioNode to be inserted
   * @param {string} identifier The identifier for the new node reference
   */
  addAudioNodeToEndOfGraph(referenceArray, newAudioNode, identifier) {
    // Find the node at the end of the chain 
    let endNodeRef = referenceArray.find(n => !n.hasOwnProperty('next') || n.next.id === 'destination');

    // Store the node in a way that allows for later disconnecting
    const newNodeRef = {
      id: identifier,
      node: newAudioNode,
      prev: endNodeRef,
    }
    referenceArray.push(newNodeRef);

    // Insert the new node after the previous end one
    endNodeRef.node.connect(newAudioNode);
    if (endNodeRef.hasOwnProperty('next')) {
      endNodeRef.node.disconnect(endNodeRef.next.node);
      newAudioNode.connect(endNodeRef.next.node);
      newAudioNode.next = endNodeRef.next;
    }
    endNodeRef.next = newNodeRef;

    // Call resume on the audioContext in case the context is in a suspended state 
    // (https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio)
    AudioStreamService.audioContext.resume();
  }

  /**
   * Remove an audio node reference from the array of nodes, reconnecting the chain as needed
   * @param {array} referenceArray The array of existing audioNodes to be updated
   * @param {string} identifier The identifier for the node reference to be removed
   * @returns {boolean} True if an item was removed
   */
  removeAudioNodeFromGraph(referenceArray, identifier) {
    const removeIndex = referenceArray.findIndex(n => n.id === identifier);
    if (removeIndex >= 0) {

      // Find the nodes before/after the one to be removed
      const removeNodeRef = referenceArray[removeIndex];
      const prevNodeRef = removeNodeRef.prev;
      const nextNodeRef = removeNodeRef.next;

      // Disconnect the node being removed
      removeNodeRef.node.disconnect();

      // Disconnect the removed node, then relink to the subsequent node
      if (typeof prevNodeRef !== 'undefined') {
        prevNodeRef.node.disconnect(removeNodeRef.node);
        if (typeof nextNodeRef !== 'undefined') {
          prevNodeRef.node.connect(removeNodeRef.next.node);
          nextNodeRef.prev = prevNodeRef;
          prevNodeRef.next = nextNodeRef;
        } else {
          delete prevNodeRef.next;
        }
      } else if (typeof nextNodeRef !== 'undefined') {
        delete nextNodeRef.prev;
      }

      // Remove the disconnected node
      referenceArray.splice(removeIndex, 1);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Update the internal state of the audio worklet processor identified by the given id.
   * @param {array} referenceArray The array of audio nodes in the current graph
   * @param {string} identifier The identifier of the audio worklet node to be updated
   * @param {string} newState The new state code to send to the worklet processor
   * @returns 
   */
  changeStateOfAudioWorkletNode(referenceArray, identifier, newState) {
    // Find the audio worklet node to stop
    const stopIndex = referenceArray.findIndex(n => n.id === identifier);
    if (stopIndex >= 0) {
      const newAudioNode = referenceArray[stopIndex];
      // Set the state of the audio processor to stopped
      newAudioNode.node.port.postMessage({ changeState: newState });
    } else {
      console.warn(`[AudioStreamService]: in (stopAudioWorkletNode) node with id '${identifier}' not found for update to state ${newState}`);
    }
  }

  /**
   * Remove all the audio nodes from the given array in one operation.
   * @param {array} referenceArray The array of audio nodes to be modified
   */
  disconnectAllAudioNodesFromGraph(referenceArray) {
    for (const nodeRef of referenceArray) {
      nodeRef.node.disconnect();
      delete nodeRef.next;
      delete nodeRef.prev;
      delete nodeRef.node;
    }

    referenceArray.splice(1, referenceArray.length);
  }

  /**
   * When the audio input source has changed, reattach any worklet nodes to the graph.
   */
  initAudioWorkerNodes() {
    if (AudioStreamService.audioContext) {
      // Also reattach any audioworklets
      Object.keys(this.audioWorkletHandlers.input).forEach((id) => {
        this.attachVolumeAudioWorklet(id, this.audioWorkletHandlers.input[id], true);
      });
    }
  }

  /**
   *
   * @param {object} serversInfo Response from BBB containing STUN and TURN server info.
   * @param {array} serversInfo.turnServers Array of available TURN servers.
   * @param {array} serversInfo.stunServers Array of available STUN servers.
   * @returns {object} Split STUN and TURN server information formatted for media server.
   */
  formatStunTurnServers(serversInfo) {
    const turnServers = [];
    for (const turnEntry of serversInfo.turnServers) {
      const { password, url, username } = turnEntry;
      turnServers.push({
        urls: url,
        password,
        username,
      });
    }
    const stunServers = serversInfo.stunServers.map(server => server.url);
    return {
      stun: stunServers,
      turn: turnServers,
    };
  }

  /**
   * Check if the user's browser can support audioWorklet nodes
   * @param {object} audioContext A valid browser audioContext instance
   * @returns True if the browser supports audioWorklets
   */
  static hasAudioWorkletSupport(audioContext) {
    return audioContext.audioWorklet && typeof audioContext.audioWorklet.addModule === 'function';
  }

  /**
   * Set up audio context for audio input processing, if not already available.
   * This method will create a new audio context in the browser window, and register any worklets
   * necessary, returning the new context created in a resolved promise
   * @returns {Promise} Asynchronous promise that contains the resulting audioContext on success.
   */
  static initAudioContext() {
    var audioContext = new AudioContext();

    return new Promise((resolve, reject) => {
      if (AudioStreamService.hasAudioWorkletSupport(audioContext)) {
        audioContext.audioWorklet.addModule('/js/microphoneVolumeProcessor.js')
          .then(() => {
            resolve(audioContext);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        // Return a fulfilled promise because there is no delayed task to execute.
        return resolve(audioContext);
      }
    });
  }

  mapStunTurn = ({ stun, turn }) => {
    const rtcStuns = stun.map(url => ({ urls: url }));
    const rtcTurns = turn.map(t => ({ urls: t.urls, credential: t.password, username: t.username }));
    return rtcStuns.concat(rtcTurns);
  };

  stopUserAgent() {
    if (this.userAgent && (typeof this.userAgent.stop === 'function')) {
      return this.userAgent.stop();
    }
    return Promise.resolve();
  }

  /**
  * Get error code from SIP.js websocket messages.
  */
  getErrorCode = (error) => {
    try {
      if (!error) return error;

      const match = error.message.match(/code: \d+/g);

      const _codeArray = match[0].split(':');

      return parseInt(_codeArray[1].trim(), 10);
    } catch (e) {
      return 0;
    }
  };

  onBeforeUnload() {
    this.userRequestedHangup = true;
    return this.stopUserAgent();
  }

  initSessionDescriptionHandler(sessionDescriptionHandler) {
    /* eslint-disable no-param-reassign */
    sessionDescriptionHandler.peerConnectionDelegate = {
      onicecandidate:
        this.onIceCandidate.bind(this, sessionDescriptionHandler),
      onicegatheringstatechange:
        this.onIceGatheringStateChange.bind(this, sessionDescriptionHandler),
    };
    /* eslint-enable no-param-reassign */
  }

  onIceCandidate(sessionDescriptionHandler, event) {
    if (this.isValidIceCandidate(event)) {
      console.debug('Found a valid candidate from trickle ICE, finishing gathering');
      if (sessionDescriptionHandler.iceGatheringCompleteResolve) {
        sessionDescriptionHandler.iceGatheringCompleteResolve();
      }
    }
  }

  isValidIceCandidate(event) {
    return event.candidate
      && this.validIceCandidates
      && this.validIceCandidates.find((validCandidate) => (
        (validCandidate.address === event.candidate.address)
        || (validCandidate.relatedAddress === event.candidate.address))
        && (validCandidate.protocol === event.candidate.protocol));
  }

  onIceGatheringStateChange(_sessionDescriptionHandler, event) {
    const iceGatheringState = event.target
      ? event.target.iceGatheringState
      : null;

    if ((iceGatheringState === 'gathering') && (!this._iceGatheringStartTime)) {
      this._iceGatheringStartTime = new Date();
    }

    if (iceGatheringState === 'complete') {
      const secondsToGatherIce = (new Date()
        - (this._iceGatheringStartTime || this._sessionStartTime)) / 1000;

      console.debug(`ICE gathering candidates took (s): ${secondsToGatherIce}`);
    }
  }

  /**
   * Determine the audio constraints to be applied based on the current device selection
   * for input audio.
   * @returns {Object} Constraints object to be passed into the 'audio' property of
   *                   any webRTC media request
   */
  getMicrophoneAudioConstraints() {
    const audioDeviceConstraints = audioSettings.microphoneConstraints || {};
    let matchConstraints = this.filterSupportedConstraints(
      audioDeviceConstraints,
    );
    if (this.media.inputDevice.id) {
      matchConstraints.deviceId = { exact: this.media.inputDevice.id };
    }
    if (!matchConstraints) {
      matchConstraints = true;
    }
    return matchConstraints;
  }

  /**
   * Filter constraints set in audioDeviceConstraints, based on
   * constants supported by browser. This avoids setting a constraint
   * unsupported by browser. In currently safari version (13+), for example,
   * setting an unsupported constraint crashes the audio.
   * @param  {Object} audioDeviceConstraints Constraints to be set
   * see: https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
   * @return {Object}                        A new Object of the same type as
   * input, containing only the supported constraints.
   */
  filterSupportedConstraints(audioDeviceConstraints) {
    try {
      const matchConstraints = {};
      const supportedConstraints = navigator
        .mediaDevices.getSupportedConstraints() || {};
      Object.entries(audioDeviceConstraints).forEach(
        ([constraintName, constraintValue]) => {
          if (supportedConstraints[constraintName]) {
            matchConstraints[constraintName] = constraintValue;
          }
        }
      );

      return matchConstraints;
    } catch (error) {
      console.error('SIP.js unsupported constraint error', error);
      return {};
    }
  }

  /**
   * Obtain a sequential number to use for reconnections
   * @returns {number} The new sequential number to use.
   */
  getAudioSessionNumber() {
    let currItem = parseInt(sessionStorage.getItem(audioSettings.audioSessionNumKey), 10);
    if (!currItem) {
      currItem = 0;
    }
    currItem += 1;
    sessionStorage.setItem(audioSettings.audioSessionNumKey, currItem);
    return currItem;
  }

}
