import 'whatwg-fetch';
import AuthService from './AuthService';
import { MSG_404 } from '../constants/login';
import NetworkRequestError from "../utils/NetworkRequestError";
import { httpErrorMessage } from "../constants/errors";

export default class NetworkService {
  /**
   * @param {Response} resp
   * @param type
   */
  static async handleStatusCode(resp) {
    if (resp.status >= 400 && resp.status <= 599) {
      let errorMessage = '';
      switch (resp.status) {
        case 400:
          errorMessage = 'Sorry, the request was incorrect or corrupted. Please check the submission and try again.';
          break;
        case 401:
          errorMessage = 'Sorry, there is an error with your session. Please try logging in again.';
          break;
        case 403:
          errorMessage = 'Sorry, this information is restricted.';
          break;
        case 404:
          errorMessage = MSG_404;
          break;
        case 413:
        case 415:
          errorMessage = httpErrorMessage[resp.status];
          break;
        default: // 5xxs and 429s go here
          errorMessage = 'Sorry, the system had an issue with processing the request. Please try again.';
          break;
      }
      let actualResp;
      if (resp.headers.get('content-length') > 0) {
        actualResp = await resp.text();
        try {
          const actualRespJson = JSON.parse(actualResp);
          console.error(`${resp.status} error: ${actualRespJson}`);
        } catch(error) {
          // if response is not json, log actual error
          console.error(`${resp.status} error: ${actualResp}`);
        }
      } else {
        console.error(`${resp.status} error`);
      }
      throw new NetworkRequestError(resp.status, errorMessage, actualResp, resp);
    }
    return resp;
  }

  static get(route, abortSignal, type = 'application/json', handleResponse = true) {
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "GET",
      headers: {
        Accept: type,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      signal: abortSignal,
    }).then(resp => {
      if (handleResponse) {
        return this.handleStatusCode(resp);
      } else {
        return resp;
      }
    });
  }

  static getDownloadFile(route, filename) {
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "GET",
      headers: {
        Accept: "application/csv",
        Authorization: AuthService.getAuthorizationHeader(),
      },
    }).then(resp => {
      this.handleStatusCode(resp);
      this.handleDownloadFile(resp, filename);
    });
  }

  static async handleDownloadFile(resp, filename = 'downloadFile') {
    const blob = await resp.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  static post(route, payload, type = "application/json", abortSignal = null) {
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: type,
        "Content-Type": type,
        Authorization: AuthService.getAuthorizationHeader(),
      },
      signal: abortSignal,
    }).then(resp => this.handleStatusCode(resp));
  }

  static put(route, payload, abortSignal = null) {
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: AuthService.getAuthorizationHeader(),
      },
      signal: abortSignal,
    }).then(resp => this.handleStatusCode(resp))
      .then((resp) => { return resp.status === 204 ? resp.status : resp.json() } ); // if no content response just return the status
  }

  static getFileData(file, formParams) {
    let uploadForm = new FormData();
    if (file) {
      uploadForm.append('file', file);
    }
    if (formParams) {
      Object.keys(formParams).forEach(p => {
        uploadForm.append(p, formParams[p]);
      });
    }
    return uploadForm;
  }

  static putFile(route, file, formParams = null, abortSignal = null) {
    var uploadForm = this.getFileData(file, formParams);
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "PUT",
      body: uploadForm,
      headers: {
        Authorization: AuthService.getAuthorizationHeader()
      },
      signal: abortSignal,
    }).then(resp => this.handleStatusCode(resp));
  }

  static patch(route, payload, abortSignal = null) {
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: AuthService.getAuthorizationHeader(),
      },
      signal: abortSignal,
    }).then(resp => this.handleStatusCode(resp))
      .then((resp) => { return resp.status === 204 ? resp.status : resp.json() } ); // if no content response just return the status
  }

  static patchFile(route, file, formParams = null, abortSignal = null) {
    const uploadForm = this.getFileData(file, formParams);
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "PATCH",
      body: uploadForm,
      headers: {
        Authorization: AuthService.getAuthorizationHeader()
      },
      signal: abortSignal,
    }).then(resp => this.handleStatusCode(resp));
  }

  static delete(route, payload, abortSignal = null) {
    return fetch(process.env.REACT_APP_APPCONFIG_BACKEND_URL + route, {
      method: "DELETE",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: AuthService.getAuthorizationHeader(),
      },
      signal: abortSignal,
    }).then(resp => this.handleStatusCode(resp));
  }
}
