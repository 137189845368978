import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import Notice from '../notification/Notice';
import Section from '../Section';
import RoleService from '../../services/RoleService';
import { validateRequired } from '../../utils/validateFields';
import { ROLE_FIELDS } from '../../constants/roles';

const styles = {
  fieldsetContainer: {
    display: 'block',
    margin: '0 auto',
    zIndex: 1200,
  },
  margin: {
    margin: 1,
  },
  legend: {
    color: 'primary.main',
    margin: 1,
  },
};

class RoleForm extends React.Component {
  constructor(props) {
    super(props);
    const baseRole = {name: '', description: ''};
    this.state = {
      role: props.role ? props.role : baseRole,
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
      validationNotices: '',
    }
  }

  setRole = (event) => {
    let roleObj = this.state.role;
    roleObj[event.target.id] = event.target.value;
    this.setState({role: roleObj});
  };

  saveRole = async () => {
    try {
      this.props.setHasRoleUpdated(false);
      validateRequired(ROLE_FIELDS, this.state.role);
      await RoleService.saveRole(this.state.role);
      this.setState({
        hasRequestErrored: false,
        isRequestSuccessful: true,
        errorMessage: '',
      });
      this.props.setHasRoleUpdated(true);
    } catch (error) {
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        errorMessage: error.message,
      });
    }
  };

  render() {
    const { role, isRequestSuccessful, hasRequestErrored, errorMessage } = this.state;
    return (
      <Section>
        <FormControl component="fieldset" sx={styles.fieldsetContainer}>
          <FormLabel component="legend" sx={styles.legend}>Manage a role</FormLabel>
          {isRequestSuccessful &&
            <Notice noticeType="success">The role was updated successfully</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {Object.keys(ROLE_FIELDS).map((field) => {
            return (
              <TextField
                key={field}
                sx={styles.margin}
                id={field}
                label={ROLE_FIELDS[field].display}
                value={role[field]}
                onChange={this.setRole}
                required={ROLE_FIELDS[field].required}
                error={hasRequestErrored && ROLE_FIELDS[field].required && isEmpty(role[field])}
                size="medium"
                variant='outlined'
                fullWidth/>
            )
          })}
          <Box display='flex' justifyContent='flex-end'>
            <Button
              color='primary'
              variant='contained'
              onClick={this.saveRole}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

RoleForm.propTypes = {
  role: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  setHasRoleUpdated: PropTypes.func
};

export default RoleForm;
