const AUTH_MATERIALS = [{
	"id": "06b46437-062f-4f48-9065-09526ee04b40",
	"examSlotId": "06b46437-062f-4f48-9065-09526ee04b48",
	"examType": "SPECIALLY_PERMITTED",
	"answerSheets": 2,
	"permittedItems": [{
		"category": "Calculator",
		"description": "Faculty approved calculator only"
	}, {
		"category": "Text book",
		"description": "Statistics 101"
	}, {
		"category": "Note book",
		"description": "3 pages of handwritten notes"
	}]
}];

export default AUTH_MATERIALS;
