import { MESSAGE_TYPES } from '../../../constants/supervisorHandler';
import { addRoleAssignmentFromDashboardNotificationToCapabilityContextAccessList } from '../../../utils/addRoleAssignmentFromDashboardNotificationToCapabilityContextAccessList';

/**
 * Handles role assignment events that are received from the supervisor handler.
 * @param {String} messageType Event message type received from the supervisor handler
 * @param {Object} roleAssignment Role assignment that has been changed
 * @param {Function} getCapabilityContextAccess Getter function to retrieve the capability context access
 * @param {Function} setAuthStateCapabilityContextAccess Callback to set the capability context access
 */
export const onRoleAssignmentChangeHandler = (messageType, roleAssignment, getCapabilityContextAccess, setAuthStateCapabilityContextAccess) => {
  if (messageType === MESSAGE_TYPES.ROLE_ASSIGNED) {
    console.debug(`[AllocationHandler]: New role has been assigned in context ${roleAssignment.contextId}, updating capability context access`);
    const updatedAccess = addRoleAssignmentFromDashboardNotificationToCapabilityContextAccessList(getCapabilityContextAccess(), roleAssignment);
    setAuthStateCapabilityContextAccess(updatedAccess);
  } else {
    console.warn(`[AllocationHandler]: Unable to handle unknown role assignment change event of type ${messageType}`);
  }
}