import React from 'react';
import { Box, Typography } from '@mui/material';
import { Check, FiberManualRecord, Help, HourglassEmpty } from '@mui/icons-material';
import { EXAM_SESSION_PARTICIPANT_STATUS_LEGEND } from '../../constants/examSessions';

const styles = {
  toJoinStatusColour: {
    color: '#FBB847',
    paddingRight: 0.5,
    fontSize: '1rem',
  },
  activeStatusColour: {
    color: '#9EE493',
    paddingRight: 0.5,
    fontSize: '1rem'
  },
  inactiveStatusColour: {
    color: '#F98988',
    paddingRight: 0.5,
    fontSize: '1rem'
  },
  completeStatusColour: {
    color: '#638FF3',
    paddingRight: 0.5,
    fontSize: '1rem'
  },
  statusText: {
    paddingRight: 3,
  }
};

function ExamSessionParticipantStatusLegend(_props) {

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
      <HourglassEmpty sx={styles.toJoinStatusColour}/>
      <Typography variant="body2"
                  sx={styles.statusText}>{EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.TO_JOIN}</Typography>
      <FiberManualRecord sx={styles.activeStatusColour}/>
      <Typography variant="body2"
                  sx={styles.statusText}>{EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.ACTIVE}</Typography>
      <Help sx={styles.inactiveStatusColour}/>
      <Typography variant="body2"
                  sx={styles.statusText}>{EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.INACTIVE}</Typography>
      <Check sx={styles.completeStatusColour}/>
      <Typography variant="body2">{EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.COMPLETE}</Typography>
    </Box>
  );

}

export default ExamSessionParticipantStatusLegend;
