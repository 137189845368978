import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AccountBoxOutlined, UnfoldMoreOutlined, VideoLibraryOutlined } from '@mui/icons-material';
import IdVerificationView from '../content/IdVerificationView';
import MeetingTabs from '../content/MeetingTabs';
import ReviewPlayback from '../audioVisual/ReviewPlayback';

function ReviewMediaContainer(props) {
  const { examDetails, handleRecordingUpdate, handleSwitchRecording, viewMeeting } = props;
  const [currentTab, setCurrentTab] = useState('recording');

  const tabOptions = {
    recording: {
      icon: <VideoLibraryOutlined />,
      label: 'Session recording',
      sx: { flexGrow: 2 }
    },
    idVerification: {
      icon: <AccountBoxOutlined />,
      label: 'ID verification record',
      sx: { flexGrow: 2 }
    },
    collapseContainer: {
      ariaLabel: 'Collapse tab panel',
      icon: <UnfoldMoreOutlined />,
      sx: { flexGrow: 0 }
    },
  };

  return (
    <TabContext value={currentTab}>
      <TabList
        aria-label="View recording or ID media selector"
        onChange={(_event, option) => setCurrentTab(option)}
        variant="fullWidth"
      >
        {Object.keys(tabOptions).map(optionKey => {
          return (
            <Tab
              aria-label={tabOptions[optionKey].ariaLabel}
              icon={tabOptions[optionKey].icon}
              iconPosition="top"
              key={optionKey}
              label={tabOptions[optionKey].label}
              sx={tabOptions[optionKey].sx}
              value={optionKey}
            />
          )
        })}
      </TabList>
      <TabPanel value="recording">
        <Box
          display="flex"
          flexDirection="row-reverse"
        >
          <MeetingTabs
            meetings={examDetails?.meetings}
            viewMeeting={viewMeeting}
            onChange={handleSwitchRecording}
          />
          <ReviewPlayback
            slotId={examDetails?.id}
            meetingId={examDetails?.meetings?.[viewMeeting]?.id}
            authToken={examDetails?.mediaAccess?.token || null}
            handlePlaybackMeta={handleRecordingUpdate}
            recordingAvailable={examDetails?.meetings?.[viewMeeting]?.recordingsAvailable}
          />
        </Box>
      </TabPanel>
      <TabPanel value="idVerification">
        {/* to note: mui tabs unmount the components each time, so this component will retrieve the images
            every time the tab switches to it. Based on how this page is used, I don't think that will
            cause significant extra load, and the images have been kept, purposefully, very small. I also don't know
            if it would be nicer to refresh them or not, so I think for now we just keep an eye on it.
            Hopefully mui will provide an option, so we should keep track of that (although it has been 3 years since
            the issue was opened)
            Discussion: https://github.com/mui/material-ui/issues/21250
         */}
        <IdVerificationView examSlotId={examDetails?.id} withContextText={false} />
      </TabPanel>
      <TabPanel value="collapseContainer" sx={{ p: 0 }}></TabPanel>
    </TabContext>
  )
}

ReviewMediaContainer.propTypes = {
  examDetails: PropTypes.object,
  handleRecordingUpdate: PropTypes.func,
  handleSwitchRecording: PropTypes.func,
  viewMeeting: PropTypes.number,
};

export default ReviewMediaContainer;