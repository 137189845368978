import { find, has, isEmpty, remove } from 'lodash';

const toggleUpdatesList = (roleId, shouldBeAddedToList, updateDetails, updatesObj) => {
  if (shouldBeAddedToList) {
    // an object having an assignmentId determines whether it should be added or deleted via the api
    updatesObj[roleId].push(updateDetails);
  } else {
    // if a user has been removed and then added back - remove them from the list of changes
    remove(updatesObj[roleId], { value: updateDetails.value });
  }
};

// updatesList is passed by reference and is mutated by these functions
const addRemoveUsersFromAssignmentsUpdatesObj = (action, updateDetails, existingAssignments, roleId, updatesObj) => {
  if (isEmpty(updateDetails)) { return } // return early if there's nothing to change

  const originalAssignment = find(existingAssignments?.[roleId], { value: updateDetails?.value });
  const inUpdateList = find(updatesObj[roleId], { value: updateDetails?.value });
  let shouldBeAddedToList;
  switch (action) {
    case 'selectOption':
      shouldBeAddedToList = Boolean(!originalAssignment && !inUpdateList)
      toggleUpdatesList(roleId, shouldBeAddedToList, updateDetails, updatesObj);
      break;
    case 'removeOption':
      shouldBeAddedToList = Boolean(originalAssignment && !inUpdateList)
      toggleUpdatesList(roleId, shouldBeAddedToList, originalAssignment ? originalAssignment : updateDetails, updatesObj);
      break;
    default:
      // unknown option, do nothing
      break;
  }
};

const updateRoleAssignmentsToUpdateObj = (action, existingAssignments, roleId, roleAssignmentsSetToUpdate, updateDetails) => {
  let usersToUpdate = { ...roleAssignmentsSetToUpdate };

  if (!has(usersToUpdate, roleId)) {
    usersToUpdate[roleId] = [];
  }

  if (action === 'clear') {
    // replace everything for this role with removing the original assignments
    usersToUpdate[roleId] = [...existingAssignments?.[roleId] || []];
  } else {
    addRemoveUsersFromAssignmentsUpdatesObj(action, updateDetails, existingAssignments, roleId, usersToUpdate);
  }
  isEmpty(usersToUpdate[roleId]) && delete usersToUpdate[roleId];

  return usersToUpdate;
}


export default updateRoleAssignmentsToUpdateObj;