import NetworkService from './NetworkService';
import MOCK from "./mock";
import mockRequest from "../utils/mockRequest";

export default class CapabilityService {

    static async getCapabilities(abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(MOCK.CAPABILITIES);
        }
        const response = await NetworkService.get(`/capabilities`, abortSignal);
        return await response.json();
    }

}
