import { default as onGatewayDisconnected } from './onGatewayDisconnected';
import { default as onGatewayReconnected } from './onGatewayReconnected';
import { default as onMeetingTerminate } from './onMeetingTerminate';
import { default as onMeetingUpdate } from './onMeetingUpdate';
import { default as onRemoteAudioChange } from './onRemoteAudioChange';
import { default as onRemoteParticipantVideoChange } from './onRemoteParticipantVideoChange';
import { default as onScreenTypeUpdate } from './onScreenTypeUpdate';
import onVideoError from './onVideoError';
import onVideoShare from './onVideoShare';
import onVideoUnshare from './onVideoUnshare';

const handlers = {
  onGatewayDisconnected,
  onGatewayReconnected,
  onMeetingTerminate,
  onMeetingUpdate,
  onRemoteAudioChange,
  onRemoteParticipantVideoChange,
  onScreenTypeUpdate,
  onVideoError,
  onVideoShare,
  onVideoUnshare,
};

export default handlers;