import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const MEETING_STATES = {
  connecting: {
    backgroundGradientPalette: 'warning',
    displayOrder: 'column',
    textComponent: 'connectingText',
  },
  waiting: {
    backgroundGradientPalette: 'popBlue',
    bannerText: <>You <strong>must</strong> leave this tab open for the duration of the exam.</>,
    displayOrder: 'column',
    textComponent: 'waitingText',
  },
  active: {
    backgroundGradientPalette: 'popPurple',
    bannerText: <>You <strong>must</strong> leave this tab open for the duration of the exam.</>,
    displayOrder: 'column',
    textComponent: 'activeWithOnboarderText',
  },
  activeSelfOnboard: {
    backgroundGradientPalette: 'popPurple',
    bannerText: <>You <strong>must</strong> leave this tab open for the duration of the exam.</>,
    displayOrder: 'column',
    textComponent: 'activeSelfOnboardText',
  },
  finished: {
    imgUrl: '/images/geometric-gradient-blue-white-pink-banner.jpg',
    displayOrder: 'column',
    textComponent: 'finishedText',
  },
  submitted: {
    imgUrl: undefined,
    backgroundColor: '#019688',
    bannerIcon: <CheckCircleOutlineIcon sx={{ fontSize: '9rem', fill: "white"}} />,
    displayOrder: 'column',
    textComponent: 'submittedText',
  },
  terminated: {
    imgUrl: '/images/geometric-gradient-blue-white-pink-banner.jpg',
    displayOrder: 'column',
    textComponent: 'terminatedText',
  },
};

export const MEETING_STATE_KEYS = {
  CONNECTING: 'connecting',
  WAITING: 'waiting',
  ACTIVE: 'active',
  FINISHED: 'finished',
  SUBMITTED: 'submitted',
  TERMINATED: 'terminated',
};

export const MEETING_ENDED_STATES = [
  MEETING_STATE_KEYS.FINISHED,
  MEETING_STATE_KEYS.TERMINATED,
];

export const PRIVACY_WEBSITE_URL = 'https://www.monash.edu/privacy-monash';
export const PRIVACY_COLLECTION_URL = 'https://www.monash.edu/__data/assets/pdf_file/0011/1595270/Student-Data-Management-and-Privacy-Collection-Statement.pdf';
export const PRIVACY_PROCEDURE_URL = 'https://www.monash.edu/__data/assets/pdf_file/0003/790086/Privacy.pdf';
