import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import GreenSwitch from './GreenSwitch';
import Notice from '../notification/Notice';
import Section from '../Section';
import UserService from '../../services/UserService';
import { validateRequired } from '../../utils/validateFields';
import { USER_FIELDS } from '../../constants/users';

const styles = {
  fieldsetContainer: {
    display: 'block',
    margin: '0 auto',
    zIndex: 1200,
  },
  margin: {
    margin: 1,
  },
  marginWithHelper: {
    margin: 1,
    mb: 0,
  },
  legend: {
    color: 'primary.main',
    margin: 1,
  },
  switchLegend: {
    color: 'primary.main',
    marginRight: 1,
  },
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    const baseUser = {userName: '', fullName: '', legalName: '', externalId: '', isDisabled: false, shiftStaff: false};
    this.state = {
      user: props.user ? props.user : baseUser,
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
      validationNotices: '',
    }
  }

  setUser = (event) => {
    let userObj = this.state.user;
    if(event.target.id === 'isDisabled' ||  event.target.id === 'shiftStaff') {
      userObj[event.target.id] = !userObj[event.target.id];
    }
    else {
      userObj[event.target.id] = event.target.value;
    }
    this.setState({ user: userObj });
  };

  saveUser = async () => {
    this.props.setHasUserUpdated(false);
    try {
      validateRequired(USER_FIELDS, this.state.user);
      await UserService.saveUser(this.state.user);
      this.setState({
        hasRequestErrored: false,
        isRequestSuccessful: true,
        errorMessage: '',
      });
      this.props.setHasUserUpdated(true);
    } catch(error) {
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        errorMessage: error.message,
      });
    }
  };

  render() {
    const { user, isRequestSuccessful, hasRequestErrored, errorMessage } = this.state;

    return (
      <Section>
        <FormControl component="fieldset" sx={styles.fieldsetContainer}>
          <FormLabel component="legend" sx={styles.legend}>Manage user account</FormLabel>
          {isRequestSuccessful &&
            <Notice noticeType="success">The user was updated successfully</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {Object.keys(USER_FIELDS).filter(field => USER_FIELDS[field].type === 'textbox').map((field) => {
            const showDescription = USER_FIELDS[field].addDescription && !user.id
            return (
              <React.Fragment key={field}>
                <TextField
                  key={field}
                  sx={showDescription ? styles.marginWithHelper : styles.margin}
                  id={field}
                  label={USER_FIELDS[field].display}
                  value={user[field]}
                  onChange={this.setUser}
                  required={USER_FIELDS[field].required}
                  error={hasRequestErrored && USER_FIELDS[field].required && isEmpty(user[field])}
                  size="medium"
                  variant='outlined'
                  fullWidth />
                  {showDescription &&
                    <Box mx={0.5} mb={1}><FormHelperText>{USER_FIELDS[field].addDescription}</FormHelperText></Box>
                  }
              </React.Fragment>
            )
          })}
          {Object.keys(USER_FIELDS).filter(field => USER_FIELDS[field].type === 'toggle').map((field => {
            return (
              <div key={field}>
                <FormControl component="fieldset" sx={styles.margin}>
                  <Box display="flex" alignItems="center">
                    <FormLabel component="legend" sx={styles.switchLegend}>{USER_FIELDS[field].display}</FormLabel>
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={field === 'isDisabled' ? !user[field] : user[field]}
                          onChange={this.setUser}
                          name={field}
                          id={field}
                          inputProps={{ 'aria-label': USER_FIELDS[field].description }}
                        />}
                      label={user[field] ? USER_FIELDS[field].on : USER_FIELDS[field].off}
                      labelPlacement="end"
                    />
                </Box>
                  <FormHelperText sx={{ marginLeft: 0 }}>{USER_FIELDS[field].description}</FormHelperText>
                </FormControl>
              </div>
            )
          }))}
          <Box display='flex' justifyContent='flex-end'>
            <Button
              color='primary'
              variant='contained'
              onClick={this.saveUser}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

UserForm.propTypes = {
  user: PropTypes.shape({
    userName: PropTypes.string,
    fullName: PropTypes.string,
    externalId: PropTypes.string,
    isDisabled: PropTypes.bool,
  }),
  setHasUserUpdated: PropTypes.func,
};

export default UserForm;
