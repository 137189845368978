import { isEmpty } from 'lodash';
import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import { getFullDayDatesObj, mapUrlParams } from '../utils/mapParams';

export default class AllocationErrorService {

  /**
   * Search for matching allocation error records from the eVigilation back-end
   * @param {Date} onDate The date on which errors should be retrieved
   * @param {string} poolId Pool uuid for which errors should be retrieved
   * @param {array} statusList Optional list of error status to filter on
   * @param {object} abortSignal Object that signals the request should be aborted
   * @returns JSON response if successful
   */
  static async searchForAllocationErrors(onDate, poolId, statusList, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.ALLOCATION_ERRORS);
    }

    const response = await NetworkService.get(
      `/allocation-errors?${this.composeSearchParameterString(onDate, poolId, statusList)}`,
      abortSignal);
    return await response.json();
  }

  /**
   * Update allocation error status
   * @param {string} examSlotId The exam slot UUID for error to be updated
   * @param {string} newStatus Status value to use for the error
   * @param {object} abortSignal Object that signals the request should be aborted
   * @returns JSON response if successful
   */
   static async updateStatus(examSlotId, newStatus, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.ALLOCATION_ERRORS);
    }

    return await NetworkService.patch(
      `/allocation-errors/` + encodeURIComponent(examSlotId),
      {status: newStatus},
      abortSignal
    );
  }

  /**
   * Compose url parameter string for allocation pool error requests
   * @param {Date} onDate The date for which errors should be retrieved
   * @param {string} poolId The uuid of the allocation pool
   * @param {array} statusList Optional status to filter results on
   * @returns Url parameter string
   */
  static composeSearchParameterString(onDate, poolId, statusList) {
    const dateTimes = onDate && getFullDayDatesObj(onDate);
    const params = {
      ...(dateTimes && { startDate: dateTimes.startDateTime, endDate: dateTimes.endDateTime }),
      ...(poolId && { poolId }),
      ...(!isEmpty(statusList) && { status: statusList.join(',') })
    }
    return isEmpty(params) ? null : mapUrlParams(params, undefined);
  }

}