import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import SecurityShield from '../../icons/SecurityShield'
import RecordingOn from '../../icons/RecordingOn'

function RecordingNotice(props) {
  return (
    <Box display="flex" pt={2}>
      <RecordingOn sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }} />
      <SecurityShield sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }} />
      <Typography variant="body1">
        Your exam session is now being recorded
        {props.showSupervisorText &&
          <> and your online supervisor is monitoring you, even if you can’t see them. </>
        }
      </Typography>
    </Box>
  )
}

RecordingNotice.propTypes = {
  showSupervisorText: PropTypes.bool,
}

export default RecordingNotice;
