import { find, isEmpty } from 'lodash';
import AppOptionService from '../services/AppOptionService';
import CONTENT_OVERRIDES from '../constants/contentOverrides';
import { FEATURE_TOGGLES } from '../constants/featureToggles';

const getAuthorisedFeatures = (options) => {
  let featuresObj = {};
  Object.keys(FEATURE_TOGGLES).forEach(toggle => {
    const toggleName = FEATURE_TOGGLES[toggle];
    const toggleFromDb = find(options, ['name', toggleName]);
    // Unless the feature is set to specifically true as a string, do not show feature
    featuresObj[toggleName] = (!isEmpty(toggleFromDb) && toggleFromDb.value === 'true');
  });
  return featuresObj;
}

const getThemeOverrides = (config) => {
  const themeOption = find(config, ['name', 'theme']);
  try {
    return JSON.parse(themeOption.content);
  } catch(error) {
    console.warn('Invalid theme overrides.  Reverting to default theme', error);
    return undefined;
  }
}

const getContentOverrides = (config) => {
  const overridesObj = {};
  CONTENT_OVERRIDES.forEach((override) => {
    const currentOverride = find(config, ['name', override]);
    if(currentOverride?.['content-type'] === "application/json") {
      try {
        overridesObj[override] = JSON.parse(currentOverride.content);
      } catch(error) {
        console.warn(`Invalid content override for ${override}.  Using default content.`, error);
      }
    }
  });
  return overridesObj;
}

const getOptionsData = async () => {
  const controller = new AbortController();
  try {
    const options = await AppOptionService.getAllOptions(
      controller.signal
    );
    // expand response if other options needed
    return getAuthorisedFeatures(options);
  } catch(error) {
    return({});
  }
}

const getConfigData = async () => {
  const controller = new AbortController();
  try {
    return await AppOptionService.getClientConfig(controller.signal);
  } catch(error) {
    return({});
  }
}

const getAppOptions = async () => {
  const clientConfig = await getConfigData();
  const appOptions = {};
  appOptions['features'] = await getOptionsData();
  appOptions['themeOverrides'] = getThemeOverrides(clientConfig);
  appOptions['contentOverrides'] = getContentOverrides(clientConfig);
  return appOptions;
}

export default getAppOptions;
