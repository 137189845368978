import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { cloneDeep, has } from 'lodash';

import { retrieveChatMessages } from './helper/ChatMessages';
import { AuthConsumer, authContext } from '../authContext';
import Can, { CanThey } from '../components/Can';
import Section from '../components/Section';
import ReviewMediaContainer from '../components/container/ReviewMediaContainer';
import ReviewData from '../components/content/ReviewData';
import ReviewHeader from '../components/content/ReviewHeader';
import ScrollButtons from '../components/form/ScrollButtons';
import Notice from '../components/notification/Notice';
import ExamSessionService from '../services/ExamSessionService';
import RoomScanService from '../services/RoomScanService';
import { getStudentUser } from '../utils/getExamSlotUser';

const styles = {
  sectionRoot: {
    padding: 0,
  },
};

function ReviewSession(_props) {
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [examDetails, setExamDetails] = useState();
  const [hasRequestErrored, setHasRequestErrored] = useState(false);
  const [examSlotChats, setExamSlotChats] = useState([]);
  const [meetingMeta, setMeetingMeta] = useState({});
  const [requestPending, setRequestPending] = useState(true);
  const [roomScanData, setRoomScanData] = useState({});
  const [studentDetails, setStudentDetails] = useState();
  const [viewMeeting, setViewMeeting] = useState(0);
  const controllerRef = useRef(new AbortController());
  const { capabilityContextAccess } = useContext(authContext);

  const getExamDetails = useCallback(async (refreshOnly = false) => {
    const slotId = params?.slotId;
    setRequestPending(true);
    setHasRequestErrored(false);
    setErrorMessage('');

    Promise.all([
      ExamSessionService.getExamSessionSummary(slotId, controllerRef?.current?.signal)
    ])
      .then((result) => {
        if (result?.[0]?.mediaAccess?.expires) {
          const expiryTime = new Date(result[0].mediaAccess.expires);
          const msToExpiry = expiryTime - new Date();
          if (msToExpiry > 5000) {
            setTimeout(() => getExamDetails(true), msToExpiry);
          }
        }
        setExamDetails(result?.[0]);
        setStudentDetails(getStudentUser(result?.[0]));
      })
      .catch((error) => {
        if (!controllerRef.current.signal.aborted) {
          console.error(`Unable to ${refreshOnly ? 'refresh' : 'get'} exam slot details`, error);
          setHasRequestErrored(true);
          setErrorMessage(error.message);
        }
      })
      .finally(() => {
        if (!controllerRef.current.signal.aborted) {
          setRequestPending(false);
        }
      });
  }, [params?.slotId, setErrorMessage, setExamDetails, setHasRequestErrored, setRequestPending, setStudentDetails]);

  const getRoomScanDetails = useCallback(async () => {
    const slotId = params?.slotId;
    try {
      const roomScanData = await RoomScanService.getRoomScanData(slotId, controllerRef?.current?.signal);
      setRoomScanData(roomScanData);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Failed to fetch room scan status information', error);
        setRoomScanData({});
      }
    }
  }, [params?.slotId, setRoomScanData]);

  useEffect(() => {
    controllerRef.current = new AbortController();
    const controller = controllerRef.current;
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (CanThey(capabilityContextAccess, false, 'REVIEW_EXAM')
      || CanThey(capabilityContextAccess, false, 'MANAGE_INTEGRITY')
      || CanThey(capabilityContextAccess, false, 'VIEW_INTEGRITY')
    ) {
      getExamDetails();
      getRoomScanDetails();
    }
  }, [capabilityContextAccess, getExamDetails, getRoomScanDetails]);

  useEffect(() => {
    if (examDetails) {
      retrieveChatMessages(examDetails, controllerRef?.current?.signal)
        .then(chatMessages => setExamSlotChats(chatMessages))
        .catch(err => console.error('[ReviewSession]: Failed to retrieve chat messages'));
    }
  }, [examDetails]);

  const handleChangeTab = (_event, newValue) => {
    setViewMeeting(newValue);
    setMeetingMeta({});
  };

  const handleRecordingMetaUpdate = (metaDetails) => {
    let updatedMeta = cloneDeep(meetingMeta);
    updatedMeta[viewMeeting] = metaDetails;
    setMeetingMeta(updatedMeta);
  };

  const getIntegrity = (examSessionSummary) => {
    if (!examSessionSummary) {
      return undefined;
    }

    return {
      context: examSessionSummary.context,
      overrideDeletionDate: examSessionSummary.overrideDeletionDate,
      cannotExpire: examSessionSummary.cannotExpire,
      integrityReview: examSessionSummary.integrityReview,
    }
  }

  const getReviewPageContent = (examSlotId) => {

    // Check for whether any meetings have started, otherwise there is nothing to review.
    let sessionHasStarted = false;
    if (examDetails && has(examDetails, 'meetings') && !!examDetails?.meetings.length) {
      sessionHasStarted = true;
    }

    return (
      <>
        <ReviewHeader
          examDetails={examDetails}
          studentDetails={studentDetails}
          roomScanDetails={roomScanData}
        />
        {sessionHasStarted ?
          <>
            <Box
              position="sticky"
              top="64px"
              zIndex="1"
              bgcolor="white"
              borderBottom="1px solid silver"
            >
              <ReviewMediaContainer
                examDetails={examDetails}
                handleRecordingUpdate={handleRecordingMetaUpdate}
                handleSwitchRecording={handleChangeTab}
                viewMeeting={viewMeeting}
              />
            </Box>
            <ReviewData
              flags={examDetails?.flags}
              meetings={examDetails?.meetings}
              investigations={getIntegrity(examDetails)}
              examSlotId={examSlotId}
              examContextId={examDetails?.context?.id}
              chats={examSlotChats}
            />
          </>
          : <Notice noticeType="error">Session has not been started</Notice>
        }
      </>
    );
  }

  return (
    <ScrollButtons>
      <Section sx={styles.sectionRoot}>
        {!params?.slotId && <Notice noticeType="error">Please select an exam slot to review.</Notice>}
        {hasRequestErrored && <Notice noticeType="error">{errorMessage}</Notice>}
        {!hasRequestErrored && !requestPending && params?.slotId && examDetails &&
          <AuthConsumer>
            {({ capabilityContextAccess }) => (
              <Can
                capabilityContextAccess={capabilityContextAccess}
                contextRequired={true}
                perform={['REVIEW_EXAM', 'MANAGE_INTEGRITY', 'VIEW_INTEGRITY']}
                data={{ id: examDetails?.context?.id }}
                yes={() => (getReviewPageContent(params?.slotId))}
                no={() => (<Notice noticeType="error">Sorry, you do not have access to this page</Notice>)}
              />
            )}
          </AuthConsumer>
        }
      </Section>
    </ScrollButtons>
  );
}

export default ReviewSession;
