import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { SCREEN_SHARE_TYPES } from '../../constants/mediaStates';

function ScreenTypeIndicator(props) {
  const { screenType } = props;
  return (
    <Box
      display="flex"
      gap={1}
      position="absolute"
      bottom={0}
      alignItems="center"
      sx={{ fontVariant: "small-caps", borderRadius: 1, bgcolor: 'rgba(80, 80, 80, 0.6)', px: 1, py: 0.5, zIndex: 1 }}
    >
      <Circle fontSize="8px" sx={{ color: SCREEN_SHARE_TYPES[screenType]?.color }}/>
      <Box>{SCREEN_SHARE_TYPES[screenType]?.display || 'unknown screen'}</Box>
    </Box>
  )
}

ScreenTypeIndicator.propTypes = {
  screenType: PropTypes.string,
};

export default ScreenTypeIndicator;