import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, CardMedia, CircularProgress, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { isEmpty } from 'lodash';

import Notice from '../notification/Notice';
import { MSG_404 } from '../../constants/login';


const styles = {
  imageViewer: {
    zIndex: 1200,
  },
  dialogContainer: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  viewedImage: {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
  hiddenImage: {
    height: 'auto',
    display: 'none',
  },
  closeButton: {
    marginLeft: 'auto',
    color: 'grey[500]',
  }
};

function ImageFromDataPopup(props) {

  const { open, onClose, imageRetriever, imageAlt, imageTitle, imageText } = props;

  const [loaded, setLoaded] = useState(false);
  const [imageError, setImageError] = useState('');
  const [image, setImage] = useState('');

  const controllerRef = useRef(new AbortController());

  const getImage = useCallback(() => {
    setImageError('');
    setLoaded(false);

    imageRetriever(controllerRef.current.signal)
      .then((blobData) => {
        const imageFromApi = URL.createObjectURL(blobData);

        setImage(imageFromApi);
        setLoaded(true);
      })
      .catch((error) => {
        if (!controllerRef.current?.signal?.aborted) {
          console.error(`Unable to download image: `, error);
          const imageError = error?.message === MSG_404 ? 'Image not found' : 'Unable to download image';
          setImageError(imageError);
        }
      });
  }, [imageRetriever]);

  useEffect(() => {
    const controller = controllerRef.current;
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (open) {
      getImage()
    }
  }, [open, getImage]);

  return (
    <Dialog
      maxWidth="lg"
      scroll="paper"
      open={open}
      onClose={onClose}
      aria-labelledby="image-viewer-title"
      sx={styles.imageViewer}
    >
      <DialogContent sx={styles.dialogContainer}>
        <Card>
          <CardContent sx={{ p: 0 }}>
            <Box width="100%" display="flex" >
              <IconButton
                sx={styles.closeButton}
                onClick={onClose}
                aria-label="Close this preview"
                size="large">
                <Close />
              </IconButton>
            </Box>
          </CardContent>
          {!isEmpty(imageError) &&
            <Notice noticeType='error'>{imageError}</Notice>
          }
          {isEmpty(imageError) && !loaded &&
            <Stack alignItems="center"><CircularProgress /></Stack>
          }
          <CardMedia
            component="img"
            sx={loaded ? styles.viewedImage : styles.hiddenImage}
            src={image}
            title={imageAlt}
          />
          <CardContent>
            <Typography gutterBottom variant="h2">
              {imageTitle}
            </Typography>
            <Typography variant="body1" component="p">
              {imageText}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

ImageFromDataPopup.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  imageRetriever: PropTypes.func.isRequired,
  imageTitle: PropTypes.string,
  imageText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

export default ImageFromDataPopup;
