import React from 'react';
import Typography from '@mui/material/Typography';
import SafeInnerHtml from '../SafeInnerHtml';

const OrderedStepsList = ({ steps }) => {
  return (
    <Typography variant="body1" component="ol" sx={{ paddingInlineStart: 3 }}>
      {Object.keys(steps).sort().map((key) => {
        return (
          <Typography key={key} variant="body1" component="li" sx={{ mb: 1 }}>
            <SafeInnerHtml htmlString={steps[key]} />
          </Typography>
        )
      })}
    </Typography>
  );
};

export default OrderedStepsList;