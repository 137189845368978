import AuthorisedMaterialsStep from './AuthorisedMaterialsStep';
import ExamStartStep from './ExamStartStep';
import IdVerificationAnalysisStep from './IdVerificationAnalysisStep';
import IdVerificationInfoStep from './IdVerificationInfoStep';
import IdVerificationStep from './IdVerificationStep';
import MediaConnectStep from './MediaConnectStep';
import MediaConnectInfoStep from './MediaConnectInfoStep';
import OpenExamGateStep from './OpenExamGateStep';
import RoomScanStep from './RoomScanStep';
import TermsConditionsStep from './TermsConditionsStep';
import WelcomeStep from './WelcomeStep';

const STEPS = {
  AuthorisedMaterialsStep,
  ExamStartStep,
  IdVerificationAnalysisStep,
  IdVerificationInfoStep,
  IdVerificationStep,
  MediaConnectStep,
  MediaConnectInfoStep,
  OpenExamGateStep,
  RoomScanStep,
  TermsConditionsStep,
  WelcomeStep,
}

export default STEPS;