import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { AuthConsumer } from '../../authContext';
import Can from '../Can';
import ReviewDataTabs from './ReviewDataTabs';

const styles = {
  displayRoot: {
    marginTop: 2.5,
    '& p': {
      wordBreak: 'break-word',
    },
  },
};

class ReviewData extends React.Component {

  render() {
    const { flags, meetings, examSlotId, examContextId, chats, investigations } = this.props;

    const reviewDataSection = (withImageLinks) => (
      <Box display="flex" sx={styles.displayRoot}>
        <ReviewDataTabs
          flags={flags}
          meetings={meetings}
          withImageLinks={withImageLinks}
          examSlotId={examSlotId}
          examContextId={examContextId}
          chats={chats}
          investigations={investigations}
        />
      </Box>
    );

    return (
      <AuthConsumer>
        {({ capabilityContextAccess }) => (
          <Can
            capabilityContextAccess={capabilityContextAccess}
            data={{ id: examContextId }}
            contextRequired={true}
            perform="REVIEW_FLAG_IMAGES"
            yes={() => reviewDataSection(true)}
            no={() => reviewDataSection(false)}
          />
        )}
      </AuthConsumer>
    );
  }
}

ReviewData.propTypes = {
  chats: PropTypes.array,
  examSlotId: PropTypes.string,
  examContextId: PropTypes.string,
  flags: PropTypes.array,
  investigations: PropTypes.object,
  meetings: PropTypes.array,
};

export default ReviewData;
