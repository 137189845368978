import { lighten } from '@mui/material/styles';
import { SEARCH_OPTIONS } from './search';
import { CAPABILITIES } from './capabilities';

export const EXAM_SESSION_FIELDS = {
  id: {},
  examDate: {
    display: 'Exam date',
  },
  examTime: {
    display: 'Time',
  },
  examDuration: {
    display: 'Duration',
  },
  allocationType: {
    display: 'Allocation type',
  },
  onboardingStatus: {
    display: 'Onboarding type',
  },
  onboarding: {
    display: 'Onboarding',
  },
  supervisionStatus: {
    display: 'Supervision status',
  },
  supervision: {
    display: 'Supervision'
  },
  studentStatus: {
    display: 'Student status',
  },
  studentName: {
    display: 'Student name',
  },
  activeOnboardStep: {
    display: 'Onboarding status',
  },
  examState: {
    display: 'eExam status',
  },
  studentId: {
    display: 'Student ID',
  },
  videoStatus: {
    display: 'Student webcam',
  },
  screenShareStatus: {
    display: 'Student screen share',
  },
  agentStatus: {
    display: 'Agent',
  },
  deletionDate: {
    display: 'Record expiry date',
  },
  deletionStatus: {
    display: 'Record status',
  },
};

//These values are used in examFields.js also
export const EXAM_SESSION_STATES = {
  canStart: 'CAN_START',
  pending: 'PENDING',
  started: 'STARTED',
  submitted: 'SUBMITTED',
};

export const EXAM_SESSION_STATUS_LABEL = {
  GATE_OPENED: 'Gate opened',
  GATE_CLOSED: 'Gate closed',
  SUBMITTED: 'Submitted',
};

export const EXAM_SESSION_INTEGRITY = {
  PENDING: {
    value: 'PENDING',
    label: 'Pending investigation',
    cannotExpire: true,
    chipBackgroundColor: (theme) => lighten(theme.palette.warning.main, 0.95),
  },
  DISMISSED: {
    value: 'DISMISSED',
    label: 'Dismissed',
    chipBackgroundColor: (theme) => lighten(theme.palette.error.main, 0.95),
  },
  UPHELD: {
    value: 'UPHELD',
    label: 'Upheld',
    chipBackgroundColor: (theme) => lighten(theme.palette.error.main, 0.95),
  },
  UPHELD_EXCLUDED: {
    value: 'UPHELD_EXCLUDED',
    label: 'Upheld and excluded',
    chipBackgroundColor: (theme) => lighten(theme.palette.error.main, 0.95),
  },
  ON_HOLD: {
    value: 'ON_HOLD',
    label: 'Investigation on hold',
    cannotExpire: true,
    chipBackgroundColor: (theme) => lighten(theme.palette.warning.main, 0.95),
  },
}

export const EXAM_SESSION_PRIVACY_CONFIRMATION = {
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  undecided: 'UNDECIDED',
};

export const EXAM_SESSION_CAPABILITIES = {
  sitExam: CAPABILITIES.sitExam,
  superviseExam: CAPABILITIES.superviseExam,
  onboardExam: CAPABILITIES.onboardExam,
  manageAssignments: CAPABILITIES.manageAssignments,
  manageExam: CAPABILITIES.manageExam,
  manageIntegrity: CAPABILITIES.manageIntegrity,
  viewIntegrity: CAPABILITIES.viewIntegrity,
  reviewSession: CAPABILITIES.reviewSession,
  skipByAdmin: CAPABILITIES.skipByAdmin,
  skipByAny: CAPABILITIES.skipByAny,
  skipByOnboarder: CAPABILITIES.skipByOnboarder,
};

export const EXAM_SESSION_TYPE_LABELS = {
  SUPERVISED_ONLY: 'Supervised only',
  AI_ONLY: 'AI only',
  SUPERVISED_AND_AI: 'Supervised + AI',
  RECORD_ONLY: 'Record only',
  ASSISTED: 'Assisted',
  UNKNOWN: 'Unknown',
};

export const EXAM_SESSION_PARTICIPANT_STATUS_LEGEND = {
  TO_JOIN: 'To join',
  ACTIVE: 'Active (joined & online)',
  INACTIVE: 'Inactive (joined & offline)',
  COMPLETE: 'Complete',
};

export const EXAM_SESSION_PARTICIPANT_STATUS = {
  TO_JOIN: 'TO_JOIN',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  COMPLETE: 'COMPLETE',
};

export const EXAM_SESSION_PARTICIPANT_WEBCAM_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

//These values are used in examFields.js also
export const EXAM_SESSION_ONBOARDING_TYPE = {
  SKIP: 'SKIP',
  HUMAN: 'HUMAN',
};

export const EXAM_SESSION_ONBOARDING_TYPE_LABELS = {
  skipOnboarding: 'Skip onboarding',
};

export const EXAM_SESSION_DELETION_STATUS = {
  PENDING: {
    value: 'PENDING',
    label: 'Pending deletion',
  },
  SCHEDULED: {
    value: 'SCHEDULED',
    label: 'Ready for deletion',
    chipBackgroundColor: (theme) => lighten(theme.palette.warning.main, 0.95),
  },
  DELETED: {
    value: 'DELETED',
    label: 'Deleted',
    chipBackgroundColor: 'success.light',
  },
}

export const EXAM_SESSION_DELETION_STATUS_LABELS = {
  PENDING: 'Pending deletion',
  SCHEDULED: 'Ready for deletion',
  DELETED: 'Deleted',
}

//These values are used in examFields.js
export const AI_TYPES = {
  ASSISTED: 'ASSISTED',
  AUTOMATIC: 'AUTOMATIC',
  NONE: 'NONE',
};

export const SESSION_TIMES = {
  am: {
    value: 'am',
    displayName: 'AM',
    startTime: '00:00:00',
    endTime: '11:59:59',
  },
  pm: {
    value: 'pm',
    displayName: 'PM',
    startTime: '12:00:00',
    endTime: '16:59:59',
  },
  eve: {
    value: 'eve',
    displayName: 'EVE',
    startTime: '17:00:00',
    endTime: '23:59:59',
  },
  all: {
    value: 'all',
    displayName: 'ALL',
    startTime: '00:00:00',
    endTime: '23:59:59',
  },
};

export const EXAM_SESSION_DTO = {
  examStartDateTime: '',
  examEndDateTime: '',
  studentId: '',
  supervisorId: '',
  onboarderId: '',
  examLandingPage: '',
  contextId: '',
  aiType: AI_TYPES.ASSISTED,
  humanSupervised: true,
  supervisionCompleted: false,
  onboardingType: EXAM_SESSION_ONBOARDING_TYPE.SKIP,
  state: EXAM_SESSION_STATES.canStart,
}

export const SEARCH_GROUP_OPTIONS = {
  UNIT: {
    label: 'Unit',
    value: 'context',
  },
  NONE: {
    label: 'No group',
    value: 'none',
  },
};


export const SEARCH_BY_TYPES = {
  student: {
    value: 'student',
    displayName: 'Student',
    endpointMapper: 'studentId',
  },
  context: {
    value: 'context',
    displayName: 'Exam unit',
    endpointMapper: 'contextNameMatch',
  },
  session: {
    value: 'session',
    displayName: 'Session',
    endpointMapper: 'session',
  },
  report: {
    value: 'report',
    displayName: 'Needs attention',
    restrictedTo: [EXAM_SESSION_CAPABILITIES.manageExam, EXAM_SESSION_CAPABILITIES.manageAssignments],
  },
  mediaHost: {
    value: 'mediaHost',
    displayName: 'Conference server',
    endpointMapper: 'meetingHostNameMatch',
    restrictedTo: [EXAM_SESSION_CAPABILITIES.manageExam],
    additionalParams: { activeMeeting: true },
  },
};

export const SEARCH_FILTERS = {
  groupBy: {
    // front end only, no mapping
    display: 'Group results by',
  },
  gateClosed: {
    display: 'Exam gate closed',
    mapper: 'examState',
    mappingType: 'multi',
    mappingValue: ['PENDING'],
  },
  selfOnboard: {
    display: 'Student self onboarding',
    mapper: 'onboardingType',
    mappingValue: 'SKIP',
  },
  studentOnline: {
    display: 'Student online',
    mapper: 'onlineParticipant',
    mappingValue: 'STUDENT',
  },
  studentOnlineOffset: {
    display: 'Student online time (in min)',
    mapper: 'minParticipantOnlineMinutes',
    // mappingValue is filterValue
  },
}

export const DEFAULT_MIN_TIME_ONLINE = '3';

export const STUDENT_ONLINE_OFFSET = {
  component: SEARCH_OPTIONS.searchNumber,
  name: 'studentOnlineOffset',
  label: 'Online offset',
  type: 'number',
  endAdornment: <>min</>,
  fullWidth: false,
};

//TODO after trial with real admins, move to db/configurable?
export const SEARCH_FILTER_PRESETS = [
  {
    name: 'default',
    display: 'Standard search',
    filterValues: {
      groupBy: SEARCH_GROUP_OPTIONS.UNIT.value,
      studentOnlineOffset: '',
    },
  },
  {
    name: 'needsHelpExamGate',
    display: 'Needs help with exam gate',
    filterValues: {
      gateClosed: true,
      groupBy: SEARCH_GROUP_OPTIONS.NONE.value,
      selfOnboard: true,
      studentOnline: true,
      studentOnlineOffset: DEFAULT_MIN_TIME_ONLINE,
    },
  },
];
