import { isProgressComplete } from './isProgressComplete';
import shouldRepeatNow from './shouldRepeatNow';

const evaluateStepToShow = (currentStepIndex, onboardProgressSteps) => {
  return onboardProgressSteps?.findIndex(
    (progressStep, progressStepIndex) => {
      const repeatStep = shouldRepeatNow(currentStepIndex, progressStep, progressStepIndex);
      return repeatStep ? true : !isProgressComplete(progressStep.progress);
    }
  );
};

export default evaluateStepToShow;