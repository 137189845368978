const ExamFlagRecordings = [
  {
    "id": "192a13b1-1986-4788-a73d-99c77a7e8126",
    "examStartDateTime": "2020-07-20T04:00:00+00",
    "examEndDateTime": "2020-07-20T05:00:00+00",
    "examSlotUsers": [
        {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      },
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
    }
    ],
    "context": {
      "name": "ATS2422 - Romanticism and its literary legacy",
      "id": "3211d9e4-180a-4b8d-af81-98d8be59cdc3",
      "description": "ATS2422 - Romanticism and its literary legacy",
      "externalId": "ATS2422"
    },
    "flags": [
      {
        "name": "verification-failed",
        "count": 1
      },
      {
        "name": "tech-issue",
        "count": 2
      },
      {
        "name": "Total flags",
        "count" : 3
      }
    ],
    "recordingsAvailable": "yes"
  },
  {
    "id": "192a13b1-1986-4788-a73d-99c77a7e8136",
    "examStartDateTime": "2020-07-20T04:00:00+00",
    "examEndDateTime": "2020-07-20T05:00:00+00",
    "examSlotUsers": [
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      },
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      }
    ],
    "context": {
      "name": "ATS2422 - Semester 2 unit",
      "id": "3211d9e4-180a-4b8d-af81-98d8be59cdc3",
      "description": "ATS2422 - Semester 2",
      "externalId": "ATS2422"
    },
    "flags": [
      {
        "name": "health-concern",
        "count": 6
      },
      {
        "name": "toilet-break",
        "count": 5
      },
      {
        "name": "Total flags",
        "count" : 11
      }
    ],
    "recordingsAvailable": 'no'
  },
  {
    "id": "192a13b1-1986-4788-a73d-99c77a7e8146",
    "examStartDateTime": "2020-07-21T04:00:00+00",
    "examEndDateTime": "2020-07-21T05:00:00+00",
    "examSlotUsers": [
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      },
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      }
    ],
    "context": {
      "name": "ATS2422 - Romanticism and its literary legacy",
      "id": "3211d9e4-180a-4b8d-af81-98d8be59cdc3",
      "description": "ATS2422 - Romanticism and its literary legacy",
      "externalId": "ATS2422"
    },
    "flags": [
      {
        "name": "rest-break",
        "count": 9
      },
      {
        "name": "red-flag",
        "count": 8
      },
      {
        "name": "Total flags",
        "count" : 17
      }
    ],
    "recordingsAvailable": 'yes'
  },
  {
    "id": "192a13b1-1986-4788-a73d-99c77a7e8156",
    "examStartDateTime": "2020-07-20T04:00:00+00",
    "examEndDateTime": "2020-07-20T05:00:00+00",
    "examSlotUsers": [
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      },
      {
        "user": {
          "userName": "Glenn.Walbran@monash.edu",
          "fullName": "Glenn Walbran",
          "externalId": "Glenn.Walbran@monash.edu",
          "isDisabled": false,
          "id": "092932d6-c907-496d-9ffa-fdd6d5525408"
        },
      }
    ],
    "context": {
      "name": "ATS2422 - Semester 2 unit",
      "id": "3211d9e4-180a-4b8d-af81-98d8be59cdc3",
      "description": "ATS2422 - Semester 2",
      "externalId": "ATS2422"
    },
    "flags": [
      {
        "name": "yellow-flag",
        "count": 11
      },
      {
        "name": "red-flag",
        "count": 2
      },
      {
        "name": "Total flags",
        "count" : 13
      }
    ],
    "recordingsAvailable": 'yes'
  },
];

export default ExamFlagRecordings;
