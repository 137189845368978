import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormPopup from '../popup/FormPopup';

class AddButton extends React.Component {
  state = {
    popupOpen: false,
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  render() {
    const { heading, content, title, id } = this.props;
    const { popupOpen } = this.state;
    return(
      <>
        <Button
          id={id}
          variant='contained'
          color='primary'
          onClick={this.openPopup}
        >{heading}</Button>
        <FormPopup
          title={title}
          open={popupOpen}
          onClose={this.closePopup}
          content={content}
        />
      </>
    )
  }
}

AddButton.propTypes = {
    id: PropTypes.string,
    heading: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.object
};

export default (AddButton);
