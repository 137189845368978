import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import browser from 'browser-detect';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PromiseWithAbort from '../../utils/PromiseWithAbort';

const friendlyKindName = {
  videoinput: "webcam",
  audioinput: "microphone",
  audiooutput: "speaker",
};

class DeviceSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      devices: [],
      options: [],
      videoPreviewError: null,
    };

    this.alldevices = null;

    this.asyncAbort = new PromiseWithAbort();
  }

  componentDidMount() {
    const { kind, updateDeviceOption, value: initialValue, setError } = this.props;
    const handleEnumerateDevicesSuccess = (deviceInfos) => {
      this.alldevices = deviceInfos;
      const devices = deviceInfos.filter(d => d.kind === kind);
      const options = devices.map((d, i) => ({
        label: d.label || (d.deviceId ? `Unknown ${friendlyKindName[kind]} device ${i}` : 'default'),
        value: d.deviceId || 'default',
        key: uniqueId(`${kind}-device-option-`),
      }));
      let stateUpdate = {
        devices,
        options
      };
      if (initialValue === null) {
        stateUpdate.value = options.length ? options[0].value : 'default';
        this.setState(stateUpdate);
        updateDeviceOption(stateUpdate.value, devices[0]);
      } else {
        this.setState(stateUpdate);
      }
      if (kind === 'videoinput' && options.length) {
        this.updateVideoPreview(options[0].value);
      }
    };

    if (this.alldevices === null) {
      this.asyncAbort.wrap(navigator.mediaDevices.enumerateDevices()
      ).then(handleEnumerateDevicesSuccess)
        .catch((err) => {
          if (err && !err.aborted) {
            console.error(`Error on enumerateDevices(${kind}): ${JSON.stringify(err)}`);
            setError(err);
          }
        });
    } else {
      handleEnumerateDevicesSuccess(this.alldevices);
    }
  }

  getVideoSource = (videoConstraints = true) => {
    return this.asyncAbort.wrap(
      new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia({
          video: videoConstraints,
          audio: false,
        })
        .then((stream) => {
          resolve(stream);
        })
        .catch((error) => {
          console.debug('[DeviceSelector] - on getVideoSource -> getUserMedia error', error,
            'Constraints= {video:videoConstraints,audio: false}. videoConstraints=', videoConstraints);
          reject(error);
        });
      }));
  };

  handleSelectChange(value) {
    const { updateDeviceOption } = this.props;
    const { devices } = this.state;
    const selectedDevice = devices.find(d => d.deviceId === value);
    if (selectedDevice.kind === 'videoinput') {
      // Update the video preview
      this.updateVideoPreview(selectedDevice.deviceId);
    }
    this.setState({ value }, () => {
      updateDeviceOption(selectedDevice.deviceId, selectedDevice);
    });
  }

  updateVideoPreview = (deviceId) => {
    const { setError } = this.props;
    this.asyncAbort.wrap(this.getVideoSource({deviceId}))
      .then((stream) => {
        const previewVideo = this.videoRef;
        if (previewVideo) {
          previewVideo.pause();
          previewVideo.srcObject = stream;
          previewVideo.load();
        }
        setError();
        this.setState({videoPreviewError: null});
        this.props.isPreviewPlaying(true);
      })
      .catch((err) => {
        if (!err.aborted) {
          setError(err);
          this.setState({videoPreviewError: err.message});
          this.props.isPreviewPlaying(false);
        }
      });
  }

  getDeviceOptions = (options, kind) => {
    if(options.length) {
      return options.map(option => (
        <MenuItem key={option.key} value={option.value}>{option.label}</MenuItem>
      ))
    }
    return (kind === 'audiooutput' && browser().name === 'safari')
      ? <MenuItem value="default">Default</MenuItem>
      : <MenuItem value="default">{`no ${kind} found`}</MenuItem>
  }

  componentWillUnmount() {
    this.asyncAbort.abort();
  }

  render() {
    const { kind, sx } = this.props;
    const { options, value, videoPreviewError } = this.state;
    let selectLabel;
    switch (kind) {
      case 'audioinput':
        selectLabel = 'Microphone';
        break;
      case 'videoinput':
        selectLabel = 'Webcam';
        break;
      case 'audiooutput':
        selectLabel = 'Speaker';
        break;
      default:
        selectLabel = 'Device';
    }

    return (
      <Box sx={sx?.container}>
        <FormControl sx={{ margin: '2vh', minWidth: 250 }} variant="outlined" required>
          <InputLabel id={`device-${kind}-label`}>
            {selectLabel}
          </InputLabel>
          <Select
            labelId={`device-${kind}-label`}
            value={value ? value : ""}
            onChange={(e) => {this.handleSelectChange(e.target.value)}}
            disabled={!options.length}
            label={selectLabel}
          >
            {this.getDeviceOptions(options, kind)}
          </Select>
        </FormControl>
        {kind === 'videoinput' && (videoPreviewError === null ?
          <Box sx={sx.videoContainer}>
            <video id="preview-video"
              style={{ transform: 'scaleX(-1)' }}
              muted
              ref={(ref) => { this.videoRef = ref;}}
              autoPlay
              playsInline
              { ...sx?.videoProps } />
          </Box>
          : "Webcam error: "+videoPreviewError
        )}
      </Box>
    );
  }
}

DeviceSelector.propTypes = {
  kind: PropTypes.oneOf(['audioinput', 'audiooutput', 'videoinput']),
  updateDeviceOption: PropTypes.func.isRequired,
  isPreviewPlaying: PropTypes.func,
  setError: PropTypes.func,
  value: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
};

DeviceSelector.defaultProps = {
  kind: 'audioinput',
  value: undefined,
  className: null,
  setError: () => { /* intentionally empty default prop function, so it can be called indiscriminately */ },
  isPreviewPlaying: () => { /* do nothing by default */},
  sx: {
    container: {
      display: 'flex',
      flexWrap: 'row-wrap',
    },
    videoContainer: {},
    videoProps: {
      height: '100',
      width: '180',
    },
  },
};

export default DeviceSelector;
