import React from 'react';
import InfoPopover from './InfoPopover';
import { Typography } from '@mui/material';

function MisconductDetailsPopup() {
  return (
    <InfoPopover
      buttonStyle={{ marginTop: 0 }}
      content={
        <>
          <Typography variant="h5" component="p" sx={{ my: 1 }}>Misconduct case</Typography>
          <Typography variant="body1">Law student</Typography>
        </>
      }
    />
  );
}

export default MisconductDetailsPopup;
