const CLIENT_CONFIG = [{
	"content": "[\"I confirm I’ve read and understand the exam rules listed on the landing page of my eExam.\",\"I understand that my entire eExam session, including any interactions I have with my supervisor through my webcam, microphone and screen, will be monitored and recorded for the purposes of remote supervision by Monash and for authorised Monash staff validating the integrity of my assessment, if required. I understand that the recordings will be held in accordance with Monashs record keeping obligations for examinations and assessments.\",\"I understand that I must remain in sight of the webcam at all times during my exam session and that, if I leave the room for any reason, I must leave my smartphone visible in front of the camera.\"]",
	"content-type": "application/json",
	"name": "student-terms"
}, {
	"content": "{\"Australia\":\"+61 3 9905 4300\",\"Malaysia\":\"+60 3 5514 5600\"}",
	"content-type": "application/json",
	"name": "it-support-numbers"
}, {
	"content": "{\"main\": \"#D13772\",\"light\": \"rgba(209,55,114,0.65)\",\"dark\": \"#A41D5B\",\"contrastText\": \"#ffffff\"}",
	"content-type": "application/json",
	"name": "theme"
}, {
	"content": "<a href=\"https://www.monash.edu/__data/assets/pdf_file/0011/1595270/Student-Data-Management-and-Privacy-Collection-Statement.pdf\" target=\"_blank\" rel=\"noopener noreferrer\">Student Privacy Collection Statement</a><br><a href=\"https://www.monash.edu/__data/assets/pdf_file/0003/790086/Privacy.pdf\" target=\"_blank\" rel=\"noopener noreferrer\">Monash Privacy Procedure</a>",
	"content-type": "text/html",
	"name": "privacy-info"
}, {
	"content": "{\"audio\":\"https://myaudiolink.com\",\"default\":\"javascript:alert(somethingtobeignored.com)\",\"screenshare\":\"https://myscreensharelink.com\"}",
	"content-type": "application/json",
	"name": "troubleshooting-links"
}];

export default CLIENT_CONFIG;
