import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { Badge, Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { CoPresent, Edit, Flight, PersonAdd, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { authContext } from '../../authContext';
import AllocationTypeForm from './AllocationTypeForm';
import AssignSessionForm from './AssignSessionForm';
import { CanThey } from '../Can';
import ExamSessionForm from './ExamSessionForm';
import FormPopup from '../popup/FormPopup';
import MultiSelectOnboardProgressFormContainer from '../container/MultiSelectOnboardProgressFormContainer';
import { EXAM_SESSION_CAPABILITIES as CAPABILITIES } from '../../constants/examSessions';
import Routing from '../../utils/Routing';

const actionTypes = {
  popup: 'POPUP',
  link: 'LINK',
};

const actions = [
  {
    icon: <PersonAdd />,
    name: 'Assign',
    multiAllowed: true,
    component: AssignSessionForm,
    title: 'Assign session(s)',
    access: [CAPABILITIES.manageAssignments],
    type: actionTypes.popup,
  },
  {
    icon: <Edit />,
    name: 'Edit',
    multiAllowed: false,
    component: ExamSessionForm,
    title: 'Edit session',
    access: [CAPABILITIES.manageExam],
    type: actionTypes.popup,
  },
  {
    icon: <SupervisedUserCircleOutlined />,
    name: 'Set allocation type',
    multiAllowed: false,
    component: AllocationTypeForm,
    title: 'Set allocation type',
    access: [CAPABILITIES.manageExam],
    type: actionTypes.popup,
  },
  {
    icon: <Flight />,
    name: 'Skip step',
    multiAllowed: false,
    component: MultiSelectOnboardProgressFormContainer,
    title: 'Skip onboard step',
    access: [CAPABILITIES.skipByAdmin, CAPABILITIES.skipByAny, CAPABILITIES.skipByOnboarder],
    type: actionTypes.popup,
  },
  {
    icon: <CoPresent />,
    name: 'Join',
    multiAllowed: false,
    access: [CAPABILITIES.manageExam],
    type: actionTypes.link,
    getLink: (selectedExamSlots) => `/${Routing.SUPERVISE_SESSION}/${selectedExamSlots.find(Boolean).id}`,
  },
];

function EditSessionSelectorButtons(props) {

  const { capabilityContextAccess } = useContext(authContext);
  const [currentAction, setCurrentAction] = useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const { selectedExamSlots, setHasSlotUpdated, setRequestResult } = props;

  const executeAction = (action) => {
    setCurrentAction(action);
    if (action.type === actionTypes.popup) {
      setPopupOpen(true);
    } else if (action.type === actionTypes.link) {
      window.open(sanitizeUrl(action.getLink(selectedExamSlots)), '_blank', 'noreferrer');
    }
  };

  const closePopup = () => {
    setPopupOpen(false);
    props.refreshSlots();
  };

  const actionsToDisplay = !isEmpty(selectedExamSlots) &&
    actions.filter((action) => {
      const canAccess = CanThey(capabilityContextAccess, false, action.access);
      return selectedExamSlots.length > 1
        ? canAccess && action.multiAllowed
        : canAccess
    });

  // This component should only be used once per page (it's very specific, so unlikely to be on multiple)
  // Otherwise, need to rethink the ids for front end testing
  return (
    <>
      <Box id="selected-exam-options" sx={{ position: 'absolute', top: '0', right: '50px' }}>
        <Badge
          badgeContent={selectedExamSlots.length}
          color="secondary"
          componentsProps={{
            badge: {
              id: 'number-selected-exams'
            }
          }}
          overlap="circular"
          sx={{ position: 'fixed', zIndex: '112' }}
        >
          <SpeedDial
            ariaLabel="Select action for selected exam sessions"
            direction="down"
            FabProps={{ disabled: isEmpty(actionsToDisplay), id: 'selected-exams-option-selector' }}
            icon={<SpeedDialIcon />}
            sx={{ position: 'fixed', zIndex: '111' }}
          >
            {actionsToDisplay.map((action) => (
              <SpeedDialAction
                icon={action.icon}
                FabProps={{ id: `${action.name}-selected-exams-button` }}
                key={action.name}
                onClick={() => executeAction(action)}
                tooltipOpen
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>
        </Badge>
      </Box>
      {currentAction && currentAction.type === actionTypes.popup &&
        <FormPopup
          content={
            <currentAction.component
              selectedExamSlots={selectedExamSlots}
              setHasSlotUpdated={setHasSlotUpdated}
              setRequestResult={setRequestResult}
            />
          }
          maxWidth="md"
          onClose={closePopup}
          open={popupOpen}
          stretch={true}
          title={currentAction.title}
        />
      }
    </>
  )
}

EditSessionSelectorButtons.propTypes = {
  actionName: PropTypes.string,
  refreshSlots: PropTypes.func,
  selectedExamSlots: PropTypes.array.isRequired,
  setHasSlotUpdated: PropTypes.func,
  setRequestResult: PropTypes.func,
};

export default EditSessionSelectorButtons;
