import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import AuthorisedMaterials from '../content/AuthorisedMaterials';
import ExamSessionTypeLabel from '../content/ExamSessionTypeLabel';
import InfoPopover from './InfoPopover';

function ExamDetailsPopup(props) {
  const { examContextName, examSession, searchBySlot, useFullButton } = props;
  const authMaterialsSearchProps = searchBySlot
    ? { slotId: examSession.id }
    : { contextId: examSession.context.id }

  return (
    <InfoPopover
      buttonStyle={useFullButton ? undefined : { marginTop: 0 }}
      buttonText={useFullButton ? "View exam information" : undefined}
      content={
        <>
          <Typography variant="h4" component="h2">
            {examContextName}
          </Typography>
          {examSession.examType &&
            <>
              <Typography variant="h5" component="h3">Invigilation type</Typography>
              <ExamSessionTypeLabel
                examType={examSession.examType}
                humanSupervised={examSession.humanSupervised}
              />
            </>
          }
          <AuthorisedMaterials { ...authMaterialsSearchProps } sx={{ px: 0 }} />
        </>
      }
    />
  )
}

ExamDetailsPopup.propTypes = {
  examContextName: PropTypes.string,
  examSession: PropTypes.object,
  useFullButton: PropTypes.bool,
  searchBySlot: PropTypes.bool,
};

export default ExamDetailsPopup;
