import { isEmpty } from 'lodash';
/**
 * Remove one or more media/conference errors which are no longer applicable
 * @param {string} category Optional category of error to clear (if blank, all are cleared)
 * @param {string} errorCode Optional specific error code to clear (if empty, all matching the category are cleared)
 * @param {array} currentConferenceErrors The set of existing errors to clear from
 */
const clearConferenceError = (category, errorCode, currentConferenceErrors) => {
  if (isEmpty(currentConferenceErrors) || !category) {
    //either nothing to clear or clear all
    return [];
  }
  // return the errors that don't meet the criteria of matching category and either no errorCode or a matching errorCode
  return currentConferenceErrors.filter(e => !(e.category === category && (!errorCode || errorCode === e.errorCode)));
};

export default clearConferenceError;