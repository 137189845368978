import { some, has, isEmpty } from 'lodash';
import { EXAM_SESSION_DELETION_STATUS } from '../constants/examSessions';

/**
 * Check whether a given exam slot session would appear on the monitoring dashboard for
 * a staff-level user.
 * @param {object} examSession Exam slot response data
 * @param {string} userId User UUID who is checking for visibility
 * @param {object} features List of currently enabled feature toggles
 * @returns True if the exam session should appear on the monitoring dashboard list for the user
 */
const examSessionIsVisibleToAssignedUser = (examSession, userId, _features, monitorListTypes) => {
  return some(Object.values(monitorListTypes), type => 
    (isEmpty(type.examStateFilter) || type.examStateFilter.includes(examSession.examState))
    && (isEmpty(type.onboardingTypeFilter) || type.onboardingTypeFilter.includes(examSession.onboardingType))
    && (has(examSession, type.userMapper)
      && some(examSession[type.userMapper], assignment => assignment.id === userId && assignment.current))
    && (examSession.deletionStatus !== EXAM_SESSION_DELETION_STATUS.DELETED.value 
        && examSession.deletionStatus !== EXAM_SESSION_DELETION_STATUS.SCHEDULED.value)
  );
};
export default examSessionIsVisibleToAssignedUser;