import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import StatusChip from './StatusChip';
import MisconductDetailsPopup from '../popup/MisconductDetailsPopup';
import { EXAM_SESSION_INTEGRITY } from '../../constants/examSessions';
import { getFormattedDeletionDate } from '../../utils/getExamDetails';

function ReviewInvestigations(props) {
  const { investigations } = props;
  const deletionDate = getFormattedDeletionDate(investigations);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' }
    },
    containerItem: {
      display: 'flex',
      flexDirection: 'column',
      mx: 3,
      mb: { xs: 1, sm: 0 }
    },
    statusChip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }
  };

  return (
    <Box display="flex" sx={{ p: 2 }}>
      <ErrorOutline sx={{ color: 'error.main', mt: 0, mr: 1, fontSize: "1.5rem" }} />
      <Box sx={styles.container} >
        <Box sx={styles.containerItem} >
          <Typography variant="body1" component="h2" mb={investigations?.cannotExpire ? 2 : 0}>Record Expiry Date</Typography>
          <Typography variant="body2">{deletionDate}</Typography>
        </Box>
        <Box sx={styles.containerItem} >
          <Typography variant="body1" component="h2">Misconduct case</Typography>
          <Box sx={styles.statusChip}>
            <StatusChip status={investigations?.integrityReview} styles={EXAM_SESSION_INTEGRITY} />
            {investigations?.cannotExpire &&
              <MisconductDetailsPopup />
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ReviewInvestigations.propTypes = {
  investigations: PropTypes.object
}

export default ReviewInvestigations
