import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { StyledEngineProvider } from '@mui/material/styles';

import { MonitoringProvider } from './components/context/MonitoringContext';
import ConfigurableAuthWrapper from './components/auth/ConfigurableAuthWrapper';
import './index.css';

const root = createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <MonitoringProvider>
      <BrowserRouter>
        <ConfigurableAuthWrapper/>
      </BrowserRouter>
    </MonitoringProvider>
  </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
