import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Routing from '../../utils/Routing';

const styles = {
  listIcon: {
    minWidth: 'unset',
  },

  listItem: {
    padding: 0,

    // Apply styling to the link (which contains all item content)
    '& > a': {
      width: '100%',
      minHeight: 50,
      padding: 1,
      boxSizing: 'border-box',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    '&$sublistItem > a': {
      paddingLeft: 4,
      backgroundColor: 'secondary.dark',
    },
    // Pad and color the item content
    '& > a > * ': {
      my: 0,
      mx: 1,
    },
    '& > a > * > *': {
      color: 'secondary.contrastText',
    },

    // Change the colour of hovered links, focused links and active links
    '&:hover > a,  &:focus > a, & > .active': {
      backgroundColor: 'primary.main',
    },
    '&:hover > a > * > *, &:focus > a > * > *, & > .active > * > *': {
      color: 'primary.contrastText',
    },
  },

  sublistItem: {},

};

function NavigationMenuItem(props) {
  const ariaLabel = props.ariaLabel !== undefined ? props.ariaLabel : props.label;

  return (
    <ListItem sx={styles.listItem}>
      <NavLink
        aria-label={ariaLabel}
        tabIndex={0}
        to={Routing.path(props.routing)}
        id={props.id}
      >
        <ListItemIcon sx={styles.listIcon}>
          {props.listIcon}
        </ListItemIcon>
        {props.isExpanded && <ListItemText primary={props.label} />}
      </NavLink>
    </ListItem>
  );
}

NavigationMenuItem.propTypes = {
  routing: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  listIcon: PropTypes.object,
  id: PropTypes.string,
}

export default NavigationMenuItem;
