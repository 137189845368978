import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

function BackgroundBanner(props) {

  const theme = useTheme();
  const { backgroundGradientPalette, backgroundColor, children } = props;

  const background = backgroundGradientPalette &&
    `repeating-conic-gradient(
      from 238deg at 50% 50%,
      ${theme.palette[backgroundGradientPalette].main} 4.77%,
      ${theme.palette[backgroundGradientPalette].main} 4.77%, 23.69%,
      ${theme.palette[backgroundGradientPalette].dark} 37.04%,
      ${theme.palette[backgroundGradientPalette].dark} 37.04%)
      0px 259px / 379px 321px repeat repeat`

  const bgColor = backgroundGradientPalette
    ? theme.palette[backgroundGradientPalette].main
    : backgroundColor;

  return (
    <Box
      alignItems="center"
      display="flex"
      height="20%"
      justifyContent="center"
      minHeight="150px"
      sx={{ background: background, backgroundColor: bgColor }}
      width="100%"
    >
      {children}
    </Box>
  )
}

BackgroundBanner.propTypes = {
  backgroundGradientPalette: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default BackgroundBanner;
