const ASSIGNED_ROLES = [
  {
    "context": {
      "name": "child 1",
      "id": "c1be44bf-fa3b-4e61-91b3-d22287c41cc4",
      "parent": {
        "id": "effb029b-b806-4013-a96c-e689bbba0331"
      }
    },
    "role": {
      "name": 'Supervisor',
      "description": 'This is a supervisor',
      "id": "1"
    },
    "user":
    {
      "id": "1",
      "fullName": "Test Name",
      "userName": "testname",
      "externalId": "1110",
      "isDisabled": false,
    },
  },
]

export default ASSIGNED_ROLES
