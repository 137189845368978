import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UnPinned = () => {
  return (
    <SvgIcon viewBox="0 0 31 31">
        <rect width="31" height="31" rx="15" fill="#DDDDDD"/>
        <path d="M20.3647 12.667L17.3343 15.6975C16.6554 16.3763 16.2493 17.2309 16.1221 18.1218L12.4855 14.4853C13.4007 14.358 14.2432 13.9398 14.9099 13.2731L17.9403 10.2426L20.3647 12.667ZM23.3952 13.2731L17.3343 7.21218C17.0009 6.87883 16.4554 6.87883 16.1221 7.21218C15.7887 7.54553 15.7887 8.09102 16.1221 8.42437L16.7282 9.03046L13.6977 12.0609C12.6916 13.067 11.0673 13.067 10.0612 12.0609L8.84897 13.2731L12.4673 16.8915L8.2247 21.1341L8.2247 22.3463L9.43688 22.3463L13.6795 18.1036L17.3343 21.7584L18.5464 20.5462C17.5403 19.5401 17.5403 17.9158 18.5464 16.9096L21.5769 13.8792L22.183 14.4853C22.5163 14.8186 23.0618 14.8186 23.3952 14.4853C23.7285 14.1519 23.7285 13.6064 23.3952 13.2731Z" fill="#3C4043"/>
    </SvgIcon>
  );
}

export default UnPinned;
