import { isEmpty } from 'lodash';

const onRaiseHand = (gatewayService) => {
  //Trigger a raise/lower hand event in the gateway
  return (isRaiseHandAction) => {
    if (isEmpty(gatewayService)) {
      return;
    }
    gatewayService.onRaiseHand(isRaiseHandAction);
  }
};

export default onRaiseHand;