import React, { useContext, useEffect, useState, useRef } from 'react';
import { Alert, Box, Button, Snackbar, Tooltip, Typography } from '@mui/material';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import { cloneDeep, has, isEqual } from 'lodash';
import { authContext } from '../authContext';
import { monitoringContext } from './context/MonitoringContext';

import AudioConnect from './audioVisual/AudioConnect';
import AudioVisualCheck from './audioVisual/AudioVisualCheck';
import DisplayVideo from './audioVisual/DisplayVideo';
import ExamDetails from './content/ExamDetails';
import FlagChatToggle from './form/FlagChatToggle';
import MonitorChatContainer from './container/MonitorChatContainer';
import Notice from './notification/Notice';
import PinSessionButton from './form/PinSessionButton';
import ResolveNoticesButton from './form/ResolveNoticesButton';
import RestartButton from './form/RestartButton';
import Section from './Section';
import SuperviseInSessionNotifications from './notification/SuperviseInSessionNotifications';
import SupervisorMediaControls from './form/SupervisorMediaControls';
import Verification from './popup/Verification';

import dismissStudent from '../utils/dismissStudent';
import { getStudentUser } from '../utils/getExamSlotUser';
import getSupervisorDashboardMeetingChangeNotifications from '../utils/getSupervisorDashboardMeetingChangeNotifications';
import reconnectStudent, { getReconnectObject } from '../utils/reconnectStudent';
import shouldStopSupervisorDashboardSelfVideoOnMeetingChange from '../utils/shouldStopSupervisorDashboardSelfVideoOnMeetingChange';

import { ACTIONS } from '../constants/monitorSessions';
import { EXAM_SESSION_ONBOARDING_TYPE, EXAM_SESSION_STATES } from '../constants/examSessions';
import { SUPERVISOR } from '../constants/chat';

const styles = {
  pageContainer: {
    width: 'calc(70% - 1px)',
    minHeight: '100%',
    height: '100%',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  panel: {
    userSelect: 'none',
    color: 'secondary.contrastText',
    backgroundColor: 'secondary.main',
    textAlign: 'center',
    marginRight: '1px',
  },
  swapPanelControl: {
    cursor: 'pointer',
  },
  swapIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  connectionNotice: {
    backgroundColor: 'error.main',
    margin: 0,
    borderRadius: '0 0 50px 50px',
  },
  resolveNoticesButton: {
    textTransform: 'none',
    lineHeight: 'initial',
    margin: 'auto',
  },
  monitorActionsPanel: {
    width: '100%',
  },
  monitorActionsSection: {
    marginBottom: '1px',
    borderRadius: 0,
    paddingTop: 1,
    paddingBottom: 1,
  },
  stackedButtonLabel: {
    flexDirection: 'column',
  },
  outlinedButton: {
    textTransform: 'initial',
    py: 0.5,
    px: 1,
    margin: 0.5,
    marginLeft: 2,
    lineHeight: '1rem',
    color: 'primary.contrastText',
    borderColor: 'primary.contrastText',
    '&:hover': {
      borderColor: 'common.white',
    }
  },
  disabledButton: {
    textTransform: 'initial',
    backgroundColor: 'secondary.dark',
    borderColor: 'transparent',
    color: 'secondary.light',
  }
};

function SuperviseInSession(_props) {

  const { state, dispatch } = useContext(monitoringContext);
  const { user } = useContext(authContext);
  const slotId = useRef(state.activeConnection);
  const examSessions = useRef(state.examSessions);
  const reconnectionDetails = useRef({
    slotId: undefined,
    examDetails: undefined
  });
  const [streamsSwitched, setStreamsSwitched] = useState(false);
  const [setupComplete, setSetupComplete] = useState(false);
  const [sharedMediaStatus, setSharedMediaStatus] = useState({
    audio: false,
    video: false,
  });
  const [showMediaControls, setShowMediaControls] = useState(false);
  const [reconnectionStatus, setReconnectionStatus] = useState('completed');
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  // Initial audio status
  const [audioStatus, setAudioStatus] = useState('connecting');
  // Placeholder for any audio errors
  const [mediaShareErrors, setMediaShareErrors] = useState(undefined);
  // Track state and student media changes only in this component state
  const [examState, setExamState] = useState(undefined);
  const [onboardingType, setOnboardingType] = useState(undefined);
  const [studentMedia, setStudentMedia] = useState(undefined);
  const [chatList, setChatList] = useState(undefined);
  const [meetingStateProperties, setMeetingStateProperties] = useState(undefined);
  const [notifications, setNotifications] = useState([]);

  const thisExamSession = slotId.current !== undefined ? examSessions.current[slotId.current] : undefined;
  const examConnectionDetails = slotId.current !== undefined ? thisExamSession.connectionDetails : undefined;
  const examSubmitted = examState === EXAM_SESSION_STATES.submitted;

  //use effect to reconnect the active sessions
  useEffect(() => {
    reconnectStudent(reconnectionStatus, reconnectionDetails, state, dispatch, setReconnectionStatus);
  }, [dispatch, reconnectionStatus, setReconnectionStatus, state]);

  useEffect(() => {
    const updateExamSessionDetails = (prevSlotId) => {
      const currentExamSession = examSessions.current[slotId.current];
      const updatedExamState = currentExamSession.examState;
      const updatedOnboardingType = currentExamSession.onboardingType;
      const updatedStudentMedia = cloneDeep(currentExamSession.studentMedia);
      const updatedMeetingStateProperties = currentExamSession.meetingStateProperties;
      if (!isEqual(examState, updatedExamState)) {
        setExamState(updatedExamState);
      }
      if (!isEqual(onboardingType, updatedOnboardingType)) {
        setOnboardingType(updatedOnboardingType);
      }
      if (!isEqual(studentMedia, updatedStudentMedia)) {
        setStudentMedia(updatedStudentMedia);
      }
      if (!isEqual(chatList, currentExamSession.chat)) {
        // Todo: Consider if this should be moved to the monitor chat
        // container since this higher level component doesn't directly
        // need to re-render when chat messages are altered.
        setChatList(cloneDeep(currentExamSession.chat));
      }

      const meetingStatePropertiesHasChanged = !isEqual(meetingStateProperties, updatedMeetingStateProperties);
      if (meetingStatePropertiesHasChanged || prevSlotId !== slotId.current) {

        // Determine if any notifications should be triggered based on the
        // gateway messages received for the meeting.
        const newNotifications = getSupervisorDashboardMeetingChangeNotifications(
          prevSlotId, 
          meetingStateProperties, 
          slotId.current, 
          updatedMeetingStateProperties
        );

        if (newNotifications.length) {
          setNotifications([...notifications, ...newNotifications]);
        }

        // Also check if we should stop sending video due to another supervisor taking control
        if (shouldStopSupervisorDashboardSelfVideoOnMeetingChange(
          meetingStateProperties, 
          updatedMeetingStateProperties, 
          sharedMediaStatus
        )) {
          let newSharedMediaStatus = { ...sharedMediaStatus };
          newSharedMediaStatus.video = false;
          setSharedMediaStatus(newSharedMediaStatus);
        }

        // Finally, update the local state of meeting properties.
        if (meetingStatePropertiesHasChanged) {
          setMeetingStateProperties(cloneDeep(updatedMeetingStateProperties));
        }
      }
    };

    const updateVideoStream = (prevSlotId) => {
      if (prevSlotId === slotId.current) {
        return false;
      }
      // Always start the supervisor media disabled
      setShowMediaControls(false);
      setSharedMediaStatus({
        audio: false,
        video: false,
      });

      // Reset any audio errors and the audio status before switching to a new student.
      setMediaShareErrors(undefined);
      setAudioStatus('connecting');
      setSnackbarOpen(true);

      const prevVideoStreamService = state[prevSlotId] && state[prevSlotId].videoService;
      const prevExamSession = examSessions.current[prevSlotId];

      const videoStreamService = state[slotId.current] && state[slotId.current].videoService;
      const thisExamSession = examSessions.current[slotId.current];
      const thisWebcamStream = thisExamSession?.studentMedia?.video?.stream;
      const thisDesktopStream = thisExamSession?.studentMedia?.screenshare?.stream;

      videoStreamService && thisWebcamStream && videoStreamService.add(thisWebcamStream, false, null);
      videoStreamService && thisDesktopStream && videoStreamService.add(thisDesktopStream, false, null);

      // Also clean up any peer supervisor streams when active slot is changed
      const prevPeerStream = has(prevExamSession, 'meetingStateProperties.videoOverride.stream') ?
        prevExamSession.meetingStateProperties.videoOverride.stream : undefined;
      const newPeerStream = has(thisExamSession, 'meetingStateProperties.videoOverride.stream') ?
        thisExamSession.meetingStateProperties.videoOverride.stream : undefined;

      if (prevVideoStreamService && prevPeerStream) {
        // Stop tracking the old supervisor video and update the flag to not be ready for rendering
        prevVideoStreamService.remove(prevPeerStream);
        const prevSessionUpdate = {
          id: prevSlotId,
          meetingStateProperties: {
            videoOverride: {
              ready: false,
            },
          },
        };
        dispatch({ type: ACTIONS.UPDATE_EXAM_SESSION, value: prevSessionUpdate });
      }
      if (videoStreamService && newPeerStream) {
        // Start tracking the new supervisor video and update the flag to ready for rendering
        videoStreamService.add(newPeerStream, false, null);
        const sessionUpdate = {
          id: slotId.current,
          meetingStateProperties: {
            videoOverride: {
              ready: true,
            },
          },
        };
        dispatch({ type: ACTIONS.UPDATE_EXAM_SESSION, value: sessionUpdate });
      }

      setSetupComplete(false)
      return true;
    };

    const prevSlotId = slotId.current;
    slotId.current = state.activeConnection;
    examSessions.current = state.examSessions;
    if (slotId.current !== undefined) {
      updateExamSessionDetails(prevSlotId);
      updateVideoStream(prevSlotId);
    } else {
      if (examState !== undefined) {
        setExamState(undefined);
      }
      if (studentMedia !== undefined) {
        setStudentMedia(undefined);
      }
      setSetupComplete(false);
      if (notifications.length) {
        setNotifications([]);
      }
      if (meetingStateProperties !== undefined) {
        setMeetingStateProperties(undefined);
      }
      if (prevSlotId !== undefined) {
        console.debug("[SuperviseInSession]: Clearing active slot details.");
      }
    }
  }, [
    // TODO: Look at optimising this useEffect trigger so it doesn't run on ANY exam change (entire state) - only the one we care about
    state,
    examState, 
    onboardingType, 
    studentMedia, 
    chatList, 
    meetingStateProperties, 
    sharedMediaStatus,
    notifications, 
    dispatch
  ]);

  useEffect(() => {
    if (examConnectionDetails && !setupComplete && reconnectionStatus === 'completed') {
      setSetupComplete(true);
    }
  }, [setupComplete, examConnectionDetails, reconnectionStatus]);

  const togglePanel = () => {
    setStreamsSwitched(!streamsSwitched);
  };

  const toggleVideoShare = () => {
    setSharedMediaStatus({
      audio: sharedMediaStatus.audio,
      video: !sharedMediaStatus.video,
    });
  };

  const onExamUnlocked = (gatewayConnection) => {
    if (gatewayConnection) {
      gatewayConnection.updateMeeting(
        { examState: EXAM_SESSION_STATES.canStart },
        'Onboarder opened exam gate'
      );
    }
  };

  const toggleAudioMute = (gatewayConnection) => {
    let status = false;
    if (gatewayConnection) {
      if (!sharedMediaStatus.audio) {
        status = true;
        gatewayConnection.updateMedia('audio', 'muted', false);
      } else {
        //status remains as false
        gatewayConnection.updateMedia('audio', 'muted', true);
      }
      setSharedMediaStatus({
        audio: status,
        video: sharedMediaStatus.video,
      });
    }
  };

  const reconnectServices = () => {
    // Update exam session details from state
    const examDetails = examSessions.current[slotId.current];
    reconnectionDetails.current = getReconnectObject(examDetails);
    setReconnectionStatus('removeSession');
  };

  const canRenderForActiveSlot = state.activeConnection !== undefined && setupComplete;

  const renderPeerSupervisorVideo = canRenderForActiveSlot && meetingStateProperties?.videoOverride?.ready;

  // define video components where they can be swapped
  const webcamDisplay = canRenderForActiveSlot &&
    (<DisplayVideo 
      sessionId={slotId.current}
      videoType="webcam"
      showWaitText={!streamsSwitched}
      studentMedia={studentMedia}
      connectionDetails={thisExamSession.connectionDetails}
    />);
  const screenshareDisplay = canRenderForActiveSlot &&
    (<DisplayVideo
      sessionId={slotId.current}
      videoType="desktop"
      showWaitText={streamsSwitched}
      studentMedia={studentMedia}
      connectionDetails={thisExamSession.connectionDetails}
    />);

  const handleSnackbarClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClearNotificationAtIndex = (index) => {
    let remainingNotifications = [...notifications];
    remainingNotifications.splice(index, 1);
    setNotifications(remainingNotifications);
  };

  return (
    <>
      {mediaShareErrors !== undefined &&
        <Snackbar open={snackbarOpen}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleSnackbarClose} severity="error">{mediaShareErrors}</Alert>
        </Snackbar>
      }
      {canRenderForActiveSlot && thisExamSession &&
        <>
          <Box display="flex" flexDirection="column" sx={styles.pageContainer}>
            <Box id="exam-summary" display="flex" color="primary.contrastText" bgcolor="secondary.dark" justifyContent="center">
              <RestartButton
                isTextButton={true}
                slotId={slotId.current}
                gatewayOpen={
                  has(examConnectionDetails, 'connectionSetUpComplete')
                    ? examConnectionDetails.connectionSetUpComplete
                    : false
                }
                restartCallback={examSubmitted
                  ? () => dismissStudent(slotId.current, dispatch)
                  : () => reconnectServices()}
                restart={!examSubmitted}
              />
              <Button
                variant='outlined'
                color='primary'
                onClick={() => reconnectServices()}
                sx={{
                  ...styles.stackedButtonLabel,
                  '&.MuiButton-outlined': styles.outlinedButton,
                  '&.Mui-disabled': styles.disabledButton,
                }}
                disabled={has(examConnectionDetails, 'connectionSetUpComplete')
                  ? !examConnectionDetails.connectionSetUpComplete
                  : true}
              >
                <div>Reconnect</div><Typography variant="body2" component="div">(on my end)</Typography>
              </Button>

              <ExamDetails examDetails={thisExamSession} slotId={slotId.current} studentDetails={getStudentUser(thisExamSession)} />
            </Box>
            <Box display="flex" flex="1" maxHeight="calc(100vh - 52px)">
              <Box flexGrow={3} display="flex">
                <Box id="main-panel" flexGrow={1} position="relative" height="calc(100vh - 52px)" display="flex" alignItems="center" sx={styles.panel}>
                  {examConnectionDetails && has(state, slotId.current) && mediaShareErrors === undefined &&
                    <AudioConnect
                      slotId={slotId.current}
                      connectionProps={has(examConnectionDetails, 'connectionProps')
                        ? examConnectionDetails.connectionProps : undefined}
                      setShowMediaControls={setShowMediaControls}
                      audioStatus={audioStatus}
                      setAudioStatus={setAudioStatus}
                      setAudioErrors={(errorText, _rawError) => { setMediaShareErrors(errorText) }}
                    />
                  }
                  {examConnectionDetails && examConnectionDetails.studentOnline === false &&
                    <Box position="absolute" top="0" left="0" right="0" zIndex="1" width="80%" m="0 auto">
                      <Notice noticeType="error" sx={{ root: styles.connectionNotice }}>Student connection lost</Notice>
                    </Box>
                  }
                  {has(examConnectionDetails, 'connectionErrors.gateway') &&
                    <Box position="absolute" top="0" left="0" right="0" zIndex="1" width="80%" m="0 auto">
                      <Notice noticeType="error" sx={{ root: styles.connectionNotice }}>
                        {examConnectionDetails.connectionErrors.gateway}
                      </Notice>
                    </Box>
                  }
                  {streamsSwitched ? screenshareDisplay : webcamDisplay}
                  <Box position="absolute" bottom="0" display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%">
                    {showMediaControls &&
                      <Box display="flex" flexGrow="1" justifyContent="center">
                        {state[slotId.current] &&
                          <SupervisorMediaControls
                            sharedMediaStatus={sharedMediaStatus}
                            onAudioToggle={() => toggleAudioMute(state[slotId.current].gatewayService)}
                            onVideoToggle={toggleVideoShare}
                            disabled={state[slotId.current].gatewayService === null}
                          />
                        }
                      </Box>
                    }
                    {(examState === EXAM_SESSION_STATES.pending && onboardingType === EXAM_SESSION_ONBOARDING_TYPE.HUMAN) &&
                      <Box m={2} mr="50px">
                        <Verification
                          examDetails={thisExamSession}
                          slotId={slotId.current}
                          onExamUnlocked={() => onExamUnlocked(state[slotId.current].gatewayService)}
                        />
                      </Box>
                    }
                  </Box>
                  {renderPeerSupervisorVideo &&
                    <Box id="peer-display" sx={{
                      m: 1,
                      height: "15%",
                      position: "absolute",
                      right: 0,
                      bottom: "88px",
                    }}>
                      <DisplayVideo
                        sessionId={slotId.current}
                        connectionDetails={thisExamSession.connectionDetails}
                        videoType="peer"
                        remoteStream={meetingStateProperties?.videoOverride?.stream}
                      />
                    </Box>
                  }
                </Box>
                {canRenderForActiveSlot && sharedMediaStatus.video &&
                  <Box id="self-display" position="absolute" height="15%" m={1} >
                    <DisplayVideo 
                      sessionId={slotId.current} 
                      connectionDetails={thisExamSession.connectionDetails}
                      videoType="supervisor" 
                    />
                  </Box>
                }
              </Box>
              <Box display="flex" flexDirection="column" maxWidth="30%" minWidth="30%">
                <Box
                  id="aside-panel"
                  position="relative"
                  flexGrow="1"
                  sx={{ ...styles.panel, ...styles.swapPanelControl }}
                  role="button"
                  aria-label="Swap web cam and screen share"
                  onClick={togglePanel}
                >
                  <Tooltip title="Swap webcam and screen share">
                    <SwapHoriz sx={styles.swapIcon} />
                  </Tooltip>
                  {streamsSwitched ? webcamDisplay : screenshareDisplay}
                </Box>
                <Box display="flex" flexDirection="column" flexGrow="3">
                  <Box id="aside-pin" sx={styles.monitorActionsPanel}>
                    <Section sx={styles.monitorActionsSection}>
                      <PinSessionButton slotId={slotId.current} />
                    </Section>
                  </Box>
                  <Box id="aside-clear-notifications" sx={styles.monitorActionsPanel}>
                    {state[slotId.current] &&
                      <Section sx={styles.monitorActionsSection}>
                        <ResolveNoticesButton
                          examSlotId={slotId.current}
                          gatewayService={state[slotId.current]?.gatewayService}
                        />
                      </Section>
                    }
                  </Box>
                  {state[slotId.current] &&
                    <MonitorChatContainer
                      gatewayService={state[slotId.current].gatewayService}
                      displayName={user ? user.fullName : 'Supervisor'}
                      userId={user ? user.id : ''}
                      authorType={SUPERVISOR.authorType}
                      renderContainer={state[slotId.current].gatewayService && user}
                      slotId={slotId.current}
                    >
                      <FlagChatToggle
                        slotId={slotId.current}
                        slotContextId={thisExamSession?.context?.id}
                        chatReady={state[slotId.current]?.gatewayService !== null}
                        showHistoryToggle={false}
                        userToDisplay={thisExamSession.student}
                      />
                    </MonitorChatContainer>
                  }
                </Box>
              </Box>
            </Box>
            {(audioStatus !== 'closed') &&
              <AudioVisualCheck
                examDetails={thisExamSession}
                audioStatus={audioStatus}
                setAudioStatus={setAudioStatus}
                setMediaShareError={setMediaShareErrors}
              />
            }
          </Box>
          <SuperviseInSessionNotifications
            notifications={notifications}
            clearAtIndex={handleClearNotificationAtIndex}
          />
        </>
      }
    </>
  )
}

export default SuperviseInSession;
