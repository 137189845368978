import React from "react";
import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import VideoStreamService from '../../services/VideoStreamService';
import streamSettings from '../../config/streamSettings';

const styles = {
  videoStreamContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  streamingVideoBox: {
    objectFit: 'contain',
    objectPosition: 'center top',
    flexGrow: '1',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  loadingVideoBox: {
    display: 'none',
  },
  mirrorVideoBox: {
    transform: 'scaleX(-1)',
    filter: 'FlipH',
  },
  waitingSpinner: {
    margin: 'auto',
  },
};

class VideoStream extends React.Component {

  constructor() {
    super();
    this.state = {
      playing: false,
    }
    this.videoRef = null;
  }

  startStreaming(newStreamId = null) {
    const { streamService, streamId, isLocal } = this.props;
    const useStreamId = newStreamId === null ? streamId : newStreamId;
    let { videoConstraints, videoBitRate } = this.props;
    if (!videoConstraints && isLocal) {
      videoConstraints = {
        video: true,
        audio: false,
      };
    }
    if (!videoBitRate && isLocal) {
      videoBitRate = streamSettings.defaultWebcamQualityBitrate;
    }
    streamService.add(useStreamId, isLocal, this.videoRef, videoConstraints, videoBitRate);
  }

  attachHandlers(targetStreamId = null) {
    const { streamService, streamId, onScreenShareStarted } = this.props;
    const attachStreamId = targetStreamId === null ? streamId : targetStreamId;
    streamService.registerDeviceHandler(attachStreamId, 'onMediaStarted', this.onVideoStarted);
    streamService.registerDeviceHandler(attachStreamId, 'onMediaStopped', this.onVideoStopped);
    streamService.registerDeviceHandler(attachStreamId, 'onError', this.onVideoError);
    streamService.registerDeviceHandler(attachStreamId, 'onMediaAttached', this.onVideoAttached);
    if (onScreenShareStarted) {
      streamService.registerDeviceHandler(attachStreamId, 'onScreenShareStarted', onScreenShareStarted);
    }
  }

  stopStreaming(oldStreamId = null) {
    const { streamService, streamId } = this.props;
    const removeStreamId = oldStreamId === null ? streamId : oldStreamId;
    if (streamService.isDeviceManaged(removeStreamId)) {
      streamService.remove(removeStreamId, true);
    }
  }

  unattachHandlers(targetStreamId = null) {
    const { streamService, streamId, onScreenShareStarted } = this.props;
    const unattachStreamId = targetStreamId === null ? streamId : targetStreamId;
    streamService.unregisterDeviceHandler(unattachStreamId, 'onMediaStarted', this.onVideoStarted);
    streamService.unregisterDeviceHandler(unattachStreamId, 'onMediaStopped', this.onVideoStopped);
    streamService.unregisterDeviceHandler(unattachStreamId, 'onError', this.onVideoError);
    streamService.unregisterDeviceHandler(unattachStreamId, 'onMediaAttached', this.onVideoAttached);
    if (onScreenShareStarted) {
      streamService.unregisterDeviceHandler(unattachStreamId, 'onScreenShareStarted', onScreenShareStarted);
    }
  }

  attachVideo(targetStreamId = null) {
    const { streamService, streamId } = this.props;
    const attachStreamId = targetStreamId === null ? streamId : targetStreamId;
    streamService.attachToElement(attachStreamId, this.videoRef);
  }

  unattachVideo(oldStreamId = null) {
    const { streamService, streamId } = this.props;
    const removeStreamId = oldStreamId === null ? streamId : oldStreamId;
    streamService.unattachFromElement(removeStreamId, this.videoRef);
  }

  componentDidMount() {
    const { manageStream } = this.props;
    this.attachHandlers();
    if (manageStream) {
      this.startStreaming();
    } else {
      this.attachVideo();
    }
  }

  componentWillUnmount() {
    const { streamId, manageStream } = this.props;
    const { playing } = this.state;
    this.unattachHandlers(streamId);
    if (manageStream) {
      this.stopStreaming(streamId);
    } else {
      this.unattachVideo(streamId);
    }
    if (playing) {
      this.onVideoStopped();
    }
  }

  componentDidUpdate(prevProps) {
    const { streamId: prevStreamId, slotId: prevSlotId } = prevProps;
    const { streamId, manageStream, slotId } = this.props;
    if ((prevStreamId !== streamId) || (slotId && slotId !== prevSlotId)) {
      // There has been a change in stream for this component

      if (prevStreamId !== null) {
        // Stop the previous stream that is being replaced
        this.unattachHandlers(prevStreamId)
        if (manageStream) {
          this.stopStreaming(prevStreamId);
        } else {
          this.unattachVideo(prevStreamId)
        }
      }
      // Handle the new stream
      this.attachHandlers(streamId);
      if (manageStream) {
        this.startStreaming(streamId);
      } else {
        this.attachVideo(streamId);
      }
    }
  }

  onVideoStarted = () => {
    const { onVideoStarted } = this.props;
    this.setState({ playing: true });

    if (onVideoStarted) {
      onVideoStarted();
    }
  };

  onVideoStopped = () => {
    const { onVideoStopped } = this.props;
    this.setState({ playing: false });

    if (onVideoStopped) {
      onVideoStopped();
    }
  };

  onVideoError = (errorMessage, disconnected, source) => {
    const { onVideoError } = this.props;
    this.setState({ playing: false });
    console.error('Video stream rendering error', errorMessage);
    if (onVideoError) {
      onVideoError(errorMessage, disconnected, source);
    }
  };

  onVideoAttached = (_deviceId, _peer, ref) => {
    if (ref === this.videoRef) {
      this.setState({ playing: true });
    } else {
      this.setState({ playing: false });
    }
  }

  render() {
    const { getRef, render, mirror } = this.props;
    const { playing } = this.state;

    if (!render) {
      return null;
    }

    const videoStyles = {
      ...(playing ? styles.streamingVideoBox : styles.loadingVideoBox),
      ...(mirror && styles.mirrorVideoBox),
    }

    return (
      <Box bgcolor={playing ? '#000000' : 'secondary.light'} sx={styles.videoStreamContainer}>
        <Box component="video"
          muted
          ref={(ref) => {
            this.videoRef = ref;
            if (getRef) { getRef(ref) }
          }}
          autoPlay
          sx={videoStyles}
          playsInline
        />
        {!playing && <CircularProgress color="secondary" sx={styles.waitingSpinner} />}
      </Box>
    );
  }
}

VideoStream.propTypes = {
  streamService: PropTypes.instanceOf(VideoStreamService).isRequired,
  streamId: PropTypes.string.isRequired,
  isLocal: PropTypes.bool.isRequired,
  manageStream: PropTypes.bool,
  onVideoStarted: PropTypes.func,
  onVideoStopped: PropTypes.func,
  onVideoError: PropTypes.func,
  onScreenShareStarted: PropTypes.func,
  videoConstraints: PropTypes.object,
  videoBitRate: PropTypes.string,
  getRef: PropTypes.func,
  render: PropTypes.bool,
  mirror: PropTypes.bool,
};

VideoStream.defaultProps = {
  manageStream: true,
  render: true,
  mirror: false,
};

export default VideoStream;
