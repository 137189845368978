const GATEWAY = {
  accept: {
    event: 'accept'
  },
  reject: {
    event: 'reject',
    data: {
      code: 403,
      error: 'That user is not authorised to join this exam session',
    }
  },
  addUser: {
    event: 'add-user',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      userName: 'Sample Supervisor',
      userType: 'SUPERVISOR',
    }
  },
  videoStart: {
    event: 'video-start',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      stream: 'w_ywie7ypuf6vn',
    }
  },
  videoStop: {
    event: 'video-stop',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      stream: 'w_ywie7ypuf6vn',
    }
  },
  screenshareStart: {
    event: 'screenshare-start',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  screenshareStop: {
    event: 'screenshare-stop',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  audioStart: {
    event: 'audio-start',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  audioStop: {
    event: 'audio-stop',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  audioMute: {
    event: 'audio-mute',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      requestedBy: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  audioUnmute: {
    event: 'audio-unmute',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      requestedBy: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  audioActive: {
    event: 'audio-active',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  audioInactive: {
    event: 'audio-inactive',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
    }
  },
  meetingUpdate: {
    event: 'meeting-update',
    data: {
      meeting: {
        examState: 'CAN_START'
      },
      requestedBy: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      reason: 'Supervisor opened exam gate',
    }
  },
  quit: {
    event: 'quit',
    data: {
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      reason: 'Browser window closed',
    }
  },
  end: {
    event: 'end',
    data: {
      reason: 'Supervisor authorisation',
    }
  },
  chatMsg: {
    event: 'chat-msg',
    data: {
      type: 'MESSAGE',
      userId: '30e12953-8ca1-4923-92f7-f5567ad6b8e9',
      timestamp: 1600812892,
      author: 'Sample User',
      text: 'This is a sample text message.',
      audience: 'ALL',
      read: false,
    }
  },
};

export default GATEWAY;