import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

const root = {
  boxSizing: 'border-box',
  padding: 2,
  marginBottom: 1,
}

/**
 * Generic wrapper for sections of the application, akin to
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section
 */
const Section = (props) => (
  <Paper component="section" sx={{...root, ...props.sx}}>
    {props.children}
  </Paper>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default Section;
