export const CONTEXT_FIELDS = {
  name: {
    display: 'Name',
    required: true,
    type: 'textbox',
    maxLength: '255',
  },
  description: {
    display: 'Description',
    required: false,
    type: 'textbox',
    maxLength: '255',
  },
  externalId: {
    display: 'External ID',
    required: false,
    type: 'textbox',
    maxLength: '255',
  },
  parent: {
    display: 'Parent context',
    required: true,
    type: 'select',
  },
}
