const EXAM_SESSION_FLAGS = [{
	"flag": {
		"name": "Room scan failed",
		"eventType": "room-scan-failed",
		"colour": "#888888",
		"autoOnly": null,
		"id": "bfbe999d-2e68-4ed6-9119-4621f877493b"
	},
	"timestamp": "2021-07-19T13:44:10.341+10:00",
	"note": "flagged",
	"flaggingUser": {
		"userName": "jodi.m.goodin@gmail.com",
		"fullName": "Jodi Goodin",
		"externalId": "ext-id",
		"isDisabled": false,
		"id": "bfbf37d9-6f2c-4da9-8175-c69ae390e4c6"
	},
	"frameUrl": null,
	"id": "47a57ca0-c26c-42f5-b2a4-d7aa9779bb59"
}, {
	"flag": {
		"name": "Room scan failed",
		"eventType": "room-scan-failed",
		"colour": "#888888",
		"autoOnly": null,
		"id": "bfbe999d-2e68-4ed6-9119-4621f877493b"
	},
	"timestamp": "2021-07-20T17:37:09.189+10:00",
	"note": "New flag",
	"flaggingUser": {
		"userName": "jodi.m.goodin@gmail.com",
		"fullName": "Jodi Goodin",
		"externalId": "ext-id",
		"isDisabled": false,
		"id": "bfbf37d9-6f2c-4da9-8175-c69ae390e4c6"
	},
	"frameUrl": null,
	"id": "55c94e2f-9e35-451e-b118-d4213ee175f7"
}, {
	"flag": {
		"name": "Health concern",
		"eventType": "health-concern",
		"colour": "#888888",
		"autoOnly": null,
		"id": "f856e6bb-90da-4f76-b486-84088068062b"
	},
	"timestamp": "2021-07-19T22:53:40+10:00",
	"note": "I am creating a flag with a very long optional note\nI am creating a flag with a very long optional",
	"flaggingUser": {
		"userName": "jodi.m.goodin@gmail.com",
		"fullName": "Jodi Goodin",
		"externalId": "ext-id",
		"isDisabled": false,
		"id": "bfbf37d9-6f2c-4da9-8175-c69ae390e4c6"
	},
	"frameUrl": null,
	"id": "e255d212-27e7-49c2-adb8-af1b7bf84269"
}, {
	"flag": {
		"name": "Tech issue",
		"eventType": "tech-issue",
		"colour": "#888888",
		"autoOnly": null,
		"id": "b8bc19a4-bb32-4018-bbd0-cee2c5228269"
	},
	"timestamp": "2021-07-20T17:41:39.37+10:00",
	"note": "new flag",
	"flaggingUser": {
		"userName": "jodi.m.goodin@gmail.com",
		"fullName": "Jodi Goodin",
		"externalId": "ext-id",
		"isDisabled": false,
		"id": "bfbf37d9-6f2c-4da9-8175-c69ae390e4c6"
	},
	"frameUrl": null,
	"id": "c6e2b872-6ba2-4fc9-9db6-8834b67aaa0a"
}, {
	"flag": {
		"name": "Yellow flag",
		"eventType": "yellow-flag",
		"colour": "#FF9E33",
		"autoOnly": null,
		"id": "731fddd4-188b-4024-b722-6d42f08d89e0"
	},
	"timestamp": "2021-07-20T17:47:12.329+10:00",
	"note": "Testing refresh",
	"flaggingUser": {
		"userName": "jodi.m.goodin@gmail.com",
		"fullName": "Jodi Goodin",
		"externalId": "ext-id",
		"isDisabled": false,
		"id": "bfbf37d9-6f2c-4da9-8175-c69ae390e4c6"
	},
	"frameUrl": null,
	"id": "8cabdf4b-f038-476e-a05b-f78e55127668"
}, {
	"flag": {
		"name": "Rest break (AAA)",
		"eventType": "rest-break",
		"colour": "#888888",
		"autoOnly": null,
		"id": "30b6af0e-686a-4317-a7b7-4fc721c2fdb3"
	},
	"timestamp": "2021-07-20T18:04:00.355+10:00",
	"note": "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww",
	"flaggingUser": {
		"userName": "jodi.m.goodin@gmail.com",
		"fullName": "Jodi Goodin",
		"externalId": "ext-id",
		"isDisabled": false,
		"id": "bfbf37d9-6f2c-4da9-8175-c69ae390e4c6"
	},
	"frameUrl": null,
	"id": "b1c44c36-c6a1-4953-aaab-2837361cbf28"
}];

export default EXAM_SESSION_FLAGS;
