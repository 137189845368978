import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { has, isEmpty } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import AudioStream from './AudioStream';
import PromiseWithAbort from '../../utils/PromiseWithAbort';
import logToGateway from '../../utils/logToGateway';

function AudioConnect(props) {
  const { state } = useContext(monitoringContext);
  const { slotId, connectionProps, setShowMediaControls } = props;

  const audioRef = useRef(null);

  const { audioStatus, setAudioStatus } = props;
  const { setAudioErrors } = props;

  let audioStreamService = has(state, slotId) ? state[slotId].audioService : undefined;
  let deviceInfo = state.deviceInfo;

  // Callback to log the error in the gateway
  const logErrorInGateway = useCallback((slot, obj, connection) => {
    const userId = has(connection, 'user.id') ? connection.user.id : 'no_user_id';
    const gatewayService = has(state, slot) ? state[slot]['gatewayService'] : undefined;

    if(obj !== undefined && gatewayService !== undefined) {
      logToGateway(
        state[slot].gatewayService,
        'assisted_supervisor_audio_connect',
        {error: obj.error, source: 'connectAssistedHandler', connection},
        userId,
        slot
      );
      console.error('[AudioConnect]: Error connecting the audio', obj);
    }
  }, [state]);

  // use effect to unmount
  useEffect(() => {
    const asyncAbort = new PromiseWithAbort();
    return (() => {
      if(audioStreamService) {
        asyncAbort.wrap(audioStreamService.exitAudioAssistedHandler())
        .then(() => {
          // unmounting obj successful
          return;
        })
        .catch((obj) => console.error('[AudioConnect]: Error unmounting the service', obj));
      }
    });
  }, [audioStreamService]);

  // use effect to join the audio
  useEffect(() => {
    const asyncAbort = new PromiseWithAbort();

    const audioConnect = () => {
      if (!isEmpty(connectionProps) && connectionProps !== undefined && audioRef.current !== null && audioStreamService !== undefined) {
        const getAudioPlayer = audioRef.current;
        const echoExtension = (deviceInfo === undefined) ?  'echo' : null;
        const inputDeviceId = has(deviceInfo, 'audioInputDevice') ? deviceInfo.audioInputDevice : 'default';
        const outputDeviceId = has(deviceInfo, 'audioOutputDevice') ? deviceInfo.audioOutputDevice : 'default';

        asyncAbort.wrap(audioStreamService.connectAssistedHandler(
          connectionProps, getAudioPlayer, false, echoExtension, inputDeviceId, outputDeviceId
        ))
        .then((obj) => {
          setAudioStatus(obj.status);
          setShowMediaControls(true);
        })
        .catch((obj) => {
          logErrorInGateway(slotId, obj, connectionProps);
          setAudioStatus(obj.status);
          setShowMediaControls(false);
          setAudioErrors('There was a problem sharing your microphone. Please check your device and allow access before trying again.');
        });
      }
    };

    audioConnect();
  }, [audioRef, audioStreamService, connectionProps, deviceInfo, slotId,
    setShowMediaControls, setAudioStatus, setAudioErrors, logErrorInGateway]);

  return (
    <Box
      sx={{
        width: '173px',
        height: '33px',
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        right: 0,
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: '10px',
      }}
      display={audioStatus === 'connected' ? "flex" : "none"}
    >
      <AudioStream
        streamService={state[slotId].audioService}
        connectionStatus={audioStatus}
        getRef={(ref) => {
          audioRef.current = ref;
        }}
        showVolumeControls={true}
        showMicrophoneMeter={false}
      />
    </Box>
  )
}

AudioConnect.propTypes = {
  slotId: PropTypes.string.isRequired,
  connectionProps: PropTypes.object.isRequired,
  setShowMediaControls: PropTypes.func.isRequired,
  audioStatus: PropTypes.string.isRequired,
  setAudioStatus: PropTypes.func.isRequired,
  setAudioErrors: PropTypes.func.isRequired,
};

export default AudioConnect;
