import { AI_TYPES, EXAM_SESSION_ONBOARDING_TYPE, EXAM_SESSION_STATES } from './examSessions';

function convertToList(obj) {
  return Object.entries(obj).map(([key, value]) => Object.assign([],
    {
      'key': key,
      'value': value
    }
  ));
}

export const EXAM_FIELDS = {
  examStartDateTime: {
    display: 'Exam start date time',
    required: true,
    type: 'dateTime',
    maxLength: '255',
    editable: true,
    canModifyAdd: true,
  },
  examEndDateTime: {
    display: 'Exam end date time',
    required: true,
    type: 'dateTime',
    maxLength: '255',
    editable: true,
    canModifyAdd: true,
  },
  studentId: {
    display: 'Student',
    required: true,
    type: 'textbox',
    maxLength: '255',
    editable: false,
    canModifyAdd: true,
  },
  aiType: {
    display: 'AI type',
    required: true,
    type: 'select',
    allowedValues: convertToList(AI_TYPES),
    editable: true,
    canModifyAdd: true,
  },
  onboardingType: {
    display: 'Onboarding type',
    required: true,
    type: 'select',
    allowedValues: convertToList(EXAM_SESSION_ONBOARDING_TYPE),
    editable: true,
    canModifyAdd: true,
  },
  state: {
    display: 'Exam state',
    required: false,
    type: 'select',
    allowedValues: convertToList(EXAM_SESSION_STATES),
    editable: false,
    canModifyAdd: true,
  },
  humanSupervised: {
    display: 'Human supervised',
    required: true,
    type: 'boolean',
    editable: true,
    canModifyAdd: true,
  },
  supervisionCompleted: {
    display: 'Dismissed from dashboard',
    required: false,
    type: 'boolean',
    editable: true,
    canModifyAdd: false,
  },
};
