import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { isEmpty } from 'lodash';
import ReviewChat from './ReviewChat';
import ReviewFlags from './ReviewFlags';
import ReviewInvestigations from './ReviewInvestigations';
import TabPanel from './TabPanel';
import { CanThey } from '../Can';
import { authContext } from '../../authContext';
import { EXAM_SESSION_CAPABILITIES } from '../../constants/examSessions';

function accessibilityProps(index) {
  return {
    id: `review-data-tab-${index}`,
    'aria-controls': `review-data-panel-${index}`,
  }
}

function ReviewDataTabs(props) {
  const { flags, meetings, frameErrors, withImageLinks, chats, recordingAvailable, studentName, investigations, examSlotId, examContextId } = props;
  const [currentTab, setCurrentTab] = React.useState(0);
  const { capabilityContextAccess } = useContext(authContext);
  const canViewIntegrity = CanThey(capabilityContextAccess, true, EXAM_SESSION_CAPABILITIES.manageIntegrity, { id: examContextId })
    || CanThey(capabilityContextAccess, true, EXAM_SESSION_CAPABILITIES.viewIntegrity, { id: examContextId });

  const handleChangeTab = (_event, newValue) => {
    setCurrentTab(newValue);
  }

  const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const badgeStyle = {
    zIndex: 0,
    mx: 0.75,
    '& .MuiBadge-badge': {
      right: -12,
    },
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
        <Tabs value={currentTab} onChange={handleChangeTab} aria-label="Review session data tabs" variant={isSm ? 'fullWidth' : 'scrollable'} >
          <Tab label="Flags" {...accessibilityProps(0)} />
          <Tab
            label={
              <Badge
                badgeContent={chats?.length}
                invisible={isEmpty(chats)}
                color="primary"
                sx={badgeStyle}
              >
                Chats
              </Badge>
            }
            {...accessibilityProps(1)}
          />
          {canViewIntegrity &&
            <Tab label="Investigations" {...accessibilityProps(2)} />
          }
        </Tabs>
      </Box>
      <TabPanel value={currentTab} name="review-data-panel" aria-labelledby={accessibilityProps(0).id} index={0} >
        <ReviewFlags
          flags={flags}
          meetings={meetings}
          withImageLinks={withImageLinks}
          frameErrors={frameErrors}
          examSlotId={examSlotId}
        />
      </TabPanel>
      <TabPanel value={currentTab} name="review-data-panel" aria-labelledby={accessibilityProps(1).id} index={1}>
        <ReviewChat
          chats={chats}
          meetings={meetings}
          recordingAvailable={recordingAvailable}
          studentName={studentName}
        />
      </TabPanel>
      {canViewIntegrity &&
        <TabPanel value={currentTab} name="review-data-panel" aria-labelledby={accessibilityProps(2).id} index={2}>
          <ReviewInvestigations
            investigations={investigations}
          />
        </TabPanel>
      }
    </Box>
  )
}

ReviewDataTabs.propTypes = {
  flags: PropTypes.array,
  meetings: PropTypes.array,
  frameErrors: PropTypes.object,
  examSlotId: PropTypes.string,
  contextId: PropTypes.string,
  withImageLinks: PropTypes.bool,
  recordingAvailable: PropTypes.bool,
  chats: PropTypes.array,
  studentName: PropTypes.string,
  investigations: PropTypes.object,
};

export default ReviewDataTabs;
