import React from 'react';
import Typography from '@mui/material/Typography';

function GateOpenFailedText(_props) {
  return (
    <>
      <Typography variant="h2">Something went wrong</Typography>
      <Typography variant="body1">An error has occurred</Typography>
      <Typography variant="body1">
        Click the <strong>Raise hand</strong> button. Your supervisor will attend to you shortly
      </Typography>
    </>
  )
}

export default GateOpenFailedText;