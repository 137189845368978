import { AUTH_BROKER_ENABLED, LOGIN_NONE } from '../constants/login';
/**
 * Most authentication-related functionality is handled by Okta, however there
 * are times where we need to grab values that Okta has written. For example,
 * we might need to pass an accessToken for backend requests that require auth.
 *
 * Generally, try and rely on @okta/okta-react though. Refer to
 * https://github.com/okta/okta-oidc-js/tree/master/packages/okta-react for
 * documentation.
 */
export default class AuthService {
  /**
   * Attempts to fetch an Okta access token from sessionStorage, will return
   * undefined if no token is found.
   *
   * The accessToken in this scenario refers to the actual token itself, and
   * not the object that encapsulates the accessToken, but that didn't stop
   * Okta giving them the same name...
   *
   * See https://developer.okta.com/authentication-guide/tokens/ for more
   * information.
   */
  static getAccessToken() {
    return this.getAuthBrokerToggleValue() === AUTH_BROKER_ENABLED
      ? this.getBrokeredAccessToken()
      : this.getDirectAccessToken();
  }

  /**
   * Attempts to fetch an access token from storage, will return
   * undefined if no token is found.
   */
  static getBrokeredAccessToken() {
    return sessionStorage.getItem('token');
  }

  static getDirectAccessToken() {
    const value = sessionStorage.getItem("okta-token-storage");
    if (value === null) return undefined;

    const oktaTokens = JSON.parse(value);
    if (oktaTokens.accessToken === undefined) return undefined;

    return oktaTokens.accessToken.accessToken;
  }

  static getAuthorizationHeader() {
    let accessToken = this.getAccessToken();
    if (!accessToken || (process.env.REACT_APP_AUTH_METHOD === LOGIN_NONE && process.env.NODE_ENV === 'development')) {
      return null;
    } else {
      return `Bearer ${accessToken}`
    }
  }

    /**
     * ensure conditions for which type of login to use aren't pre-built into the application
     *
     */
    static getAuthBrokerToggleValue() {
      return process.env.REACT_APP_AUTH_BROKER_TOGGLE;
    }
}
