import { SEARCH_OPTIONS } from "./search";

export const ALLOCATION_ERROR_DISPLAY = {
  id: {
    title: 'Error ID',
    fields: ['id'],
  },
  studentId: {
    title: 'Student ID',
    format: 'studentId',
    fields: ['examSlot.student.externalId'],
  },
  studentName: {
    title: 'Student name',
    fields: ['examSlot.student.fullName'],
  },
  examUnit: {
    title: 'Exam unit',
    format: 'firstWord',
    fields: ['examSlot.context.name'],
  },
  examTime: {
    title: 'Exam time',
    format: 'examDuration',
    fields: [
      'examSlot.examStartDateTime',
      'examSlot.examEndDateTime',
    ],
  },
  poolName: {
    title: 'Pool name',
    fields: ['allocationPool.name'],
  },
  attemptsMade: {
    title: 'Attempts made',
    fields: ['attemptCount'],
  },
  status: {
    title: 'Status',
    fields: ['status'],
  },
  firstAttempt: {
    title: 'First attempt',
    format: 'timeOnly',
    fields: ['attemptStartTime'],
  },
  lastAttempt: {
    title: 'Last attempt',
    format: 'timeOnly',
    fields: ['attemptLastUpdated'],
  },
  onboarder: {
    title: 'Onboarder',
    format: 'onboarderName',
    fields: [
      'examSlot.onboarders[0].fullName',
      'examSlot.onboardingType',
    ],
  },
  supervisor: {
    title: 'Supervisor',
    fields: [
      'examSlot.supervisors[0].fullName',
    ],
  },
};

export const ALLOCATION_ERROR_STATUS = {
  RETRYING: 'RETRYING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  RESOLVED: 'RESOLVED',
};

export const ALLOCATION_ERROR_SEARCH_OPTIONS = [
  SEARCH_OPTIONS.date, 
];