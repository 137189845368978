import React from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { themeObject } from '../../config/theme';
import { EXAM_SESSION_TYPE_LABELS as typeLabels } from '../../constants/examSessions';

const ExamSessionTypeLabel = (props) => {
  const { examType, humanSupervised } = props;
  const chipColours = humanSupervised
    ? {
        color: themeObject.palette.primary.main,
        backgroundColor: lighten(themeObject.palette.primary.main, 0.95),
      }
    : {
        color: themeObject.palette.warning.main,
        backgroundColor: lighten(themeObject.palette.warning.main, 0.95),
      };
  const displayText = typeLabels[examType] ? typeLabels[examType] : examType;

  return <Chip sx={chipColours} label={displayText} size="small"/>
}

ExamSessionTypeLabel.propTypes = {
  examType: PropTypes.string,
  humanSupervised: PropTypes.bool,
};

export default ExamSessionTypeLabel
