import { filter, isEmpty } from 'lodash';

const getDefaultCheckedCapabilities = (roles, capabilities, roleCapabilities) => {
  if(isEmpty(roles) || isEmpty(capabilities)) {
    return undefined;
  }

  let selections = [];
  roles.forEach((role) => {
    capabilities.forEach((capability) => {
      selections.push({
        'roleId': role['id'],
        'capabilityId': capability['id'],
        'role': role,
        'capability': capability,
        'isSelected': false,
      });
    });
  });
  roleCapabilities.forEach((rc) => {
    let roleId = rc['role']['id'];
    let capabilityId = rc['capability']['id'];

    filter(selections, {'roleId': roleId, 'capabilityId': capabilityId}).map((a) => a['isSelected'] = true);
  })
  return selections;
};

export default getDefaultCheckedCapabilities;