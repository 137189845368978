import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField } from '@mui/material';
import GatewayService from '../../services/GatewayService';
import { buttonLinkStyle } from '../../config/theme';
import gatewayConfig from '../../config/gateway';

const styles = {
  messageBox: {
    boxShadow: '0px -3px 16px 0px rgba(9, 76, 147, 0.31)',
    backgroundColor: 'white',
  },
  input: {
    height: '100%',
    padding: 1,
  },
};

function ChatForm(props) {
  const { gatewayService } = props;
  const [messageText, setMessageText] = useState('');
  const messageLengthError = gatewayService.sanitizeChatText(messageText).length > gatewayConfig.chatMaximumLength;

  const handleSend = (event) =>{
    event.preventDefault();
    const { gatewayService, displayName, onSend, userId } = props;

    if (messageText.length) {
      const message = {
        text: messageText,
        userId: userId,
        timestamp: Date.now(),
        displayName,
      }
      onSend(gatewayService, displayName, [message]);
    }
    setMessageText('');
  }

  return (
    <form onSubmit={handleSend}>
      <Box sx={styles.messageBox} display='flex' justifyContent='space-between'>
        <TextField
          autoFocus
          sx={styles.input}
          placeholder="Type a message..."
          value={messageText}
          onChange={(event) => setMessageText(event.target.value)}
          size="small"
          fullWidth
          InputProps={{ disableUnderline: true }}
          inputProps={{ 'aria-label': 'Enter chat message', maxLength: gatewayConfig.chatMaximumLength + 1}}
          helperText={messageLengthError ? 'Too many characters' : null}
          error={messageLengthError}
          variant="standard"
        />
        <Button
          disabled={messageLengthError || messageText === ''}
          type="submit"
          sx={{
            ...buttonLinkStyle,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          Send
        </Button>
      </Box>
    </form>
  );
}

ChatForm.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  displayName: PropTypes.string,
  userId: PropTypes.string,
  authorType: PropTypes.string,
  onSend: PropTypes.func,
};

export default ChatForm;
