import { default as Home } from './Home';
import { default as SuperviseSession } from './SuperviseSession';
import { default as MonitorSessions } from './MonitorSessions';
import { default as JoinOnboardSession } from './JoinOnboardSession';
import { default as JoinSession } from './JoinSession';
import { default as ReviewSession } from './ReviewSession';
import { default as ManageUsers } from './ManageUsers';
import { default as ManageContexts } from './ManageContexts';
import { default as ManageExamSessions } from './ManageExamSessions';
import { default as ManageRoles } from './ManageRoles';
import { default as ManageRoster } from './ManageRoster';
import { default as ListRecording } from './ListRecording';
import { default as UnresolvedNoticeReport } from './UnresolvedNoticeReport';
import { default as PageNotFound } from './PageNotFound';
import { default as Error } from './Error';
import { default as ManageSupervisorSessions } from './ManageSupervisorSessions';
import { default as ManageAllocationErrors } from './ManageAllocationErrors';
import { default as ManageAllocationPools } from './ManageAllocationPools';

const PAGES = {
  Home,
  SuperviseSession,
  MonitorSessions,
  JoinOnboardSession,
  JoinSession,
  ManageUsers,
  ManageContexts,
  ManageExamSessions,
  ManageSupervisorSessions,
  ManageRoles,
  ManageRoster,
  ListRecording,
  PageNotFound,
  UnresolvedNoticeReport,
  Error,
  ReviewSession,
  ManageAllocationErrors,
  ManageAllocationPools,
};

export default PAGES;
