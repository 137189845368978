import { isEqual } from 'lodash';

/**
 * Returns true if the current video sharing from supervisor should be stopped based on any change
 * in the meeting state properties received from the gateway
 * @param {object} prevMeetingStateProperties  The previous meeting state properties
 * @param {object} newMeetingStateProperties The updated meeting state properties
 * @param {object} sharedMediaStatus The current media sharing status of the supervisor
 * @returns True if self video should be stopped, otherwise false for no change required
 */
const shouldStopSupervisorDashboardSelfVideoOnMeetingChange = (
  prevMeetingStateProperties, 
  newMeetingStateProperties, 
  sharedMediaStatus,
) => {
  // If the supervisor has started/stopping webcam sharing, show an alert
  if (!isEqual(prevMeetingStateProperties?.videoOverride?.stream, newMeetingStateProperties?.videoOverride?.stream)) {
    
    if (newMeetingStateProperties?.videoOverride?.stream) {
      // There is a new supervisor stream
      if (sharedMediaStatus.video) {
        // Disable our own video stream if another supervisor is streaming
        return true;
      }
      
    } 
  }

  return false;
};

export default shouldStopSupervisorDashboardSelfVideoOnMeetingChange;