import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@mui/material';

function MediaConnectInfoStep(props) {
  return (
    <>
      <Typography variant="h2">Media check</Typography>
      <Typography variant="body1">
        Next, you must allow access to your microphone and webcam. You must also share your entire screen when prompted.
      </Typography>
      <Button
        color="primary"
        onClick={() => { props.onComplete({ flowStepIndex: props.flowStepIndex }) }}
        sx={{ textTransform: 'initial', mt: 2 }}
        variant="contained"
      >
        Continue
      </Button>
    </>
  )
}

MediaConnectInfoStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default MediaConnectInfoStep;