import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeviceSelector from '../form/DeviceSelector';

import { DEVICE_TYPES } from '../../constants/devices';

function AudioCheck(props) {
  const { devices, handleConfirm, updateDevices } = props;

  const updateDevice = (deviceType, deviceId) => {
    updateDevices({ ...devices, [deviceType]: deviceId });
  };

  return (
    <>
      <Typography variant="body1" gutterBottom>Say a few words to check if your audio is working. You should be able to hear what you said.</Typography>
      <Typography variant="body1" component="div" gutterBottom>
        If you didn't hear anything:
        <ol>
          <li>check your computer volume isn't muted</li>
          <li>try selecting a different audio device</li>
        </ol>
      </Typography>
      <Accordion style={{ maxWidth: "80%" }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="audio-options-content"
          id="audio-options-header"
        >
          <Typography variant="subtitle2" component="div">Audio options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <DeviceSelector
              kind='audioinput'
              updateDeviceOption={(deviceId, _deviceProps, _e) => { updateDevice(DEVICE_TYPES.AUDIO_INPUT, deviceId) }}
              value={devices?.[DEVICE_TYPES.AUDIO_INPUT]}
            />
            <DeviceSelector
              kind='audiooutput'
              updateDeviceOption={(deviceId, _deviceProps, _e) => { updateDevice(DEVICE_TYPES.AUDIO_OUTPUT, deviceId) }}
              value={devices?.[DEVICE_TYPES.AUDIO_OUTPUT]}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box display="flex" justifyContent="flex-end"  m={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleConfirm();
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
}

AudioCheck.propTypes = {
  devices: PropTypes.object,
  handleConfirm: PropTypes.func,
  updateDevices: PropTypes.func,
}

export default AudioCheck;