import React, {  useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, has } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import GatewayService from '../../services/GatewayService';
import { ACTIONS } from '../../constants/monitorSessions';

function MonitorChatContainer(props) {
  const { state, dispatch } = useContext(monitoringContext);
  const examSessions = useRef(state.examSessions);
  const { slotId, renderContainer } = props;

  const onSend = (gatewayService, fullName, messages = []) => {
    const audience = 'ALL';
    const thisExamSession = examSessions.current[slotId];
    const prevMessages = has(thisExamSession, 'chat') ? cloneDeep(thisExamSession.chat.messages) : [];

    messages.forEach(message => {
      if (message.text && message.text.length) {
        gatewayService.sendChat('MESSAGE', message.text, fullName, message.userId, audience);
        prevMessages.push(message);
      }
    });
    thisExamSession['chat']['messages'] = prevMessages;
    dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
  }

  const resetUnreadMessages = () => {
    const thisExamSession = examSessions.current[slotId];
    // reset if unread messages has ever been set. It should have an initial value from load
    if(has(thisExamSession, 'chat')) {
      thisExamSession['chat']['unreadMessages'] = 0;
      dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
    }
  }

  useEffect(() => {
    examSessions.current = state.examSessions;
  }, [state.examSessions]);


  if (!renderContainer) {
    // If the chat is flagged to be hidden, don't display it.
    return <></>;
  }

  const examSession = examSessions.current[slotId];

  const childrenWithProps = React.Children.map(props.children, child => {
    const childProps = {
      ...props,
      messages: examSession.chat.messages,
      onSend: onSend,
      unreadMessages: examSession.chat.unreadMessages,
      resetUnreadMessages: resetUnreadMessages,
    };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }
    return child;
  });

  return (<>{childrenWithProps}</>);

}

MonitorChatContainer.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  displayName: PropTypes.string,
  userId: PropTypes.string,
  authorType: PropTypes.string,
  renderContainer: PropTypes.bool,
  slotId: PropTypes.string,
};
MonitorChatContainer.defaultProps = {
  renderContainer: true,
};

export default MonitorChatContainer;
