import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const styles = {
  root: {
    boxSizing: 'border-box',
    padding: 1,
    my: 2,
    mx: 0,
    position: 'relative',
  },
  error: {
    backgroundColor: '#ffefee',
      '& p': {
        color: 'error.main',
      },
  },
  success: {
    backgroundColor: '#DFF0D8',
    '& p': {
      color: '#3E773C',
    },
  },
  warning: {
    backgroundColor: '#FAF2E7',
    '& p': {
      color: '#000000',
    },
  },
  notice: {
    backgroundColor: 'grey.100',
    '& p': {
      color: 'grey.main',
    },
  },
  text: {
    fontSize: 'inherit',
  },
  closeButton: {
    position: 'absolute',
    right: 0.5,
    top: 0.5,
    color: 'grey.500',
    padding: 0.5,
  },
};

/**
 * Generic persistent notice display (errors, warnings, notices). For alerts use snackbars.
 */
const Notice = (props) => (
  <Box sx={{ ...styles.root, ...styles[props.noticeType], ...props.sx?.root,  ...props.sx?.[props.noticeType] }} >
    <Typography component="div" sx={{ ...styles.text, ...props.sx?.text }}>{props.children}</Typography>
    {props.enableClose &&
      <IconButton
        sx={{ ...styles.closeButton, ...props.sx?.closeButton }}
        onClick={props.onClose}
        aria-label="Close this notice"
        size="large">
        <CloseIcon />
      </IconButton>
    }
  </Box>
);

Notice.propTypes = {
  children: PropTypes.node.isRequired,
  noticeType: PropTypes.string.isRequired,
  enableClose: PropTypes.bool,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};

export default Notice;
