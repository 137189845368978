import { ACTIONS } from '../../../constants/joinSession';
import { EXAM_SESSION_STATES } from '../../../constants/examSessions';

const onMeetingUpdate = (dispatch) => {
  /**
   * Handle an event received that indicates the meeting state has been updated.
   * @param {object} updatedMeetingData The updated meeting information received from the gateway.
   * @param {string} reason Any reason for the change
   */
  return (updatedMeetingData, _reason) => {
    if (Object.values(EXAM_SESSION_STATES).includes(updatedMeetingData?.examState)) {
      dispatch({ type: ACTIONS.UPDATE_EXAM_STATE, value: updatedMeetingData?.examState });
    }
  };
};

export default onMeetingUpdate;