import { cloneDeep, isEmpty, sortBy } from 'lodash';
import { getProgressForStepAndRemoveAssignedProgress } from './getProgressFromStep';

const addDisplayGroupStepIndexToStep = (flowStep, flowSteps) => {
  const displayGroupStepIndex = flowSteps.findIndex(fs => fs.onboardStep.id === flowStep.displayGroupStepId);
  if (displayGroupStepIndex !== -1 || displayGroupStepIndex !== undefined) {
    return ({
      ...flowStep,
      displayGroupStepIndex: displayGroupStepIndex,
    });
  }
  return ({ ...flowStep });
};

const addMustRecompleteToProgress = (progressItem, flowStep) => {
  const mustRecomplete = Boolean(flowStep.onboardStep.mustRepeat && isEmpty(progressItem.skippedBy));
  return { ...progressItem, mustRecomplete: mustRecomplete }
};

const mapSortedOnboardStepsAndProgress = (flowSteps, progressItems) => {
  if (isEmpty(flowSteps) || isEmpty(progressItems)) {
    return;
  }
  const workingProgressItems = cloneDeep(progressItems);
  const sortedSteps = sortBy(flowSteps, ['position']);
  return sortedSteps.map(flowStep => {
    let matchingProgress = getProgressForStepAndRemoveAssignedProgress(workingProgressItems, flowStep);
    return {
      flowStep: addDisplayGroupStepIndexToStep(flowStep, sortedSteps),
      progress: addMustRecompleteToProgress(matchingProgress, flowStep),
    };
  });
};

export default mapSortedOnboardStepsAndProgress;