import React from 'react';
import PropTypes from "prop-types";
import Bowser from "bowser";
import browserConfig from '../../config/browser';
import BrowserWarning from "../content/BrowserWarning";

/**
 * This takes the view of the current page and wraps it with a warning popup if the current browser
 * detected doesn't match the minimum specified browser type and version.
 */
class BrowserCheckWrapper extends React.Component {
  state = {
    browserWarningPopupOpen: false,
    browserType: undefined,
  };

  componentDidMount() {
    const { onClose } = this.props;
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserType = browser.getBrowserName() + ' v' + browser.getBrowserVersion() + ' on ' + browser.getOSName();
    const isValidBrowser = browser.satisfies(browserConfig.supported);
    let browserStateChanges = {
      browserType,
      browserWarningPopupOpen: !isValidBrowser,
      browser,
    };
    this.setState(browserStateChanges, () => {
      if (isValidBrowser && onClose) {
        onClose(true, browser);
      }
    });
  }

  closeBrowserWarning = () => {
    const { onClose } = this.props;
    const { browser, isValidBrowser } = this.state;
    this.setState({browserWarningPopupOpen: false}, () => {
      if (onClose) {
        onClose(isValidBrowser, browser)
      }
    });

  }

  render() {
    const { children } = this.props;
    const { browserWarningPopupOpen } = this.state;
    return (
      <>
        {browserWarningPopupOpen &&
          <BrowserWarning
            open={browserWarningPopupOpen}
            onClose={this.closeBrowserWarning}
            currentBrowser={this.state.browserType}
          />
        }
        {!browserWarningPopupOpen && children}
      </>
    );
  }
}

BrowserCheckWrapper.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default BrowserCheckWrapper;
