import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

// only allow html tags that would occur within a string
function SafeInnerHtml(props) {
  return (
    <span
      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.htmlString, {ALLOWED_TAGS: ['strong', 'em']})}}
    />
  )
}

SafeInnerHtml.propTypes = {
  htmlString: PropTypes.string,
};

export default SafeInnerHtml;
