import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { CheckCircle, PhotoCameraOutlined } from '@mui/icons-material';
import getStepsOfType from '../step/helper/getStepsOfType';
import { isProgressComplete } from '../step/helper/isProgressComplete';
import { ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import { lightestGrey } from '../../config/theme';

function IdVerificationSelector(props) {
  const { onboardProgressSteps, openAddPhoto } = props;
  const idVerificationIdSteps = getStepsOfType(onboardProgressSteps, ONBOARD_ACTION_TYPES.ID_VERIFICATION_ID.mapper);
  const idVerificationFaceSteps = getStepsOfType(onboardProgressSteps, ONBOARD_ACTION_TYPES.ID_VERIFICATION_FACE.mapper);

  const getVerificationTypeDisplay = (progressStep, typeConfig) => {
    return (
      <ListItem
        key={progressStep?.progress?.id}
        sx={ { bgcolor: lightestGrey, position: 'relative', mb: '1px' }}
      >
        <ListItemAvatar>
          <Avatar sx={{ color: 'secondary.dark', bgcolor: 'grey.300' }}>{typeConfig?.icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={typeConfig.helperText}
          secondary={isProgressComplete(progressStep.progress)
            ? <Box component="span" display="flex" alignItems="center" gap={1}>
                <CheckCircle color="success" fontSize="small"/>
                {progressStep.progress?.complete ? <>Image added.</> : <>Skipped</>}
              </Box>
            : <></>}
          sx={{ pr: 4 }}
        />
        <ListItemSecondaryAction>
          <Button
            color="primary"
            disabled={isProgressComplete(progressStep.progress)}
            onClick={() => openAddPhoto(progressStep, typeConfig)}
            startIcon={<PhotoCameraOutlined />}
            sx={{ minWidth: 0, '& .MuiButton-startIcon': { ml: { xs: 0, md: -0.5 }, mr: { xs: 0, md: 1 } } }}
            variant="contained"
          >
            <Box component="span" sx={{ display: { xs: 'none', md: 'inline' }, minWidth: { xs: 0, md: '116px' } }}>{typeConfig.captureButtonText || 'Take photo'}</Box>
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  };

  return (
    <>
      {(!isEmpty(idVerificationIdSteps) || !isEmpty(idVerificationFaceSteps)) &&
        <List>
          {!isEmpty(idVerificationFaceSteps) &&
            <>
              {idVerificationFaceSteps.map((idVerificationFaceStep) => {
                return getVerificationTypeDisplay(idVerificationFaceStep, ONBOARD_ACTION_TYPES.ID_VERIFICATION_FACE)
              })}
            </>
          }
          {!isEmpty(idVerificationIdSteps) &&
            <>
              {idVerificationIdSteps.map((idVerificationIdStep) => {
                return getVerificationTypeDisplay(idVerificationIdStep, ONBOARD_ACTION_TYPES.ID_VERIFICATION_ID)
              })}
            </>
          }
        </List>
      }
    </>
  )
}

IdVerificationSelector.propTypes = {
  onboardProgressSteps: PropTypes.array,
  openAddPhoto: PropTypes.func.isRequired,
};

export default IdVerificationSelector;