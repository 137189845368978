import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  buttonItem: {
    margin: 1,
  },
  dialogTitleRoot: {
    padding: 2,
    backgroundColor: 'grey.900',
    color: 'grey.50',
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'grey.500',
  },
  dialogContentRoot: {
    p: 0,
  },
  formContainer: {
    p: 2,
  },
};

function FormPopup(props) {
  const {
    title,
    onClose,
    open,
    content,
    maxWidth,
    stretch,
  } = props;
  return (
    <Dialog maxWidth={maxWidth} open={open} onClose={onClose} fullWidth={stretch}>
      <DialogTitle
        color="inherit"
        sx={styles.dialogTitleRoot}
      >
        <Box sx={styles.buttonItem}>{title}</Box>
        <IconButton
          sx={styles.closeButton}
          onClick={onClose}
          aria-label="Close this dialog"
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContentRoot}>
        <Box sx={styles.formContainer}>
          {content}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

FormPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  maxWidth: PropTypes.string,
  stretch: PropTypes.bool,
}

FormPopup.defaultProps = {
  maxWidth: 'md',
  stretch: false,
};

export default FormPopup;
