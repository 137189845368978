import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { identity, pickBy } from 'lodash';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import FilterButton from '../form/FilterButton';
import HoverPopover from '../popup/HoverPopover';
import SearchField from './SearchField';
import {
  DEFAULT_MIN_TIME_ONLINE,
  SEARCH_FILTERS,
  SEARCH_FILTER_PRESETS,
  SEARCH_GROUP_OPTIONS,
  STUDENT_ONLINE_OFFSET
} from '../../constants/examSessions';

function AdvancedSessionSearchOptions(props) {
  const { filterStates, setAllFilters, setFilter, triggerGroupOptions, triggerOpen } = props;
  const [open, setOpen] = useState(false);
  const [groupByDisabled, setGroupByDisabled] = useState(false);
  const numActiveFilters = Object.keys(pickBy(filterStates, (state, key) => {
    return identity(state) && key !== 'groupBy'
  })).length;

  const sectionStyle = {
    padding: 2,
    backgroundColor: '#f5f6f6',
    borderLeft: '2px solid',
    borderColor: 'primary.main',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  };

  const sectionHeadingStyle = {
    color: 'grey.700',
    mb: 1,
    textTransform: 'uppercase',
  };

  const filterStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    alignItems: 'center',
    flexGrow: 1,
  };

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  useEffect(() => {
    const noFilters = Object.keys(
      pickBy(filterStates, (filterValue, key) => {
        return identity(filterValue) && key !== 'groupBy'
      })).length === 0;

    if((noFilters && !triggerGroupOptions) && !groupByDisabled) {
      setGroupByDisabled(true);
      setFilter('groupBy', SEARCH_GROUP_OPTIONS.UNIT.value)
    } else if((!noFilters || triggerGroupOptions) && groupByDisabled) {
      setGroupByDisabled(false);
    }
  }, [triggerGroupOptions, filterStates, groupByDisabled, setFilter]);

  const setOnlineFilter = (filterName, filterState) => {
    const timeOnline = !filterState ? '' : (filterStates.studentOnlineOffset || DEFAULT_MIN_TIME_ONLINE);
    setAllFilters({...filterStates, [filterName]: filterState, 'studentOnlineOffset': timeOnline});
  }

  return (
    <Box display="flex" flexDirection="column-reverse">
      <Button
        onClick={() => setOpen(!open)}
        endIcon={open ? <ExpandLess/> : <ExpandMore/>}
        sx={{
          alignSelf: 'self-end',
          bgcolor: 'white',
          borderRadius: '0 0 4px 4px',
          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
          fontWeight: 'normal',
          mb: 2,
          textTransform: 'initial',
        }}
        variant="contained"
        color="primary-light"
      >
        Advanced options
        {numActiveFilters > 0 &&
          <Box
            bgcolor="grey.200"
            borderRadius={1}
            lineHeight="1rem"
            ml={0.5}
            p={0.5}
          >{numActiveFilters}</Box>
        }
      </Button>
      {open &&
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap={3} p={1} pb={2} bgcolor="white" boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 0%)">
          <Box display="flex" flexWrap="wrap" alignItems="stretch" gap={3}>
            <Box sx={sectionStyle}>
              <Typography variant="caption" component="h2" sx={sectionHeadingStyle}>Student</Typography>
              <Box sx={filterStyle} >
                <FilterButton
                  filterState={filterStates?.studentOnline}
                  label="Online"
                  name="studentOnline"
                  setFilterState={setOnlineFilter}
                />
                <span>
                  <SearchField
                    field={STUDENT_ONLINE_OFFSET}
                    fieldProps={{
                      disabled: !filterStates?.studentOnline,
                      size: 'small',
                      sx: { maxWidth: '120px' },
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    handleChange={setFilter}
                    fieldValue={filterStates?.studentOnlineOffset || ''}
                  />
                  <Tooltip title="Student online longer than x minutes">
                    <QuestionMarkIcon sx={{ fontSize: '1rem' }}/>
                  </Tooltip>
                </span>
                <FilterButton
                  filterState={filterStates?.selfOnboard}
                  label="Self onboarding"
                  name="selfOnboard"
                  setFilterState={setFilter}
                />
              </Box>
            </Box>
            <Box sx={sectionStyle}>
              <Typography variant="caption" component="h2" sx={sectionHeadingStyle}>Exam</Typography>
              <Box sx={filterStyle}>
                <FilterButton
                  filterState={filterStates?.gateClosed}
                  label="Gate closed"
                  name="gateClosed"
                  setFilterState={setFilter}
                />
              </Box>
            </Box>
            <Box sx={sectionStyle}>
              <Typography variant="caption" component="h2" sx={sectionHeadingStyle}>Display</Typography>
              <Box sx={filterStyle}>
                <TextField
                  select
                  disabled={groupByDisabled}
                  InputLabelProps={{shrink: true}}
                  label="Group by"
                  onChange={(event) => setFilter('groupBy', event.target.value)}
                  size="small"
                  sx={{ bgcolor: 'white', minWidth: '100px' }}
                  value={filterStates?.groupBy || SEARCH_GROUP_OPTIONS.UNIT.value}
                >
                  {Object.values(SEARCH_GROUP_OPTIONS).map((option) => {
                    return (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Box>
            </Box>
          </Box>
          <Box sx={{...sectionStyle, borderColor: 'warning.light'}}>
            <Typography variant="caption" component="h2" sx={sectionHeadingStyle}>Presets</Typography>
            <Box sx={{...filterStyle, flexDirection: 'column', alignItems: 'flex-start'}}>
              {SEARCH_FILTER_PRESETS.map(preset => {
                return (
                  <Box display="flex" key={preset.name}>
                    <Button
                      color="warning"
                      onClick={() => setAllFilters(preset.filterValues)}
                      sx={{ bgcolor: 'white', fontWeight: 'normal', textTransform: 'initial' }}
                      variant="outlined"
                    >{preset.display}</Button>
                    <HoverPopover
                      data={preset.filterValues}
                      dataDisplayOptions={SEARCH_FILTERS}
                      dataDisplayValues={SEARCH_GROUP_OPTIONS}
                      name={preset.name}
                      title="Sets the search options to"
                    />
                  </Box>
                )
              })}

            </Box>
          </Box>
        </Box>
      }
    </Box>
  )
}

AdvancedSessionSearchOptions.propTypes = {
  filterStates: PropTypes.object,
  setAllFilters: PropTypes.func,
  setFilter: PropTypes.func,
  triggerGroupOptions: PropTypes.bool,
  triggerOpen: PropTypes.bool,
};

export default AdvancedSessionSearchOptions;
