import React from 'react';

function PageNotFound(_props) {
  return (
    <>
      <h2>That page doesn't exist</h2>
      <p>
        Try the navigation in the left hand side menu. If you're still
        having issues please contact us
      </p>
    </>
  );
}

export default PageNotFound;
