import { CONFERENCE_ERROR_CATEGORIES } from '../../../constants/errors';
import { ACTIONS, ONBOARD_ACTION_TYPES, SERVICES } from '../../../constants/joinSession';
import { SHARED_MEDIA_STATES as MEDIA_STATE } from '../../../constants/mediaStates';
import { default as GATEWAY_CONFIG } from '../../../config/gateway';

const onMeetingTerminate = (joinDispatch, getServices, setConnectionError) => {
  /**
   * Handle an event received from the gateway indicating the meeting has been terminated or ended
   * @param {object} updatedMeetingData The updated meeting information received from the gateway.
   * @param {string} reason Any reason for the meeting shutdown.
   */
  return (reason) => {
    const { audioService, gatewayService, videoService } = getServices();

    if (videoService) {
      videoService.unregisterDeviceHandlers('all');
      videoService.close('video');
      videoService.close('screenshare');
    }
    if (audioService) {
      audioService.unregisterHandlers();
      audioService.exitAudio();
    }
    if (gatewayService) {
      gatewayService.unregisterHandler('all');
      gatewayService.close();
    }

    joinDispatch({
      type: ACTIONS.SET_SHARED_MEDIA_STATUS,
      value: {
        webcam: MEDIA_STATE.DISABLED,
        audio: MEDIA_STATE.DISABLED,
        desktop: MEDIA_STATE.DISABLED
      }
    });
    joinDispatch({ type: ACTIONS.SET_GATEWAY_CONNECTION_OPEN, value: false });
    joinDispatch({ type: ACTIONS.SET_SERVICE, value: { serviceType: SERVICES.GATEWAY_SERVICE, service: null } });

    setConnectionError(CONFERENCE_ERROR_CATEGORIES.CONNECT, {}, true);

    const action = reason === GATEWAY_CONFIG.events.MEETING_END ? ONBOARD_ACTION_TYPES.ENDED.mapper : ONBOARD_ACTION_TYPES.TERMINATED.mapper;
    joinDispatch({ type: ACTIONS.SET_CURRENT_ACTION, value: { actionType: action, actionStepIndex: -1 } });
  };
};

export default onMeetingTerminate;