import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import FormPopup from '../popup/FormPopup';
import UpdateAAAForm from './UpdateAAAForm';

const styles = {
  fixedContainer: {
    position: 'absolute',
    top: '0',
    right: '50px',
    zIndex: 111,
  },
};

function UpdateAAAButton(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const { hasUserUpdated, emptySelectedUsers, selectedUsers, setHasUserUpdated, setKeepSelectedUsers } = props;

  const closePopup = () => {
    setPopupOpen(false);
    if (hasUserUpdated) {
      setKeepSelectedUsers(false);
      emptySelectedUsers();
    }
  };
  const multipleEditButton = (
    <div style={styles.fixedContainer}>
      <Badge
        sx={{ position: 'fixed', '.MuiBadge-badge': { zIndex: 113 } }}
        overlap="circular"
        badgeContent={selectedUsers.length}
        color="secondary"
      >
        <Fab
          aria-label="Update AAA status for the selected people"
          color="primary"
          onClick={() => setPopupOpen(true)}
          sx={{ zIndex: 112 }}
        >
            AAA
        </Fab>
      </Badge>
    </div>
  )

  return(
    <>
      {multipleEditButton}
      <FormPopup
        title="Change AAA status"
        open={popupOpen}
        onClose={closePopup}
        content={
          <UpdateAAAForm
            selectedUsers={selectedUsers}
            setHasUserUpdated={setHasUserUpdated}
            setKeepSelectedUsers={setKeepSelectedUsers}
          />
        }
        stretch={true}
        maxWidth="sm"
      />
    </>
  );
}

UpdateAAAButton.propTypes = {
  emptySelectedUsers: PropTypes.func,
  hasUserUpdated: PropTypes.bool,
  selectedUsers: PropTypes.array.isRequired,
  setHasUserUpdated: PropTypes.func,
  setKeepSelectedUsers: PropTypes.func,
};

export default UpdateAAAButton;
