import React from 'react';
import { has } from 'lodash';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import DesktopAccessDisabledOutlinedIcon from '@mui/icons-material/DesktopAccessDisabledOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RefreshPageButton from '../form/RefreshPageButton';
import ReconnectMediaButton from '../form/ReconnectMediaButton';
import { AuthConsumer } from '../../authContext';
import { STUDENT_ERROR_DISPLAY } from '../../constants/errors';
import { TROUBLESHOOTING_LINKS_MAPPER } from '../../constants/contentOverrides';
import SafeUrlLink from '../SafeUrlLink';


const textSpacing = { pl: 2, pr: 4, py: 1, }

const RaiseHandSection = (props) => {
  const { gatewayConnectionOpen } = props;

  if (gatewayConnectionOpen) {
    return (
      <Typography variant="body1" sx={{ ...textSpacing }}>
        <strong>If that doesn't work</strong>, let your online supervisor know via the 'Raise hand' button
        or chat function, that you require IT assistance.
      </Typography>
    );
  } else {
    return (
      <Typography variant="body1" sx={{ ...textSpacing }}>
        <strong>If that doesn't work</strong>, you will need to call IT Support.
      </Typography>
    );
  }
};

const ErrorCodeDisplay = (props) => {
  const { errorCode } = props;

  return (
    <Box position="absolute" bottom={8} right={16}>
      <Typography variant="caption" sx={{ fontSize: 10, color: 'grey.400' }}>
        [{errorCode}]
      </Typography>
    </Box>
  );
}

const DefaultErrorContent = () => {
  return (
    <div id="default-error-content">
      <Typography variant="body1" sx={{ ...textSpacing }}>Click on Refresh to try reloading your page.</Typography>
      <Box sx={{ ...textSpacing }}><RefreshPageButton /></Box>
    </div>
  );
};

const AudioErrorContent = (props) => {

  const allowPermissionLink = props.supportLink 
    ? <SafeUrlLink
      href={props.supportLink}
      target="_blank">allow browser permission</SafeUrlLink>
    : 'allow browser permission';

  return (
    <div id="audio-error-content">
      <Typography variant="h3" sx={{ ...textSpacing }}>Try the following steps</Typography>
      <Typography variant="body1" component="ol" sx={{ "& li": { ...textSpacing } }}>
        <li>
          Try refreshing your page and connecting again.<br />
          <RefreshPageButton />
        </li>
        <li>Check your microphone is plugged in.</li>
        <li>Make sure you {allowPermissionLink} to use your microphone.</li>
      </Typography>
    </div>
  );
};

const WebcamErrorContent = (props) => {
  const { startSharingVideo, mediaStatus, mediaWarning, supportLink } = props;

  const allowPermissionLink = supportLink 
    ? <SafeUrlLink
      href={supportLink}
      target="_blank">allow browser permission</SafeUrlLink>
    : 'allow browser permission';

  return (
    <div id="webcam-error-content">
      <Typography variant="h3" sx={{ ...textSpacing }}>Try the following steps</Typography>
      <Typography variant="body1" component="ol" sx={{ "& li": { ...textSpacing } }}>
        <li>
          Click the 'Share webcam again' button<br />
          <ReconnectMediaButton
            mediaType="webcam"
            mediaState={mediaStatus}
            mediaWarning={mediaWarning}
            reconnectFunc={() => startSharingVideo("webcam")} />
        </li>
        <li>Check your webcam is plugged in and is enabled in your system preferences.</li>
        <li>Make sure you {allowPermissionLink} to use your webcam.</li>
        <li>Click the 'Refresh page' button.<br />
          <RefreshPageButton />
        </li>
      </Typography>
    </div>
  );
};

const ScreenshareErrorContent = (props) => {
  const { startSharingVideo, mediaStatus, mediaWarning, supportLink } = props;

  const allowPermissionLink = supportLink 
    ? <SafeUrlLink
      href={supportLink}
      target="_blank">allow browser permission</SafeUrlLink>
    : 'allow browser permission';

  return (
    <div id="screenshare-error-content">
      <Typography variant="h3" sx={{ ...textSpacing }}>Try the following steps</Typography>
      <Typography variant="body1" component="ol" sx={{ "& li": { ...textSpacing } }}>
        <li>
          Click the 'Share desktop again' button<br />
          <ReconnectMediaButton
            mediaType="desktop"
            mediaState={mediaStatus}
            mediaWarning={mediaWarning}
            reconnectFunc={() => startSharingVideo("desktop")} />
        </li>
        <li>Make sure you {allowPermissionLink} to share your screen.</li>
        <li>Make sure you select 'Share entire screen'.</li>
        <li>Click the 'Refresh page' button.<br />
          <RefreshPageButton />
        </li>
      </Typography>
    </div>
  );
};

const Troubleshooting = (props) => {
  const {
    category,
    errorCode,
    gatewayConnectionOpen,
    startSharingVideo,
    streamStatus,
    streamWarnings
  } = props;

  const errorConfig = has(STUDENT_ERROR_DISPLAY, category) ? STUDENT_ERROR_DISPLAY[category] : STUDENT_ERROR_DISPLAY.default;

  const displayErrorContent = (category, supportLinkOverrides) => {
    const supportLinkHref = has(supportLinkOverrides, errorConfig.optionalSupportLinkOverrideKey)
      ? supportLinkOverrides[errorConfig.optionalSupportLinkOverrideKey]
      : undefined;

    switch (category) {
      case 'audioError':
        return <AudioErrorContent supportLink={supportLinkHref} />;
      case 'webcamError':
        return <WebcamErrorContent
          startSharingVideo={startSharingVideo}
          mediaStatus={streamStatus.webcam}
          mediaWarning={streamWarnings.webcam}
          supportLink={supportLinkHref} />;
      case 'screenshareError':
        return <ScreenshareErrorContent
          startSharingVideo={startSharingVideo}
          mediaStatus={streamStatus.desktop}
          mediaWarning={streamWarnings.desktop}
          supportLink={supportLinkHref} />;
      default:
        return <DefaultErrorContent supportLink={supportLinkHref} />;
    }

  };

  const headerIcon = {
    NoMicrophone: <MicOffOutlinedIcon sx={errorConfig.headerIconStyle} />,
    NoWebcam: <VideocamOffOutlinedIcon sx={errorConfig.headerIconStyle} />,
    NoScreenshare: <DesktopAccessDisabledOutlinedIcon sx={errorConfig.headerIconStyle} />,
    GenericError: <ErrorOutlineOutlinedIcon sx={errorConfig.headerIconStyle} />,
  };

  return (
    <AuthConsumer>
      {({ contentOverrides }) => (
        <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="space-between" overflow="auto">
          <Box>
            <Box
              display="flex"
              minHeight="140px"
              height="40%"
              width="100%"
              justifyContent="center"
              alignItems="center"
              gap={4}
              bgcolor={errorConfig.headerBackgroundColour}
              p={1}
              boxSizing="border-box"
            >
              <Typography variant="h2" color={errorConfig.headerTextColour} textAlign="center" sx={{ margin: 0 }}>
                {errorConfig.headerText ? errorConfig.headerText : "Uh oh!"}
              </Typography>
              {headerIcon[errorConfig.headerIcon]}
            </Box>
          </Box>
          <Box display="flex" flex="1" p={6} alignItems="center" maxWidth="100%" position="relative">
            <Box mb={3} width="100%">
              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-around">
                {displayErrorContent(errorConfig.contentComponent, contentOverrides[TROUBLESHOOTING_LINKS_MAPPER])}
                <RaiseHandSection gatewayConnectionOpen={gatewayConnectionOpen} />
                <ErrorCodeDisplay errorCode={errorCode} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </AuthConsumer>
  );
}

Troubleshooting.propTypes = {
  category: PropTypes.string.isRequired,
  errorCode: PropTypes.string.isRequired,
  gatewayConnectionOpen: PropTypes.bool,
  startSharingVideo: PropTypes.func.isRequired,
  streamStatus: PropTypes.object.isRequired,
  streamWarnings: PropTypes.object,
};

Troubleshooting.defaultProps = {
  streamWarnings: {
    webcam: undefined,
    audio: undefined,
    desktop: undefined,
  },
};

export default Troubleshooting;
