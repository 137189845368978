import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

const NotificationBar = (props) => (
  <Snackbar
    anchorOrigin={props.anchorOrigin}
    autoHideDuration={props.autoHideDuration}
    ClickAwayListenerProps={{ onClickAway: () => null }}
    message={props.message}
    onClose={props.onClose}
    open={props.show}
    action={
      <>
        {props.onClose &&
          <IconButton size="small" aria-label="close" color="inherit" onClick={props.onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        }
      </>
    }
  />
);

NotificationBar.propTypes = {
  autoHideDuration: PropTypes.number,
  show: PropTypes.bool,
  message: PropTypes.node,
  onClose: PropTypes.func,
};

NotificationBar.defaultProps = {
  autoHideDuration: 6000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export default NotificationBar;