import { isEmpty } from 'lodash';
import { SHARED_MEDIA_STATES, SHARED_MEDIA_TYPES } from '../../../constants/mediaStates';

const onVideoUnshare = (getState, getUserId, updateSharedMediaWarnings, updateVideoState) => {
  /**
  * Callback function for the video management component to handle any page changes when video has stopped sharing.
  * @param {string} mediaType Can be either 'webcam' or 'desktop'
  */
  return (mediaType) => {
    const state = getState();
    let streamId = mediaType === SHARED_MEDIA_TYPES.DESKTOP ? mediaType : getUserId();

    updateVideoState(mediaType, SHARED_MEDIA_STATES.DISCONNECTED);
    if (state?.gatewayService) {
      state.gatewayService.unshareMedia(mediaType, streamId);
    }

    // Clear any warnings from the previous connection attempt if we stopped sharing
    if (!isEmpty(state?.sharedMediaWarnings[mediaType])) {
      updateSharedMediaWarnings({ ...state.sharedMediaWarnings, [mediaType]: undefined });
    }
  }
}

export default onVideoUnshare;