import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Button, FormControl, FormControlLabel, FormLabel, MenuItem, TextField, Typography } from '@mui/material';
import { isEmpty, omit, size } from 'lodash';

import GreenSwitch from './GreenSwitch';
import Notice from '../notification/Notice';
import Section from '../Section';
import UserLookup from '../search/UserLookup';
import { buildJoinUrl, formatDateTime, formatDisplayName, validateFields } from './helper/examSessionFormHelpers';
import ExamSessionService from '../../services/ExamSessionService';
import { EXAM_SESSION_DTO, EXAM_SESSION_STATES } from '../../constants/examSessions';
import { EXAM_FIELDS } from '../../constants/examFields';
import { USER_TYPES } from '../../constants/users';
import { getLastActiveUserByType } from '../../utils/getExamSlotUser';
import mapToDto from '../../utils/mapToDto';

class ExamSessionForm extends React.Component {
  controller = new AbortController();

  constructor(props) {
    super(props);

    let baseSlot = { ...EXAM_SESSION_DTO };

    if (!isEmpty(props.referenceExam)) {
      const referenceExam = props.referenceExam;

      baseSlot = {
        ...baseSlot,
        ...mapToDto(EXAM_SESSION_DTO, referenceExam),
        examStartDateTime: referenceExam.stdStart,
        examEndDateTime: referenceExam.stdEnd,
        contextId: referenceExam.id,
        student: null, // only for rendering purposes
      };
    }

    if (!isEmpty(props.selectedExamSlots)) {
      const examToDisplay = props.selectedExamSlots[0];
      const supervisor = getLastActiveUserByType(examToDisplay, USER_TYPES.SUPERVISOR);
      const onboarder = getLastActiveUserByType(examToDisplay, USER_TYPES.ONBOARDER);
      const student = {
        label: `${examToDisplay.student?.fullName} (${examToDisplay.student?.userName})`,
        value: examToDisplay.student?.id,
        user: examToDisplay.student,
      };
      baseSlot = {
        ...mapToDto(EXAM_SESSION_DTO, examToDisplay),
        id: examToDisplay.id,
        contextId: examToDisplay.context?.id,
        onboarderId: onboarder?.id,
        state: examToDisplay.examState,
        studentId: examToDisplay.student?.id,
        supervisorId: supervisor?.id,
        context: examToDisplay.context, // only for rendering purposes
        supervisionCompleted: examToDisplay.supervisionCompleted || false,
        student: student, // only for rendering purposes
      };
    }

    this.state = {
      slotDetails: baseSlot,
      editingMode: !isEmpty(props.selectedExamSlots),
      joinUrl: undefined,
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
    };
  }

  //saving the selected values
  setDetails = (event, type) => {
    let slotObj = { ...this.state.slotDetails };
    const formatForSave = "yyyy-MM-dd'T'HH:mm:00X";

    switch (type) {
      case 'boolean':
        slotObj[event.target.id] = !slotObj[event.target.id];
        break;
      case 'select':
        slotObj[event.target.name] = event.target.value;
        break;
      case 'dateTime':
        slotObj[event.target.id] = formatDateTime(event.target.value, formatForSave);
        break;
      default:
        break;
    }
    this.setState({ slotDetails: slotObj });
  };

  setUser = (_fieldName, user) => {
    this.setState({ slotDetails: { ...this.state.slotDetails, studentId: user?.[0].value, student: user?.[0] } });
  };

  saveSlot = async () => {
    const { editingMode, slotDetails } = this.state;
    const { referenceExam, selectedExamSlots } = this.props;
    try {
      validateFields(slotDetails);

      const selectedSlotSupervisionCompleted = referenceExam?.supervisionCompleted || selectedExamSlots?.[0]?.supervisionCompleted;
      const hasUpdatedSupervisionCompletedToFalse = !slotDetails.supervisionCompleted && selectedSlotSupervisionCompleted === true;
      // Reset the exam state if we set supervisionCompleted to false to give a second attempt. This will only trigger if the user toggled from "on" to "off"
      // This isn't ideal but it will work for now until we deprecate the update state endpoint.
      if (hasUpdatedSupervisionCompletedToFalse && slotDetails.state === EXAM_SESSION_STATES.submitted && editingMode) {
        try {
          await ExamSessionService.updateExamSessionState(slotDetails.id, { state: EXAM_SESSION_STATES.canStart }, this.controller.signal);
        } catch (err) {
          throw new Error(`We were unable to update the exam slot when re-engaging the slot onto the dashboard: ${err.message}`);
        }
      }

      const response = editingMode
        ? await ExamSessionService.updateExamSession(slotDetails.id, omit(slotDetails, ['context', 'student']), this.controller.signal)
        : await ExamSessionService.addExamSession(slotDetails, this.controller.signal);

      if (response.status === 200 || editingMode) {
        const url = buildJoinUrl(response);
        this.setState({
          joinUrl: url,
          slotDetails: { ...slotDetails, state: response.state || slotDetails.state },
          hasRequestErrored: false,
          isRequestSuccessful: true,
          errorMessage: '',
        });
        this.props.setHasSlotUpdated(true);
      } else {
        throw new Error(response.errorMessage);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        this.setState({
          hasRequestErrored: true,
          isRequestSuccessful: false,
          errorMessage: error.message,
        });
      }
    }
  };

  componentWillUnmount() {
    this.controller.abort();
  }

  render() {
    const { slotDetails, editingMode, isRequestSuccessful, hasRequestErrored, errorMessage } = this.state;

    return (
      <Section>
        <FormControl component="fieldset" sx={{ display: 'block', margin: '0 auto' }}>
          <FormLabel component="legend" sx={{ color: 'primary.main', my: 1 }}>
            Exam session details
          </FormLabel>
          {isRequestSuccessful &&
            <Notice noticeType="success">The slot was saved successfully. The student join url is {this.state.joinUrl}</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {slotDetails.context &&
            <Typography variant="body2" sx={{ my: 1 }}>Exam: {slotDetails.context.name}</Typography>
          }
          {Object.keys(EXAM_FIELDS).map((field) => {
            if (EXAM_FIELDS[field].type === 'textbox') {
              return (
                <UserLookup
                  key={field}
                  dataProps={{ 'data-name': 'session-user-input-container' }}
                  errored={hasRequestErrored && (EXAM_FIELDS[field].required && isEmpty(slotDetails.student))}
                  fieldAriaLabel="Select student (Enter three or more characters to search for a person) (required)"
                  fieldLabel="Student name, username or ID"
                  fieldName={field}
                  inputDataProps={{ 'data-name': 'session-user-input-box' }}
                  isDisabled={editingMode ? !EXAM_FIELDS[field].editable : false}
                  multiple={false}
                  required={EXAM_FIELDS[field].required}
                  setUserValue={this.setUser}
                  sx={{ my: 1 }}
                  userValue={[slotDetails.student]}
                />
              );
            } else if (EXAM_FIELDS[field].type === 'select') {
              return (
                <TextField
                  key={field}
                  id={field}
                  disabled={editingMode ? !EXAM_FIELDS[field].editable : false}
                  name={field}
                  label={EXAM_FIELDS[field].display}
                  value={slotDetails[field]}
                  onChange={(event) => this.setDetails(event, EXAM_FIELDS[field].type)}
                  required={EXAM_FIELDS[field].required}
                  error={hasRequestErrored && ((EXAM_FIELDS[field].required && isEmpty(slotDetails[field])) || (size(slotDetails[field]) > EXAM_FIELDS[field].maxLength))}
                  select
                  size='medium'
                  sx={{ my: 1 }}
                  variant='outlined'
                  fullWidth
                >
                  {EXAM_FIELDS[field].allowedValues.map((item) => {
                    return <MenuItem key={item.key} value={item.value}>{formatDisplayName(item.value)}</MenuItem>
                  })}
                </TextField>
              )
            } else if (EXAM_FIELDS[field].type === 'boolean') {
              return <FormControlLabel key={field} sx={{ width: '100%' }} control={
                <GreenSwitch
                  checked={slotDetails[field]}
                  disabled={editingMode ? !EXAM_FIELDS[field].editable : !EXAM_FIELDS[field].canModifyAdd}
                  onChange={(event) => this.setDetails(event, EXAM_FIELDS[field].type)}
                  name={field}
                  id={field}
                  inputProps={{ 'aria-label': 'human supervised' }}
                />} label={EXAM_FIELDS[field].display}
              />
            } else if (EXAM_FIELDS[field].type === 'dateTime') {
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns} key={field}>
                  <TextField
                    id={field}
                    disabled={editingMode ? !EXAM_FIELDS[field].editable : false}
                    label={EXAM_FIELDS[field].display}
                    defaultValue={formatDateTime(slotDetails[field])}
                    type='datetime-local'
                    onChange={(event) => this.setDetails(event, EXAM_FIELDS[field].type)}
                    required={EXAM_FIELDS[field].required}
                    error={hasRequestErrored && ((EXAM_FIELDS[field].required && isEmpty(slotDetails[field])) || (size(slotDetails[field]) > EXAM_FIELDS[field].maxLength))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size='medium'
                    sx={{ my: 1 }}
                    variant='outlined'
                    fullWidth
                  />
                </LocalizationProvider>
              )
            } else {
              return (<></>)
            }
          })}
          <Box display='flex' justifyContent='flex-end'>
            <Button
              key='save'
              color='primary'
              variant='contained'
              onClick={this.saveSlot}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

ExamSessionForm.propTypes = {
  referenceExam: PropTypes.object,
  selectedExamSlots: PropTypes.array,
  setHasSlotUpdated: PropTypes.func.isRequired
};

export default ExamSessionForm;
