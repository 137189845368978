import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormLabel } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns/parseISO';
import { format as dateFnsFormat } from 'date-fns/format';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';
import Notice from '../notification/Notice';
import Section from '../Section';
import ContextService from '../../services/ContextService';

function ContextExpiryForm(props) {
  const { selectedContext, setHasSlotUpdated } = props;
  const [selectedDate, setSelectedDate] = useState(
    selectedContext.deletionDate ? parseISO(selectedContext.deletionDate) : new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [requestError, setRequestError] = useState();
  const [requestSuccessful, setRequestSuccessful] = useState(false);

  const fieldsetStyle = { display: 'block', margin: '0, auto' }
  const legendStyle = { color: 'primary.main', my: 1 }

  const saveContext = async () => {

    if (!dateFnsIsValid(selectedDate)) {
      setRequestSuccessful(false);
      setRequestError('Sorry, the date is invalid and cannot be saved');
      return;
    }

    try {
      setRequestSuccessful(false);
      setRequestError(undefined);

      //Force the date selected to be formatted as midnight the same day local time
      const formattedDate = dateFnsFormat(selectedDate, "yyyy-MM-dd'T'00:00:00X");
      await ContextService.updateContextExamDeletionDate(selectedContext.id, formattedDate);

      setRequestSuccessful(true);
      setHasSlotUpdated(true);
    } catch (error) {
      setRequestError(error.message);
    }
  }

  const formats = {
    normalDate: 'dd/MMM/yyyy',
    keyboardDate: 'dd/MMM/yyyy',
  };

  return (
    <Section>
      <FormControl component="fieldset" sx={fieldsetStyle}>
        <FormLabel component="legend" sx={legendStyle}>Manage expiry date</FormLabel>
        {requestSuccessful &&
          <Notice noticeType="success">Record saved</Notice>
        }
        {requestError &&
          <Notice noticeType="error">{requestError}</Notice>
        }
        <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
          <DatePicker
            disableMaskedInput
            label="Select expiry date"
            value={selectedDate}
            onChange={setSelectedDate}
            slotProps={{
              textField: {
                id: 'selectContextExpiryDate',
                fullWidth: true,
                readOnly: true,
                sx: { my: 1 },
                variant: 'outlined',
              }
            }}
          />
        </LocalizationProvider>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={saveContext}
          >Save</Button>
        </Box>
      </FormControl>
    </Section>
  );
}

ContextExpiryForm.propTypes = {
  selectedContext: PropTypes.object,
  setHasSlotUpdated: PropTypes.func.isRequired
}

export default ContextExpiryForm;
