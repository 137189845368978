import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Clear as ClearIcon, ErrorOutline } from '@mui/icons-material';
import FormPopup from '../popup/FormPopup';
import Notice from '../notification/Notice';
import ExamSessionService from '../../services/ExamSessionService';
import PromiseWithAbort from '../../utils/PromiseWithAbort';
import { MSG_404 } from '../../constants/login';

const styles = {
  outlinedButton: {
    py: 0.5,
    px: 1,
    margin: 0.5,
    marginLeft: 2,
    lineHeight: '1rem',
    color: 'primary.contrastText',
    borderColor: 'primary.contrastText',
    '&:hover': {
      borderColor: 'primary.contrastText',
    }
  },
  disabledButton: {
    backgroundColor: 'secondary.dark',
    color: 'secondary.light',
  },
};

class EndButton extends React.Component {
  state = {
    popupOpen: false,
    successMessage: undefined,
    errorMessage: undefined,
    confirmed: false,
    hasRequestFailed: false,
  };

  controller = new AbortController();
  asyncAbort = new PromiseWithAbort();

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  closeSession = async (slotId) => {
    this.asyncAbort.wrap(ExamSessionService.endMeeting(slotId))
    .then(() => {
      this.setState({
        successMessage: 'The session has successfully closed. Please close this window/tab.',
        errorMessage: undefined,
      })
    })
    .catch((error) => {
      if (!error.aborted) {
        this.setState({
          successMessage: undefined,
          errorMessage: 'Sorry, the session was not closed, please try again',
        })
      }
    })
  }

  handleConfirm = async (slotId) => {
    if (this.state.confirmed) {
      this.asyncAbort.wrap(ExamSessionService.endMeeting(slotId))
      .then(() => {
        const forceSlotsToRefresh = true;
        this.props.refreshSlots(forceSlotsToRefresh);
      })
      .catch((error) => {
        if (!error.aborted) {
          console.log(error);
          let errorMessage = 'Sorry, the session was not closed, please try again';
          if (error.message === MSG_404) {
            errorMessage = 'Could not find active meeting for the slot';
          }
          this.setState({
            hasRequestFailed: true,
            errorMessage: errorMessage
          });
        }
      });
    } else {
      this.setState({ confirmed: true });
    }
  };

  cancelConfirm = () => {
    this.setState({
      confirmed: false,
      hasRequestFailed: false,
      errorMessage: undefined
    });
  };

  componentWillUnmount() {
    this.asyncAbort.abort();
    this.controller.abort();
  }

  render() {
    const { slotId, gatewayOpen, isAdminPage } = this.props;
    const { popupOpen, successMessage, errorMessage } = this.state;

    const content = (
      <>
        {successMessage &&
        <Notice noticeType='success'>{successMessage}</Notice>
        }
        {errorMessage &&
        <Notice noticeType='error'>{errorMessage}</Notice>
        }
        <p>Are you sure you want to end the session?</p>
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={(e) => this.closePopup(e)} disabled={!!successMessage}>No</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e)=> this.closeSession(slotId, e)}
            aria-label='close session'
            disabled={!!successMessage}
          >
            Yes
          </Button>
        </Box>
      </>
    );

    const endMeetingButtonForSupervision = () => (
      <>
      <Button
        variant='outlined'
        color='primary'
        onClick={this.openPopup}
        sx={{ ...styles.outlinedButton, '&.Mui-disabled': styles.disabledButton }}
        disabled={!gatewayOpen}
      >
        End session
      </Button>
      <FormPopup
        title="End session"
        open={popupOpen}
        onClose={this.closePopup}
        content={content}
      />
      </>
    );

    const endMeetingButtonForAdmin  = (
      <Box display='flex'>
        {
          !this.state.confirmed &&
          <Button
            size='small'
            variant='outlined'
            color='primary'
            aria-label={`End meeting for ${this.props.slotId}`}
            onClick={() => this.handleConfirm(this.props.slotId)}
            sx={{ml: 1, textTransform: 'initial', lineHeight: '1.3', alignSelf: 'center'}}
          >
            End meeting
          </Button>
        }
        {
          this.state.confirmed &&
          <Button
            size='small'
            variant='outlined'
            color='primary'
            aria-label={`Confirm end meeting for ${this.props.slotId}`}
            onClick={() => this.handleConfirm(this.props.slotId)}
            sx={{ml: 1, textTransform: 'initial', lineHeight: '1.3', alignSelf: 'center'}}
          >
            Confirm end
          </Button>
        }
        {
          this.state.confirmed &&
          <>
            <IconButton
              aria-label={`Cancel end meeting for ${this.props.slotId}`}
              onClick={() => this.cancelConfirm()}
              sx={{alignSelf: 'center'}}
              size='large'>
              <ClearIcon/>
            </IconButton>
            {
              this.state.hasRequestFailed &&
              <Tooltip title={this.state.errorMessage}>
                <ErrorOutline sx={{color: 'error.main', alignSelf: 'center'}} />
              </Tooltip>
            }
          </>
        }
      </Box>
    );

    return(
      <>
        {
          isAdminPage ? endMeetingButtonForAdmin : endMeetingButtonForSupervision()
        }
      </>
    )
  }
}

EndButton.propTypes = {
  slotId: PropTypes.string.isRequired,
  gatewayOpen: PropTypes.bool,
  isAdminPage: PropTypes.bool,
  setHasSlotUpdated: PropTypes.func,
  refreshSlots: PropTypes.func
};

export default EndButton;
