const ALLOCATION_POOLS = [
  {
  	"name": "evig60",
  	"description": "Medium integrity",
  	"availableSlotsLocked": 60,
  	"availableSlotsUnlocked": 60,
  	"availableSlotsAny": 60,
  	"allowedUserTypes": "SUPERVISOR,ONBOARDER",
  	"disabled": false,
  	"id": "babebe23-d087-4dae-888b-d6f9674f7f92"
  }
];

export default ALLOCATION_POOLS;
