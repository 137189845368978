export default class Routing {

  static path(...paths) {
    return '/' + paths.join('/');
  }

  /**
   * Breaking up routes with depth (ie DASHBOARD.TWO_DIGIT_FOR) could work,
   * but tends to make code verbose if you intend to go beyond 2 levels.
   *
   * Keeping everything on the same level also makes it easier to build deep
   * parametric routes, like /dashboard/4for/:fourDigitFORCode/applied-measures
   */

  static ERROR = 'error';
  static HOME = '';
  static JOIN_SESSION = 'join';
  static LOGIN_CALLBACK = 'implicit';
  static MANAGE_ALLOCATION_ERRORS = 'allocation-errors';
  static MANAGE_ALLOCATION_POOLS = 'allocation-pools';
  static MANAGE_CONTEXTS = 'contexts';
  static MANAGE_EXAM_SESSIONS = 'exam-sessions';
  static MANAGE_RECORDING = 'list-recordings';
  static MANAGE_ROLES = 'roles';
  static MANAGE_ROSTER = 'staff-roster';
  static MANAGE_SUPERVISOR_SESSIONS = 'supervisor-sessions';
  static MANAGE_USERS = 'users';
  static MONITOR_SESSIONS = 'monitor-sessions'
  static REVIEW_SESSION = 'review';
  static SUPERVISE_SESSION = 'supervise-session';
  static UNRESOLVED_NOTICE_REPORT = 'unresolved-notice-report';

  static RERENDER_ROUTES = [this.ERROR, this.HOME, this.JOIN_SESSION, this.LOGIN_CALLBACK, this.MANAGE_ALLOCATION_ERRORS,
    this.MANAGE_ALLOCATION_POOLS, this.MANAGE_CONTEXTS, this.MANAGE_EXAM_SESSIONS, this.MANAGE_RECORDING,
    this.MANAGE_ROLES, this.MANAGE_ROSTER, this.MANAGE_SUPERVISOR_SESSIONS, this.MANAGE_USERS, this.REVIEW_SESSION,
    this.SUPERVISE_SESSION, this.UNRESOLVED_NOTICE_REPORT];

  static LIMITED_RENDER_ROUTES = [this.MONITOR_SESSIONS];
}
