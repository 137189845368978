import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import {Clear as ClearIcon} from '@mui/icons-material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';

class ExamGateButton extends React.Component {
  state = {
    confirmed: false,
    hasRequestFailed: false,
    errorMessage: undefined
  };

  handleConfirm = async () => {
    const { confirmServiceCallback, data, examState } = this.props;
    if (this.state.confirmed) {
      const refreshState = await confirmServiceCallback(data);
      if(!refreshState?.refreshing) {
        this.setState({
          hasRequestFailed: true,
          errorMessage: `Unable to  ${(examState === 'Gate closed' ? 'open' : 'close')} the exam gate`
        });
      }
    } else {
      this.setState({confirmed: true});
    }
  };

  cancelConfirm = () => {
    this.setState({confirmed: false});
  };

  render() {
    const {examState, data} = this.props;
    const state = examState === 'Gate closed' ? 'open' : 'close';
    const labelText = ` exam gate for ${data['studentName']} in ${data['unit']}`;
    const confirmButtonText = `Confirm to ${state}`;
    const cancelButtonText = `Cancel ${state}`;

    return (
      <Box display='flex'>
        {
          !this.state.confirmed &&
          <IconButton
            onClick={this.handleConfirm}
            aria-label={`${state}${labelText}`}
            color={state === 'open' ? 'primary' : 'secondary'}
          >
            {state === 'open' ? <LockIcon/> : <LockOpenIcon/>}
          </IconButton>
        }
        {
          this.state.confirmed &&
          <Button
            size='small'
            variant='outlined'
            color='primary'
            aria-label={`${confirmButtonText}${labelText}`}
            onClick={this.handleConfirm}
            sx={{ml: 1, textTransform: 'initial', lineHeight: '1.3'}}
          >
            {confirmButtonText}
          </Button>
        }
        {
          this.state.confirmed &&
          <>
            <IconButton
              aria-label={`${cancelButtonText}${labelText}`}
              onClick={() => this.cancelConfirm()}
              sx={{alignSelf: 'center'}}
              size='large'>
              <ClearIcon/>
            </IconButton>
            {
              this.state.hasRequestFailed &&
              <Tooltip title={this.state.errorMessage}>
                <ErrorOutline sx={{color: 'error.main', alignSelf: 'center'}} />
              </Tooltip>
            }
          </>
        }
      </Box>
    )
  }
}

ExamGateButton.propTypes = {
  classes: PropTypes.object,
  confirmServiceCallback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default ExamGateButton;
