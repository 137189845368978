export const SEARCH_USER_LABELS = {
  fieldsetLabel: 'Search eVigilation users',
  searchFieldLabel: 'Search for users',
}

export const SEARCH_EXAM_SESSION_LABELS = {
  fieldsetLabel: 'Search eVigilation exam sessions',
  searchFieldLabel: 'Search by exam date',
}

export const SEARCH_ROOM_SCAN_DATE_LABELS = {
  fieldsetLabel: 'Search eVigilation room scans by date',
  searchFieldLabel: 'Search by exam date',
}

export const SEARCH_ROOM_SCAN_LABELS = {
  fieldsetLabel: 'Search eVigilation room scans',
  firstSearchFieldLabel: 'Exam name',
  secondSearchFieldLabel: 'Student name or ID',
}

export const SEARCH_ROSTER_LABELS = {
  fieldsetLabel: 'Search sessions',
};

export const SEARCH_SUPERVISOR_SESSION_LABELS = {
  fieldsetLabel: 'Search eVigilation supervisor sessions',
}

export const SEARCH_OPTIONS = {
  date: 'date',
  session: 'session',
  searchMultiCombo: 'searchMultiCombo',
  searchNumber: 'searchNumber',
  searchSingleSelect: 'searchSingleSelect',
  searchText: 'searchText',
  userLookup: 'userLookup',
};

export const SEARCH_ALLOCATION_ERROR_LABELS = {
  fieldsetLabel: 'Search for automatic allocation errors',
}
