import { find, isEmpty } from 'lodash';
import { isProgressComplete } from './isProgressComplete';
import shouldRepeatNow from './shouldRepeatNow';

/**
 * Checks if a 'group' on onboard steps has been completed by the user. All steps must be marked as complete for
 * a display group to be considered as "completed".
 * Assumes all array inputs are already ordered by position.
 * @param {number} currentFlowStepIndex the index we're currently up to in the flow
 * @param {Array} onboardProgressSteps Ordered list of the assigned steps and progress
 * @param {string} displayGroupStepId the displayGroupStepId of the current step to match to
 * @returns {boolean} True if the display group is completed
 */
export const isDisplayGroupStepComplete = (currentFlowStepIndex, onboardProgressSteps, displayGroupStepId) => {
  if (isEmpty(onboardProgressSteps)
    || !(find(onboardProgressSteps, {'flowStep' : {'displayGroupStepId': displayGroupStepId}}))) {
    return false;
  }
  return onboardProgressSteps
    .map((progressStep, index) => ({...progressStep, index: index}))
    .filter(progressStep => progressStep.flowStep?.displayGroupStepId === displayGroupStepId)
    .every((progressStep) => {
      return !shouldRepeatNow(currentFlowStepIndex, progressStep, progressStep.index)
        && isProgressComplete(progressStep?.progress)
    });
};
