const streamSettings = {

  /**
   * Time in ms between keepalive pings to the WebRTC SFU server.
   */
  pingInterval: 5000,

  /**
   * Starting time in ms to wait before retrying after sharing a webcam
   * stream to the remote media server failed.
   */
  cameraShareFailedWaitTime: 15000,

  /**
   * Maximum time in ms to wait before retrying after sharing a webcam
   * stream to the remote media server failed. Each time the share fails,
   * the interval before another retry will be doubled.
   */
  maxCameraShareFailedWaitTime: 60000,

  /**
   * Chrome extension key for Kurento utils to fetch desktop share options.
   */
  chromeExtensionKey: 'akgoaoikmbmhcopjgakkcepdgdgkjfbc',

  /**
   * List of available screenshare sources for chrome, out of 'window' or 'screen'.
   */
  chromeScreenshareSources: ['screen', 'window'],

  /**
   * List of available screenshare sources for firefox.
   */
  firefoxScreenshareSources: 'window',

  /**
   * Default quality for webcam video streams.
   */
  defaultWebcamQualityBitrate:
    (process.env.REACT_APP_APPCONFIG_WEBCAM_BITRATE ? process.env.REACT_APP_APPCONFIG_WEBCAM_BITRATE : 500),

  /**
   * Prefix to use for audio connection SIP identifier.
   */
  globalAudioPrefix: 'GLOBAL_AUDIO_',

  /**
   * Time in milliseconds to wait after a websocket is closed before attempting to
   * reconnect.
   */
  websocketConnectionTimeout: 4000,

  /**
   * Time in milliseconds to wait after a network interruption before trying to
   * reconnect any streams.
   */
  networkInterruptionTimeout: 5000,

  /**
   * Time in milliseconds before an audio connection attempt times out.
   */
  audioReconnectTimeoutMs: 15000,

  /**
   * Use relay for audio conference only on reconnect attempt.
   */
  relayOnlyOnReconnect: false,

  /**
   * Time in milliseconds before an ICE negotiation attempt times out.
   */
  iceNegotiationTimeout: 20000,

  /**
   * Time in milliseconds before ICE gathering on the local side times out.
   */
  iceGatheringTimeout: 5000,

  /**
   * URL path for kurento media server video websockets.
   */
  bbbSfuPath: '/bbb-webrtc-sfu',

  /**
   * URL path for freeswitch audio websockets.
   */
  bbbSipPath: '/ws',

  /**
   * Media constraints for screenshare video source
   */
  screenshareMediaConstraints: {
    video: {
      chromeMediaSource: 'screen',
      displaySurface: 'monitor',
    },
    selfBrowserSurface: 'include',
    audio: false,
  },

  screenshareBitRate: 1500,

  /**
   * Media constraints for webcam video source
   */
  webcamMediaConstraints: {
    video: true,
    audio: false,
  },

  /**
   * Screenshare maximum dimensions
   */
  maxScreenshareDimensions: {
    height: 1600,
    width: 2560,
  },

  /**
   * Media websocket keep alive packet interval in seconds.
   */
  websocketKeepAliveInterval: 30,

  /**
   * Media websocket debounce time in milliseconds.
   */
  websocketKeepAliveDebounce: 10,

};

export default streamSettings;
