import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import { AuthConsumer } from '../authContext';
import AllocationHandler from '../components/connection/AllocationHandler';
import Can from '../components/Can';
import MonitorList from '../components/container/MonitorList';
import Notice from '../components/notification/Notice';
import SuperviseContainer from '../components/container/SuperviseContainer';
import ViewShiftButton from '../components/form/ViewShiftButton';
import { MONITOR_LIST_TYPES } from '../constants/monitorSessions';
import MonitoringMeetingConnectManager from '../components/container/MonitoringMeetingConnectManager';

function MonitorSessions(_props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasRequestErrored, setHasRequestErrored] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(true);

  // For now this is in the page state as this is the only place we're currently showing an error message
  // Could move it into global context if required
  const updateRequestState = useCallback((isRequestPendingParam, hasRequestErroredParam, errorMessageParam) => {
    setErrorMessage(errorMessageParam);
    setHasRequestErrored(hasRequestErroredParam);
    setIsRequestPending(isRequestPendingParam);
  }, [setErrorMessage, setHasRequestErrored, setIsRequestPending]);

  const displayError = (messageOverride) => {
    return (
      <Notice noticeType="error">{messageOverride ? messageOverride : errorMessage}</Notice>
    )
  }

  const getPageLayout = (features, user) => {
    return (
      <>
        {!isRequestPending && hasRequestErrored && displayError()}
        <Box display="flex">
          <Box width="30%" display="flex" flexDirection="column" overflow="hidden" maxHeight="100vh">
            {features.jita && <ViewShiftButton />}
            <Box
              flexGrow="1"
              display="flex"
              flexDirection="column"
              maxHeight={features.jita ? "calc(100% - 53px)" : "100%"}
              bgcolor="black"
            >
              <AllocationHandler updateRequestState={updateRequestState}>
                <MonitoringMeetingConnectManager features={features} />
                {!isEmpty(MONITOR_LIST_TYPES) && Object.keys(MONITOR_LIST_TYPES)
                  .map(type => {
                  return (
                    <MonitorList
                      key={type}
                      type={MONITOR_LIST_TYPES[type]}
                      user={user}
                      features={features}
                    />
                  )
                })}
              </AllocationHandler>
            </Box>
          </Box>
          <SuperviseContainer />
        </Box>
      </>
    );
  };

  return (
    <AuthConsumer>
      {({ user, capabilityContextAccess, features }) => (
        <>
          {user &&
            <Can
              capabilityContextAccess={capabilityContextAccess}
              contextRequired={false}
              perform={["SUPERVISE_EXAM", "ONBOARD_EXAM"]}
              data={{ user }}
              yes={() => getPageLayout(features, user)}
              no={() => displayError("Sorry, there's an issue with your access.")}
            />
          }
        </>
      )}
    </AuthConsumer>
  )
}

export default MonitorSessions;
