import React from 'react';
import PropTypes from 'prop-types';
import 'date-fns';
import { differenceInSeconds as dateFnsDifferenceInSeconds } from 'date-fns/differenceInSeconds';
import { parseISO } from 'date-fns/parseISO';

import { lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import {
  Circle,
  Redo,
  Warning
} from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';
import { find, has, isEmpty, isEqual, join, max, orderBy, toUpper, uniq } from 'lodash';

import { authContext } from '../../authContext';
import { CanThey } from '../Can';
import AssignSessionButton from '../form/AssignSessionButton';
import EndButton from '../form/EndButton';
import ExamGateButton from '../form/ExamGateButton';
import ExamSessionTypeLabel from '../content/ExamSessionTypeLabel';
import DeletionStatusChip from '../content/DeletionStatusChip';
import ExamSessionParticipantStatusLabel from './ExamSessionParticipantStatusLabel';
import getExamDetails, { getAllocationPoolNames, getFormattedDeletionDate } from '../../utils/getExamDetails';
import isAgentExam from '../../utils/isAgentExam';
import Routing from '../../utils/Routing';
import {
  EXAM_SESSION_FIELDS,
  EXAM_SESSION_ONBOARDING_TYPE,
  EXAM_SESSION_ONBOARDING_TYPE_LABELS,
  EXAM_SESSION_STATES,
  EXAM_SESSION_STATUS_LABEL,
  EXAM_SESSION_PARTICIPANT_STATUS,
  EXAM_SESSION_PARTICIPANT_STATUS_LEGEND,
  EXAM_SESSION_CAPABILITIES as CAPABILITIES,
  EXAM_SESSION_DELETION_STATUS,
  EXAM_SESSION_PARTICIPANT_WEBCAM_STATUS
} from '../../constants/examSessions';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';
import { SUPERVISOR_SESSION_FIELDS } from '../../constants/supervisorSessions';
import { SCREEN_SHARE_TYPES } from '../../constants/mediaStates';
import { buttonLinkStyle, themeObject } from '../../config/theme';
import ExamSessionService from '../../services/ExamSessionService';
import { capitalise } from '../../utils/capitalise';

const classes = {
  blueChip: {
    color: themeObject.palette.primary.main,
    backgroundColor: lighten(themeObject.palette.primary.main, 0.95),
  },
  manualPoolDisplay: {
    color: 'error.main',
    backgroundColor: (theme) => lighten(theme.palette.error.main, 0.95),
  },
  warningStatusColour: {
    color: '#AB3428',
    paddingRight: 0.5,
    fontSize: '1rem',
  },
  skipOnboardingColour: {
    color: '#888888',
    paddingRight: 0.5,
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  rowJoined: {
    '& td': { backgroundColor: '#f7f7f7' },
    '&:hover': {
      '& td': {
        backgroundColor: 'inherit !important',
      },
    },
  },
};

// A supervisor will be able to join a session only if the exam start date is less than the time specified
const JoinTime = process.env.REACT_APP_LINK_ACTIVATION_TIME || 3600;

class ExamSessionTable extends React.PureComponent {

  state = {
    sortedExamSessions: [],
    processedData: [],
    hasJoinedSession: {},
  };

  componentDidMount() {
    const { examSessionList, displayJoinButton, isSupervisor } = this.props;
    this.setSessionData();
    displayJoinButton &&
      this.setState({
        hasJoinedSession: this.getJoinedStatus(displayJoinButton, examSessionList, isSupervisor)
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { examSessionList } = this.props;
    const { hasJoinedSession } = this.state;
    if (!isEqual(prevProps.examSessionList, examSessionList)
      || !isEqual(prevState.hasJoinedSession, hasJoinedSession)) {
      this.setSessionData();
    }
  }

  setSessionData = () => {
    const {
      additionalFields,
      permissions,
      displayActionButton,
      displayJoinButton,
      examSessionList,
      forSupervisorSessions,
      refreshSlots,
      setHasSlotUpdated
    } = this.props;

    const sortedExamSessions = this.sortExamSessions(examSessionList);
    const processedData = this.processList(
      displayActionButton,
      permissions,
      displayJoinButton,
      refreshSlots,
      setHasSlotUpdated,
      sortedExamSessions
    );

    this.setState({
      processedData: processedData,
      sortedExamSessions: sortedExamSessions,
      columns: this.getColumns(
        displayActionButton,
        displayJoinButton,
        permissions,
        processedData,
        forSupervisorSessions,
        additionalFields
      ),
    });
  };

  sortExamSessions = (examSessionList) => {
    if (!examSessionList) {
      return [];
    }
    return orderBy(examSessionList, [
      'examStartDateTime', 'examEndDateTime',
      (slot) => { const latestSup = max(slot.supervisors, 'assignedTimestamp'); return latestSup ? toUpper(latestSup.fullName) : '' },
      (slot) => { return toUpper(slot.student.fullName) },
      'student.externalId'
    ]);
  };

  getExamSessionParticipantStatusLabel = (participantStatus, participantType) => {
    return (
      <Box display="flex" alignItems="center" pr={0.5}>
        <ExamSessionParticipantStatusLabel participantStatus={participantStatus} participantType={participantType} />
      </Box>
    );
  };

  getWarningLabel = (participantType) => {
    return (
      <div>
        <Tooltip title={`${participantType}: None assigned`}>
          <Warning sx={classes.warningStatusColour} />
        </Tooltip>
      </div>
    );
  };

  canJoinExamSession = (examStartDate) => {
    if (examStartDate === undefined) {
      return false;
    }
    const currentDate = new Date();
    const differenceInSeconds = dateFnsDifferenceInSeconds(parseISO(examStartDate), currentDate);
    return differenceInSeconds <= JoinTime;
  };

  getStudentStatus = (session, examSession) => {
    if (has(examSession, 'studentStatus')) {
      session['studentStatusIcon'] = this.getExamSessionParticipantStatusLabel(examSession.studentStatus, 'Student');
      session['studentStatus'] = EXAM_SESSION_PARTICIPANT_STATUS_LEGEND[examSession.studentStatus];
    } else {
      session['studentStatusIcon'] = '';
      session['studentStatus'] = 'None';
    }
  };

  getOnBoardingDetails = (session, examSession, onboarderName) => {
    if (isEmpty(examSession)) {
      session['onboarding'] = '-';
      session['onboardingStatusIcon'] = '';
      session['onboardingStatus'] = 'None'
    } else if (examSession.onboardingType === EXAM_SESSION_ONBOARDING_TYPE.SKIP) {
      session['onboardingStatusIcon'] = <div>
        <Tooltip title={EXAM_SESSION_ONBOARDING_TYPE_LABELS.skipOnboarding}>
          <Redo sx={classes.skipOnboardingColour} />
        </Tooltip>
      </div>;
      session['onboarding'] = 'Self (' + (!isEmpty(onboarderName) ? onboarderName : '-') + ')';
      session['onboardingStatus'] = EXAM_SESSION_ONBOARDING_TYPE_LABELS.skipOnboarding;
    } else {
      if (has(examSession, 'onboardingStatus')) {
        if (!isEmpty(onboarderName)) {
          session['onboardingStatusIcon'] = this.getExamSessionParticipantStatusLabel(examSession.onboardingStatus, 'Onboarder');
        } else {
          session['onboardingStatusIcon'] = this.getWarningLabel('Onboarder');
        }
        session['onboardingStatus'] = EXAM_SESSION_PARTICIPANT_STATUS_LEGEND[examSession.onboardingStatus];
      } else {
        session['onboardingStatusIcon'] = '';
        session['onboardingStatus'] = 'None'
      }
      session['onboarding'] = !isEmpty(onboarderName) ? onboarderName : '-';
    }
  };

  getSupervisionDetails = (session, examSession, supervisorName) => {
    //Todo Confirm if supervisorStatus does not exist can we just use the word 'NONE' and no icon
    session['supervisionStatusIcon'] = '';
    session['supervisionStatus'] = 'None';
    session['supervision'] = '-';

    if (supervisorName && supervisorName.length > 0) {
      if (has(examSession, 'supervisorStatus')) {
        session['supervisionStatusIcon'] = this.getExamSessionParticipantStatusLabel(examSession.supervisorStatus, 'Supervisor');
        session['supervisionStatus'] = EXAM_SESSION_PARTICIPANT_STATUS_LEGEND[examSession.supervisorStatus];
      }
      session['supervision'] = supervisorName;
    } else if (has(examSession, 'humanSupervised') && examSession.humanSupervised) {
      session['supervisionStatusIcon'] = this.getWarningLabel('Supervisor');
    } else {
      //Do nothing - defaults apply.
    }
  };

  getAgentDetails = (session, examSession) => {
    if (isAgentExam(examSession) && has(examSession, 'agentStatus')) {
      session['agentStatusIcon'] = this.getExamSessionParticipantStatusLabel(examSession.agentStatus, 'Agent');
      session['agentStatus'] = EXAM_SESSION_PARTICIPANT_STATUS_LEGEND[examSession.agentStatus];
    } else {
      session['agentStatusIcon'] = '';
      session['agentStatus'] = 'None';
    }
  };

  getExamGateDetails = (session, examSession) => {

    if (has(examSession, 'examState')) {
      switch (examSession.examState) {
        case EXAM_SESSION_STATES.canStart:
        case EXAM_SESSION_STATES.started:
          session['examGateStatus'] = examSession.examState;
          session['examState'] = EXAM_SESSION_STATUS_LABEL.GATE_OPENED;
          break;
        case EXAM_SESSION_STATES.submitted:
          session['examGateStatus'] = examSession.examState;
          session['examState'] = EXAM_SESSION_STATUS_LABEL.SUBMITTED;
          break;
        case EXAM_SESSION_STATES.pending:
        case undefined:
        default:
          session['examGateStatus'] = examSession.examState;
          session['examState'] = EXAM_SESSION_STATUS_LABEL.GATE_CLOSED;
          break;
      }
    } else {
      session['examGateStatus'] = 'N/A';
      session['examState'] = EXAM_SESSION_STATUS_LABEL.GATE_CLOSED;
    }
    session['examStateDisplay'] = session['examState'];
  };

  getJoin = (examSession, processedSession) => {
    const { hasJoinedSession } = this.state;
    const buttonText = (hasJoinedSession?.[examSession.id]) ? 'Re-join session' : 'Join session';
    return (
      <Button
        aria-label={`Join session for ${examSession.student.fullName}`}
        disabled={!processedSession.canJoin}
        onClick={(_e) => this.handleJoinClick(examSession.id)}
        sx={[
          buttonLinkStyle,
          hasJoinedSession?.[examSession.id] ? {
            color: 'secondary.light',
          } : {}
        ]}
      >{buttonText}</Button>
    );
  }

  getActions = (permissions, examSession, refreshSlots, setHasSlotUpdated) => {
    let assignSessionButton = permissions?.canEdit && this.context.features[FEATURE_TOGGLES.SESSION_SINGLE_ASSIGN_BUTTONS]
      ? <AssignSessionButton
          selectedExamSlot={examSession}
          setHasSlotUpdated={setHasSlotUpdated}
          refreshSlots={refreshSlots}
          actionName="Assign"
      />
      : undefined;

    let endButton;
    if (permissions?.canManageExam) {
      //Only want to do this check if there's a chance the button will be shown
      endButton = this.canTheyManageExamStatus(examSession['context']['id'])
        ? <EndButton
            slotId={examSession.id}
            isAdminPage={true}
            setHasSlotUpdated={setHasSlotUpdated}
            refreshSlots={refreshSlots}
        />
        : undefined;
    }

    if (assignSessionButton || endButton) {
      return (<Box display='flex'>{assignSessionButton}{endButton}</Box>);
    }

  }

  processList = (displayActionButton, permissions, displayJoinButton,
    refreshSlots, setHasSlotUpdated, sortedExamSessions) => {

    let processedData = [];
    sortedExamSessions.forEach(session =>
      processedData.push(this.processExamSessionData(session, permissions, displayJoinButton, refreshSlots, setHasSlotUpdated, displayActionButton)));
    return processedData;
  }

  processExamSessionData = (examSession, permissions, displayJoinButton, refreshSlots, setHasSlotUpdated, displayActionButton) => {
    // get standard session details or empty object
    let session = getExamDetails(examSession) || {};

    if (!isEmpty(examSession)) {

      if (has(examSession, 'examType')) {
        session['typeDisplay'] = (
          <ExamSessionTypeLabel examType={examSession.examType} humanSupervised={examSession.humanSupervised} />
        );
      }
      const poolNames = getAllocationPoolNames(examSession);
      if (poolNames && has(examSession, 'automaticAllocation') && examSession['automaticAllocation']) {
        session['allocationType'] = poolNames;
        session['allocationTypeDisplay'] = <>
          {poolNames?.map(name => {
            return <Chip size="small" key={name} label={name} sx={classes.blueChip} />
          })}
        </>
      } else {
        session['allocationType'] = 'Manual';
        session['allocationTypeDisplay'] = <Chip size="small" label="Manual" sx={classes.manualPoolDisplay} />
      }
    }

    session['deletionStatusValue'] = examSession?.deletionStatus;
    if (permissions?.canViewIntegrity) {
      session['deletionDate'] = getFormattedDeletionDate(examSession);
      session['deletionStatus'] = EXAM_SESSION_DELETION_STATUS[examSession?.deletionStatus]?.label;
      session['deletionStatusDisplay'] = <DeletionStatusChip deletionStatus={examSession?.deletionStatus} deletedDate={examSession?.deletedDate} />
    }

    if (this.context.features[FEATURE_TOGGLES.AUTO_ONBOARD]) {
      const activeOnboardStepName = examSession?.activeOnboardStepName || 'Not started';
      session['activeOnboardStep'] = activeOnboardStepName;
      session['activeOnboardStepDisplay'] = <Chip size="small" sx={classes.blueChip} label={activeOnboardStepName} />
    }
    const webCameraStatus = examSession?.studentMedia?.video?.status;
    const isWebcamActive = webCameraStatus === EXAM_SESSION_PARTICIPANT_WEBCAM_STATUS.ACTIVE;

    session['videoStatus'] = isWebcamActive ? 'Cam on' : 'Cam off';
    session['videoStatusDisplay'] = <Chip
      icon={<Circle />}
      label={session.videoStatus}
      size="small"
      sx={{
        borderColor: isWebcamActive ? 'success.main' : 'grey.400',
        '.MuiChip-icon': { color: isWebcamActive ? 'success.main' : 'grey.400', fontSize: '8px', ml: 0.5 },
      }}
      variant="outlined"
    />

    const screenShareStatus = examSession?.studentMedia?.screenShare?.status;
    session['screenShareStatus'] = capitalise(SCREEN_SHARE_TYPES[screenShareStatus]?.display);
    session['screenShareStatusDisplay'] = <Chip
      icon={<Circle />}
      label={capitalise(SCREEN_SHARE_TYPES[screenShareStatus]?.display) || 'Unknown screen'}
      size="small"
      sx={{
        borderColor: SCREEN_SHARE_TYPES[screenShareStatus]?.color,
        '.MuiChip-icon': { color: SCREEN_SHARE_TYPES[screenShareStatus]?.color, fontSize: '8px', ml: 0.5 },
      }}
      variant="outlined"
    />

    session['canJoin'] = has(examSession, 'examStartDateTime') ? this.canJoinExamSession(examSession.examStartDateTime) : false;

    this.getSupervisionDetails(session, examSession, session.supervisorName);
    this.getOnBoardingDetails(session, examSession, session.onboarderName);
    this.getStudentStatus(session, examSession);
    this.getAgentDetails(session, examSession);
    this.getExamGateDetails(session, examSession);
    session['join'] = displayJoinButton && this.getJoin(examSession, session);
    session['action'] = displayActionButton && this.getActions(permissions, examSession, refreshSlots, setHasSlotUpdated);

    return session;
  };

  handleJoinClick = (examSessionId) => {
    if (!examSessionId) {
      return undefined;
    }

    const url = join([process.env.REACT_APP_APPCONFIG_FRONTEND_URL, Routing.SUPERVISE_SESSION, examSessionId], '/');
    this.setState(prevState => ({
      hasJoinedSession: { ...prevState.hasJoinedSession, [examSessionId]: true },
    }));
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  handleExamGate = async (data) => {
    try {
      const slotId = data['id'];
      const stateToUpdate = (data['examGateStatus'] === EXAM_SESSION_STATES.pending)
        ? EXAM_SESSION_STATES.canStart : EXAM_SESSION_STATES.pending;
      await ExamSessionService.updateExamSessionState(slotId, {
        'state': stateToUpdate
      });
      if (this.props.refreshSlots) {
        const forceSlotsToRefresh = true;
        this.props.refreshSlots(forceSlotsToRefresh);
        return { refreshing: true }; // update occurred and slots will refresh automatically
      } else if (this.props.handleRefreshByUser) {
        this.props.handleRefreshByUser();
      } else {
        console.error("handle exam gate does not have a function to execute to refresh");
      }
    } catch (error) {
      console.error("error = ", error);
    }
  };

  getPoolsFilterOptions = (processedSessions) => {
    if (isEmpty(processedSessions)) { return [] }
    let poolNames = [];
    processedSessions.forEach(session => {
      poolNames.concat(session.allocationType);
    });
    return uniq(poolNames);
  };

  canTheyManageExamStatus = (contextId, checkWithoutContext) => {
    if (this.props.permissions?.canManageExam) {
      return this.props.permissions?.canManageExam;
    }

    if (this.props.capabilityContextAccess) {
      if (checkWithoutContext) {
        return CanThey(this.props.capabilityContextAccess, false,
          CAPABILITIES.manageExam);
      } else if (contextId !== undefined) {
        return CanThey(this.props.capabilityContextAccess, true,
          CAPABILITIES.manageExam, { id: contextId });
      } else {
        return false;
      }
    }
    return false;
  };

  getColumns = (displayActionButton, displayJoinButton, permissions, contextData, forSupervisorSessions, additionalFields) => {
    let columns = [];
    const { statusColourOverride } = this.props;

    let fields = forSupervisorSessions ? SUPERVISOR_SESSION_FIELDS : EXAM_SESSION_FIELDS;
    if (!isEmpty(additionalFields)) {
      fields = { ...additionalFields, ...fields };
    }
    const poolFilterOptions = this.getPoolsFilterOptions(contextData);

    const statusCellProps = {
      style: {
        paddingLeft: '0px',
      },
      align: 'left'
    }

    const shouldShowColumn = (heading) => {
      if (heading === 'deletionStatus' || heading === 'deletionDate') {
        return permissions?.canViewIntegrity;
      }

      if (heading === 'activeOnboardStep') {
        return this.context.features[FEATURE_TOGGLES.AUTO_ONBOARD];
      }
      
      return true;
    }

    // determine whether we ever show exam gate buttons, so it's not calculated each row
    const neverShowExamGateButton = Boolean(
      this.context.features[FEATURE_TOGGLES.AUTO_ONBOARD]
      && this.context.features[FEATURE_TOGGLES.HIDE_ADMIN_OPEN_EXAM_GATE])
      || displayJoinButton || !permissions?.canManageExam;

    Object.keys(fields)
      .filter(shouldShowColumn)
      .forEach((heading) => {
        let label = fields[heading].display;
        const defaultOptions = {
          filter: true,
          customFilterListOptions: {
            render: v => `${label}: ${v}`,
          },
        };

        // id must always be first to look up by dataIndex
        switch (heading) {
          case 'id':
            // never display id - only for row identification
            columns.push({ name: heading, options: { display: 'excluded' } });
            break;
          case 'examDate':
            if (displayActionButton) {
              // don't display examDate in the exam session table
              // but only display in supervisor view for upcoming sessions or view for manage supervisor sessions
            } else {
              columns.push({
                name: heading,
                label: label,
                options: defaultOptions,
              });
            }
            break;
          case 'onboardingStatus':
          case 'supervisionStatus':
          case 'studentStatus':
            columns.push({
              name: heading,
              label: label,
              options: {
                ...defaultOptions,
                customHeadLabelRender: () => {
                  return '';
                },
                customBodyRenderLite: (dataIndex) => {
                  return contextData[dataIndex][heading + 'Icon'];
                },
                setCellHeaderProps: () => ({
                  style: {
                    borderRightColor: statusColourOverride?.cellHeader ? statusColourOverride.cellHeader : '#f5f6f6',
                    padding: '0px',
                  },
                  align: 'right'
                }),
                setCellProps: () => ({
                  style: {
                    borderRightColor: statusColourOverride?.cell ? statusColourOverride.cell : 'transparent',
                    padding: '0px',
                  },
                  align: 'right'
                }),
              }
            });
            break;
          case 'onboarding':
          case 'supervision':
          case 'studentName':
            columns.push({
              name: heading,
              label: label,
              options: {
                ...defaultOptions,
                setCellProps: () => (statusCellProps),
              },
            });
            break;
          case 'allocationType':
          case 'type': {
            const typeOptions = {
              ...defaultOptions,
              customBodyRenderLite: (dataIndex) => {
                return contextData[dataIndex][heading + 'Display'];
              },
              setCellProps: () => ({
                style: {
                  paddingLeft: '0px',
                },
                align: 'left'
              }),
            }
            if (heading === 'allocationType') {
              typeOptions['filterList'] = poolFilterOptions;
            }
            columns.push({
              name: heading,
              label: label,
              options: typeOptions
            });
            break;
          }
          case 'examState': {
            const examStateOptions = {
              filter: true,
              customBodyRenderLite: (dataIndex) => {
                let displayButton;
                let hideExamGateButton = neverShowExamGateButton ||
                  contextData[dataIndex]['examState'] === EXAM_SESSION_STATUS_LABEL.SUBMITTED;

                if (!hideExamGateButton) {
                  if (this.canTheyManageExamStatus(contextData[dataIndex]['contextId'], false)) {
                    displayButton = <ExamGateButton
                      examState={contextData[dataIndex]['examState']}
                      confirmServiceCallback={this.handleExamGate}
                      data={contextData[dataIndex]}
                    />;
                  }
                }

                const style = {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pr: 0.5
                };

                return (<Box sx={style}>{contextData[dataIndex][heading + 'Display']}{displayButton}</Box>);
              },
              setCellProps: () => (statusCellProps),
            }
            columns.push({
              name: heading,
              label: label,
              options: examStateOptions
            });
            break;
          }
          case 'agentStatus':
            columns.push({
              name: heading,
              label: label,
              options: {
                ...defaultOptions,
                customBodyRenderLite: (dataIndex) => {
                  return contextData[dataIndex][heading + 'Icon'];
                },
              }
            });
            break;
          case 'videoStatus':
          case 'screenShareStatus':
          case 'activeOnboardStep':
            columns.push({
              name: heading,
              label: label,
              options: {
                ...defaultOptions,
                customBodyRenderLite: (dataIndex) => {
                  return contextData[dataIndex][heading + 'Display'];
                }
              }
            });
            break;
          case 'deletionStatus':
            columns.push({
              name: heading,
              label: label,
              options: {
                ...defaultOptions,
                display: false,
                customBodyRenderLite: (dataIndex) => {
                  return contextData[dataIndex][heading + 'Display'];
                }
              }
            });
            break;
          case 'deletionDate':
            columns.push({
              name: heading,
              label: label,
              options: {
                ...defaultOptions,
                display: false,
                },
              })
            break;
          default:
            columns.push({
              name: heading,
              label: label,
              options: defaultOptions,
            });
        }
      });

    if (displayJoinButton) {
      columns.push({
        name: "join",
        label: "Join",
        options: {
          filter: false,
          sort: false,
          download: false,
        },
      });
    }
    if ((this.context.features[FEATURE_TOGGLES.SESSION_SINGLE_ASSIGN_BUTTONS] && permissions?.canEdit)
      || permissions?.canManageExam) {
      columns.push({
        name: "action",
        label: "Action",
        options: {
          filter: false,
          sort: false,
          download: false,
        }
      });
    }
    return columns;
  };

  getJoinedStatus(displayJoinButton, contextData, isSupervisor) {
    if (!displayJoinButton) {
      return {};
    }
    const hasJoinedList = {}
    contextData.forEach((examSession) => {
      hasJoinedList[examSession.id] = isSupervisor
        ? !(!examSession?.supervisorStatus || examSession?.supervisorStatus === EXAM_SESSION_PARTICIPANT_STATUS.TO_JOIN)
        : !(!examSession?.onboardingStatus || examSession?.onboardingStatus === EXAM_SESSION_PARTICIPANT_STATUS.TO_JOIN);
    });
    return hasJoinedList;
  }

  handleRowSelection = (allRowsSelected, examSessionList) => {
    //TODO re-look at this. contextId is more being used as a tableId.
    //Exam slots have unique ids so it shouldn't matter
    this.props.setSelectedExamSlots(this.props.contextId, allRowsSelected.map(row => {
      return find(examSessionList, session => session.id === this.state.processedData[row.dataIndex].id);
    }));
  };

  render() {
    const { permissions } = this.props;
    const { columns, processedData, sortedExamSessions } = this.state;
    const options = {
      responsive: 'simple',
      selectableRows: (permissions?.canEdit || permissions?.canManageExam) ? 'multiple' : 'none',
      isRowSelectable: (dataIndex, _selectedRows) => {
        return processedData[dataIndex].deletionStatusValue !== EXAM_SESSION_DELETION_STATUS.DELETED.value
          && processedData[dataIndex].deletionStatusValue !== EXAM_SESSION_DELETION_STATUS.SCHEDULED.value;
      },
      filterType: 'multiselect',
      pagination: false,
      print: false,
      selectToolbarPlacement: 'above',
      setRowProps: (row, _dataIndex, _rowIndex) => {
        if (this.state.hasJoinedSession?.[row[0]]) {
          return {
            sx: classes.rowJoined
          };
        }
      },
      onRowSelectionChange: (_currentRowSelected, allRowsSelected, _rowsSelected) => this.handleRowSelection(allRowsSelected, sortedExamSessions),
    };

    return (
      <MUIDataTable
        columns={columns}
        data={processedData}
        options={options}
        /** 
         * Workaround for select all checkbox not behaving correctly when selectToolbarPlacement is set to 'none'
         * TODO: Revert this component override back and use selectToolbarPlacement: 'none' if/when this is fixed in MUIDataTables 
         */
        components={{
          TableToolbarSelect: () => <></>
        }}
      />
    );
  }
}

ExamSessionTable.propTypes = {
  additionalFields: PropTypes.object,
  permissions: PropTypes.object,
  contextId: PropTypes.string.isRequired,
  displayJoinButton: PropTypes.bool.isRequired,
  displayActionButton: PropTypes.bool.isRequired,
  examSessionList: PropTypes.array.isRequired,
  forSupervisorSessions: PropTypes.bool,
  isSupervisor: PropTypes.bool,
  refreshSlots: PropTypes.func,
  setHasSlotUpdated: PropTypes.func,
  setSelectedExamSlots: PropTypes.func,
  statusColourOverride: PropTypes.object,
  capabilityContextAccess: PropTypes.object,
  handleRefreshByUser: PropTypes.func
};

export default ExamSessionTable;
ExamSessionTable.contextType = authContext;
