import NetworkService from './NetworkService';
import MOCK from './mock/'
import mockRequest from '../utils/mockRequest';

export default class MertService {

    static async getPasscode(examSessionId, abortSignal) {
      if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
        return mockRequest(MOCK.MERT_PASSCODE);
      }
       const response = await NetworkService.get(
           `/mert-passcode?session=${examSessionId}`,
           abortSignal, 'text/plain');
        return await response.text();
    }

    static async generatePasscode(examSessionId){
        const response = await NetworkService.post(
            `/mert-passcode?session=${examSessionId}`,
            '', 'text/plain');
        return response.status;
    }
}
