import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { isEmpty, findKey } from 'lodash';
import Notice from '../notification/Notice';
import { authContext } from '../../authContext';
import GatewayService from '../../services/GatewayService';
import OnboardService from '../../services/OnboardService';
import { EXAM_SESSION_STATES } from '../../constants/examSessions';
import { ONBOARD_ACTION_TYPES } from '../../constants/joinSession';

function SkipCompleteOnboardStepConfirmationForm(props) {
  const [skipCompleteStepRequestPending, setSkipCompleteStepRequestPending] = useState(false);
  const [skipCompleteStepRequestError, setSkipCompleteStepRequestError] = useState('');

  const { user } = useContext(authContext);
  const controllerRef = useRef(new AbortController());
  const stepName = props.stepDetails?.flowStep?.onboardStep?.displayName || props.stepDetails?.flowStep?.onboardStep?.name;
  const stepConfigKey = findKey(ONBOARD_ACTION_TYPES, type => type.mapper === props.stepDetails?.flowStep?.onboardStep?.actionType);

  useEffect(() => {
    const controller = controllerRef.current;
    return () => controller.abort();
  }, []);

  const sendStepUpdateMessageToGateway = () => {
    if (props.gatewayService) {
      props.gatewayService.skipOnboardStep(props.stepDetails)
    }
  };

  const sendMeetingUpdateMessageIfNeeded = () => {
    if (ONBOARD_ACTION_TYPES[stepConfigKey]?.mapper === ONBOARD_ACTION_TYPES.OPEN_EXAM_GATE.mapper
      && props.gatewayService) {
      props.gatewayService.updateMeeting(
        { examState: EXAM_SESSION_STATES.canStart },
        `Onboarder opened exam gate via ${props.type} step`
      );
    }
  };


  const skipCompleteActiveStep = async (_e) => {
    const controller = controllerRef.current;
    try {
      setSkipCompleteStepRequestPending(true);
      const progressDetails = {
        ...(props.type === 'skip' && { skippedBy: user.id }),
        ...(props.type === 'complete' && { complete: true, completedByUserId: user.id }),
      };
      const progressToUpdate = {
        onboardAssignmentId: props.flowAssignmentId,
        onboardProgressId: props.stepDetails.progress.id,
        additionalPathParam: ONBOARD_ACTION_TYPES[stepConfigKey]?.apiPathTypeSkipParam,
        progressDetails: progressDetails,
        sendAsForm: ONBOARD_ACTION_TYPES[stepConfigKey]?.sendApiAsForm,
      };
      const updatedProgress = await OnboardService.updateOnboardProgress(progressToUpdate, controller.signal);
      sendMeetingUpdateMessageIfNeeded();
      sendStepUpdateMessageToGateway();
      props.onClose(true, updatedProgress);
    } catch (err) {
      if (!controller?.signal?.aborted) {
        console.error(err);
        setSkipCompleteStepRequestError(`We were unable to ${props.type} the step '${stepName}'. Please try again`);
        setSkipCompleteStepRequestPending(false);
      }
    }
  };

  return (
    <>
      <Collapse in={!isEmpty(skipCompleteStepRequestError)}>
        <Notice noticeType="error">{skipCompleteStepRequestError}</Notice>
      </Collapse>
      <Typography>Are you sure you want to {props.type} '{stepName}'</Typography>
      <Box display="flex" justifyContent="flex-end" mt={2} >
        <Button
          sx={{ mx: 1 }}
          color="secondary"
          onClick={() => props.onClose(false)}
        >
          Close
        </Button>
        <Button
          sx={{ ml: 1 }}
          color="primary"
          disabled={skipCompleteStepRequestPending}
          variant="contained"
          onClick={skipCompleteActiveStep}
        >
          Yes
        </Button>
      </Box>
    </>
  );
}

SkipCompleteOnboardStepConfirmationForm.propTypes = {
  flowAssignmentId: PropTypes.string,
  gatewayService: PropTypes.instanceOf(GatewayService),
  onClose: PropTypes.func.isRequired,
  stepDetails: PropTypes.object,
  type: PropTypes.oneOf(['skip', 'complete']),
};

SkipCompleteOnboardStepConfirmationForm.defaultProps = {
  type: 'skip',
};

export default SkipCompleteOnboardStepConfirmationForm;