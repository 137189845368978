export default class PromiseWithAbort {

  constructor() {
    this.activePromises = {};
    this.hasAborted = {};
    this.abortedAll = false;
    this.promiseCount = 0;
  }
  
  /**
   * A cancellable promise wrapper that allows for any asynchronous tasks to be cancelled
   * when the component is unloaded.
   * @param {Promise} promise Promise object to be fulfilled
   */
  wrap(promise) {

    // Give this promise a unique ID so it can be removed from the list when completed
    const promiseId = this.promiseCount++;
    
    // Construct the wrapped promise
    const wrappedPromise = new Promise((resolve, reject) => {
      const rejectAndRemove = (r) => {
        delete this.activePromises[promiseId];
        delete this.hasAborted[promiseId];
        reject(r);
      };
      const resolveAndRemove = (v) => {
        delete this.activePromises[promiseId];
        delete this.hasAborted[promiseId];
        resolve(v);
      }
      const anyAbort = () => {
        return this.abortedAll || this.hasAborted[promiseId];
      }
      promise.then(
        val => anyAbort() ? rejectAndRemove({aborted: true}) : resolveAndRemove(val),
        error => anyAbort() ? rejectAndRemove({aborted: true}) : rejectAndRemove(error)
      );
    });

    // Store the promise for tracking
    this.activePromises[promiseId] = wrappedPromise;

    return wrappedPromise;
  }

  /**
   * Cancel all promises, causing it to reject when the current task resolves,
   * with an error object having an 'aborted' property.
   */
  abort() {
    this.abortedAll = true;
  }
}