import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import { identity, isEmpty, pickBy } from 'lodash';
import { format as dateFnsFormat } from 'date-fns/format';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Search as SearchIcon } from '@mui/icons-material';

import AdvancedSessionSearchOptions from './AdvancedSessionSearchOptions';
import { authContext } from '../../authContext';
import { CanThey } from '../Can';
import DateSelector from './DateSelector';
import Section from '../Section';
import SessionSelector from './SessionSelector';
import UserLookup from './UserLookup';
import { fieldsetContainerStyle } from '../../config/theme';
import { SEARCH_BY_TYPES } from '../../constants/examSessions';
import { examSessionSearchCanNavigate } from '../../utils/isValidSearch';
import Routing from '../../utils/Routing';
import MeetingService from '../../services/MeetingService';
import UserService from '../../services/UserService';

class SearchFormForExamSessions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeMediaHosts: [],
      filters: props.filters ? props.filters : { groupBy: 'context', onlineOffset: '' },
      loadingHosts: false,
      searchText: props.searchText ? props.searchText : '',
      selectedDate: props.selectedDate ? props.selectedDate : dateFnsFormat(new Date(), "yyyy-MM-dd"),
      selectedOption: props.selectedOption && SEARCH_BY_TYPES[props.selectedOption] ? props.selectedOption : 'context',
      selectedSession: props.selectedSession ? props.selectedSession : undefined,
      triggerAdvanced: false,
      user: null,
    };
    this.controller = new AbortController();
  }

  async componentDidMount() {
    await this.updateUser();
    if(this.props.selectedOption === 'mediaHost') {
      await this.loadActiveMediaHosts();
    }
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  updateUser = async () => {
    const { userId } = this.props;
    if (userId) {
      try {
        const user = await UserService.getUserById(userId);
        this.setUser(undefined, this.formatUserForInputBox(user));
      } catch (error) {
        console.error(`Unable to fetch user for the id ${userId}`)
      }
    }
  };

  loadActiveMediaHosts = async () => {
    this.setState({ loadingHosts: true })
    try {
      const activeMediaHosts = await MeetingService.getActiveMediaHosts(this.controller.signal);
      this.setState({ activeMediaHosts: activeMediaHosts.sort(), loadingHosts: false })
    } catch(error) {
      if(error.name !== 'AbortError') {
        this.setState({ activeMediaHosts: [], loadingHosts: false })
      }
    }
  };

  onNavigateButtonClick = () => {
    const { filters, searchText, selectedDate, selectedOption, selectedSession, user } = this.state;
    if (examSessionSearchCanNavigate({ ...this.state })) {
      let params = new URLSearchParams();
      params.append('examDate', selectedDate);

      if (selectedOption === 'context' || selectedOption === 'mediaHost') {
        params.append('searchText', searchText);
      } else if (selectedOption === 'student') {
        params.append( 'id', user.value);
      } else if (selectedOption === 'session') {
        params.append('session', selectedSession);
      } else {
        // do nothing
      }

      const filterParams = qs.stringify({filters: pickBy(filters, identity)}, { encode: false });

      this.props.navigate(
        Routing.path(
          this.props.route,
          `${SEARCH_BY_TYPES[selectedOption].value}?${params}&${filterParams}`
        )
      );
    }
  };

  handleDateChange = (fieldName, date) => {
    this.setState({ [fieldName]: date });
  };

  setSelectedOption = async (event) => {
    if(event.target.value === 'mediaHost' && isEmpty(this.state.activeMediaHosts)) {
      await this.loadActiveMediaHosts();
    }
    this.setState({
      searchText: '',
      selectedOption: event.target.value,
      triggerAdvanced: event.target.value === 'report'
    });
  };

  setSearchText = (event, selectedValue, reason) => {
    const searchText = reason === 'clear' ? '' : selectedValue || event.target.value;
    this.setState({searchText: searchText});
  };

  setUser = (_fieldName, user) => {
    this.setState({ user: user?.[0] || null });
  };

  setAllFilters = (filterStates) => {
    this.setState({filters: filterStates});
  };

  setFilter = (filterName, filterState) => {
    this.setState({filters: {...this.state.filters, [filterName]: filterState}})
  };

  handleSessionChange = (_field, value) => {
    this.setState({selectedSession: value});
  };

  getAllowedTypes = (searchByKey) => {
    if(!SEARCH_BY_TYPES[searchByKey]?.restrictedTo) {
      return true;
    }
    const { capabilityContextAccess } = this.context;
    return CanThey(capabilityContextAccess, false, SEARCH_BY_TYPES[searchByKey].restrictedTo);
  };

  formatUserForInputBox = (user) => {
    return [{ label: `${user.fullName} (${user.userName})`, value: user.id, user: user }]
  }

  render() {
    const {
      activeMediaHosts,
      filters,
      loadingHosts,
      searchText,
      selectedDate,
      selectedOption,
      selectedSession,
      triggerAdvanced,
      user
    } = this.state;
    const { labels } = this.props;

    const datePickerField = (
      <DateSelector
        defaultRequired={true}
        field={{ label: 'Select a date', fieldName: 'selectedDate' }}
        handleDateChange={this.handleDateChange}
        selectedDate={selectedDate}
      />
    );

    const optionsField = (
      <TextField
        key="dropdown-select"
        id="dropdown-select"
        name="dropdown-select"
        label="Search by"
        value={SEARCH_BY_TYPES[selectedOption].value}
        onChange={(event) => this.setSelectedOption(event)}
        select
        size='medium'
        variant='outlined'
        fullWidth
      >
        {Object.keys(SEARCH_BY_TYPES).filter(this.getAllowedTypes).map((key) => {
          return <MenuItem key={key} value={SEARCH_BY_TYPES[key].value}>{SEARCH_BY_TYPES[key].displayName}</MenuItem>
        })}
      </TextField>
    );

    const searchTextField = (<TextField
      id="searchTerm"
      label={SEARCH_BY_TYPES[selectedOption].displayName}
      value={searchText}
      onChange={(event) => this.setSearchText(event)}
      size="medium"
      variant="outlined"
      fullWidth
    />);

    const studentSearchField = (
      <UserLookup
        dataProps={{ 'data-name': 'search-sessions-by-student-user-input-container' }}
        fieldAriaLabel="Select student (Enter three or more characters to search for a person)"
        fieldLabel="Search by student name, username or ID (required)"
        fieldName="sessionsByStudentUserLookup"
        inputDataProps={{ 'data-name': 'search-sessions-by-student-user-input-box' }}
        multiple={false}
        setUserValue={this.setUser}
        userValue={[user]}
      />
    );

    const hostStillActive = activeMediaHosts?.includes(searchText);
    const inactiveAdornment = (
      !loadingHosts && !hostStillActive && searchText !== ''
        ? <InputAdornment position="start"><Chip color="warning" label="not active" size="small" /></InputAdornment>
        : <></>
    );

    const mediaHostSearchField = (
      <Autocomplete
        disablePortal
        id={selectedOption}
        getOptionDisabled={(option) => option === searchText && !hostStillActive}
        isOptionEqualToValue={(option, value) => {
          return value === '' || value === option // don't error if the field is empty
        }}
        noOptionsText="No active conference servers"
        onChange={this.setSearchText}
        options={hostStillActive ? activeMediaHosts : [...activeMediaHosts, searchText]}
        renderInput={(params) =>
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, startAdornment: inactiveAdornment }}
            label={SEARCH_BY_TYPES[selectedOption].displayName}
          />
        }
        value={searchText}
      />
    );

    const sessionSelectorField = (
      <SessionSelector
        fieldName="session"
        handleSessionChange={this.handleSessionChange}
        selectedSession={selectedSession}
      />
    );

    const renderSearchField = (param) => {
      switch (param) {
        case 'student' : return studentSearchField;
        case 'session' : return sessionSelectorField;
        case 'mediaHost' : return mediaHostSearchField;
        default: return searchTextField;
      }
    };

    const searchButton = (
      <Fab
        color="primary"
        variant="contained"
        aria-label="Search"
        onClick={this.onNavigateButtonClick}
        size="medium"
        disabled={!examSessionSearchCanNavigate({ ...this.state })}
      >
        <SearchIcon/>
      </Fab>
    );

    return (
      <><Section sx={{ p: 1, mb: 0, borderRadius: 0 }}>
        <FormControl component="fieldset" sx={fieldsetContainerStyle} aria-label={labels.fieldsetLabel}>
          <FormLabel component="legend" style={{display: 'none'}}/>
          <Box display="flex" gap={3} justifyContent="space-between">
            <Box display="flex" gap={2} flexGrow="1" flexWrap="wrap">
              <Box>{datePickerField}</Box>
              <Box>{optionsField}</Box>
              <Box flexGrow="1" minWidth="300px">
                {selectedOption !== 'report' && renderSearchField(selectedOption)}
              </Box>
            </Box>
            <Box>{searchButton}</Box>
          </Box>
        </FormControl>
      </Section>
      <AdvancedSessionSearchOptions
        filterStates={filters}
        setAllFilters={this.setAllFilters}
        setFilter={this.setFilter}
        triggerGroupOptions={selectedOption === 'student' || selectedOption === 'mediaHost'}
        triggerOpen={triggerAdvanced}
      /></>
    );
  }
}

SearchFormForExamSessions.propTypes = {
  selectedDate: PropTypes.string,
  selectedOption: PropTypes.string,
  searchText: PropTypes.string,
  userId: PropTypes.string,
  selectedSession: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    fieldsetLabel: PropTypes.string.isRequired,
    searchFieldLabel: PropTypes.string.isRequired
  })
};

export default SearchFormForExamSessions;
SearchFormForExamSessions.contextType = authContext;
