import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import IdVerificationView from './IdVerificationView';
import Notice from '../notification/Notice';
import UserIdentityText from './UserIdentityText';

function UserIdentity(props) {
  const { examSlotId, user } = props;
  const [fullDetailsOpen, setFullDetailsOpen] = useState(false);

  return(
    <Box m={2}>
      <Typography component="h2" variant="body1">Please use this info to check student identity</Typography>
      {!user &&
        <Notice noticeType="error">Details not found</Notice>
      }
      {user &&
        <>
          <Box>
            <UserIdentityText user={user} />
            <Button
              fullWidth={true}
              onClick={() => setFullDetailsOpen(true)}
              sx={{ mt: 2 }}
              variant="outlined"
            >
              View full details
            </Button>
          </Box>
          {fullDetailsOpen &&
            <Dialog onClose={() => setFullDetailsOpen(false)} open={fullDetailsOpen} maxWidth="lg">
              <DialogContent>
                <IdVerificationView examSlotId={examSlotId} user={user} />
                <IconButton
                  sx={(theme) => {
                    return { position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: 'grey.500' }
                  }}
                  onClick={() => setFullDetailsOpen(false)}
                  aria-label="Close student verification details"
                  size="large">
                  <Close />
                </IconButton>
              </DialogContent>
            </Dialog>
          }
        </>
      }
    </Box>
  )
}

UserIdentity.propTypes = {
  examSlotId: PropTypes.string,
  user: PropTypes.object,
}

export default UserIdentity;
