import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import Notice from '../notification/Notice';
import Section from '../Section';
import UserLookup from '../search/UserLookup';
import updateRoleAssignmentsToUpdateObj from './helper/updateRoleAssignmentsToUpdateObj';
import { formatAssignedRolesToSave, formatExistingAssignments } from './helper/formatAssignedRoles';
import ContextService from '../../services/ContextService';
import RoleService from '../../services/RoleService';
import { fieldsetContainerStyle, legendStyle } from '../../config/theme';
import { MSG_404 } from '../../constants/login';
import { ROLE_FIELDS } from '../../constants/roles';

class AssignRolesForm extends React.Component {
  controller = new AbortController();

  state = {
    roles: undefined,
    existingAssignments: undefined,
    isRequestSuccessful: false,
    isRequestPending: false,
    hasRequestErrored: false,
    errorMessage: '',
    noticeMessage: undefined,
    roleAssignmentsToUpdate: {},
    usersMenuOpen: false,
  }

  componentDidMount() {
    this.getRolesAndAssignments();
  }

  getRolesAndAssignments = async () => {
    const { context } = this.props;
    try {
      let rolesFromService = await RoleService.getRoles(this.controller.signal);
      try {
        let existingAssignmentsFromService = await ContextService.getContextAssignedRoles({contextId: context.id}, this.controller.signal);
        const existingAssignments = formatExistingAssignments(existingAssignmentsFromService);
        this.setState({
          roles: rolesFromService,
          existingAssignments: existingAssignments,
          isRequestPending: false,
          hasRequestErrored: false,
        });
      } catch (error) {
        if(error.name !== 'AbortError') {
          if (error.message === MSG_404) {
            this.setState({
              roles: rolesFromService,
              existingAssignments: {},
              isRequestPending: false,
              hasRequestErrored: false,
            });
          } else {
            this.setState({
              isRequestPending: false,
              hasRequestErrored: true,
              errorMessage: error.message,
            });
          }
        }
      }
    } catch (error) {
      if(error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: "No roles exist in the system",
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };


  saveRoleAssignments = async () => {
    const { roleAssignmentsToUpdate } = this.state;
    const { context } = this.props;
    if(isEmpty(roleAssignmentsToUpdate)) {
      this.setState({ noticeMessage: 'There are no changes to save' })
      return;
    }

    let formattedUsersToUpdate = formatAssignedRolesToSave(roleAssignmentsToUpdate, context);

    this.setState({isRequestSuccessful: false, noticeMessage: undefined});

    try {
      const response = await ContextService.updateAssignedRolesToContext(formattedUsersToUpdate);
      const isPartialSuccess = response.some(r => r.status !== 200);
      this.setState({
        isRequestSuccessful: !isPartialSuccess,
        isPartialSuccess: isPartialSuccess,
        isRequestPending: false,
        hasRequestErrored: false,
        roleAssignmentsToUpdate: isPartialSuccess ? roleAssignmentsToUpdate : {},
      });
      this.getRolesAndAssignments();
    } catch (error) {
      this.setState({
        isRequestPending: false,
        hasRequestErrored: true,
        errorMessage: error.message,
      });
    }
  };

  handleUserChange = (_fieldName, details, action, roleId) => {
    const { existingAssignments, roleAssignmentsToUpdate } = this.state;
    const usersToUpdate = updateRoleAssignmentsToUpdateObj(action, existingAssignments, roleId, roleAssignmentsToUpdate, details);

    this.setState({
      roleAssignmentsToUpdate: usersToUpdate,
    });
  };

  render() {
    const { context } = this.props;
    const {
      roles,
      existingAssignments,
      isRequestSuccessful,
      isPartialSuccess,
      hasRequestErrored,
      errorMessage,
      noticeMessage,
    } = this.state;
    const userFormHint = 'Please search by entering at least 3 characters of their name, username or ID. Users will not appear as an option if they are already added.';

    return (
      <Section>
        <FormControl component="fieldset" sx={fieldsetContainerStyle}>
          <FormLabel component="legend" sx={legendStyle}>Assign roles to {context.name}</FormLabel>
          {!isEmpty(noticeMessage) &&
            <Notice noticeType="notice">{noticeMessage}</Notice>
          }
          {isRequestSuccessful &&
            <Notice noticeType="success">The assignments were updated successfully</Notice>
          }
          {isPartialSuccess &&
            <Notice noticeType="warning">Unable to update all assignments. Please close this popup and try again.</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {roles &&
            <TableContainer>
              <Table size="small" sx={{ mb: 7 }}>
                <TableHead>
                  <TableRow sx={{ verticalAlign: 'top' }}>
                    {Object.keys(ROLE_FIELDS).map((heading) => {
                      return <TableCell key={ROLE_FIELDS[heading].display}>{ROLE_FIELDS[heading].display}</TableCell>
                    })}
                    <TableCell>
                      Users
                      <Typography variant="caption" component="div" sx={{ lineHeight: '1remc' }}>{userFormHint}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.map((role) => {
                    return (
                    <TableRow key={role.id}>
                      {Object.keys(ROLE_FIELDS).map((heading) => {
                        return <TableCell key={heading + role[heading]}>{role[heading]}</TableCell>
                      }) }
                      <TableCell>
                        <UserLookup
                          dataProps={{ 'data-name': 'assign-role-user-input-container', 'data-role-id': role.id, 'data-role-name': role.name }}
                          fieldAriaLabel={`Assign users to the ${role.name} role. ${userFormHint}`}
                          fieldLabel="Add users"
                          fieldName="addUsers"
                          inputDataProps={{ 'data-name': 'assign-role-user-input-box', 'data-role-id': role.id, 'data-role-name': role.name }}
                          multiple={true}
                          groupId={role.id}
                          setUsersToUpdate={this.handleUserChange}
                          defaultUserValue={existingAssignments?.[role.id] || []}
                        />
                      </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {!hasRequestErrored &&
            <Box display='flex' justifyContent='flex-end'>
              <Button
                color='primary'
                variant='contained'
                onClick={this.saveRoleAssignments}
              >Save</Button>
            </Box>
          }
        </FormControl>
      </Section>
    );
  }
}

AssignRolesForm.propTypes = {
  context: PropTypes.object.isRequired,
};

export default AssignRolesForm;
