import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Outlet } from 'react-router-dom';
import OktaSecureRoute from './OktaSecureRoute';
import AuthService from '../../services/AuthService';
import { AUTH_BROKER_ENABLED } from '../../constants/login';

// if we're not using the brokered authentication, return the okta secure route
const SecureRoute = AuthService.getAuthBrokerToggleValue() !== AUTH_BROKER_ENABLED
? OktaSecureRoute
: (_props) => {

  console.debug('Using keycloak secured route')
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  // if allowed in secure page and the token has expired (not updated by onTokenExpired) then update the token
  // this can happen on refresh when we pass tokens in from sessionStorage to avoid re-login
  isLoggedIn && console.debug('Is token expired', keycloak.isTokenExpired());
  if (isLoggedIn && keycloak.isTokenExpired()) {
    keycloak.updateToken();
  }

  return isLoggedIn ? <Outlet/> : <></>;
};

export default SecureRoute;
