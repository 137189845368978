const FLAGS = [
    {
        "name": "Verification failed",
        "eventType": "verification-failed",
        "colour": "#888888",
        "id": "f77188ce-b331-4cc1-a7dc-253095b81dd8"
    },
    {
        "name": "Tech issue",
        "eventType": "tech-issue",
        "colour": "#888888",
        "id": "e9093830-39b9-4e87-8d5f-21778c119544"
    },
    {
        "name": "Health concern",
        "eventType": "health-concern",
        "colour": "#888888",
        "id": "0b8003a3-da65-4870-a97f-4eda472ec791"
    },
    {
        "name": "Toilet break",
        "eventType": "toilet-break",
        "colour": "#888888",
        "id": "78066612-78a1-4d0f-8cbe-852585b08dc0"
    },
    {
        "name": "Rest break (AAA)",
        "eventType": "rest-break",
        "colour": "#888888",
        "id": "c18cf55c-5eef-4d10-9eb6-a19df5b7bd91"
    },
    {
        "name": "Red flag",
        "eventType": "red-flag",
        "colour": "#EC2B2B",
        "id": "ac3fe690-d2fb-4ea3-83fe-5a4def1f81ca"
    },
    {
        "name": "Yellow flag",
        "eventType": "yellow-flag",
        "colour": "#FF9E33",
        "id": "5f9f36df-a346-4f30-bb5c-064b59fced34"
    }
];

export default FLAGS;
