import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { Box, ListItem, ListItemIcon, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Notice from '../notification/Notice';
import StartEndShiftButton from './StartEndShiftButton';
import { getSessionName } from '../../utils/getSessionTime';
import { SESSION_TIMES } from '../../constants/examSessions';

const styles = {
  listItemRoot: {
    backgroundColor: 'background.paper',
    borderRadius: '12px',
    padding: 2,
    marginBottom: 2,
  },
  caption: {
    color: 'grey.600',
  },
  noticeRoot: {
    borderRadius: '12px',
    marginTop: 0,
  },
};

function JoinShift(props) {
  const { shift, setShiftUpdated, multiShiftError } = props;
  const [shiftUpdateError, setShiftUpdateError] = useState();

  return (
    <ListItem sx={styles.listItemRoot} alignItems='flex-start'>
      <ListItemIcon><ScheduleIcon/></ListItemIcon>
      <Box width="100%">
        {shiftUpdateError &&
          <Notice noticeType="error" sx={{ root: styles.noticeRoot }}>{shiftUpdateError}</Notice>
        }
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
          <div>
            <Typography variant="h2" component="h3">
              {getSessionName(shift.startTime, omit(SESSION_TIMES, 'all'))} session
            </Typography>
            <Typography variant="body1" sx={styles.caption}>
              {shift.allocationPool?.description &&
                <span>{shift.allocationPool.description}</span>
              }
            </Typography>
          </div>
          <StartEndShiftButton
            shift={shift}
            setShiftUpdated={setShiftUpdated}
            setShiftUpdateError={setShiftUpdateError}
            multiShiftError={multiShiftError}
          />
        </Box>
      </Box>
    </ListItem>
  );
}

JoinShift.propTypes = {
  shift: PropTypes.object.isRequired,
  setShiftUpdated: PropTypes.func.isRequired,
  multiShiftError: PropTypes.bool,
};

export default (JoinShift);
