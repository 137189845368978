import React, { createContext, useReducer } from 'react';
import { sortBy } from 'lodash';
import { ACTIONS, ONBOARD_ACTION_TYPES, SERVICES } from '../../constants/joinSession';
import { SHARED_MEDIA_STATES, SCREEN_SHARE_TYPES } from '../../constants/mediaStates';
import streamSettings from '../../config/streamSettings';

const defaultParticipantMedia = {
  video: {
    stream: null,
    streaming: false,
    activeUser: null,
    participants: {},
  },
  audio: {
    streaming: false,
    active: false,
    muted: false,
    participants: {},
  },
  screenshare: {
    streaming: false,
    participants: {},
  },
};

const initialState = {
  currentActionType: ONBOARD_ACTION_TYPES.CONNECTING.mapper,
  currentFlowStepIndex: -1,
  deviceInfo: {
    audioInputDevice: 'default',
    audioOutputDevice: 'default',
    videoDevice: null,
    videoQuality: streamSettings.defaultWebcamQualityBitrate,
  },
  examSession: null,
  examState: null,
  gatewayConnectionOpen: false,
  participantMedia: defaultParticipantMedia,
  audioService: null,
  gatewayService: null,
  videoService: null,
  audioConnectionState: null,
  videoConnectionState: null,
  screenShareConnectionState: null,
  screenShareType: SCREEN_SHARE_TYPES.NONE.mapper,
  sharedMediaStatus: {
    webcam: SHARED_MEDIA_STATES.DISABLED,
    audio: SHARED_MEDIA_STATES.DISABLED,
    desktop: SHARED_MEDIA_STATES.DISABLED,
  },
  sharedMediaWarnings: {
    webcam: undefined,
    audio: undefined,
    desktop: undefined,
  },
  onboardAssignmentId: undefined,
  onboardProgressSteps: [],
};
const joinContext = createContext(initialState);
const { Provider } = joinContext;

const JoinProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case ACTIONS.UPDATE_DEVICES:
        return { ...state, deviceInfo: action.value };
      case ACTIONS.SET_CURRENT_ACTION:
        return { ...state, currentActionType: action.value?.actionType, currentFlowStepIndex: action.value?.actionStepIndex };
      case ACTIONS.SET_EXAM_SESSION:
        return { ...state, examSession: action.value };
      case ACTIONS.UPDATE_EXAM_SESSION:
        return {
          ...state,
          examSession: {
            ...state.examSession,
            ...action.value
          }
        };
      case ACTIONS.UPDATE_EXAM_STATE:
        return { ...state, examState: action.value };
      case ACTIONS.SET_SERVICE:
        if (!Object.values(SERVICES).includes(action.value.serviceType)) {
          return state;
        }
        return { ...state, [action.value.serviceType]: action.value.service };
      case ACTIONS.SET_GATEWAY_CONNECTION_OPEN:
        return { ...state, gatewayConnectionOpen: action.value };
      case ACTIONS.SET_AUDIO_STATE:
        return { ...state, audioConnectionState: action.value };
      case ACTIONS.SET_VIDEO_STATE:
        return { ...state, videoConnectionState: action.value };
      case ACTIONS.SET_SCREEN_SHARE_STATE:
        return { ...state, screenShareConnectionState: action.value };
      case ACTIONS.SET_SCREEN_SHARE_TYPE:
        return { ...state, screenShareType: action.value }
      case ACTIONS.SET_PARTICIPANT_MEDIA:
        return { ...state, participantMedia: action.value };
      case ACTIONS.SET_ONBOARD_ASSIGNMENT:
        return {
          ...state,
          onboardAssignmentId: action.value.id,
          onboardProgressSteps: sortBy(action.value.onboardProgressSteps, ['flowStep.position']),
        };
      case ACTIONS.SET_ONBOARD_PROGRESS_STEPS:
        return {
          ...state,
          onboardProgressSteps: sortBy(action.value, ['flowStep.position']),
        };
      case ACTIONS.UPDATE_ONBOARD_PROGRESS:
        const updatedProgressSteps = state.onboardProgressSteps.map(progressStep => {
          if (progressStep?.progress?.id === action?.value?.id) {
            return { ...progressStep, progress: action.value }
          }
          return progressStep;
        });
        return { ...state, onboardProgressSteps: updatedProgressSteps };
      case ACTIONS.SET_SHARED_MEDIA_STATUS:
        return { ...state, sharedMediaStatus: { ...state.sharedMediaStatus, ...action.value } };
      case ACTIONS.SET_SHARED_MEDIA_WARNINGS:
        return { ...state, sharedMediaWarnings: { ...state.sharedMediaWarnings, ...action.value } };
      case ACTIONS.UPDATE_SHARED_MEDIA_STATUS:
        return { ...state, sharedMediaStatus: { ...state.sharedMediaStatus, [action.value?.mediaType]: action.value?.status } };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const JoinConsumer = joinContext.Consumer;

export { joinContext, JoinProvider, JoinConsumer };
