import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { filter, find, has, isEmpty } from 'lodash';
import RedFlag from '../../icons/RedFlag';
import RaiseHand from '../../icons/RaiseHand';
import { NOTICE_TYPES } from '../../constants/monitorSessions';
import { themeObject } from '../../config/theme';

const styles = {
  summary: {
    display: 'flex',
    alignItems: 'center',
    background: 'black',
    borderRadius: '20px',
    color: 'white',
    textTransform: 'none',
    margin: 0.5,
    py: 1,
    px: 2,
    '& .MuiChip-root': {
      my: 0,
      mx: 0.5,
      backgroundColor: 'grey.800',
      color: 'inherit',
    },
    '& svg': {
      padding: 0.5,
      paddingRight: 0,
      height: '15px'
    },
  },
};

function NotificationSummary(props) {
  const { examSessions } = props
  const [totalFlags, setTotalFlags] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const [totalHands, setTotalHands] = useState(0);

  useEffect(() => {
    const sessionsWithNotice = filter(examSessions, session =>  has(session, 'notices'));
    let flags = 0, hands = 0, chats = 0;
    !isEmpty(sessionsWithNotice) && sessionsWithNotice.forEach(session => {
      find(session.notices, {type: NOTICE_TYPES.FLAG}) && flags++;
      find(session.notices, {type: NOTICE_TYPES.CHAT}) && chats++;
      find(session.notices, {type: NOTICE_TYPES.RAISEHAND}) && hands++;
    });
    setTotalFlags(flags);
    setTotalChats(chats);
    setTotalHands(hands);
  }, [examSessions])

  return (
    <Button sx={styles.summary} variant="contained" color="secondary">
      <ArrowUpwardIcon size="small" />
      <Box lineHeight="1.1rem" fontSize="0.8rem"> Review</Box>
      <Chip variant="standard" icon={<RaiseHand fill="#F7AD1A"/>} label={totalHands} size="small"></Chip>
      <Chip variant="standard" icon={<QuestionAnswerIcon style={{ color: lighten(themeObject.palette.primary.light, 0.25) }}/>} label={totalChats} size="small"></Chip>
      <Chip variant="standard" icon={<RedFlag/>} label={totalFlags} size="small"></Chip>
    </Button>
  );
}

NotificationSummary.propTypes = {
  examSessions: PropTypes.array,
};

export default NotificationSummary;
