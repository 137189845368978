import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import OnboardStepper from '../content/OnboardStepper';
import { lightestGrey } from '../../config/theme';

function WelcomeStep(props) {
  return (
    <>
      <Typography variant="h2">Welcome to eVigilation</Typography>
      <Typography>
        You have now launched the eVigilation platform.
        You’ll need to follow the on-screen prompts to complete the online check-in steps.
      </Typography>
      <Box bgcolor={lightestGrey} borderRadius={2} p={4} my={2}>
        <Typography variant="h3">eExam online check-in steps</Typography>
        <OnboardStepper orientation="vertical" />
      </Box>
      <Typography>
        You won't be greeted by an online supervisor, however if you require assistance, please use the
        Raise hand button or chat function to alert your supervisor and they'll attend to you as quickly
        as possible.
      </Typography>
      <Button
        color="primary"
        onClick={() => { props.onComplete({ flowStepIndex: props.flowStepIndex }) }}
        sx={{ mt: 2 }}
        variant="contained"
      >
        Start online check-in
      </Button>
    </>
  )
}

WelcomeStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default WelcomeStep;