export default class NetworkRequestError extends Error {

  /**
   * Constructor for a throwable error resulting when a network request fails.
   * @param {string} name The HTTP error code received.
   * @param {string} message The friendly message to display to users.
   * @param {object} body The response body content.
   * @param {Response} response The fetch response object.
   * @param params Any further error params.
   */
  constructor(name, message, body, response) {
    super(message);
    this.name = name;
    this.message = message;
    this.response = response;
    this.body = body;
  }

  /**
   * Get the detailed error message from the server, rather than the generic display message.
   * @returns {string} The detailed error string.
   */
  getDetailedMessage() {
    const contentType = this.response.headers.get('content-type');
    if (this.body) {
      if (contentType.includes('application/json')) {
        return JSON.stringify(this.body);
      }
      if (contentType.includes('text/plain')) {
        return this.body;
      }
    }
    return this.message;
  }
}