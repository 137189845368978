import MOCK from './mock';
import NetworkService from './NetworkService';
import mockRequest from '../utils/mockRequest';

export default class MeetingService {

  static async getActiveMediaHosts(abortSignal) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.MEDIA_HOSTS);
    }
    const response = await NetworkService.get('/exam-meetings/active-hosts', abortSignal);
    return await response.json();
  }

  static async getRecordingChatMessages(meetingId, abortSignal) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.EXAM_SESSION_RECORDING_CHATS);
    }

    const response = await NetworkService.get(`/exam-meeting/${meetingId}/recording/slides_new.xml`, abortSignal, 'application/xml');
    const responseText = await response.text();
    const parser = new DOMParser();

    return parser.parseFromString(responseText, 'application/xml');
  }

}
