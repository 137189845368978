import PromiseWithAbort from '../../../utils/PromiseWithAbort';
import { MEDIA_STREAM_CONNECTION_STATES } from '../../../constants/mediaStates';

const updateDevices = (currentDevices, updatedDevices, audioStreamService, setDevices, setAudioStatus, setMediaShareError) => {
  setDevices(updatedDevices);

  if (currentDevices?.audioInputDevice !== null && currentDevices?.audioInputDevice !== updatedDevices?.audioInputDevice) {
    setAudioStatus(MEDIA_STREAM_CONNECTION_STATES.CONNECTING);
    const asyncAbort = new PromiseWithAbort();

    asyncAbort.wrap(
      audioStreamService.changeInputDevice(updatedDevices.audioInputDevice, null))
      .then(() => {
        setAudioStatus(MEDIA_STREAM_CONNECTION_STATES.CONNECTED);
      })
      .catch((err) => {
        console.error(`Error while changing microphone`, {err});
        setAudioStatus(MEDIA_STREAM_CONNECTION_STATES.CLOSED);
        setMediaShareError("There was a problem changing the microphone. Please check your device and allow access before trying again.", err)
      });
  }

  if (currentDevices?.audioOutputDevice !== null && currentDevices?.audioOutputDevice !== updatedDevices?.audioOutputDevice) {
    // Change audio output
    audioStreamService.changeOutputDevice(updatedDevices.audioOutputDevice ? updatedDevices.audioOutputDevice : 'default')
      .catch((err) => {
        console.error(`Error while changing audio output device`, {err});
        setMediaShareError("There was a problem changing the speaker. Please check your device and allow access before trying again.", err)
      });
  }
};

export default updateDevices;