import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const styles = {
  responsiveImage: {
    maxWidth: '100%',
    marginBottom: 4,
  },
};

function MonitoringDefaultMessage(_props) {
  return (
    <Box display="flex" alignItems="center" height="100vh" width="calc(70% - 1px)" bgcolor="white" pl={8}>
      <div>
        <Box component="img" sx={styles.responsiveImage} alt="Empty dashboard example graphic" src="/images/empty-dashboard-illustration.svg" />
        <Typography variant="h3" component="h2">Select a session to start or continue supervising.</Typography>
        <Typography>
          Click any errors on the left to reconnect.<br/>
          You can refresh your browser if there are multiple errors.
        </Typography>
      </div>
    </Box>
  );
}

export default MonitoringDefaultMessage;
