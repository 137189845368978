import { isEmpty } from 'lodash';

const mapToDto = (dto, object) => {
  let mappedDto = {};
  if(isEmpty(dto) || isEmpty(object)) {
    return mappedDto;
  }
  Object.keys(dto).forEach(key => {
    if(object[key]) { mappedDto[key] = object[key]; }
  });
  return mappedDto;
}

export default mapToDto;