import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ChatInfo from '../content/ChatInfo';
import ContactNumbers from '../content/ContactNumbers';
import RaiseHandInfo from '../content/RaiseHandInfo';
import GatewayService from '../../services/GatewayService';
import { EXAM_SESSION_TYPE_LABELS as typeLabels } from '../../constants/examSessions';

function HelpContainer(props) {
  const {
    activeParticipants,
    examType,
    gatewayConnectionOpen,
    gatewayService,
    humanSupervised,
    onRaiseHand
  } = props;

  const showRaiseHand = gatewayService && gatewayConnectionOpen && typeLabels[examType] === typeLabels.ASSISTED;
  const showChat = gatewayService && gatewayConnectionOpen && (humanSupervised || (!humanSupervised && activeParticipants));

  return (
    <Box display="flex" flexDirection="column">
      {showRaiseHand &&
        <RaiseHandInfo gatewayService={gatewayService} onRaiseHand={onRaiseHand} />
      }
      {/* the chat container adds a bunch of props, simplest just to pass them all through */}
      {showChat &&
        <ChatInfo {...props} />
      }
      <ContactNumbers sxProps={{ bgcolor: 'common.white', p: 2 }} />
    </Box>
  )
}

HelpContainer.propTypes = {
  activeParticipants: PropTypes.bool, //this is added by the ChatContainer but used here
  examType: PropTypes.string,
  gatewayConnectionOpen: PropTypes.bool,
  gatewayService: PropTypes.instanceOf(GatewayService),
  humanSupervised: PropTypes.bool,
  onRaiseHand: PropTypes.func,
};

export default HelpContainer;
