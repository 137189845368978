import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { has, isEmpty, isEqual } from 'lodash';
import { getLastActiveSupervisor } from '../../utils/getExamSlotUser';
import { getFormattedDate, getFormattedDeletionDate } from '../../utils/getExamDetails';
import { EXAM_RECORDINGS_FIELDS, GROUP_IDS } from '../../constants/examRecordings';
import { EXAM_SESSION_CAPABILITIES as CAPABILITIES, EXAM_SESSION_DELETION_STATUS, EXAM_SESSION_INTEGRITY } from '../../constants/examSessions';
import { default as FLAGS, PROCESS_VIDEO_FLAG_EVENT_TYPE } from '../../constants/flags';
import StatusChip from '../content/StatusChip';
import DeletionStatusChip from '../content/DeletionStatusChip';
import ExamSessionReviewActionMenu from '../form/ExamSessionReviewActionMenu';
import MisconductDetailsPopup from '../popup/MisconductDetailsPopup';
import { authContext } from '../../authContext';
import { CanThey } from '../Can';

const classes = {
  flagLabel: {
    marginTop: -1,
    textAlign: 'center',
  }
};

const HEADERS = { ...EXAM_RECORDINGS_FIELDS, ...FLAGS }

class ExamFlagAndRecordingTable extends React.Component {
  state = {
    processedExamSessionList: [],
    hasSlotUpdated: false,
    columns: [],
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { examSessionList, flags } = this.props;
    if (!isEqual(prevProps.examSessionList, examSessionList) || !isEqual(flags, prevProps.flags)) {
      this.loadData();
    }
  }

  loadData = () => {
    const { examSessionList, flags } = this.props;
    const processedData = this.getExamSessionsData(examSessionList, flags);
    const columns = this.getColumns(flags, examSessionList[0].context.id);

    this.setState({
      columns: columns,
      processedExamSessionList: processedData,
    });
  }

  flagFiltersLogic = (numFlags, filterVal) => {
    const show =
      (filterVal.indexOf('More than 0') >= 0 && numFlags > 0) ||
      (filterVal.indexOf('None') >= 0 && (numFlags === 0 || numFlags === undefined));
    return !show;
  }

  canViewColumn = (heading, contextId) => {
    if (heading === 'misconduct' || heading === 'recordExpiry' || heading === 'deletionStatus') {
      return CanThey(this.context.capabilityContextAccess, true, CAPABILITIES.viewIntegrity, { id: contextId })
        || CanThey(this.context.capabilityContextAccess, true, CAPABILITIES.manageIntegrity, { id: contextId });
    }
    return true;
  }

  setHasSlotUpdated = (hasSlotUpdated) => {
    this.setState({
      hasSlotUpdated: hasSlotUpdated
    });
  };

  updateSlotFlagCount = (addSlotFlagObject) => {
    const { processedExamSessionList } = this.state;
    const { flag, examSlot } = addSlotFlagObject;

    const processedExamSlot = processedExamSessionList.find(es => es.id === examSlot.id);
    const updatedFlagCount = processedExamSlot?.[flag.eventType] !== undefined ? processedExamSlot?.[flag.eventType] + 1 : 1;
    const updatedTotalFlags = processedExamSlot?.totalFlags !== undefined ? processedExamSlot?.totalFlags + 1 : 1;

    const updateExamObject = {
      [flag.eventType]: updatedFlagCount,
      totalFlags: updatedTotalFlags,
      action: this.getActionMenu(examSlot, flag.eventType === PROCESS_VIDEO_FLAG_EVENT_TYPE),
    };

    const updatedExamSessionList = processedExamSessionList.map(es => { return es.id === examSlot.id ? { ...es, ...updateExamObject} : es });
    this.setState({
      hasSlotUpdated: false,
      processedExamSessionList: updatedExamSessionList,
    });
  }

  updateSingleSlot = (updateObject) => {
    const { processedExamSessionList } = this.state;
    const { id, integrityReview, cannotExpire, deletionStatus, deletedDate } = updateObject;
    const formattedUpdateObj = {
      misconduct: EXAM_SESSION_INTEGRITY[integrityReview]?.label,
      misconductDisplay: this.renderMisconductLabel(integrityReview, cannotExpire),
      deletionStatus: deletionStatus,
      deletionStatusDisplay: <DeletionStatusChip deletionStatus={deletionStatus} deletedDate={deletedDate} />,
      cannotExpire: cannotExpire,
      recordExpiry: getFormattedDeletionDate(updateObject),
      action: this.getActionMenu(updateObject, processedExamSessionList.find(es => es.id === id)?.[PROCESS_VIDEO_FLAG_EVENT_TYPE] > 0),
    };

    const updatedExamSessionList = processedExamSessionList.map(es => { return es.id === id ? { ...es, ...formattedUpdateObj } : es });
    this.setState({
      hasSlotUpdated: false,
      processedExamSessionList: updatedExamSessionList,
    });
  }

  getActionMenu = (examSession, hasProcessRecordingFlag) => {
    const { flags } = this.props;
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ExamSessionReviewActionMenu
          setHasSlotUpdated={this.setHasSlotUpdated}
          updateSlotFlagCount={this.updateSlotFlagCount}
          updateSlot={this.updateSingleSlot}
          examSession={examSession}
          hasProcessRecordingFlag={hasProcessRecordingFlag}
          processRecordingFlag={flags.find(dbFlag => dbFlag.eventType === PROCESS_VIDEO_FLAG_EVENT_TYPE)}
        />
      </Box>
    );
  }

  getColumnOptions = (heading) => {
    if (this.props.groupBy === GROUP_IDS.NONE && !isEmpty(HEADERS[heading]?.ungroupedOptions)) {
      return HEADERS[heading].ungroupedOptions;
    } else {
      return HEADERS[heading]?.options || {};
    }
  }

  getColumns = (flags, contextId) => {
    let columns = [];
    Object.keys(HEADERS).sort((a, b) => HEADERS[a].reportOrder - HEADERS[b].reportOrder)
      .filter((heading) => {
        return this.canViewColumn(heading, contextId);
      })
      .forEach((heading) => {
        const flagOptionLabels = ['More than 0', 'None'];
        const standardLabel = HEADERS[heading].display;
        const headingDisplayKey = HEADERS[heading].displayKey;
        if (HEADERS[heading].display) {
          const columnOptions = this.getColumnOptions(heading);
          let colOptions = {
            ...columnOptions,
            customFilterListOptions: {
              render: v => `${standardLabel}: ${v}`,
            },
          }
          if (heading === 'totalFlags') {
            colOptions['filterOptions'] = {
              names: flagOptionLabels,
              logic: (numFlags, filterVal) => {
                return this.flagFiltersLogic(numFlags, filterVal)
              }
            }
          } else if (headingDisplayKey !== undefined) {
            colOptions['customBodyRenderLite'] = (dataIndex) => {
                const { processedExamSessionList } = this.state;
                return processedExamSessionList[dataIndex][headingDisplayKey];
            }
          }
          columns.push({
            name: heading,
            label: standardLabel,
            options: {
              ...colOptions,
            },
          })
        } else {
          const flag = flags.find(dbFlag => dbFlag.eventType === heading);
          flag && columns.push(
            {
              name: heading,
              label: flag['name'],
              options: {
                filter: true,
                customHeadLabelRender: () => {
                  return (
                    <Tooltip title={flag['name']} placement='top'>
                      <div>
                        {HEADERS[heading].icon}
                        {flag.autoOnly && <Box sx={classes.flagLabel}>AI</Box>}
                      </div>
                    </Tooltip>
                  )
                },
                customFilterListOptions: {
                  render: v => `${flag['name']}: ${v}`
                },
                // do a custom filter for !0 flags
                filterOptions: {
                  names: flagOptionLabels,
                  logic: (numFlags, filterVal) => {
                    return this.flagFiltersLogic(numFlags, filterVal)
                  },
                },
                customBodyRenderLite: (dataIndex) => {
                  const { processedExamSessionList } = this.state;
                  return processedExamSessionList[dataIndex][heading] || 0;
                },
                setCellProps: (_v) => {
                  return {
                    align: 'right',
                  }
                },
              },
            }
          )
        }
      });
    return columns;
  }

  renderMisconductLabel = (misconductStatus, cannotExpire) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <StatusChip status={misconductStatus} styles={EXAM_SESSION_INTEGRITY} />
        {cannotExpire &&
          <MisconductDetailsPopup />
        }
      </Box>
    );
  }

  processExamSessionData = (examSession, flags) => {
    if (isEmpty(examSession)) {
      return {};
    }
    let session = {};
    session['id'] = examSession.id;
    session['contextName'] = examSession.context.name;
    session['examDate'] = getFormattedDate(examSession.examStartDateTime, "dd/MMM/yyyy");
    if (has(examSession, 'student') && !isEmpty(examSession.student)) {
      session['studentName'] = examSession.student.fullName;
      session['studentId'] = examSession.student.externalId;
    }

    //TODO: Do we want to list all supervisors here (maybe as a hover over box) or only the "main" supervisor?
    session['supervisor'] = has(examSession, 'supervisors') && !isEmpty(examSession.supervisors) ?
      getLastActiveSupervisor(examSession).fullName : ' - ';
    //TODO: Do we want to put the onboarder here as well in a table column?
    session['recordingsAvailable'] = examSession.recordingsAvailable ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;

    session['misconduct'] = EXAM_SESSION_INTEGRITY[examSession.integrityReview]?.label || 'None';
    session[HEADERS.misconduct.displayKey] = this.renderMisconductLabel(examSession.integrityReview, examSession.cannotExpire);

    session['recordExpiry'] = getFormattedDeletionDate(examSession);
    session['deletionStatus'] = EXAM_SESSION_DELETION_STATUS[examSession.deletionStatus]?.label;
    session[HEADERS.deletionStatus.displayKey] = <DeletionStatusChip deletionStatus={examSession.deletionStatus} deletedDate={examSession.deletedDate} />

    session['totalFlags'] = 0;
    if (has(examSession, 'flags') && examSession.flags !== null) {
      flags.forEach(flag => {
        examSession.flags.filter(f => f.name === flag.name && HEADERS[flag.eventType]).forEach(sessionFlag => {
          session[flag.eventType] = sessionFlag.count;
          session['totalFlags'] += sessionFlag.count;
        });
      });
    }
    session['action'] = this.getActionMenu(examSession, session[PROCESS_VIDEO_FLAG_EVENT_TYPE] > 0);
    session['cannotExpire'] = examSession.cannotExpire;
    return session;
  };

  getExamSessionsData = (examSessionList, flags) => {
    return examSessionList.map((examSession) => this.processExamSessionData(examSession, flags)).sort((e1, e2) => e1.id - e2.id);
  }

  getRowsPerPageOptions(listLength) {
    return listLength && listLength > 100 ? [10, 50, 100, listLength] : [10, 50, 100];
  }

  render() {
    const { processedExamSessionList, columns } = this.state;
    const rowsPerPageOptions = this.getRowsPerPageOptions(processedExamSessionList?.length);

    return (
      <>
        <MUIDataTable
          columns={columns}
          data={processedExamSessionList}
          options={{
            selectableRows: 'none',
            filterType: 'multiselect',
            pagination: true, //need to think about how we're going to do it. For now, it'll be extra clicks to see all the data
            rowsPerPageOptions: rowsPerPageOptions,
            responsive: 'simple',
            print: false,
            setTableProps: () => ({
              sx: { '& th, td': { p: 0.5 }, '& th button': {m: 0, p: 0.5, pt: '7px'} }
            }),
          }}
        />
      </>
    );
  }
}

ExamFlagAndRecordingTable.propTypes = {
  examSessionList: PropTypes.array.isRequired,
  flags: PropTypes.array.isRequired,
  groupBy: PropTypes.string,
};

export default ExamFlagAndRecordingTable;
ExamFlagAndRecordingTable.contextType = authContext;
