import { EXAM_SESSION_TYPE_LABELS as typeLabels } from '../constants/examSessions';
import { has, isEmpty } from 'lodash';

const getExamSessionType = (humanSupervised, aiType) => {
  if(!humanSupervised && (aiType === null || aiType === undefined)) {
    // type hasn't been set
    return '';
  }

  switch(aiType) {
    case 'AUTOMATIC':
    case 'MANUAL':
      return humanSupervised ? typeLabels.SUPERVISED_AND_AI : typeLabels.AI_ONLY;
    case 'NONE':
      return humanSupervised ? typeLabels.SUPERVISED_ONLY : typeLabels.RECORD_ONLY;
    case 'ASSISTED':
      return humanSupervised ? typeLabels.ASSISTED : typeLabels.UNKNOWN;
    default:
      return typeLabels.UNKNOWN;
  }
}

export const getExamSessionTypeValue = (examSession) => {
  let examSessionType = has(examSession, 'examType') && !isEmpty(examSession.examType) ? examSession.examType : '';
  if (examSessionType !== null) {
    return typeLabels[examSessionType];
  } else {
    return '';
  }
};

export default getExamSessionType;
