import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { joinContext } from '../context/JoinContext';
import MediaConnectInfoStep from './MediaConnectInfoStep';
import MediaConnectStepPopup from './MediaConnectStepPopup';

function MediaConnectStep(props) {
  const { state } = useContext(joinContext);
  const { flowStepIndex, mediaType } = props;
  const [popupOpen, setPopupOpen] = useState(true);

  return (
    <>
      {/* background page as the media info */}
      <MediaConnectInfoStep flowStepIndex={flowStepIndex} onComplete={() => { /* do nothing, just for background */ }} />
      {/* actual step actions based on mediaType of the current step */}
      <MediaConnectStepPopup
        mediaType={mediaType}
        onClose={() => setPopupOpen(false)}
        open={popupOpen}
        stepInfo={state.onboardProgressSteps[flowStepIndex]}
      />
    </>
  )
}

MediaConnectStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  mediaType: PropTypes.string.isRequired,
};

export default MediaConnectStep;