import React from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import Auth from './Auth';
import AuthService from '../../services/AuthService';

function AuthDirect(props) {
  const { authState } = useOktaAuth();

  return (
    <Auth
      accessTokenFromIdp={authState?.accessToken?.accessToken}
      getAccessTokenFunc={AuthService.getDirectAccessToken}
      isAuthenticated={authState?.isAuthenticated}
    >
      { props.children }
    </Auth>
  );

}

AuthDirect.propTypes = {
  children: PropTypes.object,
};

export default AuthDirect;
