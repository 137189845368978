export const ACCEPTED_ID_DOCS_MAPPER = 'accepted-id-docs';
export const ACTIVE_STEPS_MAPPER = 'active-steps';
export const AUTO_ACTIVE_STEPS_MAPPER = 'auto-active-steps';
export const CONTACT_NUMBERS_MAPPER = 'it-support-numbers';
export const HUMAN_WAIT_STEPS_MAPPER = 'human-onboarding-wait-steps';
export const AUTO_ONBOARD_WAIT_STEPS_MAPPER = 'auto-onboard-wait-steps';
export const PRIVACY_STATEMENT_MAPPER = 'privacy-statement';
export const SELF_ACTIVE_STEPS_MAPPER = 'self-active-steps';
export const SUPERVISION_COMPLETE_MAPPER = 'supervision-complete-text';
export const TROUBLESHOOTING_LINKS_MAPPER = 'troubleshooting-links';
export const TERMS_CONDITIONS_MAPPER = 'student-terms';

const CONTENT_OVERRIDES = [
  ACCEPTED_ID_DOCS_MAPPER,
  ACTIVE_STEPS_MAPPER,
  AUTO_ACTIVE_STEPS_MAPPER,
  AUTO_ONBOARD_WAIT_STEPS_MAPPER,
  CONTACT_NUMBERS_MAPPER,
  HUMAN_WAIT_STEPS_MAPPER,
  PRIVACY_STATEMENT_MAPPER,
  SELF_ACTIVE_STEPS_MAPPER,
  SUPERVISION_COMPLETE_MAPPER,
  TROUBLESHOOTING_LINKS_MAPPER,
  TERMS_CONDITIONS_MAPPER,
];

export default CONTENT_OVERRIDES;
