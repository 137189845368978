import logToGateway from '../../../utils/logToGateway';
import { MEDIA_STREAM_CONNECTION_STATES } from '../../../constants/mediaStates';

const endEchoTest = (audioStatus, audioStreamService, logDetails, setEndEchoTestError, transferCompleteCallback) => {
  if (audioStreamService && audioStatus !== MEDIA_STREAM_CONNECTION_STATES.CLOSED) {
    audioStreamService.transferToConference()
      .then(() => {
        transferCompleteCallback && transferCompleteCallback();
      });
  } else {
    setEndEchoTestError();
    logToGateway(
      logDetails.gatewayService,
      logDetails.logType,
      { error: 'Audio connection not open for transfer from echo test', source: 'endEchoTest' },
      logDetails.userId,
      logDetails.slotId
    );
  }
}

export default endEchoTest;