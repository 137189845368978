import React from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';
import App from '../../App';
import AuthDirect from './AuthDirect';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_AUTHCONFIG_ISSUER,
  clientId: process.env.REACT_APP_AUTHCONFIG_CLIENT_ID,
  redirectUri: process.env.REACT_APP_APPCONFIG_FRONTEND_URL +
    process.env.REACT_APP_AUTHCONFIG_REDIRECT_URI_PARTIAL,
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  storageManager: {
    token: {
      storageTypes: ['sessionStorage'],
    },
  },
});


const Oidc = () => {
  const navigate = useNavigate();
  const customAuthHandler = (initialisedOktaAuth) => {
    initialisedOktaAuth?.token?.getWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
        <AuthDirect>
          <App/>
        </AuthDirect>
    </Security>
  );
};

export default Oidc;
