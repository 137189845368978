import { assign } from 'lodash';

const logToGateway = (gatewayConnection, type, details, userId, slotId) => {
  if (gatewayConnection) {
    assign(details, {
      userId: userId,
      slotId: slotId,
    });
    gatewayConnection.logInfo(type, details);
  }
};

export default logToGateway;