import React, { useCallback, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { authContext } from '../../authContext';
import { joinContext } from '../context/JoinContext';
import { formatErrorForDisplay } from '../../utils/formatErrorForDisplay';
import logToGateway from '../../utils/logToGateway';
import PromiseWithAbort from '../../utils/PromiseWithAbort';
import { ACTIONS } from '../../constants/joinSession';
import { SHARED_MEDIA_STATES, MEDIA_STREAM_CONNECTION_STATES } from '../../constants/mediaStates';

function SingleVideoConnect(props) {
  const { state, dispatch } = useContext(joinContext);
  const { user } = useContext(authContext);
  const { setConnectionError, videoTypeConfig } = props;
  const connectionStarted = useRef(false);
  const asyncAbort = useRef(new PromiseWithAbort());
  const localConnectState = state[videoTypeConfig.localConnectStateName];

  const initialiseVideo = useCallback(() => {
    const connectionProps = state.examSession?.connectionDetails?.connectionProps;
    asyncAbort.current.wrap(
      state.videoService.open(connectionProps, videoTypeConfig.serviceMediaTypeLabel)
    )
      .then(() => {
        dispatch({ type: videoTypeConfig.localConnectStateAction, value: MEDIA_STREAM_CONNECTION_STATES.CONNECTED });
        // If we already were notified about available streams, connect them
        if (state.participantMedia?.[videoTypeConfig.serviceMediaTypeLabel]?.stream !== null) {
          dispatch({
            type: ACTIONS.SET_PARTICIPANT_MEDIA,
            value: {
              ...state.participantMedia,
              [videoTypeConfig.serviceMediaTypeLabel]: {
                ...state.participantMedia[videoTypeConfig.serviceMediaTypeLabel],
                streaming: true
              },
            }
          });
        }
        setConnectionError(videoTypeConfig.errorLabel, {}, true);
      })
      .catch((error) => {
        console.error(`Unable to open video (${videoTypeConfig.errorLabel}) connection`, error);
        setConnectionError(videoTypeConfig.errorLabel, formatErrorForDisplay(error, videoTypeConfig.errorLabel, 'streamOpen'), false)
        logToGateway(
          state.gatewayService,
          'student_video_open',
          { error: error, source: 'initVideo', connectionProps },
          user.id,
          state.examSession.id
        );
        dispatch({ type: videoTypeConfig.localConnectStateAction, value: MEDIA_STREAM_CONNECTION_STATES.CLOSED });
        dispatch({
          type: ACTIONS.UPDATE_SHARED_MEDIA_STATUS,
          value: { mediaType: videoTypeConfig.sharedMediaStatusLabel, status: SHARED_MEDIA_STATES.DISABLED }
        });
        connectionStarted.current = false;
      });

  }, [dispatch, setConnectionError, state.examSession, state.gatewayService, state.participantMedia,
    state.videoService, user.id, videoTypeConfig]);

  useEffect(() => {
    if (localConnectState === MEDIA_STREAM_CONNECTION_STATES.CONNECTING  && !connectionStarted.current) {
      connectionStarted.current = true;
      initialiseVideo();
    }
  }, [connectionStarted, initialiseVideo, localConnectState]);

  useEffect(() => {
    const asyncAbortRef = asyncAbort.current;
    return () => {
      asyncAbortRef?.abort();
    }
  }, [])

  return <></>
}

SingleVideoConnect.propTypes = {
  setConnectionError: PropTypes.func.isRequired,
  videoTypeConfig: PropTypes.object.isRequired,
};

export default SingleVideoConnect;