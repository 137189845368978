import { sortBy, toLower } from 'lodash';
// import parseISO from 'date-fns/parseISO';
import getFirstTimeFromNotices from './getFirstTimeFromNotices';

const getInitialSortValue = (session, sortType) => {
  let sortValue;
  switch(sortType) {
    // once we can get the student's joined time instead of the supervisors, we can sort by joinedTime
    // case 'joinedTime':
    //   sortValue = parseISO(session.connectionDetails.connectionProps?.joinedTime);
    //   break;
    case 'notices':
    default:
      sortValue = getFirstTimeFromNotices(session.notices);
      break;
  }
  return sortValue;
};

const sortMonitoringSessions = (sessions, type) => {
  if(!sessions) return [];
  return sortBy(sessions, [
    (session) => { return getInitialSortValue(session, type.sort)},
    (session) => { return toLower(session.student.fullName) }
  ]);
};

export default sortMonitoringSessions;
