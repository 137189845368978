import UserService from "../services/UserService";
import {debounce, has, isEmpty} from "lodash";

export function formatUsers(usersResponse) {
  const users = has(usersResponse, 'users') ? usersResponse.users : usersResponse
  if (isEmpty(users)) {
    return undefined;
  }
  let usersList = users.map((user) => {
    return {
      value: user ? user.id : '',
      user: user,
      label: user ? `${user.fullName} (${user.userName})` : ''
    }
  });

  if(has(usersResponse, 'status') && usersResponse.status === 206) {
    usersList.push({value: null, user: undefined, label : "Too many results, please refine your search", isDisabled: true});
  }
  return usersList;
}


export function getUserOptions(inputValue, callback) {
  if (inputValue === undefined || inputValue.length < 3) {
    callback([]);
  } else {
    debouncedFetchUsers(inputValue, callback);
  }
}

const debouncedFetchUsers = debounce((searchTerm, callback) => getUsersAndFormat(searchTerm, callback), 250);

async function getUsersAndFormat(searchTerm, callback) {
  try {
    const users = await UserService.getUsers(searchTerm, false, true, process.env.REACT_APP_USER_SEARCH_RESULT_LIMIT);
    callback(formatUsers(users));
  } catch (error) {
    callback([]);
  }
}
