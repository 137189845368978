import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import { has } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import { ACTIONS } from '../../constants/monitorSessions';
import GatewayService from '../../services/GatewayService';

const styles = {
  resolveNoticesButton: {
    lineHeight: 'initial',
    margin: 'auto',
  },
};

function ResolveNoticesButton(props) {

  const { examSlotId, gatewayService } = props;
  const { state, dispatch } = useContext(monitoringContext);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const clearNotices = useCallback(() => {
    const clearFromTime = Date.now();
    // Send a message to the gateway to update the server copy of notices
    gatewayService.resolveNotices(clearFromTime);

    // Update the local state accordingly
    dispatch({type: ACTIONS.CLEAR_NOTICES, value: {id: examSlotId, timestamp: clearFromTime}});
  }, [examSlotId, dispatch, gatewayService]);

  useEffect(() => {
    // Whenever the exam details list is updated, see if the button state should change due to notices
    // arriving or being cleared
    const hasNotices = has(state.examSessions, examSlotId + '.notices')
      && state.examSessions[examSlotId].notices.length > 0;

    if (buttonEnabled !== hasNotices) {
      setButtonEnabled(hasNotices);
    }
  }, [state.examSessions, buttonEnabled, examSlotId])


  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<DoneAllRoundedIcon />}
      aria-label="Verify and clear notifications"
      onClick={clearNotices}
      disabled={!buttonEnabled}
      className="resolve-notices-button"
      sx={styles.resolveNoticesButton}
    >
      Verify and clear notifications
    </Button>
  );
}

ResolveNoticesButton.propTypes = {
  examSlotId: PropTypes.string.isRequired,
  gatewayService: PropTypes.instanceOf(GatewayService).isRequired,
};

export default ResolveNoticesButton;
