import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import AuthorisedMaterials from '../content/AuthorisedMaterials';
import { joinContext } from '../context/JoinContext';

function AuthorisedMaterialsStep(props) {
  const { state } = useContext(joinContext);
  const [hasConfirmedAuthMaterials, setHasConfirmedAuthMaterials] = useState(false);
  const [hasAcknowledgedResponsibility, setHasAcknowledgedStatement] = useState(false);

  const hasConfirmedAll = hasConfirmedAuthMaterials && hasAcknowledgedResponsibility;

  const handleCheckbox = (onChange) => (event) => {
    onChange(event.target.checked);
  }

  const handleContinue = (_event) => {
    props.onComplete({ flowStepIndex: props.flowStepIndex });
  }

  return (
    <>
      <Typography variant="h2">Authorised Materials</Typography>
      <Typography variant="h3">Permitted materials for this exam are:</Typography>
      <AuthorisedMaterials
        slotId={state.examSession.id}
        showHeader={false}
        showHybridText={false}
        sx={{ p: 0 }}
      />
      <FormControlLabel
        sx={{ my: 1 }}
        control={
          <Checkbox
            name="auth-material-confirmation"
            onChange={handleCheckbox(setHasConfirmedAuthMaterials)}
            color="primary" />
        }
        label="I confirm I have ONLY the authorised materials listed above in my possession and will not use unauthorised materials during my exam."
      />
      <FormControlLabel
        sx={{ my: 1 }}
        control={
          <Checkbox
            name="responsibility-acknowledgment"
            onChange={handleCheckbox(setHasAcknowledgedStatement)}
            color="primary" />
        }
        label="I acknowledge my responsibility to read all the information presented on the home page of my exam. I agree to follow any additional instructions contained within the exam."
      />
      <Box mt={1}>
        <Button
          variant="contained"
          color="primary"
          disabled={!hasConfirmedAll}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </>
  );
}

AuthorisedMaterialsStep.propTypes = {
  onComplete: PropTypes.func,
  flowStepIndex: PropTypes.number,
}

export default AuthorisedMaterialsStep;
