const CONTEXTS = {
  "name": "root",
  "id": "effb029b-b806-4013-a96c-e689bbba0331",
  "children": [
    {
      "name": "child 1",
      "id": "c1be44bf-fa3b-4e61-91b3-d22287c41cc4",
      "parent": {
        "id": "effb029b-b806-4013-a96c-e689bbba0331"
      },
      "children": [
        {
          "name": "layer 3",
          "id": "b4369a7d-b5c9-4d94-8d2f-c81656bc1e13"
        }
      ]
    },
    {
      "name": "child 2",
      "id": "8661c0b8-7bf1-4615-aa18-9c25f846056a"
    }
  ]
};

export default CONTEXTS;
