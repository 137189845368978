import React from 'react';
import App from "../../App";
import NoAuth from "./NoAuth";

class NoOidc extends React.Component {
  render() {
    return (
      <NoAuth>
        <App/>
      </NoAuth>
    )
  }
}

export default NoOidc;  
