import { isEmpty } from 'lodash';

/**
 * Checks if an individual step is completed
 * @param {Object} onboardProgress Individual progress object
 * @returns {boolean} True if the step is completed
 */
export const isProgressComplete = (onboardProgress) => {
  return (onboardProgress.complete === true || !isEmpty(onboardProgress.skippedBy));
};
