import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import RaiseHand from '../../icons/RaiseHand';
import GatewayService from '../../services/GatewayService';
import { NOTICE_TYPES } from '../../constants/monitorSessions';

class RaiseHandButton extends React.Component {
  state = {
    handRaised: false,
    disableButton: false,
    raiseTime: 0,
  }

  componentDidMount() {
    const { gatewayService } = this.props;
    if (gatewayService) {
      // Add handler to detect when raise hand is lowered remotely
      gatewayService.registerResolveNoticesEvent(this.onResolveNotices);
    }
  }

  /**
   * Handle an event received that indicates notices have been resolved.
   * @param {array} types Notice types that were resolved (null for all types)
   * @param {number} timestamp The datetime when the resolution occurred
   * @param {string} resolvedBy The evig user UUID who resolved the notices
   */
  onResolveNotices = (types, timestamp, resolvedBy) => {
    const { raiseTime } = this.state;
    if ((isEmpty(types) || types.includes(NOTICE_TYPES.RAISEHAND)) && timestamp >= raiseTime) {
      // The raise hand button has been cleared, it should be reset
      console.debug(`Raised hand has been cleared remotely by user ${resolvedBy}, types cleared: ${types.join(", ")}`);
      this.setState({
        handRaised: false
      });
    }
  }

  componentWillUnmount() {
    const { gatewayService } = this.props;
    if (gatewayService) {
      gatewayService.unregisterHandler('onResolveNotices', this.onResolveNotices);
    }
  }

  handleClick = () => {
    const { onRaiseHand } = this.props;

    if (onRaiseHand) {
      onRaiseHand(!this.state.handRaised);
      let stateChange = {
        handRaised: !this.state.handRaised,
        disableButton: true,
      };
      if (!this.state.handRaised) {
        stateChange.raiseTime = Date.now();
      }
      this.setState(stateChange);
      setTimeout(() => {
        this.setState({disableButton: false})
      }, 1000);
    }
  }

  render() {
    const { disableButton, handRaised } = this.state;

    return (
      <Button
        color={handRaised ? 'secondary' : 'primary'}
        disabled={disableButton}
        id="raise-hand-button"
        onClick={this.handleClick}
        startIcon={<RaiseHand/>}
        sx={{textTransform: 'initial', textAlign: 'left', justifyContent: 'left',}}
        variant='contained'
      >
          {handRaised ? 'Undo' : 'Raise hand'}
      </Button>
    );
  }
}

RaiseHandButton.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  onRaiseHand: PropTypes.func,
}

export default RaiseHandButton;
