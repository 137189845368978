import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SHARED_MEDIA_STATES } from '../../constants/mediaStates';

function ScreenShareAdvice(props) {

  const isConnectedOrConnecting = props.connectionState === SHARED_MEDIA_STATES.CONNECTED || props.connectionState === SHARED_MEDIA_STATES.CONNECTING;

  return (
    <>
      <Typography variant="body1" gutterBottom>
        In the next step, you must share your entire screen.<br/>
        If you only select a window or tab, it may be considered a breach of exam conditions.
      </Typography>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Button
          color="primary"
          disabled={isConnectedOrConnecting}
          variant="contained"
          onClick={() => {
            props.handleConfirm();
          }}
        >
          Select screen
        </Button>
      </Box>
    </>
  )
}

ScreenShareAdvice.propTypes = {
  connectionState: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
};

export default ScreenShareAdvice;