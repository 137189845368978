import { each, flatten, isEmpty, omit } from 'lodash';

const flattenContexts = (contexts, list = []) => {
  if (isEmpty(contexts)) {
    return undefined;
  }
  if (isEmpty(list)) list.push(omit(contexts, 'children'));
  each(contexts.children, (item) => {
    list.push(omit(item, 'children'));
    if (Array.isArray(item.children)) {
      flattenContexts(item, list);
    }
    return true;
  });

  return flatten(list);
};

export default flattenContexts;