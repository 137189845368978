import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import NavigationMenu from '../navigation/NavigationMenu';
import Header from '../content/Header';
import Footer from '../content/Footer';
import Routing from '../../utils/Routing';

const styles = {
  main: {
    display: 'flex',
    backgroundColor: '#f5f6f6',
  },
  pageContent: {
    flex: 1,
    boxSizing: 'border-box',
    minHeight: '100vh'
  },
};

/**
 * This takes the view of the current page and wraps it with various semantic
 * components (a header and footer, a menu for general navigation)
 */
function PageWrapper(props) {
  const { children, route } = props;
  const [isNavigationMenuExpanded, setIsNavigationMenuExpanded] = useState(false)

  const toggleNavigationMenuExpansion = () => {
    setIsNavigationMenuExpanded(!isNavigationMenuExpanded);
  };

  const getThisPageRoute = () => {
    return route.split('/')[1];
  };

  const shouldDisplayMenu = () => {
    const thisRoute = getThisPageRoute();
    return Routing.SUPERVISE_SESSION !== thisRoute
      && Routing.JOIN_SESSION !== thisRoute
      && Routing.MONITOR_SESSIONS !== thisRoute
      && process.env.REACT_APP_AUTHCONFIG_REDIRECT_URI_PARTIAL !== route;
  };

  const shouldDisplayFooter = () => {
    const thisRoute = getThisPageRoute();
    return Routing.MONITOR_SESSIONS !== thisRoute;
  };

  const displayMenuOrHeader = shouldDisplayMenu();
  const navSize = isNavigationMenuExpanded ? 206 : 57;
  const pageHeight = displayMenuOrHeader ? 'calc(100vh - 96px)' : '100vh';

  return (
    <>
      {displayMenuOrHeader &&
      <Header displayMenu={displayMenuOrHeader} toggleNavigationMenuExpansion={toggleNavigationMenuExpansion} />
      }
      <main style={styles.main}>
        {displayMenuOrHeader && <NavigationMenu expanded={isNavigationMenuExpanded} /> }
        <Box style={{width: `calc(100% - ${navSize}px)`, minHeight: pageHeight}} sx={styles.pageContent} p={displayMenuOrHeader ? 2 : 0}>
          {children}
        </Box>
      </main>

      {shouldDisplayFooter() && <Footer />}
    </>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node,
  route: PropTypes.string,
};

export default PageWrapper;
