import React from 'react';
import NoOidc from './NoOidc';
import Oidc from './Oidc';
import OidcWithBroker from './OidcWithBroker';
import { AUTH_BROKER_ENABLED, LOGIN_NONE } from '../../constants/login';
import AuthService from '../../services/AuthService';

function ConfigurableAuthWrapper(props) {
  // default to direct login required
  let AuthComponent = Oidc;
  if (process.env.REACT_APP_AUTH_METHOD === LOGIN_NONE) {
    AuthComponent = NoOidc;
  }
  if (AuthService.getAuthBrokerToggleValue() === AUTH_BROKER_ENABLED) {
    AuthComponent = OidcWithBroker;
  }
  return <AuthComponent/>
};

export default ConfigurableAuthWrapper;