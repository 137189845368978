import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { omit } from 'lodash';


// Sanitize a href to try and prevent XSS
function SafeUrlLink(props) {
  let relValue = props.rel;
  if (!relValue && props.target) {
    relValue = 'noreferrer noopener';
  }
  return (
    <Link
      href={sanitizeUrl(props.href)}
      target={props.target}
      rel={relValue}
      {...omit(props, ['href', 'target', 'rel'])}
    />
  )
}

SafeUrlLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

export default SafeUrlLink;
