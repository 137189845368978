import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { has, isEmpty, includes } from 'lodash';
import { Box, Container, Grid, ListItemButton, Tooltip } from '@mui/material';
import { monitoringContext } from '../context/MonitoringContext';
import MonitorItemStatusBar from '../container/MonitorItemStatusBar';
import MonitorNoticeItems from '../notification/MonitorNoticeItems';
import RestartButton from './RestartButton';
import Stopwatch from '../notification/Stopwatch';
import dismissStudent from '../../utils/dismissStudent';
import reconnectStudent, { getReconnectObject } from '../../utils/reconnectStudent';
import { ACTIONS } from '../../constants/monitorSessions';
import { EXAM_SESSION_STATES } from '../../constants/examSessions';
import { SCREEN_SHARE_TYPES } from '../../constants/mediaStates';
import Pinned from '../../icons/Pinned';
import StopError from '../../icons/StopError';

const styles = {
  container: {
    padding: '0 !important', //override mui media breakpoints styling
  },
  listSpacing: {
    padding: 0.5,
    borderRadius: '5px',
  },
  unitText: {
    fontSize: '0.8rem',
    marginTop: '0.1rem',
  },
  itemText: {
    fontSize: '0.9rem',
  },
  supportingText: {
    fontSize: '0.7rem',
  },
  activeItem: {
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: 'secondary.dark',
    color: 'secondary.contrastText',
    py: 1.5,
    px: 0.5,
  },
  nonActiveItem: {
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: 'primary.contrastText',
    padding: 0.5,
  },
  errorItem: {
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: 'primary.contrastText',
    border: `1px solid`,
    borderColor: 'error.main',
    padding: 0.5,
  },
  offlineItem: {
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: 'primary.contrastText',
    padding: 0.5,
    color: 'grey.600',
  },
  errorText: {
    color: 'error.main',
    paddingRight: 2,
    '& svg': {
      paddingRight: 0.5,
      height: '18px',
      width: '18px',
    },
  },
  alignEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pin: {
    height: '18px',
  },
};

function MonitorListItem(props) {
  const { state, dispatch } = useContext(monitoringContext)
  const { activeConnection } = state;
  const { session } = props;
  const contextName = session.context.name;

  const isActive = activeConnection && activeConnection === session.id;
  const isErrored = has(session, 'connectionDetails.requestError') || !isEmpty(session.connectionDetails.connectionErrors);
  const studentOnline = has(session, 'connectionDetails.studentOnline') && session.connectionDetails.studentOnline;
  const examSubmitted = session.examState === EXAM_SESSION_STATES.submitted;
  const screenStreaming = Boolean(session.studentMedia?.screenshare?.stream);
  const screenType = (screenStreaming && session.screenShareType) || SCREEN_SHARE_TYPES.NONE.mapper;
  const webcamStreaming = Boolean(session.studentMedia?.video?.stream);

  let listDisplayStyle = styles.nonActiveItem;
  if (isActive) {
    listDisplayStyle = styles.activeItem;
  } else if (!studentOnline) {
    listDisplayStyle = styles.offlineItem;
  }
  if (isErrored) {listDisplayStyle = styles.errorItem;}
  
  const [reconnectionStatus, setReconnectionStatus] = useState('completed');
  const reconnectionDetails = useRef({
    slotId: undefined,
    examDetails: undefined
  });

  useEffect(() => {
    reconnectStudent(reconnectionStatus, reconnectionDetails, state, dispatch, setReconnectionStatus);
  }, [dispatch, reconnectionStatus, setReconnectionStatus, state]);

  const reconnectServices = () => {
    reconnectionDetails.current = getReconnectObject(session);
    setReconnectionStatus('removeSession');
  };

  return (
    <Box display="flex" style={{scrollSnapAlign: 'start'}}>
      <ListItemButton
        sx={styles.listSpacing}
        onClick={isErrored
          ? (_e) => reconnectServices()
          : (_e) => dispatch({type: ACTIONS.SET_ACTIVE_CONNECTION, value: session.id})
        }
      >
        <Container sx={styles.container}>
          <Grid container sx={{ ...listDisplayStyle, ...styles.itemText }} className="student-list-item">
            <Grid item xs={1}>
              <Box width="100%" display="block">
                {includes(state.pinnedSessions, session.id) &&
                  <Tooltip title="Student pinned for later">
                    <Box display="flex" alignItems="center"><Pinned sx={styles.pin}/></Box>
                  </Tooltip>
                }
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box pl={examSubmitted ? 0.25 : 0} width="100%" textOverflow="ellipsis" whiteSpace="nowrap" display="block" overflow="hidden" sx={styles.unitText}>
                {contextName.substring(0,contextName.indexOf(' '))}
              </Box>
            </Grid>
            <Grid item xs={isErrored ? 6 : 5}>
              <Box pl={examSubmitted ? 0.75 : 0} width="100%" textOverflow="ellipsis" whiteSpace="nowrap" display="block" overflow="hidden">
                {session.student.fullName}
              </Box>
            </Grid>
            {isErrored ?
              <Grid item xs={3} sx={{ ...styles.alignEnd, ...styles.errorText }}>
                <StopError/><div>Error</div>
              </Grid>
            :
              <Grid item xs={4} sx={{ ...styles.alignEnd, ...styles.supportingText }}>
                { !isEmpty(session.notices) && <Stopwatch notices={session.notices} />}
                <MonitorNoticeItems
                  examSubmitted={examSubmitted}
                  isActive={isActive}
                  notices={session.notices}
                />
              </Grid>
            }

              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <MonitorItemStatusBar
                  isActive={isActive}
                  isErrored={isErrored}
                  online={studentOnline}
                  screenType={screenType}
                  submitted={examSubmitted}
                  webCamStreaming={webcamStreaming}
                />
              </Grid>

          </Grid>
        </Container>
      </ListItemButton>
      {examSubmitted &&
        <RestartButton
          isTextButton={false}
          slotId={session.id}
          gatewayOpen={
            has(session, 'connectionDetails.connectionSetUpComplete')
              ? session.connectionDetails.connectionSetUpComplete
              : false
          }
          restartCallback={examSubmitted ? () => dismissStudent(session.id, dispatch) : reconnectServices}
          restart={!examSubmitted}
        />
      }
    </Box>
  );
}

MonitorListItem.propTypes = {
  session: PropTypes.object.isRequired,
};

export default MonitorListItem;
