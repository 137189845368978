import PropTypes from "prop-types";
import React from "react";
import { AuthConsumer } from '../authContext';
import { NO_ACCESS } from '../constants/login';

function Error(props) {
  const { errorType: propErrorType } = props;
  return (
    <AuthConsumer>
      {( { isErrored, errorType }) => (
        <>
          {(errorType === NO_ACCESS || propErrorType === NO_ACCESS) &&
            <>
              <h2>Sorry, there's an issue with your access to eVigilation</h2>
              <p>Please <a href="https://www.monash.edu/esolutions/contact">contact eSolutions</a> to report the problem.</p>
            </>
          }
          {isErrored && errorType !== NO_ACCESS &&
            <>
              <h2>Sorry, the eVigilation system is currently unavailable</h2>
              <p>
                 Please <a href="https://www.monash.edu/esolutions/contact">contact eSolutions</a> to report the problem.
              </p>
            </>
          }
        </>
      )}
    </AuthConsumer>
  );
}

Error.propTypes = {
  errorType: PropTypes.string,
};

export default Error;
