import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const ScrollableBox = (props) => {
  const maxHeight = props.sx?.maxHeight ? props.sx.maxHeight : '400px';
  return (
    <Box
      sx={{
        ...props.sx,
        maxHeight: maxHeight,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          backgroundColor: 'transparent',
        },
        '&:hover::-webkit-scrollbar': {
          backgroundColor: '#eee',
        },
        '&:hover::-webkit-scrollbar-thumb': {
          backgroundColor: '#ccc',
          borderRadius: '8px',
        },
      }}
    >
      { props.children }
    </Box>
  )
};

ScrollableBox.propTypes = {
  sx: PropTypes.object,
};

export default ScrollableBox;
