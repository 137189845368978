import { isEmpty, sortBy } from 'lodash';
import updateIdVerificationProgressSteps from '../helper/updateProgressStepsByType';
import { ACTIONS, ID_ANALYSIS_STATUS } from '../../../constants/joinSession';
import OnboardService from '../../../services/OnboardService';
import evaluateStepToShow from '../helper/evaluateStepToShow';

const onIdVerificationAnalysisUpdate = (dispatch, getProgressStepsDetails) => {

  const getIdAnalysisStepFromProgressStepDetailsByProgressId = (idAnalysisProgressId) => {
    if (isEmpty(idAnalysisProgressId)) {
      console.error('No valid progress id was found');
      return undefined;
    }

    const progressDetails = getProgressStepsDetails();
    return progressDetails.find(pd => pd.progress.id === idAnalysisProgressId);
  };

  const mapProgressWithIdAnalysisStatus = (flowAssignment, analysisProgressId, status) => {
    return {
      ...flowAssignment,
      onboardSlotProgress: flowAssignment.onboardSlotProgress?.map(
        progress => progress.id === analysisProgressId ? { ...progress, progressStatus: { ...progress.progressStatus, status: status } } : progress),
    };
  };

  const handleIdAnalysisFailure = (receivedMessage) => {
    const slotId = receivedMessage.slotId;
    OnboardService.getExamSessionFlowAssignment(slotId).then(flowAssignment => {
      const progressDetails = getProgressStepsDetails();
      const mappedUpdatedFlowAssignment = mapProgressWithIdAnalysisStatus(flowAssignment, receivedMessage.onboardSlotProgressId, receivedMessage.status);
      // Only update id verification steps as an extra defensive measure just in case 
      // so the student won't get jumped back to a non id analysis step. Only real risk of this occurring 
      // is if the frontend state has the step set as complete, but the backend has not.
      const mappedProgressDetails = sortBy(updateIdVerificationProgressSteps(mappedUpdatedFlowAssignment, progressDetails), ['flowstep.position']);
      const nextStepIndex = evaluateStepToShow(mappedProgressDetails);
      const nextStepActionType = mappedProgressDetails[nextStepIndex]?.flowStep?.onboardStep?.actionType;
      dispatch({ type: ACTIONS.SET_ONBOARD_PROGRESS_STEPS, value: mappedProgressDetails });
      dispatch({ type: ACTIONS.SET_CURRENT_ACTION, value: { actionType: nextStepActionType, currentFlowStepIndex: nextStepIndex } });
    }).catch(err => {
      const idAnalysisStepDetails = getIdAnalysisStepFromProgressStepDetailsByProgressId(receivedMessage.onboardSlotProgressId);
      const idAnalysisStepProgress = idAnalysisStepDetails?.progress;
      console.error('Unable to update progress for id analysis: ', err);
      dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: { ...idAnalysisStepProgress, progressStatus: { ...idAnalysisStepProgress?.progressStatus, status: ID_ANALYSIS_STATUS.ERROR } } });
    });
  };

  const hanldeIdAnalysisSuccess = (receivedMessage) => {
    const idAnalysisStep = getIdAnalysisStepFromProgressStepDetailsByProgressId(receivedMessage.onboardSlotProgressId);
    dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: { ...idAnalysisStep?.progress, complete: true, mustRecomplete: false, progressStatus: { ...idAnalysisStep?.progress?.progressStatus, status: ID_ANALYSIS_STATUS.SUCCESS } } });
  };

  return (receivedMessage) => {
    if (isEmpty(receivedMessage)) {
      console.info('Unable to update Id analysis as no data was received');
      return;
    }

    const { status } = receivedMessage;

    const isValidStatus = ID_ANALYSIS_STATUS[status] !== undefined;
    if (!isValidStatus) {
      console.info(`Received unknown status ${status}. No ID analysis updates will be performed`);
      return;
    }

    if (status === ID_ANALYSIS_STATUS.SUCCESS) {
      // If it was successful, we can safely update the progress without needing to do any other API calls to grab the progress again
      hanldeIdAnalysisSuccess(receivedMessage);
    } else {
      handleIdAnalysisFailure(receivedMessage);
    }
  };
}

export default onIdVerificationAnalysisUpdate;