import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  dismissButton: {
    padding: 0.5,
    my: 0.5,
    mx: 0,
    minWidth: 'auto',
    "& .dismiss": {
      backgroundColor: 'grey.200',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: '50%',
    },
    "&:hover .dismiss": {
      backgroundColor: 'error.main',
      color: 'error.contrastText',
    }
  },
  noticeItem: {
    border: `1px solid`,
    borderColor: 'grey.200',
    borderRadius: '50%',
    height: '18px',
    width: '18px',
    '& svg': {
      maxHeight: '14px',
      maxWidth: '14px',
      padding: 0.25,
    },
  },
  dismissAction: {
    backgroundColor: 'error.main',
    color: 'error.contrastText',
  },
};

function DismissButton(props) {
  const { onClick } = props;

  return (
    <Tooltip
      title="End session"
      placement="right"
      slotProps={{ tooltip: { sx: styles.dismissAction } }}
    >
      <Button variant='text' sx={styles.dismissButton} onClick={onClick}>
        <Box sx={styles.noticeItem}>
          <div className="dismiss"><CloseIcon /></div>
        </Box>
      </Button>
    </Tooltip>
  );
}

DismissButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DismissButton;
