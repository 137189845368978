import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { assign, cloneDeep, has, isEmpty, isMatch } from 'lodash';
import { AuthConsumer, authContext } from '../authContext';
import Header from '../components/content/Header';
import Notice from '../components/notification/Notice';
import ExamDetails from '../components/content/ExamDetails';
import HelpContainer from '../components/container/HelpContainer';
import TermsConditionsPopup from '../components/popup/TermsConditionsPopup';
import StreamsCheck from '../components/popup/StreamsCheck';
import ContactNumbers from '../components/content/ContactNumbers';
import JoinBanner from '../components/content/JoinBanner';
import RecordingIndicator from '../components/notification/RecordingIndicator';
import VideoStream from '../components/audioVisual/VideoStream';
import PrivacyAdvice from '../components/content/PrivacyAdvice';
import BrowserCheckWrapper from '../components/popup/BrowserCheckWrapper';
import ChatContainer from '../components/container/ChatContainer';
import AudioStream from '../components/audioVisual/AudioStream';
import Troubleshooting from '../components/content/Troubleshooting';
import ExamSessionService from '../services/ExamSessionService';
import VideoStreamService from '../services/VideoStreamService';
import GatewayService from '../services/GatewayService';
import AudioStreamService from '../services/AudioStreamService';
import PromiseWithAbort from '../utils/PromiseWithAbort';
import gatewayConfig from '../config/gateway';
import streamSettings from '../config/streamSettings';
import withRouter from '../config/withRouter';
import {
  EXAM_SESSION_DELETION_STATUS,
  EXAM_SESSION_PRIVACY_CONFIRMATION as CONFIRMATION,
  EXAM_SESSION_STATES as EXAM_STATES
} from '../constants/examSessions';
import {
  MEETING_STATE_KEYS,
  MEETING_ENDED_STATES,
} from '../constants/session';
import { STUDENT } from '../constants/chat';
import { CONFERENCE_ERROR_DISPLAY_ORDER } from '../constants/errors';
import { FEATURE_TOGGLES } from '../constants/featureToggles';
import shouldHideChat from '../utils/shouldHideChat';
import { getStudentUser } from "../utils/getExamSlotUser";
import getExamSessionType from '../utils/getExamSessionType';
import { formatErrorForDisplay } from '../utils/formatErrorForDisplay';
import {
  SHARED_MEDIA_STATES as MEDIA_STATE,
  MEDIA_STREAM_CONNECTION_STATES as MEDIA_STREAM_STATE,
} from '../constants/mediaStates';

const styles = {
  pageContainer: {
    minHeight: 'calc(100vh - 96px)',
  },
  bannerContainer: {
    position: 'relative',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.05)',

  },
  blueBox: {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.05)',
  },
  boxShadows: {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.05)',
    border: '1px solid #f2f2f2',
  },
  audioContainerHidden: {
    display: 'none',
  },
  selfVideoContainer: {
    height: '20%',
    right: 0,
    margin: 0,
    position: 'absolute',
  },
};

/**
  * Return an array that represents a default media sharing state with no supervisors
  * sharing anything. This is a simple helper function since we reset this in a couple
  * of places.
  */
const getDefaultSupervisorMediaState = () => {
  return {
    video: {
      stream: null,
      streaming: false,
      activeUser: null,
      participants: {},
    },
    audio: {
      streaming: false,
      active: false,
      muted: false,
      participants: {},
    },
    screenshare: {
      streaming: false,
      participants: {},
    },
  };
}

class JoinSession extends React.Component {

  state = {
    termsConditionsPopupOpen: true,
    examDetails: undefined,
    studentDetails: undefined,
    hasRequestErrored: false,
    connectionErrors: {},
    errorMessage: '',
    conferenceErrors: [],
    meetingState: MEETING_STATE_KEYS.WAITING,
    connectionProps: undefined,
    supervisorMedia: getDefaultSupervisorMediaState(),
    sharedMediaStatus: {
      webcam: MEDIA_STATE.DISABLED,
      audio: MEDIA_STATE.DISABLED,
      desktop: MEDIA_STATE.DISABLED,
    },
    sharedMediaWarnings: {
      webcam: undefined,
      audio: undefined,
      desktop: undefined,
    },
    gatewayConnection: null,
    gatewayConnectionOpen: false,
    videoConnection: MEDIA_STREAM_STATE.CLOSED,
    audioConnection: MEDIA_STREAM_STATE.CLOSED,
    devices: {
      audioInputDevice: null,
      audioOutputDevice: null,
      videoDevice: null,
      videoQuality: streamSettings.defaultWebcamQualityBitrate,
    },
    streamsChecked: false,
    weAreTalking: false,
  }

  controller = new AbortController();
  asyncAbort = new PromiseWithAbort();

  audioStreamService = null;
  videoStreamService = null;

  getExamDetails = async () => {
    const { user } = this.context;
    const slotId = this.props.router?.match?.params?.slotId;

    if (isEmpty(slotId)) {
      // invalid URL with no slot ID available to use.
      return;
    }

    try {
      const examSessionDetails = await ExamSessionService.getExamSession(slotId, this.controller.signal);
      if (examSessionDetails.deletionStatus === EXAM_SESSION_DELETION_STATUS.DELETED.value || examSessionDetails.deletionStatus === EXAM_SESSION_DELETION_STATUS.SCHEDULED.value) {
        throw new Error('Cannot join exam session that is marked for deletion or has already been deleted.')
      }

      const acceptedTerms = examSessionDetails.privacyConfirmation === CONFIRMATION.accepted;
      const examGateOpened = examSessionDetails.examState !== EXAM_STATES.pending;
      const studentDetails = getStudentUser(examSessionDetails);

      this.setState({
        examDetails: examSessionDetails,
        studentDetails: studentDetails,
        termsConditionsPopupOpen: acceptedTerms ? false : true,
        hasRequestErrored: false,
        errorMessage: '',
        meetingState: examGateOpened ? MEETING_STATE_KEYS.ACTIVE : MEETING_STATE_KEYS.WAITING,
      });
      if (acceptedTerms && user.id === studentDetails.id) {
        this.joinConference(studentDetails);
      }
    } catch (error) {
      this.setRequestError('We were unable to get details for that eVigilation session. Please check your link and try again.');
      console.error('Unable to get exam slot details', error);
    }
  }

  openPopup = () => {
    this.setState({ termsConditionsPopupOpen: true });
  };

  closePopup = () => {
    const { user } = this.context;
    const { studentDetails } = this.state;
    this.setState({ termsConditionsPopupOpen: false }, () => {
      if (user.id === studentDetails.id) {
        this.joinConference(studentDetails);
      }
    });
  }

  closeCheck = (err) => {
    const { gatewayConnection, audioConnection } = this.state;
    this.setState({ streamsChecked: true });

    // Start streaming webcam
    this.startSharingVideo('webcam');

    // Flag the microphone is already being shared with the conference
    if (audioConnection === MEDIA_STREAM_STATE.CONNECTED) {
      if (gatewayConfig.autoMuteOnJoin) {
        gatewayConnection.updateMedia('audio', 'muted', false);
      }
      this.changeMediaState('audio', MEDIA_STATE.CONNECTED);
    }

    // If there was an error and the streams check failed, fall out to error page
    if (has(err, 'webcam')) {
      this.addConferenceError('webcam', formatErrorForDisplay(err.webcam, 'webcam', 'videoPreviewFailed'));
    }
  }

  /**
   * Handler for when the browser warning check is passed, either due to the browser being valid
   * or the user selecting to close the warning popup.
   * @param {boolean} isValid
   * @param {object} browserObj
   */
  passBrowserCheck = () => {
    // don't make any api calls until the student has logged in
    this.context.authorised && this.getExamDetails();
  }

  updateDevices = (devices) => {
    const currentDevices = cloneDeep(this.state.devices);
    this.setState({ devices });
    if (currentDevices.audioInputDevice !== null && currentDevices.audioInputDevice !== devices.audioInputDevice) {
      // Change audio input
      this.setState({ audioConnection: MEDIA_STREAM_STATE.CONNECTING }, () => {
        this.asyncAbort.wrap(
          this.audioStreamService.changeInputDevice(devices.audioInputDevice))
          .then(() => {
            this.setState({ audioConnection: MEDIA_STREAM_STATE.CONNECTED });
            this.clearConferenceError('audio');
          })
          .catch((err) => {
            this.addConferenceError('audio', formatErrorForDisplay(err, 'audio', 'setInputDevice'));
            this.logToGateway('student_audio_change_input', { error: err, source: 'updateDevices' });
            if (!this.controller.signal.aborted) {
              this.setState({ audioConnection: MEDIA_STREAM_STATE.CLOSED });
            }
          });
      });
    }
    if (currentDevices.audioOutputDevice !== null && currentDevices.audioOutputDevice !== devices.audioOutputDevice) {
      // Change audio output
      try {
        this.audioStreamService.changeOutputDevice(devices.audioOutputDevice ? devices.audioOutputDevice : 'default');
      } catch (err) {
        console.error(`Error while changing audio output device`, { err });
        // We don't actually send this to an error state - will retain existing functionality for now
        // this.addMediaError(formatErrorForDisplay(err, 'audio', 'setOutputDevice'));
        this.logToGateway('student_audio_change_output', { error: err, source: 'updateDevices' });
      }
    }
  }

  joinConference = async (studentDetails) => {
    const { slotId } = this.props.router?.match?.params;
    try {
      // Obtain meeting details via API calls
      const meetingDetails = await ExamSessionService.joinMeeting(
        slotId,
        this.controller.signal
      );

      // Assuming we have successfully joined, open websocket session.
      const gatewayConnection = new GatewayService();
      // Tell the student if we are unexpectedly disconnected
      gatewayConnection.registerDisconnectionHandler(this.onGatewayDisconnected);
      // Remove a gateway error when we are successfully reconnected.
      gatewayConnection.registerConnectionHandler(this.onGatewayReconnected);
      // Show or remove media elements from other users
      gatewayConnection.registerMediaChangeEvent('video', this.onRemoteVideoChange);
      gatewayConnection.registerMediaChangeEvent('audio', this.onRemoteAudioChange);
      // Change the banner when exam state is updated
      gatewayConnection.registerMeetingChangeEvent('update', this.onMeetingUpdate);
      // Shut everything down when the meeting is closed by supervisor
      gatewayConnection.registerMeetingChangeEvent('end', this.onMeetingEnd);
      // Shut everything down when the meeting is terminated
      gatewayConnection.registerMeetingChangeEvent('terminated', this.onMeetingTerminated);
      // May not need this on student side - do something when a user joins or leaves
      gatewayConnection.registerParticipantChangeEvent('add',
        (userId, userData, reason) => { this.onUserChange('add', userId, userData, reason) }
      );
      gatewayConnection.registerParticipantChangeEvent('remove',
        (userId, userData, reason) => { this.onUserChange('remove', userId, userData, reason) }
      );

      // Now actually try to authenticate
      this.asyncAbort.wrap(gatewayConnection.connect(studentDetails.id, slotId, meetingDetails))
        .then(() => {
          // We have authenticated successfully with the websocket server
          this.videoStreamService = new VideoStreamService();
          this.audioStreamService = new AudioStreamService();
          this.setState({
            connectionProps: meetingDetails,
            gatewayConnection: gatewayConnection,
            audioConnection: MEDIA_STREAM_STATE.CONNECTING,
            videoConnection: MEDIA_STREAM_STATE.CONNECTING,
          }, () => {
            this.initAudio(true);
            this.initVideo();
          });
        })
        .catch((error) => {
          //Could not authenticate with gateway server.
          gatewayConnection.unregisterHandler('all');
          this.addConferenceError("connection", formatErrorForDisplay(error, "connection", "gatewayAuthenticationFail"));
          console.error('Could not connect to gateway', { error });
          this.setState({
            sharedMediaStatus: {
              webcam: MEDIA_STATE.DISABLED,
              audio: MEDIA_STATE.DISABLED,
              desktop: MEDIA_STATE.DISABLED,
            },
          });
        });
    } catch (error) {
      // Could not obtain meeting details.
      if (!this.controller.signal.aborted) {
        this.addConferenceError("connection", formatErrorForDisplay(error, "connection", "joinMeetingFail"));
        console.error('Could not join eVigilation session', { error });
        this.setState({
          sharedMediaStatus: {
            webcam: MEDIA_STATE.DISABLED,
            audio: MEDIA_STATE.DISABLED,
            desktop: MEDIA_STATE.DISABLED,
          },
        });
      }
    }
  }

  /**
   * Wrapper for displaying a non-recoverable request error to the user on a failure, that checks whether
   * the component has been unmounted before updating the state.
   * @param {string} message The error message to display.
   */
  setRequestError = (message) => {
    if (!this.controller.signal.aborted) {
      this.setState({
        hasRequestErrored: true,
        errorMessage: message,
      });
    }
  }

  /**
   * Wrapper for displaying a recoverable connection error to the user on a failure, that checks whether
   * the component has been unmounted before updating the state. This error can be removed when the connection
   * is restored.
   * @param {string} connectionType The type of connection that failed.
   * @param {string} message The error message to display.
   * @param {boolean} clear True if the error has been resolved.
   */
  setConnectionError = (connectionType, message, clear = false) => {
    if (!this.controller.signal.aborted) {
      let newConnectErrors = cloneDeep(this.state.connectionErrors);
      if (!clear) {
        newConnectErrors[connectionType] = message;
      } else if (newConnectErrors[connectionType]) {
        delete newConnectErrors[connectionType];
      }
      this.setState({
        connectionErrors: newConnectErrors
      });
    }
  }

  /**
   * When the gateway connection drops out, display a message to the user and handle any errors.
   * @param {string} reason The reason message for the disconnection.
   */
  onGatewayDisconnected = (reason) => {
    this.addConferenceError('connection', formatErrorForDisplay(reason, 'connection', 'gatewayDisconnection'));
    console.error(`Gateway disconnected: ${reason}`);
    this.setState({ gatewayConnectionOpen: false });
  }

  /**
   * When the gateway connection returns after a dropout, clear error message.
   */
  onGatewayReconnected = () => {
    this.clearConferenceError('connection');
    console.info('Gateway connected successfully.');
    this.setState({ gatewayConnectionOpen: true });
  }

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their video streams, either starting a new stream or stopping an existing one.
   * @param {string} type Type of change, either 'start' or 'stop'.
   * @param {string} userId The eVig user ID who is sharing their video stream.
   * @param {object} updatedParticipant The updated user and media data for this change from the gateway.
   */
  onRemoteVideoChange = (type, userId, updatedParticipant) => {
    let newMedia = cloneDeep(this.state.supervisorMedia);
    const { videoConnection } = this.state;
    console.info(`Received video ${type} request for ${userId}`);
    if (type === 'start') {
      // We always take the latest supervisor to start streaming to us
      newMedia.video.stream = updatedParticipant.video.stream;
      if (videoConnection === MEDIA_STREAM_STATE.CONNECTED) {
        newMedia.video.streaming = true;
      }
      newMedia.video.activeUser = userId;
      newMedia.video.participants[userId] = {
        ...updatedParticipant.video,
        userName: updatedParticipant.userName,
        userType: updatedParticipant.userType,
      };
    } else {
      // This is a video stop message
      if (userId === newMedia.video.activeUser) {
        // Only if the supervisor who is currently streaming to us stops their video do we
        // unset the display. If another supervisor stops, we don't really care.
        newMedia.video.streaming = false;
        newMedia.video.stream = null;
        newMedia.video.activeUser = null;

        // Check if there is another active supervisor video (shouldn't really happen)
        const fallbackUsers = Object.keys(newMedia.video.participants)
          .filter(activeUser => activeUser !== userId);

        if (fallbackUsers.length) {
          // Go back to other active supervisor stream
          newMedia.video.streaming = true;
          newMedia.video.stream = newMedia.video.participants[fallbackUsers[0]].stream;
          newMedia.video.activeUser = fallbackUsers[0];
        }
      }

      // Remove the participant who stopped streaming from the video participant list
      if (newMedia.video.participants.hasOwnProperty(userId)) {
        delete newMedia.video.participants[userId];
      }
    }
    this.setState({
      supervisorMedia: newMedia,
    });
  }

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their audio state in the call.
   * @param {string} type Type of change, one of 'start', 'stop', 'mute', 'unmute', 'active' or 'inactive'.
   * @param {string} userId The eVig user ID who is sending audio to the meeting.
   * @param {object} updatedParticipant The updated user and media data for this change from the gateway.
   */
  onRemoteAudioChange = (type, userId, updatedParticipant) => {
    let newMedia = cloneDeep(this.state.supervisorMedia);
    console.info(`Received audio ${type} request for ${userId}`);

    switch (type) {
      case 'start':
        newMedia.audio.streaming = true;
        break;
      case 'stop':
        newMedia.audio.streaming = false;
        break;
      case 'mute':
        newMedia.audio.muted = true;
        break;
      case 'unmute':
        newMedia.audio.muted = false;
        break;
      case 'active':
        newMedia.audio.active = true;
        break;
      case 'inactive':
        newMedia.audio.inactive = false;
        break;
      default:
        return;
    }

    // Update the participant list with latest audio details
    if (type === 'stop') {
      if (newMedia.audio.participants.hasOwnProperty(userId)) {
        delete newMedia.audio.participants[userId];
      }
    } else {
      newMedia.audio.participants[userId] = {
        ...updatedParticipant.audio,
        userName: updatedParticipant.userName,
        userType: updatedParticipant.userType,
      };
    }

    this.setState({ supervisorMedia: newMedia });
  }

  /**
   * Handle an event received that indicates the meeting state has been updated.
   * @param {object} updatedMeetingData The updated meeting information received from the gateway.
   * @param {string} reason Any reason for the change
   */
  onMeetingUpdate = (updatedMeetingData, _reason) => {
    if (updatedMeetingData.examState === EXAM_STATES.canStart) {
      // The second banner should be shown
      this.setState({ meetingState: MEETING_STATE_KEYS.ACTIVE });
    } else if (updatedMeetingData.examState === EXAM_STATES.submitted) {
      this.setState({ meetingState: MEETING_STATE_KEYS.SUBMITTED });
    } else {
      // do nothing
    }
  }

  /**
   * Handle an event received from the gateway indicating the meeting has been terminated
   * @param {object} updatedMeetingData The updated meeting information received from the gateway.
   * @param {string} reason Any reason for the meeting shutdown.
   */
  onMeetingEnd = (_updatedMeetingData, _reason) => {
    const { videoStreamService, audioStreamService } = this;
    const { gatewayConnection } = this.state;
    videoStreamService.unregisterDeviceHandlers('all');
    videoStreamService.close('video');
    videoStreamService.close('screenshare');
    audioStreamService.unregisterHandlers();
    audioStreamService.exitAudio();
    gatewayConnection.unregisterHandler('all');
    gatewayConnection.close();
    this.clearConferenceError();
    this.setState({
      meetingState: MEETING_STATE_KEYS.FINISHED,
      supervisorMedia: getDefaultSupervisorMediaState(),
      sharedMediaStatus: {
        webcam: MEDIA_STATE.DISABLED,
        audio: MEDIA_STATE.DISABLED,
        desktop: MEDIA_STATE.DISABLED,
      },
      gatewayConnection: null,
    });
  }

  /**
   * Handle an event received from the gateway indicating the meeting has been terminated
   * @param {object} updatedMeetingData The updated meeting information received from the gateway.
   * @param {string} reason Any reason for the meeting shutdown.
   */
  onMeetingTerminated = (_updatedMeetingData, _reason) => {
    const { videoStreamService, audioStreamService } = this;
    const { gatewayConnection } = this.state;
    videoStreamService.unregisterDeviceHandlers('all');
    videoStreamService.close('video');
    videoStreamService.close('screenshare');
    audioStreamService.unregisterHandlers();
    audioStreamService.exitAudio();
    gatewayConnection.unregisterHandler('all');
    gatewayConnection.close();
    this.clearConferenceError();
    this.setState({
      meetingState: MEETING_STATE_KEYS.TERMINATED,
      supervisorMedia: getDefaultSupervisorMediaState(),
      sharedMediaStatus: {
        webcam: MEDIA_STATE.DISABLED,
        audio: MEDIA_STATE.DISABLED,
        desktop: MEDIA_STATE.DISABLED,
      },
      gatewayConnection: null,
    });
  }

  /**
   * Handle an event received that indicates a remote participant either joined or left.
   * @param {string} type Type of change, either 'add' or 'remove'.
   * @param {string} userId The eVig user ID who joined.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   * @param {string} reason The reason for this change
   */
  onUserChange = (type, userId, userChangeData, reason) => {
    console.info(`${type} meeting participant: ${userId} (${reason}) ${userChangeData ? userChangeData.userType + " " + userChangeData.userName : ""}`);
  }

  /**
   * Set flag so that video stream management components will be rendered, initialising shared stream and sending
   * either desktop or webcam video to the media server.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   */
  startSharingVideo = (mediaType) => {
    const { sharedMediaStatus } = this.state;

    const doShare = () => {
      // We set the state to 'connecting' to allow the element to render, then when video negotiation has completed
      // the callback function will update the status to 'on'.
      this.changeMediaState(mediaType, MEDIA_STATE.CONNECTING);
    };

    // Check if the current status of the stream is active, in which case we need to stop sharing first
    if (sharedMediaStatus[mediaType] === MEDIA_STATE.CONNECTED) {
      this.changeMediaState(mediaType, MEDIA_STATE.DISCONNECTED, doShare);
    } else {
      doShare();
    }
  }

  /**
   * Set flag so that video stream management components will be unmounted, stopping sharing of video to server.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   */
  stopSharingVideo = (mediaType) => {
    this.changeMediaState(mediaType, MEDIA_STATE.DISCONNECTED);
  }

  /**
   * Callback function for the video management component to handle any page changes when video has started sharing
   * successfully.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   */
  onVideoShare = (mediaType) => {
    const { connectionProps, gatewayConnection, sharedMediaStatus } = this.state;
    const { userId } = connectionProps;
    let streamId = mediaType === 'desktop' ? 'desktop' : userId;

    this.changeMediaState(mediaType, MEDIA_STATE.CONNECTED);
    this.clearConferenceError(mediaType);
    if (gatewayConnection) {
      gatewayConnection.shareMedia(mediaType, streamId);
    }

    if (mediaType === 'webcam' && sharedMediaStatus.desktop === MEDIA_STATE.DISABLED) {
      // If the webcam was successfully shared, proceed to share desktop
      this.startSharingVideo('desktop');
    }
  }

  /**
   * Callback function for the video management component to handle any page changes when video has stopped sharing.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   */
  onVideoUnshare = (mediaType) => {
    const { connectionProps, gatewayConnection, sharedMediaWarnings } = this.state;
    const { userId } = connectionProps;
    let streamId = mediaType === 'desktop' ? 'desktop' : userId;

    this.changeMediaState(mediaType, MEDIA_STATE.DISCONNECTED);
    if (gatewayConnection) {
      gatewayConnection.unshareMedia(mediaType, streamId);
    }

    // Clear any warnings from the previous connection attempt if we stopped sharing
    if (!isEmpty(sharedMediaWarnings[mediaType])) {
      let updatedMediaWarnings = { ...sharedMediaWarnings };
      updatedMediaWarnings[mediaType] = undefined;
      this.setState({ sharedMediaWarnings: updatedMediaWarnings });
    }
  }

  /**
   * Callback function for whenever the video management component encounters an error sharing media.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   * @param {string} err Error message encountered
   * @param {boolean} disconnected True if the stream was disconnected entirely
   * @param {string} source The source code for what type of error this represents
   */
  onVideoError = (mediaType, err, disconnected, source, features) => {
    const { connectionProps, gatewayConnection, sharedMediaWarnings } = this.state;
    const { userId } = connectionProps;
    let streamId = mediaType === 'desktop' ? 'desktop' : userId;

    if (disconnected) {
      this.changeMediaState(mediaType, MEDIA_STATE.DISCONNECTED);
      if (gatewayConnection) {
        gatewayConnection.unshareMedia(mediaType, streamId);
      }

      this.addConferenceError(mediaType, formatErrorForDisplay(err, mediaType, source));

      // Clear any warnings from the previous connection attempt, if we were disconnected
      if (!isEmpty(sharedMediaWarnings[mediaType])) {
        let updatedMediaWarnings = { ...sharedMediaWarnings };
        updatedMediaWarnings[mediaType] = undefined;
        this.setState({ sharedMediaWarnings: updatedMediaWarnings });
      }
    } else {
      // Special check for partial screenshare - if a warning message is configured, then
      // pass this through to the button components
      if (mediaType === 'desktop' && source === 'partialScreenshare'
        && features[FEATURE_TOGGLES.WARN_PARTIAL_SCREENSHARE]) {
        let updatedMediaWarnings = { ...sharedMediaWarnings };
        updatedMediaWarnings['desktop'] = "Please check that you have shared your entire desktop";
        this.setState({ sharedMediaWarnings: updatedMediaWarnings });
      }
    }
    this.logToGateway(`student_${mediaType}_share`, { error: err, source: 'registeredDeviceHandler', stream: streamId });
    console.error(`Error while streaming outgoing ${mediaType} video.`, { err });
  }

  initAudio = async (echoTest) => {
    const { connectionProps, devices } = this.state;
    let sharedMediaStatus = cloneDeep(this.state.sharedMediaStatus);
    this.asyncAbort.wrap(
      this.audioStreamService.open(connectionProps, this.audioRef, devices.inputAudioDevice, devices.inputOutputDevice)
    )
      .then(() => {
        this.asyncAbort.wrap(
          this.audioStreamService.start(false, echoTest ? 'echo' : null)
        )
          .then(() => {
            this.changeMediaState('audio', MEDIA_STATE.CONNECTED);
            this.setState({ audioConnection: MEDIA_STREAM_STATE.CONNECTED });
            this.clearConferenceError('audio');
          })
          .catch((err) => {
            this.addConferenceError('audio', formatErrorForDisplay(err, 'audio', 'audioStart'));
            this.logToGateway(`student_audio_start`, { error: err, source: 'initAudio', devices });
            if (!this.controller.signal.aborted) {
              sharedMediaStatus.audio = MEDIA_STATE.DISABLED;
              this.setState({ audioConnection: MEDIA_STREAM_STATE.CLOSED, sharedMediaStatus });
            }
            console.error(`Unable to join audio VOIP conference`, { err });
          });
      })
      .catch(err => {
        this.addConferenceError('audio', formatErrorForDisplay(err, 'audio', 'audioOpen'));
        this.logToGateway(`student_audio_open`, { error: err, source: 'initAudio', devices });
        if (!this.controller.signal.aborted) {
          sharedMediaStatus.audio = MEDIA_STATE.DISABLED;
          this.setState({ audioConnection: MEDIA_STREAM_STATE.CLOSED, sharedMediaStatus });
        }
        console.error(`Unable to open audio connection`, { err });
      });
  }

  initVideo = () => {
    const { connectionProps, sharedMediaStatus } = this.state;
    this.asyncAbort.wrap(
      this.videoStreamService.open(connectionProps, 'video')
    )
      .then(() => this.videoStreamService.open(connectionProps, 'screenshare'))
      .then(() => {
        this.setState({ videoConnection: MEDIA_STREAM_STATE.CONNECTED }, () => {
          // If we already were notified about available streams, connect them
          if (this.state.supervisorMedia.video.stream !== null) {
            let updatedMedia = cloneDeep(this.state.supervisorMedia);
            updatedMedia.video.streaming = true;
            this.setState({ supervisorMedia: updatedMedia });
          }
          this.clearConferenceError('webcam');
          this.clearConferenceError('screenshare');
        });
      })
      .catch((err) => {
        console.error(`Unable to open video connection`, err);
        this.addConferenceError('webcam', formatErrorForDisplay(err, 'webcam', 'streamOpen'));
        //this.setConnectionError('video', `There was a problem connecting video for this session. Please close the page and try again.`);
        this.logToGateway(`student_video_open`, { error: err, source: 'initVideo', connectionProps });
        if (!this.controller.signal.aborted) {
          let updatedMediaStatus = cloneDeep(sharedMediaStatus);
          updatedMediaStatus.video = MEDIA_STATE.DISABLED;
          this.setState({
            videoConnection: MEDIA_STREAM_STATE.CLOSED,
            sharedMediaStatus: updatedMediaStatus
          });
        }
      });
  }

  endEchoTest = () => {
    if (this.state.audioConnection !== MEDIA_STREAM_STATE.CLOSED) {
      this.audioStreamService.transferToConference();
    } else {
      this.addConferenceError('audio', formatErrorForDisplay('SIP_ECHOTEST_TRANSFER_FAIL', 'audio', 'transferToConference'));
      this.logToGateway('student_audio_transfer', { error: 'Audio connection not open for transfer from echo test', source: 'endEchoTest' });
    }
  }

  changeMediaState = (mediaType, newState, callbackFunc) => {
    this.setState(state => {
      state.sharedMediaStatus[mediaType] = newState;
      return state;
    }, callbackFunc);
  }

  handleRaiseHand = (isRaiseHandAction) => {
    const { gatewayConnection } = this.state;
    if (gatewayConnection === null) {
      return;
    }
    gatewayConnection.onRaiseHand(isRaiseHandAction);
  }

  handleTalking = (isTalking) => {
    const { weAreTalking, gatewayConnection } = this.state;
    if (gatewayConnection === null) {
      return;
    }
    if (isTalking && !weAreTalking) {
      this.setState({ weAreTalking: true });
      gatewayConnection.updateMedia('audio', 'active', true);
    } else if (!isTalking && weAreTalking) {
      this.setState({ weAreTalking: false });
      gatewayConnection.updateMedia('audio', 'active', false);
    }
  }

  getUserError() {
    const { hasRequestErrored, errorMessage, connectionErrors } = this.state;
    if (hasRequestErrored) {
      return errorMessage;
    }
    if (connectionErrors) {
      return Object.values(connectionErrors).join(' ');
    }
    return null;
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (!prevProps.authorised && this.props.authorised) {
      this.passBrowserCheck();
    }
  }

  componentWillUnmount() {
    const { gatewayConnection } = this.state;
    this.asyncAbort.abort();
    this.controller.abort();
    if (gatewayConnection) {
      gatewayConnection.leave('Student leaving page');
    }
  }

  /**
   * Log an information message to the gateway for tracking purposes.
   * @param {string} type The code what type of event is being logged
   * @param {object} details An object containing any error message or further details
   */
  logToGateway(type, details) {
    const { gatewayConnection } = this.state;
    if (gatewayConnection) {
      assign(details, {
        userId: this.context.user.id,
        slotId: this.state.examDetails.id,
      });
      gatewayConnection.logInfo(type, details);
    }
  }

  /**
   * Add a new media/conference error to the tracking list
   * @param {string} category The type of conference error which is active (audio, webcam, screenshare)
   * @param {object} errorObject
   * @param {string} errorObject.errorKey An obscured numbered error key (for hiding details from user)
   * @param {string} errorObject.errorCode A unique error code to display for tracking the problem
   * @param {object} errorObject.details Any error details to be captured (not currently displayed)
   */
  addConferenceError = (category, errorObject) => {
    let updatedConferenceErrors = [...this.state.conferenceErrors];
    updatedConferenceErrors.push({
      category,
      errorCode: errorObject.errorCode,
      errorKey: errorObject.errorKey,
      details: errorObject.details,
    });

    this.setState({ conferenceErrors: updatedConferenceErrors });
  }

  /**
   * Remove one or more media/conference errors which are no longer applicable
   * @param {string} category Optional category of error to clear (if blank, all are cleared)
   * @param {string} errorCode Optional specific error code to clear (if empty, all matching the category are cleared)
   */
  clearConferenceError = (category, errorCode) => {
    let updatedConferenceErrors = [...this.state.conferenceErrors];

    updatedConferenceErrors = updatedConferenceErrors
      .filter(e => e.category !== category && (!errorCode || errorCode !== e.errorCode));

    this.setState({ conferenceErrors: updatedConferenceErrors });
  }

  /**
   * Select the next relevant media/conference error to display, or nothing if no errors exist
   * @returns {object} The media/conference error of highest interest
   */
  getConferenceError = () => {
    const { conferenceErrors } = this.state;

    let selectedError = undefined;

    if (!isEmpty(conferenceErrors)) {
      // Select the media error based on defined sequence
      let orderedErrors = CONFERENCE_ERROR_DISPLAY_ORDER
        .map(matchingRule => conferenceErrors.find(e => isMatch(e, matchingRule)))
        .filter(e => e);
      selectedError = orderedErrors.length ? orderedErrors[0] : conferenceErrors[0];
    }

    return selectedError;
  }

  render() {
    const match = this.props.router?.match;
    const {
      examDetails,
      studentDetails,
      termsConditionsPopupOpen,
      meetingState,
      supervisorMedia,
      sharedMediaStatus,
      sharedMediaWarnings,
      streamsChecked,
      audioConnection,
      gatewayConnection,
      connectionProps,
      devices,
      gatewayConnectionOpen,
    } = this.state;
    const canDisplaySession = has(match, 'params.slotId') && !isEmpty(match.params.slotId);
    const errorToDisplay = this.getUserError();
    const showChatOnlyWithOtherParticipants = shouldHideChat(examDetails);
    const examType = has(examDetails, 'humanSupervised') && has(examDetails, 'aiType') ?
      getExamSessionType(examDetails.humanSupervised, examDetails.aiType) : '';
    const conferenceError = this.getConferenceError();
    const studentPage = (features) => (
      <>
        {canDisplaySession && examDetails &&
          <>
            <Box display="flex" flexDirection="column" sx={styles.pageContainer} ml={3} mr={3} mt={1}>
              <Grid container mt={2} columnSpacing={2}>
                <Grid item sm={6} md={8} xl={9} height="calc(100vh - 112px)" sx={styles.bannerContainer}>
                  <Box bgcolor="common.white" height="100%" display="flex" flexDirection="column">
                    <Box sx={styles.audioContainerHidden}>
                      {this.audioStreamService && <AudioStream
                        streamService={this.audioStreamService}
                        connectionStatus={audioConnection}
                        onAudioStarted={() => { this.changeMediaState('audio', MEDIA_STATE.CONNECTED) }}
                        onAudioStopped={() => { this.changeMediaState('audio', MEDIA_STATE.DISCONNECTED) }}
                        getRef={(ref) => { this.audioRef = ref }}
                        showVolumeControls={false}
                        showMicrophoneMeter={false}
                        onTalking={this.handleTalking}
                      />}
                    </Box>
                    {!MEETING_ENDED_STATES.includes(meetingState) && Object.values(sharedMediaStatus).includes(MEDIA_STATE.CONNECTED)
                      && <RecordingIndicator />}
                    {!supervisorMedia.video.streaming &&
                      ((!conferenceError || MEETING_ENDED_STATES.includes(meetingState))
                        ? <JoinBanner
                          meetingState={
                            (examDetails.examState === 'SUBMITTED' && meetingState === MEETING_STATE_KEYS.ACTIVE)
                              ? MEETING_STATE_KEYS.SUBMITTED
                              : meetingState
                          }
                          examType={examType}
                          onboardingType={examDetails.onboardingType}
                          humanSupervised={examDetails.humanSupervised}
                          streamsChecked={streamsChecked && sharedMediaStatus['desktop'] === MEDIA_STATE.CONNECTED}
                        />
                        : <Troubleshooting
                          category={conferenceError.category}
                          errorCode={features[FEATURE_TOGGLES.HIDE_DETAILED_ERROR_CODES] ? conferenceError.errorKey : conferenceError.errorCode}
                          gatewayConnectionOpen={gatewayConnectionOpen}
                          startSharingVideo={this.startSharingVideo}
                          streamStatus={sharedMediaStatus}
                          streamWarnings={sharedMediaWarnings}
                        />
                      )
                    }
                    {supervisorMedia.video.streaming && <VideoStream
                      streamService={this.videoStreamService}
                      streamId={supervisorMedia.video.stream}
                      onVideoError={(err, _disconnected) => {
                        this.logToGateway('student_webcam_view', { error: err, source: 'mainVideo', stream: supervisorMedia.video.stream })
                      }}
                      isLocal={false}
                    />}
                    <Box bgcolor="#f5f6f6" m={1} p={1}>
                      <PrivacyAdvice
                        examLandingPage={examDetails.examLandingPage}
                        streamStatus={sharedMediaStatus}
                        streamWarnings={sharedMediaWarnings}
                        startSharingVideo={this.startSharingVideo}
                        startSharingAudio={this.initAudio}
                      />
                    </Box>
                    {(sharedMediaStatus.webcam === MEDIA_STATE.CONNECTED || sharedMediaStatus.webcam === MEDIA_STATE.CONNECTING)
                      && <Box sx={styles.selfVideoContainer}>
                        <VideoStream
                          streamService={this.videoStreamService}
                          streamId={connectionProps.userId}
                          isLocal={true}
                          render={true}
                          mirror={true}
                          onVideoStarted={() => this.onVideoShare('webcam')}
                          onVideoStopped={() => this.onVideoUnshare('webcam')}
                          onVideoError={(err, disconnected, source) => this.onVideoError('webcam', err, disconnected, source, features)}
                          videoConstraints={{
                            video: { deviceId: devices.videoDevice },
                            audio: false,
                          }}
                        />
                      </Box>}
                    {(sharedMediaStatus.desktop === MEDIA_STATE.CONNECTED || sharedMediaStatus.desktop === MEDIA_STATE.CONNECTING) &&
                      <VideoStream
                        streamService={this.videoStreamService}
                        streamId="desktop"
                        isLocal={true}
                        render={false}
                        onVideoStarted={() => this.onVideoShare('desktop')}
                        onVideoStopped={() => this.onVideoUnshare('desktop')}
                        onVideoError={(err, disconnected, source) => this.onVideoError('desktop', err, disconnected, source, features)}
                      />}
                  </Box>
                </Grid>
                <Grid item sm={6} md={4} xl={3} position="relative">
                  <ChatContainer
                    gatewayService={gatewayConnection}
                    displayName={has(this.context, 'user.fullName') ? this.context.user.fullName : 'Student'}
                    userId={has(this.context, 'user.id') ? this.context.user.id : ''}
                    authorType={STUDENT.authorType}
                    showChatOnlyWithParticipant={showChatOnlyWithOtherParticipants}
                    renderContainer={true}
                  >
                    <HelpContainer
                      examType={examDetails.examType}
                      gatewayConnectionOpen={gatewayConnectionOpen}
                      humanSupervised={examDetails.humanSupervised}
                      onRaiseHand={gatewayConnection ? this.handleRaiseHand : undefined}
                    />
                  </ChatContainer>
                </Grid>
              </Grid>
            </Box>
            <TermsConditionsPopup
              open={termsConditionsPopupOpen}
              onClose={this.closePopup}
              slotId={match.params.slotId}
            />
            <StreamsCheck
              open={(!errorToDisplay && !conferenceError && !termsConditionsPopupOpen && !streamsChecked && !!gatewayConnection)}
              onClose={this.closeCheck}
              onConfirmAudio={this.endEchoTest}
              updateDevices={this.updateDevices}
              devices={this.state.devices}
              audioConnectionState={audioConnection}
              checkScreenshare={true}
            />
          </>
        }
      </>
    )

    const unauthorised = (
      <Box margin={4}>
        <Notice noticeType="error">
          <Typography variant="h3" component="h2">Sorry, you are not authorised to join this session.</Typography>
          <Typography variant="body1">Please try joining from your exam page again and if you still see this message, please contact support</Typography>
          <ContactNumbers examSupport={false} />
        </Notice>
      </Box>
    )

    return (
      <BrowserCheckWrapper onClose={this.passBrowserCheck}>
        <AuthConsumer>
          {({ user, features }) => {
            const readyToRender = !!(examDetails && studentDetails && user);
            const authorisedStudentInSlot = readyToRender && user.id === studentDetails.id;
            return (
              <>
                {!canDisplaySession &&
                  <Box margin={4}>
                    <Notice noticeType="error">Sorry, the URL is incorrect, please try joining from your exam page again</Notice>
                  </Box>
                }
                {authorisedStudentInSlot &&
                  <Header
                    title={<ExamDetails examDetails={examDetails} studentDetails={studentDetails} slotId={match.params.slotId} studentView={true} />}
                    displayMenu={false}
                    studentView={true}
                  />
                }
                {errorToDisplay &&
                  <Box margin={4}>
                    <Notice noticeType="error">{errorToDisplay}</Notice>
                  </Box>
                }
                {readyToRender && (authorisedStudentInSlot ? studentPage(features) : unauthorised)}
              </>
            );
          }}
        </AuthConsumer>
      </BrowserCheckWrapper>
    );
  }
}

export default withRouter(JoinSession);
JoinSession.contextType = authContext;
