import React, { Component } from 'react';
import { AuthProvider } from '../../authContext';
import withRouter from '../../config/withRouter';
import { has, isEmpty, omit } from 'lodash';
import UserService from '../../services/UserService';
import getAppOptions from '../../utils/getAppOptions';
import { getCapabilityContextAccessList } from '../../utils/getAccessDetailsHelper';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';
import { NO_ACCESS } from '../../constants/login';
import Routing from '../../utils/Routing';

// Can add more attributes if required to resemble the object returned from okta
const getNoOidcUserInfo = {
  [process.env.REACT_APP_AUTHCONFIG_USER_ATTRIBUTE]: process.env.REACT_APP_DEFAULT_NO_OIDC_USER
}

async function checkUser(getAssignments = true) {
  if (isEmpty(this.state.userInfo)) {
    const userInfo = getNoOidcUserInfo;
    const evigUser = await getEvigUser(userInfo, getAssignments);
    const appOptions = await getAppOptions();

    if (evigUser === null) {
      this.setState({
        authorised: true,
        userInfo: userInfo,
        isPending: false,
        isErrored: true,
        errorType: NO_ACCESS,
      });
    } else {
      let access = {};
      if (getAssignments) {
        access = getCapabilityContextAccessList(evigUser);
      }
      const authorised = !isEmpty(access) || (appOptions?.features[FEATURE_TOGGLES.JITA] && evigUser.shiftStaff) || !getAssignments;

      this.setState({
        authorised: authorised,
        userInfo: userInfo,
        isPending: false,
        user: omit(evigUser, 'roleAssignments'),
        capabilityContextAccess: access,
        features: appOptions?.features,
        theme: appOptions?.themeOverrides,
        contentOverrides: appOptions?.contentOverrides,
        configOptions: appOptions?.config,
        errorType: authorised ? undefined : NO_ACCESS
      });
    }
  }
}

async function getEvigUser(user, withAssignments = true) {
  try {
    if (user && has(user, process.env.REACT_APP_AUTHCONFIG_USER_ATTRIBUTE)) {
      const getUserFunction = withAssignments
        ? UserService.getUserWithRoleAssignmentsByUsername
        : UserService.getUserByUsername;
      return await getUserFunction(user[process.env.REACT_APP_AUTHCONFIG_USER_ATTRIBUTE]);
    }
    return null;
  } catch (error) {
    return null;
  }
}

class NoAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorised: false,
      userInfo: null,
      user: undefined,
      capabilityContextAccess: {},
      isPending: true,
      isErrored: false,
      errorType: undefined,
      features: {},
      theme: undefined,
      contentOverrides: undefined,
      configOptions: {},
      getAccessToken: () => { return undefined }
    }
    this.checkUser = checkUser.bind(this);
  }

  async componentDidMount() {
    this.checkUser(this.doRoleAssignmentFetch());
  }

  async componentDidUpdate() {
    this.checkUser(this.doRoleAssignmentFetch());
  }

  doRoleAssignmentFetch() {
    // Determine if only basic user information should be retrieved rather than
    // full role assignments tree, which is not needed for the student session
    // page.
    return !(has(this.props, 'location.pathname')
      && this.props.location.pathname.startsWith('/' + Routing.JOIN_SESSION + '/'));
  }

  render() {
    const authProviderValue = {
      ...this.state,
    };
    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

export default withRouter(NoAuth);
