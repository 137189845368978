import React from 'react';
import { Typography } from '@mui/material';

const FaceCaptureGuidelinesText = (_props) => {
  return (
    <>
      <Typography variant="h3">Image capture guidelines</Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>To capture good-quality images of yourself, try the following:</Typography>
      <Typography variant="body1" component="ol">
        <li>Facing your webcam, position your face in the blue oval frame.</li>
        <li>Ensure no part of your face is obscured. Take off sunglasses, masks and any other items that can block your face.</li>
        <li>Make sure you’re in a room with sufficient light and that you’re facing the light source.</li>
        <li>Make sure there’s no light source behind you or strong overhead lights that cast a shadow on your face.</li>
      </Typography>
    </>
  )
}

export default FaceCaptureGuidelinesText;