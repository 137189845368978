import React from 'react';

const QrCode = (props) => (
  <svg
    width="28px"
    height="28px"
    viewBox="0 0 324 324"
    version="1.1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="M88.3636 235.636V265.091H58.9091V235.636H88.3636ZM88.3636 58.9091V88.3636H58.9091V58.9091H88.3636ZM265.091 58.9091V88.3636H235.636V58.9091H265.091ZM29.4545 294.315H117.818V206.182H29.4545V294.315ZM29.4545 117.818H117.818V29.4545H29.4545V117.818ZM206.182 117.818H294.545V29.4545H206.182V117.818ZM147.273 176.727V324H0V176.727H147.273ZM265.091 294.545V324H235.636V294.545H265.091ZM324 294.545V324H294.545V294.545H324ZM324 176.727V265.091H235.636V235.636H206.182V324H176.727V176.727H265.091V206.182H294.545V176.727H324ZM147.273 0V147.273H0V0H147.273ZM324 0V147.273H176.727V0H324Z" fill="black"/>
  </svg>

);

export default QrCode;
