import React, { useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { monitoringContext } from '../context/MonitoringContext';
import Notice from '../notification/Notice';
import AuthMaterialsService from '../../services/AuthMaterialsService';
import { ACTIONS } from '../../constants/monitorSessions';
import { MATERIALS_EXAM_TYPE } from '../../constants/authorisedMaterials';

// Any authorised material descriptions matching the following list will be shown with a
// red 'not allowed' icon, where all others will show with a green check icon.
const notAllowedItemDescriptions = ['n/a', 'none', 'no calculator'];

const HybridAnswerSheetsListItem = ({ answerSheets, showHybridText }) => {
  const hasAnswerSheets = answerSheets > 0;
  const answerSheetsText = hasAnswerSheets && `Answer sheets: ${answerSheets}`;
  const hybridText = hasAnswerSheets ? 'Hybrid: yes' : 'Hybrid: no';

  const primaryText = showHybridText ? hybridText : answerSheetsText;
  const secondaryText = showHybridText ? answerSheetsText : null;

  return (
    <>
      <ListItem disablePadding disableGutters>
        <ListItemIcon><ArticleOutlinedIcon /></ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: 'body1' }}
          primary={primaryText}
          secondary={secondaryText}
        />
      </ListItem>
    </>
  );
}

function AuthorisedMaterials(props) {

  const { state, dispatch } = useContext(monitoringContext);
  const { contextId, slotId, showHeader, showHybridText } = props;
  const [dataError, setDataError] = useState('');

  const lookupType = slotId ? 'examSlotId' : 'contextId';
  const id = slotId ? slotId : contextId;
  const authMaterials = state?.authorisedMaterials?.[lookupType]?.[id];

  const getAuthMaterialsData = useCallback(async () => {
    try {
      if (!id) { throw new Error() }
      const controller = new AbortController();
      const authMaterialsResp = await AuthMaterialsService.getAuthMaterials({ [lookupType]: id }, controller.signal);
      if (controller.signal.aborted) { return; }
      dispatch({ type: ACTIONS.ADD_AUTH_MATERIALS, id: id, lookupType: lookupType, value: authMaterialsResp });
      return authMaterialsResp;
    } catch (error) {
      setDataError('No authorised materials data found');
    }
  }, [dispatch, id, lookupType]);

  useEffect(() => {
    if (isEmpty(dataError) && isEmpty(authMaterials)) {
      getAuthMaterialsData();
    }
  }, [authMaterials, dataError, getAuthMaterialsData]);

  return (
    <Box sx={{ p: 2, ...props.sx }}>
      {showHeader &&
        <Typography variant="h5" component="h3" >Authorised materials</Typography>
      }
      {!isEmpty(dataError) &&
        <Notice noticeType="notice">{dataError}</Notice>
      }
      {authMaterials && authMaterials.map((authMaterial) => {
        return (
          <List key={authMaterial.id}>
            <ListItem disablePadding disableGutters>
              <ListItemIcon>{MATERIALS_EXAM_TYPE[authMaterial.examType]?.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: 'body1' }}
                primary={MATERIALS_EXAM_TYPE[authMaterial.examType]?.display}
              />
            </ListItem>
            {!isEmpty(authMaterial.permittedItems) &&
              <List dense>
                {sortBy(authMaterial.permittedItems, 'category').map((item, index) => {
                  return (
                    <ListItem key={index} alignItems="flex-start" sx={{ py: 0 }}>
                      <ListItemIcon>
                        {notAllowedItemDescriptions.includes(item.description.toLowerCase())
                          ? <IndeterminateCheckBoxOutlinedIcon color="warning" />
                          : <CheckBoxOutlinedIcon color="success" />
                        }
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body1' }}
                        primary={item.category}
                        secondary={item.description}
                      />
                    </ListItem>
                  )
                })}
              </List>
            }
            <HybridAnswerSheetsListItem
              answerSheets={authMaterial.answerSheets}
              showHybridText={showHybridText}
            />
          </List>
        )
      })}
    </Box>
  );
}

AuthorisedMaterials.propTypes = {
  contextId: PropTypes.string,
  slotId: PropTypes.string,
  showHeader: PropTypes.bool, // Defaults to true
  showHybridText: PropTypes.bool, // Defaults to true
};

AuthorisedMaterials.defaultProps = {
  showHeader: true,
  showHybridText: true,
};

export default AuthorisedMaterials;
