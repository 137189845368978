import mockRequest from '../utils/mockRequest';
import MOCK from './mock';
import { format as dateFnsFormat } from 'date-fns/format';
import {isEmpty} from 'lodash';
import NetworkService from './NetworkService';

export default class RoomScanService {
    static async getRoomScans(unitName, studentName, examDate, abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(MOCK.ROOM_SCANS);
        }
        let params = new URLSearchParams();

        if (!isEmpty(unitName)) {
            params.append('contextNameMatch', unitName);
        }

        if (!isEmpty(studentName)) {
            params.append('studentTextMatch', studentName);
        }

        if (examDate) {
            params.append('examDate', dateFnsFormat(new Date(examDate), 'yyyy-MM-dd\'T\'00:00:00X'));
        }
        const response = await NetworkService.get(
            `/examslots-roomscan?` + params,
            abortSignal);
        return await response.json();
    }

    static async uploadImage(image, examSlotId, abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest({}, 2000);
        }

        return await NetworkService.putFile(`/examslots/${examSlotId}/room-scan/panorama`, image, null, abortSignal);
    }

    /**
     * Download a zip archive of stored image data for a given exam slot, if it exists.
     * @param {string} examSlotId The exam slot identifier in eVigilation.
     * @param {AbortSignal} abortSignal An abort controller signal to halt the request.
     * @returns {Promise<unknown>} The successful response object
     * @throws {Error} If request was not successful
     */
    static async downloadArchive(examSlotId, abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(
              new Response(
                new Blob(
                  [new Blob()],
                  {type: "application/zip"}
                  )
              ), 2000);
        }

        return NetworkService.get(`/examslots/${examSlotId}/room-scan/data`, abortSignal, 'application/zip');
    }

    /**
     * Download the room scan image for a given exam slot referenced by an index if it exists. If the index is not
     * specified, downloads the finished stitched image.
     * @param {string} examSlotId The exam slot identifier in eVigilation.
     * @param {string} index Optional index name for the image requested.
     * @param {string} type The content type for the image requested, eg. 'image/jpeg'.
     * @param {AbortSignal} abortSignal An abort controller signal to halt the request.
     * @returns {Promise<unknown>} The successful response object
     * @throws {Error} If request was not successful
     */
    static async downloadImage(examSlotId, index, type, abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(
              new Response(
                new Blob(
                  [new Blob()],
                  {type: "image/jpeg"}
                )
              ), 2000);
        }
        let url = `/examslots/${examSlotId}/room-scan/image`;
        if (index) {
            url += `?index=${encodeURIComponent(index)}`;
        }
        return NetworkService.get(url, abortSignal, type);
    }

    /**
     * Get the room scan data for a specific exam slot.
     * @param {string} examSlotId The exam slot identifier in eVigilation.
     * @param {AbortSignal} abortSignal An abort controller signal to halt the request.
     * @returns {Promise<unknown>} The successful response object
     * @throws {Error} If request was not successful
     */
    static async getRoomScanData(examSlotId, abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(MOCK.ROOM_SCAN);
        }
        const response = await NetworkService.get(
            `/examslots/${examSlotId}/room-scan`,
            abortSignal);
        return await response.json();
    }
}