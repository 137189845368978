import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Button, Collapse, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { ErrorOutline, InfoOutlined, WarningAmber } from '@mui/icons-material';
import RoomScanQrCode from '../qrCode/RoomScanQrCode';
import IconContainer from '../container/IconContainer';
import Notice from '../notification/Notice';
import { joinContext } from '../context/JoinContext';
import RoomScanService from '../../services/RoomScanService';
import { lightestGrey } from '../../config/theme';
import { ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import { ROOM_SCAN_STATUS } from '../../constants/roomScanStatuses';


const isRoomScanComplete = (roomScanStatus) => roomScanStatus === ROOM_SCAN_STATUS.UPLOADED;

const QRCodeError = () => {
  return (
    <Notice noticeType="error">
      <IconContainer icon={<WarningAmber fontSize="large" color="error" />}>
        <Typography component="p" variant="h3">QR code not available</Typography>
        <Typography component="p" variant="h3">Please contact your online supervisor for support</Typography>
      </IconContainer>
    </Notice>
  );
}

function RoomScanProccessSteps() {
  return (
    <Stepper connector={null} orientation="vertical" >
      <Step active={false}>
        <StepLabel><Typography>Scan the QR code with your smartphone</Typography></StepLabel>
      </Step>
      <Step active={false}>
        <StepLabel><Typography>Take photos of the exam environment as directed</Typography></StepLabel>
      </Step>
      <Step active={false}>
        <StepLabel><Typography>Return to this page and click 'Continue'</Typography></StepLabel>
      </Step>
    </Stepper>
  )
}

function RoomScanStep(props) {
  const [roomScanStatusRequest, setRoomScanStatusRequest] = useState({
    roomScanStatus: '',
    requestPending: false,
    requestError: '',
  });
  const { state } = useContext(joinContext);
  const controllerRef = useRef(new AbortController());

  const isRoomScanCompleted = isRoomScanComplete(roomScanStatusRequest.roomScanStatus);
  const showRoomScanError = (!isEmpty(roomScanStatusRequest.requestError) || (!isEmpty(roomScanStatusRequest.roomScanStatus) && !isRoomScanCompleted));

  const verifyRoomScanStatus = async () => {
    //TODO determine whether we need this additional check when the room scan step is blocked if not complete
    //may also be replaced by a 'student handler' that provides a message once the room scan is complete

    const controller = controllerRef.current;
    try {
      setRoomScanStatusRequest({
        ...roomScanStatusRequest,
        requestPending: true,
        requestError: '',
      });

      const { roomScanStatus } = await RoomScanService.getRoomScanData(state.examSession.id, controller.signal);
      if (isRoomScanComplete(roomScanStatus)) {
        await props.onComplete({ flowStepIndex: props.flowStepIndex }, ONBOARD_ACTION_TYPES.ROOM_SCAN.apiPathTypeCompleteParam);
      }

      setRoomScanStatusRequest({
        ...roomScanStatusRequest,
        requestPending: false,
        roomScanStatus: roomScanStatus,
      });
    } catch (error) {
      console.log('Failed to check room scan status', error);
      setRoomScanStatusRequest({
        ...roomScanStatusRequest,
        requestPending: false,
        requestError: 'We were unable to check your room scan status',
      });
    }
  }

  const onContinueClick = async () => {
    if (!isRoomScanCompleted) {
      await verifyRoomScanStatus();
      return;
    }

    props.onComplete({ flowStepIndex: props.flowStepIndex }, ONBOARD_ACTION_TYPES.ROOM_SCAN.apiPathTypeCompleteParam);
  }

  useEffect(() => {
    const controller = controllerRef.current;
    return () => controller.abort();
  }, []);

  const roomScanStatusText = 'Room scan incomplete, if you are experiencing difficulties please contact your online supervisor.'

  return (
    <>
      <Typography variant="h2">Room scan</Typography>
      <Typography>Scan the QR code below with your smartphone to start the room scan.</Typography>
      <Box bgcolor={lightestGrey} borderRadius={2} p={4} my={2} display="flex" flexGrow={1} flexDirection={{ xs: 'column', md: 'row' }}>
        <Box maxWidth={{ sm: '100%', md: '50%' }} mr={{ xs: 0, md: 2 }}>
          <RoomScanQrCode slotId={state.examSession.id} errorComponent={<QRCodeError />} dynamicScaling />
        </Box>
        <Box maxWidth={{ sm: '100%', md: '50%' }} display="flex" flexDirection="column">
          <Typography variant="h3">Room scan process</Typography>
          <RoomScanProccessSteps />
          <IconContainer icon={<InfoOutlined />} sx={{ mt: 3 }}>
            <Typography>
              If you experience any technical difficulties during this process, please contact your online supervisor
            </Typography>
          </IconContainer>
        </Box>
      </Box>
      <Collapse in={showRoomScanError}>
        <IconContainer icon={<ErrorOutline color="error" />}>
          <Typography>{roomScanStatusText}</Typography>
        </IconContainer>
      </Collapse>
      <Button variant="contained" onClick={onContinueClick} disabled={roomScanStatusRequest.requestPending}>
        Continue
      </Button>
    </>
  )
}

RoomScanStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default RoomScanStep;