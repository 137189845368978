import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import InfoPopover from './InfoPopover';
import { getFormattedDate } from '../../utils/getExamDetails';

function DeletedDatePopup(props) {
  const { deletedDate } = props;
  const formattedDeletedDate = getFormattedDate(deletedDate, 'dd/MMM/yyyy') || 'Unknown';

  return (
    <InfoPopover
      buttonStyle={{ marginTop: 0 }}
      content={
        <>
          <Typography variant="h4" component="p" sx={{ my: 1 }}>Record deleted</Typography>
          <Typography variant="body1">Deletion date: {formattedDeletedDate}</Typography>
        </>
      }
    />
  );
}

DeletedDatePopup.propTypes = {
  deletedDate: PropTypes.string
}

export default DeletedDatePopup;
