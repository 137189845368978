import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';
import OrderedStepsList from './OrderedStepsList';
import RecordingNotice from './RecordingNotice';
import { authContext } from '../../authContext';
import { AUTO_ONBOARD_WAIT_STEPS_MAPPER, HUMAN_WAIT_STEPS_MAPPER } from '../../constants/contentOverrides';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';

function ExamWaitingForSupervisorText() {
  const { contentOverrides, features } = useContext(authContext);
  const stepsOverride = features[FEATURE_TOGGLES.AUTO_ONBOARD] ? contentOverrides?.[AUTO_ONBOARD_WAIT_STEPS_MAPPER] : contentOverrides?.[HUMAN_WAIT_STEPS_MAPPER];

  const autoOnboardDefaultSteps = {
    1: 'When asked, hold your <strong>photo ID</strong> up to the webcam so they can confirm your identity.',
    2: 'Next, you’ll need to do a room scan using your smartphone.',
    3: 'You’ll then need then need to confirm your authorised materials.'
  };

  const humanDefaultSteps = {
    1: 'When asked, hold your <strong>photo ID</strong> up to the webcam so they can confirm your identity.',
    2: 'You’ll then need to do a room scan using your smartphone.',
    3: 'The supervisor will then check your authorised materials.'
  };

  const defaultSteps = features[FEATURE_TOGGLES.AUTO_ONBOARD] ? autoOnboardDefaultSteps : humanDefaultSteps;

  const steps = isEmpty(stepsOverride) ? defaultSteps : stepsOverride;

  return (
    <Box id="human-onboarding-waiting-text">
      <Box mr={2}>
        <Typography variant="h2" color="primary">
          You’re now ready to check in! Your supervisor will be with you shortly.
        </Typography>
        <OrderedStepsList steps={steps} />
        <RecordingNotice />
      </Box>
    </Box>
  )
};

export default ExamWaitingForSupervisorText;
