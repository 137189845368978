import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { format as dateFnsFormat } from 'date-fns/format';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';

import { AuthConsumer } from '../authContext';
import Can, { CanThey } from '../components/Can';
import SupervisorSessions from '../components/SupervisorSessions';
import SearchFormByOptions from '../components/search/SearchFormByOptions';
import ScrollButtons from '../components/form/ScrollButtons';
import Routing from '../utils/Routing';
import { CAPABILITIES } from '../constants/capabilities';
import { SEARCH_SUPERVISOR_SESSION_LABELS } from '../constants/search';
import { SUPERVISOR_SESSIONS_SEARCH_OPTIONS, SUPERVISOR_SESSIONS_SEARCH_FIELDS } from '../constants/supervisorSessions';

function ManageSupervisorSessions(_props) {
  const location = useLocation();
  const queryParams = qs.parse(location?.search, { ignoreQueryPrefix: true, depth: 1 });

  const canNavigate = (params) => {
    if(!params) { return false; }
    const { date, userLookup } = params;
    return Boolean(date && userLookup);
  };

  const getRoute = (params) => {
    const { date, userLookup } = params;
    let urlParams = new URLSearchParams();
    const parsedDate = dateFnsIsValid(date) ? date : new Date(date);
    urlParams.append('date', dateFnsFormat(parsedDate, "yyyy-MM-dd"));

    //TODO if we allow multi lookup, fix here to not just get first array element
    urlParams.append('userId', userLookup[0].value);
    urlParams.append(`userName`, userLookup[0].label);
    return `${Routing.MANAGE_SUPERVISOR_SESSIONS}/searchBy?${urlParams}`;
  };

  const examSessionPage = (capabilityContextAccess) => {
    return (
      <ScrollButtons>
        <Typography variant="h2">Supervisor sessions</Typography>
        <Box display="flex" mb={2}>
          <Box flexGrow="1">
            <SearchFormByOptions
              key={location.key}
              canNavigate={canNavigate}
              fieldsToShow={SUPERVISOR_SESSIONS_SEARCH_OPTIONS}
              getRoute={getRoute}
              labels={SEARCH_SUPERVISOR_SESSION_LABELS}
              searchFields={SUPERVISOR_SESSIONS_SEARCH_FIELDS}
              uriParams={{
                date: queryParams?.date,
                userLookup: queryParams?.userId ? [{label: queryParams?.userName, value: queryParams?.userId}] : null,
              }}
            />
          </Box>
        </Box>
        {!isEmpty(queryParams.date) && !isEmpty(queryParams.userId) &&
          <SupervisorSessions
            key={location.key}
            canEdit={CanThey(capabilityContextAccess, false,
              CAPABILITIES.manageAssignments)}
            capabilityContextAccess={capabilityContextAccess}
            selectedDate={queryParams.date}
            selectedSupervisorId={queryParams.userId}
          />
        }
      </ScrollButtons>
    )
  };

  return (
    <AuthConsumer>
      {({capabilityContextAccess}) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={[CAPABILITIES.manageAssignments, CAPABILITIES.viewExam]}
          yes={() => (examSessionPage(capabilityContextAccess))}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageSupervisorSessions;
