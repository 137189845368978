import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  Edit as EditIcon,
  AddCircleOutline as AddIcon,
  GroupAdd as AssignRolesIcon,
  AccountTree as WorkInBranch,
} from '@mui/icons-material';
import { isEmpty, has } from 'lodash';
import AssignRolesForm from './AssignRolesForm';
import ContextForm from './ContextForm';
import FormPopup from '../popup/FormPopup';
import { buttonLinkStyle, fieldsetContainerStyle, legendStyle } from '../../config/theme';
import Routing from '../../utils/Routing';

class ContextOptionsForm extends React.Component {
  state = {
    editNode: undefined,
    modalTitle: 'Manage context',
    parentEditable: true,
    popupOpen: false,
    popupType: 'add',
    popupContent: '',
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  addContext = () => {
    const { selectedNode } = this.props;
    const parentId = has(selectedNode, 'id') && !isEmpty(selectedNode.id) ? selectedNode.id : '';
    const baseAddContext = {id: undefined, name: '', description: '', externalId: '', parent: parentId};
    this.setState({
      modalTitle: 'Add context',
      editNode: baseAddContext,
      popupContent: 'editContext',
    }, () => this.openPopup())
  };

  editContext = () => {
    const { contextsTree, selectedNode } = this.props;
    let editNode = { ...selectedNode };
    let parentEditable = true;
    if(selectedNode.id === contextsTree.id) {
      editNode['parentObj'] = selectedNode?.parent;
      parentEditable = false;
    }
    editNode['parent'] = selectedNode?.parent?.id || '';

    this.setState({
      modalTitle: 'Edit context',
      editNode: editNode,
      parentEditable: parentEditable,
      popupContent: 'editContext',
    }, () => this.openPopup());
  };

  disableEdit = () => {
    const { rootContextId, selectedNode } = this.props;
    return isEmpty(selectedNode) ||
      (rootContextId ? selectedNode.id === rootContextId : selectedNode.name?.toLowerCase() === 'root');
    //if we've failed to get a root context (shouldn't happen), fail safe to not allow edit of contexts named root
  };

  navigateToContext = () => {
    const { navigate, selectedNode } = this.props;
    navigate(
      Routing.path(`${Routing.MANAGE_CONTEXTS}/topBranch/${selectedNode.id}`)
    )
  }

  assignRoles = () => {
    this.setState({
      modalTitle: 'Assign roles to this context',
      popupContent: 'somethingElse',
    }, () => this.openPopup());
  }

  render() {
    const { canManageContext, canManageRoleAssignment, contextsList, contextsTree, selectedNode, setHasContextUpdated } = this.props;
    const { editNode, modalTitle, parentEditable, popupOpen, popupContent } = this.state;

    return (
      <>
        <FormControl component="fieldset" sx={fieldsetContainerStyle}>
          <FormLabel component="legend" sx={legendStyle}>Action on selected context</FormLabel>
          <Box display="flex" flexDirection="column" ml={1}>
            <Button
              disabled={!canManageContext}
              startIcon={<AddIcon/>}
              onClick={() => this.addContext()}
              aria-label="Add context"
              sx={buttonLinkStyle}
            >
              Add sub-context
            </Button>
            <Button
              id="edit-context-button"
              startIcon={<EditIcon/>}
              disabled={canManageContext ? this.disableEdit() : true}
              onClick={this.editContext}
              aria-label="Edit context"
              sx={buttonLinkStyle}
            >
              Edit this context
            </Button>
            <Button
              startIcon={<AssignRolesIcon/>}
              disabled={canManageRoleAssignment ? this.disableEdit() : true}
              onClick={this.assignRoles}
              aria-label="Assign roles to this context"
              sx={buttonLinkStyle}
            >
              Assign roles to this context
            </Button>
            <Button
              startIcon={<WorkInBranch/>}
              disabled={isEmpty(selectedNode) || selectedNode.id === contextsTree.id}
              onClick={this.navigateToContext}
              aria-label="Limit view to this branch (this context and its sub-contexts)"
              sx={buttonLinkStyle}
            >
              Limit view to this branch
            </Button>
          </Box>
        </FormControl>
        {selectedNode &&
          <FormPopup
            title={modalTitle}
            open={popupOpen}
            onClose={this.closePopup}
            content={ popupContent === 'editContext'
              ? <ContextForm
                  setHasContextUpdated={setHasContextUpdated}
                  context={editNode}
                  contextsList={contextsList}
                  parentEditable={parentEditable}
                />
              : <AssignRolesForm context={selectedNode}/>
            }
          />
        }
      </>
    );
  }
}

ContextOptionsForm.propTypes = {
  selectedNode: PropTypes.object,
  contextsTree: PropTypes.object,
  contextsList: PropTypes.array,
  rootContextId: PropTypes.string,
  setHasContextUpdated: PropTypes.func,
  canManageContext: PropTypes.bool,
  canManageRoleAssignment: PropTypes.bool,
};

export default ContextOptionsForm;
