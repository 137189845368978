import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import AudioStream from '../audioVisual/AudioStream';
import { joinContext } from '../context/JoinContext';

import addConferenceError from '../audioVisual/helper/addConferenceError';
import clearConferenceError from '../audioVisual/helper/clearConferenceError';
import { formatErrorForDisplay } from '../../utils/formatErrorForDisplay';
import logToGateway from '../../utils/logToGateway';
import PromiseWithAbort from '../../utils/PromiseWithAbort';

import { ACTIONS } from '../../constants/joinSession';
import { CONFERENCE_ERROR_CATEGORIES } from '../../constants/errors';
import {
  MEDIA_STREAM_CONNECTION_STATES,
  SHARED_MEDIA_STATES,
  SHARED_MEDIA_TYPES,
} from '../../constants/mediaStates';

function SingleAudioConnect(_props) {
  const { state, dispatch } = useContext(joinContext);
  const [currentlyTalking, setCurrentlyTalking] = useState(false);
  const sessionId = state.examSession?.id;
  const conferenceUserId = state.examSession?.connectionDetails?.connectionProps?.user.id || 'no_user_id'

  const asyncAbort = useRef(new PromiseWithAbort());
  const audioRef = useRef(null);
  const connectionStarted = useRef(false);

  const logToGatewayWrapper = useCallback((logType, logDetails) => {
    logToGateway(
      state.gatewayService,
      logType,
      logDetails,
      conferenceUserId,
      sessionId
    );
  }, [conferenceUserId, sessionId, state.gatewayService]);

  const updateAudioStates = useCallback((sharedState, state) => {
    sharedState && dispatch({
      type: ACTIONS.UPDATE_SHARED_MEDIA_STATUS,
      value: { mediaType: SHARED_MEDIA_TYPES.AUDIO, status: sharedState },
    });
    state && dispatch({
      type: ACTIONS.SET_AUDIO_STATE,
      value: state,
    });
  }, [dispatch]);

  const handleTalking = useCallback((isTalking) => {
    if (state.gatewayService === null) {
      return;
    }
    if (isTalking && !currentlyTalking) {
      setCurrentlyTalking(true);
      state.gatewayService.updateMedia(SHARED_MEDIA_TYPES.AUDIO, 'active', true);
    } else if (!isTalking && currentlyTalking) {
      setCurrentlyTalking(false);
      state.gatewayService.updateMedia(SHARED_MEDIA_TYPES.AUDIO, 'active', false);
    }
  }, [currentlyTalking, state.gatewayService]);

  const initialiseAudio = useCallback(async (echoTest) => {
    const connectionProps = state.examSession?.connectionDetails?.connectionProps;
    const connectionErrors = state.examSession?.connectionDetails?.connectionErrors;

    if (isEmpty(connectionProps)) {
      console.error('Cannot open audio connection without media gateway details');
      return;
    }

    asyncAbort.current.wrap(
      state.audioService.open(connectionProps, audioRef.current, state.deviceInfo?.audioInputDevice,
        state.deviceInfo?.audioOutputDevice)
    )
      .then(() => {
        asyncAbort.current.wrap(
          state.audioService.start(false, echoTest)
        )
          .then(() => {
            updateAudioStates(SHARED_MEDIA_STATES.CONNECTED,  MEDIA_STREAM_CONNECTION_STATES.CONNECTED);
            clearConferenceError(CONFERENCE_ERROR_CATEGORIES.AUDIO, undefined, connectionErrors);
            connectionStarted.current = false;
          })
          .catch((err) => {
            addConferenceError(
              CONFERENCE_ERROR_CATEGORIES.AUDIO,
              formatErrorForDisplay(err, CONFERENCE_ERROR_CATEGORIES.AUDIO, 'audioStart') ,
              connectionErrors);
            logToGatewayWrapper(`student_audio_start`, { error: err, source: 'initAudio', devices: state.deviceInfo });
            updateAudioStates(SHARED_MEDIA_STATES.DISABLED,  MEDIA_STREAM_CONNECTION_STATES.CLOSED);
            console.error(`Unable to join audio VOIP conference`, { err });
          });
      })
      .catch(err => {
        addConferenceError(
          CONFERENCE_ERROR_CATEGORIES.AUDIO,
          formatErrorForDisplay(err, CONFERENCE_ERROR_CATEGORIES.AUDIO, 'audioOpen'),
          connectionErrors);
        logToGatewayWrapper(`student_audio_open`, { error: err, source: 'initAudio', devices: state.deviceInfo });
        updateAudioStates(SHARED_MEDIA_STATES.DISABLED,  MEDIA_STREAM_CONNECTION_STATES.CLOSED);
        console.error(`Unable to open audio connection`, { err });
      });
  }, [logToGatewayWrapper, state.audioService, state.deviceInfo, state.examSession?.connectionDetails, updateAudioStates]);

  useEffect(() => {
    if (state.audioConnectionState === MEDIA_STREAM_CONNECTION_STATES.CONNECTING && !connectionStarted.current) {
      connectionStarted.current = true;
      initialiseAudio('echo');
    }

    state.audioConnectionState === MEDIA_STREAM_CONNECTION_STATES.RECONNECTING &&
      initialiseAudio(null);

  }, [initialiseAudio, state.audioConnectionState]);

  useEffect(() => {
    const asyncAbortRef = asyncAbort.current;
    return () => {
      asyncAbortRef.abort();
    };
  }, [])

  return (
    <>
      {state.audioService &&
        <AudioStream
          streamService={state.audioService}
          connectionStatus={state.examSession?.connectionDetails?.audioConnection}
          getRef={(ref) => { audioRef.current = ref }}
          showVolumeControls={false}
          showMicrophoneMeter={false}
          onTalking={handleTalking}
        />
      }
    </>
  )
}

export default SingleAudioConnect;
