import { isEmpty, filter } from 'lodash';
import { CONFERENCE_ERROR_CATEGORIES } from '../../../constants/errors';
/**
 * Select the next relevant media/conference error to display, or nothing if no errors exist
 * If there is a connection error, no media will work, so it's the highest priority
 * @returns {object} The media/conference error of the highest priority (connection or requested category)
 */
const getConferenceErrorByPriority = (conferenceErrors, errorCategory) => {
  if (isEmpty(conferenceErrors)) {
    return;
  }
  let confError = filter(conferenceErrors, ['category', CONFERENCE_ERROR_CATEGORIES.CONNECT]);
  if (isEmpty(confError)) {
    confError = filter(conferenceErrors, ['category', errorCategory]);
  }
  // if there's more than one error for the category, just return the first
  return confError?.[0];
};

export default getConferenceErrorByPriority;