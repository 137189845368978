export const USER_FIELDS = {
  id: {
    display: 'eVigilation ID',
    required: false,
    type: 'none',
  },
  accountId: {
    display: 'Account ID',
    required: false,
    type: 'textbox',
  },
  userName: {
    display: 'Username',
    required: true,
    type: 'textbox',
  },
  fullName: {
    display: 'Preferred name',
    required: true,
    type: 'textbox',
  },
  legalName: {
    display: 'Legal name',
    required: false,
    type: 'textbox',
    addDescription: 'If left blank, this will be populated by full name',
  },
  externalId: {
    display: 'External ID',
    required: false,
    type: 'textbox',
  },
  isDisabled: {
    display: 'Status',
    required: true,
    type: 'toggle',
    description: 'Set users active status',
    on: 'Inactive',
    off: 'Active',
  },
  shiftStaff: {
    display: 'Session access',
    required: false,
    type: 'toggle',
    description: 'Is user allowed to access supervising pages without pre-assignment',
    on: 'Yes',
    off: 'No',
  },
  isAAA: {
    display: 'AAA',
    required: false,
    type: 'none',
  },
  action: {
    display: 'Action',
    required: false,
    type: 'button',
  },
}

export const USER_TYPES = {
  STUDENT: 'STUDENT',
  SUPERVISOR: 'SUPERVISOR',
  ONBOARDER: 'ONBOARDER',
  AGENT: 'AGENT',
}
