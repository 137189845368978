import React from 'react';
import PropTypes from 'prop-types';
import OnboardProgressForm from '../form/OnboardProgressForm';

/**
 * Container for the {@link OnboardProgressForm} to handle cases where multiple exam slots
 * are selected and update callback functions are passed as props.
 * @param {Object} props List of exam slots that are selected 
 * @returns 
 */
function MultiSelectOnboardProgressFormContainer(props) {
  const selectedExamSlot = props.selectedExamSlots.find(Boolean);
  const selectedExamSlotId = selectedExamSlot?.id;
  const selectedExamSlotContextId = selectedExamSlot?.context?.id;

  // TODO: Possibly include the response payload / other information here, remove this TODO when it's updated / if we do not want to do this
  const onSkipRequestComplete = () => {
    props?.setHasSlotUpdated(true);
  }

  return (
    <OnboardProgressForm
      onSkipRequestComplete={onSkipRequestComplete}
      slotId={selectedExamSlotId}
      slotContextId={selectedExamSlotContextId}
    />
  );
}

MultiSelectOnboardProgressFormContainer.propTypes = {
  selectedExamSlots: PropTypes.array.isRequired,
  setHasSlotUpdated: PropTypes.func,
  setRequestResult: PropTypes.func, // Do nothing with this prop for now, just listed to note that it is passed through as a prop
};

export default MultiSelectOnboardProgressFormContainer;
