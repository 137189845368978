import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import ExamSessionService from '../../services/ExamSessionService';
import Notice from '../notification/Notice';

function ExamSessionResetMisconductForm(props) {
  const [requestError, setRequestError] = useState();
  const [requestPending, setRequestPending] = useState(false);
  const { examSessionId, setHasSlotUpdated, updateSlot, onClose } = props;

  const saveSession = async () => {
    try {
      setRequestError();
      setRequestPending(true);
      const defaultStatusUpdateObj = {
        integrityReview: null,
        overrideDeletionDate: null,
        cannotExpire: false,
      }
      const updatedSlot = await ExamSessionService.updateExamSession(examSessionId, defaultStatusUpdateObj);
      setHasSlotUpdated(true);
      updateSlot(updatedSlot);
      setRequestPending(false);
      handleCloseForm(true);
    } catch (error) {
      setRequestPending(false);
      setRequestError(error.message ? error.message : 'Sorry, we were unable to update the exam session');
    }
  }

  const handleCloseForm = (requestSuccessful) => {
    onClose(requestSuccessful);
  }

  return (
    <>
      {requestError &&
        <Notice noticeType="error">{requestError}</Notice>
      }
      <Typography sx={{ my: 1 }}>Proceeding with this action will reset the record to its default state</Typography>
      <Box display="flex" justifyContent="flex-end">
        {requestPending &&
          <CircularProgress disableShrink />
        }
        {!requestPending &&
          <>
            <Button
              sx={{ mx: 1 }}
              color="secondary"
              onClick={() => handleCloseForm(false)}
            >Close</Button>
            <Button
              sx={{ ml: 1 }}
              color="primary"
              variant="contained"
              onClick={saveSession}
            >Yes</Button>
          </>
        }
      </Box>
    </>
  );
}

ExamSessionResetMisconductForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  examSessionId: PropTypes.string.isRequired,
  setHasSlotUpdated: PropTypes.func,
  updateSlot: PropTypes.func,
}

export default ExamSessionResetMisconductForm;
