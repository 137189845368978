import React from 'react';

const RoomScanFailed = (props) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Room scan failed</title>
    <g id="RoomScanFailed" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="RoomScanFailed-Artboard" transform="translate(-632.000000, -208.000000)">
            <g id="RoomScanFailedGroup" transform="translate(632.000000, 208.000000)">
                <g id="RoomScanFailedGroup-2" transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) translate(3.000000, 0.000000)" stroke="#3C4043">
                    <rect id="RoomScanFailedRectangle" strokeWidth="0.9" x="0.45" y="0.45" width="8.562252" height="15.1" rx="1"></rect>
                    <rect id="RoomScanFailedRectangle-2" strokeWidth="0.7" x="4.38160578" y="13.6255249" width="1" height="1" rx="0.5"></rect>
                    <line x1="3.731126" y1="1.58634087" x2="5.731126" y2="1.58634087" id="RoomScanFailedLine" strokeWidth="0.7" strokeLinecap="round"></line>
                    <line x1="0.833374165" y1="2.73604544" x2="8.83337417" y2="2.73604544" id="RoomScanFailedLine-2" strokeWidth="0.8" strokeLinecap="square"></line>
                    <line x1="0.731125999" y1="12.4758203" x2="8.731126" y2="12.4758203" id="RoomScanFailedLine-3" strokeWidth="0.8" strokeLinecap="square"></line>
                </g>
                <rect id="RoomScanFailedRectangle-3" x="0" y="0" width="16" height="16"></rect>
            </g>
        </g>
    </g>
  </svg>

);

RoomScanFailed.defaultProps = {
  width: "16px",
  height: "16px",
}

export default RoomScanFailed;
