const EXAMSESSIONS = [
  {
    "student": {
        "userName": "astudent",
        "fullName": "A Student",
        "externalId": "",
        "isDisabled": false,
        "id": "5b78749a-675a-412c-831d-6725dfe5be32"
    },
    "supervisors": [{
        "userName": "asupervisor",
        "fullName": "A Supervisor",
        "externalId": "",
        "isDisabled": false,
        "id": "4817c366-f6f1-40c7-912d-1c1afcaf309c"
    }],
    "context": {
        "name": "Monash",
        "id": "63aa4ae0-76be-438b-acba-eaca18f35c01",
        "parent": {
            "id": "01697a21-180a-4d22-b780-86e242ebca8c"
        }
    },
    "examStartDateTime": "2021-05-22T19:09:00+10:00",
    "examEndDateTime": "2021-05-22T21:00:00+10:00",
    "bbbUrl": null,
    "state": "PENDING",
    "privacyConfirmation": "ACCEPTED",
    "id": "5d0fe647-5bdb-4520-9be5-ad753c3ea854"
  },
  {
    "student": {
        "userName": "anotherstudent",
        "fullName": "Another Student",
        "externalId": "",
        "isDisabled": false,
        "id": "5b78749a-675a-412c-831d-6725dfe5be32"
    },
    "supervisors": [{
        "userName": "anothersupervisor",
        "fullName": "Another Supervisor",
        "externalId": "",
        "isDisabled": false,
        "id": "4817c366-f6f1-40c7-912d-1c1afcaf309c"
    }],
    "context": {
        "name": "Monash",
        "id": "63aa4ae0-76be-438b-acba-eaca18f35c01",
        "parent": {
            "id": "01697a21-180a-4d22-b780-86e242ebca8c"
        }
    },
    "examStartDateTime": "2020-06-22T19:09:00+10:00",
    "examEndDateTime": "2020-06-22T21:00:00+10:00",
    "bbbUrl": null,
    "state": "PENDING",
    "id": "5d0fe647-5bdb-4520-9be5-ad753c3ea853",
    "humanSupervised": true,
    "aiType": "NONE",
  }
];

export const EXAM_SESSION = EXAMSESSIONS[0];

export default EXAMSESSIONS;
