import React from 'react';
import Typography from '@mui/material/Typography';
import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import RoleCapabilityTable from '../components/table/RoleCapabilityTable';
import ScrollButtons from '../components/form/ScrollButtons';

function ManageRoles(_props) {
  const rolePage = (
    <ScrollButtons horizontalScroll={true}>
      <Typography variant="h2">Roles</Typography>
      <RoleCapabilityTable/>
    </ScrollButtons>
  )
  return (
    <AuthConsumer>
      {({capabilityContextAccess}) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform="MANAGE_ROLE"
          yes={() => (rolePage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageRoles;
