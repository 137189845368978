import { parseISO } from 'date-fns/parseISO';
import { format as dateFnsFormat } from 'date-fns/format';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';
import { set } from 'date-fns/set';
import { SESSION_TIMES as sessionTimesConst } from '../constants/examSessions';

const getSessionTime = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) {
    return '';
  }
  return `${getTimeDisplay(startDateTime)} – ${getTimeDisplay(endDateTime)}`;
}

export const getTimeDisplay = (dateTime) => {
  if(!dateTime) return '';
  const parsedDate = typeof dateTime === 'string' ? parseISO(dateTime) : dateTime;
  if(dateFnsIsValid(parsedDate)) {
    return dateFnsFormat(parsedDate, 'HH:mm');
  }
  return '';
}

export const getSessionStartEndTimes = (selectedDate, selectedSession) => {
  const startFormat = "T" + sessionTimesConst[selectedSession]['startTime'];
  const endFormat = "T" + sessionTimesConst[selectedSession]['endTime'];
  return {
    startDateTime: selectedDate + startFormat,
    endDateTime: selectedDate + endFormat,
  }
};

export const getSessionName = (startTime, SESSION_TIMES) => {
  if (!startTime) {
    return;
  }
  const dateOfStartTime = new Date(parseISO(startTime));
  if(!dateFnsIsValid(dateOfStartTime) || !SESSION_TIMES) {
    return;
  }
  let sessionName;
  for(let session in SESSION_TIMES) {
    const startTimeArr = SESSION_TIMES[session].startTime.split(':');
    const endTimeArr = SESSION_TIMES[session].endTime.split(':');
    const start = set(dateOfStartTime, {hours: startTimeArr[0], minutes: startTimeArr[1], seconds: startTimeArr[2]});
    const end = set(dateOfStartTime, {hours: endTimeArr[0], minutes: endTimeArr[1], seconds: endTimeArr[2]});
    if(dateOfStartTime >= start && dateOfStartTime <= end){
      sessionName = SESSION_TIMES[session].displayName;
      break;
    }
  }
  return sessionName;
};

export default getSessionTime;
