const CAPABILITIES = [
    {
        name: 'Manage user',
        description: 'To add or edit user in the system',
    },
    {
        name: 'Manage context',
        description: 'To add or edit context in the system',
    },
    {
        name: 'Manage role',
        description: 'To add or edit role in the system',
    }
];

export default CAPABILITIES;