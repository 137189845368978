import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import ChatForm from '../form/ChatForm';
import ChatMessage from '../content/ChatMessage';
import GatewayService from '../../services/GatewayService';
import { STUDENT, SUPERVISOR } from '../../constants/chat';

class ChatBox extends React.Component {

  componentDidMount() {
    this.props.resetUnreadMessages();
  }

  ref = React.createRef();

  renderMessage(message, i, ref = undefined) {
    const { text, timestamp, userId: messageUserId, displayName } = message;
    const { userId, authorType } = this.props;

    let fromSelf = false;
    if (userId === messageUserId) {
      fromSelf = true;
    }

    return <ChatMessage
      key={`${timestamp}-${i}`}
      lastElement={ref}
      fromSelf={fromSelf}
      text={text}
      timestamp={timestamp}
      displayName={authorType === STUDENT.authorType ? SUPERVISOR.displayName : displayName}
    />
  }

  render() {
    const { onSend, gatewayService, displayName, authorType, userId, messages, messageBoxHeight } = this.props;
    return (
      <Box sx={{ overflowY: 'auto' }} display="flex" flexGrow="1" flexDirection="column" justifyContent="space-between" height="100%" bgcolor="#f7f7f7">
        <Box
          maxHeight={messageBoxHeight ? messageBoxHeight : '100%'}
          sx={{
            borderStyle: 'solid',
            borderWidth: '0.5px',
            borderColor: '#eef1f2',
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            position: 'relative',
            flex: 'auto',
          }}
        >
          {!isEmpty(messages) && messages.map((message, i) => {
            return this.renderMessage(message, i, (i === (messages.length - 1)) && this.ref)
          })}
        </Box>
        <ChatForm
          onSend={onSend}
          gatewayService={gatewayService}
          displayName={displayName}
          authorType={authorType}
          userId={userId}
          lastElement={this.ref}
        />
      </Box>
    )
  }
}

ChatBox.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  messages: PropTypes.array,
  displayName: PropTypes.string,
  authorType: PropTypes.string,
  userId: PropTypes.string,
  onSend: PropTypes.func,
  messageBoxHeight: PropTypes.string,
};

export default ChatBox;
