const rules = {
  // the mapper value will be the value  of 'name' that is seeded in capabilities table
  VIEW_USER: {
    'mapper': 'View users',
  },
  MANAGE_USER: {
    'mapper': 'Manage user',
  },
  VIEW_AAA: {
    'mapper': 'View AAA users',
  },
  MANAGE_AAA: {
    'mapper': 'Manage AAA users',
  },
  MANAGE_CONTEXT: {
    'mapper': 'Manage context',
  },
  MANAGE_ROLE: {
    'mapper': 'Manage role',
  },
  MANAGE_ROLE_ASSIGNMENT: {
    'mapper': 'Manage role assignment',
  },
  SUPERVISE_EXAM: {
    'mapper': 'Supervise exam',
    'override': (data, contextRequired) => {
        if(!data || contextRequired) return false;
        return data.user?.shiftStaff ? data.user.shiftStaff : false;
      },
  },
  MANAGE_ASSIGNMENTS: {
    'mapper': 'Manage assignments',
  },
  MANAGE_FLAG: {
    'mapper': 'Manage flag',
  },
  SIT_EXAM: {
    'mapper': 'Sit exam',
  },
  MANAGE_DATA: {
    'mapper': 'Manage data',
  },
  REVIEW_EXAM: {
    'mapper': 'Review exam',
  },
  REVIEW_FLAG_IMAGES: {
    'mapper': 'Review flagged images',
  },
  MANAGE_ROOM_SCANS: {
    'mapper': 'Manage room scans',
  },
  MANAGE_RECORDING: {
    'mapper': 'Manage recording',
  },
  VIEW_EXAM: {
    'mapper': 'View exams',
  },
  ONBOARD_EXAM: {
    'mapper': 'Onboard exam',
    'override': (data, contextRequired) => {
        if(!data || contextRequired) return false;
        return data.user?.shiftStaff ? data.user.shiftStaff : false;
      },
  },
  CONFIG_APP: {
    'mapper': 'Config App',
  },
  VIEW_SHIFT: {
    'mapper': 'View shift',
  },
  MANAGE_SHIFT: {
    'mapper': 'Manage shift',
  },
  MANAGE_ALLOCATION_POOLS: {
    'mapper': 'Manage allocation pools',
  },
  MANAGE_EXAM: {
    'mapper': 'Manage exam',
  },
  MANAGE_INTEGRITY: {
    'mapper': 'Manage integrity',
  },
  VIEW_INTEGRITY: {
    'mapper': 'View integrity',
  },
  MANAGE_ONBOARD_FLOW: {
    'mapper': 'Manage onboarding flow',
  },
  VIEW_ONBOARD_FLOW: {
    'mapper': 'View onboarding flow',
  },
  SKIP_BY_ONBOARDER: {
    'mapper': 'Skip onboarding step by onboarder user',
  },
  SKIP_BY_ADMIN: {
    'mapper': 'Skip onboarding step by Admin user',
  },
  SKIP_BY_ANY: {
    'mapper': 'Skip onboarding step by any user',
  },
  COMPLETE_STEP: {
    'mapper': 'Complete an onboarding step',
  },
}

export default rules;
