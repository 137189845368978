import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  MobileStepper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  useMediaQuery
} from '@mui/material';
import { joinContext } from '../context/JoinContext';
import { isDisplayGroupStepComplete } from '../step/helper/isDisplayGroupStepComplete';
import { getActiveGroupedStepIndex } from '../step/helper/getActiveGroupedStepIndex';

function OnboardStepper(props) {
  const { state } = useContext(joinContext);
  const { onboardProgressSteps, currentFlowStepIndex } = state;
  const displaySteps = onboardProgressSteps.filter((progressStep, index) => progressStep.flowStep?.displayGroupStepIndex === index);
  const activeDisplayGroupStepIndex = getActiveGroupedStepIndex(displaySteps, onboardProgressSteps, currentFlowStepIndex);

  const shouldDisplayStepper = !isEmpty(onboardProgressSteps);
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const orientation = props.orientation || 'horizontal';
  const displayBarOnly = !isMd && props.orientation !== 'vertical';

  return (
    <>
      {shouldDisplayStepper && !displayBarOnly &&
        <Stepper activeStep={activeDisplayGroupStepIndex} connector={props.orientation === 'vertical' ? null : <StepConnector/>} orientation={orientation}>
          {displaySteps.map(progressStep => {
            const { onboardStep } = progressStep.flowStep;
            const active = progressStep.flowStep?.displayGroupStepId === onboardProgressSteps[currentFlowStepIndex]?.flowStep?.displayGroupStepId;
            const completed = isDisplayGroupStepComplete(currentFlowStepIndex, onboardProgressSteps, progressStep.flowStep?.onboardStep?.id);
            const label = onboardStep.displayName || onboardStep.name;
            return (
              <Step
                key={`onboard-stepper-${onboardStep.name}`}
                active={active}
                completed={completed}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      }
      {shouldDisplayStepper && displayBarOnly &&
        <MobileStepper
          variant="progress"
          steps={displaySteps.length}
          activeStep={activeDisplayGroupStepIndex}
          position="static"
          sx={{ '.MuiMobileStepper-progress': { width: '100%' } }}
        />
      }
    </>
  );
}

OnboardStepper.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']), // Leave orientation blank for a responsive stepper
};

export default OnboardStepper;
