import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Notice from '../notification/Notice';
import RoomScanService from '../../services/RoomScanService';
import { ROOM_SCAN_STATUS_DESCRIPTION } from '../../constants/roomScanStatuses';
import { buttonLinkStyle } from '../../config/theme';

class CheckRoomScanStatusButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
    }
    this.controller = new AbortController();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  updateRoomScanStatus = (slotId) => {
    const { setRoomScanStatus, onError } = this.props;

    this.setState({
      requestPending: true
    }, async () => {
      try {
        const roomScanData = await RoomScanService.getRoomScanData(slotId, this.controller.signal);
        this.setState({
          hasRequestErrored: false,
          requestPending: false,
          errorMessage: '',
        });
        if (setRoomScanStatus) {
          setRoomScanStatus(roomScanData.hasOwnProperty('roomScanStatus') ? roomScanData.roomScanStatus : 'UNKNOWN');
        }
      } catch(error) {
        if (!error.aborted) {
          this.setState({
            requestPending: false,
            hasRequestErrored: true,
            errorMessage: 'Error: unable to fetch room scan status',
          });
          if (onError) {
            onError('Error: unable to fetch room scan status');
          }
        }
      }
    });
  };

  getRoomScanStatusLabel = (statusCode) => {
    if (!statusCode) {
      return 'Unknown';
    } else if (ROOM_SCAN_STATUS_DESCRIPTION.hasOwnProperty(statusCode)) {
      return ROOM_SCAN_STATUS_DESCRIPTION[statusCode];
    } else {
      return statusCode;
    }
  }

  getRoomScanStatusIcon = (statusLabel) => {
    if (statusLabel !== ROOM_SCAN_STATUS_DESCRIPTION.UPLOADED) {
      return ;
    } else {
      return <CheckRoundedIcon />;
    }
  }

  render() {
    const { slotId, roomScanStatus } = this.props;
    const { requestPending, hasRequestErrored, errorMessage } = this.state;

    const roomScanStatusLabel = this.getRoomScanStatusLabel(roomScanStatus);

    let roomScanButton;
    if (roomScanStatusLabel !== ROOM_SCAN_STATUS_DESCRIPTION.UPLOADED) {
      roomScanButton = <Button
        onClick={() => this.updateRoomScanStatus(slotId)}
        aria-label={"Check for room scan"}
        sx={buttonLinkStyle}
        endIcon={requestPending ? <CircularProgress size="small" /> : null}
      >
        Check room scan{roomScanStatus ? ` (status: ${roomScanStatusLabel})` : null}
      </Button>;
    } else {
      roomScanButton = <Button
        aria-label={"Room scan completed"}
        disabled={true}
        endIcon={<CheckRoundedIcon />}
        sx={buttonLinkStyle}
      >
        Room scan completed
      </Button>;
    }

    return(
      <>
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        {roomScanButton}
      </>
    )
  }
}

CheckRoomScanStatusButton.propTypes = {
  slotId: PropTypes.string.isRequired,
  roomScanStatus: PropTypes.string,
  setRoomScanStatus: PropTypes.func,
  onError: PropTypes.func,
};

export default CheckRoomScanStatusButton;
