import transform from 'sdp-transform';

/**
 * Try to parse IP address received.
 * @param {string} ip IP address to parse.
 * @returns {object} Whether this is v4/v6 address, and whether it is public.
 */
export const parseIP = (ip) => {
  if (ip && typeof ip === 'string') {
    if (ip.indexOf(':') !== -1) return { type: 'v6', public: true };
    if (ip.indexOf('.local') !== -1) return { type: 'mdns', public: false };
    if (ip.indexOf('.')) return { type: 'v4', public: this.isPublicIpv4(ip) };
  }
  return { type: 'unknown', public: false };
};

/**
 * Determine if the given IP is a v4 address.
 * @param {string} ip Ip address to check.
 * @returns {boolean} True if this is a v4 address.
 */
export const isPublicIpv4 = (ip) => {
  const ipParts = ip.split('.');
  switch (ipParts[0]) {
    case 10:
    case 127:
      return false;
    case 172:
      return ipParts[1] <= 16 || ipParts[1] > 32;
    case 192:
      return ipParts[1] !== 168;
    default:
      return true;
  }
};

/**
 * Utility function for analysing SDP messages.
 * @param {object} sdp SDP message entity.
 */
export const analyzeSdp = (sdp) => {
  // For now we just need to parse and log the different pieces. In the future we're going to want
  // to be tracking whether there were TURN candidates and IPv4 candidates to make informed
  // decisions about what to do on fallbacks/reconnects.
  const parsedSDP = transform.parse(sdp);

  const v4Info = {
    found: false,
    public: false,
  };

  const v6Info = {
    found: false,
    public: false,
  };

  const srflxInfo = {
    found: false,
    type: 'not found',
    public: false,
  };

  const prflxInfo = {
    found: false,
    type: 'not found',
    public: false,
  };

  const relayInfo = {
    found: false,
    type: 'not found',
    public: false,
  };

  // Things to parse:
  // Are there any IPv4/IPv6
  // Is there a server reflexive candidate? (srflx) is a public or private IP
  // Is there a relay (TURN) candidate
  parsedSDP.media.forEach((media) => {
    if (media.candidates) {
      media.candidates.forEach((candidate) => {
        const ipInfo = this.parseIP(candidate.ip);
        switch (ipInfo.type) {
          case 'v4':
            v4Info.found = true;
            v4Info.public = v4Info.public || ipInfo.public;
            break;
          case 'v6':
            v6Info.found = true;
            v6Info.public = v6Info.public || ipInfo.public;
            break;
          default:
          // Shouldn't happen
        }

        switch (candidate.type) {
          case 'srflx':
            srflxInfo.found = true;

            if (srflxInfo.type === 'not found') {
              srflxInfo.type = ipInfo.type;
            } else if (srflxInfo.type !== ipInfo.type) {
              srflxInfo.type = 'both';
            }

            srflxInfo.public = srflxInfo.public || ipInfo.public;
            break;
          case 'prflx':
            prflxInfo.found = true;

            if (prflxInfo.type === 'not found') {
              prflxInfo.type = ipInfo.type;
            } else if (prflxInfo.type !== ipInfo.type) {
              prflxInfo.type = 'both';
            }

            prflxInfo.public = prflxInfo.public || ipInfo.public;
            break;
          case 'relay':
            relayInfo.found = true;

            if (relayInfo.type === 'not found') {
              relayInfo.type = ipInfo.type;
            } else if (relayInfo.type !== ipInfo.type) {
              relayInfo.type = 'both';
            }

            relayInfo.public = relayInfo.public || ipInfo.public;
            break;
          default:
          // Shouldn't happen
        }
      });
    }
  });
  return {
    v4Info,
    v6Info,
    srflxInfo,
    prflxInfo,
    relayInfo,
  };
};

/**
 * Strip any ICE candidates with mDNS addresses from a supplied local
 * session description object, returning the modified object with only
 * valid candidates remaining. This is required because FreeSwitch will
 * reject candidates with a '.local' IP.
 * @param {Object} sdp The session description object to be modified
 * @returns Modified session description object
 */
export const stripMDnsCandidates = (sdp) => {
  const parsedSDP = transform.parse(sdp.sdp);
  let strippedCandidates = 0;
  parsedSDP.media.forEach((media) => {
    if (media.candidates) {
      media.candidates = media.candidates.filter((candidate) => {
        if (candidate.ip && candidate.ip.indexOf('.local') === -1) {
          return true;
        }
        strippedCandidates += 1;
        return false;
      });
    }
  });
  if (strippedCandidates > 0) {
    console.debug(`Stripped ${strippedCandidates} mDNS candidates`);
  }
  return { sdp: transform.write(parsedSDP), type: sdp.type };
};

/**
 * If validIceCandidates contains an array of valid ICE candidate objects to allow
 * then modify the session description object to remove all other candidates. If
 * the valid candidates array is empty, the object is returned unmodified.
 * @param {Array} validIceCandidates A list of the valid candidates to whitelist
 * @param {Object} sdp The session description object to be modified
 * @returns Modified session description object
 */
export const filterValidIceCandidates = (validIceCandidates, sdp) => {
  if (!validIceCandidates || !validIceCandidates.length) return sdp;

  const matchCandidatesIp = (candidate, mediaCandidate) => (
    (candidate.address && candidate.address.includes(mediaCandidate.ip))
    || (candidate.relatedAddress
      && candidate.relatedAddress.includes(mediaCandidate.ip))
  );

  const parsedSDP = transform.parse(sdp.sdp);
  let strippedCandidates = 0;
  parsedSDP.media.forEach((media) => {
    if (media.candidates) {
      media.candidates = media.candidates.filter((candidate) => {
        if (candidate.ip
          && candidate.type
          && candidate.transport
          && validIceCandidates.find((c) => (c.protocol === candidate.transport)
            && matchCandidatesIp(c, candidate))
        ) {
          return true;
        }
        strippedCandidates += 1;
        return false;
      });
    }
  });
  if (strippedCandidates > 0) {
    console.debug(`Filtered ${strippedCandidates} invalid candidates from trickle SDP`);
  }
  return { sdp: transform.write(parsedSDP), type: sdp.type };
};

/**
 * Some heuristics to determine if the input SDP is Unified Plan. This will be used
 * by the SIP code in the browser.
 * @param {Object} sdp 
 * @returns Boolean result of whether this sdp matches a unified plan
 */
export const isUnifiedPlan = (sdp) => {
  const parsedSDP = transform.parse(sdp);
  if (parsedSDP.media.length <= 3 && parsedSDP.media.every(m => ['video', 'audio', 'data'].indexOf(m.mid) !== -1)) {
    return false;
  }
  return true;
};