import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import App from '../../App';
import AuthWithBroker from './AuthWithBroker';
import keycloak from '../../constants/keycloak';
import LoggingIn from '../content/LoggingIn'

const OidcWithBroker = () => {

  const setTokens = (tokens: unknown) => {
    tokens?.token && sessionStorage.setItem('token', tokens.token);
    tokens?.refreshToken && sessionStorage.setItem('refreshToken', tokens.refreshToken);
    tokens?.idToken && sessionStorage.setItem('idToken', tokens.idToken);
  };

  //for information only
  const authEventWatcher = (event: unknown, error: unknown) => {
    console.debug('onKeycloakEvent', event, 'error', error);
  };

  const initOptions = {
    onLoad: 'login-required', // this has a silent check-sso method, but then we would have to manually manage the login flow
    pkceMethod: 'S256', // required for pkce
    token: sessionStorage.getItem('token'), // using the tokens from session storage here enable us to not redirect to login on every refresh
    refreshToken: sessionStorage.getItem('refreshToken'),
    idToken: sessionStorage.getItem('idToken'),
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      onEvent={authEventWatcher}
      onTokens={setTokens}
      isLoadingCheck={(keycloak) => { return !keycloak.authenticated }}
      LoadingComponent={<LoggingIn/>}
    >
      <AuthWithBroker>
        <App/>
      </AuthWithBroker>
    </ReactKeycloakProvider>
  )
}

export default OidcWithBroker;
