import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Chip, FormControl, FormControlLabel, FormLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import GreenSwitch from './GreenSwitch';
import Notice from '../notification/Notice';
import Section from '../Section';
import UserService from '../../services/UserService';
import updateUserWithAAASavedStatus from './helper/updateUserWithAAASavedStatus';

const styles = {
  fieldsetContainer: {
    display: 'block',
    margin: '0 auto',
  },
  margin: {
    margin: 1,
  },
  legend: {
    color: 'primary.main',
    margin: 1,
  },
};

class UpdateAAAForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: props.selectedUsers,
      aaaUpdateState: props.selectedUsers[0].isAAA, // match current state to first selected user
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
    };
  }

  controller = new AbortController();

  componentDidMount() {
    this.props.setHasUserUpdated && this.props.setHasUserUpdated(false);
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  updateUsers = async () => {
    const { selectedUsers, setHasUserUpdated, setKeepSelectedUsers } = this.props;
    if (!selectedUsers) { return; }

    setHasUserUpdated(false);
    setKeepSelectedUsers(true);

    const reqBody = this.props.selectedUsers.map((user) => {
      return {id: user.id, isAAA: this.state.aaaUpdateState};
    });

    try {
      const response = await UserService.updateAAAStatuses(reqBody, this.controller.signal);
      const isPartialSuccess = response.some(r => r.status !== 200);
      this.setState({
        userList: updateUserWithAAASavedStatus(response, this.state.userList, this.state.aaaUpdateState),
        hasRequestErrored: false,
        isRequestSuccessful: !isPartialSuccess,
        isPartialSuccess: isPartialSuccess,
        errorMessage: '',
      });
      this.props.setHasUserUpdated(true);
    } catch(error) {
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        errorMessage: error.message,
      });
    }
  };

  updateAAAState = () => {
    this.setState({aaaUpdateState: !this.state.aaaUpdateState});
  };

  render() {
    const {
      userList,
      aaaUpdateState,
      isRequestSuccessful,
      isPartialSuccess,
      hasRequestErrored,
      errorMessage
    } = this.state;

    return (
      <Section>
        <FormControl component="fieldset" sx={styles.fieldsetContainer}>
          <FormLabel component="legend" sx={styles.legend}>Manage AAA status</FormLabel>
          {isRequestSuccessful &&
            <Notice noticeType="success">The users updated successfully</Notice>
          }
          {isPartialSuccess &&
            <Notice noticeType="warning">Unable to update all users. Please try again.</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          <Box maxHeight="40vh" overflow="auto">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>External ID</TableCell>
                    <TableCell>AAA</TableCell>
                    {!isEmpty(userList) && userList[0].status &&
                      <TableCell>Updated</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((user) => {
                    return (
                      <TableRow key={'row' + user.id}>
                        <TableCell>
                          {user.fullName}
                        </TableCell>
                        <TableCell>
                          {user.externalId}
                        </TableCell>
                        <TableCell>
                          {user.isAAA ? <Chip label="AAA" size="small"/> : ''}
                        </TableCell>
                        {user.status &&
                          <TableCell>
                            {user.status}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box pt={3} pb={3} display="flex">
            <Typography variant='body1' sx={styles.margin}>AAA status</Typography>
            <FormControlLabel control={
              <GreenSwitch
                checked={aaaUpdateState}
                onChange={this.updateAAAState}
                name='isDisabled'
                id='isDisabled'
                inputProps={{ 'aria-label': 'mark selected users status as AAA or not AAA' }}
              />} label={aaaUpdateState ? 'AAA' : 'Not AAA'}
            />
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              color='primary'
              variant='contained'
              onClick={this.updateUsers}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

UpdateAAAForm.propTypes = {
  selectedUsers: PropTypes.array.isRequired,
  setHasUserUpdated:PropTypes.func,
  setKeepSelectedUsers: PropTypes.func,
};

export default UpdateAAAForm;
