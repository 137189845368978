import { isEmpty } from 'lodash';
import NetworkService from './NetworkService';
import MOCK from "./mock";
import mockRequest from "../utils/mockRequest";
import mapParams from '../utils/mapParams';

export default class UserService {

  static async getUserWithRoleAssignmentsByUsername(username) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.USERS);
    }
    const response = await NetworkService.get(
      `/user/${username}/role-assignments`
    );
    return await response.json();
  }

  static async getUserByUsername(username) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.USERS);
    }
    const response = await NetworkService.get(
      `/user/${username}/login`
    );
    return await response.json();
  }

  static async getUserById(userId) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.USERS);
    }
    const response = await NetworkService.get(`/user/${userId}`);
    return await response.json();
  }

  static async getUsers(searchTerm, onlyAAA, excludeDisabled, limit, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.USERS);
    }
    let params = {
      q: searchTerm,
      ...(onlyAAA && {includeAAA: onlyAAA}),
      excludeDisabled: excludeDisabled,
      limit: limit,
    };

    const response = await NetworkService.get(
      `/users?${mapParams(params)}`
      , abortSignal);
    const responseStatus = response.status;
    const responseJson = await response.json();
    return {"users" : responseJson, "status" : responseStatus};
  }

  static async saveUser(user) {
    let response;
    if(isEmpty(user.id)) {
      response = await NetworkService.post('/user', user).then(resp => {return resp});
    } else {
      response = await NetworkService.put(`/user/${user.id}`, user).then(resp => {return resp});
    }
    return response;
  }

  static async updateAAAStatuses(reqBody) {
    return await NetworkService.patch('/users', reqBody).then(resp => {return resp});
  }

 }
