import React, { useContext } from 'react';
import { authContext } from '../../authContext';
import { monitoringContext } from '../context/MonitoringContext';
import SuperviseInSession from '../SuperviseInSession';
import MonitoringDefaultMessage from '../content/MonitoringDefaultMessage';
import MonitoringShifts from '../MonitoringShifts';
import { FEATURE_TOGGLES } from "../../constants/featureToggles";

function SuperviseContainer() {
  const { state } = useContext(monitoringContext);
  const { features } = useContext(authContext);

  // very simple for now, there may be more complicated logic when different messages are shown based on scenario
  return (
    <>
      {!state.activeConnection &&
        <>
          {features[FEATURE_TOGGLES.JITA] ? <MonitoringShifts/> : <MonitoringDefaultMessage/>}
        </>
      }
      {/* Be careful about unmounting SuperviseInSession, can behave irratically */}
      <SuperviseInSession/>
    </>
  );
}

export default SuperviseContainer;
