import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import HearingSharpIcon from '@mui/icons-material/HearingSharp';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

class SupervisorMediaControls extends React.Component {

  render() {
    const { sharedMediaStatus, onAudioToggle, onVideoToggle, onMuteOthers, disabled } = this.props;
    return(
      <>
        <Box margin={2}>
          {sharedMediaStatus.audio &&
            <Tooltip title="Mute me">
              <Fab color="primary" aria-label="Mute" onClick={onAudioToggle} disabled={disabled}>
                <MicNoneOutlinedIcon />
              </Fab>
            </Tooltip>
          }
          {!sharedMediaStatus.audio &&
            <Tooltip title="Unmute me">
              <Fab color="secondary" aria-label="Unmute" onClick={onAudioToggle} disabled={disabled}>
                <MicOffOutlinedIcon />
              </Fab>
            </Tooltip>
          }
        </Box>
        <Box margin={2}>
          {!sharedMediaStatus.video &&
            <Tooltip title="Share my video">
              <Fab color="secondary" aria-label="Turn on Video" onClick={onVideoToggle} disabled={disabled}>
                <VideocamOffOutlinedIcon />
              </Fab>
            </Tooltip>
          }
          {sharedMediaStatus.video &&
            <Tooltip title="Stop my video">
              <Fab color="primary" aria-label="Stop Video" onClick={onVideoToggle} disabled={disabled}>
                <VideocamOutlinedIcon />
              </Fab>
            </Tooltip>
          }
        </Box>
        {onMuteOthers &&
          <Fab color="secondary" aria-label="Mute All Others" onClick={onMuteOthers} disabled={disabled}>
            <HearingSharpIcon/>
          </Fab>
        }
      </>
    )
  }
}

SupervisorMediaControls.propTypes = {
  sharedMediaStatus: PropTypes.object.isRequired,
  onAudioToggle: PropTypes.func,
  onVideoToggle: PropTypes.func,
  onMuteOthers: PropTypes.func,
  disabled: PropTypes.bool,
}

export default SupervisorMediaControls;
