import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { format as dateFnsFormat } from 'date-fns/format';
import MUIDataTable from 'mui-datatables';
import { isEmpty, sortBy, without } from 'lodash';
import Notice from '../notification/Notice';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import Section from '../Section';
import ExamSessionService from '../../services/ExamSessionService';
import getExamDetails, { getNoticeTimes, getRoomScanStatus } from '../../utils/getExamDetails';
import getFirstTimeFromNotices from '../../utils/getFirstTimeFromNotices';
import { getSessionStartEndTimes } from '../../utils/getSessionTime';
import { requestStatusIndicatorContainer } from '../../config/theme';
import { EXAM_SESSION_NOTICE_FIELDS, NOTICES_REQUEST_PARAMS } from '../../constants/unresolvedNotices';
import { MSG_404 } from '../../constants/login';

class ExamSessionNoticeTable extends React.Component {

  state = {
    slotList: undefined,
    tableData: undefined,
    isRequestPending: true,
    hasRequestErrored: false,
    errorMessage: '',
  }

  controller = new AbortController();

  componentDidMount() {
    this.loadSlotData();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  loadSlotData = async () => {
    const { date, searchNumber, session } = this.props;
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: '',
    });
    try {
      const sessionTimes = getSessionStartEndTimes(date, session);
      let offsetTime = new Date();
      offsetTime.setMinutes(offsetTime.getMinutes() - searchNumber);
      const searchParams = {
        ...NOTICES_REQUEST_PARAMS,
        examStartDateAfter: sessionTimes.startDateTime,
        examStartDateBefore: sessionTimes.endDateTime,
        hasUnresolvedNoticeBefore: dateFnsFormat(offsetTime, "yyyy-MM-dd'T'HH:mm:ssX"),
      };
      const slots = await ExamSessionService.getExamSessionsForSearchOptions(searchParams, 'unified', this.controller.signal);
      this.setState({
        slotList: slots,
        tableData: this.processData(slots),
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch(error) {
      if(error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            slotList: [],
            isRequestPending: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  getColumns = (tableData) => {
    if(isEmpty(tableData)) { return; }
    return (
      without(Object.keys(EXAM_SESSION_NOTICE_FIELDS).map((heading) => {
        let columnDetail;
        let label = EXAM_SESSION_NOTICE_FIELDS[heading].display;
        let stdOptions = {
          customFilterListOptions: {
            render: v => {
              return `${label}: ${v}`
            }
          }
        };
        switch (heading) {
          case 'id':
            columnDetail = {
              name: heading,
              label: label,
              options: { display: 'excluded', filter: false, download: true, ...stdOptions }
            };
            break;
          case 'supervisorId':
          case 'onboarderId':
          case 'supervisorUsername':
          case 'onboarderUsername':
          case 'roomScanStatus':
            columnDetail = {
              name: heading,
              label: label,
              options: { display: 'false', download: true, ...stdOptions }
            };
            break;
          default:
            columnDetail = {name: heading, label: label, options: stdOptions};
        }
        return columnDetail;
      }), undefined)
    )
  }

  processData = (slotList) => {
    if(isEmpty(slotList)) { return; }
    const sortedSlotList = sortBy(slotList, [(slot) => { return getFirstTimeFromNotices(slot.notices); }]);
    return (
      sortedSlotList.map((slot) => {
        return {...getExamDetails(slot), ...getNoticeTimes(slot), roomScanStatus: getRoomScanStatus(slot)};
      })
    );
  };

  render() {
    const {
      slotList,
      tableData,
      isRequestPending,
      hasRequestErrored,
      errorMessage,
    } = this.state;

    const displayData = !isRequestPending && !hasRequestErrored && slotList.length > 0;

    const columns = this.getColumns(tableData);

    const options = {
      responsive: 'simple',
      filterType: 'multiselect',
      pagination: false,
      print: false,
      selectToolbarPlacement: 'none',
      selectableRows: 'none',
    };

    return(
      <Section sx={{ position: 'relative' }}>
        <Box sx={requestStatusIndicatorContainer}>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </Box>
        {!displayData &&
          <Notice noticeType="notice">There are no slots that match the search criteria</Notice>
        }
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        {displayData &&
          <MUIDataTable
            columns={columns}
            data={tableData}
            options={options}
          />
        }
      </Section>
    )
  }
}

ExamSessionNoticeTable.propTypes = {
  classes: PropTypes.object,
  date: PropTypes.string,
  searchNumber: PropTypes.string,
  session: PropTypes.string,
};

export default ExamSessionNoticeTable;
