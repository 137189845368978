import { has, isEmpty } from "lodash";
import { USER_TYPES } from "../constants/users";

//Retrieving the most recent supervisor - assuming the list is already sorted by the assigned times.
const getLastAssignedUserSorted = (users) => {
    return isEmpty(users) ? undefined : users[0];
}

const mapToUser = (examSlotUser) => {
    if (!has(examSlotUser, 'user')) {
        return examSlotUser;
    }

    let user = examSlotUser.user;
    user.assignedTimestamp = examSlotUser.assignedTimestamp;
    return user;
}

const getExamSlotUserOfType = (examSlotUsers, userType) => {
    let allUsersOfType = examSlotUsers.filter(esu => esu.userType.type === userType);
    return !isEmpty(allUsersOfType) ? allUsersOfType[0] : undefined;
}

/**
 * Gets the student that is assigned to an ExamSlot object. Allows for getting student object regardless of format
 * from API endpoints.
 * @param examSlot
 * @returns {*|undefined} student assigned to the exam slot.
 * @throws Error if it is unable to get the student details.
 */
export const getStudentUser = (examSlot) => {
    //Case 1: Student is in examSlot
    if (has(examSlot, 'student') && examSlot.student !== null) {
        return mapToUser(examSlot.student);
    }

    //Case 2: List of examSlotUsers
    if (has(examSlot, 'examSlotUsers')) {
        const studentESU = getExamSlotUserOfType(examSlot.examSlotUsers, USER_TYPES.STUDENT);
        if (studentESU) {
            return mapToUser(studentESU);
        }
    }

    throw new Error("Unable to retrieve student details from exam slot");
}

/**
 * Gets the supervisor that was most recently assigned to a given Exam Slot. Currently
 * assumes that it is the first supervisor in the list of supervisors.
 * @param examSlot
 * @returns {*|undefined}
 */
export const getLastActiveSupervisor = (examSlot) => {
    //Case 1: Unwrapped examSlotUser
    if (has(examSlot, 'supervisors')) {
        return mapToUser(getLastAssignedUserSorted(examSlot.supervisors));
    }

    //Case 2: List of examSlotUsers
    if (has(examSlot, 'examSlotUsers')) {
        return mapToUser(getExamSlotUserOfType(examSlot.examSlotUsers, USER_TYPES.SUPERVISOR));
    }
    return undefined;
}

export const getLastActiveOnboarder = (examSlot) => {
    //Case 1: Unwrapped examSlotUser
    if (has(examSlot, 'onboarders')) {
        return mapToUser(getLastAssignedUserSorted(examSlot.onboarders));
    }

    //Case 2: List of examSlotUsers
    if (has(examSlot, 'examSlotUsers')) {
        return mapToUser(getExamSlotUserOfType(examSlot.examSlotUsers, USER_TYPES.ONBOARDER));
    }
    return undefined;
}

export const getLastActiveUserByType = (examSlot, userType) =>  {
  let userDetails;
  switch (userType) {
    case USER_TYPES.ONBOARDER:
      userDetails = getLastActiveOnboarder(examSlot);
      break;
    case USER_TYPES.SUPERVISOR:
      userDetails = getLastActiveSupervisor(examSlot);
      break;
    default:
      break;
  }
  return userDetails;
}
