import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { cloneDeep, has, isEmpty } from 'lodash';
import { authContext } from '../../authContext';
import { monitoringContext } from '../context/MonitoringContext';
import handler from './handler/';
import PromiseWithAbort from '../../utils/PromiseWithAbort';
import AudioStreamService from '../../services/AudioStreamService';
import ExamSessionService from '../../services/ExamSessionService';
import GatewayService from '../../services/GatewayService';
import VideoStreamService from '../../services/VideoStreamService';
import { ACTIONS } from '../../constants/monitorSessions';
import { MEDIA_STREAM_CONNECTION_STATES, SCREEN_SHARE_TYPES } from '../../constants/mediaStates';
import { USER_TYPES } from '../../constants/users';

function MeetingConnect(props) {
  const { slotId } = props;
  const { user } = useContext(authContext)
  const { state, dispatch } = useContext(monitoringContext);
  const examSessions = useRef(state.examSessions);
  const latestSlotServices = state[slotId];
  const slotServices = useRef(latestSlotServices);
  const activeConnection = useRef(state.activeConnection);
  const [hasMeetingDetails, setHasMeetingDetails] = useState(false);
  const [isGatewayConnected, setIsGatewayConnected] = useState(false);
  const [readyToConnectStreams, setReadyToConnectStreams] = useState(false);

  /**
   * Wrapper for displaying a recoverable connection error to the user on a failure, that checks whether
   * the component has been unmounted before updating the state. This error can be removed when the connection
   * is restored.
   * @param {string} connectionType The type of connection that failed.
   * @param {string} message The error message to display.
   * @param {boolean} clear True if the error has been resolved.
   */
  const setConnectionError = useCallback((connectionType, message, clear = false) => {
    const thisExamSession = examSessions.current[slotId];
    if (typeof thisExamSession === 'undefined') {
      return;
    }
    if (!clear) {
      thisExamSession.connectionDetails.connectionErrors[connectionType] = message;
    } else if (thisExamSession.connectionDetails.connectionErrors?.[connectionType]) {
      delete thisExamSession.connectionDetails.connectionErrors[connectionType];
    }
    dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
  }, [dispatch, slotId]);

  /**
   * When the gateway connection drops out, display a message to the user and handle any errors.
   * @param {string} reason The reason message for the disconnection.
   */
  const onGatewayDisconnected = useCallback((reason) => {
    setConnectionError('gateway', 'You have been disconnected from your eVigilation session. If this error persists, please reload the page to reconnect.');
    console.error(`Gateway disconnected: ${reason}`);
  }, [setConnectionError]);

  /**
   * When the gateway connection returns after a dropout, clear error message.
   */
  const onGatewayReconnected = useCallback(() => {
    setConnectionError('gateway', '', true);
    console.info('Gateway connected successfully.');
  }, [setConnectionError]);

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their video streams, either starting a new stream or stopping an existing one.
   * @param {string} type Type of change, either 'start' or 'stop'.
   * @param {string} userId The eVig user ID who is sharing their video stream.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   */

  const onRemoteVideoChange = useCallback((type, userId, userChangeData) => {
    const thisExamSession = examSessions.current[slotId];
    // stay in the same handler
    let videoStreamService = slotServices?.current?.videoService;
    if (!videoStreamService) {
      console.debug('onRemoteVideoChange: no video service available.');
      return;
    }

    let updateRequired = false;
    let sessionUpdates = {
      id: slotId,
      studentMedia: {},
    };

    let videoConnection = has(thisExamSession, 'connectionDetails.videoConnection')
        ? thisExamSession['connectionDetails']['videoConnection']
        : MEDIA_STREAM_CONNECTION_STATES.CLOSED;

    if (userId === thisExamSession.student.id) {
      // The student for this session has changed video
      const mediaState = thisExamSession.studentMedia;

      if (type === 'start') {
        const videoStream = userChangeData.video.stream;
        if (mediaState !== undefined && has(mediaState, 'video.stream')) {
          sessionUpdates.studentMedia = {
            video: {
              stream: videoStream,
            },
          };
        } else {
          sessionUpdates.studentMedia.video = userChangeData.video;
        }
        if (videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED) {
          slotId === activeConnection.current && videoStreamService.add(videoStream, false, null);

          if (!sessionUpdates.studentMedia.hasOwnProperty('video')) {
            sessionUpdates.studentMedia.video = {};
          }
          sessionUpdates.studentMedia.video.ready = true;
        }
      } else {
        const oldStream = thisExamSession.studentMedia?.video?.stream;
        if (oldStream) {
          videoStreamService.isDeviceManaged(oldStream) && videoStreamService.remove(oldStream);
        }
        sessionUpdates.studentMedia = {
          video: {
            ready: false,
            stream: null,
          },
        };
      }
      updateRequired = true;
    } else {
      // A supervisor peer has changed their video status
      let meetingPeers = thisExamSession.meetingStateProperties.peers;
      let videoOverride = thisExamSession.meetingStateProperties.videoOverride;
      if (!meetingPeers.hasOwnProperty(userId)) {
        // We need to add the new user sharing their webcam to our list of peers
        sessionUpdates.meetingStateProperties = {
          peers: {
            [userId]: userChangeData,
          },
        };
        updateRequired = true;
      }

      if (type === 'start') {
        // Other supervisor has started streaming to the student
        // We need to switch off our webcam if we were active
        //if (sharedMediaStatus.video) {
        //  this.toggleVideoShare();
        //}

        // Next set the active supervisor video to the one that is now being received
        let overrideActive = false;
        if (videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED && slotId === activeConnection.current) {
          videoStreamService.add(userChangeData.video.stream, false, null);
          overrideActive = true;
        }

        sessionUpdates.meetingStateProperties = {
          videoOverride: {
            peer: userId,
            stream: userChangeData.video.stream,
            ready: overrideActive,
          },
        };
        updateRequired = true;
      } else {
        // Another supervisor is stopping their video
        if (videoOverride.peer === userId) {
          videoStreamService.isDeviceManaged(videoOverride.stream) && videoStreamService.remove(videoOverride.stream);
          sessionUpdates.meetingStateProperties = {
            videoOverride: {
              peer: null,
              stream: null,
              ready: false,
            },
          };
          updateRequired = true;
        }
      }
    }

    if (updateRequired) {
      dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: sessionUpdates});
    }
  }, [slotId, dispatch]);

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their screensharing streams, either starting a new stream or stopping an existing one.
   * @param {string} type Type of change, either 'start' or 'stop'.
   * @param {string} userId The eVig user ID who is sharing their screenshare stream (only if they are connected)
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   */
  const onRemoteScreenshareChange = useCallback((type, _userId, userChangeData) => {
    const thisExamSession = examSessions.current[slotId];

    let sessionUpdates = {
      id: slotId,
    };

    // stay in the same handler
    let videoStreamService = slotServices?.current?.videoService;
    if (!videoStreamService) {
      console.debug('onRemoteScreenshareChange: no video service available.');
      return;
    }

    let videoConnection = has(thisExamSession, 'connectionDetails.videoConnection')
      ? thisExamSession['connectionDetails']['videoConnection']
      : MEDIA_STREAM_CONNECTION_STATES.CLOSED;

    const mediaState = thisExamSession.studentMedia;

    if (type === 'start') {
      const screenShareStream = userChangeData.screenshare?.stream ? userChangeData.screenshare.stream : 'desktop';
      if (mediaState !== undefined && has(mediaState, 'screenshare.stream')) {
        sessionUpdates.studentMedia = {
          screenshare: {
            stream: screenShareStream,
          }
        };
      } else {
        sessionUpdates.studentMedia = userChangeData;
        sessionUpdates.studentMedia.screenshare.stream = screenShareStream;
      }
      if (videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED) {
        slotId === activeConnection.current && videoStreamService.add(screenShareStream, false, null);

        if (!sessionUpdates.hasOwnProperty('studentMedia')) {
          sessionUpdates.studentMedia = {};
        }
        if (!sessionUpdates.studentMedia.hasOwnProperty('screenshare')) {
          sessionUpdates.studentMedia.screenshare = {};
        }
        sessionUpdates.studentMedia.screenshare.ready = true;
      }
    } else {
      const oldStream = mediaState?.screenshare?.stream;
      if (oldStream) {
        videoStreamService.isDeviceManaged(oldStream) && videoStreamService.remove(oldStream);
      }
      sessionUpdates.screenShareType = SCREEN_SHARE_TYPES.NONE.mapper;
      sessionUpdates.studentMedia = {
        screenshare: {
          ready: false,
          stream: null,
        },
      };
    }

    dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: sessionUpdates});
  }, [slotId, dispatch]);


  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their audio state in the call.
   * @param {string} type Type of change, one of 'start', 'stop', 'mute', 'unmute', 'active' or 'inactive'.
   * @param {string} userId The eVig user ID who is sending audio to the meeting.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   */
  const onRemoteAudioChange = useCallback((type, userId, userChangeData) => {
    const thisExamSession = examSessions.current[slotId];
    if (userId === thisExamSession.student.id) {
      // Student for this session has changed audio
      let newMediaState = cloneDeep(thisExamSession.studentMedia);

      switch (type) {
        case 'start':
          newMediaState.audio.ready = true;
          break;
        case 'stop':
          newMediaState.audio.ready = false;
          break;
        case 'mute':
          newMediaState.audio.muted = true;
          break;
        case 'unmute':
          newMediaState.audio.muted = false;
          break;
        case 'active':
          newMediaState.audio.active = true;
          break;
        case 'inactive':
          newMediaState.audio.inactive = false;
          break;
        default:
          return;
      }
      (type !== 'active' && type !== 'inactive') && console.debug('[onRemoteAudioChange]: audio status ', type);
    } else {
      // Supervisor peer has changed audio
      let meetingPeers = thisExamSession.meetingStateProperties.peers;
      if (!meetingPeers.hasOwnProperty(userId)) {
        meetingPeers[userId] = userChangeData;
      }
      switch (type) {
        case 'mute':
          meetingPeers[userId].audio.muted = true;
          break;
        case 'unmute':
          meetingPeers[userId].audio.muted = false;
          break;
        case 'active':
          meetingPeers[userId].audio.active = true;
          break;
        case 'inactive':
          meetingPeers[userId].audio.active = false;
          break;
        default:
          return;
      }
    }

    //Note currently these values are not being used. Hence not storing in the context
    // thisExamSession.studentMedia = newMediaState;
    // dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
  }, [slotId]);

  /**
   * Handle an event received that indicates a remote participant either joined or left.
   * @param {string} type Type of change, either 'add' or 'remove'.
   * @param {string} userId The eVig user ID who joined.
   * @param {object} userChangeData The updated user and media data for this change from the gateway.
   * @param {string} reason The reason for this change
   */

  const onUserChange = useCallback((type, userId, userChangeData, reason) =>  {
    const thisExamSession = examSessions.current[slotId];
    let videoStreamService = slotServices?.current?.videoService;
    let updateRequired = false;
    let sessionUpdates = {
      id: slotId,
    };

    if (userId === thisExamSession.student.id) {
      // Student for this session has come online or left
      const mediaState = thisExamSession.studentMedia;

      console.info(`Change to ${type} meeting participant: ${userId} (${reason})`);

      if (type === 'add') {
        sessionUpdates.connectionDetails = {
          studentOnline: true,
        };
        sessionUpdates.studentMedia = userChangeData;
        updateRequired = true;
      } else {
        // We need to stop any streams that are active if student has quit
        const videoStream = mediaState?.video?.stream;
        const screenStream = mediaState?.screenshare?.stream;
        const audioReady = mediaState?.audio?.ready;
        let updatedStudentMedia = {};
        if (videoStream) {
          videoStreamService.isDeviceManaged(videoStream) && videoStreamService.remove(videoStream);
          updatedStudentMedia['video'] = {
            stream: null,
            ready: false,
          };
        }
        if (screenStream) {
          videoStreamService.isDeviceManaged(screenStream) && videoStreamService.remove(screenStream);
          sessionUpdates.screenShareType = SCREEN_SHARE_TYPES.NONE.mapper;
          updatedStudentMedia['screenshare'] = {
            stream: null,
            ready: false,
          };
        }
        if (audioReady) {
          updatedStudentMedia['audio'] = {
            ready: false,
          };
        }
        if (!isEmpty(updatedStudentMedia)) {
          sessionUpdates['studentMedia'] = updatedStudentMedia;
        }
        sessionUpdates.connectionDetails = {
          studentOnline: false,
        };
        updateRequired = true;
      }
    } else {
      // This is another supervisor who has joined or left
      let meetingPeers = thisExamSession.meetingStateProperties.peers;
      let videoOverride = thisExamSession.meetingStateProperties.videoOverride;
      if (!meetingPeers.hasOwnProperty(userId) && type === 'add') {
        sessionUpdates.meetingStateProperties = {
          meetingPeers: {
            userId: userChangeData,
          },
        };
        updateRequired = true;
      } else if (meetingPeers.hasOwnProperty(userId) && type === 'remove') {
        // Note the userChangeData for remove action will be null
        sessionUpdates.meetingStateProperties = {
          meetingPeers: {
            userId: null,
          },
        };
        updateRequired = true;
      }

      // If the supervisor was sharing video, we need to remove it when they leave
      if (type === 'remove' && videoOverride.peer === userId) {
        // If this is the active session, the video stream should be terminated
        videoStreamService.isDeviceManaged(videoOverride.stream) && videoStreamService.remove(videoOverride.stream);

        // Also remove this override video stream
        updateRequired = true;
        sessionUpdates.meetingStateProperties = {
          videoOverride: {
            peer: null,
            stream: null,
            ready: false,
          },
        };
      }
    }
    if (updateRequired) {
      dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: sessionUpdates});
    }
  }, [dispatch, slotId]);

 /**
 * Handle an event received that indicates a chat message has been sent.
 * @param {object} messageData The author, time and message content
 */
  const onReceiveMessage = useCallback((messageData) => {
    if (messageData.type === 'MESSAGE' && has(messageData, 'text')) {
      const messageProps = {
        text: messageData.text,
        displayName: messageData.author,
        timestamp: messageData.timestamp,
        userId: messageData.userId,
        fromSelf: user.id === messageData.userId,
        read: messageData.read,
      }

      dispatch({type: ACTIONS.ADD_CHAT_MESSAGE, id: slotId, value: messageProps});
    }
  }, [dispatch, slotId, user.id]);

  /**
  * Handle an event received from the gateway indicating the meeting has been terminated or ended
  * @param {object} updatedMeetingData The updated meeting information received from the gateway.
  * @param {string} reason Any reason for the meeting shutdown.
  */
  const onMeetingTerminate = useCallback((type) => {
    let videoStreamService = slotServices.current.videoService;
    let audioStreamService = slotServices.current.audioService;
    let gatewayConnection = slotServices.current.gatewayService;

    if (videoStreamService) {
      videoStreamService.unregisterDeviceHandlers('all');
      videoStreamService.close('video');
      videoStreamService.close('screenshare');
    }
    if (audioStreamService) {
      audioStreamService.unregisterHandlers();
      audioStreamService.exitAudio();
    }
    gatewayConnection.unregisterHandler('all');
    gatewayConnection.close();

    let message = type === 'end'
      ? 'This eVigilation session has been ended. Either rejoin or close this session. The student has also been disconnected.'
      : 'This evigilation session has been opened elsewhere. Please close this session.'

    setConnectionError('gateway', message);
  }, [setConnectionError]);

  // Use effect to get the current exam session from the state
  useEffect(() => {
    examSessions.current = state.examSessions;
  }, [state.examSessions]);

  useEffect(() => {
    slotServices.current = latestSlotServices;
  }, [slotId, latestSlotServices]);

  useEffect(() => {
    const previousActiveConnection = activeConnection.current;
    activeConnection.current = state.activeConnection;

    if (previousActiveConnection !== activeConnection.current) {
      // If active student session has changed, adjust media streams
      const asyncAbort = new PromiseWithAbort();
      const thisExamSession = examSessions.current[slotId];
      const videoConnection = thisExamSession?.connectionDetails?.videoConnection;
      const localStreamName = thisExamSession?.connectionDetails?.connectionProps?.userId;
      const videoStreamService = slotServices.current?.videoService;
      const studentMedia = thisExamSession?.studentMedia;

      if (slotId === previousActiveConnection && slotId !== activeConnection.current) {
        // Disconnect webcam/screenshare streams if necessary since we are no longer active
        if (videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED && videoStreamService) {
          // stop devices and remove management of streams
          if (localStreamName && videoStreamService.isDeviceManaged(localStreamName)) {
            videoStreamService.remove(localStreamName, true);
          }
          studentMedia?.video?.stream && videoStreamService.remove(studentMedia?.video?.stream, true);
          studentMedia?.screenshare?.stream && videoStreamService.remove(studentMedia?.screenshare?.stream, true);

          // the removes have just stopped the devices, so don't stop the devices again.
          // Just remove handlers and close the sockets
          const webcamStreamClose = asyncAbort.wrap(videoStreamService.close('video', false, true));
          const screenshareStreamClose = asyncAbort.wrap(videoStreamService.close('screenshare', false, true));
          Promise.all([webcamStreamClose, screenshareStreamClose])
            .then(() => {
              const examSessionUpdate = {
                id: slotId,
                connectionDetails: {
                  videoConnection: MEDIA_STREAM_CONNECTION_STATES.CLOSED,
                },
              };
              dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: examSessionUpdate});
            })
            .catch((err) => {
              console.error(`Failed to close video stream connection to BBB host for slot [${slotId}]`, err);
            });
        }
      }
      if (slotId === activeConnection.current) {
        // if we need to connect the streams, ensure there's a rerender so the details are not stale
        setReadyToConnectStreams(true);
      }
    }
  }, [state.activeConnection, slotId, dispatch]);

  useEffect(() => {
    if (readyToConnectStreams) {
      const asyncAbort = new PromiseWithAbort();
      const thisExamSession = examSessions.current[slotId];
      const videoConnection = thisExamSession?.connectionDetails?.videoConnection;
      const connectionProps = thisExamSession?.connectionDetails?.connectionProps;
      const videoStreamService = slotServices.current?.videoService;

      // Connect webcam/screenshare streams
      if (videoConnection === MEDIA_STREAM_CONNECTION_STATES.CLOSED && videoStreamService) {
        if (!thisExamSession.studentMedia?.screenshare?.stream) {
          // There's no desktop stream, so make 100% sure there's not a desktop stream being managed.
          // This can occur if a remove happens after a close
          videoStreamService.removeNonRunningDeviceFromQueue('desktop');
        }
        const webcamStreamConnect = asyncAbort.wrap(videoStreamService.open(connectionProps,'video'));
        const screenshareStreamConnect = asyncAbort.wrap(videoStreamService.open(connectionProps, 'screenshare'));
        Promise.all([webcamStreamConnect, screenshareStreamConnect])
          .then(() => {
            let studentMediaChanges = {};

            const videoMedia = thisExamSession.studentMedia.video;
            if (videoMedia.stream) {
              if (!videoStreamService.isDeviceManaged(videoMedia.stream)) {
                videoStreamService.add(videoMedia.stream, false, null);
              }
              studentMediaChanges.video = {
                ready: true,
              };
            }

            const screenMedia = thisExamSession.studentMedia.screenshare;
            if (screenMedia.stream) {
              if (!videoStreamService.isDeviceManaged('desktop')) {
                videoStreamService.add('desktop', false, null);
              }
              studentMediaChanges.screenshare = {
                ready: true,
              };
            }

            let examSessionUpdate = {
              id: slotId,
              connectionDetails: {
                videoConnection: MEDIA_STREAM_CONNECTION_STATES.CONNECTED,
              }
            };
            if (studentMediaChanges) {
              examSessionUpdate.studentMedia = studentMediaChanges;
            }
            dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: examSessionUpdate});
            setReadyToConnectStreams(false);
          })
          .catch((err) => {
            console.error(`Failed to open video stream connection to BBB host for slot [${slotId}]`, err);
          });
      }
    }
  }, [readyToConnectStreams, slotId, dispatch]);


  // Use effect to get the meeting details
  // Will be triggered only if hasMeetingDetails is false
  // sets ['connectionDetails']['connectionProps']
  useEffect(() => {
    const getMeetingDetails = async () => {
      const controller = new AbortController();

      try {
        if (!user) {
          throw new Error('No logged in user yet');
        }
        // Obtain meeting details via API calls
        const meetingDetails = await ExamSessionService.joinMeeting(
          slotId,
          controller.signal
        );

        if (controller.signal.aborted) {
          return;
        }

        setMeetingDetailsInContext({
          connectionProps: meetingDetails,
        });
        setHasMeetingDetails(true);
      } catch (error) {
        // Could not obtain meeting details.
        console.error(`[joinMeeting error]: Could not get meeting details for slotId ${slotId}`, {error});
        setMeetingDetailsInContext({
          requestError: "Could not connect to eVigilation session. Please close the page and try again.",
        });
        setHasMeetingDetails(true);
      }
    };

    const setMeetingDetailsInContext = (connectionDetails) => {
      let sessionToUpdate = examSessions.current[slotId];
      sessionToUpdate['connectionDetails'] = { ...sessionToUpdate.connectionDetails, ...connectionDetails };
      dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: sessionToUpdate});
    };

    !hasMeetingDetails && getMeetingDetails();
  }, [slotId, user, hasMeetingDetails, dispatch]);

  // Use effect to initialise the video stream service and open
  // Will be triggered only if hasMeetingDetails is true and meeting details is not null
  // sets ['videoConnectionDetails'] and ['studentMedia']
  useEffect(() => {
    const asyncAbort = new PromiseWithAbort();

    const addStudentMedia = (thisExamSession, videoStreamService) => {
      // Start video if we've already received a gateway notification
      let newMediaState = cloneDeep(thisExamSession.studentMedia);
      let videoConnection = has(thisExamSession, 'connectionDetails.videoConnection')
      ? thisExamSession['connectionDetails']['videoConnection']
      : MEDIA_STREAM_CONNECTION_STATES.CLOSED;

      if (newMediaState !== undefined) {
        if (
          newMediaState.video.stream 
          && slotId === activeConnection.current 
          && videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED
        ) {
          videoStreamService.add(newMediaState.video.stream, false, null);
          newMediaState.video.ready = true;
        }
        if (
          newMediaState.screenshare.stream
          && slotId === activeConnection.current
          && videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED
        ) {
          videoStreamService.add(newMediaState.screenshare.stream, false, null);
          newMediaState.screenshare.ready = true;
        }
      }
      return newMediaState;
    };

    const initPeerSupervisorVideo = (thisExamSession, videoStreamService) => {
      // Start video for peer supervisor if we've already received a gateway notification
      let newMeetingProperties = cloneDeep(thisExamSession.meetingStateProperties);
      let videoConnection = has(thisExamSession, 'connectionDetails.videoConnection')
      ? thisExamSession['connectionDetails']['videoConnection']
      : MEDIA_STREAM_CONNECTION_STATES.CLOSED;

      if (
        newMeetingProperties?.videoOverride?.stream 
        && !newMeetingProperties?.videoOverride?.ready
        && videoConnection === MEDIA_STREAM_CONNECTION_STATES.CONNECTED
      ) {
        videoStreamService.add(newMeetingProperties.videoOverride.stream, false, null);
        newMeetingProperties.videoOverride.ready = true;
      }
      return newMeetingProperties;
    };

    const initialiseStreams = () => {
      let thisExamSession = examSessions.current[slotId];
      const meetingDetails = has(thisExamSession, 'connectionDetails.connectionProps')
      ? thisExamSession.connectionDetails.connectionProps : undefined;

      if(meetingDetails !== undefined) {
        let videoStreamService = new VideoStreamService();

        try {
          let videoSettings = {
            streamId: meetingDetails.userId,
          };
          let studentMedia = addStudentMedia(thisExamSession, videoStreamService);
          let meetingStateProperties = initPeerSupervisorVideo(thisExamSession, videoStreamService);
          const videoProps = {
            videoConnectionDetails: {...thisExamSession.videoConnectionDetails, ...videoSettings},
            studentMedia: {...thisExamSession.studentMedia, ...studentMedia},
            meetingStateProperties: {...thisExamSession.meetingStateProperties, ...meetingStateProperties},
          };
          initialiseGateway(videoProps, videoStreamService);
        } catch (err) {
          console.error("video stream initialisation failed", err);
          const error =  {
            requestError: "Could not connect to eVigilation session. Please close the page and try again.",
          }
          //Note: Not doing a logToGateway as the gateway service may not be initialised at this point.
          thisExamSession['connectionDetails'] = {...thisExamSession.connectionDetails, ...error};
          dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
        }
      }
    };

    const initialiseGateway = (videoProps, videoStreamService) => {
      let thisExamSession = examSessions.current[slotId];
      const meetingDetails = has(thisExamSession, 'connectionDetails.connectionProps')
        ? thisExamSession.connectionDetails.connectionProps : undefined;

      const onScreenShareTypeHandler =
        handler.onScreenTypeUpdate(dispatch, slotId, USER_TYPES.SUPERVISOR)

      if(meetingDetails !== undefined) {
        // Assuming we have successfully joined, open websocket session.
        let gatewayConnection = new GatewayService();
        console.debug(`Attaching gateway event handlers for slot [${slotId}]`)
        // Tell the supervisor if we are unexpectedly disconnected
        gatewayConnection.registerDisconnectionHandler(onGatewayDisconnected);
        // // Clear the gateway error if the supervisor is successfully reconnected
        gatewayConnection.registerConnectionHandler(onGatewayReconnected);
        // Show or remove media elements from other users
        gatewayConnection.registerMediaChangeEvent('video',
          (type, userId, userChangeData) => {
            onRemoteVideoChange(type, userId, userChangeData)
          }
        );
        gatewayConnection.registerMediaChangeEvent('audio',
          (type, userId, userChangeData) => onRemoteAudioChange(type, userId, userChangeData)
        );
        gatewayConnection.registerMediaChangeEvent('screenshare',
          (type, userId, userChangeData) => {
            onRemoteScreenshareChange(type, userId, userChangeData)
          }
        );
        // When the gateway sends a terminate message, smoothly disconnect the current session (we can reuse the meeting
        // end function since we just need to close all things down).
        gatewayConnection.registerMeetingChangeEvent('terminated', () => onMeetingTerminate('terminated'));
        // Shut everything down when the meeting is closed on BBB
        gatewayConnection.registerMeetingChangeEvent('end', () => onMeetingTerminate('end'));
        gatewayConnection.registerReceiveNoticeEvent((data) => {
          dispatch({type: ACTIONS.ADD_NOTICE, value: {slotId: data.slotId, notice: data}});
        });
        // When another supervisor clears notices, update this dashboard accordingly
        gatewayConnection.registerResolveNoticesEvent((types, timestamp) => {
          dispatch({type: ACTIONS.CLEAR_NOTICES, value: {id: slotId, types, timestamp}});
        });
        // Update the state when another user joins or leaves
        gatewayConnection.registerParticipantChangeEvent('add',
          (userId, userData, reason) => {
            onUserChange('add', userId, userData, reason)
          }
        );
        gatewayConnection.registerParticipantChangeEvent('remove',
          (userId, userData, reason) => {
            onUserChange('remove', userId, userData, reason)
          }
        );
        gatewayConnection.registerChatNotificationEvent(onReceiveMessage);
        gatewayConnection.registerScreenTypeUpdateEvent(onScreenShareTypeHandler);

        asyncAbort.wrap(gatewayConnection.connect(user.id, slotId, meetingDetails))
          .then(() => {
            console.debug(`Gateway connection is complete for ${slotId}`);
            const gatewayProps = {
              connectionDetails: {
                ...thisExamSession.connectionDetails,
                statusMessage: 'gatewayConnected',
                connectionSetUpComplete: true,
                videoConnection: MEDIA_STREAM_CONNECTION_STATES.CLOSED,
                connectionErrors: {},
              },
            };

            const services = {
              gatewayService: gatewayConnection,
              videoService: videoStreamService,
              audioService: new AudioStreamService(),
            };
            flushSync(() => {
              dispatch({type: ACTIONS.SET_SERVICES, value: {examSlot: slotId, services: services}});
              thisExamSession = {...thisExamSession,...videoProps,...gatewayProps};
              dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
              setIsGatewayConnected(true);
            });

          }).catch((err) => {
          console.error(`Could not connect to gateway for slotId ${slotId}`, {err});
          const error =  {
            requestError: "Could not connect to eVigilation session. Please try to reconnect to this session.",
          }
          thisExamSession['connectionDetails'] = {...thisExamSession.connectionDetails, ...error};
          dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
        });
      }
    };

    if(hasMeetingDetails && !isGatewayConnected) {
      initialiseStreams();
    }
  },
    [ hasMeetingDetails, isGatewayConnected,
      slotId, user, dispatch,
      onGatewayDisconnected, onGatewayReconnected,
      onRemoteScreenshareChange, onRemoteVideoChange, onUserChange, onRemoteAudioChange,
      onReceiveMessage, onMeetingTerminate
    ]
  );

  return null;
}

export default MeetingConnect;
