import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { without } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { getUserOptions } from '../../utils/userSearchHelper';

function UserLookup(props) {
  const {
    dataProps,
    defaultUserValue,
    errored,
    fieldAriaLabel,
    fieldLabel,
    fieldName,
    groupId,
    inputDataProps,
    isDisabled,
    multiple,
    required,
    setUserValue,
    setUsersToUpdate,
    sx,
    userValue,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [multiUserValue, setMultiUserValue] = useState(defaultUserValue)

  useEffect(() => {
    const getAsyncUserOptions = async (userSearchText) => {
      setIsLoading(true);
      getUserOptions(userSearchText, (userOptions) => {
        setOptions(userOptions);
        setIsLoading(false);
      });
    };
    getAsyncUserOptions(inputValue);
  }, [inputValue]);

  const updateValue = (action, details) => {
    setUserValue(fieldName, details ? [details] : null);
    if (action === 'clear') {
      setOptions([]);
    }
  };

  const updateMultiValues = (action, details) => {
    switch (action) {
      case 'clear':
        setMultiUserValue([]);
        setOptions([]);
        break;
      case 'selectOption':
        setMultiUserValue([...multiUserValue, details]);
        break;
      case 'removeOption':
        setMultiUserValue(without(multiUserValue, details));
        break;
      default:
        //unknown option, do nothing
        break;
    }
    setUsersToUpdate(fieldName, details, action, groupId);
  }

  return (
    <Autocomplete
      {...dataProps}

      open={menuOpen}
      onOpen={() => { setMenuOpen(true); }}
      onClose={() => { setMenuOpen(false); }}
      onChange={(_event, _newValue, reason, details) => {
        multiple ? updateMultiValues(reason, details?.option) : updateValue(reason, details?.option);
      }}
      onInputChange={(event, input) => {
        event?.type === 'change' && setInputValue(input)
      }}
      filterOptions={(x) => x}
      getOptionDisabled={(option) => option.isDisabled }
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => {
        // don't error if empty
        return value === '' || value === null || option?.value === value?.value
      }}

      disabled={isDisabled}
      filterSelectedOptions={multiple}
      loading={false}
      multiple={multiple}
      noOptionsText="No matches found"
      options={[...options, ...(multiUserValue || [])]}
      value={multiple ? multiUserValue : userValue?.[0] || ''}

      sx={sx}
      ChipProps={{ size: 'small' }}
      ListboxProps={{sx: { 'li[aria-disabled="true"]': { color: 'warning.main', opacity: '1 !important' }}}}
      renderInput={(params) => (
        <TextField
          {...params}
          aria-label={fieldAriaLabel}
          error={errored}
          label={fieldLabel || 'Select user'}
          name={fieldName || 'userLookup' }
          required={required}
          sx={{ backgroundColor: 'common.white' }}
          inputProps={{
            ...params.inputProps,
            ...inputDataProps,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

UserLookup.propTypes = {
  dataProps: PropTypes.object,
  defaultUserValue: PropTypes.array,
  errored: PropTypes.bool,
  fieldAriaLabel: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  inputDataProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  roleId: PropTypes.string,
  setUserValue: PropTypes.func,
  setUsersToUpdate: PropTypes.func,
  sx: PropTypes.object,
  userValue: PropTypes.array,
};

export default UserLookup;
