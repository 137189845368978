export const SHARED_MEDIA_STATES = {
  DISABLED: 'disabled',
  CONNECTING: 'connecting',
  CONNECTED: 'on',
  DISCONNECTED: 'off',
  RECONNECTING: 'reconnecting',
};

export const MEDIA_STREAM_CONNECTION_STATES = {
  CLOSED: 'closed',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  RECONNECTING: 'reconnecting',
}

export const SHARED_MEDIA_TYPES = {
  AUDIO: 'audio',
  DESKTOP: 'desktop',
  WEBCAM: 'webcam',
};

export const AUDIO_MEDIA_EVENTS = {
  START: 'start',
  STOP: 'stop',
  MUTE: 'mute',
  UNMUTE: 'unmute',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const VIDEO_MEDIA_EVENTS = {
  START: 'start',
  STOP: 'stop',
};

export const FULL_SCREEN_BROWSER_MAPPERS = {
  chrome: 'monitor',
  firefox: 'Primary Monitor',
};

export const SCREEN_SHARE_TYPES = {
  PARTIAL: {
    color: 'warning.main',
    display: 'partial screen',
    mapper: 'PARTIAL',
  },
  FULL: {
    color: 'success.main',
    display: 'full screen',
    mapper: 'FULL',
  },
  NONE: {
    color: 'grey.400',
    display: 'no screen',
    mapper: 'NONE',
  },
};
