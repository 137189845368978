import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import { isEmpty } from 'lodash'
import mapParams, { mapUrlParams } from '../utils/mapParams';

export default class ContextService {

  static async getContexts(params, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.CONTEXTS);
    }
    const response = await NetworkService.get(
     `/context?${mapUrlParams(params)}`, abortSignal);
    return await response.json();
  }

  static async getContextAssignedRoles(params, abortSignal) {
   if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
        return mockRequest(MOCK.ASSIGNED_ROLES);
   }
    const response = await NetworkService.get(
        `/assigned-roles?${mapParams(params)}`
        , abortSignal);
    return await response.json();
  }

  static async saveContext(context) {
    let response;
    if(isEmpty(context.id)) {
      response = await NetworkService.post('/context', context).then(resp => {return resp});
    } else {
      response = await NetworkService.put(`/context/${context.id}`, context).then(resp => {return resp});
    }
    return response;
  }

  static async updateAssignedRolesToContext(assignments) {
    return await NetworkService.put( '/role-assignments', assignments).then(resp => {return resp});
  }

  static async updateContextExamDeletionDate(contextId, deletionDate, abortSignal) {
    const deletionDateObj = { examDeletionDate: deletionDate }
    return NetworkService.patch(`/context/${contextId}/integrity`, deletionDateObj, abortSignal).then(resp => {return resp});
  }
}
