import { formatErrorForDisplay } from '../../../utils/formatErrorForDisplay';
import { CONFERENCE_ERROR_CATEGORIES } from '../../../constants/errors';

const onGatewayDisconnected = (setConnectionError, setGatewayConnectionOpen) => {
  /**
   * When the gateway connection drops out, display a message to the user and handle any errors.
   * @param {string} reason The reason message for the disconnection.
   */
  return (reason) => {
    setConnectionError(
      CONFERENCE_ERROR_CATEGORIES.CONNECT,
      formatErrorForDisplay(reason, CONFERENCE_ERROR_CATEGORIES.CONNECT, 'gatewayDisconnection'),
      false);
    setGatewayConnectionOpen(false);
    console.error(`Gateway disconnected: ${reason}`);
  };
};

export default onGatewayDisconnected;