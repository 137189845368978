import { isEmpty } from 'lodash';

export function addRoleAssignmentFromDashboardNotificationToCapabilityContextAccessList(capabilityContextAccess, roleAssignment) {
  const capabilityAccessFromRoleAssignment = roleAssignment?.capabilities?.reduce((result, capability) => {
    if (isEmpty(capabilityContextAccess[capability.key])) {
      return {
        ...result,
        [capability.key]: {
          capability: capability.name,
          contexts: [roleAssignment.contextId]
        }
      }
    }

    return {
      ...result,
      [capability.key]: {
        ...capabilityContextAccess[capability.key],
        contexts: [...capabilityContextAccess[capability.key].contexts, roleAssignment.contextId]
      }
    }
  }, {});

  return { ...capabilityContextAccess, ...capabilityAccessFromRoleAssignment };
}
