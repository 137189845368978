import React from 'react';
import { Box, Button, Checkbox, FormControlLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { findIndex } from 'lodash';
import Section from '../Section';
import RoleForm from '../form/RoleForm';
import AddButton from '../form/AddButton';
import InfoPopover from '../popup/InfoPopover';
import FormPopup from '../popup/FormPopup';
import CapabilityService from '../../services/CapabilityService';
import RoleCapabilityService from '../../services/RoleCapabilityService';
import RoleService from '../../services/RoleService';
import { buttonLinkStyle } from '../../config/theme';
import getCapabilitySelectedStatus from './helper/getCapabilitySelectedStatus';
import getDefaultCheckedCapabilities from './helper/getDefaultCheckedCapabilities';

const styles = {
  margin: {
    margin: 1,
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
};

class RoleCapabilityTable extends React.Component {
  controller = new AbortController();

  constructor(props) {
    super(props);
    this.state = {
      rolesList: [],
      capabilitiesList: [],
      roleCapabilitiesList: [],
      selectedRoleCapabilities: [],
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
      validationNotices: '',
      popupOpen: false,
      editRoleValues: undefined
    }
  }

  componentDidMount() {
    this.getRolesAndCapabilities();
  }

  getRolesAndCapabilities = async () => {
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: '',
    });
    try {
      let [capabilities, roles, roleCapabilities] = await Promise.all([
        CapabilityService.getCapabilities(this.controller.signal),
        RoleService.getRoles(this.controller.signal),
        RoleCapabilityService.getRoleCapabilities(this.controller.signal)
      ]);

      this.setState({
        capabilitiesList: capabilities,
        rolesList: roles,
        roleCapabilitiesList: roleCapabilities,
        selectedRoleCapabilities: getDefaultCheckedCapabilities(roles, capabilities, roleCapabilities),
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
        });
      }
    }
  };

  setHasRoleUpdated = (hasUpdated) => {
    if (hasUpdated) {
      this.getRolesAndCapabilities();
    }
  };

  saveRoleCapabilities = async () => {
    try {
      await RoleCapabilityService.saveRolesAndCapabilities(this.state.selectedRoleCapabilities);
      this.setState({
        hasRequestErrored: false,
        isRequestSuccessful: true,
        errorMessage: '',
      });
    } catch (error) {
      console.log("error" , error)
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        errorMessage: error.message,
      });
    }
  };

  handleEditClick = (editRoleValues) => {
    this.setState({
      editRoleValues
    }, () => {
      this.openPopup()
    });
  }

  handleChange = (event, roleId, capabilityId, selections) => {
    let index = findIndex(selections, {'roleId': roleId, 'capabilityId': capabilityId});
    selections[index].isSelected = event.target.checked;
    this.setState({selectedRoleCapabilities: selections})
  }

  cancelRoleCapabilities = () => {
    this.getRolesAndCapabilities();
  }

  openPopup = () => {
    this.setState({popupOpen: true});
  };

  closePopup = () => {
    this.setState({popupOpen: false});
  };

  render() {
    const {rolesList, capabilitiesList, selectedRoleCapabilities, editRoleValues, popupOpen} = this.state;
    const editRoleForm = <RoleForm role={editRoleValues} setHasRoleUpdated={this.setHasRoleUpdated}/>;
    return (
      <Section sx={styles.tableWrapper}>
        <Box display='flex' justifyContent='flex-end' flexDirection='row-reverse'>
          <Box m={1}>
            <AddButton
              title="Add role"
              heading="Add role"
              content={<RoleForm setHasRoleUpdated={this.setHasRoleUpdated}/>}
            />
          </Box>
          <Box mr={1}>
            <TableContainer sx={styles.margin}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <>
                      <TableCell></TableCell>
                      {rolesList.map((role) => {
                        return <TableCell key={role['id']}>
                          <Box display='flex' alignItems='flex-start'>{role['name']}
                            <InfoPopover popoverText={role['description']}
                              content={
                               <Button
                                 aria-label={`Edit record for ${role['name']}`}
                                 onClick={(_e) => this.handleEditClick(role)}
                                 sx={buttonLinkStyle}
                               >Edit</Button>
                              }
                            />
                          </Box>
                        </TableCell>
                      })}
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {capabilitiesList.map((capability) => {
                    return (
                      <TableRow key={capability['id']}>
                        <TableCell key={capability['id']}>{capability['name']}</TableCell>
                        {rolesList.map((role) => {
                          const checked = getCapabilitySelectedStatus(role['id'], capability['id'], selectedRoleCapabilities);
                          return <TableCell key={capability['id'] + role['id']}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  name={role['name']}
                                  inputProps={{'aria-label': `Assign access to ${capability['name']} for the ${role['name']} role`}}
                                  onChange={(e) => this.handleChange(e, role['id'], capability['id'], selectedRoleCapabilities)}
                                />
                              }
                              label={checked ? 'On' : 'Off'}
                              key={capability['id'] + role['id'] + role['name']}
                            />
                          </TableCell>
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display='flex' justifyContent='space-between' flexDirection='row-reverse'>
              <Button
                sx={styles.margin}
                color='primary'
                variant='contained'
                onClick={this.saveRoleCapabilities}>Save
              </Button>
              <Button
                sx={styles.margin}
                onClick={(_e) => this.cancelRoleCapabilities()}
              >Cancel
              </Button>
            </Box>
          </Box>
        </Box>
        <FormPopup
          title='Edit role account'
          open={popupOpen}
          onClose={this.closePopup}
          content={editRoleForm}
        />
      </Section>
    );
  }
}

export default RoleCapabilityTable;
