export const EVIG_FILE_TYPE = {
  USER_AAA: {
    display: 'AAA users',
    path: '/users/aaa-csv',
    requestType: 'patch',
    badRequestText: 'There was an error reading the file. Please check the fields are correct and try again.',
    partialSuccessText: 'Not all students were updated successfully. Please check the following IDs and try again',
    allowedFileTypes: 'text/csv',
  },
  SHIFTS: {
    display: 'Sessions',
    path: '/shifts/csv',
    requestType: 'put',
    badRequestText: 'There was an error reading the file. Please check the fields are correct and try again.',
    partialSuccessText: 'Not all sessions were added successfully.',
    warningText: 'Please note, session data for selected date will be cleared and replaced with new uploaded data.',
    allowedFileTypes: 'text/csv',
  },
};
