import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet, useLocation } from 'react-router-dom';
import LoggingIn from '../content/LoggingIn';

function OktaSecureRoute(_props) {
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = process.env.REACT_APP_APPCONFIG_FRONTEND_URL + location.pathname;
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [authState, location.pathname, oktaAuth]);

  return (!authState?.isAuthenticated) ? <LoggingIn /> : <Outlet />
}

export default OktaSecureRoute;