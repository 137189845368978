import SETTINGS from '../config/supervisorHandlerSettings';
import { MESSAGE_TYPES } from '../constants/supervisorHandler';

export default class SupervisorHandlerService {

  /**
   * Constructor initialises the common class properties.
   */
  constructor() {

    // Websocket object
    this.ws = null;
    this.wsUrl = null;
    this.wsQueue = [];
    this.socketOpen = false;

    // Function to retrieve a valid access token
    this.getAccessToken = () => {return ""};

    // Bind handler methods for websocket communication
    this.onWsOpen = this.onWsOpen.bind(this);
    this.onWsClose = this.onWsClose.bind(this);
    this.onWsMessage = this.onWsMessage.bind(this);

    // Resolvers that can be set to trigger when the connection opens/closes
    this.wsOpenResolver = null;
    this.wsCloseResolver = null;

    // Callback handlers that can be set to trigger on events received
    this.callbacks = {
      
      // Assignment or unassignment message has been received
      onAssignmentChange: [],
      
      // Exam state has changed (to unlocked, locked or submitted)
      onExamStateChange: [],
      onRoleAssignmentChange: [],

      // Socket has disconnected
      onDisconnection: [],

      // Socket has connected successfully
      onConnection: [],
    };
  }

  /**
   * Open the websocket and try to connect for updates.
   * @param {string} websocketUrl The wss:// url to use for the supervisor handler connection
   * @param {func} getAccessToken A function which returns a valid access token
   */
   connect(websocketUrl, getAccessToken) {
    return new Promise(async (resolve, reject) => {
      this.wsOpenResolver = resolve;
      this.wsCloseResolver = reject;
      this.wsUrl = websocketUrl;
      this.getAccessToken = getAccessToken;

      // Open a new websocket
      const accessToken = await getAccessToken();
      this.ws = new WebSocket(websocketUrl, [accessToken]);

      // Activate websocket handlers
      this.addWebsocketHandlers();
    });
  }


  /**
   * Handler for receipt of a websocket message, determines which method to call
   * based on message type.
   * @param {string} message Received web socket data packet.
   */
  onWsMessage(message) {
    // Deserialize the data JSON into an object
    let parsedMessage;
    try {
      parsedMessage = JSON.parse(message.data);
    } catch (err) {
      console.error("[SupervisorHandlerService]: Could not parse message received from handler websocket", message.data);
      return;
    }

    // Based on the type parameter, determine which callback event should be triggered
    switch (parsedMessage.type) {
      case MESSAGE_TYPES.ASSIGNED:
      case MESSAGE_TYPES.UNASSIGNED:
      case MESSAGE_TYPES.DISMISSED:
        // An exam has been allocated or removed, pass the details into any callback function registered
        if (this.callbacks.hasOwnProperty('onAssignmentChange')) {
          for (const assignmentFunc of this.callbacks.onAssignmentChange) {
            assignmentFunc(parsedMessage.type, parsedMessage.examSlot);
          }
        }
        break;
      case MESSAGE_TYPES.EXAM_UNLOCKED:
      case MESSAGE_TYPES.EXAM_LOCKED:
      case MESSAGE_TYPES.SUBMITTED:
        // An exam has had its exam gate opened, closed or been submitted, pass the details into any 
        // callback function registered
        if (this.callbacks.hasOwnProperty('onExamStateChange')) {
          for (const stateChangeFunc of this.callbacks.onExamStateChange) {
            stateChangeFunc(parsedMessage.type, parsedMessage.examSlot);
          }
        }
        break;
      case MESSAGE_TYPES.ROLE_ASSIGNED:
        if (this.callbacks.hasOwnProperty('onRoleAssignmentChange')) {
          for (const roleAssignmentFunc of this.callbacks.onRoleAssignmentChange) {
            roleAssignmentFunc(parsedMessage.type, parsedMessage.roleAssignment);
          }
        }
        break;
      default:
        // Unknown message type received - just error
        console.warn(`[SupervisorHandlerService]: Received unhandled message type of ${parsedMessage.type}`, parsedMessage);
        return;
    }
  }

  /**
   * Upon the socket being closed unexpectedly, call the close handler.
   */
  onWsClose() {
    console.debug(`[SupervisorHandlerService]: Socket connection to ${this.wsUrl} closed`);
    clearInterval(this.pingInterval);
    
    if (SETTINGS.maxConnectionDurationMs > 0 && this.maxDurationTimeout) {
      clearTimeout(this.maxDurationTimeout);
    }
    
    if (this.wsCloseResolver) {
      console.debug(`[SupervisorHandlerService]: Calling close resolver function`);
      this.wsCloseResolver('Websocket closed');
      this.wsOpenResolver = null;
      this.wsCloseResolver = null;
    }

    if (this.socketOpen && this.callbacks.hasOwnProperty('onDisconnection')) {
      this.socketOpen = false;
      for (const disconnectFunc of this.callbacks.onDisconnection) {
        // Call disconnect handler with clientInitiated: false, and tryReconnect: false
        disconnectFunc(false, false);
      }
    }
  }

  /**
   * When the websocket is opened, send any data that was flagged as pending
   * and start a keep-alive ping timer.
   */
  onWsOpen() {
    console.debug(`[SupervisorHandlerService]: Socket connection to ${this.wsUrl} opened`);
    // Start sending regular ping messages to keep the connection alive
    this.pingInterval = setInterval(this.ping.bind(this), SETTINGS.pingIntervalMs);

    // Start max duration countdown if set
    if (SETTINGS.maxConnectionDurationMs > 0) {
      this.maxDurationTimeout = setTimeout(this.timeoutReconnection.bind(this), SETTINGS.maxConnectionDurationMs);
    }

    // Resend queued messages that happened when socket was not connected
    while (this.wsQueue.length > 0) {
      const pendingMessage = this.wsQueue.pop();
      this.ws.send(pendingMessage.json, pendingMessage.callback);
    }

    if (this.wsOpenResolver) {
      console.debug(`[SupervisorHandlerService]: Calling open resolver function`);
      // Call any open resolver function
      this.wsOpenResolver();
      this.wsOpenResolver = null;
      this.wsCloseResolver = null;
    }

    if (!this.socketOpen && this.callbacks.hasOwnProperty('onConnection')) {
      this.socketOpen = true;
      for (const connectFunc of this.callbacks.onConnection) {
        connectFunc();
      }
    }
  }

  /**
   * Send a simple ping message to the websocket.
   */
  ping() {
    this.sendMessage({
      type: MESSAGE_TYPES.PING,
      timestamp: Date.now(),
    })
  }

  /**
   * Construct the message packet that will encapsulate anything sent to the
   * media server.
   * @param {object} message The message object
   * @param {string} message.id The type of message
   * @param {*} message.data The data of the message
   */
  sendMessage(message) {
    return new Promise((resolve, reject) => {
      const { ws } = this;
      const jsonMessage = JSON.stringify(message);
      const messageCallback = (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      };

      // If there is an active websocket connection, serialize and then send the
      // message to the socket.
      if (this.socketOpen) {
        ws.send(jsonMessage, messageCallback);
      } else if (message.type !== MESSAGE_TYPES.PING) {
        // Queue the message for later sending when the socket has opened again
        this.wsQueue.push({ json: jsonMessage, callback: messageCallback });
      }
    });
  }

  /**
   * Associate websocket event handlers.
   */
  addWebsocketHandlers() {
    // Set handler functions
    this.ws.onopen = this.onWsOpen;
    this.ws.onclose = this.onWsClose;
    this.ws.onmessage = this.onWsMessage;

    // Add listeners for the client browser going on/off line from network connectivity
    window.addEventListener('online', () => this.connect(this.wsUrl, this.getAccessToken));
    window.addEventListener('offline', this.onWsClose);
  }

  /**
   * Disassociate websocket event handlers.
   */
   removeWebsocketHandlers() {
    // Set handler functions
    this.ws.onopen = undefined;
    this.ws.onclose = undefined;
    this.ws.onmessage = undefined;
  }

  /**
   * Close down the socket connection gracefully.
   */
  close() {
    // Stop the keep-alive ping
    clearInterval(this.pingInterval);

    // Stop the duration countdown
    if (SETTINGS.maxConnectionDurationMs > 0 && this.maxDurationTimeout) {
      clearTimeout(this.maxDurationTimeout);
    }

    // Close websocket connection and trigger any disconnection function
    if (this.socketOpen) {
      this.socketOpen = false;
      this.removeWebsocketHandlers();
      this.ws.close();
      for (const disconnectFunc of this.callbacks.onDisconnection) {
        // Call disconnect handler with clientInitiated: true, and tryReconnect: false
        disconnectFunc(true, false);
      }
    }
  }

  /**
   * Register a callback handler function for a specific event type.
   * @param {string} type The type of callback event, from 'onAssignmentChange', 
   *                      'onExamStateChange', 'onConnection' or 'onDisconnection'
   * @param {func} func The callback function to execute on an event received, which is
   *                    passed parameters as follows: 
   *                    - onAssignmentChange & onExamStateChange: (messageType, examData)
   *                    - onRoleAssignmentChange: (messageType, roleAssignmentData)
   *                    - onConnect: <no parameters>
   *                    - onDisconnect: (clientInitiated, tryReconnect)
   */
  registerHandler(type, func) {
    if (this.callbacks.hasOwnProperty(type)) {
      this.callbacks[type].push(func);
    } else {
      console.warn(`[SupervisorHandlerService]: Unable to register callback function of unknown type ${type}`);
    }
  }

  /**
   * Unregister a callback event of the given type, or all callbacks for that type.
   * @param {string} type The type of callback event, from 'onAssignmentChange', 
   *                      'onExamStateChange', 'onConnection' or 'onDisconnection'
   * @param {func} func The callback function to be removed. If not set, all handlers
   *                    of the given type will be unregistered.
   */
  unregisterHandler(type, func) {
    if (this.callbacks.hasOwnProperty(type)) {
      if (func) {
        // Remove a specific handler
        const removeIndex = this.callbacks[type].indexOf(func);
        if (removeIndex >= 0) {
          this.callbacks[type].splice(removeIndex, 1);
        } else {
          console.warn(`[SupervisorHandlerService]: Attempt to unregister ${type} handler which was not found`);
        }
      } else {
        // Remove all handlers of that type
        this.callbacks[type] = [];
      }
    } else {
      console.warn(`[SupervisorHandlerService]: Unable to unregister callback function of unknown type ${type}`);
    }
  }

  async timeoutReconnection() {
    if (this.socketOpen) {
      // Close the current websocket
      console.debug('[SupervisorHandlerService]: Max duration timeout reached, disconnecting');
      this.socketOpen = false;
      this.removeWebsocketHandlers();
      this.ws.close();
      for (const disconnectFunc of this.callbacks.onDisconnection) {
        // Call disconnect handler with clientInitiated: true, and tryReconnect: true
        disconnectFunc(true, true);
      }
    }
  }
}