import { identity, isEmpty, pickBy } from 'lodash';
import { SEARCH_BY_TYPES, SESSION_TIMES } from '../constants/examSessions';

const hasFilters = (filters) => {
  if(!filters) { return false; }
  return Object.keys(
    pickBy(filters, (filterValue, key) => {
      return identity(filterValue) && key !== 'groupBy';
    })).length > 0;
}

export const isValidExamSessionSearch = (params, selectedOption) => {
  return (params?.examDate || params?.examStartDateAfter) &&
    ((params.contextId || params.studentId) ||
      (hasFilters(params.filters) && (params.filters?.groupBy === 'none' || selectedOption === 'report')) ||
      (params[SEARCH_BY_TYPES.mediaHost.endpointMapper] && params.filters?.groupBy === 'none')
    );
}

export const examSessionSearchCanNavigate = (params) => {
  if (!params) { return false }
  const { filters, searchText, selectedDate, selectedOption, selectedSession, user } = params;
  if (!selectedDate || !SEARCH_BY_TYPES[selectedOption]) {
    return false;
  }
  if (selectedOption === 'context' && (isEmpty(searchText) || searchText.length < 5)) {
    return false;
  }
  if (selectedOption === 'student' && isEmpty(user)) {
    return false;
  }
  if (selectedOption === 'session' && (selectedSession === undefined || !SESSION_TIMES[selectedSession])) {
    return false;
  }
  if (selectedOption === 'report' && !hasFilters(filters) ) {
    return false;
  }
  if (selectedOption === 'mediaHost' && isEmpty(searchText) ) {
    return false;
  }
  return true;
}
