import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReconnectMediaButton from '../form/ReconnectMediaButton';
import {
  PRIVACY_COLLECTION_URL,
  PRIVACY_PROCEDURE_URL,
} from '../../constants/session';

class PrivacyAdvice extends Component {

  render() {
    const {
      examLandingPage,
      streamStatus,
      streamWarnings,
      startSharingAudio,
      startSharingVideo,
    } = this.props;
    return (
      <Box display="flex" alignItems="flex-start">
        <div>
          <ReconnectMediaButton
            mediaType="audio"
            mediaState={streamStatus.audio}
            mediaWarning={streamWarnings.audio}
            displayAsIcon={true}
            reconnectFunc={startSharingAudio} />
          <ReconnectMediaButton
            mediaType="webcam"
            mediaState={streamStatus.webcam}
            mediaWarning={streamWarnings.webcam}
            displayAsIcon={true}
            reconnectFunc={() => startSharingVideo("webcam")} />
          <ReconnectMediaButton
            mediaType="desktop"
            mediaState={streamStatus.desktop}
            mediaWarning={streamWarnings.desktop}
            displayAsIcon={true}
            reconnectFunc={() => startSharingVideo("desktop")} />
        </div>
        <Typography variant="body1" sx={{ maxWidth: '55%', fontSize: '0.8rem' }}>You must keep this tab open at all times to maintain connection. You'll be recorded through your webcam, microphone and screenshare throughout the exam session.</Typography>
        <Typography variant="body2">
          {examLandingPage && <><a href={examLandingPage} target="_blank" rel="noopener noreferrer">My eExam landing page</a><br/></>}
          <a href={PRIVACY_COLLECTION_URL} target="_blank" rel="noopener noreferrer">Student Privacy Collection Statement</a><br/>
          <a href={PRIVACY_PROCEDURE_URL} target="_blank" rel="noopener noreferrer">Monash Privacy Procedure</a>
        </Typography>
      </Box>
    );
  }
}

PrivacyAdvice.propTypes = {
  examLandingPage: PropTypes.string,
  startSharingVideo: PropTypes.func.isRequired,
  startSharingAudio: PropTypes.func.isRequired,
  streamStatus: PropTypes.object.isRequired,
  streamWarnings: PropTypes.object,
};

PrivacyAdvice.defaultProps = {
  streamWarnings: {
    webcam: undefined,
    audio: undefined,
    desktop: undefined,
  },
};

export default PrivacyAdvice;
