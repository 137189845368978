import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ChatPopup from '../popup/ChatPopup';

function ChatInfo(props) {
  return (
    <>
      {props.gatewayService &&
        <Box mb={1} bgcolor="white" id="chat-aside-box">
          <Box display="flex">
            <QuestionAnswerOutlinedIcon sx={{ fontSize: '1.6rem', pt: 2, pl: 2 }}/>
            <Box p={2} position="relative">
              <Typography variant="h4" component="h3">Need to send a message to your online supervisor?</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>Use the web chat function.</Typography>
              <ChatPopup {...props} chatReady={true} />
            </Box>
          </Box>
          <Typography variant="caption" component="p" sx={{ p: 1, pt: 1, lineHeight: '1rem', color: 'grey.600', borderTop: '1px solid', borderColor: 'grey.200' }}>
            They may be assisting another student but will get back to you as soon as possible.
            Continue with your exam if you can.
          </Typography>
        </Box>
      }
    </>
  )
}

export default ChatInfo;
