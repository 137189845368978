import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  TextField
} from '@mui/material';
import GreenSwitch from './GreenSwitch';
import Notice from '../notification/Notice';
import Section from '../Section';
import { ALLOWED_USER_TYPES } from '../../constants/allocationPools';
import AllocationPoolService from '../../services/AllocationPoolService';
import { capitalise } from '../../utils/capitalise';

function AllocationPoolForm(props) {
  const { allocationPool, setUpdatedAllocationPool, setDisabledUpdated } = props;

  const [requestStatus, setRequestStatus] = useState({
    error: '',
    isPending: false,
    isSuccessful: false,
  });

  const [name, setName] = useState(allocationPool.name);
  const [description, setDescription] = useState(allocationPool.description);
  const [availableSlotsAny, setAvailableSlotsAny] = useState(allocationPool.availableSlotsAny?.toString());
  const [availableSlotsUnlocked, setAvailableSlotsUnlocked] = useState(allocationPool.availableSlotsUnlocked?.toString());
  const [allowedUserTypes, setAllowedUserTypes] = useState(
    allocationPool.allowedUserTypes?.split(',')?.filter(userType => !isEmpty(userType)) || []);
  const [disabled, setDisabled] = useState(allocationPool.disabled);

  const handleUserTypeChange = (event) => {
    const { target: { value } } = event;
    setAllowedUserTypes(typeof value === 'string' ? value.split(',') : value);
  }

  const handleFormChangeEvent = (formValueSetter) => (event) => {
    const { target: { value } } = event;
    return formValueSetter(value);
  }

  const handleDisabledChange = (_event) => {
    setDisabled(!disabled);
  }

  const validateFields = () => {
    if (isEmpty(name) || isEmpty(description) || isEmpty(availableSlotsAny)
      || isEmpty(availableSlotsUnlocked) || isEmpty(allowedUserTypes)) {
      throw Error('Please fill out all required fields.');
    }

    if (availableSlotsAny < 0 || availableSlotsUnlocked < 0) {
      throw Error('Please ensure available slots fields are positive values');
    }
  }

  const saveAllocationPool = async () => {
    try {

      validateFields();

      const patchRequestBody = {
        name: name,
        description: description,
        availableSlotsAny: parseInt(availableSlotsAny),
        availableSlotsUnlocked: parseInt(availableSlotsUnlocked),
        allowedUserTypes: allowedUserTypes?.join(','),
        disabled: disabled,
      };

      setRequestStatus({
        error: '',
        isPending: false,
        isSuccessful: false,
      });

      const updateResponse = await AllocationPoolService.patchAllocationPools(allocationPool.id, patchRequestBody);

      const disabledUpdated = disabled !== allocationPool.disabled;
      setDisabledUpdated(disabledUpdated);
      setUpdatedAllocationPool(updateResponse);

      setRequestStatus({
        ...requestStatus,
        error: '',
        isPending: false,
        isSuccessful: true,
      });
    } catch (err) {
      setRequestStatus({
        error: err.message,
        isPending: false,
      });
    }
  }

  return (
    <Section >
      <FormControl component="fieldset" sx={{ display: 'block', margin: '0 auto' }}>
        <FormLabel component="legend" sx={{ color: 'primary.main', my: 1 }}>
          Update allocation pool
        </FormLabel>
        {requestStatus.isSuccessful &&
          <Notice noticeType="success">Record saved</Notice>
        }
        {requestStatus.error &&
          <Notice noticeType="error">{requestStatus.error}</Notice>
        }
        <TextField
          key="allocation-pool-name"
          id="allocation-pool-name"
          label="Allocation pool name"
          error={isEmpty(name)}
          value={name || ''}
          onChange={handleFormChangeEvent(setName)}
          required
          size="medium"
          sx={{ m: 1 }}
          variant="outlined"
          fullWidth />
        <TextField
          key="allocation-pool-description"
          id="allocation-pool-description"
          label="Allocation pool description"
          helperText="Please note, the description is displayed on the supervisor dashboard page"
          error={isEmpty(description)}
          value={description || ''}
          onChange={handleFormChangeEvent(setDescription)}
          required
          size="medium"
          sx={{ m: 1 }}
          variant="outlined"
          fullWidth />
        <TextField
          key="allocation-pool-available-any"
          id="allocation-pool-available-any"
          label="Available slots any"
          helperText="Available slots any controls the maximum number of students allowed in the onboarding stage at once"
          error={isEmpty(availableSlotsAny) || availableSlotsAny < 0}
          value={availableSlotsAny || ''}
          type="number"
          inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={handleFormChangeEvent(setAvailableSlotsAny)}
          required
          size="medium"
          sx={{ m: 1 }}
          variant="outlined"
          fullWidth />
        <TextField
          key="allocation-pool-available-unlocked"
          id="allocation-pool-available-unlocked"
          label="Available slots unlocked"
          error={isEmpty(availableSlotsUnlocked) || availableSlotsUnlocked < 0}
          value={availableSlotsUnlocked || ''}
          type="number"
          inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={handleFormChangeEvent(setAvailableSlotsUnlocked)}
          required
          size="medium"
          sx={{ m: 1 }}
          variant="outlined"
          fullWidth />
        <TextField
          id="allocation-pool-user-types"
          label="Allowed user types"
          required
          error={isEmpty(allowedUserTypes)}
          select
          size="medium"
          sx={{ m: 1 }}
          variant="outlined"
          fullWidth
          SelectProps={{
            multiple: true,
            value: allowedUserTypes,
            onChange: handleUserTypeChange,
            renderValue: (selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={capitalise(value)} />
                ))}
              </Box>
            )
          }}
        >
          {ALLOWED_USER_TYPES.map((userType) => {
            return (
              <MenuItem key={userType} value={userType}>{capitalise(userType)}</MenuItem>
            )
          })}
        </TextField>
        <FormControlLabel
          key="disabled"
          sx={{ width: '100%' }}
          control={
            <GreenSwitch
              checked={disabled}
              onChange={handleDisabledChange}
              name="Disabled"
              id="allocation-pool-disabled"
              inputProps={{ 'aria-label': 'pool disabled' }}
            />} label="Disabled"
        />
        <Box display="flex" justifyContent="flex-end">
          {requestStatus.isPending &&
            <CircularProgress disableShrink />
          }
          {!requestStatus.isPending &&
            <Button
              color="primary"
              variant="contained"
              onClick={saveAllocationPool}
            >Save</Button>
          }
        </Box>
      </FormControl>
    </Section>
  );
}

AllocationPoolForm.propTypes = {
  allocationPool: PropTypes.object,
  setUpdatedAllocationPool: PropTypes.func,
  setDisabledUpdated: PropTypes.func,
}

export default AllocationPoolForm;
