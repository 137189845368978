import { createTheme, darken } from '@mui/material/styles';

export const buttonLinkStyle = {
  textTransform: 'initial',
  textAlign: 'left',
  justifyContent: 'start',
  color: 'rgb(0,108,171)',
  '&:hover': {
    backgroundColor: 'rgba(0,108,171,0.04)',
  },
};

export const transparentScrollbarStyle = {
  '&::-webkit-scrollbar': {
    width: 1,
    backgroundColor: 'transparent',
  },
  '&:hover::-webkit-scrollbar': {
    backgroundColor: '#eee',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    borderRadius: 1,
  },
};

export const scrollBoxStyle =  {
  borderRadius: 0,
  padding: 0,
  margin: 0,
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollPadding: 0.25,
  ...transparentScrollbarStyle,
};

export const requestStatusIndicatorContainer = {
  position: 'fixed',
  left: '50%',
  top: '50%',
  zIndex: 2,
  transform: 'translate(-50%, 0)',
};

export const fieldsetContainerStyle = {
  display: 'block',
  margin: '0 auto',
};

export const legendStyle = {
  color: 'primary.main',
  m: 1,
};

export const lightestGrey = '#f5f6f6';

const defaultTheme = createTheme();
export const themeObject = {
  palette: {
    primary: {
      main: '#006dae',
      light: '#549be0',
      dark: '#00437e',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#505050',
      light: '#7c7c7c',
      dark: '#282828',
      contrastText: '#ffffff',
    },
    text: {
      disabled: '#797677',
    },
    error: {
      main: '#D03232',
      dark: darken('#D03232', 0.1),
    },
    success: {
      main: '#457B7B',
      light: '#DFF0D8',
    },
    warning: {
      main: '#C94618',
      light: '#E7B156',
    },
    popBlue: {
      main: '#0872E7',
      dark: '#054a96',
    },
    popPurple: {
      main: '#6f64a9',
      dark: '#4b4379',
    },
    popPink: {
      main: '#c8008f'
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 14.5,
    h1: {
      fontSize: '1.55rem',
      color: '#006cab',
      textWrap: 'balance',
    },
    h2: {
      fontWeight: 'normal',
      fontSize: '1.7rem',
      marginBottom: '0.5em',
      textWrap: 'balance',
    },
    h3: {
      fontSize: '1.4rem',
      marginBottom: '0.5em',
      textWrap: 'balance',
    },
    h4: {
      fontSize: '1.3rem',
      marginBottom: '0.5em',
      textWrap: 'balance',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '500',
      textShadow: 'none',
      textWrap: 'balance',
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 'normal',
      textShadow: 'none',
      textWrap: 'balance',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
    caption: {
      lineHeight: 2,
      letterSpacing: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          '&$disabled': {
            color: '#797677',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        dense: {
          '& .MuiListItemIcon-root': {
            minWidth: '40px',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: 'black',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#505050',
        },
      },
    },
    // pre-opened tooltips
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          backgroundColor: 'rgba(97, 97, 97, 0.92)',
          boxShadow: 'none',
          color: '#fff',
          fontSize: '0.7rem',
          fontWeight: '500',
          padding: '4px 8px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
      },
    },
    // mui tables
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f6f6',
          color: '#000000',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color:'#282828',
        },
        root: {
          border: '1px solid #eee',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            background: '#f4f8fc !important',
          },
        },
      },
    },
    // mui-datatables
    MUIDataTable: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          '& > div': {
            [defaultTheme.breakpoints.up('md')]: {
              overflow: 'auto',
            },
          },
        }
      }
    },

    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f6f6',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          verticalAlign: 'top',
          '& > div': {
            padding: '6px',
          },
        },
        fixedHeader: {
          backgroundColor: '#f5f6f6',
        },
        toolButton: {
          fontSize: '0.8rem',
        },
        sortAction: {
          color: '#282828',
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: '0.5em',
          verticalAlign: 'middle',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f6f6',
          padding: defaultTheme.spacing(0.5),
        },
        headerCell: {
          backgroundColor: '#f5f6f6',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          display: 'flex',
        }
      },
    },
  }
};

themeObject.palette['primary-light'] = {
  main: themeObject.palette.primary.contrastText,
  light: themeObject.palette.primary.main,
  dark: themeObject.palette.primary.contrastText,
  contrastText: themeObject.palette.primary.main,
}

const theme = createTheme(themeObject);

export default theme;
