import NetworkService from './NetworkService';
import mapParams from '../utils/mapParams';

export default class UploadService {

  static async uploadFile(file, evigFileType, params, controller) {
    if(!evigFileType && !evigFileType.path) { return; }
    let path = evigFileType.path;
    if(params) {
      path = `${path}?${mapParams(params)}`;
    }

    if(evigFileType.requestType === 'patch') {
      return await NetworkService.patchFile(path, file, controller).then(resp => {return resp});
    }
    if(evigFileType.requestType === 'put') {
      return await NetworkService.putFile(path, file, controller).then(resp => {return resp});
    }
  }

 }
