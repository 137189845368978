import ReconnectingWebSocket from 'reconnecting-websocket';
import gatewayConfig from '../config/gateway';
import AuthService from './AuthService';
import MOCK_GATEWAY_RESPONSE from "./mock/Gateway";
import { assign, escape, unescape } from 'lodash';
import Bowser from 'bowser';
import { NOTICE_TYPES } from '../constants/monitorSessions';

const USE_MOCK_GATEWAY = !!process.env.REACT_APP_APPCONFIG_USE_MOCK_GATEWAY || (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true");

export default class GatewayService {

  /**
   * Constructor for new Gateway service connector, which will handle a socket connection
   * with the back-end java engine, as an intermediary to BBB.
   */
  constructor() {

    // Initialise websocket, using provided session token
    this.socketOpen = false;
    this.ws = null;
    this.wsQueue = [];
    this.meetingConnectionState = undefined;
    this.meetingDetails = undefined;

    // Bind handler methods for websocket communication
    this.onWsOpen = this.onWsOpen.bind(this);
    this.onWsClose = this.onWsClose.bind(this);
    this.onWsMessage = this.onWsMessage.bind(this);
    this.tryReopen = this.tryReopen.bind(this);
    this.handleAuthenticated = this.handleAuthenticated.bind(this);
    this.handleClosed = this.handleClosed.bind(this);
    this.handleRejected = this.handleRejected.bind(this);
    this.handleMediaChange = this.handleMediaChange.bind(this);
    this.handleParticipantChange = this.handleParticipantChange.bind(this);
    this.handleWindowUnload = this.handleWindowUnload.bind(this);

    // Set timers for retry
    this.restartTimeout = {};
    this.restartTimer = {};
    this.attemptingReconnection = false;

    // Handlers for hooked in methods
    this.handlers = {
      onMediaChange: [],
      onMeetingChange: [],
      onParticipantChange: [],
      onDisconnected: [],
      onConnected: [],
      onChatNotification: [],
      onReceiveNotice: [],
      onResolveNotices: [],
      onSkipOnboardStep: [],
      onOnboardProgressUpdate: [],
      onIdAnalysisUpdate: [],
      onScreenTypeUpdate: [],
    };
  }

  /**
   * Register an external function handler to update the client when a disconnection event occurs
   * @param {function} handlerFunc Callback function to be registered when that event occurs
   */
  registerDisconnectionHandler(handlerFunc) {
    this.handlers.onDisconnected.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when a reconnection event occurs
   * @param {function} handlerFunc Callback function to be registered when that event occurs
   */
  registerConnectionHandler(handlerFunc) {
    this.handlers.onConnected.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when a media change occurs
   * @param {string} mediaType Can be one of 'video', 'audio' or 'screenshare'
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with three params:
   *                               changeType - a change code from 'start', 'stop', 'mute', 'unmute', 'active', 'inactive';
   *                               userId - the eVigilation platform user ID for which participant is being updated;
   *                               mediaState - an object which contains the new media state for that user.
   */
  registerMediaChangeEvent(mediaType, handlerFunc) {
    if (this.handlers.onMediaChange.hasOwnProperty(mediaType)) {
      this.handlers.onMediaChange[mediaType].push(handlerFunc);
    } else {
      this.handlers.onMediaChange[mediaType] = [handlerFunc];
    }
  }

  /**
   * Register an external function handler to update the client when a user joins or leaves the meeting
   * @param {string} changeType Can be one of 'add' or 'remove'
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with three params:
   *                               userId - the eVigilation platform user ID for which participant is affected;
   *                               newUserDetails - an object which contains the new participant details;
   *                               changeReason - any reason for why the change occurred.
   */
  registerParticipantChangeEvent(changeType, handlerFunc) {
    if (this.handlers.onParticipantChange.hasOwnProperty(changeType)) {
      this.handlers.onParticipantChange[changeType].push(handlerFunc);
    } else {
      this.handlers.onParticipantChange[changeType] = [handlerFunc];
    }
  }

  /**
   * Register an external function handler to update the client when the meeting changes
   * @param {string} changeType Can be one of 'update' or 'end'
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with two params:
   *                               changedMeetingDetails - an object which contains the changed meeting details;
   *                               changeReason - any reason for why the change occurred.
   */
  registerMeetingChangeEvent(changeType, handlerFunc) {
    if (this.handlers.onMeetingChange.hasOwnProperty(changeType)) {
      this.handlers.onMeetingChange[changeType].push(handlerFunc);
    } else {
      this.handlers.onMeetingChange[changeType] = [handlerFunc];
    }
  }

  /**
   * Register an external function handler to update the client when a chat notification or message is received.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with a single param:
   *                               messageDetails - an object which contains the received chat message details.
   */
  registerChatNotificationEvent(handlerFunc) {
    this.handlers.onChatNotification.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when a receive notices message is received.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with one param:
   * notice - an object containing the notice details
   */
   registerReceiveNoticeEvent(handlerFunc) {
    this.handlers.onReceiveNotice.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when a resolve notices message is received.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with two params:
   *                               types - an optional array of notice types that have been cleared
   *                               timestamp - the unix timestamp in ms for which notices have been cleared
   *                               resolvedBy - user id of the person who resolved these notices
   */
   registerResolveNoticesEvent(handlerFunc) {
    this.handlers.onResolveNotices.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when a skip onboard step message is received.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with three params:
   *                               onboardProgressId - The progress id of for the skipped step
   *                               skippedById - The user id of the person who skipped the step
   *                               slotId - The exam slot id of the skipped step
   */
  registerSkipOnboardStepEvent(handlerFunc) {
    this.handlers.onSkipOnboardStep.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when an onboard progress update event occurs.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with two params:
   *                               onboardStepType - The actionType of the step that was completed
   *                               slotId - The exam slot id
   */
  registerOnboardProgressUpdateEvent(handlerFunc) {
    this.handlers.onOnboardProgressUpdate.push(handlerFunc);
  }


  /**
   * Register an external function handler to update the client when an ID analysis update event occurs.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with two params:
   *                               onboardStepType - The actionType of the step that was completed
   *                               slotId - The exam slot id
   */
  registerIdAnalysisUpdateEvent(handlerFunc) {
    this.handlers.onIdAnalysisUpdate.push(handlerFunc);
  }

  /**
   * Register an external function handler to update the client when a screen share type event occurs.
   * @param {function} handlerFunc Callback function to be registered when that event occurs, with two params:
   *                               screenType - The type of screen that was shared
   *                               slotId - The exam slot id
   */
  registerScreenTypeUpdateEvent(handlerFunc) {
    this.handlers.onScreenTypeUpdate.push(handlerFunc);
  }

  /**
   * Unregister any external function handlers associated with a particular event
   * @param {string} eventType Can either be 'all' or a specific event type.
   * @param {function} handlerFunc The function handler to remove, or null for all of this event type.
   */
  unregisterHandler(eventType, handlerFunc = null) {
    if (eventType === 'all') {
      Object.keys(this.handlers).forEach((type) => {
        this.handlers[type] = [];
      });
    } else if (eventType in this.handlers) {
      if (handlerFunc === null) {
        this.handlers[eventType] = [];
      } else {
        const removeIndex = this.handlers[eventType].indexOf(handlerFunc);
        if (removeIndex >= 0) {
          this.handlers[eventType].splice(removeIndex, 1);
        }
      }
    }
  }

  /**
   * Unregister any external function handlers associated with a particular event
   * @param {string} eventType Can be one of 'onMeetingChange', 'onParticipantChange' or 'onMediaChange'.
   * @param {string} subType The subtype to deregister such as 'add', 'remove' or 'video'.
   * @param {function} handlerFunc The function handler to remove, or null for all of this event type.
   */
  unregisterHandlerWithSubtype(eventType, subType, handlerFunc = null) {
    if (this.handlers.hasOwnProperty(eventType)) {
      if (this.handlers[eventType].hasOwnProperty(subType)) {
        if (handlerFunc === null) {
          this.handlers[eventType][subType] = [];
        } else {
          const removeIndex = this.handlers[eventType][subType].indexOf(handlerFunc);
          if (removeIndex >= 0) {
            this.handlers[eventType][subType].splice(removeIndex, 1);
          }
        }
      }

    }
  }

  /**
   * Open the websocket and connect to the gateway once a meeting has been established
   * @param {object} meetingDetails.gatewayServer Remote gateway server host
   */
  connect(evigUserId, evigSlotId, meetingDetails) {
    this.meetingDetails = meetingDetails;
    this.evigUserId = evigUserId;
    this.evigSlotId = evigSlotId;
    if (!meetingDetails.hasOwnProperty('participants')) {
      this.meetingDetails.participants = {};
    }

    // Add listeners for the client browser going on/off line from network connectivity
    window.addEventListener('online', this.tryReopen);
    window.addEventListener('offline', this.onWsClose);
    window.addEventListener('beforeunload', this.handleWindowUnload);

    if (USE_MOCK_GATEWAY) {
      this.ws = 'MOCK_GATEWAY';
    } else {
      const { gatewayServer } = meetingDetails;
      this.ws = new ReconnectingWebSocket(
        `wss://${gatewayServer}${gatewayConfig.gatewayPath}`,
        [],
        gatewayConfig.reconnectingSocketOptions,
      );
      this.ws.onopen = this.onWsOpen;
      this.ws.onclose = this.onWsClose;
      this.ws.onmessage = this.onWsMessage;
    }

    // Return a promise that resolves when we have authenticated
    this.meetingConnectionState = gatewayConfig.connectionState.CONNECTING;
    return new Promise((resolve, reject) => {
      this.onAuthenticated = (data) => (this.handleAuthenticated(data, resolve));
      this.onClosed = () => { this.handleClosed(reject) };
      this.onRejected = (data) => { this.handleRejected(data, reject) };

      if (USE_MOCK_GATEWAY) {
        this.onWsOpen();
      }
    });
  }

  /**
   * Publish to the session server that new media is available to be accessed from this client
   * @param {string} type The type of media, being one of 'webcam', 'audio', or 'desktop'
   * @param {string} streamName The unique identifier for this stream, usually the user ID
   * @param {string} [screenType] Extra information about what screen is being shared
   */
  shareMedia(type, streamName, screenType) {
    let shareMessage = {};
    if (streamName && type === 'webcam') {
      shareMessage.data = {};
      shareMessage.data.stream = streamName;
    }
    switch (type) {
      case 'webcam':
        shareMessage.event = gatewayConfig.events.VIDEO_START;
        break;
      case 'audio':
        shareMessage.event = gatewayConfig.events.AUDIO_START;
        break;
      case 'desktop':
        shareMessage.event = gatewayConfig.events.SCREENSHARE_START;
        if (screenType) {
          shareMessage.data = { screenType: screenType };
        }
        break;
      default:
        // Do nothing - shouldn't happen.
        return;
    }
    this.sendMessage(shareMessage);
  }

  /**
   * Publish to the session server that a local source of media is stopping from this client
   * @param {string} type The type of media, being one of 'webcam', 'audio', or 'desktop'
   * @param {string} streamName The unique identifier for this stream, usually the user ID
   */
  unshareMedia(type, streamName) {
    let shareMessage = {};
    if (streamName && type === 'webcam') {
      shareMessage.data = {};
      shareMessage.data.stream = streamName;
    }
    switch (type) {
      case 'webcam':
        shareMessage.event = gatewayConfig.events.VIDEO_STOP;
        break;
      case 'audio':
        shareMessage.event = gatewayConfig.events.AUDIO_STOP;
        break;
      case 'desktop':
        shareMessage.event = gatewayConfig.events.SCREENSHARE_STOP;
        break;
      default:
        // Do nothing - shouldn't happen.
        return;
    }
    this.sendMessage(shareMessage);
  }

  /**
   * Publish to the session server that we want to change the media state for this user
   * @param {string} mediaType The type of media, currently only 'audio' supported.
   * @param {string} flag The property that will be toggled, one of 'muted' or 'active'.
   * @param {boolean} value New state of this flag.
   * @param {string} targetUser The user to change, otherwise assume ourselves.
   */
  updateMedia(mediaType, flag, value, targetUser = null) {
    if (mediaType !== 'audio') {
      return;
    }
    let shareMessage = {};
    switch (flag) {
      case 'muted':
        if (value) {
          shareMessage.event = gatewayConfig.events.AUDIO_MUTE;
        } else {
          shareMessage.event = gatewayConfig.events.AUDIO_UNMUTE;
        }
        shareMessage.data = {
          userId: targetUser ? targetUser : this.evigUserId,
        };
        break;
      case 'active':
        if (value) {
          shareMessage.event = gatewayConfig.events.AUDIO_ACTIVE;
        } else {
          shareMessage.event = gatewayConfig.events.AUDIO_INACTIVE;
        }
        break;
      default:
        // Do nothing - shouldn't happen.
        return;
    }
    this.sendMessage(shareMessage);
  }

  /**
   * Publish to the session server that the meeting state has changed
   * @param {object} updatedMeetingData The updated meeting struct containing any changed fields.
   * @param {string} reason Any reason for the update.
   */
  updateMeeting(updatedMeetingData, reason) {
    const shareMessage = {
      event: gatewayConfig.events.MEETING_UPDATE,
      data: {
        meeting: updatedMeetingData,
        reason: reason,
      },
    };
    this.sendMessage(shareMessage);
  }

  /**
   * Send a chat message or notification from the current logged in user to the gateway.
   * @param {string} type The type of chat message, being one of 'text' or 'notification'.
   * @param {string} text The text of the message.
   * @param {string} displayName The display name of the message author.
   * @param {string} userId The evigilation user ID of the sender.
   * @param {string} audience The intended audience of the message, being one of:
   *                          - null for all participants in the meeting.
   *                          - <user UUID> for a specific user only.
   *                          - 'ADMIN' for non-student participants only.
   *                          - 'STUDENT' for student participant only.
   *                          - 'SUPERVISOR' for supervisor participant only.
   */
  sendChat(type, text, displayName, userId, audience) {
    let shareMessage = {
      event: gatewayConfig.events.CHATMSG,
      data: {
        type,
        audience,
        author: displayName,
        userId,
        text: this.sanitizeChatText(text).substring(0, gatewayConfig.chatMaximumLength),
        timestamp: Date.now(),
      },
    };
    this.sendMessage(shareMessage);
  }

  /**
   * Publish to the session server an information message for logging purposes
   * @param {string} type The type of logged message.
   * @param {object} details The details to be recorded on the server.
   */
  logInfo(type, details) {
    const shareMessage = {
      event: gatewayConfig.events.INFO,
      data: {
        type,
        details,
      },
    };
    this.sendMessage(shareMessage);
  }

  /**
   * Send a notice resolution message to the gateway, indicating all icon alerts are being
   * cleared by the supervisor.
   * @param {number} timestamp The time from which notices should be cleared
   * @param {string} types (Optional) A list of notice types to resolve. If not set, all notices
   *                      will be resolved.
   */
  resolveNotices(timestamp, types) {
    let resolveMessage = {
      event: gatewayConfig.events.RESOLVE_NOTICES,
      data: {
        timestamp,
      },
    };
    if (types) {
      resolveMessage.data.types = types;
    }
    this.sendMessage(resolveMessage);
  }

  skipOnboardStep(onboardStepDetails) {
    const skipOnboardStepMessage = {
      event: gatewayConfig.events.SKIP_ONBOARD_STEP,
      data: {
        onboardProgressId: onboardStepDetails.progress.id,
      },
    };
    this.sendMessage(skipOnboardStepMessage);
  }

  /**
   * If the browser goes offline and then back on again, attempt to reconnect if necessary
   */
  tryReopen() {
    if (!this.ws && this.meetingConnectionState === gatewayConfig.connectionState.CONNECTING) {
      if (USE_MOCK_GATEWAY) {
        this.ws = 'MOCK_GATEWAY';
      } else {
        const { gatewayServer } = this.meetingDetails;
        this.ws = new ReconnectingWebSocket(
          `wss://${gatewayServer}${gatewayConfig.gatewayPath}`,
          [],
          gatewayConfig.reconnectingSocketOptions,
        );
      }
    }
  }

  /**
   * Leave a meeting.
   * @param {string} reason The reason this user is leaving the session.
   */
  leave(reason) {
    if (this.isAuthenticated()) {
      const leaveMessage = {
        event: gatewayConfig.events.LEAVE,
        data: {
          reason: reason,
        }
      };
      this.sendMessage(leaveMessage);
      this.meetingConnectionState = gatewayConfig.connectionState.DISCONNECTED;
    }
    this.close();
  }

  /**
   * Close down the SessionConnection object gracefully.
   */
  close() {
    this.clearPromiseFunctions();

    // Close websocket connection to prevent multiple reconnects from happening
    if (!USE_MOCK_GATEWAY && this.ws) {
      this.ws.close();
    }
  }

  /**
 * Handler for receipt of a websocket message, determines which method to call
 * based on message ID type.
 * @param {*} message Received web socket data packet
 */
  onWsMessage(message) {
    // Deserialize the data JSON into an object
    try {
      let parsedMessageData = JSON.parse(message.data);
      if (!Array.isArray(parsedMessageData)) {
        parsedMessageData = [parsedMessageData];
      }
      for (const parsedMessage of parsedMessageData) {
        // Based on the event type, call the relevant handler method
        switch (parsedMessage.event) {
          case gatewayConfig.events.AUTH_ACCEPT:
            // Successfully authenticated
            if (this.onAuthenticated) {
              this.onAuthenticated(parsedMessage.data ? parsedMessage.data : {});
            } else {
              // Unexpected authentication response received
              console.error(`Received authentication response out of order`);
            }
            break;
          case gatewayConfig.events.AUTH_REJECT:
            // Unable to authenticate
            if (this.onRejected) {
              this.onRejected(parsedMessage.data ? parsedMessage.data : {});
            } else {
              // Unexpected authentication response received
              console.error(`Received authentication rejection out of order`);
            }
            break;
          case gatewayConfig.events.MEETING_END:
            // Meeting was ended by server
            this.handleMeetingChange('end', parsedMessage.data);
            break;
          case gatewayConfig.events.TERMINATED:
            // Meeting was terminated by server. Terminated is different from ended, for example
            // a user may have opened up a new session of the same meeting so we want to terminate
            // this session.
            this.handleMeetingChange('terminated', parsedMessage.data);
            break;
          case gatewayConfig.events.VIDEO_START:
            // Webcam started sharing by other participant
            this.handleMediaChange('video', 'start', parsedMessage.data);
            break;
          case gatewayConfig.events.VIDEO_STOP:
            // Webcam stopped by other participant
            this.handleMediaChange('video', 'stop', parsedMessage.data);
            break;
          case gatewayConfig.events.SCREENSHARE_START:
            // Screenshare started sharing by other participant
            this.handleMediaChange('screenshare', 'start', parsedMessage.data);
            break;
          case gatewayConfig.events.SCREENSHARE_STOP:
            // Screenshare stopped by other participant
            this.handleMediaChange('screenshare', 'stop', parsedMessage.data);
            break;
          case gatewayConfig.events.AUDIO_START:
            // Audio call joined by other participant
            this.handleMediaChange('audio', 'start', parsedMessage.data);
            break;
          case gatewayConfig.events.AUDIO_STOP:
            // Audio call left by other participant
            this.handleMediaChange('audio', 'stop', parsedMessage.data);
            break;
          case gatewayConfig.events.AUDIO_MUTE:
            // Audio muted by other participant
            this.handleMediaChange('audio', 'mute', parsedMessage.data);
            break;
          case gatewayConfig.events.AUDIO_UNMUTE:
            // Audio unmuted by other participant
            this.handleMediaChange('audio', 'unmute', parsedMessage.data);
            break;
          case gatewayConfig.events.AUDIO_ACTIVE:
            // Other participant is actively talking
            this.handleMediaChange('audio', 'active', parsedMessage.data);
            break;
          case gatewayConfig.events.AUDIO_INACTIVE:
            // Other participant has stopped actively talking
            this.handleMediaChange('audio', 'inactive', parsedMessage.data);
            break;
          case gatewayConfig.events.JOIN:
            // Participant has joined the conference
            this.handleParticipantChange('add', parsedMessage.data);
            break;
          case gatewayConfig.events.LEAVE:
            // Participant has left the conference
            this.handleParticipantChange('remove', parsedMessage.data);
            break;
          case gatewayConfig.events.MEETING_UPDATE:
            // A change has been made to meeting properties
            this.handleMeetingChange('update', parsedMessage.data);
            break;
          case gatewayConfig.events.PONG:
            // Ignore keep-alive packets
            break;
          case gatewayConfig.events.QRCODE:
            // There is now a QR code available to display
            this.handleMeetingChange('update', {
              meeting: {
                qrCodeUrl: parsedMessage.data.url,
              }
            });
            break;
          case gatewayConfig.events.CHATMSG:
            // A chat message was received from the gateway
            this.handleChatMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.RECEIVE_NOTICE:
            // A notice message was received from the gateway
            this.handleReceiveNoticeMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.RESOLVE_NOTICES:
            // A message that certain notifications have been resolved received from gateway
            this.handleResolveNoticesMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.LOWER_HAND:
            // The raise hand has been removed remotely
            this.handleLowerHandMessage(parsedMessage.data)
            break;
          case gatewayConfig.events.SKIP_ONBOARD_STEP:
            // A skip onboard step message was received from the gateway
            this.handleSkipOnboardStepMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.ONBOARD_PROGRESS_UPDATE:
            // A media progress update was requested by the gateway
            this.handleOnboardProgressUpdateMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.ID_ANALYSIS_UPDATE:
            this.handleIdAnalysisUpdateMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.SCREEN_SHARE_TYPE:
            this.handleScreenTypeUpdateMessage(parsedMessage.data);
            break;
          case gatewayConfig.events.ERROR:
            // An error message received from the gateway
            console.error('Received error from gateway', {parsedMessage});
            break;
          case gatewayConfig.events.UNSUPPORTED:
            // We sent an unrecognised message to the gateway.
            console.info('Unsupported message received from gateway', {parsedMessage});
            break;
          default:
            console.error(`Received unknown message of type ${parsedMessage.event}`);
        }
      }
    } catch (error) {
      // Error occurred parsing incoming message
      console.error(`Unable to parse incoming gateway message`, error);
    }
  }

  /**
   * When the websocket is closed, make sure any timers associated are stopped,
   * and handle disconnection if it was unexpected
   */
  onWsClose() {
    clearInterval(this.pingInterval);
    this.socketOpen = false;

    if (this.onClosed) {
      this.onClosed();
    } else if (this.isConnected()) {
      // We aren't expecting a close, this must be a disruption to the server or
      // network connection.
      const { onDisconnected } = this.handlers;
      if (onDisconnected) {
        for (const callbackFunc of onDisconnected) {
          callbackFunc("Socket closed");
        }
      }
      this.attemptingReconnection = true;

      // We automatically try to reconnect, and we will need to re-authenticate
      this.meetingConnectionState = gatewayConfig.connectionState.CONNECTING;
      this.wsQueue = [];
      this.onAuthenticated = (data) => (this.handleAuthenticated(data, () => { /* no callback */ }));
      this.onClosed = () => { this.handleClosed(() => { /* no callback */ }) };
      this.onRejected = (data) => { this.handleRejected(data, () => { /* no callback */ }) };
    }
  }

  /**
   * When the websocket is opened, send any data that was flagged as pending
   * and start a keep-alive ping timer.
   */
  onWsOpen() {
    this.pingInterval = setInterval(this.ping.bind(this), gatewayConfig.keepAliveInterval);
    this.socketOpen = true;
    this.meetingConnectionState = gatewayConfig.connectionState.UNAUTHENTICATED;

    // Send initial authentication.
    const authenticationMessage = {
      event: gatewayConfig.events.IDENTITY,
      data: {
        slotId: this.evigSlotId,
        userId: this.evigUserId,
        accessToken: AuthService.getAccessToken(),
        userAgent: window.navigator.userAgent,
      }
    };
    this.sendMessage(authenticationMessage);

    // Send an information message with extra details to the GW after authentication.
    let browserInfo = Bowser.parse(window.navigator.userAgent);
    browserInfo.userId = this.evigUserId;
    browserInfo.slotId = this.evigSlotId;
    browserInfo.userAgent = window.navigator.userAgent;
    this.logInfo('browser_details', browserInfo);

    // Mock gateway should immediately respond
    if (USE_MOCK_GATEWAY) {
      this.onWsMessage({data: JSON.stringify(MOCK_GATEWAY_RESPONSE.accept)});
      this.onWsMessage({data: JSON.stringify(MOCK_GATEWAY_RESPONSE.addUser)});
      //this.onWsMessage({data: JSON.stringify(MOCK_GATEWAY_RESPONSE.videoStart)});
      //this.onWsMessage({data: JSON.stringify(MOCK_GATEWAY_RESPONSE.audioStart)});
      //this.onWsMessage({data: JSON.stringify(MOCK_GATEWAY_RESPONSE.audioUnmute)});
      //this.onWsMessage({data: JSON.stringify(MOCK_GATEWAY_RESPONSE.screenshareStart)});
    }
  }

  onRaiseHand(isRaiseHandAction) {
    const raiseHandMessage = {
      event: isRaiseHandAction ? gatewayConfig.events.RAISE_HAND : gatewayConfig.events.LOWER_HAND,
      data: {
        timestamp: Date.now(),
      }
    }
    this.sendMessage(raiseHandMessage);
  }

  /**
   * Send a simple ping message to the websocket.
   */
  ping() {
    const message = { event: gatewayConfig.events.PING };
    this.sendMessage(message);
  }

  /**
   * Construct the message packet that will encapsulate anything sent to the
   * BBB gateway.
   * @param {object} message The message object
   * @param {string} message.event The type of message
   * @param {*} message.data The data of the message
   */
  sendMessage(message) {
    return new Promise((resolve, reject) => {
      const { ws } = this;
      const messageCallback = (error) => {
        if (error) {
          reject(error);

        } else {
          resolve();

        }
      };

      // If there is an active websocket connection, serialize and then send the
      // message to the gateway server.
      if (this.socketIsActive()) {
        // Hold back all messages except identification if we aren't yet authenticated.
        if (this.isAuthenticated() || message.event === gatewayConfig.events.IDENTITY) {
          if (!USE_MOCK_GATEWAY) {
            // Add a timestamp
            message = this.addTimestamp(message);
            ws.send(JSON.stringify(message), messageCallback);
          } else {
            console.info('Mock gateway message', message);
          }
        } else {
          this.wsQueue.push({ message, callback: messageCallback });
        }
      } else if (message.event !== gatewayConfig.events.LEAVE && message.event !== gatewayConfig.events.PING) {
        // No need to queue ping or disconnect messages
        this.wsQueue.push({ message, callback: messageCallback });
      }
    });
  }

  /**
   * Add a timestamp to the outgoing message for ping only
   * @param {object} message The json object being sent to the gateway.
   * @returns {object} modified message object
   */
  addTimestamp(message) {
    if (message.event !== 'ping' && message.event !== 'info') {
      return message;
    } else {
      message.timestamp = Math.round((new Date()).getTime() / 1000);
      return message;
    }
  }

  /**
   * Test whether the websocket to the back-end server is open
   * and available for use.
   * @returns {boolean} True if the websocket connection is open.
   */
  socketIsActive() {
    if (USE_MOCK_GATEWAY) {
      return this.ws === 'MOCK_GATEWAY';
    } else {
      return this.ws.readyState === WebSocket.OPEN;
    }

  }

  /**
   * Generate a default set of properties for a managed participant in the meeting
   */
  //TODO [tech-debt] update property screenshare to screenShare, plus all usages, to match property from api
  static defaultUserProperties() {
    return {
      userName: undefined,
      userType: undefined,
      video: {
        stream: undefined,
        streaming: false,
      },
      audio: {
        streaming: false,
        active: false,
        muted: gatewayConfig.autoMuteOnJoin,
      },
      screenshare: {
        streaming: false,
      },
    };
  }


  /**
   * Handle a change in media state message received from the gateway.
   * @param {string} mediaType The type of media, from 'video', 'audio' or 'screenshare'.
   * @param {string} change The type of update, from 'start', 'stop', 'mute', 'unmute', 'active', 'inactive'.
   * @param {object} data The message data received.
   */
  handleMediaChange(mediaType, change, data) {
    const updatedUser = data.userId;

    if (!updatedUser) {
      // No user given, ignore
      return;
    }

    // Update meeting details with this information
    if (!this.meetingDetails.participants.hasOwnProperty(updatedUser)) {
      // We don't yet know about this user, just add their media information.
      this.meetingDetails.participants[updatedUser] = GatewayService.defaultUserProperties();
    }

    let newMediaState = {...this.meetingDetails.participants[updatedUser][mediaType]};
    switch (change) {
      case 'start':
        if (newMediaState.streaming) {
          console.error(`${mediaType} started for user ${updatedUser} after stream already active.`);
        }
        newMediaState.streaming = true;
        if (data.stream) {
          newMediaState.stream = data.stream;
        }
        break;
      case 'stop':
        if (!newMediaState.streaming) {
          console.error(`${mediaType} stopped for user ${updatedUser} after stream already inactive.`);
        }
        newMediaState.streaming = false;
        break;
      case 'mute':
        if (newMediaState.muted) {
          console.error(`${mediaType} muted for user ${updatedUser} after mute already on.`);
        }
        newMediaState.muted = true;
        break;
      case 'unmute':
        if (!newMediaState.muted) {
          console.error(`${mediaType} unmuted for user ${updatedUser} after mute already off.`);
        }
        newMediaState.muted = false;
        break;
      case 'active':
        newMediaState.active = true;
        break;
      case 'inactive':
        newMediaState.active = false;
        break;
      default:
        break;
    }
    this.meetingDetails.participants[updatedUser][mediaType] = newMediaState;

    // Now call any handler functions registered for a media change
    const { onMediaChange } = this.handlers;
    if (onMediaChange.hasOwnProperty(mediaType)) {
      for (const mediaHandler of onMediaChange[mediaType]) {
        mediaHandler(change, updatedUser, this.meetingDetails.participants[updatedUser]);
      }
    }
  }

  /**
   * Handle a change in meeting participants message received from the gateway.
   * @param {string} type The type of update, from 'add', 'remove'.
   * @param {object} data The message data received.
   */
  handleParticipantChange(type, data) {
    const updatedUser = data.userId;

    if (!updatedUser) {
      // No user given, ignore
      return;
    }

    // Update meeting details with this information
    let newParticipantDetails = null;
    switch (type) {
      case 'add':
        if (this.meetingDetails.participants.hasOwnProperty(updatedUser)) {
          console.error(`Duplication join notification received for user ${updatedUser}`);
          newParticipantDetails = this.meetingDetails.participants[updatedUser];
        } else {
          newParticipantDetails = GatewayService.defaultUserProperties();
        }
        assign(newParticipantDetails, {
          userName: data.userName,
          userType: data.userType,
        });
        this.meetingDetails.participants[updatedUser] = newParticipantDetails
        break;
      case 'remove':
        if (!this.meetingDetails.participants.hasOwnProperty(updatedUser)) {
          console.error(`Departure notification received for user ${updatedUser} not in meeting`);
        } else {
          delete this.meetingDetails.participants[updatedUser];
        }
        break;
      default:
        break;
      }

    // Now call any handler functions registered for a participant change
    const { onParticipantChange } = this.handlers;
    if (this.handlers.onParticipantChange.hasOwnProperty(type)) {
      for (const participantChangeHandler of onParticipantChange[type]) {
        participantChangeHandler(updatedUser, newParticipantDetails, data.reason);
      }
    }

  }

  /**
   * Handle a change in meeting details message received from the gateway.
   * @param {string} type The type of update, from 'update', 'end'.
   * @param {object} data The message data received.
   */
  handleMeetingChange(type, data) {
    let updatedMeetingDetails = data.meeting;
    if (type === 'update') {
      if (!updatedMeetingDetails) {
        // No meeting data given, invalid update
        return;
      }
      // Update meeting details with this information
      Object.keys(updatedMeetingDetails).forEach((key) => {
        this.meetingDetails.meeting[key] = updatedMeetingDetails[key];
      });
    } else if (type === 'end') {
      // End meeting
      this.meetingConnectionState = gatewayConfig.connectionState.ENDED;
    }

    // Now call any handler functions registered for a participant change
    const { onMeetingChange } = this.handlers;
    if (this.handlers.onMeetingChange.hasOwnProperty(type)) {
      for (const meetingChangeHandler of onMeetingChange[type]) {
        meetingChangeHandler(updatedMeetingDetails, data.reason);
      }
    }
  }

  /**
   * Handle a chat message received from the gateway.
   * @param {object} data The message data received.
   */
  handleChatMessage(data) {
    // Now call any handler functions registered for a chat message received
    const { onChatNotification } = this.handlers;

    // Unsanitize the chat text if needed.
    if (data.hasOwnProperty('text')) {
      data.text = this.unsanitizeChatText(data.text);
    }

    for (const chatMessageHandler of onChatNotification) {
      chatMessageHandler(data);
    }
  }

  /**
   * Handle successful authentication to the gateway server.
   */
  handleAuthenticated(data, resolve) {
    this.meetingConnectionState = gatewayConfig.connectionState.ACTIVE;
    this.clearPromiseFunctions();
    // Resend any queued messages that were waiting for authentication
    while (this.wsQueue.length > 0) {
      const pendingMessage = this.wsQueue.pop();
      if (!USE_MOCK_GATEWAY) {
        const sendMessage = JSON.stringify(this.addTimestamp(pendingMessage.message));
        this.ws.send(sendMessage, pendingMessage.callback);
      }
    }

    if (this.attemptingReconnection) {
      this.attemptingReconnection = false;
    }

    // Execute handlers for connection success.
    const { onConnected } = this.handlers;
    if (onConnected) {
      for (const connectHandler of onConnected) {
        connectHandler();
      }
    }

    return resolve(data);
  }

  /**
   * Handle a close socket request received during authentication.
   */
  handleClosed(reject) {
    if (this.attemptingReconnection) {
      // If we are trying to reconnect, don't trigger permanent socket close, 
      // since we will automatically try again
      return reject("Gateway socket reconnect was unsuccessful");
    } else {
      this.close();
      return reject("Socket closed unexpectedly");
    }
  }

  /**
   * Handle rejected authentication from the session server.
   */
  handleRejected(data, reject) {
    this.close();
    return reject(data.error);
  }

  /**
   * Handle a received notice message received from the gateway.
   * @param {object} data The message data received.
   */
   handleReceiveNoticeMessage(data) {
    // Just call any handler functions registered for this type of message
    const { onReceiveNotice } = this.handlers;

    for (const receiveMessageHandler of onReceiveNotice) {
      receiveMessageHandler(data);
    }
  }

  /**
   * Handle a resolved notices message received from the gateway.
   * @param {object} data The message data received.
   */
   handleResolveNoticesMessage(data) {
    // Just call any handler functions registered for this type of message
    const { onResolveNotices } = this.handlers;

    for (const resolveMessageHandler of onResolveNotices) {
      resolveMessageHandler(data.types, data.timestamp, data.resolvedBy);
    }
  }

  /**
   * Handle a lower hand message received from the gateway.
   * @param {object} data The message data received.
   */
   handleLowerHandMessage(data) {
    // Just call any handler functions registered for this type of message
    const { onResolveNotices } = this.handlers;

    for (const resolveMessageHandler of onResolveNotices) {
      resolveMessageHandler([NOTICE_TYPES.RAISEHAND], data.timestamp, null);
    }
  }

  /**
   * Handle a skip onboard step message received from the gateway
   * @param {object} data The message data received.
   */
  handleSkipOnboardStepMessage(data) {
    const { onSkipOnboardStep } = this.handlers;

    for (const skipOnboardStepHandler of onSkipOnboardStep) {
      skipOnboardStepHandler(data)
    }
  }

  /**
   * Handle an onboard progress update message received from the gateway
   * @param {object} data The message data received.
   */
  handleOnboardProgressUpdateMessage(data) {
    const { onOnboardProgressUpdate } = this.handlers;

    for (const onboardProgressUpdateHandler of onOnboardProgressUpdate) {
      onboardProgressUpdateHandler(data);
    }
  }

  /**
   * Handle an ID analysis update message received from the gateway
   * @param {object} data The message data received.
   */
  handleIdAnalysisUpdateMessage(data) {
    const { onIdAnalysisUpdate } = this.handlers;

    for (const onIdAnalysisUpdateHandler of onIdAnalysisUpdate) {
      onIdAnalysisUpdateHandler(data);
    }
  }

  /**
   * Handle a screen share type message received from the gateway
   * @param {object} data The message data received.
   */
  handleScreenTypeUpdateMessage(data) {
    const { onScreenTypeUpdate } = this.handlers;

    for (const onScreenTypeUpdateHandler of onScreenTypeUpdate) {
      onScreenTypeUpdateHandler(data);
    }
  }

  /**
   * Clear any pending promise functions
   */
  clearPromiseFunctions() {
    this.onAuthenticated = null;
    this.onClosed = null;
    this.onRejected = null;
  }

  /**
   * If the browser window is closed, try to notify the back-end server first
   */
  handleWindowUnload() {
    if (this.isConnected()) {
      this.meetingConnectionState = gatewayConfig.connectionState.DISCONNECTED;
      this.leave();
    }
  }

  /**
   * Check whether the session is active and authenticated.
   */
  isAuthenticated() {
    return this.meetingConnectionState === gatewayConfig.connectionState.ACTIVE;
  }

  /**
   * Check whether the websocket is open for messaging, but may not be authenticated.
   */
  isConnected() {
    return this.meetingConnectionState === gatewayConfig.connectionState.UNAUTHENTICATED
      || this.meetingConnectionState === gatewayConfig.connectionState.ACTIVE;
  }

  /**
   * Sanitize the chat text for sending to the gateway, removing any weird characters.
   * @param {string} text The text to sanitize
   * @returns {string} The formatted text to be sent
   */
  sanitizeChatText(text) {
    return escape(text);
  }

  /**
   * Reverse the sanitizing of chat text upon receiving from the gateway, restoring to its original format.
   * @param {string} text The text to unsanitize
   * @returns {string} The formatted text to be rendered in JSX (which will sanitize upon render)
   */
  unsanitizeChatText(text) {
    return unescape(text);
  }
}
