import { omit } from 'lodash';
import { FEATURE_TOGGLES } from './featureToggles';
import { SEARCH_OPTIONS } from './search';
import { SESSION_TIMES } from './examSessions';

const START_TITLE = 'Are you ready to start your session?';
const END_TITLE = 'Are you sure you want to end your session?';

const SHIFT_STATUSES = {
  NOT_STARTED: {
    mapper: 'NOT_STARTED',
    display: 'Not started',
    class: 'notStarted',
    action: 'Start supervising',
    warningTitle: START_TITLE,
  },
  ACTIVE: {
    mapper: 'ACTIVE',
    display: 'In progress',
    class: 'active',
    action: 'End supervising',
    warningTitle: END_TITLE,
    adminAction: 'End session',
  },
  INACTIVE: {
    mapper: 'INACTIVE',
    display: 'Offline',
    class: 'inactive',
    action: 'End supervising',
    warningTitle: END_TITLE,
    adminAction: 'End session',
  },
  FINISHED: {
    mapper: 'FINISHED',
    display: 'Finished',
    class: 'finished',
    action: 'Start supervising',
    warningTitle: START_TITLE,
  },
};

export default SHIFT_STATUSES;

export const SHIFT_FIELDS = {
  id: {
    display: 'ID',
    mapper: 'id',
  },
  shiftSession: {
    display: 'Session',
  },
  staffId: {
    display: 'ID',
    mapper: 'user.externalId',
  },
  username: {
    display: 'Username',
    mapper: 'user.userName',
  },
  staffName: {
    display: 'Supervisor name',
    mapper: 'user.fullName',
  },
  poolName: {
    display: 'Pool name',
    mapper: 'allocationPool.name',
  },
  allocationCount: {
    display: 'No. of slots allocated',
    mapper: 'allocationCount',
    toggle: FEATURE_TOGGLES.FETCH_SHIFT_ALLOCATED_SLOTS,
  },
  notes: {
    display: 'Note',
    mapper: 'notes',
  },
  status: {
    display: 'Status',
    mapper: 'status',
  },
  action: {
    display: 'Action',
  },
};

export const SHIFT_REQUEST_FIELDS = {
  supervisorId: {
    type: 'userSelect',
    display: 'Supervisor',
    required: true,
  },
  shiftDate: {
    type: 'date',
    display: 'Date',
    required: true,
    maxLength: 255,
  },
  session: {
    type: 'select',
    display: 'Session',
    required: true,
    allowedValues: Object.entries(omit(SESSION_TIMES, ['all'])).map(([key, value]) => Object.assign([],
      {
        'key': key,
        'value': value.displayName
      })),
  },
  allocationPoolId: {
    type: 'select',
    display: 'Allocation pool',
    required: true,
    valuesFromState: 'allocationPools',
  },
  status: {
   type: 'select',
   display: 'Session status',
   required: true,
   disabled: true, // not allowing setting of different shift statuses yet
   allowedValues: Object.entries(SHIFT_STATUSES).map(([key, value]) => Object.assign([],
     {
       'key': key,
       'value': value.display
     })),
  },
  notes: {
   type: 'textfield',
   display: 'Note',
   required: false,
  },
}

export const SHIFT_SEARCH_OPTIONS = [SEARCH_OPTIONS.date, SEARCH_OPTIONS.session];

export const SHIFT_SEARCH_FIELDS = [
  {
    component: SEARCH_OPTIONS.session,
    gridSize: 7,
  },
]
