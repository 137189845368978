import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { AuthConsumer, authContext } from '../authContext';
import Can, { CanThey } from '../components/Can';
import AddButton from '../components/form/AddButton';
import ScrollButtons from '../components/form/ScrollButtons';
import SearchFormByOptions from '../components/search/SearchFormByOptions';
import ShiftForm from '../components/form/ShiftForm';
import ShiftTable from '../components/table/ShiftTable';
import ShiftUploadForm from '../components/form/ShiftUploadForm';
import UploadButton from '../components/form/UploadButton';
import Routing from '../utils/Routing';
import { SEARCH_ROSTER_LABELS } from '../constants/search';
import { SESSION_TIMES } from '../constants/examSessions';
import { SHIFT_SEARCH_OPTIONS, SHIFT_SEARCH_FIELDS } from '../constants/shift';
import { EVIG_FILE_TYPE } from '../constants/upload';

const styles = {
  buttonContainer: {
    '& button': {
      marginLeft: 1,
    },
  },
};

function ManageRoster(_props) {
  const location = useLocation();
  const uriParams = useParams();
  const { capabilityContextAccess, isPending } = useContext(authContext);
  const [canEdit, setCanEdit] = useState(false);
  const [canManageAssignments, setCanManageAssignments] = useState(false);
  const [canViewExam, setCanViewExam] = useState(false);
  const [hasShiftUpdated, setHasShiftUpdated] = useState(false);

  if (!uriParams.session) {
    uriParams.session = SESSION_TIMES.am.value;
  }

  useEffect(() => {
    const setUserAccess = () => {
      setCanEdit(CanThey(capabilityContextAccess, false, 'MANAGE_SHIFT'));
      setCanManageAssignments(CanThey(capabilityContextAccess, false, 'MANAGE_ASSIGNMENTS'));
      setCanViewExam(CanThey(capabilityContextAccess, false, 'VIEW_EXAM'));
    };
    !isPending && setUserAccess();
  }, [capabilityContextAccess, isPending, setCanEdit, setCanManageAssignments, setCanViewExam]);

  const canNavigate = (params) => {
    if (!params) { return false; }
    const { date, session } = params;
    return Boolean(date && session);
  };

  const getRoute = (params) => {
    const { date, session } = params;
    return `${Routing.MANAGE_ROSTER}/date/${date}/session/${session}`;
  };

  const rosterPage = (
    <ScrollButtons>
      <Typography variant="h2">Staff roster</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" py={2}>
        <SearchFormByOptions
          key={location.key}
          canNavigate={canNavigate}
          fieldsToShow={SHIFT_SEARCH_OPTIONS}
          getRoute={getRoute}
          labels={SEARCH_ROSTER_LABELS}
          searchFields={SHIFT_SEARCH_FIELDS}
          uriParams={uriParams}
        />
        {canEdit &&
          <Box display="flex" justifyContent="flex-end" sx={styles.buttonContainer}>
            <AddButton
              id="add-shift-button"
              heading="Add session"
              title="Add session"
              content={<ShiftForm setHasShiftUpdated={setHasShiftUpdated}/>}
            />
            {/* the ShiftUploadForm is cloned and the setParams is replaced with a real func,
                but since it's required, need to send an empty func */}
            <UploadButton
              id="upload-sessions-button"
              title="Upload sessions"
              evigFileType={EVIG_FILE_TYPE.SHIFTS}
              additionalFieldsForm={<ShiftUploadForm setParams={() => { /* do nothing */ }} />}
              setHasUpdated={setHasShiftUpdated}
            />
          </Box>
        }
      </Box>
      {canNavigate(uriParams) &&
        <ShiftTable
          key={location.key}
          canEdit={canEdit}
          canManageAssignments={canManageAssignments}
          canViewExam={canViewExam}
          date={uriParams.date}
          hasShiftUpdated={hasShiftUpdated}
          session={uriParams.session}
          setHasShiftUpdated={setHasShiftUpdated}
        />
      }
    </ScrollButtons>
  )

  return (
    <AuthConsumer>
      {({capabilityContextAccess}) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={["MANAGE_SHIFT", "VIEW_SHIFT"]}
          yes={() => (rosterPage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageRoster;
