import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import { has, isEmpty, omit } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { AuthProvider } from '../../authContext';
import UserService from '../../services/UserService';
import getAppOptions from '../../utils/getAppOptions';
import { getCapabilityContextAccessList } from '../../utils/getAccessDetailsHelper';
import Routing from '../../utils/Routing';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';
import { MSG_404, NO_ACCESS } from '../../constants/login';

function Auth(props) {
  const { accessTokenFromIdp, getAccessTokenFunc, isAuthenticated } = props;
  const location = useLocation();

  let [authObject, setAuthObject] = useState({
    authorised: false,
    userInfo: null,
    user: undefined,
    isPending: true,
    isErrored: false,
    errorType: undefined,
    features: {},
    theme: undefined,
    contentOverrides: undefined,
    getAccessToken: null,
  });

  const [capabilityContextAccess, setCapabilityContextAccess] = useState({})

  // Determine if only basic user information should be retrieved rather than
  // full role assignments tree, which is not needed for the student session
  // page.
  const isStudentPage = matchPath(
    { path: Routing.path(Routing.JOIN_SESSION, ':slotId'), exact: true },
    location?.pathname);
  const getAssignments = !isStudentPage;

  // get whether we're still in the Okta flow redirecting back to original page
  // if REACT_APP_AUTHCONFIG_REDIRECT_URI_PARTIAL is empty, it still won't match the "/" pathname of the site root
  const isLoginRedirect = location?.pathname === process.env.REACT_APP_AUTHCONFIG_REDIRECT_URI_PARTIAL;

  const getEvigUserFromAccessToken = useCallback(async (accessToken) => {
    const accessTokenData = jwtDecode(accessToken);
    if (!accessTokenData || !has(accessTokenData, process.env.REACT_APP_AUTHCONFIG_USER_ATTRIBUTE)) {
      console.error("Unable to extract account login from token");
      return { error: 'oktaError' };
    }

    const getUserFunction = getAssignments
      ? UserService.getUserWithRoleAssignmentsByUsername
      : UserService.getUserByUsername;

    try {
      return await getUserFunction(accessTokenData[process.env.REACT_APP_AUTHCONFIG_USER_ATTRIBUTE]);
    } catch (error) {
      if (error.message === MSG_404) {
        return { error: NO_ACCESS }
      }
      return { error: 'backendError' }
    }
  }, [getAssignments]);

  const checkUser = useCallback(async () => {
    const evigUser = await getEvigUserFromAccessToken(accessTokenFromIdp, getAssignments);
    const appOptions = await getAppOptions();

    if (!has(evigUser, 'error')) {
      let access = {};
      if (getAssignments) {
        access = getCapabilityContextAccessList(evigUser);
      }

      if (!isEmpty(access) || (appOptions?.features[FEATURE_TOGGLES.JITA] && evigUser.shiftStaff) || !getAssignments) {
        setAuthObject({
          ...authObject,
          authorised: true,
          isErrored: false,
          isPending: false,
          user: omit(evigUser, 'roleAssignments'),
          features: appOptions?.features,
          theme: appOptions?.themeOverrides,
          contentOverrides: appOptions?.contentOverrides,
          getAccessToken: getAccessTokenFunc,
        });
        setCapabilityContextAccess(access);
      } else {
        evigUser['error'] = NO_ACCESS;
      }
    }
    if (has(evigUser, 'error')) {
      setAuthObject({
        ...authObject,
        authorised: false,
        isPending: false,
        isErrored: true,
        errorType: evigUser.error,
      });
    }
  }, [accessTokenFromIdp, getAssignments, getAccessTokenFunc, getEvigUserFromAccessToken, authObject]);

  useEffect( () => {
    // don't check the user's access until we've completed the login flow redirects, and they are authenticated
    if (isAuthenticated && !isLoginRedirect && isEmpty(authObject.user) && !authObject.isErrored) {
      checkUser();
    }
  }, [authObject.isErrored, authObject.user, checkUser, isAuthenticated, isLoginRedirect] );

  return (
    <AuthProvider value={{ ...authObject, capabilityContextAccess, setCapabilityContextAccess }}>
      { props.children }
    </AuthProvider>
  );

}

Auth.propTypes = {
  accessTokenFromIdp: PropTypes.string,
  children: PropTypes.object,
  getAccessTokenFunc: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default Auth;
