import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Notice from '../notification/Notice';
import FlagService from '../../services/FlagService';

function ProcessRecordingRequestForm(props) {
  const [requestError, setRequestError] = useState();
  const [requestPending, setRequestPending] = useState(false);

  const { examSessionId, onClose, processRecordingFlag, updateSlotFlagCount } = props;

  const saveSession = async () => {
    try {
      setRequestError();
      setRequestPending(true);
      const response = await FlagService.addExamSessionFlag({
        flagId: processRecordingFlag.id,
        examSlotId: examSessionId,
        note: 'Exam slot has been manually flagged for recording processing',
        timestamp: new Date().toISOString()
      });
      updateSlotFlagCount(response);
      setRequestPending(false);
      handleCloseForm(true);
    } catch (error) {
      setRequestPending(false);
      setRequestError(error.message ? error.message : 'Sorry, we were unable to update the exam session');
    }
  }

  const handleCloseForm = (requestSuccessful = false) => {
    onClose(requestSuccessful);
  }

  return (
    <>
      {requestError &&
        <Notice noticeType="error">{requestError}</Notice>
      }
      <Typography sx={{ my: 1 }}>Proceeding with this action will queue the exam recording for processing.</Typography>
      <Typography sx={{ my: 1 }}>Please note, video processing will take a few hours.</Typography>
      <Box display="flex" justifyContent="flex-end">
        {requestPending &&
          <CircularProgress disableShrink />
        }
        {!requestPending &&
          <>
            <Button
              sx={{ mx: 1 }}
              color="secondary"
              onClick={() => handleCloseForm(false)}
            >Close</Button>
            <Button
              sx={{ ml: 1 }}
              color="primary"
              variant="contained"
              onClick={saveSession}
            >Yes</Button>
          </>
        }
      </Box>
    </>
  )
}

ProcessRecordingRequestForm.propTypes = {
  examSessionId: PropTypes.string.isRequired,
  processRecordingFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  updateSlotFlagCount: PropTypes.func.isRequired,
}

export default ProcessRecordingRequestForm;
