import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import StatusChip from './StatusChip';
import DeletedDatePopup from '../popup/DeletedDatePopup';
import { EXAM_SESSION_DELETION_STATUS } from '../../constants/examSessions';

function DeletionStatusChip(props) {
  const { deletionStatus, deletedDate } = props;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}>
      <StatusChip styles={EXAM_SESSION_DELETION_STATUS} status={deletionStatus} />
      {props.deletionStatus === EXAM_SESSION_DELETION_STATUS.DELETED.value &&
        <DeletedDatePopup deletedDate={deletedDate} />}
    </Box>
  );
}

DeletionStatusChip.propTypes = {
  deletionStatus: PropTypes.string,
  deletedDate: PropTypes.string,
}

export default DeletionStatusChip;