import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Card, CardContent, CardHeader, CardMedia, CircularProgress, Stack }  from '@mui/material';
import Notice from '../notification/Notice';
import { MSG_404 } from '../../constants/login';
import { NOTICE_TYPES } from '../../constants/noticeTypes';

function ImageFromData(props) {
  const { imageAlt, imageText, serviceFunc } = props;
  const [image, setImage] = useState('');
  const [imageError, setImageError] = useState('');
  const controllerRef = useRef(new AbortController());

  const getImage = useCallback(() => {
    serviceFunc(controllerRef.current.signal)
      .then(response => response.blob())
      .then((blobData) => {
        const imageFromApi = URL.createObjectURL(blobData);
        setImageError('');
        setImage(imageFromApi);

      })
      .catch((error) => {
        if (!controllerRef.current?.signal?.aborted) {
          console.error(`Unable to download image: `, error);
          const imageError = error?.message === MSG_404 ? 'Image not found' : 'Unable to download image';
          setImageError(imageError);
        }
      });
  }, [serviceFunc]);

  useEffect(() => {
    getImage();
    const controller = controllerRef?.current;
    return () => { controller.abort() }
  }, [getImage])

  return (
    <>
      <Card sx={{ position: 'relative' }}>
        {imageText &&
          <CardHeader title={imageText}/>
        }
        {isEmpty(image) && isEmpty(imageError) &&
          <Stack alignItems="center"><CircularProgress /></Stack>
        }
        {!isEmpty(image) &&
          <CardMedia
            component="img"
            sx={{ maxWidth: '100%', height: 'auto', maxHeight: '100%', }}
            src={image}
            title={imageAlt}
          />
        }
        {!isEmpty(imageError) &&
          <CardContent>
            <Notice noticeType={NOTICE_TYPES.notice}><Box padding={2}>{imageError}</Box></Notice>
          </CardContent>
        }
      </Card>
    </>
  )
}

ImageFromData.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  imageText: PropTypes.string,
  serviceFunc: PropTypes.func.isRequired,
}

export default ImageFromData;