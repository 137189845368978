import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import StreamsCheck from '../popup/StreamsCheck';
import endEchoTest from './helper/endEchoTest';
import updateDevices from './helper/updateDevices';
import logToGateway from '../../utils/logToGateway';
import { ACTIONS } from '../../constants/monitorSessions';

function AudioVisualCheck(props) {

  const { state, dispatch } = useContext(monitoringContext);
  const avCheckStatus = useRef(state.avCheckStatus);
  const { examDetails,
          audioStatus,
          setAudioStatus,
          setMediaShareError,
        } = props;

  const [audioInputDevice, setAudioInputDevice] = useState(null);
  const [audioOutputDevice, setAudioOutputDevice] = useState(null);
  const [videoDevice, setVideoDevice] = useState(null);

  const setAllDevices = (devices) => {
    devices?.audioInputDevice && setAudioInputDevice(devices.audioInputDevice);
    devices?.audioOutputDevice && setAudioOutputDevice(devices.audioOutputDevice);
    devices?.videoDevice && setVideoDevice(devices.videoDevice);
  };

  const closeCheck = (errors) => {
    avCheckStatus.current = true;
    if (has(errors, 'webcam')) {
      console.error(`Webcam preview error detected: `, errors.webcam);
      logToGateway(has(state, examDetails.id) ? state[examDetails.id]['gatewayService'] : undefined,
        'supervisor_video_preview', 
        { error: errors.webcam, source: 'webcamPreview'},
        has(examDetails, 'connectionDetails.connectionProps.user.id') ? examDetails.connectionDetails.connectionProps.user.id : 'no_user_id',
          examDetails.id);
          setMediaShareError('There was a problem sharing your webcam. Please check your device and allow access before trying again.');
      
    }
    dispatch({type: ACTIONS.UPDATE_DEVICES, value: {devices: { audioInputDevice, audioOutputDevice, videoDevice }, status: true}});
  };

  const endEchoTestWrapper = () => {
    const audioStreamService = has(state, examDetails.id) ? state[examDetails.id]['audioService'] : undefined;
    const logDetails = {
      gatewayService: state[examDetails.id]?.['gatewayService'],
      logType: 'supervisor_audio_transfer',
      userId: examDetails.connectionDetails?.connectionProps?.user.id || 'no_user_id',
      slotId: examDetails.id,
    };
    const setEndEchoTestError = () => {
      setMediaShareError('There was a problem accessing your microphone. Please check your device and allow access before trying again.');
    };

    endEchoTest(audioStatus, audioStreamService, logDetails, setEndEchoTestError);
  };

  const updateDevicesWrapper = (updatedDevices) => {
    const devices = { audioInputDevice, audioOutputDevice, videoDevice };
    updateDevices(devices, updatedDevices, state[examDetails.id].audioService, setAllDevices, setAudioStatus, setMediaShareError);
  };

  return (
    <>
      { !avCheckStatus.current &&
        <StreamsCheck
          open={!avCheckStatus.current}
          onClose={closeCheck}
          onConfirmAudio={endEchoTestWrapper}
          updateDevices={updateDevicesWrapper}
          devices={{ audioInputDevice, audioOutputDevice, videoDevice }}
          audioConnectionState={audioStatus}
          checkScreenshare={false}
          troubleshootLabel="Cancel"
        />
      }

    </>
  )
}

AudioVisualCheck.propTypes = {
  classes: PropTypes.object,
  examDetails: PropTypes.object.isRequired,
  audioStatus: PropTypes.string.isRequired,
  setAudioStatus: PropTypes.func.isRequired,
  setMediaShareError: PropTypes.func.isRequired,
};

export default AudioVisualCheck;
