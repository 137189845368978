import { isEmpty } from 'lodash';
import mockRequest from '../utils/mockRequest';
import MOCK from './mock';
import NetworkService from './NetworkService';

export default class OnboardService {
  static async getExamSessionFlowAssignment(examSlotId, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ONBOARD_ASSIGNMENT);
    }

    const response = await NetworkService.get(`/examslot/${examSlotId}/flow-assignment`, abortSignal);
    return await response.json();
  }

  static async getOnboardFlowFromFlowAssignment(flowAssignmentId, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ONBOARD_FLOW);
    }
    const response = await NetworkService.get(`/onboarding/flow-assignment/${flowAssignmentId}/flow`, abortSignal);
    return await response.json();
  }

  static async getOnboardFlow(flowId, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ONBOARD_FLOW);
    }

    const response = await NetworkService.get(`/onboarding/flow/${flowId}`, abortSignal);
    return await response.json();
  }

  static async getOnboardProgressImage(progressDetails, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.IMAGE, 2000);
    }

    const { onboardAssignmentId, onboardProgressId, additionalPathParam } = progressDetails;
    const path = `/onboarding/flow-assignment/${onboardAssignmentId}/progress/${onboardProgressId}/${additionalPathParam}`

    const response = await NetworkService.get(path, abortSignal);
    return response
  }

  static async addProgress(flowAssignmentId, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ONBOARD_ASSIGNMENT.onboardSlotProgress);
    }

    const response = await NetworkService.post(`/onboarding/flow-assignment/${flowAssignmentId}/progress`, undefined, undefined, abortSignal);
    return await response.json();
  }

  static async updateOnboardProgress(progressUpdateDetails, abortSignal = null) {
    const { additionalPathParam, file, onboardAssignmentId, onboardProgressId, progressDetails, sendAsForm } = progressUpdateDetails;
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      const mockProgressDetails =  (sendAsForm && !progressDetails) ? { complete: true } : progressDetails;
      return mockRequest({ id: onboardProgressId, ...mockProgressDetails });
    }

    const url = additionalPathParam
      ? `/onboarding/flow-assignment/${onboardAssignmentId}/progress/${onboardProgressId}/${additionalPathParam}`
      : `/onboarding/flow-assignment/${onboardAssignmentId}/progress/${onboardProgressId}`;

    const slotProgressEntity = Boolean(sendAsForm && !isEmpty(progressDetails)) &&
      { slotProgressEntity: new Blob([JSON.stringify(progressDetails)], { type: 'application/json' }) };

    const response = sendAsForm
      ? NetworkService.patchFile(url, file, slotProgressEntity, abortSignal)
          .then((resp) => { return resp.status === 204 ? resp : resp.json() })
      : await NetworkService.patch(url, progressDetails, abortSignal);

    return response;
  }

  static async recalibrateOnboardProgress(onboardAssignmentId, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ONBOARD_ASSIGNMENT.onboardSlotProgress);
    }

    const response = await NetworkService.patch(`/onboarding/flow-assignment/${onboardAssignmentId}/progress/recalibrate`, undefined, abortSignal);
    return response;
  }

  static async requestAnalysisForProgress(assignmentId, progressId, abortSignal = null) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest();
    }
    const route = `/onboarding/flow-assignment/${assignmentId}/progress/${progressId}/idAnalysis`;
    return await NetworkService.post(route, undefined, 'application/json', abortSignal);
  }
}