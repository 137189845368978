import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { find } from 'lodash';
import Notice from '../notification/Notice';
import FormPopup from '../popup/FormPopup';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import Section from '../Section';
import UpdateAAAButton from '../form/UpdateAAAButton';
import UserForm from '../form/UserForm';
import { getUserTableColumns, processUserData } from './helper/userTableHelpers';
import UserService from '../../services/UserService';
import { MSG_404 } from '../../constants/login';

const styles = {
  requestStatusIndicatorContainer: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: 2,
    transform: 'translate(-50%, 0)',
  },
  sectionContainer: {
    position: 'relative',
  },
};

class UserTable extends React.Component {

  state = {
    userList: undefined,
    isRequestPending: true,
    hasRequestErrored: false,
    errorMessage: "",
    popupOpen: false,
    editUserValues: undefined,
    selectedUsers: [],
    keepSelectedUsers: false,
  }

  controller = new AbortController();

  componentDidMount() {
    this.loadUserData();
  }

  componentDidUpdate(prevProps) {
    const { hasUserUpdated } = this.props;
    if (prevProps.hasUserUpdated !== hasUserUpdated && hasUserUpdated) {
      this.loadUserData();
      !this.state.keepSelectedUsers && this.emptySelectedUsers()
    }
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  loadUserData = async () => {
    const { searchTerm, onlyAAA, canEdit } = this.props;
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: "",
    });
    try {
      const users = await UserService.getUsers(searchTerm, onlyAAA, false, 10000, this.controller.signal);
      this.setState({
        userList: users.users,
        tableData: processUserData(users.users, canEdit, this.handleEditClick),
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch(error) {
      if(error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            userList: [],
            isRequestPending: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  emptySelectedUsers = () => {
    this.setState({selectedUsers: []});
  };

  setKeepSelectedUsers = (state) => {
    this.setState({keepSelectedUsers: state});
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  handleEditClick = (_e, editUserValues) => {
    this.setState({
      editUserValues
    }, () => this.openPopup());
  }

  setSelectedUsers = (allRowsSelected, userList, tableData) => {
    const users = allRowsSelected.map(row => {
      // id always needs to be the first item in the tableData array
      return find(userList, user => user.id === tableData[row.dataIndex]['id']);
    });
    this.setState({selectedUsers: users});
  };

  render() {
    const {
      userList,
      tableData,
      isRequestPending,
      hasRequestErrored,
      errorMessage,
      popupOpen,
      editUserValues,
      selectedUsers
    } = this.state;

    const { canEdit, canViewAAA, canManageAAA, hasUserUpdated, setHasUserUpdated } = this.props;
    const editForm = <UserForm user={editUserValues} setHasUserUpdated={setHasUserUpdated}/>;
    const displayData = !isRequestPending && !hasRequestErrored && userList.length > 0;

    const columns = getUserTableColumns(tableData, canEdit, canViewAAA);
    const bulkUpdateButton =
      <UpdateAAAButton
        selectedUsers={selectedUsers}
        hasUserUpdated={hasUserUpdated}
        setHasUserUpdated={setHasUserUpdated}
        setKeepSelectedUsers={this.setKeepSelectedUsers}
        emptySelectedUsers={this.emptySelectedUsers}
      />

    const options = {
      responsive: 'simple',
      filterType: 'multiselect',
      pagination: false,
      print: false,
      selectToolbarPlacement: 'none',
      selectableRows: canManageAAA ? 'multiple' : 'none',
      onRowSelectionChange: (_currentRowSelected, allRowsSelected, _rowsSelected) =>
        this.setSelectedUsers(allRowsSelected, userList, tableData),
    };

    return(
      <Section sx={styles.sectionContainer}>
        <div style={styles.requestStatusIndicatorContainer}>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </div>
        {selectedUsers.length > 0 && bulkUpdateButton}
        {!displayData &&
          <Notice noticeType="notice">There are no users that match the search criteria</Notice>
        }
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        {displayData &&
          <MUIDataTable
            columns={columns}
            data={tableData}
            options={options}
          />
        }
        <FormPopup
          title='Edit user account'
          open={popupOpen}
          onClose={this.closePopup}
          content={editForm}
        />
      </Section>
    )
  }
}

UserTable.propTypes = {
  searchTerm: PropTypes.string,
  onlyAAA: PropTypes.string,
  canEdit: PropTypes.bool,
  canViewAAA: PropTypes.bool,
  canManageAAA: PropTypes.bool,
  hasUserUpdated: PropTypes.bool,
  setHasUserUpdated: PropTypes.func,
};

export default UserTable;
