import { isEmpty } from 'lodash';

/**
 * Retrieves the index of the display group step for the first step that the user needs to complete from a list of steps.
 * Useful for when we need to deal with a modified / filtered list of steps.
 * @param {Array} displayFlowSteps Ordered list of steps in the flow.
 * @param {number} activeStepIndex Position (index) in the ordered lists of the step that needs to be completed
 * @returns {number} Index of the display group step of the active step.
 */
export const getActiveGroupedStepIndex = (displayFlowSteps, onboardProgressSteps, activeStepIndex) => {
  if (isEmpty(displayFlowSteps) || activeStepIndex === -1 || activeStepIndex >= onboardProgressSteps?.length) {
    return -1;
  }
  const activeGroupIndex = onboardProgressSteps[activeStepIndex].flowStep?.displayGroupStepIndex;
  return displayFlowSteps.findIndex(flowStep => flowStep.displayGroupStepIndex === activeGroupIndex);
};