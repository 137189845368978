import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NotificationBar from './NotificationBar';

const SuperviseInSessionNotifications = (props) => {

  const { notifications, clearAtIndex } = props;

  const [activeNotification, setActiveNotification] = useState(undefined);

  const handleNotificationBarClose = (index) => {
    clearAtIndex(index);
    setActiveNotification(undefined);
  };

  useEffect(() => {
    if (notifications && notifications.length) {
      // Show first notification in the list for now
      setActiveNotification({
        show: true,
        message: notifications[0],
        index: 0,
      });
    } else {
      // No notifications to show
      setActiveNotification(undefined);
    }
  }, [notifications])

  return (<NotificationBar
    show={activeNotification?.show}
    message={activeNotification?.message}
    onClose={() => handleNotificationBarClose(activeNotification?.index)}
  />);
};

SuperviseInSessionNotifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  clearAtIndex: PropTypes.func.isRequired,
};

export default SuperviseInSessionNotifications;