import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { differenceInMilliseconds } from 'date-fns/differenceInMilliseconds';
import Timer from '@amplication/react-compound-timer';
import Box from '@mui/material/Box';
import getFirstTimeFromNotices from '../../utils/getFirstTimeFromNotices';

function Stopwatch(props) {
  const HR = 'hours';
  const MIN = 'minutes';
  const SEC = 'seconds';
  const [timeOffset, setTimeOffset] = useState(undefined);
  const [timeToDisplay, setTimeToDisplay] = useState(SEC);
  const [shouldLoadTimer, setShouldLoadTimer] = useState(false);
  const { notices, classOverride } = props;
  const earliestTimeRef = useRef(undefined);

  useEffect(() => {
    const earliestTime = getFirstTimeFromNotices(notices);
    if(earliestTime !== earliestTimeRef.current) {
      earliestTimeRef.current = earliestTime;
      let offset = differenceInMilliseconds(new Date(), earliestTime);
      switch (true) {
        case (offset < 60000):
          setTimeToDisplay(SEC);
          break;
        case (offset < (60000 * 60)):
          setTimeToDisplay(MIN);
          break;
        case (offset >= (60000 * 60)):
          setTimeToDisplay(HR);
          break;
        default:
          break;
      }
      setTimeOffset(offset);
      setShouldLoadTimer(false);
    }
  }, [notices])

  useEffect(() => {
    // timer doesn't update offset if it's already rendered. Need to unmount.
    !shouldLoadTimer && setShouldLoadTimer(true);
  }, [shouldLoadTimer]);

  const checkpointUpdate = (type) => {
    if((timeToDisplay === SEC && type === MIN) ||
       (timeToDisplay === MIN && type === HR)) {
      setTimeToDisplay(type);
    }
  }

  return(
    <Box pr={0.5} className={classOverride}>
      {shouldLoadTimer && timeOffset &&
        <Timer
          initialTime={timeOffset}
          lastUnit="h"
          checkpoints={[
            { time: 60000, callback: () => checkpointUpdate(MIN) },
            { time: 60000 * 60, callback: () => checkpointUpdate(HR) }
          ]}
        >
          {timeToDisplay === HR && <><Timer.Hours/>hr</>}
          {timeToDisplay === MIN && <><Timer.Minutes/>min</>}
          {timeToDisplay === SEC && <><Timer.Seconds/>sec</>}
        </Timer>
      }
    </Box>
  );
}

Stopwatch.propTypes = {
  classOverride: PropTypes.string,
  notices: PropTypes.array,
};

export default Stopwatch;
