import { isEmpty } from 'lodash';

import { USER_TYPES } from '../../constants/users';
import ExamSessionService from '../../services/ExamSessionService';
import MeetingService from '../../services/MeetingService';

export const retrieveChatMessages = async (examDetails, abortController) => {
  const apiChatMessages = await retrieveChatsFromCoreApi(examDetails.id, abortController);
  let chatMessages = apiChatMessages;

  if (isEmpty(apiChatMessages)) {
    chatMessages = await retrieveChatsFromRecording(examDetails, abortController);
  }

  return chatMessages;
}

const retrieveChatsFromCoreApi = async (slotId, abortController) => {
  try {
    return await ExamSessionService.getExamSlotChatMessages(slotId, abortController);;
  } catch (err) {
    return [];
  }
}

const retrieveChatsFromRecording = async (examDetails, abortController) => {
  const chatMessages = [];
  for (let meeting of examDetails.meetings) {
    if (meeting.recordingsAvailable) {
      const meetingChatsResponse = await MeetingService.getRecordingChatMessages(meeting.id, abortController);
      chatMessages.push(...processChatRecordingXML(meeting, meetingChatsResponse));
    }
  }
  return chatMessages;
}

const processChatRecordingXML = (meetingDetails, chatMessagesXml) => {
  const popcornElement = chatMessagesXml.getElementsByTagName('popcorn')[0];
  const chatMessages = [...popcornElement.getElementsByTagName('chattimeline')];

  return chatMessages.map(chatMessage => ({
    sender: {
      legalName: chatMessage.getAttribute('name'),
      fullName: chatMessage.getAttribute('name'),
    },
    senderUserType: getUserType(meetingDetails, chatMessage),
    createdAt: calculateChatRecordingTime(meetingDetails, chatMessage).toISOString(),
    chatMessage: chatMessage.getAttribute('message'),
  }));
}

const getUserType = (meetingDetails, recordingChatMessage) => {
  const student = meetingDetails?.participants?.find(participant =>
    recordingChatMessage.getAttribute('name') === participant.user.fullName && participant.userType === USER_TYPES.STUDENT);
  return student ? USER_TYPES.STUDENT : USER_TYPES.SUPERVISOR;
}

const calculateChatRecordingTime = (meetingDetails, chatMessage) => {
  return new Date(new Date(meetingDetails.creationTime).getTime() + parseFloat(chatMessage.getAttribute('in'), 10)*1000);
}