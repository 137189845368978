import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { isEmpty, size } from 'lodash';
import Section from '../Section';
import Notice from '../notification/Notice';
import ContextService from '../../services/ContextService';
import { validateRequired } from '../../utils/validateFields';
import { CONTEXT_FIELDS } from '../../constants/contexts';
import { fieldsetContainerStyle, legendStyle } from '../../config/theme';

class ContextForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: props.context,
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
    }
  }

  setContext = (event) => {
    let contextObj = { ...this.state.context };
    if(event.target.name) {
      contextObj[event.target.name] = event.target.value;
    } else {
      contextObj[event.target.id] = event.target.value;
    }
    this.setState({ context: contextObj });
  };

  saveContext = async () => {
    try {
      validateRequired(CONTEXT_FIELDS, this.state.context);
      await ContextService.saveContext(this.state.context);
      this.setState({
        hasRequestErrored: false,
        isRequestSuccessful: true,
        errorMessage: '',
      });
      this.props.setHasContextUpdated(true);
    } catch(error) {
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        errorMessage: error.message,
      });
    }
  };

  render() {
    const { contextsList, parentEditable } = this.props;
    const { context, isRequestSuccessful, hasRequestErrored, errorMessage } = this.state;

    return (
      <Section>
        <FormControl component="fieldset" sx={fieldsetContainerStyle}>
          <FormLabel component="legend" sx={legendStyle}>Manage context</FormLabel>
          {isRequestSuccessful &&
            <Notice noticeType="success">The context was updated successfully</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {Object.keys(CONTEXT_FIELDS).filter(field => CONTEXT_FIELDS[field].type === 'textbox').map((field) => {
            return (
              <TextField
                key={field}
                id={field}
                label={CONTEXT_FIELDS[field].display}
                value={context[field] || ''}
                onChange={this.setContext}
                required={CONTEXT_FIELDS[field].required}
                error={hasRequestErrored && ((CONTEXT_FIELDS[field].required && isEmpty(context[field])) || (size(context[field]) > CONTEXT_FIELDS[field].maxLength))}
                size="medium"
                sx={{ m: 1 }}
                variant="outlined"
                fullWidth />
            )
          })}
          {parentEditable === false
            ?
              <Box mx={2} my={1}>
                <Typography variant="caption">{CONTEXT_FIELDS['parent'].display} (top branch parent is not editable)</Typography>
                <Typography variant="body1">{context?.parentObj?.name}</Typography>
              </Box>
            :
              <TextField
                id="parent"
                name="parent"
                label={CONTEXT_FIELDS['parent'].display}
                value={context.parent}
                onChange={this.setContext}
                required={CONTEXT_FIELDS['parent'].required}
                error={hasRequestErrored && CONTEXT_FIELDS['parent'].required && isEmpty(context['parent'])}
                select
                size="medium"
                sx={{ m: 1 }}
                variant="outlined"
                fullWidth
              >
                {contextsList.map((contextItem) => {
                  return (
                    <MenuItem key={contextItem.id} value={contextItem.id}>{contextItem.name}</MenuItem>
                  )
                })}
              </TextField>
          }
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={this.saveContext}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

ContextForm.propTypes = {
  context: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    parent: PropTypes.string,
    externalId: PropTypes.string,
  }).isRequired,
  contextsList: PropTypes.array.isRequired,
  setHasContextUpdated: PropTypes.func,
};

export default ContextForm;
