import { isEmpty } from 'lodash';
import { ACTIONS, ONBOARD_ACTION_TYPES } from '../../../constants/joinSession';
import { MEDIA_STREAM_CONNECTION_STATES, SHARED_MEDIA_STATES, SHARED_MEDIA_TYPES } from '../../../constants/mediaStates';

/**
 * Close off the audio connection. This is done to prevent the echo test from
 * persisting forever if the audio check step is skipped.
 * @param {Func} dispatch Dispatch function for the JoinContext
 * @param {Object} audioService {@link AudioStreamService} class
 */
const closeAudioConnection = (dispatch, audioService) => {
  audioService.unregisterHandlers();
  audioService.exitAudio();

  dispatch({
    type: ACTIONS.UPDATE_SHARED_MEDIA_STATUS,
    value: {
      mediaType: SHARED_MEDIA_TYPES.AUDIO,
      status: SHARED_MEDIA_STATES.DISABLED,
    },
  });
  dispatch({
    type: ACTIONS.SET_AUDIO_STATE,
    value: MEDIA_STREAM_CONNECTION_STATES.CLOSED,
  });
};

/**
 * Handle scenarios that could lead to a weird state / errors for when certain media check steps
 * are skipped
 * @param {Object} progressStepDetail The progress step details that we want to skip
 * @param {Function} dispatch Dispatch function for the JoinContext
 * @param {Function} getServices Function to retrieve the media services
 */
const handleSkipMediaCheck = (progressStepDetail, dispatch, getServices) => {
  const services = getServices();
  if (isEmpty(services)) {
    return;
  }

  const stepType = progressStepDetail.flowStep.onboardStep.actionType;

  if (stepType === ONBOARD_ACTION_TYPES.MEDIA_CHECK_AUDIO.mapper && services.audioService) {
    closeAudioConnection(dispatch, services.audioService);
  }
}

/**
 * Handler for when a skip onboard step message is received from the gateway.
 * @param {Object} skipMessageData Data in the gateway message.
 * @param {Function} dispatch Dispatch function for the JoinContext
 * @param {Function} getProgressStepsDetails Function to retrieve the progress step details that is produced
 * by {@link mapSortedOnboardStepsAndProgress}.
 * Ensures that we will always retrieve the most up to date version
 * @param {Function} getServices Function to retrieve the media services. Ensures that we will always
 * retrieve the most up to date version
 */
const onSkipOnboardStep = (skipMessageData, dispatch, getProgressStepsDetails, getServices) => {
  if (isEmpty(skipMessageData) || !getProgressStepsDetails || !dispatch) {
    console.error('Skip onboard step was triggered with either an empty message, no onboard step information or update callback');
    return;
  }
  const stepDetails = getProgressStepsDetails();
  const progressStepToUpdate = stepDetails?.find(stepDetail => stepDetail.progress.id === skipMessageData.onboardProgressId);

  if (isEmpty(progressStepToUpdate)) {
    console.error(`Received a message to skip a step with progress id ${skipMessageData.onboardProgressId} from the gateway however no corressponding step was found in`, stepDetails);
    return;
  }

  const updatedProgress = {
    ...progressStepToUpdate.progress,
    skippedBy: skipMessageData.skippedById,
    mustRecomplete: false,
  };

  handleSkipMediaCheck(progressStepToUpdate, dispatch, getServices);

  dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: updatedProgress });
}

export default onSkipOnboardStep;