import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import RoomScanQrCode from './RoomScanQrCode';
import { buttonLinkStyle } from '../../config/theme';
import QrCode from '../../icons/QrCode';

class QrCodePopup extends React.Component {
  state = { ancherEl: null, open: false }

  handleClick = (event) => {
    const { open } = this.state;
    this.setState({ anchorEl: event.currentTarget, open: !open });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  }

  render() {
    const { slotId } = this.props;
    return (
      <>
        <Button
          aria-label="Get your QR code"
          onClick={this.handleClick}
          endIcon={<QrCode />}
          sx={{ ...buttonLinkStyle, fontSize: '1rem', fontWeight: 'normal' }}
        >
          Get your QR code
        </Button>
        <Popover
          id="qrCodePopover"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box p={2} maxWidth="308px">
            <Typography variant="h3" component="p" color="primary">QR code to begin room scan</Typography>
            <RoomScanQrCode slotId={slotId} />
          </Box>
        </Popover>
      </>
    )
  }
}

QrCodePopup.propTypes = {
  slotId: PropTypes.string.isRequired,
}

export default QrCodePopup;
