import { isEmpty, remove } from 'lodash';

const getProgressFromStep = (progressItems, step) => {
  if (isEmpty(progressItems) || isEmpty(step)) {
    return;
  }
  return progressItems.find(progressItem => progressItem.onboardStepId === step.onboardStep?.id);
}

export const getProgressForStepAndRemoveAssignedProgress = (progressItems, step) => {
  const progressItem = getProgressFromStep(progressItems, step);
  !isEmpty(progressItem) && remove(progressItems, ['id', progressItem.id]);
  return progressItem;
};

export default getProgressFromStep;