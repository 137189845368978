import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

const styles = {
  paper: {
    padding: 3,
    borderRadius: '16px'
  },
  dialogTitleRoot: {
    margin: 0,
    padding: 2,
    display: 'flex',
    alignItems: 'center',
  },
  dialogContentRoot: {
    padding: 2,
  },
  warningIcon: {
    color: 'warning.light',
  },
};

function WarningPopup(props) {
  const {
    content,
    title,
    onClose,
    open,
    sx,
  } = props;

  return (
    <Dialog maxWidth="sm" sx={{ '.MuiDialog-paper': styles.paper }} open={open} onClose={onClose}>
      <DialogTitle
        color="inherit"
        sx={styles.dialogTitleRoot}
      >
        <ErrorOutline sx={{ ...styles.warningIcon, ...sx?.warningIcon }} />
        <Box pl={2}>{title}</Box>
      </DialogTitle>
      <DialogContent sx={styles.dialogContentRoot}>
        {content}
      </DialogContent>
    </Dialog>
  )
}

WarningPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default WarningPopup;
