import { createTheme } from '@mui/material/styles';
import { themeObject } from './theme';

export const themeSubTableObject = {
  ...themeObject,
  components: {
    ...themeObject.components,
    MUIDataTable: {
      styleOverrides: {
        paper: {
          ...themeObject.components.MUIDataTable.styleOverrides.paper,
          borderRadius: 0,
          border: '1px solid #dadada',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        ...themeObject.components.MUIDataTableHeadCell.styleOverrides,
        fixedHeader: {
          backgroundColor: '#dadada',
          padding: '0.3rem',
        },
        toolButton: {
          fontSize: '0.8rem',
          marginLeft: 0,
        }
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          ...themeObject.components.MUIDataTableBodyCell.styleOverrides.root,
          backgroundColor: '#f5f6f6',
          padding: '0.3rem',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#dadada',
        },
        headerCell: {
          backgroundColor: '#dadada',
        },
      },
    },
  }
};

const themeSubTable = createTheme(themeSubTableObject);

export default themeSubTable;
