import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListSubheader, Typography } from '@mui/material';
import { groupBy, isEmpty } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import MonitorListItem from '../form/MonitorListItem';
import NotificationSummary from '../notification/NotificationSummary';
import { getTimeDisplay } from '../../utils/getSessionTime';
import sortMonitoringSessions from '../../utils/sortMonitoringSessions';
import examSessionIsVisibleToAssignedUser from '../../utils/examSessionIsVisibleToAssignedUser';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';

const styles = {
  scrollableList: {
    backgroundColor: '#f5f6f6',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    px: 0,
    pt: 0,
    pb: 2,
    overflowY: 'auto',
    scrollSnapType: 'y',
    '& > div': {
      scrollSnapAlign: 'start',
      width: '100%',
    },
    '&::-webkit-scrollbar': {
      width: 1,
      backgroundColor: 'transparent',

    },
    '&:hover::-webkit-scrollbar': {
      backgroundColor: '#eee',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: 1,
    },
  },
  mainHeading: {
    backgroundColor: 'grey.300',
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },
  sectionHeading: {
    fontWeight: 500,
    py: 0.5,
    px: 1,
  },
};

function MonitorList(props) {

  const { state } = useContext(monitoringContext);
  const { type, user, features }  = props;

  const [ sortedSessionList, setSortedSessionList ] = useState([]);

  const listWrapper = useRef(null);

  const displayListItems = (sessions) => {
    return (
      <>
        {sessions.map(session => {
          return (<MonitorListItem key={session.id} session={session} />)
        })}
      </>
    )
  };

  const displayList = (sessions) => {
    return(
      <List
        id={`${type.uniqueTitle}-student-list`}
        subheader={<div />}
      >
        {type.showSummaryBanner &&
          <ListSubheader
            id={`${type.uniqueTitle}-notification-summary`}
            onClick={scrollToTop}
            sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'transparent', top: '24px' }}
          >
            <NotificationSummary examSessions={sessions}/>
          </ListSubheader>
        }
        {type.groupByTime
          ? Object.entries(groupBy(sortedSessionList, es => getTimeDisplay(es.examStartDateTime))).sort().map((group) => {
            return (
              <React.Fragment key={group[0]}>
                <Typography variant="body2" component="li" sx={styles.sectionHeading}>
                  {group[0]}
                </Typography>
                {displayListItems(group[1])}
              </React.Fragment>
            )
          })
          : displayListItems(sessions)
        }

      </List>
    )
  };

  useEffect(() => {
    let visibleSessions = Object.values(state.examSessions)
      .filter(s => examSessionIsVisibleToAssignedUser(s, user.id, features, [type]));
    setSortedSessionList(sortMonitoringSessions(visibleSessions, type));
  }, [state.examSessions, type, user.id, features]);

  const scrollToTop = () => {
    listWrapper.current.scrollTo({behavior: "smooth", top: 0, left: 0});
  };

  return (
    <>
      {!isEmpty(sortedSessionList) &&
        <Box
          id={`${type.uniqueTitle}-list-wrapper`}
          flexGrow={type.growValue ? type.growValue : 1}
          minHeight={type.minHeight}
          ref={listWrapper}
          sx={(theme) => {
            return { ...styles.scrollableList, scrollPadding: type.groupByTime ? theme.spacing(11) : theme.spacing(9) }
          }}
        >
          <Typography variant="body2" component="h2" sx={{ ...styles.sectionHeading, ...styles.mainHeading }}>
            {features?.[FEATURE_TOGGLES.AUTO_ONBOARD] ? type.title : type.uniqueTitle}
          </Typography>
          {displayList(sortedSessionList)}
        </Box>
      }
    </>
  );
}

MonitorList.propTypes = {
  type: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  features: PropTypes.object,
};

export default MonitorList;
