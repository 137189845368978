import React from 'react';
import Typography from '@mui/material/Typography';
import ContactNumbers from './ContactNumbers';

const TerminatedSessionText = () => {
  return (
    <div id="terminated-text">
      <Typography variant="h3" component="p">This browser session has been terminated.</Typography>
      <Typography variant="body1">You can close this tab at any time.</Typography>
      <ContactNumbers />
    </div>
  );
};

export default TerminatedSessionText;