import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

function HoverPopover(props) {
  const { data, dataDisplayOptions, dataDisplayValues, name, title } = props;
  const [anchorEl, setAnchorEl] = useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.target);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  const getValueDisplay = (value) => {
    if (value === true) {
      return 'true';
    }
    if (value === false) {
      return 'false';
    }
    if (value === '') {
      return '[blank]';
    }
    return find(dataDisplayValues, ['value', value])?.label || value;
  }

  return (
    <>
      <QuestionMarkIcon
        aria-owns={popoverOpen ? `${name}-hover-popover` : undefined}
        aria-haspopup="true"
        onMouseEnter={(e) => handlePopoverOpen(e)}
        onMouseLeave={handlePopoverClose}
        sx={{ fontSize: '1rem' }}
      />
      <Popover
        id={`${name}-hover-popover`}
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box p={2}>
          <Typography variant="h5" component="p" sx={{mb: 1}}>{title}</Typography>
          {data && Object.entries(data).map((item) => {
            return(
              <Typography
                component="div"
                key={item[0]}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
                variant="body2"
              >
                <div>{dataDisplayOptions[item[0]].display || item[0]}</div>
                <div>{getValueDisplay(item[1])}</div>
              </Typography>
            )
          })}
        </Box>
      </Popover>
    </>
  )
}

HoverPopover.propTypes = {
  data: PropTypes.object,
  dataDisplayOptions: PropTypes.object,
  dataDisplayValues: PropTypes.object,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default HoverPopover;
