import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';
import { authContext } from '../../authContext';
import ContactNumbers from './ContactNumbers';
import { SUPERVISION_COMPLETE_MAPPER } from '../../constants/contentOverrides';

function SupervisionComplete(_props) {
  const { contentOverrides } = useContext(authContext);

  const supervisionCompleteText = isEmpty(contentOverrides?.[SUPERVISION_COMPLETE_MAPPER])
    ? 'You are no longer connected to your online supervisor. If you need assistance, contact IT support.'
    : contentOverrides[SUPERVISION_COMPLETE_MAPPER];

  return (
    <Box bgcolor="common.white" boxSizing="border-box" height="calc(100vh - 112px)" my={2} mx="auto" p={4} maxWidth="80%">
      <Typography variant="h2">Your online supervision session is now complete.</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {supervisionCompleteText}
      </Typography>
      <ContactNumbers contentOverrides={contentOverrides} itSupport={true} />
    </Box>
  )
}

export default SupervisionComplete;