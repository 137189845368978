import { SHARED_MEDIA_STATES, SHARED_MEDIA_TYPES } from '../../../constants/mediaStates';

const onVideoShare = (clearConferenceError, getState, getUserId, updateVideoState) => {
  /**
   * Callback function for the video management component to handle any page changes when video has started sharing
   * successfully.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   */
  return (mediaType) => {
    const state = getState();
    const streamId = mediaType === SHARED_MEDIA_TYPES.DESKTOP ? mediaType : getUserId();
    const screenType = mediaType === SHARED_MEDIA_TYPES.DESKTOP ? state?.screenShareType : undefined;

    updateVideoState(mediaType, SHARED_MEDIA_STATES.CONNECTED);
    clearConferenceError(mediaType);
    if (state?.gatewayService) {
      state.gatewayService.shareMedia(mediaType, streamId, screenType);
    }
  }
};

export default onVideoShare;