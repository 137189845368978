import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import NetworkService from './NetworkService';

export default class DetectionConfigService {

  static async getDetectionConfigByType(type, abortSignal) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.DETECTION_CONFIG.find(config => config.type === type));
    }

    const response = await NetworkService.get(`/detection-configuration/${type}`, abortSignal = null);
    return await response.json();
  }
}