import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Search as SearchIcon, Clear as ClearIcon} from '@mui/icons-material';

class UserSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.searchTerm ? props.searchTerm : '',
    };
  }

  canNavigate = () => {
    return (
      this.state.searchTerm &&
      this.state.searchTerm.length > 2
    );
  };

  clearSearch = (onlyAAA) => {
    this.setState({ searchTerm: '' })
    this.props.navigate('', onlyAAA)
  }

  onNavigateButtonClick = (onlyAAA) => {
    if (this.canNavigate()) {
      this.props.navigate(this.state.searchTerm, onlyAAA);
    }
  };

  setSearchTerm = (event) => {
      this.setState({ searchTerm: event.target.value });
  };

  searchKeyPress = (event, onlyAAA) => {
    const enterKeyCode = 13;
    if(event.keyCode === enterKeyCode && this.canNavigate()){
      this.onNavigateButtonClick(onlyAAA);
    }
  };

  render() {
    const { searchTerm } = this.state;
    const { labels, onlyAAA } = this.props;

    return (
      <FormControl component="fieldset" sx={{display: "block", margin: "0 auto" }} aria-label={labels.fieldsetLabel}>
        <FormLabel component="legend" style={{display:'none'}} />
        <Box display="flex">
          <TextField
            id="searchTerm"
            label={labels.searchFieldLabel}
            value={searchTerm}
            onChange={this.setSearchTerm}
            size="medium"
            variant="outlined"
            fullWidth
            onKeyDown={(event) => this.searchKeyPress(event, onlyAAA)}
            InputProps={searchTerm ? {endAdornment:
              <InputAdornment position="end">
                <Tooltip title="Clear search">
                  <IconButton
                    aria-label="Clear search"
                    onClick={() => this.clearSearch(onlyAAA)}
                    size="large">
                    <ClearIcon/>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            } : {}}
          />
          <Box ml={2}>
            <Fab
              id="user-search-button"
              color="primary"
              aria-label="Search"
              disabled={!this.canNavigate()}
              onClick={() => this.onNavigateButtonClick(onlyAAA)}
              size="medium"
            >
              <SearchIcon />
            </Fab>
          </Box>
        </Box>
      </FormControl>
    );
  }
}

UserSearchForm.propTypes = {
  searchTerm: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  onlyAAA: PropTypes.bool,
  route: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    fieldsetLabel: PropTypes.string.isRequired,
    searchFieldLabel: PropTypes.string.isRequired
  })
};

export default UserSearchForm;
