import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { EXAM_SESSION_STATUS_LABEL } from '../../constants/examSessions';
import { SCREEN_SHARE_TYPES } from '../../constants/mediaStates';

const styles = {
  statusChip: {
    height: 'auto',
    borderRadius: 1,
    fontVariant: 'small-caps',
    fontSize: '0.7rem',
    '.MuiChip-label': {
      marginTop: '-2px', // small-caps throws off the line height a little, but it was the most readable
      lineHeight: '0.9rem',
    }
  },
}

function MonitorItemStatusBar(props) {
  const { isActive, isErrored, online, screenType, submitted, webCamStreaming } = props;
  const textColor = isActive && !isErrored ? 'common.white' : 'common.black';
  const onlineStatusColour = online ? 'success.main' : 'grey.400';
  const camStatusColour = webCamStreaming ? 'success.main' : 'grey.400';

  return (
    <Box display="flex" columnGap={0.5} mt={0.5} pl={submitted ? 0.75 : 0}>
      {!isErrored &&
        <>
          <Chip
            icon={<Circle/>}
            label={online ? 'online' : 'offline'}
            size="small"
            sx={{
              ...styles.statusChip,
              color: textColor,
              borderColor: onlineStatusColour,
              '.MuiChip-icon': { color: onlineStatusColour, fontSize: '8px' },
            }}
            variant="outlined"
          />
          <Chip
            icon={<Circle/>}
            label={webCamStreaming ? 'cam on' : 'cam off'}
            size="small"
            sx={{
              ...styles.statusChip,
              color: textColor,
              borderColor: camStatusColour,
              '.MuiChip-icon': { color: camStatusColour, fontSize: '8px' },
            }}
            variant="outlined"
          />
          <Chip
            icon={<Circle/>}
            label={SCREEN_SHARE_TYPES[screenType]?.display || 'unknown screen'}
            size="small"
            sx={{
              ...styles.statusChip,
              color: textColor,
              borderColor: SCREEN_SHARE_TYPES[screenType]?.color,
              '.MuiChip-icon': { color: SCREEN_SHARE_TYPES[screenType]?.color, fontSize: '8px' },
            }}
            variant="outlined"
          />
        </>
      }
      {submitted &&
        <Chip
          icon={<Circle/>}
          label={EXAM_SESSION_STATUS_LABEL.SUBMITTED.toLowerCase()}
          size="small"
          sx={{
            ...styles.statusChip,
            color: textColor,
            borderColor: 'success.main',
            '.MuiChip-icon': { color: 'success.main', fontSize: '8px' },
          }}
          variant="outlined"
        />
      }
    </Box>
  )
}

MonitorItemStatusBar.propTypes = {
  isActive: PropTypes.bool,
  isErrored: PropTypes.bool,
  online: PropTypes.bool,
  screenType: PropTypes.string,
  submitted: PropTypes.bool,
  webCamStreaming: PropTypes.bool,
};

export default MonitorItemStatusBar;