import React from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';

function ActionMenu(props) {

  const { iconId, menuId, ariaLabel, setMenuAnchorEl, menuAnchorEl, menuIcon } = props;
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  }

  return (
    <>
      <IconButton
        id={iconId}
        aria-label={ariaLabel}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        {menuIcon ? menuIcon : <MoreVert />}
      </IconButton>
      <Menu
        id={menuId}
        open={isMenuOpen}
        anchorEl={menuAnchorEl}
        onClose={handleCloseMenu}
        autoFocus={false}
      >
        {props.children}
      </Menu>
    </>
  );
}

ActionMenu.propTypes = {
  iconId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  setMenuAnchorEl: PropTypes.func.isRequired,
  menuAnchorEl: PropTypes.object,
  menuIcon: PropTypes.object,
  ariaLabel: PropTypes.string,
}

export default ActionMenu;
