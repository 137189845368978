import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Button, FormControl, FormLabel, MenuItem, TextField, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import Notice from '../notification/Notice';
import Section from '../Section';
import { MSG_404 } from '../../constants/login';
import { NOTICE_TYPES } from '../../constants/noticeTypes';
import AllocationPoolService from '../../services/AllocationPoolService';

function AllocationTypeForm(props) {
  const { selectedExamSlots, setHasSlotUpdated } = props;

  const [allocationPool, setAllocationPool] = useState('');
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const [deleteNeedsConfirmation, setDeleteNeedsConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleteRequestSuccessful, setIsDeleteRequestSuccessful] = useState(false);
  const [savedAssignmentList, setSavedAssignmentList] = useState([]);
  const [poolAssignmentsList, setPoolAssignmentsList] = useState([]);

  const abortController = useRef(new AbortController());

  // only one exam slot allowed for now
  const slotDetails = selectedExamSlots?.[0] || {};

  const formatPoolsIntoOptions = useCallback((pools) => {
    if (isEmpty(pools)) {
      return [];
    }
    return pools
      .filter(pool => !pool.disabled)
      .map((pool) => { return { key: pool.id, value: pool.name} });
  }, []);

  useEffect(() => {
    const controller = abortController.current;

    const getAllocationPools = async () => {
      try {
        const pools = await AllocationPoolService.getAllocationPools(controller.signal);
        const poolsAsOptions = formatPoolsIntoOptions(pools);
        setPoolAssignmentsList(poolsAsOptions);
        if (isEmpty(poolsAsOptions)) {
          setErrorMessage('No enabled allocation pools available.');
        }
      } catch (error) {
        if (error.message === MSG_404) {
          setErrorMessage('No allocation pools found.  Please check the environment is set up for automatic allocations');
        } else if (!controller.signal.aborted) {
          console.error(error);
          setErrorMessage(`Error retrieving allocation pools. ${error.message}`);
        }
        setPoolAssignmentsList([]);
      }
    }
    setHasSlotUpdated && setHasSlotUpdated(false);
    getAllocationPools();

    return () => { controller.abort('Allocation type form unmounting'); }
  }, [formatPoolsIntoOptions, setHasSlotUpdated]);

  const overridePoolAssignment = async () => {
    try {
      const response = await AllocationPoolService.overrideAllocationPoolAssignments(
        slotDetails.id,
        { allocationPoolId: allocationPool },
        abortController.current.signal
      );
      setSavedAssignmentList(response);
      setHasSlotUpdated && setHasSlotUpdated(true);
    } catch(error) {
      if (!abortController.current.signal.aborted) {
        console.error(error);
        setErrorMessage(`Error: unable to override allocation type. ${error.message}`);
      }
    }
  };

  const deletePoolAssignments = async () => {
    try {
      await AllocationPoolService.deleteAllocationPoolAssignments(slotDetails.id, abortController.current.signal);
      setIsDeleteRequestSuccessful(true);
      setHasSlotUpdated && setHasSlotUpdated(true);
      setDeleteNeedsConfirmation(false);
    } catch(error) {
      if (!abortController.current.signal.aborted) {
        console.error(error);
        setDeleteErrorMessage(`Error: unable to delete allocation type from exam session. ${error.message}`);
        setDeleteNeedsConfirmation(false);
      }
    }
  };

  const displayPoolAssignmentList = (assignmentsList) => {
    return isEmpty(assignmentsList) ? ' none' :
      assignmentsList.map(assignment =>
        <div key={assignment.allocationPool.id+'-'+assignment.userType}>
          {`${assignment.allocationPool.name} for ${assignment.userType}`}
        </div>
      )
  };

  return (
    <>
      <Section sx={{ mb: 2 }}>
        <FormControl component="fieldset" sx={{ display: 'block', margin: '0 auto' }}>
          <FormLabel component="legend" sx={{ color: 'primary.main', my: 1 }}>
            Override exam session allocation type
          </FormLabel>
          {!isEmpty(savedAssignmentList) &&
            <Notice noticeType={NOTICE_TYPES.success}>
              The allocation type was saved successfully.
              <Typography variant="body2" component="div" sx={{ my: 1 }}>
                Updated allocation types:
                {displayPoolAssignmentList(savedAssignmentList)}
              </Typography>
            </Notice>
          }
          {!isEmpty(errorMessage) &&
            <Notice noticeType={NOTICE_TYPES.error}>{errorMessage}</Notice>
          }
          {slotDetails.context &&
            <Typography variant="body2" sx={{ my: 1 }}>Exam: {slotDetails.context.name}</Typography>
          }
          <Typography variant="body2" component="div" sx={{ my: 1 }}>
            Existing allocation types:
            {displayPoolAssignmentList(slotDetails.poolAssignments)}
          </Typography>
          <Notice noticeType={NOTICE_TYPES.warning}>
            Setting an allocation type will delete any current setting, and disable automatic allocation type determination
            on save. You can re-enable the automation by deleting all allocation types, and then select edit and save the session.
          </Notice>
          <TextField
            id="select-allocation-pool"
            name="allocation-pool"
            label="Select allocation pool"
            value={allocationPool}
            onChange={(event) => setAllocationPool(event.target.value)}
            required={true}
            error={!isEmpty(errorMessage)}
            select
            size='medium'
            sx={{ my: 1 }}
            variant='outlined'
            fullWidth
          >
            {poolAssignmentsList.map((poolAssignment) => {
              return <MenuItem key={poolAssignment.key} value={poolAssignment.key}>{poolAssignment.value}</MenuItem>
            })}
          </TextField>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              color='primary'
              disabled={isEmpty(allocationPool)}
              variant='contained'
              onClick={overridePoolAssignment}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
      <Section>
        <FormControl component="fieldset" sx={{ display: 'block', margin: '0 auto' }}>
          <FormLabel component="legend" sx={{ color: 'primary.main', my: 1 }}>
            Delete all allocation types for exam session
          </FormLabel>
          {isDeleteRequestSuccessful &&
            <Notice noticeType={NOTICE_TYPES.success}>All allocation types have been removed from the exam session.</Notice>
          }
          {!isEmpty(deleteErrorMessage) &&
            <Notice noticeType={NOTICE_TYPES.error}>{deleteErrorMessage}</Notice>
          }
          <Button
            color='error'
            onClick={() => setDeleteNeedsConfirmation(true)}
            startIcon={<Delete/>}
            variant='outlined'
          >Delete all</Button>
          {deleteNeedsConfirmation &&
            <Button
              variant='outlined'
              color='error'
              aria-label={`Confirm delete all pool assignments for ${slotDetails.id}`}
              onClick={() => deletePoolAssignments(slotDetails.id)}
              sx={{ ml: 1 }}
            >
              Confirm delete all
            </Button>
          }
        </FormControl>
      </Section>
    </>
  )
}

AllocationTypeForm.propTypes = {
  selectedExamSlots: PropTypes.array,
  setHasSlotUpdated: PropTypes.func,
};

export default AllocationTypeForm;