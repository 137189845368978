import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Notice from '../notification/Notice'
import MertService from '../../services/MertService';
import { buttonLinkStyle } from '../../config/theme';

class GenerateQrPasscode extends React.Component {
  state = {
    isRequestPending: false,
    isRequestSuccessful: false,
    hasRequestErrored: false,
    errorMessage: '',
  }

  generatePasscode = async (examSessionId) => {
    this.setState({isRequestPending: true});
    try {
      await MertService.generatePasscode(examSessionId);
      this.setState({
        isRequestPending: false,
        isRequestSuccessful: true,
      });
    } catch(err) {
      this.setState({
        isRequestPending: false,
        hasRequestErrored: true,
        errorMessage: err.message,
      });
    }
  }

  render() {
    const { slotId } = this.props;
    const {isRequestPending, isRequestSuccessful, hasRequestErrored, errorMessage} = this.state;
    return (
      <>
        {isRequestSuccessful &&
          <Notice noticeType="success">Student passcode generated</Notice>
        }
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        {!isRequestPending && !isRequestSuccessful &&
          <Button
            onClick={() => this.generatePasscode(slotId)}
            sx={buttonLinkStyle}
          >
            Generate QR code
          </Button>
        }
      </>
    );
  }
}

GenerateQrPasscode.propTypes = {
  slotId: PropTypes.string.isRequired,
};

export default GenerateQrPasscode;
