import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';
import { ArrowBack, Done, PhotoCameraOutlined, Refresh } from '@mui/icons-material';
import DeviceSelector from '../form/DeviceSelector';
import { joinContext } from '../context/JoinContext';
import OnboardService from '../../services/OnboardService';
import { buttonLinkStyle } from '../../config/theme';
import { ACTIONS, ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import { DEVICE_TYPES } from '../../constants/devices';
import { NOTICE_TYPES } from '../../constants/noticeTypes';

const idFrame = {
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  borderRadius: '20px',
};
const faceFrame = {
  top: '20px',
  right: '100px',
  bottom: '20px',
  left: '100px',
  borderRadius: '100%',
}
const captureDisplayStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems:'center',
  position: 'relative',
};

function IdVerificationCaptureStepPopup(props) {
  const { state, dispatch } = useContext(joinContext);
  const { onClose, open, progressStep, setRequestMessage, typeConfig  } = props;
  const [guidelinesOpen, setGuidelinesOpen] = useState(false);
  const [localDevice, setLocalDevice] = useState(state.devices?.[DEVICE_TYPES.VIDEO_INPUT] || null);
  const [photo, setPhoto] = useState();
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const captureCanvasRef = useRef();
  const videoRef = useRef();
  const controllerRef = useRef(new AbortController());
  const typeFrameStyle = typeConfig?.mapper === ONBOARD_ACTION_TYPES.ID_VERIFICATION_ID.mapper ? idFrame : faceFrame;

  // We will request this video size and scale the photo width to a clip of this size of the video
  // despite the video resolution. This is important because we want the frames to be more fixed to make
  // having id's and faces in the right place easier
  const videoWidth = 400;
  const videoHeight = 300;

  const frame = isPreviewPlaying ? {
    transform: 'rotateY(180deg)',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: '1',
      border: '3px solid',
      borderColor: 'primary.main',
      boxShadow: '0 0 50px rgba(0, 0, 0, 0.5)',
      ...typeFrameStyle,
    }
  } : {};

  const takePicture = () => {
    const context = captureCanvasRef.current.getContext('2d');
    captureCanvasRef.current.width = videoWidth;
    captureCanvasRef.current.height = videoHeight;
    context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

    setPhoto(captureCanvasRef.current.toDataURL('image/png'));
  };

  const dataToImage = () => {
    return fetch(photo)
      .then(response => response.blob())
      .then(blob => {
        return new File([blob], typeConfig.fileName, { type: 'image/png' });
      })
      .catch((error) => {
        console.log('Error while converting image to file', error);
      });
  }

  const savePhoto = async () => {
    const imageToSave = await dataToImage();
    const progressToUpdate = {
      file: imageToSave,
      onboardAssignmentId: state.onboardAssignmentId,
      onboardProgressId: progressStep?.progress?.id,
      additionalPathParam: typeConfig.apiPathTypeCompleteParam,
      sendAsForm: typeConfig.sendApiAsForm,
    };
    setRequestInProgress(true);
    try {
      const updatedProgress = await OnboardService.updateOnboardProgress(progressToUpdate, controllerRef.current?.signal);
      setRequestInProgress(false);
      setRequestMessage({ type: NOTICE_TYPES.success, message: 'Image uploaded' });
      dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: { ...updatedProgress, mustRecomplete: false } });
      onClose();
    } catch (error) {
      setRequestMessage({ type: 'error', message: 'Sorry, we were unable to save your photo. Please try again' });
      console.error('Error: saving photo', error);
      if (!controllerRef.current?.signal?.aborted) {
        setRequestInProgress(false);
      }
    }
  };

  // Handler to take photo on enter key press to assist while id card is being held in front of the screen
  // and manage the abort controller for the api request
  useEffect(() => {
    const takePicOnKeyDown = (event) => {
      const enterKeyCode = 13;
      if (event.keyCode === enterKeyCode) {
        takePicture();
      }
    };
    document.addEventListener('keydown', takePicOnKeyDown);
    const controller = controllerRef.current;
    return () => {
      document.removeEventListener('keydown', takePicOnKeyDown);
      controller.abort();
    }
  }, []);

  const limitedOnClose = () => {
    if (!requestInProgress) {
      onClose();
    }
  }

  return (
    <Dialog
      aria-labelledby="verification-type-title"
      maxWidth="sm"
      open={open}
      onClose={limitedOnClose}
    >
      <DialogTitle id="verification-type-title">
        {typeConfig?.helperText || 'Capture photo' }
      </DialogTitle>
      <DialogContent>
        {guidelinesOpen &&
          <>
            <div>
              <Button onClick={() => setGuidelinesOpen(false)} startIcon={<ArrowBack/>} sx={buttonLinkStyle}>
                Back
              </Button>
            </div>
            {typeConfig?.guidelinesComponent}
          </>
        }
        {/* we don't want to reload the video component multiple times,
            so hide instead of unmount when switching between views */}
        <Box display={guidelinesOpen ? 'none' : 'initial'}>
          <Typography variant="body1">
            Position your { typeConfig?.verificationTypeDisplay } in the frame below.
            <Button onClick={ () => setGuidelinesOpen(true)} sx={buttonLinkStyle}>View guidelines</Button>
          </Typography>
          <canvas id="capture-canvas" ref={captureCanvasRef} style={{ display: 'none' }}></canvas>
          <Box sx={captureDisplayStyle}>
            {photo &&
              <>
                <img src={photo} alt="Capture for ID verification" />
                <Box display="flex" flexWrap="row-wrap" justifyContent="center" gap={1} my={2}>
                  <Button color="primary" onClick={() => setPhoto(null)} startIcon={<Refresh/>} variant="text">
                    Try again
                  </Button>
                  <Button color="primary" onClick={savePhoto} startIcon={<Done/>} variant="contained">
                    Accept
                  </Button>
                </Box>
              </>
            }
            <Box sx={photo ? { display: 'none' } : captureDisplayStyle}>
              {!isPreviewPlaying && <CircularProgress sx={{ position: 'absolute', zIndex: 2 }} />}
              <DeviceSelector
                isPreviewPlaying={setIsPreviewPlaying}
                kind='videoinput'
                ref={(node) => { videoRef.current = node?.videoRef }}
                setError={(_err) => {/* do nothing for now */}}
                sx={{
                  container: { display: 'flex', flexDirection: 'column-reverse' },
                  videoContainer: {
                    ...frame,
                  },
                  videoProps: {
                    height: videoHeight,
                    width: videoWidth,
                  },
                }}
                updateDeviceOption={(deviceId, _deviceProps, _e) => { setLocalDevice(deviceId) }}
                value={localDevice}
              />
              <Button
                color="primary"
                disabled={!isPreviewPlaying}
                onClick={takePicture}
                startIcon={<PhotoCameraOutlined />}
                variant="contained"
              >
                {typeConfig.captureButtonText || 'Take photo'}
              </Button>
              <Typography variant="h5" component="div" color="primary">or press enter/return to take photo</Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

IdVerificationCaptureStepPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  progressStep: PropTypes.object,
  setRequestMessage: PropTypes.func,
  typeConfig: PropTypes.object,
};

IdVerificationCaptureStepPopup.defaultProps = {
  setRequestMessage: () => { /* do nothing by default */ }
}

export default IdVerificationCaptureStepPopup;