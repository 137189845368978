import {has} from "lodash";
/**
 * Logical rule to decide if the given exam session object returned from the manage exams endpoint
 * refers to an exam that expects a connected image processing agent.
 * @param {object} examDetails The exam slot details object retrieved from the API server
 * @returns {boolean} True if the exam is an agent-monitored exam.
 */
const isAgentExam = (examDetails) => {
  return has(examDetails, 'aiType') && !(['NONE', null].includes(examDetails.aiType));
};

export default isAgentExam;
