import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import DismissButton from './DismissButton';
import Notice from '../notification/Notice';
import WarningPopup from '../popup/WarningPopup';
import ExamSessionService from '../../services/ExamSessionService';
import PromiseWithAbort from "../../utils/PromiseWithAbort";

const styles = {
  outlinedButton: {
    py: 0.5,
    px: 1,
    margin: 0.5,
    marginLeft: 2,
    lineHeight: '1rem',
    color: 'primary.contrastText',
    borderColor: 'primary.contrastText',
    '&:hover': {
      borderColor: 'primary.contrastText',
    }
  },
  disabledButton: {
    backgroundColor: 'secondary.dark',
    color: 'secondary.light',
    borderColor: 'transparent',
  },
  warningButton: {
    backgroundColor: 'warning.light',
    color: 'common.black',
    marginRight: 3,
    '&:hover': {
      backgroundColor:'warning.light',
    },
  },
  stackedButtonLabel: {
    flexDirection: 'column',
  },
};

class RestartButton extends React.Component {
  state = {
    popupOpen: false,
    errorMessage: undefined,
  };

  controller = new AbortController();
  asyncAbort = new PromiseWithAbort();

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  closeSession = async (slotId, dismissSession) => {
    const { restartCallback } = this.props;
    this.asyncAbort.wrap(ExamSessionService.endMeeting(slotId))
    .then((_result) => {
      this.setState({
        errorMessage: undefined,
      }, () => {
        if (dismissSession) {
          this.dismissSession(slotId);
        } else {
          this.closePopup();
          if (restartCallback) {
            restartCallback();
          }
        }
      })
    })
    .catch((error) => {
      if (!error.aborted) {
        this.setState({
          errorMessage: 'Sorry, the session was not closed, please try again',
        })
      }
    });
  };

  dismissSession = async (slotId) => {
    const { restartCallback } = this.props;
    this.asyncAbort.wrap(ExamSessionService.completeSupervision(slotId))
    .then((_result) => {
      this.setState({
        errorMessage: undefined,
      }, () => {
        this.closePopup();
        if (restartCallback) {
          restartCallback();
        }
      })
    })
    .catch((error) => {
      if (!error.aborted) {
        this.setState({
          errorMessage: 'Sorry, the session could not be dismissed, please try again',
        })
      }
    });
  }

  componentWillUnmount() {
    this.asyncAbort.abort();
    this.controller.abort();
  }

  render() {
    const { slotId, gatewayOpen, isTextButton, restart } = this.props;
    const { popupOpen, errorMessage } = this.state;

    const content = (
      <Box pl={5}>
        {errorMessage &&
          <Notice noticeType='error'>{errorMessage}</Notice>
        }
        {restart ?
          <>
            <Box mb={4}>
              <Typography variant="body1" gutterBottom={true}>Please use this <strong>only if</strong> it is <strong>advised by support staff</strong>.</Typography>
              <Typography variant="body1" gutterBottom={true}><strong>Restarting the session</strong> will require the <strong>student</strong> to
                <strong> re-join</strong> from their end.
                Ensure you have instructed the student to do so <strong>before continuing</strong></Typography>
            </Box>
            <Button
              variant='contained'
              sx={styles.warningButton}
              onClick={(e)=> this.closeSession(slotId, false, e)}
              aria-label='Restart the session'
            >
              Restart the session
            </Button>
          </>
        :
          <>
            <Box mb={4}>
              <Typography variant="body1" gutterBottom={true}>Are you sure you want to <strong>end the session</strong>?</Typography>
              <Typography variant="body1" gutterBottom={true}>Please ensure you have answered student queries <strong>before continuing</strong>.</Typography>
            </Box>
            <Button
              variant='contained'
              sx={styles.warningButton}
              onClick={(e)=> this.closeSession(slotId, true, e)}
              aria-label='End the session'
            >
              End the session
            </Button>
          </>
        }
        <Button variant='outlined' onClick={(e) => this.closePopup(e)}>Cancel</Button>
      </Box>
    );
    return(
      <>
        {isTextButton &&
          <Button
            variant='outlined'
            color='primary'
            onClick={this.openPopup}
            sx={{
              ...styles.stackedButtonLabel,
              '&.MuiButton-outlined': styles.outlinedButton,
              '&.Mui-disabled': styles.disabledButton,
            }}
            disabled={!gatewayOpen}
          >
            <div>Restart</div><Typography variant="body2" component="div"> (ask student to re-join)</Typography>
          </Button>
        }
        {!isTextButton &&
          <DismissButton
            onClick={this.openPopup}
          />

        }
        <WarningPopup
          title="Warning!"
          open={popupOpen}
          onClose={this.closePopup}
          content={content}
        />
      </>
    )
  }
}

RestartButton.propTypes = {
  slotId: PropTypes.string.isRequired,
  gatewayOpen: PropTypes.bool.isRequired,
  restartCallback: PropTypes.func,
  restart: PropTypes.bool,
  isTextButton: PropTypes.bool,
};

RestartButton.defaultProps = {
  restart: true,
};

export default RestartButton;
