import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { lighten } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { monitoringContext } from '../context/MonitoringContext';
import WarningPopup from '../popup/WarningPopup';
import ShiftService from '../../services/ShiftService';
import SHIFT_STATUSES from '../../constants/shift';
import { buttonLinkStyle, themeObject } from '../../config/theme';

const styles = {
  startIconColor: {
    color: '#006cab',
  },
  endIconColor: {
    color: 'error.main',
  },
};

function StartEndShiftButton(props) {
  const { isAdmin, shift, setShiftUpdated, setShiftUpdateError, multiShiftError } = props;
  const { state } = useContext(monitoringContext);
  const [popupOpen, setPopupOpen] = useState(false);

  const isShiftActive = shift.status === SHIFT_STATUSES.ACTIVE.mapper
                     || shift.status === SHIFT_STATUSES.INACTIVE.mapper;

  const updateShift = async () => {
    const shiftStatusToUpdate = isShiftActive
      ? SHIFT_STATUSES.FINISHED.mapper
      : SHIFT_STATUSES.ACTIVE.mapper;
    setShiftUpdated(false);
    setPopupOpen(false);
    try {
      await ShiftService.setShiftStatus(shift.id, shiftStatusToUpdate);
      setShiftUpdated(true);
    } catch(error) {
      setShiftUpdateError(`Sorry, we were unable to update the shift.  Error: ${error.message}`);
    }
  };

  const actionDisplay = isAdmin ? SHIFT_STATUSES[shift.status].adminAction : SHIFT_STATUSES[shift.status].action;
  const actionTag = actionDisplay !== undefined ? actionDisplay.replace(/\s+/g, '-').toLowerCase() : '';
  const superviseButton = (
    <Button
      data-name={isAdmin ? `start-end-shift-admin-button-${actionTag}-${shift.id}` : `start-end-shift-button-${actionTag}-${shift.id}`}
      size="small"
      variant={isAdmin ? 'outlined' : 'contained'}
      onClick={popupOpen ? updateShift : () => setPopupOpen(true)}
      disabled={!isShiftActive && (!isEmpty(state.activeShift) || multiShiftError)}
      sx={[
        buttonLinkStyle,
        {
          color: 'common.white',
          backgroundColor: themeObject.palette.primary.main,
          "&:hover": {
            backgroundColor: themeObject.palette.primary.dark,
          },
        },
        isShiftActive ? {
          backgroundColor: 'error.main',
          '&:hover': {
            backgroundColor: 'error.dark',
          }
        }
        : {},
        (theme) => isAdmin ? {
          color: 'error.main',
          borderColor: 'error.main',
          backgroundColor: 'common.white',
          '&:hover': {
            backgroundColor: lighten(theme.palette.error.light, 0.95),
            borderColor: 'error.main',
          },
        } : {},
      ]}
    >{actionDisplay}</Button>
  );

  const startWarningContent = (
    <>
      <Typography variant="body1">
        Once you start your session, you will <strong>begin receiving students</strong>.
      </Typography>
      <Typography variant="body1">
        <strong>Only click on start</strong> when you are ready to start supervising.
      </Typography>
      <Box display="flex" justifyContent="flex-end" mt={2}>{superviseButton}</Box>
    </>
  );

  const endWarningContent = (
    <>
      {isAdmin ?
        <Typography variant="body1">
          <strong>Ending this supervisor's session</strong> will reallocate any remaining students to other supervisors.
          If exams are still in progress, <strong>please ensure you have authorisation</strong> prior to ending this supervisor's session.
        </Typography>

        : <>
          <Typography variant="body1">
            <strong>Ending your session</strong> will reallocate any remaining students to other supervisors.
          </Typography>
          <Typography variant="body1">
            If exams are still in progress, <strong>please ensure you have authorisation</strong> prior to ending your session.
          </Typography>
        </>
      }
      <Box display="flex" justifyContent="flex-end" mt={2}>{superviseButton}</Box>
    </>
  );

  return (
    <>
      { superviseButton }
      <WarningPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        title={isAdmin ? 'Warning' : SHIFT_STATUSES[shift.status].warningTitle}
        content={isShiftActive ? endWarningContent : startWarningContent}
        sx={{ warningIcon: isShiftActive ? styles.endIconColor : styles.startIconColor }}
      />
    </>
  );
}

StartEndShiftButton.propTypes = {
  isAdmin: PropTypes.bool,
  multiShiftError: PropTypes.bool,
  setShiftUpdated: PropTypes.func.isRequired,
  setShiftUpdateError: PropTypes.func.isRequired,
  shift: PropTypes.object.isRequired,
};

export default StartEndShiftButton;
