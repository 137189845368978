import NetworkService from './NetworkService';
import MOCK from "./mock";
import mockRequest from "../utils/mockRequest";

export default class FlagService {

  static async getAllFlags(abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.FLAGS);
    }
    const response = await NetworkService.get('/flags', abortSignal);
    return await response.json();
  }

  static async getFlagFrame(examSlotId, flagId, abortSignal, imageType = 'image/jpeg') {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(
        new Response(
          new Blob(
            [new Blob()],
            {type: "image/jpeg"}
          )
        ), 2000);
    }
    return await NetworkService.get(`/examslot/${examSlotId}/flags/${flagId}/frame`, abortSignal, imageType);
  }

  static async getExamSessionFlags(examSessionId) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAM_SESSION_FLAGS);
    }
    const response = await NetworkService.get(`/examslot-flags/${examSessionId}`);
    return await response.json()
  }

  static async addExamSessionFlag(examSessionFlag) {
    const response = await NetworkService.post('/examslot-flag', examSessionFlag)
    return await response.json();
  }

  static async removeExamSessionFlag(examSessionFlagId) {
    return await NetworkService.delete(`/examslot-flag/${examSessionFlagId}`)
      .then(resp => {return resp});
  }

 }
