import { createContext } from 'react';

const initialState = {
  authorised: false,
  user: undefined,  // this info is coming from the application database
  capabilityContextAccess: {},
  isPending: true,
  isErrored: false,
  errorType: undefined,
  features: {},
  theme: undefined,
  contentOverrides: undefined,
  getAccessToken: undefined,
  setCapabilityContextAccess: undefined,
};

export const authContext = createContext(initialState);

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
