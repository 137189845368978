import {
  LibraryBooksOutlined,
  ImportContactsOutlined,
  LockOutlined,
  DevicesOutlined,
  LockOpenOutlined,
} from '@mui/icons-material/';

export const MATERIALS_EXAM_TYPE = {
  CLOSED_BOOK: {
    display: 'Closed book',
    icon: <LockOutlined/>,
  },
  SPECIALLY_PERMITTED: {
    display: 'Closed book with specifically permitted items',
    icon: <LibraryBooksOutlined/>,
  },
  OPEN_BOOK: {
    display: 'Open book',
    icon: <ImportContactsOutlined/>,
  },
  OPEN_ACCESS: {
    display: 'Open access',
    icon: <LockOpenOutlined/>,
  },
  OPEN_ADDITIONAL_DEVICE: {
    display: 'Open book with additional device',
    icon: <DevicesOutlined/>,
  },
};
