import { AccessTime, AccountBoxOutlined, AirplaneTicket, Assignment, CheckCircleOutline, Fingerprint, Gavel, ImportContacts, LockOpen, MenuBook, PermIdentity, ThreeSixty } from '@mui/icons-material';
import FaceCaptureGuidelinesText from '../components/content/FaceCaptureGuidelinesText';
import IdCaptureGuidelinesText from '../components/content/IdCaptureGuidelinesText';
import { CONFERENCE_ERROR_CATEGORIES } from './errors';
import { EXAM_SESSION_STATES } from './examSessions';
import { MEETING_STATE_KEYS } from './session';
import { SHARED_MEDIA_TYPES } from './mediaStates';

export const ACTIONS = {
  ADD_CHAT_MESSAGE: 'addChatMessage',
  SET_CURRENT_ACTION: 'setCurrentAction',
  SET_EXAM_SESSION: 'setExamSession',
  UPDATE_EXAM_SESSION: 'updateExamSession',
  UPDATE_EXAM_STATE: 'updateExamState',
  SET_GATEWAY_CONNECTION: 'setGatewayConnection',
  SET_GATEWAY_CONNECTION_OPEN: 'setGatewayConnectionOpen',
  SET_AUDIO_STATE: 'setAudioState',
  SET_VIDEO_STATE: 'setVideoState',
  SET_SCREEN_SHARE_STATE: 'setScreenShareState',
  SET_SCREEN_SHARE_TYPE: 'setScreenShareType',
  SET_SERVICE: 'setService',
  SET_ONBOARD_ASSIGNMENT: 'setOnboardAssignment',
  SET_ONBOARD_PROGRESS_STEPS: 'setOnboardProgressSteps',
  SET_PARTICIPANT_MEDIA: 'setParticipantMedia',
  SET_SHARED_MEDIA_STATUS: 'setSharedMediaStatus',
  SET_SHARED_MEDIA_WARNINGS: 'setSharedMediaWarnings',
  UPDATE_SHARED_MEDIA_STATUS: 'updateSharedMediaStatus',
  UPDATE_DEVICES: 'updateDevices',
  UPDATE_ONBOARD_PROGRESS: 'updateOnboardFlowInstanceProgress',
};

export const SERVICES = {
  GATEWAY_SERVICE: 'gatewayService',
  VIDEO_SERVICE: 'videoService',
  AUDIO_SERVICE: 'audioService',
}

const DEFAULT_BANNER = {
  backgroundGradientPalette: 'primary',
  bannerContent: <>You <strong>must</strong> leave this tab open for the duration of the exam.</>,
}

export const ADDITIONAL_PATH_PARAMS = {
  idVerificationId: 'studentId',
  idVerificationFace: 'studentPhoto',
  openExamGate: 'openExamGate',
  roomScan: 'roomScan',
};

export const ID_ANALYSIS_STATUS = {
  ATTEMPT_FAILED: 'ATTEMPT_FAILED',
  MAX_ATTEMPTS_REACHED: 'MAX_ATTEMPTS_REACHED',
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
};

export const ONBOARD_ACTION_TYPES = {
  CONNECTING: {
    mapper: 'CONNECTING',
    backgroundGradientPalette: 'warning',
    bannerContent: <>Connecting</>,
    errorCategory: CONFERENCE_ERROR_CATEGORIES.CONNECT,
    displayStepper: false,
  },
  WELCOME: {
    mapper: 'WELCOME',
    ...DEFAULT_BANNER,
    errorCategory: CONFERENCE_ERROR_CATEGORIES.CONNECT,
    displayStepper: false,
    icon: <AirplaneTicket />,
  },
  TERMS_CONDITIONS: {
    mapper: 'TERMS_CONDITIONS',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <Gavel />,
  },
  MEDIA_CHECK_INFO: {
    mapper: 'MEDIA_CHECK_INFO',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <Assignment />,
  },
  MEDIA_CHECK_AUDIO: {
    mapper: 'MEDIA_CHECK_AUDIO',
    mediaTypeName: 'AUDIO',
    ...DEFAULT_BANNER,
    displayStepper: true,
    errorCategory: CONFERENCE_ERROR_CATEGORIES.AUDIO,
  },
  MEDIA_CHECK_VIDEO: {
    mapper: 'MEDIA_CHECK_VIDEO',
    mediaTypeName: 'VIDEO',
    ...DEFAULT_BANNER,
    displayStepper: true,
    errorCategory: CONFERENCE_ERROR_CATEGORIES.WEBCAM,
  },
  MEDIA_CHECK_SCREEN: {
    mapper: 'MEDIA_CHECK_SCREEN',
    mediaTypeName: 'SCREEN',
    ...DEFAULT_BANNER,
    displayStepper: true,
    errorCategory: CONFERENCE_ERROR_CATEGORIES.DESKTOP,
  },
  ID_VERIFICATION_INFO: {
    mapper: 'ID_VERIFICATION_INFO',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <Assignment />,
  },
  ID_VERIFICATION_ID: {
    mapper: 'ID_VERIFICATION_ID',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <AccountBoxOutlined />,
    helperText: 'Present your photo ID',
    captureButtonText: 'Capture photo ID',
    verificationTypeDisplay: 'photo ID',
    guidelinesComponent: <IdCaptureGuidelinesText />,
    fileName: 'verification-id-photo.png',
    apiPathTypeCompleteParam: ADDITIONAL_PATH_PARAMS.idVerificationId,
    apiPathTypeSkipParam: ADDITIONAL_PATH_PARAMS.idVerificationId,
    sendApiAsForm: true,
  },
  ID_VERIFICATION_FACE: {
    mapper: 'ID_VERIFICATION_FACE',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <PermIdentity />,
    helperText: 'Add photo of you',
    captureButtonText: 'Take your photo',
    verificationTypeDisplay: 'face',
    guidelinesComponent: <FaceCaptureGuidelinesText />,
    fileName: 'verification-face-photo.png',
    apiPathTypeCompleteParam: ADDITIONAL_PATH_PARAMS.idVerificationFace,
    apiPathTypeSkipParam: ADDITIONAL_PATH_PARAMS.idVerificationFace,
    sendApiAsForm: true,
  },
  ID_ANALYSIS: {
    mapper: 'ID_ANALYSIS',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <Fingerprint />,
  },
  ROOM_SCAN: {
    mapper: 'ROOM_SCAN',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <ThreeSixty />,
    apiPathTypeCompleteParam: ADDITIONAL_PATH_PARAMS.roomScan,
    apiPathTypeSkipParam: ADDITIONAL_PATH_PARAMS.roomScan,
  },
  AUTHORISED_MATERIALS: {
    mapper: 'AUTHORISED_MATERIALS',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <MenuBook />,
  },
  OPEN_EXAM_GATE: {
    mapper: 'OPEN_EXAM_GATE',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <LockOpen />,
    apiPathTypeCompleteParam: ADDITIONAL_PATH_PARAMS.openExamGate,
    apiPathTypeSkipParam: ADDITIONAL_PATH_PARAMS.openExamGate,
  },
  EXAM_START: {
    mapper: 'EXAM_START',
    ...DEFAULT_BANNER,
    backgroundGradientPalette: 'popPurple',
    displayStepper: false,
    icon: <ImportContacts />,
    [EXAM_SESSION_STATES.pending]: {
      ...DEFAULT_BANNER,
    },
    [EXAM_SESSION_STATES.submitted]: {
      backgroundColor: '#019688',
      bannerContent: <CheckCircleOutline sx={{ fontSize: '9rem', fill: "white" }} />,
    },
  },
  SUPERVISOR_CONNECT: {
    mapper: 'SUPERVISOR_CONNECT',
    ...DEFAULT_BANNER,
    displayStepper: true,
    icon: <AccessTime />,
  },
  TERMINATED: {
    mapper: 'TERMINATED',
    backgroundGradientPalette: 'secondary',
    displayStepper: false,
    meetingStateKey: MEETING_STATE_KEYS.TERMINATED,
  },
  ENDED: {
    mapper: 'ENDED',
    backgroundGradientPalette: 'secondary',
    displayStepper: false,
    meetingStateKey: MEETING_STATE_KEYS.FINISHED,
  },
};

export const VIDEO_TYPE_CONFIG = {
  WEBCAM: {
    errorLabel: CONFERENCE_ERROR_CATEGORIES.WEBCAM,
    localConnectStateAction: ACTIONS.SET_VIDEO_STATE,
    localConnectStateName: 'videoConnectionState',
    serviceMediaTypeLabel: 'video',
    sharedMediaStatusLabel: SHARED_MEDIA_TYPES.WEBCAM,
  },
  SCREEN_SHARE: {
    errorLabel: CONFERENCE_ERROR_CATEGORIES.DESKTOP,
    localConnectStateAction: ACTIONS.SET_SCREEN_SHARE_STATE,
    localConnectStateName: 'screenShareConnectionState',
    serviceMediaTypeLabel: 'screenshare',
    sharedMediaStatusLabel: SHARED_MEDIA_TYPES.DESKTOP,
  },
}