import { isEmpty } from 'lodash';
import { ONBOARD_ACTION_TYPES } from '../../../constants/joinSession';

const stepDetailProgressIsIdVerificationType = (progressStepDetail) => {
  const stepType = progressStepDetail.flowStep.onboardStep.actionType;
  return stepType === ONBOARD_ACTION_TYPES.ID_VERIFICATION_ID.mapper || stepType === ONBOARD_ACTION_TYPES.ID_VERIFICATION_FACE.mapper || stepType === ONBOARD_ACTION_TYPES.ID_ANALYSIS.mapper;
};

const updateIdVerificationProgressSteps = (updatedFlowAssignment, progressStepsDetails) => {
  if (isEmpty(updatedFlowAssignment) || isEmpty(progressStepsDetails)) {
    return;
  }

  return progressStepsDetails.map(progressStepDetail => {
    const isIdVerificationType = stepDetailProgressIsIdVerificationType(progressStepDetail);
    if (isIdVerificationType) {
      const matchingUpdatedProgress = updatedFlowAssignment.onboardSlotProgress.find(progress => progress.id === progressStepDetail.progress.id);
      const mustRecomplete = Boolean(progressStepDetail.flowStep.onboardStep.mustRepeat && isEmpty(matchingUpdatedProgress.skippedBy));
      return {
        ...progressStepDetail,
        progress: { ...matchingUpdatedProgress, mustRecomplete: mustRecomplete },
      };
    };
    return progressStepDetail;
  });
};

export default updateIdVerificationProgressSteps;