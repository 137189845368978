import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import qs from 'qs';
import { isEmpty } from 'lodash';
import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import ExamSessionBySearchOption from '../components/ExamSessionBySearchOption';
import ScrollButtons from '../components/form/ScrollButtons';
import SearchFormForExamSessions from '../components/search/SearchFormForExamSessions';
import Routing from '../utils/Routing';
import { CAPABILITIES } from '../constants/capabilities';
import { SEARCH_EXAM_SESSION_LABELS } from '../constants/search';

function ManageExamSessions(_props) {
  const urlParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = qs.parse(location?.search, { ignoreQueryPrefix: true, depth: 1 });

  const renderData =
    (!(isEmpty(queryParams?.examDate))) && (!isEmpty(queryParams?.searchText)
      || !isEmpty(queryParams?.id) || !isEmpty(queryParams?.session) || urlParams?.searchBy === 'report');

  const navigateToRoute = (route) => {
    navigate(route);
  };

  const examSessionPage = (
    <ScrollButtons>
      <Typography variant="h2">Exam sessions</Typography>
      <Box display="flex" justifyContent="space-between">
        <Box flexGrow="1">
          <SearchFormForExamSessions
            key={location?.key}
            selectedDate={queryParams?.examDate}
            selectedOption={urlParams?.searchBy || ''}
            searchText={queryParams?.searchText}
            userId={queryParams?.id}
            filters={queryParams?.filters}
            selectedSession={queryParams?.session}
            navigate={navigateToRoute}
            route={Routing.MANAGE_EXAM_SESSIONS}
            labels={SEARCH_EXAM_SESSION_LABELS}
          />
        </Box>
      </Box>
      {renderData &&
        <ExamSessionBySearchOption
          key={location?.key}
          filters={queryParams?.filters}
          selectedDate={queryParams?.examDate}
          selectedOption={urlParams?.searchBy || ''}
          searchText={queryParams?.searchText}
          studentId={queryParams?.id}
          selectedSession={queryParams?.session}
        />
      }
    </ScrollButtons>
  );

  return (
    <AuthConsumer>
      {({ capabilityContextAccess }) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={[CAPABILITIES.manageAssignments, CAPABILITIES.manageExam, CAPABILITIES.viewExam, CAPABILITIES.manageIntegrity, CAPABILITIES.viewIntegrity]}
          yes={() => (examSessionPage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageExamSessions;
