import { SEARCH_OPTIONS } from './search';

// Note: If the report order is changed ensure the order is changed (if required) in flags.js
export const EXAM_RECORDINGS_FIELDS = {
  id: {
    display: 'Exam slot ID',
    reportOrder: 1,
    options: {
      download: true,
      filter: false,
      sort: false,
      display: false,
      viewColumns: false,
    },
  },
  contextName: {
    display: 'Exam name',
    reportOrder: 2,
    options: {
      download: true,
      filter: false,
      sort: false,
      display: false,
      viewColumns: false,
    },
    ungroupedOptions: {
      download: true,
      display:true,
    }
  },
  examDate: {
    display: 'Exam date',
    reportOrder: 3,
    options: {
      download: true,
      filter: false,
      sort: false,
      display: false,
      viewColumns: false,
    },
    ungroupedOptions: {
      download: true,
      display:true,
    }
  },
  studentName: {
    display: 'Student name',
    reportOrder: 4,
  },
  studentId: {
    display: 'Student ID',
    reportOrder: 5,
  },
  supervisor: {
    display: 'Supervisor',
    reportOrder: 6,
  },
  totalFlags: {
    display: 'Total flags',
    reportOrder: 19,
    options: {
      setCellProps: (_v) => {
        return {
          align: 'right',
        }
      },
    },
  },
  recordingsAvailable: {
    display: 'Video available',
    reportOrder: 20,
    options: {
      filter:false, //TODO need to think about how to filter this column nicely
      sort: false, //TODO need to think about how to sort this column nicely
      download: false, //TODO need to think about how to download this column nicely
      setCellProps: (_v) => {
        return {
          align: 'center',
        }
      },
    },
  },
  misconduct: {
    display: 'Misconduct',
    displayKey: 'misconductDisplay',
    reportOrder: 21,
  },
  recordExpiry: {
    display: 'Record expiry date',
    reportOrder: 22,
  },
  deletionStatus: {
    display: 'Deletion status',
    displayKey: 'deletionStatusDisplay',
    reportOrder: 23,
  },
  action: {
    display: 'Action',
    reportOrder: 24,
    options: {
      filter: false,
      sort: false,
      download: false,
    },
  }
};

export const EXAM_RECORDINGS_SEARCH_OPTIONS = [
  SEARCH_OPTIONS.userLookup,
  SEARCH_OPTIONS.searchText,
  SEARCH_OPTIONS.date,
  SEARCH_OPTIONS.searchMultiCombo,
  SEARCH_OPTIONS.searchSingleSelect,
];

export const GROUP_IDS = {
  DATE_UNIT: 'DATE_UNIT',
  NONE: 'NONE',
};

export const RECORDINGS_SEARCH_FIELDS = [
  {
    component: SEARCH_OPTIONS.date,
    dataProps: { 'data-name': 'search-recordings-date-input-container' },
    required: false,
    label: 'Search recordings by exam date',
    fullWidth: true,
    gridSize: 4,
  }, {
    component: SEARCH_OPTIONS.searchText,
    dataProps: { 'data-name': 'search-recordings-context-input-container' },
    name: SEARCH_OPTIONS.searchText,
    label: 'Search recordings by context',
    type: 'text',
    fullWidth: true,
    gridSize: 4,
  }, {
    component: SEARCH_OPTIONS.userLookup,
    dataProps: { 'data-name': 'search-recordings-user-input-container' },
    inputDataProps: { 'data-name': 'search-recordings-user-input-box' },
    name: SEARCH_OPTIONS.userLookup,
    label: 'Search recordings by student',
    ariaLabel: 'Search by name, student id or username',
    multiple: false,
    gridSize: 4,
  }, {
    component: SEARCH_OPTIONS.searchSingleSelect,
    dataProps: { 'data-name': 'search-recordings-group-by' },
    name: SEARCH_OPTIONS.searchSingleSelect,
    required: false,
    label: 'Group by',
    fullWidth: true,
    gridSize: 4,
    options: [{ id: GROUP_IDS.DATE_UNIT, label: 'Date and unit' }, { id: GROUP_IDS.NONE, label:  'No group' }],
  },
];

export const RECORDINGS_FLAG_SEARCH_FIELD_TEMPLATE = {
  component: SEARCH_OPTIONS.searchMultiCombo,
  dataProps: { 'data-name': 'search-recordings-flag-type' },
  fullWidth: true,
  gridSize: 8,
  label: 'Search by flag types',
  name: SEARCH_OPTIONS.searchMultiCombo,
  required: false,
};