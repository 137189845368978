import React from 'react';
import Link from '@mui/material/Link';

const styles = {
  footer: {
    padding: '2em',
    backgroundColor: '#f6f6f6',
    marginTop: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
  },
  footerNotes: {
    color: '#505050',
    fontSize: '0.8rem',
    display: 'inline-block',
    textAlign: 'left',
  },
  footerNotesLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

function Footer(_props) {
  const year = new Date().getFullYear();

  return (
    <footer style={styles.footer}>
      <div style={styles.footerNotes}>
        <p>
          Authorised by: Pro Vice-Chancellor (Academic). Maintained by:&nbsp;
          <Link
            sx={styles.footerNotesLink}
            href="mailto:its-servicedesk@monash.edu"
          >
            eSolutions ServiceDesk.
          </Link>
        </p>

        <p>
          Copyright &copy; {year} Monash University. ABN 12 377 614 012 &nbsp;
          <Link
            sx={styles.footerNotesLink}
            href="http://monash.edu/accessibility.html"
          >
            Accessibility
          </Link>{" "}
          -&nbsp;
          <Link
            sx={styles.footerNotesLink}
            href="http://www.monash.edu/disclaimer-copyright"
          >
            {" "}
            Disclaimer and copyright
          </Link>{" "}
          -&nbsp;
          <Link
            sx={styles.footerNotesLink}
            href="http://monash.edu/privacy.html"
          >
            {" "}
            Privacy
          </Link>{" "}
          CRICOS Provider Number: 00008C
        </p>

        <p>
          We acknowledge and pay respects to the Elders and Traditional Owners
          of the land on which our four Australian campuses stand.&nbsp;
          <Link
            sx={styles.footerNotesLink}
            href="http://monash.edu/about/indigenous/"
          >
            Information for Indigenous Australians
          </Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
