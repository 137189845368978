import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import mapParams, { getFullDayParamObj, getSessionParamObj } from '../utils/mapParams';

export default class ShiftService {

  static async getSupervisorShifts(supervisorId, fullDayFromDate, sessionTimes, fetchSlotCountDetails = false, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.SHIFTS);
    }

    let params = {fetchSlotCountDetails};
    if(fullDayFromDate) {
      params = {...params, ...getFullDayParamObj(fullDayFromDate)};
    }
    if(sessionTimes) {
      params = {...params, ...getSessionParamObj(sessionTimes)};
    }
    if(supervisorId) {
      params = {...params, supervisorId};
    }

    const response = await NetworkService.get(
      `/shifts?${mapParams(params)}`,
      abortSignal
    );
    return await response.json();
  }

  static async getShiftAssignments(shiftId, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }
    const response = await NetworkService.get(`/shift/${shiftId}/assignments`, abortSignal);
    return await response.json();
  }

  static async setShiftStatus(shiftId, status, abortSignal) {
    const statusObj = {status: status};
    return await NetworkService.patch(`/shift/${shiftId}`, statusObj, abortSignal).then(resp => {return resp});
  }

  static async saveShift(shift, abortSignal) {
    // add conditional for editing shift
    return await NetworkService.post('/shift', shift, undefined, abortSignal).then(resp => {return resp});
  }

  static async deleteShift(shiftId, abortSignal) {
    return await NetworkService.delete(`/shift/${shiftId}`, undefined, abortSignal).then(resp => {return resp});
  }

 }
