import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'date-fns';
import { format as dateFnsFormat } from 'date-fns/format';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const formats = {
  normalDate: 'dd/MMM/yyyy',
  keyboardDate: 'dd/MMM/yyyy',
};

function DateSelector(props) {
  const { defaultRequired, field, handleDateChange, selectedDate } = props;
  const [localDate, setLocalDate] = useState(null);

  useEffect(() => {
    if (defaultRequired && !selectedDate) {
      const today = new Date();
      handleDateChange(field?.fieldName, dateFnsFormat(today, 'yyyy-MM-dd'));
    }
    else {
      setLocalDate(selectedDate ? new Date(selectedDate) : null);
    }
  }, [defaultRequired, field, handleDateChange, selectedDate, setLocalDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
      <DatePicker
        disableMaskedInput
        format={formats.normalDate}
        label={field?.label || 'Select a date'}
        value={localDate}
        onChange={date =>
          handleDateChange(field?.fieldName, dateFnsIsValid(date) ? dateFnsFormat(date, "yyyy-MM-dd") : null)
        }
        slotProps={{
          textField: {
            ...field?.dataProps,
            id:'dateSelected',
            fullWidth: field?.fullWidth,
            name: field?.fieldName,
            variant: 'outlined',
          }
        }}
        sx={{backgroundColor: 'common.white'} }
      />
    </LocalizationProvider>
  )
}

DateSelector.propTypes = {
  defaultRequired: PropTypes.bool,
  field: PropTypes.object,
  selectedDate: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
};

export default DateSelector;
