import { isEmpty } from 'lodash';

export const shouldRedirectFromError = (redirectProps) => {
  if (isEmpty(redirectProps)) { return false; }
  const { authorised, isPending, user, path } = redirectProps;
  const allowAllRoutes = !isPending && authorised && user !== undefined;
  const onErrorRoute = path === '/error';
  return allowAllRoutes && onErrorRoute;
};

export const shouldRedirectToError = (redirectProps) => {
  if (isEmpty(redirectProps)) { return false; }
  const { authorised, isPending, user, path } = redirectProps;
  const onlyErrorRouteAllowed = !isPending && ((user === undefined && authorised) || !authorised);
  const notOnErrorRoute = path !== '/error';
  return onlyErrorRouteAllowed && notOnErrorRoute;
};