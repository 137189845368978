import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import { authContext } from '../../authContext';
import { TERMS_CONDITIONS_MAPPER } from '../../constants/contentOverrides';

const defaultStyle = {
  termsList: {
    listStyleType: 'decimal',
    '& li': {
      paddingBottom: 1,
      display: 'list-item',
      listStyle: 'decimal',
    },
  },
}

const TermsConditionsList = (props) => {
  const { contentOverrides } = useContext(authContext);

  const hasTermConditionsOverrides = !isEmpty(contentOverrides?.[TERMS_CONDITIONS_MAPPER]);

  const TermsConditionsItems = ({ termsConditions }) => {
    return termsConditions.map((tc, index) => <li key={`term-condition-${index}`}>{tc}</li>);
  }

  const defaultTermsConditions = [
    "I confirm I’ve read and understand the exam rules listed on the landing page of my eExam.",
    "I understand that my entire eExam session, including any interactions I have with my supervisor through my webcam, microphone and screen, will be monitored and recorded for the purposes of remote supervision by Monash and for authorised Monash staff validating the integrity of my assessment, if required. I understand that the recordings will be held in accordance with Monash's record keeping obligations for examinations and assessments.",
    "I understand that I must keep my webcam and entire screen shared at all times during my exam and that if my connection drops out, it may be flagged as a potential breach of exam conditions.",
    "I understand that I must remain in sight of the webcam at all times during my exam session and that, if I leave the room for any reason, I must leave my smartphone visible in front of the camera.",
    "If my exam requires handwritten responses, I understand that once my exam time has ended, or I have submitted my exam, I am not allowed to continue writing or editing my handwritten responses.",
    "I agree to follow instructions provided to me by my online exam supervisor during my exam session.",
    "I understand that if I do not provide any information required by Monash in connection with my eExam, or if I provide incorrect information, I may not be able to sit my exam.",
  ];

  const termsConditionsText = hasTermConditionsOverrides ? contentOverrides[TERMS_CONDITIONS_MAPPER] : defaultTermsConditions;

  return (
    <Box component="ol" sx={{ ...props.sx, ...defaultStyle.termsList }}>
      <TermsConditionsItems termsConditions={termsConditionsText} />
    </Box>
  );
};

TermsConditionsList.propTypes = {
  sx: PropTypes.object,
}

export default TermsConditionsList
