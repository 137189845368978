import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    margin: 1,
    padding: 1,
  },
};

function RequestStatusIndicator(props) {

  const { isPending, isErrored } = props;

  if (!isPending && !isErrored) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      {isPending && <CircularProgress />}
    </Box>
  );
}

RequestStatusIndicator.propTypes = {
  isPending: PropTypes.bool.isRequired,
  isErrored: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default RequestStatusIndicator;
