import { isEmpty } from 'lodash';

const shouldRepeatNow = (activeStepIndex, progressStep, progressStepIndex) => {
  //if step must repeat and progress is not skipped and activeStepIndex < the index of the step we're looking at
  if (isEmpty(progressStep) || !progressStep?.progress?.mustRecomplete) {
    return false;
  }
  return (activeStepIndex || -1) <= progressStepIndex;
};

export default shouldRepeatNow;