import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import { isEmpty } from 'lodash';
import ImageViewer from '../popup/ImageViewer';

function QrCode(props) {
  const canvasRef = useRef();
  const [qrCodePopupDataUrl, setQrCodePopupDataUrl] = useState();
  const [qrCodePopupOpen, setQrCodePopupOpen] = useState();

  useEffect(() => {
    if (isEmpty(props.url)) {
      return;
    }

    QRCode.toCanvas(canvasRef.current, props.url);
    QRCode.toDataURL(props.url, { margin: 2 }).then(dataUrl => setQrCodePopupDataUrl(dataUrl));
    if (props.dynamicScaling) {
      canvasRef.current.style.width = '228px';
      canvasRef.current.style.height = '';
      canvasRef.current.style.maxWidth = '100%';
    }
  }, [props.url, props.dynamicScaling]);

  return (
    <>
      <canvas id='qrCanvas' hidden={isEmpty(props.url)} ref={canvasRef} onClick={() => setQrCodePopupOpen(true)} />
      {qrCodePopupDataUrl &&
        <ImageViewer
          imageSource={qrCodePopupDataUrl}
          imageAlt="Room scan QR code"
          imageText="Scan me with your mobile device to start your room scan"
          title="Room scan QR code"
          isOpen={qrCodePopupOpen}
          onClose={() => setQrCodePopupOpen(false)}
        />
      }
    </>
  )
}

QrCode.propTypes = {
  url: PropTypes.string,
  dynamicScaling: PropTypes.bool,
}

QrCode.defaultProps = {
  dynamicScaling: false,
}

export default QrCode;
