import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import VpnKeySharpIcon from '@mui/icons-material/VpnKeySharp';
import CheckRoomScanStatusButton from '../form/CheckRoomScanStatusButton';
import GenerateQrPasscode from '../qrCode/GenerateQrPasscode';
import OpenExamGateButton from '../form/OpenExamGateButton';
import { authContext } from '../../authContext';
import { EXAM_SESSION_STATES as states } from '../../constants/examSessions';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';

const styles = {
  fieldsetContainer: {
    display: 'block',
    margin: `0 auto`,
    zIndex: 1200,
  },
  legend: {
    color: 'primary.main',
    margin: 1,
  },
};

class Verification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      examStatus: props.examDetails.state ? props.examDetails.state : states.pending,
      roomScanStatus: undefined,
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  }

  setExamStatus = (status) => {
    this.setState({ examStatus: status });
    if (status === states.canStart) {
      //TODO if the meeting hasn't started and the supervisor opens the exam gate, we'll get an error
      // we should check if a meeting exists before using updateMeeting (just in case there are issues and the supervisor just needs to open the gate)
      // but I don't want to update the supervise session page this close to exams
      this.props.onExamUnlocked();
    }
  }

  setRoomScanStatus = (status) => {
    this.setState({ roomScanStatus: status });
  }

  render() {
    const { examStatus, roomScanStatus } = this.state;
    const { slotId, examDetails } = this.props;
    const { features } = this.context;

    const shouldOnlyDisplayRoomScanStatus = features?.[FEATURE_TOGGLES.AUTO_ONBOARD] && features?.[FEATURE_TOGGLES.HIDE_SUPERVISOR_OPEN_EXAM_GATE];
    const tooltipTitle = shouldOnlyDisplayRoomScanStatus ? 'Verify room scan status' : 'Verify student';
    const formLabel = shouldOnlyDisplayRoomScanStatus ? 'Verify room scan status' : 'Verification and exam gate';

    return (
      <>
        {examDetails &&
          <>
            <Tooltip title={tooltipTitle}>
              <Fab color="primary" aria-label="Verification" onClick={this.handleClick}>
                <VpnKeySharpIcon />
              </Fab>
            </Tooltip>
            <Popover
              id="verificationPopover"
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Box p={2}>
                <FormControl component="fieldset" sx={styles.fieldsetContainer}>
                  <FormLabel component="legend" sx={styles.legend}>{formLabel}</FormLabel>
                  <Box display="flex" flexDirection="column">
                    {!shouldOnlyDisplayRoomScanStatus &&
                      <GenerateQrPasscode slotId={slotId} />
                    }
                    <CheckRoomScanStatusButton
                      slotId={slotId}
                      roomScanStatus={roomScanStatus}
                      setRoomScanStatus={this.setRoomScanStatus}
                    />
                    {!shouldOnlyDisplayRoomScanStatus &&
                      <OpenExamGateButton
                        slotId={slotId}
                        examGateStatus={examStatus}
                        setExamStatus={this.setExamStatus}
                      />
                    }
                  </Box>
                </FormControl>
              </Box>
            </Popover>
          </>
        }
      </>
    )
  }
}

Verification.propTypes = {
  slotId: PropTypes.string.isRequired,
  examDetails: PropTypes.object.isRequired,
  onExamUnlocked: PropTypes.func,
}

export default Verification;
Verification.contextType = authContext;
