import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import MonashLogo from './MonashLogo';

const styles = {
  header: {
    backgroundColor: 'secondary.contrastText',
    px: { xs: 0.5 },
    py: 0,
  },
  title: {
    flexGrow: 1,
    py: 0,
    px: 2,
  },
  hidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
};

function Header(props) {
  const { title, displayMenu, toggleNavigationMenuExpansion, studentView } = props;
  const titleStyles = title ? { ...styles.title, ...styles.hidden } : styles.title;
  return (
    <AppBar elevation={1} position="sticky" component="header">
      <Toolbar sx={styles.header}>
        <Box display="flex" flex="1" alignItems="center" justifyContent="space-between" flexDirection={studentView ? "row-reverse" : "row"}>
          {displayMenu &&
            <IconButton aria-hidden="true" onClick={toggleNavigationMenuExpansion} size="large">
              <MenuIcon />
            </IconButton>
          }
          <Typography variant="h1" sx={{ ...titleStyles, color: 'primary.main' }}>
            eVigilation
          </Typography>
          {title !== undefined && title}
          <MonashLogo />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  title: PropTypes.object,
  displayMenu: PropTypes.bool.isRequired,
  toggleNavigationMenuExpansion: PropTypes.func,
  studentView: PropTypes.bool,
};

export default Header;
