import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import VolumeUpSharpIcon from '@mui/icons-material/VolumeUpSharp';
import VolumeOffSharpIcon from '@mui/icons-material/VolumeOffSharp';
import AudioStreamService from '../../services/AudioStreamService';

class AudioStream extends React.Component {

  constructor() {
    super();

    this.state = {
      slow: 0,
      volume: 100,
      audioConnected: false,
      isTalking: false,
    };

    this.audioRef = null;
  }

  /**
   * When audio element is first rendered, set up callbacks so when the audio
   * conference is joined, media source chains are implemented.
   */
  componentDidMount() {
    const { onTalking } = this.props;
    const { streamService, showMicrophoneMeter } = this.props;
    console.debug(`[AudioStream]: mounting component and registering handlers`);
    // Register handlers and attach processors
    streamService.registerHandler('onMediaStarted', this.onAudioStarted);
    streamService.registerHandler('onMediaStopped', this.onAudioStopped);
    if (showMicrophoneMeter || onTalking) {
      streamService.attachVolumeAudioWorklet('microphone-volume', this.microphoneVolumeHandler);
    }
  }

  /**
   * When audio element is unrendered, remove any callbacks and clean up context.
   */
  componentWillUnmount() {
    const { onTalking } = this.props;
    const { streamService, showMicrophoneMeter } = this.props;
    // Audio connection has disconnected, unregister handlers
    console.debug(`[AudioStream]: unmounting component and unregistering handlers`);
    streamService.unregisterHandler('onMediaStarted', this.onAudioStarted);
    streamService.unregisterHandler('onMediaStopped', this.onAudioStopped);
    if (showMicrophoneMeter || onTalking) {
      streamService.detachVolumeAudioWorklet('microphone-volume');
    }
  }

  componentDidUpdate(prevProps) {
    const { inputDevice: nextDeviceId} = this.props;
    if (prevProps.inputDevice !== nextDeviceId) {
      // The input device has changed.
      this.setState({
        slow: 0,
      });
    }
  }

  /**
   * Handler function for changing microphone volume, that defaults to using the newer
   * web audio spec for audioWorklets in separate processing threads.
   * @param {object} event The response from the microphone volume audioWorklet, or a
   *                       raw scriptProcessor response if the browser does not support
   *                       audioWorklets.
   */
   microphoneVolumeHandler = (event) => {
    if (event.data && event.data.hasOwnProperty('volume')) {
      // Audio Worklet has responded with new volume update
      this.setState({slow: event.data.volume}, this.updateTalkingState);
    }
  }

  /**
   * Given that the new volume level has been set in the 'slow' state variable,
   * determine if the talking state has changed and trigger events as necessary.
   */
  updateTalkingState = () => {
    const { onTalking, optimum } = this.props;
    const { isTalking, slow } = this.state;
    if (onTalking) {
      if (!isTalking && slow > optimum) {
        // Started talking
        this.setState({isTalking: true}, () => {
          onTalking(true);
        })
      } else if (isTalking && slow < optimum) {
        // Stopped talking
        this.setState({isTalking: false}, () => {
          onTalking(false);
        });
      }
    }
  }

  changeVolume = (_evt, newValue) => {
    const { streamService } = this.props;
    streamService.setOutputVolume(newValue);
    this.setState({volume: newValue});
  }

  onAudioStarted = () => {
    this.setState({audioConnected: true});
  }

  onAudioStopped = () => {
    this.setState({audioConnected: false});
  }

  render() {
    const {
      showVolumeControls,
      showMicrophoneMeter,
      high,
      optimum,
      low,
      getRef
    } = this.props;
    const { slow, volume, audioConnected } = this.state;

    return (
      <>
        <audio
          style={{display: 'none'}}
          autoPlay="autoplay"
          onPlay={this.onAudioStarted}
          onPause={this.onAudioStopped}
          ref={(ref) => {
            this.audioRef = ref;
            if (getRef) {getRef(ref)}
          }}
        >
          {/* Todo: Look at captions? */}
        </audio>
        { (showVolumeControls || showMicrophoneMeter) && audioConnected &&
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {volume === 0 && <VolumeOffSharpIcon sx={{ colour: 'common.white' }} titleAccess="volume" />}
          {volume > 0 && <VolumeUpSharpIcon sx={{ colour: 'common.white' }} titleAccess="volume" />}
          {showVolumeControls &&
            <Slider
              aria-label="Change volume"
              min={0}
              max={100}
              value={volume}
              onChange={this.changeVolume}
              sx={{
                width: '140px',
                mx: 0.5,
                zIndex: 1,
                color: '#ffffff',
              }}
            />
          }
          {showMicrophoneMeter &&
            <meter
              min={0}
              max={high * 1.25}
              low={low}
              optimum={optimum}
              high={high}
              value={slow}
              style={{
                position: 'absolute',
                right: 0,
                width: '140px',
                marginRight: '4px',
                zIndex: 0,
              }}
            />
          }
        </Box>
        }
      </>
    );
  }
}

AudioStream.propTypes = {
  streamService: PropTypes.instanceOf(AudioStreamService).isRequired,
  showVolumeControls: PropTypes.bool,
  showMicrophoneMeter: PropTypes.bool,
  low: PropTypes.number,
  optimum: PropTypes.number,
  high: PropTypes.number,
  getRef: PropTypes.func,
  onTalking: PropTypes.func,
};
AudioStream.defaultProps = {
  low: 0,
  optimum: 0.05,
  high: 0.3,
  showVolumeControls: false,
  showMicrophoneMeter: false,
};

export default AudioStream;
