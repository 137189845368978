import React, { useCallback, useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import Notice from '../components/notification/Notice';
import RequestStatusIndicator from '../components/notification/RequestStatusIndicator';
import AllocationPoolTable from '../components/table/AllocationPoolTable';
import AllocationPoolService from '../services/AllocationPoolService';
import { CAPABILITIES } from '../constants/capabilities';

const ManageAllocationPoolsPage = (props) => {
  const {getRequestStatus, allocationPools, updateAllAllocationPools} = props;
  const groupedPools = groupBy(allocationPools, 'disabled');
  return (
    <>
      <Typography variant="h2">Manage allocation pools</Typography>
      {getRequestStatus.isPending &&
        <RequestStatusIndicator
          errorMessage={getRequestStatus.error}
          isErrored={!isEmpty(getRequestStatus.error)}
          isPending={getRequestStatus.isPending}
        />}
      {!isEmpty(getRequestStatus.error) &&
        <Notice noticeType="error">{getRequestStatus.error}</Notice>
      }
      {!getRequestStatus.isPending && !allocationPools &&
        <Notice noticeType="notice">There are no allocation pools</Notice>
      }
      {allocationPools &&
        <>
          {Object.keys(groupedPools).sort().map(heading => {
            const title = heading === 'true' ? 'Inactive allocation pools' : 'Active allocation pools';
            const pools = groupedPools[heading];
            return (
              <Box key={title} mb={1}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ flexDirection: 'row' }} >
                    <Typography variant="h3">{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 0 }} >
                    <AllocationPoolTable allocationPools={pools} updateAllAllocationPools={updateAllAllocationPools} />
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </>
      }
    </>
  )
}

function ManageAllocationPools() {
  const [allocationPools, setAllocationPools] = useState(undefined);
  const [getRequestStatus, setGetRequestStatus] = useState({
    isPending: false,
    error: '',
  });

  const getAllocationPools = useCallback(async () => {
    setGetRequestStatus({
      error: '',
      isPending: true,
    });
    try {
      const allocationPools = await AllocationPoolService.getAllocationPools();
      setGetRequestStatus(status => ({
        ...status,
        isPending: false,
      }));
      setAllocationPools(allocationPools);
    } catch (error) {
      setGetRequestStatus({
        isPending: false,
        error: error.message,
      });
    }
  }, []);

  const updateAllocationPoolOnDisableChange = (allocationPool) => {
    const updatedAllocationPools = allocationPools.map(ap => { return ap.id === allocationPool.id ? { ...ap, ...allocationPool } : ap });
    setAllocationPools(updatedAllocationPools);
  }

  useEffect(() => { getAllocationPools(); }, [getAllocationPools]);

  return (
    <AuthConsumer >
      {({ capabilityContextAccess }) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={CAPABILITIES.manageAllocationPools}
          yes={() => <ManageAllocationPoolsPage getRequestStatus={getRequestStatus} allocationPools={allocationPools} updateAllAllocationPools={updateAllocationPoolOnDisableChange} />}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageAllocationPools;
