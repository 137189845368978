import NetworkService from './NetworkService';
import MOCK from "./mock";
import mockRequest from "../utils/mockRequest";

export default class AppOptionService {

  static async getAllOptions(abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return await mockRequest(MOCK.OPTIONS);
    }
    const response = await NetworkService.get('/options', abortSignal);
    return await response.json();
  }

  /**
   * Fetch a specific option value from the Core API server
   * @param {string} optionName The name of the option to be retrieved
   * @param {object} abortSignal The abortController object to interrupt any network request
   * @returns {Promise} The option response json promise
   */
  static async getOption(optionName, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return await mockRequest(MOCK.OPTIONS[0].value);
    }
    const response = await NetworkService.get('/option/' + encodeURIComponent(optionName), abortSignal);
    return await response.json();
  }

  static async getClientConfig(abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return await mockRequest(MOCK.CLIENT_CONFIG);
    }
    const response = await NetworkService.get('/client-config', abortSignal);
    return await response.json();
  }
}
