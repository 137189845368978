import { cloneDeep, isEmpty } from 'lodash';

const updateUserWithAAASavedStatus = (response, originalUserList, updatedAAAState) => {
  if (isEmpty(response) || isEmpty(originalUserList)) { return; }
  const userList = cloneDeep(originalUserList);
  return userList.map((user) => {
    response.filter(r => r.id === user.id).forEach(r => {
      if(r.status === 200) {
        user.isAAA = updatedAAAState;
        user.status = 'Success';
      } else {
        user.status = 'Failed';
      }
    });
    return user;
  });
};

export default updateUserWithAAASavedStatus;