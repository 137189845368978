import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

function SearchField(props) {
  const { field, fieldProps, fieldValue, handleChange } = props;

  return (
    <TextField
      { ...field.dataProps }
      id={field.name}
      name={field.name}
      label={field.label}
      type={field.type}
      value={fieldValue || ''}
      onChange={(event) => handleChange(field.name, event.target.value)}
      size="medium"
      variant="outlined"
      fullWidth={field.fullWidth}
      InputProps={{
        endAdornment: field.endAdornment ? field.endAdornment : <></>,
        sx: { backgroundColor: 'white' }
      }}
      helperText={field.helperText}
      {...fieldProps}
    />
  )
}

SearchField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default SearchField;
