import React from 'react';

const ToiletBreak = (props) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>waterdrop</title>
    <g id="ToiletBreak" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ToiletBreak-Artboard" transform="translate(-632.000000, -273.000000)" fillRule="nonzero">
            <g id="waterdrop" transform="translate(632.000000, 273.000000)">
                <rect id="ToiletBreak-Rectangle" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
                <path d="M8.003125,1 C8.171875,1 8.325,1.065625 8.4375,1.171875 C8.4375,1.171875 8.440625,1.175 8.440625,1.175 C8.446875,1.184375 8.459375,1.19375 8.46875,1.20625 C9.7125,2.475 13,6.209375 13,10.20625 C13,12.853125 10.7625,15 8,15 C6.25,15 4.709375,14.1375 3.81875,12.83125 C3.63125,12.55625 3.471875,12.2625 3.34375,11.95 C3.125,11.409375 3.003125,10.821875 3.003125,10.20625 C3.003125,10.165625 3.00625,10.121875 3.00625,10.08125 C3.04375,7.9875 3.965625,5.978125 5.009375,4.359375 C5.63125,3.396875 6.29375,2.575 6.8375,1.959375 C7.10625,1.65625 7.346875,1.403125 7.5375,1.20625 C7.546875,1.19375 7.55625,1.184375 7.565625,1.175 L7.5671875,1.1734375 C7.678125,1.065625 7.83125,1 8.003125,1 Z M8.00437539,3 C7.86683241,3 7.74429412,3.0515625 7.65676676,3.13504464 L7.65426598,3.1375 C7.64676364,3.14486607 7.63926129,3.15223214 7.63175895,3.16205357 C7.47921128,3.31674107 7.2866511,3.515625 7.07158389,3.75379464 C6.63644791,4.2375 6.10628224,4.88325893 5.60862672,5.63950893 C4.7733657,6.91138393 4.03563516,8.49017857 4.00562578,10.1352679 L4.003125,10.2334821 L4.003125,10.2334821 C4.003125,10.7171875 4.10065548,11.1787946 4.27571018,11.6035714 C4.37824222,11.8491071 4.50578208,12.0799107 4.65582897,12.2959821 C5.3685517,13.3223214 6.60143697,14 8.00187461,14 C10.2125655,14 12.003125,12.3131696 12.003125,10.2334821 C12.003125,7.09308036 9.37230287,4.15892857 8.37699183,3.16205357 C8.36948949,3.15223214 8.35948636,3.14486607 8.3544848,3.1375 C8.3544848,3.1375 8.35198402,3.13504464 8.35198402,3.13504464 C8.26195588,3.0515625 8.13941759,3 8.00437539,3 Z M8,13.25 C7.50625,13.25 7.040625,13.134375 6.628125,12.934375 C8.6875,12.484375 10.328125,10.9125 10.871875,8.8875 C11.034375,9.265625 11.128125,9.684375 11.128125,10.125 C11.125,11.85 9.725,13.25 8,13.25 Z" id="ToiletBreak-Shape" fill="#3C4043"></path>
            </g>
        </g>
    </g>
  </svg>

);

ToiletBreak.defaultProps = {
  width: "16px",
  height: "16px",
}

export default ToiletBreak;
