import NetworkService from './NetworkService';
import MOCK from "./mock";
import mockRequest from "../utils/mockRequest";
import { isEmpty } from 'lodash';

export default class RoleService {

    static async getRoles(abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(MOCK.ROLES);
        }
        const response = await NetworkService.get(
            `/roles`
            , abortSignal);
        return await response.json();
    }

    static async saveRole(role) {
        let response;
        if(isEmpty(role.id)) {
            response = await NetworkService.post('/role', role).then(resp => {return resp});
        } else {
            response = await NetworkService.put(`/role/${role.id}`, role).then(resp => {return resp});
        }
        return response;
    }

}
