import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { authContext } from '../../authContext';
import Section from '../Section';
import Routing from "../../utils/Routing";

class ActivateDash extends React.Component {

  openMonitorSessions = () => {
    window.open(`/${Routing.MONITOR_SESSIONS}`, 'EvigMonitoringDash', 'noreferrer');
  }

  render() {
    return (
      <Section>
        <Box display="flex" justifyContent="space-between">
          <Box p={3}>
            <Box pb={2}>
              <Typography>
                Ready to <strong>start supervising</strong>?<br/>
                You can now <strong>join the dashboard</strong> where you will commence your session.
              </Typography>
            </Box>
            <Button
              variant='contained'
              color='primary'
              onClick={() => this.openMonitorSessions()}
            >Join enhanced dashboard</Button>
          </Box>
          <Box display={{xs: 'none', md: 'flex'}} justifyContent="flex-end">
              <img src="/images/plane.svg" alt="" title="" />
          </Box>
        </Box>
      </Section>
    )
  }
}

export default (ActivateDash);
ActivateDash.contextType = authContext;
