import React from 'react';
import { Box, Typography } from '@mui/material';

function ExamSubmittedText(_props) {
  const textSpacing = { pl: 2, pr: 4, py: 1 };
  return (
    <div id="submitted-text">
      <Typography
        variant="h2"
        sx={textSpacing}
      >
        Your eExam responses have been successfully submitted.
      </Typography>
      <Box sx={textSpacing}>
        <Typography variant="body1">
          If you have any questions and would like to speak to your supervisor, click the <strong>&lsquo;raise hand&rsquo;</strong> button for help.
        </Typography>
        <Typography variant="body1">
          Otherwise, feel free to close this tab at any time.
        </Typography>
      </Box>
    </div>
  )
}

export default ExamSubmittedText;