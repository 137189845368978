export const FEATURE_TOGGLES = {
  MONITOR_SESSIONS: 'monitorSessions',
  JITA: 'jita',
  FETCH_SHIFT_ALLOCATED_SLOTS: 'shiftAllocatedSlots',
  SESSION_SINGLE_ASSIGN_BUTTONS: 'sessionSingleAssignButtons',
  HIDE_DETAILED_ERROR_CODES: 'hideDetailedErrorCodes',
  WARN_PARTIAL_SCREENSHARE: 'warnPartialScreenshare',
  AUTO_ONBOARD: 'autoOnboard',
  HIDE_ADMIN_OPEN_EXAM_GATE: 'hideAdminOpenExamGate',
  HIDE_SUPERVISOR_OPEN_EXAM_GATE: 'hideSupervisorOpenExamGate',
};
