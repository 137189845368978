import React from 'react';
import RecordingOnIcon from '../../icons/RecordingOn';
import SecurityShieldIcon from '../../icons/SecurityShield';

const styles = {
  alertContainer: {
    width: '251px',
    height: '31px',
    backgroundColor: '#fbecf7',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px',
    opacity: '90%',
  },
  alertText: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#c8008f',
    flexGrow: 1,
    marginLeft: '5px',
  },
  alertIcon: {
    margin: '4px',
  },
};

function RecordingStatusIndicator(_props) {

  return (
    <div style={styles.alertContainer}>
      <div style={styles.alertIcon}><RecordingOnIcon /></div>
      <div style={styles.alertIcon}><SecurityShieldIcon /></div>
      <div style={styles.alertText}>Screen + Cam Recording &hellip;</div>
    </div>
  );
}

export default RecordingStatusIndicator;
