import { has, isEqual } from 'lodash';
import displaySupervisorPeerName from './displaySupervisorPeerName';

/**
 * Based on a change to the meeting state properties held for the supervisor dashboard session currently being rendered,
 * determine any notification messages that should be displayed to the supervisor. This should currently only ever return
 * one notification per gateway message.
 * @param {string} prevSlotId The previous slot being displayed to the supervisor
 * @param {object} prevMeetingStateProperties The previous meeting state properties
 * @param {string} newSlotId The new slot being displayed to the supervisor
 * @param {object} newMeetingStateProperties The updated meeting state properties received from the BBB gateway
 * @returns List of notification strings to display, if any
 */
const getSupervisorDashboardMeetingChangeNotifications = (
  prevSlotId, 
  prevMeetingStateProperties, 
  newSlotId,
  newMeetingStateProperties
) => {
  let newNotifications = [];

  // If a supervisor has joined or left, show an alert
  const prevPeers = has(prevMeetingStateProperties, 'peers')
  ? Object.getOwnPropertyNames(prevMeetingStateProperties.peers)
  : [];
  const newPeers = has(newMeetingStateProperties, 'peers')
  ? Object.getOwnPropertyNames(newMeetingStateProperties.peers)
  : [];

  // Joining peers will not exist in the old array
  const joiningPeers = newPeers.filter(np => !prevPeers.some(pp => pp === np));

  // Leaving peers will not exist in the new array
  const leavingPeers = prevPeers.filter(pp => !newPeers.some(np => np === pp));

  joiningPeers.forEach(p => {
    if (newMeetingStateProperties.peers[p]?.userType !== 'AGENT') {
      let userName = newMeetingStateProperties.peers[p]?.userName;
      newNotifications.push(displaySupervisorPeerName(userName) + ' is also connected');
    }
  });

  if (prevSlotId === newSlotId) {
    // Only show notifications for peers that actually left, not just because we have
    // switched to another session
    leavingPeers.forEach(p => {
      if (prevMeetingStateProperties.peers[p]?.userType !== 'AGENT') {
        let userName = prevMeetingStateProperties.peers[p]?.userName;
        newNotifications.push(displaySupervisorPeerName(userName) + ' has disconnected');
      }
    });
  }

  // If the supervisor has started/stopping webcam sharing, show an alert
  if (!isEqual(prevMeetingStateProperties?.videoOverride?.stream, newMeetingStateProperties?.videoOverride?.stream)) {
    if (newMeetingStateProperties?.videoOverride?.stream) {
      // There is a new supervisor stream
      let userName = newMeetingStateProperties?.peers?.[newMeetingStateProperties.videoOverride.peer]?.userName;
      newNotifications.push(displaySupervisorPeerName(userName) + ' is sharing video to student');
    } 
    if (prevSlotId === newSlotId && prevMeetingStateProperties?.videoOverride?.stream) {
      // Supervisor has stopped streaming (and we are still looking at the same slot session)
      let userName = prevMeetingStateProperties?.peers?.[prevMeetingStateProperties.videoOverride.peer]?.userName;
      newNotifications.push(displaySupervisorPeerName(userName) + ' stopped video share to student');
    }
  }

  return newNotifications;
};

export default getSupervisorDashboardMeetingChangeNotifications;