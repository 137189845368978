import { has } from 'lodash';

const formatAudioError = (err, source, options) => {

  // GetUserMedia errors have known exceptions - https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
  // This also needs to handle other exceptions in our own code around accessing an audio device
  // Called from AudioStreamService class as well as the JoinSession page
  if (source === 'setInputDevice') {
    if (err instanceof DOMException) {
      if (err.name === 'AbortError' || err.name === 'NotReadableError') { 
        return {
          errorKey: 'AUD.1',
          errorCode: 'MIC_DEVICE_NOT_ACCESSIBLE',
          details: err,
        };
      }
      if (err.name === 'NotAllowedError' || err.name === 'SecurityError' ) {
        return {
          errorKey: 'AUD.2',
          errorCode: 'MIC_DEVICE_PERMISSION_DENIED',
          details: err,
        };
      }
      if (err.name === 'NotFoundError' || err.name === 'OverconstrainedError' || err.name === 'TypeError') {
        return {
          errorKey: 'AUD.3',
          errorCode: 'MIC_DEVICE_NOT_FOUND',
          details: err,
        };
      }
    }

    // Otherwise, we have a generic device access error
    return {
      errorKey: 'AUD.4',
      errorCode: 'MIC_DEVICE_ACCESS_FAIL',
      details: err,
    };
  }

  // SetSinkId error has only one exception - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId  if (source === 'getOutputDevice') {
  // Called from the AudioStreamService class as well as the student JoinSession page
  if (source === 'setOutputDevice') {
    return {
      errorKey: 'AUD.5',
      errorCode: 'AUDIO_OUT_DEVICE_ACCESS_FAIL',
      details: err,
    };
  }

  // SIP errors from audio stream service call connection
  // Called from the AudioStreamService class
  if (source === 'sipSession') {
    switch (options.code) {
      case 1001:
        return {
          errorKey: 'AUD.6',
          errorCode: 'SIP_ERROR_1001_RECONNECT_FAIL',
          details: err.bridgeError,
        };
      case 1002:
        return {
          errorKey: 'AUD.7',
          errorCode: 'SIP_ERROR_1002_CONNECT_FAIL',
          details: err.bridgeError,
        };
      case 1004:
        return {
          errorKey: 'AUD.8',
          errorCode: 'SIP_ERROR_1004_CALL_DROP_IN_SETUP',
          details: err.bridgeError,
        };
      case 1005:
        return {
          errorKey: 'AUD.9',
          errorCode: 'SIP_ERROR_1005_CALL_DROP',
          details: err.bridgeError,
        };
      case 1006:
        return {
          errorKey: 'AUD.10',
          errorCode: 'SIP_ERROR_1006_CALL_TIMEOUT',
          details: err.bridgeError,
        };
      case 1007:
        return {
          errorKey: 'AUD.11',
          errorCode: 'SIP_ERROR_1007_ICE_NEGOT_FAIL',
          details: err.bridgeError,
        };
      case 1008:
        return {
          errorKey: 'AUD.12',
          errorCode: 'SIP_ERROR_1008_TRANSFER_FAIL',
          details: err.bridgeError,
        };
      case 1010:
        return {
          errorKey: 'AUD.13',
          errorCode: 'SIP_ERROR_1010_CALL_ICE_TIMEOUT',
          details: err.bridgeError,
        };
      case 1012:
        return {
          errorKey: 'AUD.14',
          errorCode: 'SIP_ERROR_1012_ICE_TERMINATED',
          details: err.bridgeError,
        };
      default:
        return {
          errorKey: 'AUD.15',
          errorCode: 'SIP_ERROR_' + options.code,
          details: err.bridgeError
        };
    }
  }

  // Tried to initiate a call when it was already active
  // Called from the AudioStreamService class
  if (source === 'callActive') {
    if (options.echoTest) {
      return {
        errorKey: 'AUD.16',
        errorCode: 'ALREADY_CONNECTED_TO_ECHO',
        details: err,
      };
    } else {
      return {
        errorKey: 'AUD.17',
        errorCode: 'ALREADY_CONNECTED_TO_CALL',
        details: err,
      };
    }
  }

  // Tried to transfer a call from echo test to conference and it failed
  // Called from the student session page
  if (source === 'transferToConference') {
    return {
      errorKey: 'AUD.18',
      errorCode: 'TRANSFER_TO_CONF_FAILED',
      details: 'Transfer from echo test to audio call failed',
    };
  }

  if (has(err, 'errorKey') && has(err, 'errorCode') && has(err, 'details')) {
    // Error is already formatted
    return err;
  }

  // Generic audio error
  return {
    errorKey: 'AUD.99',
    errorCode: 'AUDIO_ERROR',
    details: err,
  };
};

const formatWebcamError = (err, source, _options) => {

  switch (source) {
    case 'streamOpen':
      return {
        errorKey: 'WEBC.1',
        errorCode: 'WEBCAM_INIT_ERROR',
        details: err,
      };
    case 'permissionDenied':
      return {
        errorKey: 'WEBC.2',
        errorCode: 'WEBCAM_PERMISSION_DENIED',
        details: err,
      };
    case 'permissionDeniedBySystem':
      return {
        errorKey: 'WEBC.3',
        errorCode: 'WEBCAM_PERMISSION_DENIED_BY_SYSTEM',
        details: err,
      };
    case 'peerTimeout':
      return {
        errorKey: 'WEBC.4',
        errorCode: 'WEBCAM_PEER_TIMEOUT',
        details: err,
      };
    case 'peerCreation':
      return {
        errorKey: 'WEBC.5',
        errorCode: 'WEBCAM_PEER_CREATION',
        details: err,
      };
    case 'iceFailed':
      return {
        errorKey: 'WEBC.6',
        errorCode: 'WEBCAM_ICE_NEGOT_FAILED',
        details: err,
      };
    case 'sfuError':
      return {
        errorKey: 'WEBC.7',
        errorCode: 'WEBCAM_SFU_SERVER_ERROR',
        details: err,
      };
    case 'sdpAnswer':
      return {
        errorKey: 'WEBC.8',
        errorCode: 'WEBCAM_SDP_ANSWER_ERROR',
        details: err,
      };
    case 'videoPreviewFailed':
      if (err instanceof DOMException) {
        if (err.name === 'AbortError' || err.name === 'NotReadableError') { 
          return {
            errorKey: 'WEBC.9',
            errorCode: 'WEBCAM_DEVICE_NOT_ACCESSIBLE',
            details: err,
          };
        }
        if (err.name === 'NotAllowedError' || err.name === 'SecurityError' ) {
          return {
            errorKey: 'WEBC.2',
            errorCode: 'WEBCAM_PERMISSION_DENIED',
            details: err,
          };
        }
        if (err.name === 'NotFoundError' || err.name === 'OverconstrainedError' || err.name === 'TypeError') {
          return {
            errorKey: 'WEBC.10',
            errorCode: 'WEBCAM_DEVICE_NOT_FOUND',
            details: err,
          };
        }
      }
      return {
        errorKey: 'WEBC.11',
        errorCode: 'WEBCAM_PREVIEW_FAILED',
        details: err,
      };
    default:

      if (has(err, 'errorKey') && has(err, 'errorCode') && has(err, 'details')) {
        // Error is already formatted
        return err;
      }

      // Generic webcam error
      return {
        errorKey: 'WEBC.99',
        errorCode: 'WEBCAM_ERROR',
        details: err,
      };
  }
};

const formatScreenshareError = (err, source, _options) => {

  switch (source) {
    case 'partialScreenshare':
      return {
        errorKey: 'DESKT.1',
        errorCode: 'PARTIAL_SCREENSHARE',
        details: err,
      }
    case 'permissionDenied':
      return {
        errorKey: 'DESKT.2',
        errorCode: 'SCREENSHARE_PERMISSION_DENIED',
        details: err,
      };
    case 'permissionDeniedBySystem':
      return {
        errorKey: 'DESKT.3',
        errorCode: 'SCREENSHARE_PERMISSION_DENIED_BY_SYSTEM',
        details: err,
      };
    case 'screenshareEnded':
      return {
        errorKey: 'DESKT.4',
        errorCode: 'SCREENSHARE_STOPPED',
        details: err,
      };
    case 'sourceSelection':
      return {
        errorKey: 'DESKT.5',
        errorCode: 'SCREENSHARE_SOURCE_SELECT_ERROR',
        details: err,
      };
    case 'peerTimeout':
      return {
        errorKey: 'DESKT.6',
        errorCode: 'SCREENSHARE_PEER_TIMEOUT',
        details: err,
      };
    case 'peerCreation':
      return {
        errorKey: 'DESKT.7',
        errorCode: 'SCREENSHARE_PEER_CREATION',
        details: err,
      };
    case 'iceFailed':
      return {
        errorKey: 'DESKT.8',
        errorCode: 'SCREENSHARE_ICE_NEGOT_FAILED',
        details: err,
      };
    case 'sfuError':
      return {
        errorKey: 'DESKT.9',
        errorCode: 'SCREENSHARE_SFU_SERVER_ERROR',
        details: err,
      };
    case 'sdpAnswer':
      return {
        errorKey: 'DESKT.10',
        errorCode: 'SCREENSHARE_SDP_ANSWER_ERROR',
        details: err,
      };
    default:

      if (has(err, 'errorKey') && has(err, 'errorCode') && has(err, 'details')) {
        // Error is already formatted
        return err;
      }

      // Generic screenshare error
      return {
        errorKey: 'DESKT.99',
        errorCode: 'SCREENSHARE_ERROR',
        details: err,
      };
  }
};

const formatConnectionError = (err, source, _options) => {

  switch (source) {
    case 'gatewayDisconnection':
      return {
        errorKey: 'CONN.1',
        errorCode: 'GATEWAY_DISCONNECTION',
        details: err,
      };
    case 'gatewayAuthenticationFail':
      return {
        errorKey: 'CONN.2',
        errorCode: 'GATEWAY_AUTHENTICATION_ERROR',
        details: err,
      };
    case 'joinMeetingFail':
      return {
        errorKey: 'CONN.3',
        errorCode: 'MEETING_ACCESS_ERROR',
        details: err,
      };
    default:

      if (has(err, 'errorKey') && has(err, 'errorCode') && has(err, 'details')) {
        // Error is already formatted
        return err;
      }

      return {
        errorKey: 'CONN.99',
        errorCode: 'CONNECTION_ERROR',
        details: err,
      };
  }
  
};

/**
 * 
 * @param {object} err The caught error that needs to be formatted for display
 * @param {string} errorType The type of error to be handled (eg. audio, webcam, desktop, connection)
 * @param {string} source An optional source of where the error originated, could be a method call or keyword.
 * @param {object} options Any additional options specific to the error type/source
 */
export function formatErrorForDisplay(err, errorType, source, options = undefined) {

  switch (errorType) {
    case 'audio':
      return formatAudioError(err, source, options);
    case 'webcam':
      return formatWebcamError(err, source, options);
    case 'desktop':
      return formatScreenshareError(err, source, options);
    case 'connection':
      return formatConnectionError(err, source, options);
    default:
      return {
        errorKey: 'GEN.1',
        errorCode: errorType && errorType.toUpperCase() + '_ERROR',
        details: err,
      };
  }
}