import { CONFERENCE_ERROR_CATEGORIES } from '../../../constants/errors';
/**
 * When the gateway connection returns after a dropout, clear error message.
 */
const onGatewayReconnected = (setConnectionError, setGatewayConnectionOpen) => {
  return () => {
    setConnectionError(CONFERENCE_ERROR_CATEGORIES.CONNECT, {}, true);
    console.info('Gateway connected successfully.');
    setGatewayConnectionOpen(true);
  };
};

export default onGatewayReconnected;