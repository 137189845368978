import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { SESSION_TIMES } from '../../constants/examSessions';

function SessionSelector(props) {

  const { fieldName, handleSessionChange, selectedSession } = props
  return (
    <RadioGroup
      row aria-label="session"
      name={fieldName}
      defaultValue="top"
      value={selectedSession}
      onChange={(event) => handleSessionChange(fieldName, event.target.value)}
      sx={{ pt: 1 }}
      >
      {Object.keys(SESSION_TIMES).map((key) => {
        return (
          <FormControlLabel
            key={key}
            value={SESSION_TIMES[key].value}
            control={<Radio color="primary" />}
            label={SESSION_TIMES[key].displayName}
            labelPlacement="end"
          />
        )
      })}
    </RadioGroup>
  )
}

SessionSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  selectedSession: PropTypes.string,
  handleSessionChange: PropTypes.func.isRequired,
};

//avoid undefined for a controlled component - should be managed in ancestor
SessionSelector.defaultProps = {
  selectedSession: null,
}

export default SessionSelector;
