import React from 'react';
import Typography from '@mui/material/Typography';
import { AuthConsumer } from '../authContext';
import ActivateDash from '../components/form/ActivateDash';
import Can from '../components/Can';
import ExamSessionByUser from '../components/ExamSessionByUser';
import ScrollButtons from '../components/form/ScrollButtons';
import Section from '../components/Section';
import WithShifts from '../components/container/WithShifts';
import { FEATURE_TOGGLES } from '../constants/featureToggles';

function Home(_props) {

  const showMessage = () => {
    return (
      <Section>
        <Typography variant="h3" component="h2">You aren't allocated to any sessions today.</Typography>
        <Typography variant="body1">
          If this is an error, please contact your escalation supervisor to ensure you are assigned a session.
        </Typography>
      </Section>
    )
  };

  return (
    <AuthConsumer>
      {({ user, capabilityContextAccess, features }) => (
        <ScrollButtons>
          <Can
            capabilityContextAccess={capabilityContextAccess}
            contextRequired={false}
            perform="ONBOARD_EXAM"
            yes={() => (
              <ExamSessionByUser isSupervisor={false} userId={user.id} />
            )}
          />
          <Can
            capabilityContextAccess={capabilityContextAccess}
            contextRequired={false}
            perform="SUPERVISE_EXAM"
            yes={() => (
              <ExamSessionByUser isSupervisor={true} userId={user.id} />
            )}
          />
          <Can
            capabilityContextAccess={capabilityContextAccess}
            contextRequired={false}
            perform={['SUPERVISE_EXAM','ONBOARD_EXAM']}
            data={{user}}
            yes={() => (
              <>
                {features[FEATURE_TOGGLES.MONITOR_SESSIONS] &&
                  <>
                    {features[FEATURE_TOGGLES.JITA]
                      ? (<WithShifts userId={user.id} else={() => showMessage()}>
                          <ActivateDash />
                        </WithShifts>)
                      : <ActivateDash />
                    }
                  </>
                }
              </>
            )}
          />
        </ScrollButtons>
      )}
    </AuthConsumer>
  );
}

export default Home;
