import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormPopup from '../popup/FormPopup';
import UploadForm from './UploadForm';

class UploadButton extends React.Component {
  state = {
    popupOpen: false,
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  render() {
    const { title, additionalFieldsForm, evigFileType, setHasUpdated, id } = this.props;
    const { popupOpen } = this.state;

    return(
      <>
        <Button
          id={id}
          variant="contained"
          color="primary"
          onClick={this.openPopup}
        >
          {title}
        </Button>
        <FormPopup
          title={title}
          open={popupOpen}
          onClose={this.closePopup}
          content={
            <UploadForm additionalFieldsForm={additionalFieldsForm} setHasUpdated={setHasUpdated} evigFileType={evigFileType}/>
          }
          stretch={true}
          maxWidth="sm"
        />
      </>
    )
  }
}

UploadButton.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  additionalFieldsForm : PropTypes.object,
  evigFileType: PropTypes.object.isRequired,
  setHasUpdated: PropTypes.func,
};

export default UploadButton;
