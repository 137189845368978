import { isEmpty } from 'lodash';

export function capitalise(string) {
  if (typeof string !== 'string' && string !== undefined && string !== null) {
    throw Error('Value must be a string');
  }

  if (isEmpty(string)) {
    return string;
  }

  return string[0].toLocaleUpperCase() + string.slice(1)?.toLowerCase();
}
