import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/material';

const styles = {
  imageViewer: {
    zIndex: 1200,
  },
  dialogContainer: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  viewedImage: {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
  closeButton: {
    marginLeft: 'auto',
    color: 'grey[500]',
  }
};

function ImageViewer(props) {

  const { imageSource, imageAlt, imageText, title, isOpen, onClose } = props;

  const handleImageViewerClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      maxWidth="lg"
      scroll="paper"
      open={isOpen}
      onClose={handleImageViewerClose}
      aria-labelledby="image-viewer-title"
      sx={styles.imageViewer}
    >
      <DialogContent sx={styles.dialogContainer}>
        <Card>
          <CardContent sx={{ p: 0 }}>
            <Box width="100%" display="flex" >
              <IconButton
                sx={styles.closeButton}
                onClick={handleImageViewerClose}
                aria-label="Close this preview"
                size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </CardContent>
          <CardMedia
            component="img"
            sx={styles.viewedImage}
            src={imageSource}
            title={imageAlt}
          />
          <CardContent>
            <Typography gutterBottom variant="h2">
              {title}
            </Typography>
            <Typography variant="body1" component="p">
              {imageText}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

ImageViewer.propTypes = {
  imageSource: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageText: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ImageViewer.defaultProps = {
  isOpen: false,
};

export default ImageViewer;
