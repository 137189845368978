import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

class DeleteButton extends React.Component {
  state = {
    deleteConfirm: false,
    deleteSuccess: false,
    deleteFail: false,
  };

  controller = new AbortController();

  handleDelete = async () => {
    const { deleteServiceCallback, itemId, removeItem } = this.props;
    if(this.state.deleteConfirm) {
      try {
        await deleteServiceCallback(itemId, this.controller.signal);
        this.setState({deleteFail: false, deleteSuccess: true});
        removeItem && removeItem(itemId);
      } catch(error) {
        if(error.name !== 'AbortError') {
          console.log(error);
          this.setState({deleteFail: true, deleteSuccess: false});
        }
      }
    } else {
      this.setState({deleteConfirm: true})
    }
  };

  render() {
    const { buttonAriaLabel, itemId } = this.props;
    const { deleteConfirm, deleteFail, deleteSuccess } = this.state;

    const buttonText = deleteConfirm ? 'Confirm permanent delete' : 'Delete';

    return(
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {!deleteSuccess &&
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={this.handleDelete}
            id={itemId}
            name={itemId}
            aria-label={buttonAriaLabel}
          >{buttonText}</Button>
        }
        {deleteSuccess &&
          <Tooltip title="Successfully deleted">
            <CheckCircleOutlineIcon color="primary"/>
          </Tooltip>
        }
        {deleteFail &&
          <Tooltip title="Delete failed, please refresh the page and try again">
            <CancelIcon color="error"/>
          </Tooltip>
        }
      </Box>
    )
  }
}

DeleteButton.propTypes = {
  buttonAriaLabel: PropTypes.string.isRequired,
  deleteServiceCallback: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  removeItem: PropTypes.func,
};

export default DeleteButton;
