import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeviceSelector from '../form/DeviceSelector';
import { DEVICE_TYPES } from '../../constants/devices';
import { SHARED_MEDIA_STATES } from '../../constants/mediaStates';

function VideoCheck(props) {
  const { devices, handleConfirm, troubleshootLabel, updateDevices, connectionState } = props;
  const [webcamError, setWebcamError] = useState('No webcam selected yet');

  const updateDevice = (deviceType, deviceId) => {
    updateDevices({ ...devices, [deviceType]: deviceId });
  };

  const isConnectedOrConnecting = connectionState === SHARED_MEDIA_STATES.CONNECTED || props.connectionState === SHARED_MEDIA_STATES.CONNECTING;

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Can you see yourself on the screen?<br/>
        If you can't see anything select a different webcam.
      </Typography>
      <DeviceSelector
        kind='videoinput'
        updateDeviceOption={(deviceId, deviceProps, e) => {updateDevice(DEVICE_TYPES.VIDEO_INPUT, deviceId, deviceProps, e)}}
        value={devices?.[DEVICE_TYPES.VIDEO_INPUT]}
        setError={(err) => setWebcamError(err)}
      />
      <Box display="flex" justifyContent="flex-end" m={2}>
        {!webcamError &&
          <Button
            color="primary"
            disabled={isConnectedOrConnecting}
            variant="contained"
            onClick={() => handleConfirm()}
          >
            Next
          </Button>}
        {webcamError &&
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              props.onClose({
                webcam: webcamError
              });
            }}
          >
            {troubleshootLabel}
          </Button>
        }
      </Box>
    </>
  )
}

VideoCheck.propTypes = {
  connectionState: PropTypes.string,
  devices: PropTypes.object,
  handleConfirm: PropTypes.func,
  onClose: PropTypes.func,
  troubleshootLabel: PropTypes.string,
  updateDevices: PropTypes.func,
};

VideoCheck.defaultProps = {
  troubleshootLabel: 'Troubleshoot',
};

export default VideoCheck;