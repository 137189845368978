import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash';
import MUIDataTable from 'mui-datatables'
import { Box, Chip } from '@mui/material';
import AllocationPoolActionMenu from '../form/AllocationPoolActionMenu';
import AllocationPoolForm from '../form/AllocationPoolForm';
import FormPopup from '../popup/FormPopup';
import { ALLOCATION_POOLS_DISPLAY } from '../../constants/allocationPools';
import { capitalise } from '../../utils/capitalise';

function AllocationPoolTable(props) {

  const [allocationPools, setAllocationPools] = useState(props.allocationPools);
  const [selectedPool, setSelectedPool] = useState(null);

  const [updatedAllocationPool, setUpdatedAllocationPool] = useState();
  const [disabledUpdated, setDisabledUpdated] = useState(false);
  const [editAllocationPoolPopupOpen, setEditAllocationPoolPopupOpen] = useState(false);

  // Update the state if any of the pools from the parent component change
  useEffect(() => setAllocationPools(props.allocationPools), [props.allocationPools]);

  const closeEditAllocationPoolPopup = () => {
    setEditAllocationPoolPopupOpen(false);
    if (!isEmpty(updatedAllocationPool)) {
      updateAllocationPool(updatedAllocationPool, disabledUpdated);
    }
  }

  const openEditAllocationPoolPopup = (allocationPool) => {
    setSelectedPool(allocationPool);
    setEditAllocationPoolPopupOpen(true);
  }

  const updateAllocationPool = (allocationPool, isDisabledUpdated) => {
    // If we change the disabled status, we need to update both allocation pool tables
    if (isDisabledUpdated) {
      props.updateAllAllocationPools(allocationPool);
    } else {
      const updatedAllocationPools = allocationPools.map(ap => { return ap.id === allocationPool.id ? { ...ap, ...allocationPool } : ap });
      setAllocationPools(updatedAllocationPools);
    }
  }

  const getColumns = () => {
    const columns = [];
    if (isEmpty(allocationPools)) {
      return columns;
    }
    Object.keys(ALLOCATION_POOLS_DISPLAY).forEach(heading => {
      const columnLabel = ALLOCATION_POOLS_DISPLAY[heading].display;
      const baseColumn = {
        name: heading,
        label: columnLabel,
        options: ALLOCATION_POOLS_DISPLAY[heading].options,
      }

      if (heading === 'allowedUserTypes') {
        baseColumn.options = {
          ...baseColumn.options,
          customBodyRenderLite: (dataIndex) => {
            const userTypes = allocationPools[dataIndex].allowedUserTypes;
            if (!isEmpty(userTypes)) {
              const userTypeChips = allocationPools[dataIndex].allowedUserTypes
                .split(',')
                .filter(userType => !isEmpty(userType))
                .map(userType =>
                  <Chip key={userType} label={capitalise(userType)} size="small" />)
              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {userTypeChips}
                </Box>
              )
            }
            return null;
          }
        }
      }

      if (heading === 'actions') {
        baseColumn.options = {
          ...baseColumn.options,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AllocationPoolActionMenu
                  allocationPool={allocationPools[dataIndex]}
                  openEditAllocationPoolPopup={openEditAllocationPoolPopup}
                />
              </Box>
            );
          }
        }
      }
      columns.push(baseColumn);
    })
    return columns;
  }

  const options = {
    selectableRows: 'none',
    filterType: 'multiselect',
    pagination: false,
    print: false,
  };

  return (
    <>
      <MUIDataTable
        data={allocationPools}
        columns={getColumns()}
        options={options}
      />
      <FormPopup
        title="Edit allocation pool"
        open={editAllocationPoolPopupOpen}
        onClose={closeEditAllocationPoolPopup}
        stretch={true}
        maxWidth="sm"
        content={<AllocationPoolForm
          setUpdatedAllocationPool={setUpdatedAllocationPool}
          setDisabledUpdated={setDisabledUpdated}
          allocationPool={selectedPool} />}
      />
    </>
  );
}

AllocationPoolTable.propTypes = {
  allocationPools: PropTypes.arrayOf(Object),
  updateAllAllocationPools: PropTypes.func,
}

export default AllocationPoolTable;
