import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AuthConsumer, authContext } from '../authContext';
import BrowserCheckWrapper from '../components/popup/BrowserCheckWrapper';
import ContactNumbers from '../components/content/ContactNumbers';
import Header from '../components/content/Header';
import Join from '../components/Join';
import { JoinProvider } from '../components/context/JoinContext';
import JoinMeetingConnect from '../components/audioVisual/JoinMeetingConnect';
import Notice from '../components/notification/Notice';
import ExamDetails from '../components/content/ExamDetails';
import SupervisionComplete from '../components/content/SupervisionComplete';

import ExamSessionService from '../services/ExamSessionService';
import { getStudentUser } from "../utils/getExamSlotUser";

import {
  EXAM_SESSION_DELETION_STATUS,
  EXAM_SESSION_STATES,
} from '../constants/examSessions';

function JoinOnboardSession(_props) {
  const { authorised } = useContext(authContext);
  const urlParams = useParams();
  const [examDetails, setExamDetails] = useState();
  const [studentDetails, setStudentDetails] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [canAccess, setCanAccess] = useState(false);
  const controllerRef = useRef(new AbortController());

  const canDisplaySession = !isEmpty(urlParams?.slotId);
  const slotId = urlParams?.slotId;

  const unauthorised = (
    <Box bgcolor="common.white" boxSizing="border-box" height="calc(100vh - 32px)" my={2} mx="auto" p={4} maxWidth="80%">
      <Notice noticeType="error">
        <Typography variant="h3" component="h2">Sorry, you are not authorised to join this session.</Typography>
        <Typography variant="body1">Please try joining from your exam page again and if you still see this message, please contact support</Typography>
        <ContactNumbers examSupport={false} />
      </Notice>
    </Box>
  )


  const getExamDetails = async () => {
    if (isEmpty(slotId)) {
      // invalid URL with no slot ID available to use.
      return;
    }

    try {
      const examSessionDetails = await ExamSessionService.getExamSession(slotId, controllerRef.current.signal);
      if (examSessionDetails.deletionStatus === EXAM_SESSION_DELETION_STATUS.DELETED.value
        || examSessionDetails.deletionStatus === EXAM_SESSION_DELETION_STATUS.SCHEDULED.value) {
        throw new Error('Cannot join exam session that is marked for deletion or has already been deleted.')
      }
      const studentDetails = getStudentUser(examSessionDetails);
      // no access if it's both dismissed and submitted
      const isDismissedAndSubmitted = examSessionDetails?.supervisionCompleted
        && examSessionDetails?.examState === EXAM_SESSION_STATES.submitted;

      setExamDetails(examSessionDetails);
      setStudentDetails(studentDetails);
      setErrorMessage('');
      setCanAccess(!isDismissedAndSubmitted)
    } catch (error) {
      setRequestError('We were unable to get details for that eVigilation session. Please check your link and try again.');
      console.error('Unable to get exam slot details', error);
    }
  };

  useEffect(() => {
    const controller = controllerRef.current;
    return () => { controller.abort();}
  });

  const setRequestError = (message) => {
    if (!controllerRef.current.signal.aborted) {
      setErrorMessage(message);
    }
  }

  const passBrowserCheck = () => {
    // don't make any api calls until the student has logged in
    authorised && !examDetails && getExamDetails();
  };

  return (
    <BrowserCheckWrapper onClose={passBrowserCheck}>
      <AuthConsumer>
        {({ user }) => {
          const readyToRender = !!(examDetails && studentDetails && user);
          const authorisedStudentInSlot = readyToRender && user.id === studentDetails.id;
          return (
            <>
              {!canDisplaySession &&
                <Box margin={4}>
                  <Notice noticeType="error">Sorry, the URL is incorrect, please try joining from your exam page again</Notice>
                </Box>
              }
              {authorisedStudentInSlot &&
                <Header
                  title={<ExamDetails examDetails={examDetails} studentDetails={studentDetails} slotId={slotId} studentView={true} />}
                  displayMenu={false}
                  studentView={true}
                />
              }
              {errorMessage &&
                <Box margin={4}>
                  <Notice noticeType="error">{errorMessage}</Notice>
                </Box>
              }
              {readyToRender && !canAccess &&
                <>{authorisedStudentInSlot ? <SupervisionComplete /> : unauthorised}</>

              }
              {readyToRender && canAccess &&
                <>
                  {authorisedStudentInSlot
                    ? <JoinProvider>
                        <JoinMeetingConnect key={examDetails?.id} slotId={examDetails?.id} />
                        <Join canDisplaySession={canDisplaySession} examDetails={examDetails} />
                      </JoinProvider>
                    : unauthorised
                  }
                </>
              }
            </>
          );
        }}
      </AuthConsumer>
    </BrowserCheckWrapper>
  )
}

export default JoinOnboardSession;