const ALLOCATION_ERRORS = [
  {
    "id": "9cabe21d-e520-4ee5-8e56-e686bc1025e3",
    "examSlot": {
      "id": "9cabe21d-e520-4ee5-8e56-e686bc1025e3",
      "examStartDateTime": "2021-10-05T07:00:00+11:00",
      "examEndDateTime": "2021-10-05T20:48:00+11:00",
      "context": {
        "id": "7a228946-b3f6-463b-89ab-0253892b437c",
        "name": "BIO101 Biology Test",
        "description": "BIO101 Biology Test"
      },
      "student": {
        "id": "12007b0e-6da8-4313-bc41-d285b306f271",
        "fullName": "Generic Student",
        "userName": "stud01234",
        "legalName": "Generic Student",
        "externalId": "MonashIDP:Student:102303",
        "isDisabled": false,
        "shiftStaff": false
      },
      "supervisors": [
        {
          "id": "d3033095-9391-456a-aaed-62327e0ae64f",
          "fullName": "Admin User",
          "userName": "admin0001",
          "externalId": "bretts-id",
          "isDisabled": false,
          "userType": "SUPERVISOR",
          "assignedTimestamp": "2021-10-05T10:58:24.793529+11:00",
          "current": true
        }
      ],
      "onboarders": [
        {
          "id": "d3033095-9391-456a-aaed-62327e0ae64f",
          "fullName": "Admin User",
          "userName": "admin0001",
          "externalId": "bretts-id",
          "isDisabled": false,
          "userType": "ONBOARDER",
          "assignedTimestamp": "2021-10-05T10:58:24.667748+11:00",
          "current": true
        }
      ],
      "humanSupervised": true,
      "aiType": "ASSISTED",
      "examState": "PENDING",
      "onboardingType": "HUMAN",
      "privacyConfirmation": "ACCEPTED"
    },
    "allocationPool": {
      "id": "b4506249-e1a2-4a2e-a598-d524c1ff5924",
      "name": "evig08",
      "description": "High Integrity Supervision (1:8)",
      "availableSlotsLocked": 0,
      "availableSlotsUnlocked": 4,
      "availableSlotsAny": 4,
      "disabled": false
    },
    "allocationUserType": "ONBOARDER",
    "description": "Generic Student (BIO101 Biology Test)",
    "attemptCount": 10,
    "attemptStartTime": "2021-10-07T10:58:16+11:00",
    "attemptLastUpdated": "2021-10-07T11:02:51+11:00",
    "status": "FAILED"
  },
  {
    "id": "1b35dd1f-375c-4bf6-aacf-366dc6c10c33",
    "examSlot": {
      "id": "1b35dd1f-375c-4bf6-aacf-366dc6c10c33",
      "examStartDateTime": "2021-10-06T07:00:00+11:00",
      "examEndDateTime": "2021-10-06T20:48:00+11:00",
      "context": {
        "id": "7a228946-b3f6-463b-89ab-0253892b437c",
        "name": "BIO101 Biology Test",
        "description": "BIO101 Biology Test"
      },
      "student": {
        "id": "12007b0e-6da8-4313-bc41-d285b306f271",
        "fullName": "Generic Student",
        "userName": "stud01234",
        "legalName": "Generic Student",
        "externalId": "MonashIDP:Student:102303",
        "isDisabled": false,
        "shiftStaff": false
      },
      "supervisors": [
        {
          "id": "d3033095-9391-456a-aaed-62327e0ae64f",
          "fullName": "Admin User",
          "userName": "admin0001",
          "externalId": "bretts-id",
          "isDisabled": false,
          "userType": "SUPERVISOR",
          "assignedTimestamp": "2021-10-06T08:08:34.216792+11:00",
          "current": true
        }
      ],
      "onboarders": [
        {
          "id": "d3033095-9391-456a-aaed-62327e0ae64f",
          "fullName": "Admin User",
          "userName": "admin0001",
          "externalId": "bretts-id",
          "isDisabled": false,
          "userType": "ONBOARDER",
          "assignedTimestamp": "2021-10-06T09:16:04.817364+11:00",
          "current": true
        }
      ],
      "humanSupervised": true,
      "aiType": "ASSISTED",
      "examState": "CAN_START",
      "onboardingType": "HUMAN",
      "privacyConfirmation": "ACCEPTED"
    },
    "allocationPool": {
      "id": "b4506249-e1a2-4a2e-a598-d524c1ff5924",
      "name": "evig08",
      "description": "High Integrity Supervision (1:8)",
      "availableSlotsLocked": 0,
      "availableSlotsUnlocked": 4,
      "availableSlotsAny": 4,
      "disabled": false
    },
    "allocationUserType": "SUPERVISOR",
    "description": "Generic Student (BIO101 Biology Test)",
    "attemptCount": 10,
    "attemptStartTime": "2021-10-07T08:08:09+11:00",
    "attemptLastUpdated": "2021-10-07T08:12:43+11:00",
    "status": "RESOLVED"
  },
  {
    "id": "6b863a19-52c3-4b42-b2da-5328d5f57d4c",
    "examSlot": {
      "id": "6b863a19-52c3-4b42-b2da-5328d5f57d4c",
      "examStartDateTime": "2021-10-07T07:00:00+11:00",
      "examEndDateTime": "2021-10-07T20:48:00+11:00",
      "context": {
        "id": "7a228946-b3f6-463b-89ab-0253892b437c",
        "name": "BIO101 Biology Test",
        "description": "BIO101 Biology Test"
      },
      "student": {
        "id": "12007b0e-6da8-4313-bc41-d285b306f271",
        "fullName": "Generic Student",
        "userName": "stud01234",
        "legalName": "Generic Student",
        "externalId": "MonashIDP:Student:102303",
        "isDisabled": false,
        "shiftStaff": false
      },
      "humanSupervised": true,
      "aiType": "ASSISTED",
      "examState": "PENDING",
      "onboardingType": "SKIP",
      "privacyConfirmation": "ACCEPTED"
    },
    "allocationPool": {
      "id": "b4506249-e1a2-4a2e-a598-d524c1ff5924",
      "name": "evig08",
      "description": "High Integrity Supervision (1:8)",
      "availableSlotsLocked": 0,
      "availableSlotsUnlocked": 4,
      "availableSlotsAny": 4,
      "disabled": false
    },
    "allocationUserType": "ONBOARDER",
    "description": "Generic Student (BIO101 Biology Test)",
    "attemptCount": 10,
    "attemptStartTime": "2021-10-07T09:46:27+11:00",
    "attemptLastUpdated": "2021-10-07T09:51:02+11:00",
    "notes": "There were no supervisors available",
    "status": "RETRYING"
  },
];

export default ALLOCATION_ERRORS;