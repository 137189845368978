import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AssignSessionForm from './AssignSessionForm';
import FormPopup from '../popup/FormPopup';
import { EXAM_SESSION_DELETION_STATUS } from '../../constants/examSessions';

// We're trialling a new location for assign/edit, if that goes well, this file
// is only the single button so we can update the Unsuccessful automatic allocation and
// this can be completely removed
class AssignSessionButton extends React.Component {
  state = {
    popupOpen: false,
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
    this.props.refreshSlots();
  };

  render() {
    const { selectedExamSlot, actionName, setHasSlotUpdated, setRequestResult } = this.props;
    const { popupOpen } = this.state;

    if (selectedExamSlot.deletionStatus === EXAM_SESSION_DELETION_STATUS.SCHEDULED.value
      || selectedExamSlot.deletionStatus === EXAM_SESSION_DELETION_STATUS.DELETED.value) {
      return null;
    }

    const singleEditButton = (
      <Button
        aria-label={`${actionName} session for ${selectedExamSlot.context.name}`}
        onClick={this.openPopup}
        size='small'
        variant='outlined'
        color='primary'
        sx={{ textTransform: 'initial', lineHeight: '1.3', alignSelf: 'center' }}
      >
        {actionName}
      </Button>
    );

    return (
      <>
        {singleEditButton}
        <FormPopup
          title="Assign session(s)"
          open={popupOpen}
          onClose={this.closePopup}
          content={<AssignSessionForm
            selectedExamSlots={[selectedExamSlot]}
            setHasSlotUpdated={setHasSlotUpdated}
            setRequestResult={setRequestResult}
          />}
        />
      </>
    )
  }
}

AssignSessionButton.propTypes = {
  selectedExamSlot: PropTypes.object.isRequired,
  setHasSlotUpdated: PropTypes.func,
  refreshSlots: PropTypes.func,
  actionName: PropTypes.string,
  setRequestResult: PropTypes.func,
};

AssignSessionButton.defaultProps = {
  actionName: 'Assign',
};

export default AssignSessionButton;
