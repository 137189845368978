import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputAdornment, Popover, TextField, Typography } from '@mui/material';

const styles = {
  root: {
    padding: 2,
    lineHeight: 1.5,
    maxWidth: { xs: '30%', md: '20%' },
  },
  inputRoot: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: 1,
  },
  inputTextarea: {
    minHeight: '60px',
  },
  adornment: {
    fontSize: '0.8em',
    height: 'auto',
    color: 'grey.600',
  },
};

function FlagNotePopover(props) {
  const [flagNote, setFlagNote] = useState('');
  const { open, handleClose, flag, timestamp, addFlag, anchorEl } = props;

  const closePopover = () => {
    setFlagNote('');
    handleClose();
  }

  return (
    <Box position="relative">
      {flag &&
        <Popover
          open={open}
          onClose={() => closePopover()}
          sx={{ '.MuiPopover-paper': styles.root }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <section>
            <Typography variant="h3" component="h2">Add flag &apos;{flag.name}&apos;</Typography>
            <TextField
              id="flagNote"
              label="(enter optional notes)"
              multiline={true}
              value={flagNote}
              onChange={(event) => setFlagNote(event.target.value)}
              size='small'
              margin='normal'
              variant='outlined'
              fullWidth
              inputProps={{ maxLength :100, style: styles.inputTextarea }}
              InputProps={{
                endAdornment: <InputAdornment position="end" sx={styles.adornment}>{flagNote ? flagNote.length : 0}/100</InputAdornment>,
                sx: styles.inputRoot,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {addFlag(flag, flagNote, timestamp); closePopover()}}
              >Add</Button>
              <Button variant="outlined" onClick={() => closePopover()}>Cancel</Button>
            </Box>
          </section>
        </Popover>
      }
    </Box>
  );
}

FlagNotePopover.propTypes = {
  open: PropTypes.bool,
  addFlag: PropTypes.func,
  handleClose: PropTypes.func,
  flag: PropTypes.object,
  anchorEl: PropTypes.object,
};

export default FlagNotePopover;
