import {
  EXAM_SESSION_STATES as STATES,
  EXAM_SESSION_ONBOARDING_TYPE as ONBOARDING_TYPES,
} from './examSessions';

export const ACTIONS = {
  SET_ACTIVE_SHIFT: 'setActiveShift',
  SET_ACTIVE_CONNECTION: 'setActiveConnection',
  SET_EXAM_SESSIONS: 'setExamSessions',
  UPDATE_EXAM_SESSION: 'updateExamSession',
  UPDATE_DEVICES: 'updateDevices',
  SET_SERVICES: 'setServices',
  ADD_EXAM_SESSION: 'addExamSession',
  REMOVE_EXAM_SESSION: 'removeExamSession',
  ADD_NOTICE: 'addNotice',
  CLEAR_NOTICES: 'clearNotices',
  PIN_SESSIONS: 'pinSessions',
  ADD_CHAT_MESSAGE: 'addChatMessage',
  ADD_AUTH_MATERIALS: 'addAuthMaterials',
  SET_ONBOARD_IDENTITY: 'setOnboardIdentity',
}

export const NOTICE_TYPES = {
  FLAG: 'FLAG',
  CHAT: 'CHAT',
  RAISEHAND: 'RAISEHAND',
}

export const MONITOR_LIST_TYPES = {
  onboardingAssisted: {
    title: 'Onboarding',
    uniqueTitle: 'Assisted onboarding',
    examStateFilter: [STATES.pending],
    minHeight: '260px',
    onboardingTypeFilter: [ONBOARDING_TYPES.HUMAN],
    userMapper: 'onboarders',
    sort: 'notices',  // to be joinedTime later
    groupByTime: true,
    showSummaryBanner: true,
    growValue: 1,
  },
  onboardingSelf: {
    title: 'Onboarding',
    uniqueTitle: 'Self onboarding',
    examStateFilter: [STATES.pending],
    minHeight: '260px',
    onboardingTypeFilter: [ONBOARDING_TYPES.SKIP],
    userMapper: 'onboarders',
    sort: 'notices',  // to be joinedTime later
    groupByTime: true,
    showSummaryBanner: true,
    growValue: 1,
  },
  supervising: {
    title: 'Supervising',
    uniqueTitle: 'Supervising',
    examStateFilter: [STATES.canStart, STATES.started, STATES.submitted],
    minHeight: '130px',
    onboardingTypeFilter: [],
    userMapper: 'supervisors',
    sort: 'notices',
    groupByTime: false,
    showSummaryBanner: true,
    growValue: 10,
  },
}
