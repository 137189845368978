import React from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { authContext } from '../authContext';
import ExamSessionTableContainer from './container/ExamSessionTableContainer';
import Notice from './notification/Notice';
import RefreshButton from './form/RefreshButton';
import RequestStatusIndicator from './notification/RequestStatusIndicator';
import Section from './Section';
import ExamSessionService from '../services/ExamSessionService';
import { getExamSessionTypeValue } from '../utils/getExamSessionType';
import { getLastActiveOnboarder, getLastActiveSupervisor } from '../utils/getExamSlotUser';
import { EXAM_SESSION_TYPE_LABELS as typeLabels } from '../constants/examSessions';
import { FEATURE_TOGGLES } from '../constants/featureToggles';
import { MSG_404 } from '../constants/login';

const styles = {
  requestStatusIndicatorContainer: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: 2,
    transform: 'translate(-50%, 0)',
  },
  showButton: {
    marginTop: 1,
    marginBottom: 1,
  },
  timeSubtitle: {
    fontSize: '0.65em',
    color: 'secondary.main',
    paddingLeft: 1,
    verticalAlign: 'middle',
  },
};

class ExamSessionByUser extends React.Component {
  state = {
    userId: this.props.userId,
    examSessionList: undefined,
    isRequestPending: true,
    hasRequestErrored: false,
    hasDataProcessingErrored: false,
    errorMessage: "",
    showButtonClicked: false,
    hasSlotUpdated: false,
  }

  controller = new AbortController();

  hasSameOnboarderSupervisor(examSlot) {
    const supervisor = getLastActiveSupervisor(examSlot);
    const onboarder = getLastActiveOnboarder(examSlot);

    return (supervisor && onboarder) ? supervisor.id === onboarder.id : false;
  }

  setHasSlotUpdated = (hasSlotUpdated) => {
    this.setState({ hasSlotUpdated: hasSlotUpdated });
  }

  // Get upcoming sessions for supervisor. Filter out non-human supervised types (AI_ONLY & RECORD_ONLY)
  getFilteredSupervisors = async (currentDate, examEndDate) => {
    return (await ExamSessionService.getSupervisorUpcomingExamSessions(this.state.userId, currentDate, examEndDate, this.controller.signal))
      .filter(es => {
        return (
          es.humanSupervised && getExamSessionTypeValue(es) !== typeLabels.ASSISTED
        );
      });
  };


  // Get upcoming sessions for onboarder. Filter out supervised types (the onboarder and supervisor should be the same in that case)
  // except ASSISTED which will be accessed via join dashboard rather than join session
  getFilteredOnboarders = async (currentDate, examEndDate) => {
    return (await ExamSessionService.getOnboarderUpcomingExamSessions(this.state.userId, currentDate, examEndDate, this.controller.signal))
      .filter(es => {
        if (this.context.features[FEATURE_TOGGLES.MONITOR_SESSIONS]) {
          return (!es.humanSupervised);
        }
        else {
          return (!es.humanSupervised || (getExamSessionTypeValue(es) === typeLabels.ASSISTED && !this.hasSameOnboarderSupervisor(es)));
        }
      });
  }

  loadExamSessionData = async () => {
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: "",
    });
    try {
      const currentDate = new Date();
      const examEndDate = addDays(currentDate, 2); // get all the data for the next 48 hours
      const { isSupervisor } = this.props;

      const examSessions = isSupervisor
        ? await this.getFilteredSupervisors(currentDate, examEndDate)
        : await this.getFilteredOnboarders(currentDate, examEndDate);

      this.setState({
        examSessionList: examSessions,
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            examSessionList: [],
            isRequestPending: false,
            hasRequestErrored: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  handleShowButtonClick = () => {
    this.setState({
      showButtonClicked: true,
      isRequestPending: true,
    }, this.loadExamSessionData);
  };

  handleRefreshButtonClick = () => {
    this.setState({
      examSessionList: undefined,
      isRequestPending: true,
    }, this.loadExamSessionData);
  };

  refreshSlotsOnUpdate = () => {
    const { hasSlotUpdated } = this.state;
    if (hasSlotUpdated) {
      this.setState({
        hasSlotUpdated: false,
        examSessionList: undefined,
        isRequestPending: true,
      }, this.loadExamSessionData);
    }
  }

  render() {
    const {
      examSessionList,
      isRequestPending,
      hasRequestErrored,
      errorMessage,
      showButtonClicked
    } = this.state;
    const { isSupervisor } = this.props;
    const displayData = !isRequestPending && !hasRequestErrored && examSessionList.length > 0;
    const sectionTitle = isSupervisor ? "Upcoming onboarding + supervising sessions" : "Upcoming onboarding only sessions";

    return (
      <Section>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <Box>
            <Typography variant="h3" component="h2">{sectionTitle} <Box component="span" sx={styles.timeSubtitle}>(Next 48 hours)</Box></Typography>
          </Box>
          {showButtonClicked &&
            <RefreshButton
              refreshCallback={this.handleRefreshButtonClick}
              disabled={isRequestPending} />
          }
        </Box>
        {!showButtonClicked &&
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleShowButtonClick}
            sx={styles.showButton}
            aria-label={`Show my upcoming ${isSupervisor ? 'supervision' : 'onboarding'} sessions`}
          >
            Show sessions
          </Button>
        }
        {showButtonClicked &&
          <Collapse in={showButtonClicked && !isRequestPending}>
            <Box sx={styles.requestStatusIndicatorContainer}>
              <RequestStatusIndicator
                isPending={isRequestPending}
                isErrored={hasRequestErrored}
                errorMessage={errorMessage}
              />
            </Box>
            {!displayData && !isRequestPending && !hasRequestErrored &&
              <Notice noticeType="notice">There are no upcoming exam sessions</Notice>
            }
            {displayData &&
              <ExamSessionTableContainer
                examSessionList={examSessionList}
                isSupervisor={isSupervisor}
                displayJoinButton={true}
                displayActionButton={false}
                handleRefreshByUser={this.handleRefreshButtonClick}
                refreshSlots={this.refreshSlotsOnUpdate}
                setHasSlotUpdated={this.setHasSlotUpdated}
              />
            }
            {hasRequestErrored &&
              <Notice noticeType="error">{errorMessage}</Notice>
            }
          </Collapse>
        }
      </Section>
    );
  }
}

ExamSessionByUser.propTypes = {
  userId: PropTypes.string.isRequired,
  isSupervisor: PropTypes.bool.isRequired,
};

export default ExamSessionByUser;
ExamSessionByUser.contextType = authContext;
