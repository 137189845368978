import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeObject } from '../config/theme';
import { authContext } from '../authContext';

function AppTheme(props) {
  const { theme } = useContext(authContext);
  const { themeObjOverride } = props;

  const createdTheme = useMemo(() => {
    let updatedThemeObj = themeObjOverride ? themeObjOverride : themeObject;
    if(theme) {
      updatedThemeObj = {
        ...updatedThemeObj,
        palette: {
          ...updatedThemeObj.palette,
          primary: theme,
        },
      }
    }
    return createTheme(updatedThemeObj)
  }, [theme, themeObjOverride]);

  return (
    <ThemeProvider theme={createdTheme}>
      { props.children }
    </ThemeProvider>
  )
}

AppTheme.propTypes = {
  themeObjOverride: PropTypes.object,
};

export default AppTheme;
