import React from 'react';
import SvgMonashLogoMono from './MonashLogoImage';

const styles = {
  monashBrandHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.5em',
  },
  hidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
};

function MonashLogo(_props) {
  return (
    <div style={styles.monashBrandHeader}>
      <a href="http://monash.edu/">
        <SvgMonashLogoMono />
        <span style={styles.hidden}>Monash University</span>
      </a>
    </div>
  );
}

export default MonashLogo;
