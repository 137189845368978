export const ROOM_SCAN_STATUS = {
  WAITING: "WAITING",            // No room scan images uploaded, default starting status
  UPLOADING: "UPLOADING",        // Uploading in progress
  UPLOADED: "UPLOADED",          // Raw images have been uploaded
  STITCH: "STITCH",              // Stitching of raw images into a single composite has been requested
  STITCHING: "STITCHING",        // Stitching in progress
  COMPLETED: "COMPLETED",        // Composite image has been successfully created automatically
  USER_COMPLETED: "USER_COMPLETED", // A user has manually uploaded a composite image to use
  ERROR: "ERROR"                 // An error has occurred
};

export const ROOM_SCAN_STATUS_DESCRIPTION = {
  WAITING: "Pending",           // No images uploaded yet, default state
  UPLOADING: "Uploading",       // Uploading in progress, or partial upload
  UPLOADED: "Completed",        // Student has successfully uploaded 24 images
  STITCH: "Stitch requested",
  STITCHING: "Stitching in progress",
  COMPLETED: "Completed",
  USER_COMPLETED: "Completed",
  ERROR: "Error",
};