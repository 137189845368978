import React, { useEffect, useState } from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { format as dateFnsFormat } from 'date-fns/format';

import ExamFlagAndRecordingTable from '../table/ExamFlagAndRecordingTable';
import Notice from '../notification/Notice';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import Section from '../Section';
import ExamSessionService from '../../services/ExamSessionService';
import { MSG_404 } from '../../constants/login';
import { GROUP_IDS } from '../../constants/examRecordings';
import { requestStatusIndicatorContainer } from '../../config/theme';
import { groupByDateThenContext } from '../../utils/groupExamSessions';

function ExamFlagAndRecordingContainer(props) {

  const { flags, groupBy } = props;
  const [examSessionList, setExamSessionList] = useState(null);
  const [isRequestPending, setIsRequestPending] = useState(true);
  const [hasRequestErrored, setHasRequestErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const displayData = !isRequestPending && !hasRequestErrored && !isEmpty(examSessionList);
  const groupedSessionList = displayData && groupBy === GROUP_IDS.DATE_UNIT
    && groupByDateThenContext(examSessionList);

  useEffect(() => {
    const controller = new AbortController();
    const loadData = async () => {
      const { selectedDate, selectedFlagTypes, selectedSearchTerm, selectedStudent } = props;
      setIsRequestPending(true);
      setHasRequestErrored(false);
      setErrorMessage('');

      try {
        const examSessions = await ExamSessionService.getExamSlotsForReview(
          selectedSearchTerm,
          selectedDate,
          selectedStudent,
          selectedFlagTypes,
          controller.signal
        );
        setExamSessionList(examSessions);
        setIsRequestPending(false);
        setHasRequestErrored(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          if (error.message === MSG_404) {
            setExamSessionList([]);
            setIsRequestPending(false);
            setHasRequestErrored(false);
          } else {
            setExamSessionList([]);
            setIsRequestPending(false);
            setHasRequestErrored(true);
            setErrorMessage(error.message);
          }
        }
      }
    };

    if (!examSessionList && !hasRequestErrored) {
      loadData();
    }

    return () => { controller.abort() }
  }, [examSessionList, hasRequestErrored, props]);

  const displaySessionsTable = (heading, sessions) => {
    return (
      <Section key={heading}>
        <Typography variant="h3">{heading}</Typography>
        <ExamFlagAndRecordingTable
          examSessionList={sessions}
          flags={flags}
          groupBy={groupBy}
        />
      </Section>
    )
  }

  const displayUngroupedExamSessions = () => {
    const heading = 'Matching recordings (ungrouped)';
    return displaySessionsTable(heading, sortBy(examSessionList, ['examStartDateTime', 'context.name']));
  };

  const displayGroupedExamSessions = () => {
    return (
      <>
        {Object.keys(groupedSessionList).sort().reverse().map((dateKey) => {
          return Object.keys(groupedSessionList[dateKey]).sort().map((contextKey) => {
            const heading = `${contextKey} - ${dateFnsFormat(new Date(dateKey), 'dd MMM yyyy')}`;
            return displaySessionsTable(heading, groupedSessionList[dateKey][contextKey]);
          })
        })}
      </>
    )
  };

  const displayExamSessions = () => {
    if (groupBy === GROUP_IDS.NONE) {
      return displayUngroupedExamSessions();
    } else {
      // currently default to date -> unit grouping
      return displayGroupedExamSessions();
    }
  }

  return (
    <>
      <Box sx={requestStatusIndicatorContainer}>
        <RequestStatusIndicator
          isPending={isRequestPending}
          isErrored={hasRequestErrored}
          errorMessage={errorMessage}
        />
      </Box>
      {!displayData && !isRequestPending &&
        <Section><Notice noticeType="notice">There are no exam sessions that match the search criteria</Notice></Section>
      }
      {hasRequestErrored &&
        <Section><Notice noticeType="error">{errorMessage}</Notice></Section>
      }
      {displayData &&
        <>{displayExamSessions()}</>
      }
    </>
  );
}

ExamFlagAndRecordingContainer.propTypes = {
  flags: PropTypes.array.isRequired,
  groupBy: PropTypes.string,
  selectedDate: PropTypes.string,
  selectedFlagTypes: PropTypes.array,
  selectedSearchTerm: PropTypes.string,
  selectedStudent: PropTypes.string,
};

export default ExamFlagAndRecordingContainer;
