import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';
import QrCode from './QrCode';
import Notice from '../notification/Notice';
import MertService from '../../services/MertService';

const styles = {
  error: {
    '& p': {
      color: 'secondary.dark',
    },
  },
};

function ErrorComponent({ errorComponent }) {
  return (
    <>
      {errorComponent === undefined &&
        <Notice noticeType="error" sx={{ error: styles.error }}>
          <p>QR code not currently available.</p>
          <p>Please wait for your supervisor to join and ask you to retrieve your QR code.</p>
          <p>If you have been asked to retrieve your QR code, please notify your exam supervisor immediately of this error.</p>
        </Notice>
      }
      {errorComponent !== undefined && errorComponent}
    </>
  );
}

function RoomScanQrCode(props) {
  const [state, setState] = useState({
    mertUrl: '',
    isRequestSuccessful: false,
    isRequestPending: false,
    hasRequestErrored: false,
  });

  const generateUrl = (examSessionId, passcode) => {
    return process.env.REACT_APP_APPCONFIG_MERT_URL + `/${examSessionId}?passcode=${passcode}`;
  }

  const getQRCode = useCallback(async (examSessionId) => {
    const controller = new AbortController();
    try {
      if (!examSessionId) {
        throw new Error('Session ID missing');
      }
      const passcode = await MertService.getPasscode(examSessionId, controller.signal, 'text/plain');
      const url = generateUrl(examSessionId, passcode);
      setState({
        isRequestSuccessful: true,
        hasRequestErrored: false,
        mertUrl: url,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        setState({
          isRequestSuccessful: false,
          hasRequestErrored: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    getQRCode(props.slotId)
  }, [getQRCode, props.slotId]);

  const { hasRequestErrored } = state;
  return (
    <>
      {hasRequestErrored &&
        <ErrorComponent errorComponent={props.errorComponent} />
      }
      {isEmpty(state.mertUrl) && !hasRequestErrored &&
        <Box sx={{width: '228px', maxWidth: '100%', aspectRatio: '1 / 1'}}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>
      }
      <QrCode dynamicScaling={props.dynamicScaling} url={state.mertUrl} />
    </>
  );
}

RoomScanQrCode.propTypes = {
  slotId: PropTypes.string.isRequired,
  errorComponent: PropTypes.node,
  dynamicScaling: PropTypes.bool, // Defaults to false, 
};

RoomScanQrCode.defaultProps = {
  dynamicScaling: false,
}

export default RoomScanQrCode;
