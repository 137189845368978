import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Check, Circle, SkipNext } from '@mui/icons-material';
import { ONBOARD_ACTION_TYPES } from '../../constants/joinSession';

const STEP_STATUS = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  SKIPPED: 'skipped',
  TO_COMPLETE: 'to-complete',
}

const getStepStatus = (stepProgressionDetails, activeStepProgressDetails) => {
  if (!isEmpty(stepProgressionDetails.progress.skippedBy)) {
    return STEP_STATUS.SKIPPED;
  }

  if (activeStepProgressDetails?.progress?.id === stepProgressionDetails.progress.id) {
    return STEP_STATUS.ACTIVE;
  }

  if (stepProgressionDetails.progress.complete) {
    return STEP_STATUS.COMPLETE;
  }

  return STEP_STATUS.TO_COMPLETE;
}

const getStepStyleFromStatus = (stepStatus) => {
  switch (stepStatus) {
    case STEP_STATUS.SKIPPED:
    case STEP_STATUS.COMPLETE:
      return 'text.disabled'
    case STEP_STATUS.ACTIVE:
      return 'primary.main';
    case STEP_STATUS.TO_COMPLETE:
    default:
      return 'text.primary';
  }
}

const StepStatusIcon = ({ stepProgressionDetails, stepStatus }) => {
  switch (stepStatus) {
    case STEP_STATUS.SKIPPED:
      return <SkipNext fontSize="small" />
    case STEP_STATUS.COMPLETE:
      return <Check fontSize="small" />
    case STEP_STATUS.TO_COMPLETE:
    case STEP_STATUS.ACTIVE:
    default:
      const icon = ONBOARD_ACTION_TYPES[stepProgressionDetails.flowStep.onboardStep.actionType]?.icon;
      return icon !== undefined ? React.cloneElement(icon, { fontSize: 'small' }) : <Circle fontSize="small" />;
  }
}

function FlowAssignmentProgressList(props) {
  return (
    <List dense sx={{ py: 0 }}>
      {props.onboardFlowAssignmentDetails.map(stepProgressionDetails => {
        const stepStatus = getStepStatus(stepProgressionDetails, props.activeStepProgressDetails);
        const color = getStepStyleFromStatus(stepStatus);
        const stepToDisplay = stepProgressionDetails.flowStep.onboardStep;
        return (
          <ListItem key={stepProgressionDetails.progress.id} sx={{ color: color, px: 0 }}>
            <ListItemIcon sx={{ color: 'inherit' }}>
              <StepStatusIcon stepProgressionDetails={stepProgressionDetails} stepStatus={stepStatus} />
            </ListItemIcon>
            <ListItemText primary={stepToDisplay.displayName || stepToDisplay.name}></ListItemText>
          </ListItem>
        )
      })}
    </List>
  );
}

FlowAssignmentProgressList.propTypes = {
  activeStepProgressDetails: PropTypes.object,
  onboardFlowAssignmentDetails: PropTypes.arrayOf(PropTypes.object),
};

export default FlowAssignmentProgressList;
