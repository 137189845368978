import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { authContext } from '../../authContext';
import { lightestGrey } from '../../config/theme';
import { ACCEPTED_ID_DOCS_MAPPER, PRIVACY_STATEMENT_MAPPER } from '../../constants/contentOverrides';
import { PRIVACY_WEBSITE_URL } from '../../constants/session';

function IdVerificationInfoStep(props) {
  const { contentOverrides } = useContext(authContext);
  const acceptedIdDocsOverride = contentOverrides?.[ACCEPTED_ID_DOCS_MAPPER];
  // this can currently only be a string, no html
  const privacyStatementOverride = contentOverrides?.[PRIVACY_STATEMENT_MAPPER]?.text;

  const acceptedIdDocs = isEmpty(acceptedIdDocsOverride)
    ? [
      'Monash ID card',
      'Valid government-issued passport',
      'Driver\'s licence',
    ]
    : acceptedIdDocsOverride;

  const privacyStatement = isEmpty(privacyStatementOverride)
    ? <>
        All Monash eVigilation recordings and personal information are stored securely and entirely within Monash
        infrastructure. All data is securely stored within Australia only. Only authorised Monash staff have access
        to these recordings after your exam, and a strict process ensures they can use the information only for the
        purposes of investigating academic integrity matters and in accordance with Monash’s record-keeping obligations
        for examinations and assessments. For more information, visit the
        &nbsp;<a href={PRIVACY_WEBSITE_URL} target="_blank" rel="noopener noreferrer">data protection and privacy website</a>.
      </>
    : privacyStatementOverride;

  return (
    <>
      <Typography variant="h2">ID verification</Typography>
      <Typography variant="body1">Next, we need to verify your identity.</Typography>
      <Box my={2} p={1} bgcolor={lightestGrey}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="id-verification-documents"
            id="id-verification-documents-header"
          >
            <Typography variant="h3">What you'll need</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box p={4} bgcolor={lightestGrey}>
              <Stepper
                activeStep={-1}
                connector={null}
                orientation="vertical"
                sx={{ '& .MuiStepIcon-root': { color: 'primary.main' } }}
              >
                <Step>
                  <StepLabel sx={{ alignItems: "flex-start" }}>
                    One of the following ID documents
                    <ol type="A" style={{ paddingInlineStart: '16px', lineHeight: '1.5rem' }}>
                      {acceptedIdDocs.map((documentType) => {
                        return <li key={`${documentType}`}>{documentType}</li>
                      })}
                    </ol>
                  </StepLabel>
                </Step>
                <Step><StepLabel>Your computer's webcam</StepLabel></Step>
                <Step><StepLabel>A location with sufficient lighting</StepLabel></Step>
              </Stepper>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="id-verification-privacy-statement"
            id="id-verification-privacy-statement-header"
          >
            <Typography variant="h3">How we store and protect your information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {privacyStatement}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Button
        color="primary"
        onClick={() => { props.onComplete({ flowStepIndex: props.flowStepIndex }) }}
        sx={{ mt: 2 }}
        variant="contained"
      >
        Accept and continue
      </Button>
    </>
  )
}

IdVerificationInfoStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default IdVerificationInfoStep;