import { SHARED_MEDIA_STATES } from '../../../constants/mediaStates';
/**
 * Set flag so that video stream management components will be rendered, initialising shared stream and sending
 * either desktop or webcam video to the media server.
 * @param {string} mediaType Can be either 'webcam' or 'desktop'
 * @param {object} sharedMediaStatus the shared state for each stream (audio, desktop, webcam)
 * @param {function} setVideoStates the function to set the state of the video connection for the media type
 */
const startSharingVideo = (mediaType, sharedMediaStatus, setVideoStates) => {
  const doShare = () => {
    // We set the state to 'connecting' to allow the element to render, then when video negotiation has completed
    // the callback function will update the status to 'on'.
    setVideoStates(mediaType, SHARED_MEDIA_STATES.CONNECTING);
  };

  // Check if the current status of the stream is active, in which case we need to stop sharing first
  if (sharedMediaStatus?.[mediaType] === SHARED_MEDIA_STATES.CONNECTED) {
    setVideoStates(mediaType, SHARED_MEDIA_STATES.DISCONNECTED);
    doShare();
  } else {
    doShare();
  }
};

export default startSharingVideo;