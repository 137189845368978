import React from 'react'
import PropTypes from 'prop-types';
import { find, get, has, isEmpty } from 'lodash';
import { darken, lighten } from '@mui/material/styles';
import { Chip, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { parseISO } from 'date-fns';
import AssignSessionButton from '../form/AssignSessionButton';
import Notice from '../notification/Notice';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import Section from '../Section';
import AllocationErrorService from '../../services/AllocationErrorService';
import { formatDataValuesForDisplay } from '../../utils/formatDataForDisplay';
import { ALLOCATION_ERROR_DISPLAY, ALLOCATION_ERROR_STATUS } from '../../constants/allocationErrors';
import { MSG_404 } from '../../constants/login';
import { themeObject } from '../../config/theme';

const getStyle = (styleName, theme = themeObject) => {
  const styles = {
    upperCaseFirstLetter: {
      textTransform: 'lowercase',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    errorChip: {
      color: theme.palette.error.main,
      backgroundColor: lighten(theme.palette.error.main, 0.95),
    },
    warningChip: {
      color: darken(theme.palette.warning.main, 0.1),
      backgroundColor: lighten(theme.palette.warning.main, 0.95),
    },
    successChip: {
      color: darken(theme.palette.success.main, 0.1),
      backgroundColor: lighten(theme.palette.success.main, 0.95),
    },
    requestStatusIndicatorContainer: {
      position: 'fixed',
      left: '50%',
      top: '50%',
      zIndex: 2,
      transform: 'translate(-50%, 0)',
    },
    sectionContainer: {
      position: 'relative',
    },
  };
  return styles[styleName];
};

class AllocationErrorTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: props.selectedDate || undefined,
      selectedStatus: props.selectedStatus || undefined,
      selectedPool: props.selectedPool || undefined,
      allocationErrors: [],
      displayErrorData: [],
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: "",
      hasSlotUpdated: false,
    };

    this.controller = new AbortController();
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  loadData = async () => {
    const { selectedDate, selectedPool, selectedStatus } = this.props;

    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: "",
    }, () => {
      // Execute request for matching errors
      AllocationErrorService.searchForAllocationErrors(
        selectedDate,
        selectedPool,
        selectedStatus
          ? [selectedStatus]
          : [ALLOCATION_ERROR_STATUS.FAILED, ALLOCATION_ERROR_STATUS.RETRYING],
        this.controller.signal
      ).then((results) => {
        // Generate sorted display data based on this result
        const processedErrorData = results.sort(this.compareErrorsForSort)
          .map(e => this.processErrorDataForDisplay(e));

        // Save into the state
        this.setState({
          allocationErrors: results,
          displayErrorData: processedErrorData,
          hasRequestErrored: false,
          isRequestPending: false,
          errorMessage: "",
        });
      })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            if (error.message === MSG_404) {
              this.setState({
                allocationErrors: [],
                isRequestPending: false,
                hasRequestErrored: false,
              });
            } else {
              this.setState({
                allocationErrors: [],
                isRequestPending: false,
                hasRequestErrored: true,
                errorMessage: error.message,
              });
            }
          }
        });
    });
  }

  /**
   * Parse the raw response data for an allocation error to extract display
   * information for the table.
   * @param {object} errorData Raw error object from eVigilation response
   * @returns Object with properties matching table headers
   */
  processErrorDataForDisplay = (errorData) => {
    if (isEmpty(errorData)) {
      return {};
    }

    let displayData = {
      id: errorData.id,
    };

    Object.keys(ALLOCATION_ERROR_DISPLAY).forEach(columnName => {
      const fieldValues = ALLOCATION_ERROR_DISPLAY[columnName].fields.map(field => {
        if (has(errorData, field)) {
          return get(errorData, field);
        } else {
          return null;
        }
      });
      const formatRule = ALLOCATION_ERROR_DISPLAY[columnName].format;
      displayData[columnName] = formatDataValuesForDisplay(fieldValues, formatRule);
    });

    return displayData;
  };

  compareErrorsForSort = (firstError, secondError) => {
    return parseISO(firstError.attemptStartTime) - parseISO(secondError.attemptStartTime);
  }

  getColumns = () => {
    const { canAllocate } = this.props;
    const { allocationErrors, displayErrorData } = this.state;
    let columns = [];

    if (isEmpty(displayErrorData)) { return []; }

    Object.keys(ALLOCATION_ERROR_DISPLAY).forEach((heading) => {
      let label = ALLOCATION_ERROR_DISPLAY[heading].title;
      // id must always be first to look up by dataIndex
      switch (heading) {
        case 'id':
          // never display id - only for row identification
          columns.push({ name: heading, options: { display: 'excluded' } });
          break;
        case 'supervisor':
        case 'studentName':
        case 'onboarder':
          columns.push({
            name: heading,
            label: label,
            options: {
              filter: true,
              customFilterListOptions: {
                render: v => `${label}: ${v}`
              },
              setCellProps: () => ({
                style: {
                  paddingLeft: '0px',
                },
                align: 'left'
              }),
            },
          });
          break;
        case 'status':
          columns.push({
            name: heading,
            label: label,
            options: {
              filter: true,
              customFilterListOptions: {
                render: v => `${label}: ${v}`
              },
              customBodyRenderLite: (dataIndex, _rowIndex) => {
                const allocationError = find(allocationErrors, e => e.id === displayErrorData[dataIndex].id);
                if (allocationError === undefined) {
                  return undefined;
                }

                let chipStyle;
                switch (allocationError.status) {
                  case ALLOCATION_ERROR_STATUS.FAILED:
                    chipStyle = 'errorChip';
                    break;
                  case ALLOCATION_ERROR_STATUS.COMPLETED:
                  case ALLOCATION_ERROR_STATUS.RESOLVED:
                    chipStyle = 'successChip';
                    break;
                  default:
                    chipStyle = 'warningChip';
                }
                const chipToDisplay = (
                  <Chip
                    size="small"
                    label={allocationError.status.toLowerCase()}
                    sx={(theme) => {
                      return { ...getStyle(chipStyle, theme) || {}, label: getStyle('upperCaseFirstLetter')}
                    }}
                  />
                );
                return allocationError.notes ? (
                  <Tooltip title={allocationError.notes}>
                    {chipToDisplay}
                  </Tooltip>
                ) : chipToDisplay;
              },
            },
          });
          break;
        default:
          columns.push({
            name: heading,
            label: label,
            options: {
              customFilterListOptions: {
                render: v => {
                  return `${label}: ${v}`
                }
              },
            },
          });
      }
    });

    // Add action column
    if (canAllocate) {
      columns.push({
        name: "Action",
        label: "Action",
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex, _rowIndex) => {
            const allocationError = find(allocationErrors, e => e.id === displayErrorData[dataIndex].id);
            const examData = has(allocationError, 'examSlot') ? allocationError.examSlot : undefined;
            const statusForAllocate = has(allocationError, 'status')
              && [ALLOCATION_ERROR_STATUS.FAILED, ALLOCATION_ERROR_STATUS.RETRYING].includes(allocationError.status);
            return examData && statusForAllocate ? (
              <AssignSessionButton
                selectedExamSlot={examData}
                setHasSlotUpdated={this.setHasSlotUpdated}
                refreshSlots={this.refreshSlots}
                setRequestResult={(result) => this.setAllocationRequestResult(examData.id, result)}
                actionName="Assign"
              />
            ) : undefined;
          }
        },
      });
    }
    return columns;
  }

  setHasSlotUpdated = (hasSlotUpdated) => {
    this.setState({
      hasSlotUpdated: hasSlotUpdated
    });
  };

  refreshSlots = () => {
    const { hasSlotUpdated } = this.state;
    if (hasSlotUpdated) {
      this.loadData();
    }
  };

  setAllocationRequestResult = (slotId, result) => {
    // If successful, update the error to resolved and refresh the page
    if (result === 'success') {
      this.setState({
        isRequestPending: true,
        hasRequestErrored: false,
        errorMessage: '',
      }, () => {
        AllocationErrorService.updateStatus(
          slotId,
          ALLOCATION_ERROR_STATUS.RESOLVED,
          this.controller.signal
        ).then((responseResult) => {
          let updatedAllocationErrors = this.state.allocationErrors.filter(
            e => e.id !== slotId
          );
          updatedAllocationErrors.push(responseResult);

          const processedErrorData = updatedAllocationErrors
            .sort(this.compareErrorsForSort)
            .map(e => this.processErrorDataForDisplay(e));

          this.setState({
            allocationErrors: updatedAllocationErrors,
            displayErrorData: processedErrorData,
            isRequestPending: false,
            hasRequestErrored: false,
            errorMessage: '',
          });
        }).catch((error) => {
          console.error('Unable to update allocation error status: ', error);
          if (error.name !== 'AbortError') {
            this.setState({
              isRequestPending: false,
              hasRequestErrored: true,
              errorMessage: error.message,
            });
          }
        });
      });
    }
  };

  render() {
    const {
      displayErrorData,
      isRequestPending,
      hasRequestErrored,
      errorMessage
    } = this.state;

    const displayData = !isRequestPending && !hasRequestErrored && displayErrorData.length > 0;

    const columns = this.getColumns();
    const tableData = displayErrorData;
    const options = {
      responsive: 'simple',
      selectableRows: 'none',
      filterType: 'multiselect',
      pagination: false,
      print: false,
    };

    return (
      <Section sx={getStyle('sectionContainer')}>
        <div style={getStyle('requestStatusIndicatorContainer')}>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </div>
        {displayData
          ? <MUIDataTable
              columns={columns}
              data={tableData}
              options={options}
          />
          : <Notice noticeType="notice">There are no allocation errors matching the search criteria</Notice>
        }
      </Section>
    );
  }
}

AllocationErrorTable.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  selectedPool: PropTypes.string,
  selectedStatus: PropTypes.string,
  canAllocate: PropTypes.bool,
};

AllocationErrorTable.defaultProps = {
  canAllocate: false,
};

export default AllocationErrorTable;
