import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactNumbers from '../content/ContactNumbers';
import DeviceSelector from '../form/DeviceSelector';
import AudioConnecting from '../content/AudioConnecting';
import { MEDIA_STREAM_CONNECTION_STATES as MEDIA_STREAM_STATE } from '../../constants/mediaStates';

class StreamsCheck extends React.Component {
  state = {
    audioWorks: false,
    videoWorks: false,
    modalTitle: 'Check audio',
    webcamError: 'No webcam selected yet',
  }

  handleConfirm = (confirmationType) => {
    this.setState({
      [confirmationType]: true,
    })
  };

  updateDevice = (deviceType, deviceId) => {
    this.props.updateDevices({ ...this.props.devices, [deviceType]: deviceId });
  };

  updateModalTitle = (newTitle) => {
    this.setState({ modalTitle: newTitle });
  }

  //TODO refactor to use created components instead of these inline gets
  getAudioCheck = () => {
    const { devices } = this.props;
    return (
      <>
        <Typography variant="body1" gutterBottom>Say a few words to check if your audio is working. You should be able to hear what you said.</Typography>
        <Typography variant="body1" component="div" gutterBottom>
          If you didn't hear anything:
          <ol>
            <li>check your computer volume isn't muted</li>
            <li>try selecting a different audio device</li>
          </ol>
        </Typography>
        <Accordion style={{ maxWidth: "80%" }} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="audio-options-content"
            id="audio-options-header"
          >
            <Typography variant="subtitle2" component="div">Audio options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column">
              <DeviceSelector
                kind='audioinput'
                updateDeviceOption={(deviceId, _deviceProps, _e) => { this.updateDevice('audioInputDevice', deviceId) }}
                value={devices.audioInputDevice}
              />
              <DeviceSelector
                kind='audiooutput'
                updateDeviceOption={(deviceId, _deviceProps, _e) => { this.updateDevice('audioOutputDevice', deviceId) }}
                value={devices.audioOutputDevice}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box display="flex" justifyContent="flex-end" m={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              this.props.onConfirmAudio();
              this.handleConfirm('audioWorks');
              this.updateModalTitle('Check video');
            }}
          >
            Next
          </Button>
        </Box>
      </>
    )
  };

  getVideoCheck = () => {
    const { devices, checkScreenshare, troubleshootLabel } = this.props;
    const { webcamError } = this.state;

    return (
      <>
        <Typography variant="body1" gutterBottom>
          Can you see yourself on the screen?<br/>
          If you can't see anything select a different webcam.
        </Typography>
        <DeviceSelector
          kind='videoinput'
          updateDeviceOption={(deviceId, deviceProps, e) => { this.updateDevice('videoDevice', deviceId, deviceProps, e) }}
          value={devices.videoDevice}
          setError={(err) => this.setState({ webcamError: err })}
        />
        <Box display="flex" justifyContent="flex-end" m={2}>
          {!webcamError &&
            <Button
              color="primary"
              variant="contained"
              onClick={checkScreenshare ? () => {
                this.handleConfirm('videoWorks');
                this.updateModalTitle('Sharing your entire screen');
              } : () => {
                this.props.onClose();
              }}
            >
              Next
            </Button>}
          {webcamError &&
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                this.props.onClose({
                  webcam: webcamError
                });
              }}
            >
              {troubleshootLabel}
            </Button>
          }
        </Box>
      </>
    )
  };

  getScreenShareAdvice = () => {
    return (
      <>
        <Typography variant="body1" gutterBottom>
          In the next step, you must share your entire screen.<br />
          If you only select a window or tab, it may be considered a breach of exam conditions.
        </Typography>
        <Box display="flex" justifyContent="flex-end" m={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              this.props.onClose();
            }}
          >
            Select screen
          </Button>
        </Box>
      </>
    )
  };

  render() {
    const { open, audioConnectionState, checkScreenshare } = this.props;
    const { audioWorks, videoWorks, modalTitle } = this.state;
    return (
      <Dialog
        disableEscapeKeyDown
        aria-labelledby="screen-check-title"
        open={open}
        maxWidth="sm">
        <DialogTitle id="screen-check-title">{modalTitle}</DialogTitle>
        <DialogContent>
          {!audioWorks && audioConnectionState === MEDIA_STREAM_STATE.CONNECTING && <AudioConnecting />}
          {!audioWorks && audioConnectionState === MEDIA_STREAM_STATE.CONNECTED && this.getAudioCheck()}
          {audioWorks && !videoWorks && this.getVideoCheck()}
          {audioWorks && videoWorks && checkScreenshare && this.getScreenShareAdvice()}
          <Divider />
          <Box m={2}>
            <Typography variant="h4" component="h2">Need help?</Typography>
            <ContactNumbers examSupport={false} />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

StreamsCheck.propTypes = {
  audioConnectionState: PropTypes.string,
  checkScreenshare: PropTypes.bool.isRequired,
  devices: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmAudio: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  troubleshootLabel: PropTypes.string,
  updateDevices: PropTypes.func.isRequired,
};

StreamsCheck.defaultProps = {
  troubleshootLabel: 'Troubleshoot',
};

export default (StreamsCheck);
