import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TermsConditionsList from '../content/TermsConditionsList';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Notice from '../notification/Notice';
import { joinContext } from '../context/JoinContext';
import { EXAM_SESSION_PRIVACY_CONFIRMATION } from '../../constants/examSessions';
import ExamSessionService from '../../services/ExamSessionService';

function TermsConditionsStep(props) {
  const [hasAcknowledged, setHasAcknowledged] = useState(false);
  const [updateHasErrored, setUpdateHasErrored] = useState(false);

  const { state } = useContext(joinContext);
  const handleAcknowledgeCheck = (event) => {
    setHasAcknowledged(event.target.checked);
  };

  const handleConfirm = async () => {
    try {
      setUpdateHasErrored(false);
      await ExamSessionService.confirmTermsConditions(state.examSession.id, { privacyConfirmation: EXAM_SESSION_PRIVACY_CONFIRMATION.accepted });
      props.onComplete({ flowStepIndex: props.flowStepIndex });
    } catch (error) {
      setUpdateHasErrored(true);
    }
  };

  return (
    <>
      {updateHasErrored &&
        <Notice noticeType="error">Sorry, we were unable to update your acknowledgment. Please try again</Notice>
      }
      <Typography variant="h2" pb={0}>Terms and conditions</Typography>
      <TermsConditionsList sx={{ pl: 4 }} />
      <FormControlLabel
        control={
          <Checkbox
            checked={hasAcknowledged}
            onChange={handleAcknowledgeCheck}
            name="acknowledgment"
            color="primary"
          />
        }
        label="I acknowledge the terms and conditions above."
      />
      <Box>
        <Button onClick={handleConfirm} variant="contained" color="primary" disabled={!hasAcknowledged}>
          Continue
        </Button>
      </Box>
    </>
  );
}

TermsConditionsStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default TermsConditionsStep;
