import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';

// Import acorn media player styles and functions
import '../../utils/playback/acornmediaplayer/jquery.acornmediaplayer';
import '../../utils/playback/acornmediaplayer/acornmediaplayer.base.css';
import '../../utils/playback/acornmediaplayer/themes/access/acorn.access.css';
import '../../utils/playback/acornmediaplayer/themes/bigbluebutton/acorn.bigbluebutton.css';

import PlaybackService from "../../services/PlaybackService";

// Import styling from bigbluebutton
import './ReviewPlayback.css';

const styles = {
  playbackPanel: {
    maxHeight: 600,
    minHeight: 300,
    position: 'relative',
  },
  slideContainer: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'none',
    /* vertical and horizontal alignment */
    position: 'absolute',
    top: '0',
    left: '0',
  },
  slideText: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '20',
    display: 'none',
  },
  presentationContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'url(/images/monash-logo-mono.svg) no-repeat center center'
  },
  videoArea: {
    color: 'error.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

class ReviewPlayback extends React.Component {

  constructor() {
    super();
    this.state = {
      playbackService: undefined,
      videoText: undefined,
    }
  }

  componentDidMount() {
    const {
      slotId,
      meetingId,
      authToken,
      recordingAvailable,
      handlePlaybackMeta,
    } = this.props;
    if (recordingAvailable) {
      const playbackService = new PlaybackService(
        slotId, 
        meetingId, 
        authToken, 
        handlePlaybackMeta, 
        this.handleLoadError,
      );
      this.setState({
        playbackService,
      });
    } else {
      this.setState({
        videoText: 'No recording available',
      });
    }
  }

  componentWillUnmount() {
    const { playbackService } = this.state;
    if (playbackService) {
      playbackService.unload();
    }
  }

  componentDidUpdate(prevProps) {
    const { playbackService } = this.state;
    const {
      slotId,
      meetingId,
      authToken,
      handlePlaybackMeta,
      recordingAvailable,
    } = this.props;
    if (meetingId !== prevProps.meetingId && prevProps.meetingId !== undefined) {
      if (playbackService) {
        playbackService.unload();
      }
      if (recordingAvailable) {
        const newService = new PlaybackService(
          slotId, 
          meetingId, 
          authToken, 
          handlePlaybackMeta, 
          this.handleLoadError
        );
        this.setState({
          playbackService: newService,
          videoText: undefined,
        });
      } else {
        this.setState({
          playbackService: undefined,
          videoText: 'No recording available',
        });
      }
    } else if (authToken !== prevProps.authToken && prevProps.authToken !== undefined) {
      // Updated session token only
      if (playbackService) {
        playbackService.updateToken(authToken);
      }
    }
  }

  handleLoadError = () => {
    this.setState({
      videoText: 'Failed to retrieve meeting data',
    });
  }

  render() {
    const { videoText } = this.state;
    return (
      <>
        <div id="loading" style={{display: 'none'}}>
          <img id="load-img" className="loading-img" src="logo.png" alt="loading" />
          <p id="load-msg">Loading</p>
        </div>
        <div className="circle" id="cursor"></div>
      <Box display="flex" flex={1} flexDirection="row" id="playback-panel" sx={styles.playbackPanel}>
        <Box flex={1} id="side-section" display="flex" flexDirection="column" alignItems="flex-end">
          <Box id="video-area" sx={styles.videoArea} role="region" aria-label="Video"
               title="Click to play or pause the playback" data-swap>
            {!isEmpty(videoText) &&
              <Typography>{videoText}</Typography>
            }
          </Box>
          <div id="audio-area" role="region" aria-label="Audio">
          </div>
        </Box>
        <Box flex={1} id="main-section" display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <Box id="presentation-area" sx={styles.presentationContainer} role="region" aria-label="Presentation" display="flex" flexDirection="row" alignItems="center" data-swap>
            <Box id="slide" sx={styles.slideContainer} role="img" aria-labelledby="slideText"></Box>
            <Box id="slideText" sx={styles.slideText} aria-live="polite"></Box>
          </Box>
        </Box>
      </Box>
        <aside className="left-off-canvas-menu" style={{display: 'none'}}>
          <ul className="off-canvas-list">
            <li><label>Slides</label></li>
            <div id="thumbnails" role="region" aria-label="Slide thumbnails"></div>
          </ul>
        </aside>
        <div id="copyright" style={{display: 'none'}}></div>
        <div id="navbar" style={{display: 'none'}}>
          <button className="left-off-canvas-toggle menu-icon" aria-label="toggle video thumbnail preview">
            <i className="sidebar-icon fi-list"></i>
          </button>
          <h1 id="recording-title">Recording Playback</h1>
        </div>
      </>
    );
  }
}

ReviewPlayback.propTypes = {
  slotId: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
  authToken: PropTypes.string,
  handlePlaybackMeta: PropTypes.func,
  recordingAvailable: PropTypes.bool,
};

export default ReviewPlayback;
