import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Collapse, Typography } from '@mui/material';
import IdVerificationSelector from '../form/IdVerificationSelector';
import IdVerificationCaptureStepPopup from './IdVerificationCaptureStepPopup';
import { joinContext } from '../context/JoinContext';
import { ID_ANALYSIS_STATUS, ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import Notice from '../notification/Notice';
import getStepsOfType from './helper/getStepsOfType';

function IdVerificationStep(props) {
  const { state } = useContext(joinContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentTypeConfig, setCurrentTypeConfig] = useState();
  const [currentProgressStep, setCurrentProgressStep] = useState();

  const openAddPhoto = (progressStep, typeConfig) => {
    setCurrentProgressStep(progressStep);
    setCurrentTypeConfig(typeConfig);
    setPopupOpen(true);
  };

  const onPopupClose = () => {
    popupOpen && setPopupOpen(false);
  };

  const idAnalysisProgressStep = getStepsOfType(state.onboardProgressSteps, ONBOARD_ACTION_TYPES.ID_ANALYSIS.mapper).find(Boolean);
  const idAnalysisProgressStatus = idAnalysisProgressStep?.progress?.progressStatus;

  const isIdAnalysisStepNotComplete = !idAnalysisProgressStep?.progress?.complete && isEmpty(idAnalysisProgressStep?.progress?.skippedBy);
  const isAttemptFailed = (isEmpty(idAnalysisProgressStatus?.status) && (idAnalysisProgressStatus?.attemptCounter || 0) >= 1) || idAnalysisProgressStatus?.status === ID_ANALYSIS_STATUS.ATTEMPT_FAILED;
  const shouldDisplayIdVerificationUnsuccessful = isAttemptFailed && isIdAnalysisStepNotComplete;

  return (
    <>
      <Collapse in={shouldDisplayIdVerificationUnsuccessful}>
        <Notice noticeType="warning">
          <Typography component="h2" variant="h3" >Unable to verify your identity</Typography>
          <Typography variant="body1">Your images could not be verified, please try again.</Typography>
        </Notice>
      </Collapse>
      <Typography variant="h2">Add photos</Typography>
      <Typography variant="body1">
        Next we need to capture an image of your photo ID and image of you via your webcam.
        Get your photo ID ready and click Add photo.
      </Typography>
      <Box my={3}>
        <IdVerificationSelector onboardProgressSteps={state.onboardProgressSteps} openAddPhoto={openAddPhoto} />
      </Box>

      {/* while we don't really want to mount/unmount the video component too often, we do need to manage the event
          listener properly, so this will only mount when it's open */}
      {popupOpen &&
        <IdVerificationCaptureStepPopup
          onClose={onPopupClose}
          open={popupOpen}
          progressStep={currentProgressStep}
          setRequestMessage={props.setRequestMessage}
          typeConfig={currentTypeConfig}
        />
      }
    </>
  )
}

IdVerificationStep.propTypes = {
  setRequestMessage: PropTypes.func,
}

export default IdVerificationStep;