const EXAMSESSIONSSUMMARY = [
    {
        "group": "5b3e71fd-2c04-3b64-0597-ca7ffa563b24",
        "context": {
            "id": "5b3e71fd-2c04-3b64-0597-ca7ffa563b24",
            "name": "LT001 - Load Test Exam",
            "description": "generated context for load testing 1"
        },
        "examStartStandard": "2021-07-21T08:00:00+10:00",
        "examEndStandard": "2021-07-21T20:00:00+10:00",
        "humanSupervised": true,
        "aiType": "ASSISTED",
        "onboardingType": "HUMAN",
        "total": 300,
        "agentTotal": 295,
        "users": {
            "ONBOARDER": {
                "assignedCount": 300
            },
            "AGENT": {
                "joinedCount": 0
            },
            "STUDENT": {
                "joinedCount": 0
            },
            "SUPERVISOR": {
                "joinedCount": 300,
                "assignedCount": 300
            }
        }
    },
    {
        "group": "7beacbe8-fe28-c8fb-0058-d9c22fe7802d",
        "context": {
            "id": "7beacbe8-fe28-c8fb-0058-d9c22fe7802d",
            "name": "LT002 - Load Test Exam",
            "description": "generated context for load testing 2"
        },
        "examStartStandard": "2021-07-21T08:00:00+10:00",
        "examEndStandard": "2021-07-21T20:00:00+10:00",
        "humanSupervised": true,
        "aiType": "ASSISTED",
        "onboardingType": "HUMAN",
        "total": 300,
        "agentTotal": 295,
        "users": {
            "ONBOARDER": {
                "assignedCount": 300
            },
            "AGENT": {
                "joinedCount": 0
            },
            "STUDENT": {
                "joinedCount": 0
            },
            "SUPERVISOR": {
                "joinedCount": 300,
                "assignedCount": 300
            }
        }
    }
];

export const EXAM_SESSION_SUMMARY = EXAMSESSIONSSUMMARY[0];

export default EXAMSESSIONSSUMMARY;