import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import RoomScanService from '../../services/RoomScanService';
import NetworkRequestError from '../../utils/NetworkRequestError';

const styles = {
    iconButton: {
        top: 0,
        left: 0,
    },
    activeIcon: {
        color: 'primary.main',
    },
    inactiveIcon: {
        color: 'secondary.main',
    },
    uploadingIcon: {
        opacity: '50%',
    },
    erroredIcon: {
        color: 'error.main',
    },
    downloadSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 0,
        margin: 0,
    }
};

class DownloadFileButton extends React.Component {
    state = {
        isRequestPending: false,
        hasRequestErrored: false,
    }

    controller = new AbortController();

    replicateClick(anchorElement) {
        // Simulate a click event on a DOM element
        if (document.createEvent) {
            const event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            anchorElement.dispatchEvent(event);
        } else {
            anchorElement.click();
        }
    }

    handleDownloadFile = async (_e, examSlotId) => {
        const { fail: failCallback, defaultFilename } = this.props;
        let filename = defaultFilename;
        this.setState({
            isRequestPending: true,
            hasRequestErrored: false,
            errorMessage: "",
        }, () => {
            RoomScanService.downloadArchive(examSlotId, this.controller.signal)
              .then((response) => {
                  // Get the filename from the response headers
                  const contentDisposition = response.headers.get('content-disposition');
                  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  const matches = filenameRegex.exec(contentDisposition);
                  if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"]/g, '');
                  }
                  return response.blob();
              })
              .then((blobData) => {
                  this.setState({
                      isRequestPending: false,
                      hasRequestErrored: false,
                  });
                  if (window.navigator.msSaveBlob) {
                      // IE 10 and later, and Edge.
                      window.navigator.msSaveBlob(blobData, filename);
                  } else {
                      // Other browsers - create a dummy link element in the DOM and click it.
                      const anchor = document.createElement('a');
                      anchor.download = filename;
                      if (window.URL.createObjectURL) {
                          anchor.href = window.URL.createObjectURL(blobData);
                          this.replicateClick(anchor);
                      } else {
                          // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
                          blobData.text()
                            .then(() => {
                                anchor.href = `data:${blobData.type};charset=utf-8,${encodeURIComponent()}`;
                                this.replicateClick(anchor);
                            });
                      }
                  }
              })
              .catch((err) => {
                  if (err.name !== 'AbortError') {
                      this.setState({
                          isRequestPending: false,
                          hasRequestErrored: true,
                      });
                      failCallback(`Unable to download ${filename}: ${err instanceof NetworkRequestError ? err.getDetailedMessage() : err.message}`);
                  } else {
                      failCallback('Download cancelled');
                  }
              })
        });
    }

    render() {
        const { examSession, session, size, className } = this.props;
        const { hasRequestErrored, isRequestPending } = this.state;

        let buttonStyle;
        let buttonDisabled = false;
        if (isRequestPending) {
            buttonStyle = styles.uploadingIcon;
            buttonDisabled = true;
        } else if (hasRequestErrored) {
            buttonStyle = styles.erroredIcon;
        } else if (session.requiresUpdate) {
            buttonStyle = styles.activeIcon;
        } else {
            buttonStyle = styles.inactiveIcon;
        }

        const tooltipText = `Download ${session.imageCount} room scan photos`;

        return (
          <Box position="relative" className={className}>
              <Tooltip title={tooltipText}>
                  <IconButton
                    onClick={(evt) => {this.handleDownloadFile(evt, examSession.id)}}
                    disabled={buttonDisabled}
                    aria-label={tooltipText}
                    size={size ? size : 'medium'}
                  >
                      <GetApp sx={buttonStyle} />
                  </IconButton>
              </Tooltip>
                {isRequestPending &&
                <CircularProgress sx={styles.downloadSpinner} size="100%" />
                }
            </Box>
        );
    }
}

DownloadFileButton.propTypes = {
    examSession: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    defaultFilename: PropTypes.string,
    fail: PropTypes.func,
    size: PropTypes.string,
    className: PropTypes.string,
};
DownloadFileButton.defaultProps = {
  defaultFilename: 'roomscans.zip',
  size: 'medium',
};

export default DownloadFileButton;
