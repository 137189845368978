import { isEmpty, has, size } from 'lodash';

export const validateRequired = (fields, data) => {
  if (isEmpty(data)) { return; }

  Object.keys(fields).forEach(function (field) {
    if ((fields[field].type === 'select' || fields[field].type === 'dateTime') && fields[field].required
      && isEmpty(data[field])) {
      throw new Error(`Sorry, ${fields[field].display} is required`);
    }

    if (fields[field].type === 'textbox' || fields[field].type === 'userSelect') {
      if(fields[field].required && isEmpty(data[field])) {
        throw new Error(`Sorry, ${fields[field].display} is required`);
      }
      if (has(fields[field], 'maxLength') && size(data[field]) > fields[field]['maxLength']) {
        throw new Error(`Sorry, ${fields[field].display} exceeded the character limit`)
      }
    }

  });
  return true;
}
