import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material';

function StatusChip(props) {
  const { status, styles, defaultLabel } = props;

  const chipStyle = {
    color: 'grey.900',
    backgroundColor: styles[status]?.chipBackgroundColor,
  }

  const defaultDisplayLabel = defaultLabel || 'None';
  const displayText = styles[status] ? styles[status]?.label : defaultDisplayLabel;

  return (<Chip sx={chipStyle} label={displayText} size="small" />);
}

StatusChip.propTypes = {
  status: PropTypes.string,
  styles: PropTypes.object,
  defaultLabel: PropTypes.string,
}

export default StatusChip
