import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { joinContext } from '../context/JoinContext';
import GateOpenFailedText from '../content/GateOpenFailedText';
import { ACTIONS, ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import { EXAM_SESSION_STATES } from '../../constants/examSessions';

function OpenExamGateStep(props) {
  const { flowStepIndex, onComplete } = props;
  const [gateOpenedErrored, setGateOpenedErrored] = useState(false);
  const { state, dispatch } = useContext(joinContext)

  const progressFailedMessage = 'An error has occurred';

  const onReadyToStart = async () => {
    try {
      const updateSuccess = await onComplete(
        { flowStepIndex: flowStepIndex },
        ONBOARD_ACTION_TYPES.OPEN_EXAM_GATE.apiPathTypeCompleteParam,
        progressFailedMessage
      );
      setGateOpenedErrored(!updateSuccess?.success);
      if (updateSuccess?.success) {
        if (state?.gatewayService) {
          state.gatewayService.updateMeeting(
            { examState: EXAM_SESSION_STATES.canStart },
            'Student completed onboard steps and opened exam gate'
          );
        }
        dispatch({ type: ACTIONS.UPDATE_EXAM_STATE, value: EXAM_SESSION_STATES.canStart });
      }
    } catch(error) {
      // don't update local state if an abort error is thrown
      console.log('Error on complete exam gate open step', error);
    }
  }

  return (
    <>
      {!gateOpenedErrored &&
        <>
          <Typography variant="h2">Well done!</Typography>
          <Typography variant="body1">
            You&apos;ve completed the online check-in process. Click the <strong>Ready to start</strong> button and keep this tab open.
          </Typography>
          <Button
            color="primary"
            onClick={onReadyToStart}
            sx={{mt: 2}}
            variant="contained"
          >
            Ready to start
          </Button>
        </>
      }
      {gateOpenedErrored && <GateOpenFailedText />}
    </>
  )
}

OpenExamGateStep.propTypes = {
  flowStepIndex: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default OpenExamGateStep;