import React from 'react';
import VideoSettingsOutlinedIcon from '@mui/icons-material/VideoSettingsOutlined';
import HealthConcern from '../icons/HealthConcern';
import RedFlag from '../icons/RedFlag';
import RestBreak from '../icons/RestBreak';
import TechIssue from '../icons/TechIssue';
import ToiletBreak from '../icons/ToiletBreak';
import VerificationFailed from '../icons/VerificationFailed';
import RoomScanFailed from '../icons/RoomScanFailed';
import YellowFlag from '../icons/YellowFlag';

export const PROCESS_VIDEO_FLAG_EVENT_TYPE = 'process-video-request-flag';

// Note: If the report order is changed ensure the order is changed (if required) in examRecordings.js
// hideFromSearch property (Bool) can be added to a flag to exclude it from the search screen
const FLAGS = {
  "tech-issue": {
    icon: <TechIssue/>,
    singleLine: true,
    order: 3,
    reportOrder: 14,
  },
  "health-concern": {
    icon: <HealthConcern/>,
    singleLine: true,
    order: 4,
    reportOrder: 12,
  },
  "toilet-break": {
    icon: <ToiletBreak/>,
    singleLine: true,
    order: 5,
    reportOrder: 15,
  },
  "rest-break": {
    icon: <RestBreak/>,
    singleLine: true,
    order: 6,
    reportOrder: 13,
  },
  "verification-failed": {
    icon: <VerificationFailed/>,
    singleLine: true,
    order: 1,
    reportOrder: 10,
  },
  "room-scan-failed": {
    icon: <RoomScanFailed/>,
    singleLine: true,
    order: 2,
    reportOrder: 11,
  },
  "yellow-flag": {
    icon: <YellowFlag fill="#FF9E33"/>,
    singleLine: false,
    order: 7,
    reportOrder: 9,
  },
  "red-flag": {
    icon: <RedFlag fill="#EC2B2B"/>,
    singleLine: false,
    order: 8,
    reportOrder: 8,
  },
  "ai-red-flag": {
    icon: <RedFlag fill="#EC2B2B"/>,
    reportOrder: 16,
  },
  "ai-yellow-flag": {
    icon: <YellowFlag fill="#FF9E33"/>,
    reportOrder: 17,
  },
  "process-video-request-flag": {
    icon: <VideoSettingsOutlinedIcon sx={{ color: 'rgba(0,0,0,0.54)', fontSize: '1rem'}}  />,
    hideFromDashboard: true,
    reportOrder: 18,
  }
}

export default FLAGS;
