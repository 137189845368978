import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { scrollBoxStyle } from '../../config/theme';

const contentStyle = {
  padding: 2,
  maxWidth: "40%",
  lineHeight: 1.5,
};

const buttonStyle = {
  marginTop: -2,
}

class InfoPopover extends React.Component {
  state = { ancherEl: null, open: false }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true});
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false});
  }

  render() {
    const { anchorEl, open } = this.state;
    const { buttonText, content, icon, popoverText} = this.props;
    const id = open ? 'info-popover' : undefined;
    const popoverTop = anchorEl ? anchorEl.getBoundingClientRect().top : 0;

    return (
      <Box>
        {!buttonText &&
          <IconButton
            aria-describedby={id}
            onClick={this.handleClick}
            aria-label="More information"
            sx={{ ...buttonStyle, ...this.props.buttonStyle }}
            size="large"
          >
            {icon ? icon : <InfoOutlinedIcon/>}
          </IconButton>
        }
        {buttonText &&
          <Button
            color="secondary"
            onClick={this.handleClick}
            size="small"
            sx={{ ...this.props.buttonStyle, fontSize: '0.8rem', lineHeight: '1rem', textTransform: 'initial' }}
            variant="outlined"
          >
            {buttonText}
          </Button>
        }
        <Popover
          id={id}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          PaperProps={{ sx: { ...contentStyle, ...this.props.contentStyle, maxHeight: `calc(100% - ${popoverTop}px)` } }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={scrollBoxStyle}>
            {popoverText}
            <div>
            {content}
            </div>
          </Box>
        </Popover>
      </Box>
    );
  }
}

InfoPopover.propTypes = {
  buttonStyle: PropTypes.object,
  buttonText: PropTypes.string,
  content: PropTypes.object,
  contentStyle: PropTypes.object,
  icon: PropTypes.object,
  popoverText: PropTypes.string,
};

export default InfoPopover;
