import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { requestStatusIndicatorContainer } from '../../config/theme';

function LoggingIn(props) {
  return (
    <Box sx={requestStatusIndicatorContainer} display="flex" flexDirection="column" alignItems="center">
      <CircularProgress />
      <p>Logging in to eVigilation</p>
    </Box>
  )
}

export default LoggingIn;
