import React from 'react';
import { Typography } from '@mui/material';

const IdCaptureGuidelinesText = (_props) => {
  return (
    <>
      <Typography variant="h3">Image capture guidelines</Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>To capture good-quality images of your photo ID, try the following:</Typography>
      <Typography variant="body1" component="ol">
        <li>Position your photo ID in front of your webcam.</li>
        <li>Ensure the whole ID document is contained within the blue camera frame and that your fingers are not covering any part of the document.</li>
        <li>Make sure you&apos;re in a room with sufficient light and that you&apos;re facing the light source.</li>
        <li>Make sure there&apos;s no light source behind you.</li>
        <li>Make sure there&apos;s no glare on the surface of your ID document.</li>
      </Typography>
    </>
  )
}

export default IdCaptureGuidelinesText;