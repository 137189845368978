import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web'
import Auth from './Auth';
import AuthService from '../../services/AuthService';

function AuthWithBroker(props) {
  const { keycloak } = useKeycloak();
  const [loadAuth, setLoadAuth] = useState(false);

  useEffect(() => {
    if (!loadAuth && AuthService.getBrokeredAccessToken()) {
      setLoadAuth(true);
    }
  }, [loadAuth, keycloak]);

  return (
    <Auth
      accessTokenFromIdp={keycloak?.token}
      getAccessTokenFunc={AuthService.getBrokeredAccessToken}
      isAuthenticated={keycloak?.authenticated}
    >
      { loadAuth ? props.children : <></> }
    </Auth>
  );

};

AuthWithBroker.propTypes = {
  children: PropTypes.object,
};

export default AuthWithBroker;
