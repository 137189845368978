import { assign, flatten, isEmpty, } from 'lodash';
import { formatUsers } from '../../../utils/userSearchHelper';

const getUsersForRoleAssignment = (existingAssignments, role) => {
  if(isEmpty(existingAssignments || isEmpty(role) )) { return undefined; }
  return flatten(
    existingAssignments
      .filter(assignedRole => assignedRole?.role.id === role)
      .map(assignedRole => {
        let userObj = formatUsers([assignedRole.user]);
        assign(...userObj, {'assignmentId': assignedRole.id});
        return userObj;
      })
  );
};

export default getUsersForRoleAssignment;