import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material';
import { Check, ErrorOutline, FiberManualRecord, Help, HourglassEmpty } from '@mui/icons-material';
import { EXAM_SESSION_PARTICIPANT_STATUS, EXAM_SESSION_PARTICIPANT_STATUS_LEGEND } from '../../constants/examSessions';


function ExamSessionParticipantStatusLabel(props) {
  const { participantStatus, participantType } = props;

  const baseStyle = {
    paddingRight: 0.5,
    fontSize: '1rem',
    verticalAlign: 'middle',
  };

  const chipStyle = {
    toJoinStatusColour: {
      ...baseStyle,
      color: '#FBB847',
    },
    activeStatusColour: {
      ...baseStyle,
      color: '#9EE493',
    },
    inactiveStatusColour: {
      ...baseStyle,
      color: '#F98988',
    },
    completeStatusColour: {
      ...baseStyle,
      color: '#638FF3',
    },
  };

  switch (participantStatus) {
    case EXAM_SESSION_PARTICIPANT_STATUS.TO_JOIN:
      return <Tooltip title={`${participantType}: ${EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.TO_JOIN}`}>
        <HourglassEmpty sx={chipStyle.toJoinStatusColour} />
      </Tooltip>;
    case EXAM_SESSION_PARTICIPANT_STATUS.ACTIVE:
      return <Tooltip title={`${participantType}: ${EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.ACTIVE}`}>
        <FiberManualRecord sx={chipStyle.activeStatusColour} />
      </Tooltip>;
    case EXAM_SESSION_PARTICIPANT_STATUS.INACTIVE:
      return <Tooltip title={`${participantType}: ${EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.INACTIVE}`}>
        <Help sx={chipStyle.inactiveStatusColour} />
      </Tooltip>;
    case EXAM_SESSION_PARTICIPANT_STATUS.COMPLETE:
      return <Tooltip title={`${participantType}: ${EXAM_SESSION_PARTICIPANT_STATUS_LEGEND.COMPLETE}`}>
        <Check sx={chipStyle.completeStatusColour} />
      </Tooltip>;
    default:
      return <Tooltip title={`${participantType}: no status available`}>
        <ErrorOutline />
      </Tooltip>;
  }
}

ExamSessionParticipantStatusLabel.propTypes = {
  participantStatus: PropTypes.string,
  participantType: PropTypes.string,
}

export default ExamSessionParticipantStatusLabel
