import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import ContactNumbers from "./ContactNumbers";

const styles = {
  warningTitle: {
    textAlign: 'center',
    color: 'primary.main',
  },
  messageParagraph: {
    padding: 1,
  },
  currentBrowserText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    padding: '0.8rem 0',
  },
  phoneLink: {
    color: 'secondary.dark',
  },
  continueButton: {
    backgroundColor: 'error.main',
    '&:hover': {
      backgroundColor: 'error.main',
    },
    color: 'secondary.contrastText',
  }
};

class BrowserWarning extends React.Component {

  render() {
    const { open, onClose } = this.props;
    return(
      <Dialog
        aria-labelledby="browser-warning-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle id="browser-warning-title" sx={styles.warningTitle}>You're not using Google Chrome</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={styles.messageParagraph}>You must use Google Chrome to sit your eExam.</Typography>
          <Typography variant="body1" sx={styles.messageParagraph}>We've got you covered, just download the <a href="https://www.google.com/intl/en_au/chrome/">Google Chrome</a><br />browser and reconnect with your online supervisor.</Typography>
          <Typography variant="body1" sx={styles.messageParagraph}>Stuck? </Typography>
          <ContactNumbers examSupport={false} />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button onClick={onClose} variant="contained" sx={styles.continueButton} aria-label="Continue">
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
}

BrowserWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentBrowser: PropTypes.string.isRequired,
}

export default BrowserWarning;
