import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  AccountBoxOutlined as UserAccountIcon,
  Flag as FlagIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Restore as RestoreIcon,
  MenuBook as BookIcon,
  Flight,
} from '@mui/icons-material/';
import AuthorisedMaterials from '../content/AuthorisedMaterials';
import ChatBox from '../container/ChatBox';
import FlagsForm from './FlagsForm';
import FlagHistory from '../container/FlagHistory';
import Notice from '../notification/Notice';
import OnboardProgressForm from './OnboardProgressForm';
import Section from '../Section';
import UserIdentity from '../content/UserIdentity';
import { authContext } from '../../authContext';
import GatewayService from '../../services/GatewayService';
import { scrollBoxStyle } from '../../config/theme';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';

const styles = {
  buttonGroup: {
    position: 'absolute',
    zIndex: 2,
    "& button, button:hover": {
      color: 'secondary.main',
      backgroundColor: 'secondary.contrastText',
      textTransform: 'initial',
    },
    "& .Mui-selected, .Mui-selected:hover": {
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
    },
    "& label": {
      cursor: 'pointer',
      paddingLeft: 1,
    },
  },
  toggleButtons: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
};

class FlagChatToggle extends React.Component {
  state = {
    view: 'flag',
    showLabels: false,
    toggleWidth: '50px',
  };

  toggleGroup = React.createRef();

  componentDidMount() {
    this.setState({
      toggleWidth: this.toggleGroup.current.offsetWidth,
    });
  }

  handleToggle = (_event, nextView) => {
    // if the active button is clicked, ignore
    if (nextView !== null) {
      this.setState({ view: nextView })
    }
  };

  displayLabels = (showLabels) => {
    this.setState({
      showLabels: showLabels,
    }, () => this.setState({
      toggleWidth: this.toggleGroup.current.offsetWidth,
    }));
  }

  render() {
    const {
      chatReady,
      gatewayService,
      messages,
      onSend,
      unreadMessages,
      resetUnreadMessages,
      authorType,
      displayName,
      userId,
      userToDisplay,
      slotId,
      slotContextId,
      showHistoryToggle,
    } = this.props;
    const { view, showLabels, toggleWidth } = this.state;
    const { features } = this.context;

    return (
      <Box position="relative" display="flex" flexDirection="column" flexGrow="1">
        <ToggleButtonGroup
          ref={this.toggleGroup}
          orientation="vertical"
          value={view}
          exclusive
          onChange={this.handleToggle}
          onMouseEnter={() => this.displayLabels(true)}
          onMouseLeave={() => this.displayLabels(false)}
          sx={{ ...styles.buttonGroup, '.MuiToggleButtonGroup-grouped': styles.toggleButtons, ...scrollBoxStyle, left: `-${toggleWidth + 1}px`, height: 'auto', maxHeight: '100%' }}
        >
          <ToggleButton value="flag" aria-label="Show flags">
            <FlagIcon htmlColor="secondary" />
            {showLabels && <label>Flag</label>}
          </ToggleButton>
          <ToggleButton value="chat" aria-label="Show chat">
            <Badge badgeContent={unreadMessages} color="error">
              <QuestionAnswerIcon />
            </Badge>
            {showLabels && <label>Chat</label>}
          </ToggleButton>
          {showHistoryToggle !== false &&
            <ToggleButton value="history" aria-label="Show flag history">
              <RestoreIcon />
              {showLabels && <label>History</label>}
            </ToggleButton>
          }
          <ToggleButton value="identity" aria-label="Show student details">
            <UserAccountIcon />
            {showLabels && <label>Student details</label>}
          </ToggleButton>
          <ToggleButton value="materials" aria-label="Show authorise materials">
            <BookIcon />
            {showLabels && <label>Authorised materials</label>}
          </ToggleButton>
          {features?.[FEATURE_TOGGLES.AUTO_ONBOARD] &&
            <ToggleButton value="onboard" aria-label="Show onboard progress details">
              <Flight />
              {showLabels && <label>Onboard progress details</label>}
            </ToggleButton>
          }
        </ToggleButtonGroup>
        <Box display="flex" flexDirection="column" position="absolute" top="0" bottom="0" left="0" right="0">
          {view === 'flag' &&
            <>
              <Box sx={{ ...scrollBoxStyle, minHeight: '40%', height: 'auto', mb: '2px' }}>
                <FlagsForm slotId={slotId} />
              </Box>
              {showHistoryToggle === false &&
                <Box id="aside-flag-history" minHeight="20%" mt="1px">
                  <FlagHistory slotId={slotId} />
                </Box>
              }
            </>
          }
          {view === 'chat' &&
            <Box component="section" sx={scrollBoxStyle}>
              {chatReady ?
                <ChatBox
                  messages={messages}
                  authorType={authorType}
                  displayName={displayName}
                  userId={userId}
                  onSend={onSend}
                  gatewayService={gatewayService}
                  messageBoxHeight="100%"
                  resetUnreadMessages={resetUnreadMessages}
                />
                : <Box p={2}><Notice noticeType="warning">Chat is currently unavailable</Notice></Box>
              }
            </Box>
          }
          {view === 'history' &&
            <Section sx={scrollBoxStyle}>
              <FlagHistory slotId={slotId} />
            </Section>
          }
          {view === 'identity' &&
            <Section sx={scrollBoxStyle}>
              <UserIdentity examSlotId={slotId} user={userToDisplay} />
            </Section>
          }
          {view === 'materials' &&
            <Section sx={scrollBoxStyle}>
              <AuthorisedMaterials slotId={slotId} />
            </Section>
          }
          {view === 'onboard' && features?.[FEATURE_TOGGLES.AUTO_ONBOARD] &&
            <Box sx={{ backgroundColor: 'common.white', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}} >
              <OnboardProgressForm slotId={slotId} slotContextId={slotContextId} gatewayService={gatewayService}/>
            </Box>
          }
        </Box>
      </Box>
    )
  }
}

FlagChatToggle.propTypes = {
  slotId: PropTypes.string.isRequired,
  slotContextId: PropTypes.string.isRequired,
  chatReady: PropTypes.bool,
  gatewayService: PropTypes.instanceOf(GatewayService),
  displayName: PropTypes.string,
  authorType: PropTypes.string,
  userId: PropTypes.string,
  messages: PropTypes.array,
  onSend: PropTypes.func,
  unreadMessages: PropTypes.number,
  userToDisplay: PropTypes.object,
  resetUnreadMessages: PropTypes.func,
  showHistoryToggle: PropTypes.bool,
};

export default FlagChatToggle;
FlagChatToggle.contextType = authContext;