import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import FlagNotePopover from '../popup/FlagNotePopover';
import Notice from '../notification/Notice';
import Section from '../Section';
import FlagService from '../../services/FlagService';
import { default as FLAGS_DISPLAY } from '../../constants/flags';

const styles = {
  fieldsetContainer: {
    display: 'block',
    margin: '0 auto',
  },
  buttonLink: {
    textTransform: 'initial',
    textAlign: 'left',
    justifyContent: 'start',
  },
  buttonText: {
    py: '3px',
    px: 1,
  },
  buttonOutlined: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  noticeRoot: {
    margin: 0,
  },
  noticeText: {
    fontSize: '0.9rem',
  },
};

class FlagsForm extends React.Component {
  state = {
    flags: undefined,
    lastAddedFlag: undefined,
    showNotification: true,
    successMessage: '',
    hasRequestErrored: false,
    errorMessage: '',
    notePopupOpen: false,
    selectedFlag: undefined,
    selectedFlagTimestamp: undefined,
    anchorEl: undefined,
  }

  componentDidMount() {
    this.getFlags();
  }

  controller = new AbortController();
  timeout;

  getFlags = async () => {
    try {
      const flags = await FlagService.getAllFlags(this.controller.signal);
      this.setState({
        flags,
        hasRequestErrored: false,
        errorMessage: '',
      })
    } catch(error) {
      this.setState({
        showNotification: true,
        hasRequestErrored: true,
        errorMessage: 'Error: unable to get flags',
      }, () => clearTimeout(this.timeout));
    }
  };

  addFlag = async (flag, note, timestamp) => {
    const { slotId } = this.props;
    try {
      const lastAddedFlag = await FlagService.addExamSessionFlag({flagId: flag.id, examSlotId: slotId, note: note, timestamp: timestamp});
      this.setState({
        lastAddedFlag,
        successMessage: `'${flag.name}' flag added`,
        showNotification: true,
        hasRequestErrored: false,
        errorMessage: '',
      }, () => {
        clearTimeout(this.timeout);
        this.startNotificationTimeout();
      })
    } catch(error) {
      this.setState({
        successMessage: '',
        showNotification: true,
        hasRequestErrored: true,
        errorMessage: 'Error adding flag',
      }, () => clearTimeout(this.timeout));
    }
  };

  removeFlag = async (_event) => {
    const { lastAddedFlag } = this.state;
    try {
      await FlagService.removeExamSessionFlag(lastAddedFlag.id);
      this.setState({
        lastAddedFlag: undefined,
        successMessage: `'${lastAddedFlag.flag.name}' flag removed`,
        showNotification: true,
        hasRequestErrored: false,
        errorMessage: '',
      }, () => {
        clearTimeout(this.timeout);
        this.startNotificationTimeout();
        })
    } catch(error) {
      this.setState({
        successMessage: '',
        showNotification: true,
        hasRequestErrored: true,
        errorMessage: 'Error removing flag',
      }, () => clearTimeout(this.timeout));
    }
  };

  openNote = (event, flag) => {
    this.setState({
      notePopupOpen: true,
      selectedFlag: flag,
      selectedFlagTimestamp: new Date().toISOString(),
      anchorEl: event.currentTarget,
    });
  }

  displayFlagButton = (flags, flagId, singleLine) => {
    const flag = flags.find((thisFlag) => thisFlag.eventType === flagId);
    const singleLineStyle = singleLine ? {} : { borderColor: flag?.colour, marginRight: '5px' };
    return (
      <>
        {flag &&
          <Button
            color="secondary"
            variant={singleLine ? "text" : "outlined"}
            startIcon={FLAGS_DISPLAY[flagId].icon}
            aria-label={flag.name}
            sx={{
              ...styles.buttonLink,
              '&.MuiButton-text': styles.buttonText,
              '&.MuiButton-outlined': styles.buttonOutlined,
              ...singleLineStyle,
            }}
            onClick={(event) => this.openNote(event, flag)}
          >
            {flag.name}
          </Button>
        }
      </>
    )
  };

  startNotificationTimeout = () => {
    this.timeout = setTimeout(function(){
      this.onNotificationClose()
    }.bind(this),process.env.REACT_APP_MESSAGE_TIMEOUT)
  }

  onNotificationClose = () => {
    this.setState({showNotification: false});
  };

  render() {
    const {
      flags,
      lastAddedFlag,
      hasRequestErrored,
      errorMessage,
      successMessage,
      showNotification,
      notePopupOpen,
      selectedFlag,
      selectedFlagTimestamp,
      anchorEl
    } = this.state;

    const flagsToDisplay = flags && flags.filter(flag => flag.autoOnly !== true);
    return(
      <>
        <Section sx={{ borderRadius: 0, marginBottom: 0 }}>
          {hasRequestErrored && showNotification &&
            <Notice noticeType="error" enableClose={true} onClose={this.onNotificationClose} sx={{root: styles.noticeRoot, text: styles.noticeText}}>{errorMessage}</Notice>
          }
          {!isEmpty(successMessage) && showNotification &&
            <Notice noticeType="success"  enableClose={true} onClose={this.onNotificationClose} sx={{root: styles.noticeRoot, text: styles.noticeText}}>
                <div>{successMessage}</div>
                {lastAddedFlag &&
                  <Button
                    aria-label="Remove this flag"
                    variant="outlined"
                    sx={{ ...styles.buttonLink, '&.MuiButton-outlined': styles.buttonOutlined }}
                    onClick={(event) => this.removeFlag(event)}
                  >
                    Remove this flag
                  </Button>
                }
            </Notice>
          }
          {flagsToDisplay && !isEmpty(flagsToDisplay) &&
            <FormControl component="fieldset" sx={styles.fieldsetContainer} aria-label="Add flag">
              <FormLabel component="legend" style={{display:'none'}} />
              <Box display="flex" flexDirection="column">
                {Object.keys(FLAGS_DISPLAY)
                  .filter((flagId) => FLAGS_DISPLAY[flagId].singleLine && FLAGS_DISPLAY[flagId].hideFromDashboard !== true)
                  .sort((a,b) => FLAGS_DISPLAY[a].order - FLAGS_DISPLAY[b].order)
                  .map((flagId) => {
                  return(
                    <React.Fragment key={flagId}>
                      {this.displayFlagButton(flagsToDisplay, flagId, true)}
                    </React.Fragment>
                  )
                })}
                <Box display="flex" flexDirection="row" justifyContent="flex-start" ml={1} mt={0.5}>
                  {Object.keys(FLAGS_DISPLAY)
                    .filter((flagId) => !FLAGS_DISPLAY[flagId].singleLine && FLAGS_DISPLAY[flagId].hideFromDashboard !== true)
                    .sort((a,b) => FLAGS_DISPLAY[a].order - FLAGS_DISPLAY[b].order)
                    .map((flagId) => {
                    return(
                      <React.Fragment key={flagId}>
                        {this.displayFlagButton(flagsToDisplay, flagId, false)}
                      </React.Fragment>
                    )
                  })}
                </Box>
              </Box>
            </FormControl>
          }
        </Section>
        <FlagNotePopover
          open={notePopupOpen}
          addFlag={this.addFlag}
          handleClose={() => this.setState({notePopupOpen: false})}
          flag={selectedFlag}
          timestamp={selectedFlagTimestamp}
          anchorEl={anchorEl}
        />
      </>
    )
  }
}

FlagsForm.propTypes = {
  slotId: PropTypes.string.isRequired,
};

export default FlagsForm;
