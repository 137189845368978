import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Notice from '../notification/Notice';
import TermsConditionsList from '../content/TermsConditionsList';
import { EXAM_SESSION_PRIVACY_CONFIRMATION as CONFIRMATION } from '../../constants/examSessions';
import ExamSessionService from '../../services/ExamSessionService';

const styles = {
  termsTitle: {
    textAlign: 'center',
  },
};

const TermsConditionsPopup = (props) => {
  const [hasAcknowledged, setHasAcknowledged] = useState(false);
  const [updateHasErrored, setUpdateHasErrored] = useState(false);
  const { open } = props;

  const handleConfirm = async () => {
    const { onClose, slotId } = props;
    try {
      await ExamSessionService.confirmTermsConditions(slotId, { privacyConfirmation: CONFIRMATION.accepted });
      onClose();
    } catch (error) {
      setUpdateHasErrored(true);
    }
  };

  const handleAcknowledgeCheck = (event) => {
    setHasAcknowledged(event.target.checked);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      aria-labelledby="terms-conditions-title"
      open={open}
      maxWidth="md">
      <DialogTitle id="terms-conditions-title" sx={styles.termsTitle}>Terms and conditions</DialogTitle>
      <DialogContent>
        {updateHasErrored &&
          <Notice noticeType="error">Sorry, we were unable to update your acknowledgment. Please try again</Notice>
        }
        <TermsConditionsList />
        <Box display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                checked={hasAcknowledged}
                onChange={handleAcknowledgeCheck}
                name="acknowledgment"
                color="primary"
              />
            }
            label="I acknowledge the terms and conditions above."
          />
          {hasAcknowledged &&
            <Button onClick={handleConfirm} variant="contained" color="primary">
              Connect to my supervisor
            </Button>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
}

TermsConditionsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  slotId: PropTypes.string.isRequired,
}

export default TermsConditionsPopup;
