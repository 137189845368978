import { isEmpty } from 'lodash';
import { getActiveStepFromMappedAssignmentDetails } from '../helper/getActiveStepFromMappedAssignmentDetails';
import getStepsOfType from '../helper/getStepsOfType';
import { ACTIONS } from '../../../constants/joinSession';

const onOnboardProgressUpdate = (dispatch, getProgressStepsDetails) => {

  const getStepInfo = (slotId, onboardStepType) => {
    const progressSteps = getProgressStepsDetails();
    const stepsOfType = getStepsOfType(progressSteps, onboardStepType);
    if (isEmpty(stepsOfType)) {
      console.error(`Unable to update progress for slot ${slotId}. Step type ${onboardStepType}`);
      return;
    }
    // get the next non-complete step of the onboard step type provided
    return getActiveStepFromMappedAssignmentDetails(stepsOfType);
  };

  return (receivedMessage) => {
    if (isEmpty(receivedMessage)) {
      console.info('Unable to update progress as no data was received');
      return;
    }
    const { slotId, onboardStepType } = receivedMessage;
    const stepInfo = getStepInfo(slotId, onboardStepType);
    if (isEmpty(stepInfo)) {
      console.info(`No pending step of type ${onboardStepType} for slot ${slotId} to update`);
      return;
    }
    dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: { ...stepInfo?.progress, complete: true, mustRecomplete: false } })
  }
}

export default onOnboardProgressUpdate;