import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { authContext } from '../../authContext';
import BackgroundBanner from '../container/BackgroundBanner';
import ContactNumbers from './ContactNumbers';
import RecordingOnIcon from '../../icons/RecordingOn';
import SecurityShieldIcon from '../../icons/SecurityShield';
import { default as Steps } from './OrderedStepsList';
import {
  HUMAN_WAIT_STEPS_MAPPER,
  SELF_ACTIVE_STEPS_MAPPER,
  ACTIVE_STEPS_MAPPER,
 } from '../../constants/contentOverrides';
import { 
  MEETING_ENDED_STATES,
  MEETING_STATES as states,
  MEETING_STATE_KEYS,
} from '../../constants/session';

const textSpacing = { pl: 2, pr: 4, py: 1, }

const RecordingText = ({ showSupervisorText }) => {
  return (
    <Box display="flex" pt={2}>
      <RecordingOnIcon sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }} />
      <SecurityShieldIcon sx={{ height: '1.5rem', width: '1.5rem', mr: 1 }} />
      <Typography variant="body1">
        Your exam session is now being recorded
        {showSupervisorText &&
          <> and your online supervisor is monitoring you, even if you can’t see them. </>
        }
      </Typography>
    </Box>
  );
};

const ActiveSelfOnboardText = ({ contentOverrides, humanSupervised }) => {
  const steps = isEmpty(contentOverrides?.[SELF_ACTIVE_STEPS_MAPPER])
    ? {
        1: 'Hold your <strong>photo ID</strong> up to the webcam for at least 5 seconds so your photo image is captured.',
        2: "You don't need to do a room scan for this eExam.",
        3: 'Keep this tab open and go back to your eExam landing page.',
        4: 'At your scheduled start time, <strong>refresh your eExam screen</strong>.',
        5: "Click the '<strong>Start exam</strong>' button when it turns blue.",
      }
    : contentOverrides[SELF_ACTIVE_STEPS_MAPPER];
  return (
    <div id="self-onboard-active-text">
      <Typography variant="h2" color="primary">
        You're now connected. You won’t be checked in by a supervisor, just follow the steps below.
      </Typography>
      <Typography variant="h3" color="primary">
        Self check-in:
      </Typography>
      <Steps steps={steps}/>
      <RecordingText showSupervisorText={humanSupervised}/>
    </div>
  );
};

const ActiveWithOnboarderText = ({ contentOverrides }) => {
  const steps = isEmpty(contentOverrides?.[ACTIVE_STEPS_MAPPER])
    ? {
        1: 'Keep this tab open and go back to your eExam landing page.',
        2: "At your scheduled start time, <strong>refresh your eExam screen</strong> and click the '<strong>Start exam</strong>' button when it turns blue.",
      }
    : contentOverrides[ACTIVE_STEPS_MAPPER];
  return (
    <div id="active-text">
      <Typography variant="h2" color="primary">You’re now ready to start your eExam.</Typography>
      <Steps steps={steps}/>
      <Typography variant="body1" sx={{ mb: 2 }}>Good luck with your exam!</Typography>
    </div>
  );
};

const SubmittedText = () => {
  return (
      <div id="submitted-text">
        <Typography
          variant="h2"
          sx={{ ...textSpacing, }}
        >
          Your eExam responses have been successfully submitted.
        </Typography>
        <Box sx={{ ...textSpacing, }}>
          <Typography variant="body1">
            If you have any questions and would like to speak to your supervisor, click the <strong>&lsquo;raise hand&rsquo;</strong> button for help.
          </Typography>
          <Typography variant="body1">
            Otherwise, feel free to close this tab at any time.
          </Typography>
        </Box>
    </div>
  );
};

const FinishedText = () => {
  return (
    <div id="finished-text">
      <Typography variant="h3" component="p">Your exam session and recording have now finished.</Typography>
      <Typography variant="body1">You can close this tab at any time.</Typography>
      <Typography variant="body1">If you have any questions or concerns, let us know and we'll get back to you as soon as possible.</Typography>
    </div>
  );
};

const TerminatedText = () => {
  return (
    <div id="terminated-text">
      <Typography variant="h3" component="p">This browser session has been terminated.</Typography>
      <Typography variant="body1">You can close this tab at any time.</Typography>
      <ContactNumbers />
    </div>
  );
};

const WaitingText = ({ contentOverrides }) => {
  const steps = isEmpty(contentOverrides?.[HUMAN_WAIT_STEPS_MAPPER])
    ? {
        1: 'When asked, hold your <strong>photo ID</strong> up to the webcam so they can confirm your identity.',
        2: 'You’ll then need to do a room scan using your smartphone.',
        3: 'The supervisor will then check your authorised materials.'
      }
    : contentOverrides[HUMAN_WAIT_STEPS_MAPPER];

  return (
    <Box display="flex" justifyContent="space-between" id="human-onboarding-waiting-text">
      <Box mr={2}>
        <Typography variant="h2" color="primary">
          You’re now ready to check in! Your supervisor will be with you shortly.
        </Typography>
        <Steps steps={steps} />
        <RecordingText/>
      </Box>
    </Box>
  )
};

const ConnectingText = () => {
  return (
    <Typography variant="h2">You are connecting&hellip;</Typography>
  );
};

const getDisplayState = (meetingState, onboardingType, streamsChecked) => {
  // return early: if the streams check fails, they should get the troubleshooting screen
  // unless it's in a state where everything has disconnected on purpose
  if(!streamsChecked && !MEETING_ENDED_STATES.includes(meetingState)) {
    return MEETING_STATE_KEYS.CONNECTING;
  }
  let displayState = meetingState;
  switch(meetingState) {
    case MEETING_STATE_KEYS.WAITING:
      if(onboardingType === 'SKIP') { displayState = 'connecting'; }
      break;
    case MEETING_STATE_KEYS.ACTIVE:
      if(onboardingType === 'SKIP') { displayState = 'activeSelfOnboard'; }
      break;
    default:
      break;
  }
  return displayState;
};

const JoinBanner = (props) => {
  const { humanSupervised, meetingState, onboardingType, streamsChecked } = props;
  const { contentOverrides } = useContext(authContext);

  const displayState = getDisplayState(meetingState, onboardingType, streamsChecked);

  const textOptions = {
    connectingText: <ConnectingText />,
    waitingText: <WaitingText contentOverrides={contentOverrides} />,
    activeSelfOnboardText: <ActiveSelfOnboardText contentOverrides={contentOverrides} humanSupervised={humanSupervised} />,
    activeWithOnboarderText: <ActiveWithOnboarderText contentOverrides={contentOverrides} />,
    submittedText: <SubmittedText />,
    finishedText: <FinishedText />,
    terminatedText: <TerminatedText />,
  };

  return (
    <Box display="flex" flexDirection={states[displayState].displayOrder} justifyContent="space-between" overflow="auto" width="100%" height="100%">
      <Box>
        {/* alt text excluded for screen readers as the banner is just for display */}
        {states[displayState].imgUrl && <img style={{ maxWidth: '100%' }} alt=' ' src={states[displayState].imgUrl} />}
        {(states[displayState].backgroundColor || states[displayState].backgroundGradientPalette) &&
          <BackgroundBanner
            backgroundGradientPalette={states[displayState].backgroundGradientPalette}
            backgroundColor={states[displayState].backgroundColor}
          >
            {states[displayState].bannerIcon}
            {!isEmpty(states[displayState].bannerText) &&
              <Typography
                color="common.white"
                component="p"
                sx={{ textAlign: 'center', p: 2, mb: 0, mr: '20%' }}
                variant="h2"
                key={`banner${displayState}`}
              >{states[displayState].bannerText}</Typography>
            }
          </BackgroundBanner>
        }
      </Box>
      <Box display="flex" flex="1" m={6} alignItems="center" maxWidth="100%">
        <Box mb={3} width="100%">
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'flex-end' }} justifyContent="space-around">
            <Box>
              {textOptions[states[displayState].textComponent]}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

JoinBanner.propTypes = {
  examType: PropTypes.string,
  humanSupervised: PropTypes.bool,
  meetingState: PropTypes.string.isRequired,
  onboardingType: PropTypes.string,
  streamsChecked: PropTypes.bool,
};

export default JoinBanner;
