const supervisorHandlerSettings = {

  /**
   * Time in ms between keepalive pings to the supervisor handler gateway.
   * Default: 5 minutes
   */
  pingIntervalMs: 1000 * 60 * 5,

  /**
   * Time in ms between trying to reconnect after a failed attempt.
   * Default: 10 seconds
   */
  retryConnectionAfterMs: 1000 * 10,

  /**
   * The key name of the option in the Core API that holds the url to use
   */
  websocketOptionName: 'supervisorHandlerWebsocket',

  /**
   * Time in ms before reconnecting to the supervisor handler with a fresh
   * socket. Set to zero to disable.
   */
  maxConnectionDurationMs: 1000 * 60 * 110,
};

export default supervisorHandlerSettings;
