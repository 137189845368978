import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import getMeetingVideoTime from '../../utils/getMeetingVideoTime';

function VideoTime(props) {
  const { meetings, timeStamp } = props;
  const result = useMemo(() => getMeetingVideoTime(timeStamp, meetings), [meetings, timeStamp]);

  if (result !== null) {
    const timeString = new Date(result.secondsSinceStart * 1000).toISOString().slice(11, 19);
    if (meetings.length > 1) {
      return (
        <Box display="flex" flexDirection="column">
          <div>{timeString}</div>
          <div>(video {result.meeting + 1})</div>
        </Box>
      );
    } else {
      return <div>{timeString}</div>;
    }
  } else {
    return <></>;
  }
}

VideoTime.propTypes = {
  meetings: PropTypes.array,
  timeStamp: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

export default VideoTime;
