import {has} from "lodash";
/**
 * Logical rule to decide if the chat option on the student session page should be hidden without an active supervisor.
 * Currently this rule is whether the exam is flagged as not having a human supervisor.
 * @param {object} examDetails The exam slot details object retrieved from the API server
 * @returns {boolean} True if the chat should be hidden without an active supervisor present
 */
const shouldHideChat = (examDetails) => {
  return has(examDetails, 'humanSupervised') && examDetails.humanSupervised === false;
};

export default shouldHideChat;
