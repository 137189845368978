export const MESSAGE_TYPES = {
  UNASSIGNED: 'unassigned',
  ASSIGNED: 'assigned',
  DISMISSED: 'dismissed',
  EXAM_LOCKED: 'exam-locked',
  EXAM_UNLOCKED: 'exam-unlocked',
  ROLE_ASSIGNED: 'role-assigned',
  SUBMITTED: 'submitted',
  SHIFT_ACTIVE: 'shift-active',
  SHIFT_INACTIVE: 'shift-inactive',
  SHIFT_FINISHED: 'shift-finished',
  PING: 'ping',
};
