import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { difference } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import { ACTIONS } from '../../constants/monitorSessions';
import UnPinned from '../../icons/UnPinned';
import Pinned from '../../icons/Pinned';
import { buttonLinkStyle, themeObject } from '../../config/theme';

const styles = {
  pinned: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: themeObject.palette.primary.main,
    '& svg': {
      height: '14px',
      width: '14px',
      padding: '3px'
    }
  },
  unPinned: {
    height: '20px',
    '& svg': {
      height: '20px',
      width: '20px',
    }
  }
};

function PinSessionButton(props) {

  const {  slotId } = props;
  const { state, dispatch } = useContext(monitoringContext);

  let isPinned = state.pinnedSessions.indexOf(slotId) !== -1;

  const pinStudent = () => {
    let currentPinnedSlots = [...state.pinnedSessions];

    if(isPinned) {
      currentPinnedSlots = difference(currentPinnedSlots, [slotId]);
    } else {
      currentPinnedSlots.push(slotId);
    }
    dispatch({type: ACTIONS.PIN_SESSIONS, value: currentPinnedSlots});
    isPinned = !isPinned;
  };

  const text = isPinned ? 'Student is pinned' : 'Pin student for later';
  return (
    <Button
      variant="text"
      color= "primary"
      endIcon={ isPinned
        ? <Box sx={styles.pinned}><Pinned fill='#fff'/></Box>
        : <Box sx={styles.unPinned}><UnPinned/></Box>
      }
      aria-label={text}
      onClick={pinStudent}
      sx={[
        buttonLinkStyle,
        {
          lineHeight: 'initial',
          margin: 'auto',
        }
      ]}
      className='pin-button'
    >
    {text}
    </Button>
);
}

PinSessionButton.propTypes = {
  slotId: PropTypes.string.isRequired
};

export default PinSessionButton;
