import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const StopError = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <defs>
        <clipPath id="_clipPath_DGiR1Q5skTH5oyyKsOwqi51U6GBiro1q"><rect width="24" height="24"/></clipPath>
      </defs>
      <g clipPath="url(#_clipPath_DGiR1Q5skTH5oyyKsOwqi51U6GBiro1q)">
        <path d=" M 21.333 16.066 L 21.333 8.412 L 15.964 3 L 8.37 3 L 3 8.412 L 3 16.066 L 8.37 21.478 L 15.964 21.478 L 21.333 16.066 Z  M 9.06 4.68 L 15.273 4.68 L 19.667 9.108 L 19.667 15.37 L 15.273 19.798 L 9.06 19.798 L 4.667 15.37 L 4.667 9.108 L 9.06 4.68 Z  M 12.167 17.277 C 12.627 17.277 13.001 16.901 13.001 16.437 C 13.001 15.973 12.627 15.597 12.167 15.597 C 11.707 15.597 11.333 15.973 11.333 16.437 C 11.333 16.901 11.707 17.277 12.167 17.277 Z  M 13.003 7.198 L 11.336 7.198 L 11.336 13.917 L 13.003 13.917 L 13.003 7.198 Z " fillRule="evenodd" fill={props.fill ? props.fill : '#D03232'} />
      </g>
    </SvgIcon>
  );
}

export default StopError
