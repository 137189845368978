import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { parseISO } from "date-fns";
import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import AllocationErrorTable from '../components/table/AllocationErrorTable';
import Routing from '../utils/Routing';
import ScrollButtons from '../components/form/ScrollButtons';
import SearchFormByOptions from '../components/search/SearchFormByOptions';
import { ALLOCATION_ERROR_SEARCH_OPTIONS } from '../constants/allocationErrors';
import { SEARCH_ALLOCATION_ERROR_LABELS } from '../constants/search';

function ManageAllocationErrors(_props) {
  const location = useLocation();
  const uriParams = useParams();

  let selectedDate;
  if ( uriParams?.date) {
    try {
      selectedDate = parseISO(uriParams.date);
    } catch (err) {
      console.error(`Invalid date format in url ${JSON.stringify(uriParams.date)}`, err);
    }
  }

  const canNavigate = (params) => {
    if (!params) { return false; }
    const { date } = params;
    // if date, then can Navigate
    return Boolean(date);
  }

  const getRoute = (params) => {
    const { date } = params;
    return `${Routing.MANAGE_ALLOCATION_ERRORS}/date/${date}`;
  }

  const errorsPage = (manageCapability) => (
    <ScrollButtons>
      <Typography variant="h2">Unsuccessful automatic allocation</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" py={2}>
        <SearchFormByOptions
          key={location.key}
          fieldsToShow={ALLOCATION_ERROR_SEARCH_OPTIONS}
          labels={SEARCH_ALLOCATION_ERROR_LABELS}
          getRoute={getRoute}
          canNavigate={canNavigate}
          uriParams={uriParams}
        />
      </Box>
      {selectedDate && !isNaN(selectedDate) &&
        <AllocationErrorTable
          key={location.key}
          selectedDate={selectedDate}
          canAllocate={manageCapability}
        />
      }
    </ScrollButtons>
  )
  return (
    <AuthConsumer>
      {({ capabilityContextAccess }) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform="MANAGE_ASSIGNMENTS"
          yes={() => errorsPage(true)}
          no={() => (<Can
            capabilityContextAccess={capabilityContextAccess}
            contextRequired={false}
            perform="VIEW_EXAM"
            yes={() => errorsPage(false)}
          />)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageAllocationErrors;
