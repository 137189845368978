import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function RecordingOn(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      version="1.0"
      {...props}
    >
      <title>Recording Active</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H259V38H0z" transform="translate(-46 -8)"/>
        <path fill="none" d="M0 0H251V31H0z" transform="translate(-46 -8)"/>
        <g transform="translate(-46 -8) translate(46 8)">
          <circle cx="8" cy="8" r="8" fill="#D3D2D5"/>
          <circle cx="8" cy="8" r="3" fill="#EC4B4B"/>
        </g>
      </g>
    </SvgIcon>
  );
}

RecordingOn.defaultProps = {
  sx: { height: '1rem', width: '1rem' }
}

export default RecordingOn;
