import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import RaiseHandButton from '../form/RaiseHandButton';
import GatewayService from '../../services/GatewayService';

function RaiseHandInfo(props) {
  const { gatewayService, onRaiseHand } = props;
  return (
    <>
      {gatewayService &&
        <Box mb={1} bgcolor="white" id="raise-hand-aside-box">
          <Box display="flex">
            <PanToolOutlinedIcon sx={{ fontSize: '1.6rem', pt: 2, pl: 2 }}/>
            <Box p={2}>
              <Typography variant="h4" component="h3" sx={{ mt: 0 }}>Need to speak to your online supervisor?</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Click the ‘Raise hand’ button to let your online supervisor know you need assistance.
              </Typography>
              <RaiseHandButton gatewayService={gatewayService} onRaiseHand={onRaiseHand} />
            </Box>
          </Box>
          <Typography variant="caption" component="p" sx={{ p: 1, lineHeight: '1rem', color: 'grey.600', borderTop: '1px solid', borderColor: 'grey.200' }}>
            They may be assisting another student but they’ll appear on your screen as soon as they’re available.
            Continue with your exam if you can.
          </Typography>
        </Box>
      }
    </>
  )
}

RaiseHandInfo.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  onRaiseHand: PropTypes.func,
};

export default RaiseHandInfo;
