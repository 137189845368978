/**
 * Add a new media/conference error to the tracking list
 * @param {string} category The type of conference error which is active (audio, webcam, screenshare)
 * @param {errorKey: string, errorCode: string, details: any} errorObject
 * @param {string} errorObject.errorKey An obscured numbered error key (for hiding details from user)
 * @param {string} errorObject.errorCode A unique error code to display for tracking the problem
 * @param {*} errorObject.details Any error details to be captured (not currently displayed)
 */
const addConferenceError = (category, errorObject, existingErrors) => {
  if (!errorObject) {
    return existingErrors;
  }
  const newError = {
    category,
    errorCode: errorObject.errorCode,
    errorKey: errorObject.errorKey,
    details: errorObject.details,
  };
  return [...(existingErrors ? existingErrors : []), newError];
};

export default addConferenceError;