import { parseISO, format as dateFnsFormat } from 'date-fns';
import getSessionTime from './getSessionTime';
import { EXAM_SESSION_ONBOARDING_TYPE as ONBOARDING_TYPES } from '../constants/examSessions';

export function formatDataValuesForDisplay(dataValues, formatRule) {
  try {
    switch (formatRule) {
      case 'firstWord':
        // Take the first word of a string argument
        // Expecting one value
        const firstSpacePosition = dataValues[0].indexOf(' ');
        return dataValues[0].substr(0, firstSpacePosition >= 0 ? firstSpacePosition : dataValues[0].length );

      case 'studentId':
        // Trim 'MonashIDP:Student:' prefix if found
        // Expecting one value
        return dataValues[0]?.replace(/MonashIDP:Student:/i, '');

      case 'timeOnly':
        // Convert an ISO-format datetime string into a time only
        // Expecting one value
        return dateFnsFormat(parseISO(dataValues[0]), 'HH:mm');

      case 'examDuration':
        // Fetch the exam start and end datetimes and convert to a time duration,
        // such as '09:00 - 13:00'.
        // Expecting array of [exam start date string, exam end date string]
        const examStartDateTime = dataValues[0];
        const examEndDateTime = dataValues[1];
        return getSessionTime(parseISO(examStartDateTime), parseISO(examEndDateTime));

      case 'onboarderName':
      case 'onboarderStatus':
        // Display the onboarder details if expecting a human onboarder, otherwise display 'N/A'
        const onboarderProperty = dataValues[0];
        const skipOnboarding = dataValues[1] === ONBOARDING_TYPES.SKIP;
        if (skipOnboarding) {
          return 'N/A';
        } else {
          return onboarderProperty;
        }
      default:
        // Just display the value as-is
        return dataValues.join(',');
    }
  } catch (error) {
    console.error('Error formatting data ', dataValues, ' for display with rule ', formatRule, error);
    return null;
  }
}