import { flatten, has, isEmpty, uniq } from 'lodash';
import getUsersForRoleAssignment from './getUsersForRoleAssignment';

export const formatAssignedRolesToSave = (users, context) => {
  if(isEmpty(users) || isEmpty(context)) {
    return undefined;
  }
  return flatten(
    Object.keys(users).map((roleId) => {
      if(isEmpty(users[roleId])) { return undefined; }
      return users[roleId].map((user) => {
        let obj = {
          'roleId': roleId,
          'contextId': context.id,
          'userId': user.value,
        };
        if (has(user, 'assignmentId')) {
          // user assignment id will exist only for users to be deleted
          obj['id'] = user.assignmentId;
        }
        return obj;
      });
    })
  );
};

export const formatExistingAssignments = (existingAssignments) => {
  if(isEmpty(existingAssignments)) { return undefined; }
  const rolesWithAssignments = (uniq(existingAssignments.map((assignment) => { return assignment.role.id })));
  let existingAssignmentsObj = {}
  rolesWithAssignments.forEach((role) => {
    existingAssignmentsObj[role] = getUsersForRoleAssignment(existingAssignments, role);
  });
  return existingAssignmentsObj;
};