export const ROLE_FIELDS = {
    name: {
        display: 'Role name',
        required: true,
        type: 'textbox',
    },
    description: {
        display: 'Description',
        required: false,
        type: 'textbox',
    },
}
