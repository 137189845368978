const audio = {
  // Options related to microphone volume processing
  microphoneVolume: {
    // Smoothing factor for what percentage of the instantaneous measurement of
    // microphone volume should be replaced with the previous volume level. For
    // example, a smoothing factor of 25% means that if the previous volume was 0.1
    // and the new volume recorded this instant is 0.5, then the volume would be
    // updated to (25% x 0.1) + (75% x 0.5) = 0.4. A smoothing of 0% would mean it
    // jumps immediately to 0.5. This must be entered as a percentage between 0 and 1.
    smoothingFactor: process.env.REACT_APP_AUDIO_SMOOTHING_FACTOR || 0.2,

    // The number of milliseconds between each update of a volume change sent
    // by the microphone input AudioWorkletProcessor.
    updateIntervalInMs: 250,
  },

  // Constraints to be supplied when selecting an audio input device from the browser
  // Refer to https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
  microphoneConstraints: {
    autoGainControl: {
      ideal: true,
    },
    echoCancellation: {
      ideal: true,
    },
    noiseSuppression: {
      ideal: true,
    },
  },

  /**
   * Time in milliseconds to wait after connecting to the initial voip conference
   * before flagging the audio call as available, to allow time for the BBB server
   * to set up and respond properly to events.
   */
  audioJoinConnectionDelay: 4000,

  /**
   * Time in milliseconds before the overall call connection attempt to FreeSwitch 
   * times out. (Catching where the server stops responding mid-negotiation)
   */
  callConnectTimeout: 20000,

  /**
   * Time before the initial user agent connection attempt times out in milliseconds
   */
  audioAgentConnectionTimeout: 5000,

  /**
   * Time in milliseconds before a transfer attempt times out.
   */
  callTransferTimeout: 20000,

  /**
   * Audio call SIP user agent reconnection attempts
   */
  callReconnectionAttempts: 3,

  /**
   * Audio call SIP user agent reconnection delay in milliseconds before trying again
   */
  callReconnectionDelay: 5000,

  /**
   * Time in milliseconds before an audio hangup attempt times out.
   */
  callHangupTimeout: 2000,

  /**
   * Number of times to try hanging up an audio SIP call before giving up.
   */
  callHangupMaxRetries: 3,

  /**
   * Session storage identifier for the sequential audio session number.
   */
  audioSessionNumKey: 'AudioSessionNumber',

  /**
   * Audio call SDP semantics option.
   */
  sdpSemantics: 'unified-plan',

};
export default audio;