import { ACTIONS } from '../../../constants/monitorSessions';
import { SCREEN_SHARE_TYPES } from '../../../constants/mediaStates';
import { USER_TYPES } from '../../../constants/users';

const onScreenTypeUpdate = (dispatch, slotId, userType) => {
  if (userType === USER_TYPES.STUDENT) {
    return () => { /* currently do nothing if a student receives a screen type update */ }
  }
  return (message) => {
    let updatedScreenType = message.screenType;
    if (!SCREEN_SHARE_TYPES[updatedScreenType]) {
      console.warn(`Invalid screen type received for slot ${slotId}. Setting back to NONE`);
      updatedScreenType = SCREEN_SHARE_TYPES.NONE.mapper;
    }
    dispatch({
      type: ACTIONS.UPDATE_EXAM_SESSION,
      value: {
        id: slotId,
        screenShareType: updatedScreenType,
      }
    });
  }
};

export default onScreenTypeUpdate;