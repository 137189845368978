const gateway = {
  /**
   * Time in milliseconds between each keep-alive ping with the BBB gateway.
   */
  keepAliveInterval: 5000,

  /**
   * Path to the gateway websocket endpoint.
   */
  gatewayPath: '/connect',

  reconnectingSocketOptions: {
    /**
     * Time in milliseconds before a connection attempt times out.
     */
    websocketConnectionTimeout: 20000,

    /**
     * Maximum number of times to retry connection
     */
    maxRetries: 10,

    /**
     * Minimum amount of time to wait before trying a reconnection
     */
    minReconnectionDelay: 5000 + Math.random() * 5000,
  },

  /**
   * Flag for whether the conference is configured so that all users join the
   * session muted by default. This is set on the server-side, and this flag
   * only determines the way the client renders the mute button initially.
   */
  autoMuteOnJoin: true,

  /**
   * Flag for whether the conference is configured so that all meetings are
   * recorded automatically. This is set on the server-side, and this flag
   * only determines how the 'recording active' alert is rendered.
   */
  autoStartRecording: true,

  /**
   * Message events which can be sent or received when communicating with the
   * gateway server.
   */
  events: {
    IDENTITY: 'identity',
    AUTH_ACCEPT: 'accept',
    AUTH_REJECT: 'reject',
    JOIN: 'add-user',
    LEAVE: 'quit',
    VIDEO_START: 'video-start',
    VIDEO_STOP: 'video-stop',
    AUDIO_START: 'audio-start',
    AUDIO_STOP: 'audio-stop',
    AUDIO_MUTE: 'audio-mute',
    AUDIO_UNMUTE: 'audio-unmute',
    AUDIO_ACTIVE: 'audio-active',
    AUDIO_INACTIVE: 'audio-inactive',
    SCREENSHARE_START: 'screenshare-start',
    SCREENSHARE_STOP: 'screenshare-stop',
    SCREEN_SHARE_TYPE: 'screen-share-type',
    TERMINATED: 'terminated',
    MEETING_END: 'end',
    MEETING_UPDATE: 'meeting-update',
    ERROR: 'error',
    UNSUPPORTED: 'unsupported',
    PING: 'ping',
    PONG: 'pong',
    QRCODE: 'qr-code',
    INFO: 'info',
    CHATMSG: 'chat-msg',
    RAISE_HAND: 'raise-hand',
    LOWER_HAND: 'lower-hand',
    RESOLVE_NOTICES: 'resolve-notices',
    RECEIVE_NOTICE: 'notice',
    SKIP_ONBOARD_STEP: 'skip-onboard-step',
    ONBOARD_PROGRESS_UPDATE: 'onboard-progress-updated',
    ID_ANALYSIS_UPDATE: 'id-analysis-update'
  },

  /**
   * Websocket connection state options.
   */
  connectionState: {
    CONNECTING: 'connecting',
    UNAUTHENTICATED: 'unauthenticated',
    ACTIVE: 'active',
    DISCONNECTED: 'disconnected',
    ENDED: 'ended',
  },

  /**
   * Maximum allowed chat message length
   */
  chatMaximumLength: 2000,
};
export default gateway;
