import React from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@mui/material/styles';
import { Box, Tooltip } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { filter, isEmpty, minBy, some } from 'lodash';
import RaiseHand from '../../icons/RaiseHand';
import FLAGS from '../../constants/flags';
import { NOTICE_TYPES } from '../../constants/monitorSessions';
import { themeObject } from '../../config/theme';

const styles = {
  noticeItem: {
    border: '1px solid',
    borderColor: 'grey.200',
    borderRadius: '50%',
    height: '18px',
    width: '18px',
    marginRight: 0.5,
    '& svg': {
      maxHeight: '14px',
      maxWidth: '14px',
      padding: 0.25,
    },
  },
};

function MonitorNoticeItems(props) {
  const { examSubmitted, isActive, notices } = props

  const noticeFlags = filter(notices, ['type', NOTICE_TYPES.FLAG]);
  // If there are multiple with the same (max) displayOrder, it will return the first it finds
  const flagToShow = !isEmpty(noticeFlags) && minBy(noticeFlags, 'displayOrder');

  return (
    <Box display="flex" justifyContent="flex-end" pr={!examSubmitted ? 3.5 : 0}>
      <Box sx={styles.noticeItem}>
        {some(notices, ['type', NOTICE_TYPES.RAISEHAND]) &&
          <Tooltip title="Student raised hand">
            <div><RaiseHand fill="#F7AD1A" /></div>
          </Tooltip>
        }
      </Box>
      <Box sx={styles.noticeItem}>
        {some(notices, ['type', NOTICE_TYPES.CHAT]) &&
          <Tooltip title="Student sent chat message">
            <div>
              <QuestionAnswerIcon
                sx={{ color: isActive ? lighten(themeObject.palette.primary.light, 0.25) : themeObject.palette.primary.main }}
              />
            </div>
          </Tooltip>
        }
      </Box>
      <Box sx={styles.noticeItem}>
        {(!isEmpty(flagToShow) && !isEmpty(flagToShow.flag)) &&
          <Tooltip title={flagToShow.flag.name}>
            <div style={{padding: '1px 0 0 2px'}}>
              {FLAGS[flagToShow.flag.eventType].icon}
            </div>
          </Tooltip>
      }
      </Box>
    </Box>
  );
}

MonitorNoticeItems.propTypes = {
  examSubmitted: PropTypes.bool,
  isActive: PropTypes.bool,
  notices: PropTypes.array,
};

export default MonitorNoticeItems;
