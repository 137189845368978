import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Pinned = (props) => {
  return (
    <SvgIcon viewBox="0 0 11 19" {...props}>
      <path d="M9.42857 0L1.57143 0C1.13929 0 0.785714 0.417857 0.785714 0.928571C0.785714 1.43929 1.13929 1.85714 1.57143 1.85714H2.35714L2.35714 6.5C2.35714 8.04143 1.30429 9.28571 0 9.28571L0 11.1429H4.69071V17.6429L5.47643 18.5714L6.26214 17.6429V11.1429H11V9.28571C9.69571 9.28571 8.64286 8.04143 8.64286 6.5V1.85714H9.42857C9.86071 1.85714 10.2143 1.43929 10.2143 0.928571C10.2143 0.417857 9.86071 0 9.42857 0Z"
            fill={props.fill ? props.fill : "#006DAE"} />
    </SvgIcon>
  );
}

export default Pinned;
