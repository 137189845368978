import React from 'react';
import Chip from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

function FilterButton (props) {

  const { name, filterState, label, setFilterState, sx } = props;

  const iconStyle = { fontSize:'12px !important', ml: '4px !important' }

  return (
    <Chip
      color={filterState ? 'primary' : 'secondary'}
      icon={filterState ? <CircleIcon sx={iconStyle}/> : <CircleOutlinedIcon sx={iconStyle}/>}
      label={label}
      onClick={() => setFilterState(name, !filterState)}
      size="small"
      sx={{
        ...sx
      }}
      variant='outlined'
    />
  );
}

export default FilterButton;
