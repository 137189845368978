import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import Section from './Section';
import RequestStatusIndicator from './notification/RequestStatusIndicator';
import Notice from './notification/Notice';
import ExamSessionService from '../services/ExamSessionService';
import ExamSessionTable from "./table/ExamSessionTable";
import EditSessionSelectorButtons from './form/EditSessionSelectorButtons';
import { MSG_404 } from '../constants/login';

const styles = {
  requestStatusIndicatorContainer: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    zIndex: 2,
    transform: 'translate(-50%, 0)',
  },
  spacedSection: {
    marginTop: 4,
    //position: 'relative', // can't be set to relative without forcing the bulk button down the page
  },
  sectionRoot: {
    position: 'relative',
  },
};

class SupervisorSessions extends React.Component {

  state = {
    examSessionList: undefined,
    hasSlotUpdated: false,
    isRequestPending: true,
    hasRequestErrored: false,
    hasDataProcessingErrored: false,
    errorMessage: "",
    selectedExamSlots: [],
  }

  setHasSlotUpdated = (hasSlotUpdated) => {
    this.setState({
      hasSlotUpdated: hasSlotUpdated
    });
  };

  setSelectedExamSlots = (_contextId, examSessions) => {
    this.setState({ selectedExamSlots: examSessions });
  };

  refreshSlots = () => {
    if (this.state.hasSlotUpdated) {
      this.loadExamSessionData();
    }
  };

  controller = new AbortController();

  componentDidMount() {
    this.loadExamSessionData();
  }

  componentDidUpdate(prevProps) {
    !isEqual(prevProps, this.props) && this.loadExamSessionData();
  }

  loadExamSessionData = async () => {
    const { selectedDate } = this.props;
    const { selectedSupervisorId } = this.props;
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: "",
      hasSlotUpdated: false,
      selectedExamSlots: []
    });
    try {
      const examSessions = await ExamSessionService.getSupervisorSessions(selectedDate, selectedSupervisorId, this.controller.signal);
      this.setState({
        examSessionList: examSessions,
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            examSessionList: [],
            isRequestPending: false,
            hasRequestErrored: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  render() {
    const { examSessionList, isRequestPending, hasRequestErrored, errorMessage, selectedExamSlots } = this.state;
    const { canEdit, capabilityContextAccess } = this.props;
    const displayData = !isRequestPending && !hasRequestErrored && examSessionList.length > 0;
    const bulkUpdateButton =
      <EditSessionSelectorButtons
        selectedExamSlots={selectedExamSlots}
        setHasSlotUpdated={this.setHasSlotUpdated}
        refreshSlots={this.refreshSlots}
      />
    const permissions = {
      canEdit: canEdit,
      canManageExam: false,
    };

    return (
      <Section sx={styles.sectionRoot}>
        <Box sx={styles.requestStatusIndicatorContainer}>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </Box>
        {!displayData && !isRequestPending &&
          <Notice noticeType="notice">There are no supervisor sessions that match the search criteria</Notice>
        }
        {displayData &&
          <>
            {selectedExamSlots.length > 0 && bulkUpdateButton}
            <ExamSessionTable
              contextId={examSessionList[0].context.id}
              examSessionList={examSessionList}
              displayJoinButton={false}
              displayActionButton={true}
              setHasSlotUpdated={this.setHasSlotUpdated}
              setSelectedExamSlots={this.setSelectedExamSlots}
              refreshSlots={this.refreshSlots}
              permissions={permissions}
              forSupervisorSessions={true}
              capabilityContextAccess={capabilityContextAccess}
            />
          </>
        }
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
      </Section>
    )
  }
}

SupervisorSessions.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  capabilityContextAccess: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  selectedSupervisorId: PropTypes.string.isRequired,
};

export default SupervisorSessions;
