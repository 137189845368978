import { groupBy, isEmpty } from 'lodash';
import { parseISO } from 'date-fns/parseISO';
import { format as dateFnsFormat } from 'date-fns/format';

export const groupByDateThenContext = (sessions) => {
  if (isEmpty(sessions)) {
    return;
  }
  const groupedByDate = groupBy(sessions, (session ) => {
    return session.examStartDateTime ? dateFnsFormat(parseISO(session.examStartDateTime), 'yyyy-MM-dd') : 'Unknown';
  });
  const finalGroup = {};

  Object.entries(groupedByDate).forEach((entry) => {
    finalGroup[entry[0]] = groupBy(entry[1], 'context.name');
  });
  return finalGroup;
}
