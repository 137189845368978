import { clone, isEmpty, sortBy, without } from 'lodash';
import { Box, Button, Chip } from '@mui/material';
import { USER_FIELDS } from '../../../constants/users';
import { buttonLinkStyle } from '../../../config/theme';

const styles = {
  active: {
    color: 'success.main',
  },
  inactive: {
    color: 'warning.main',
  },
};

export const getUserTableColumns = (tableData, canEdit, canViewAAA) => {
  if (isEmpty(tableData)) { return; }
  return (
    without(Object.keys(USER_FIELDS).map((heading) => {
      if(heading === 'id'){
        return {
          name: heading,
          label: USER_FIELDS[heading].display,
          options: { display: 'excluded', filter: false, download: true }}
      }
      if(heading === 'isDisabled') {
        return  {
          name: heading,
          label: USER_FIELDS[heading].display,
          options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <Box sx={tableData[dataIndex].isDisabled === 'Active' ? styles.active : styles.inactive}>
                  {tableData[dataIndex].isDisabled}
                </Box>
              );
            },
          }
        }
      }
      if(heading === 'isAAA' || heading === 'shiftStaff') {
        if(heading === 'isAAA' && !canViewAAA) return undefined;
        return  {
          name: heading,
          label: USER_FIELDS[heading].display,
          options: {
            customBodyRenderLite: (dataIndex) => {
              if(heading === 'isAAA' && tableData[dataIndex].isAAA) {
                return (<Chip label="AAA" size="small"/>);
              }
              if(heading === 'shiftStaff') {
                return tableData[dataIndex].shiftStaff ? 'Yes' : 'No';
              }
            },
            customFilterListOptions: {
              render: v => {
                return `${USER_FIELDS[heading].display}: ${v ? 'Yes' : 'No'}`
              }
            },
            filterOptions:  {
              renderValue: v => {return v ? 'Yes' : 'No'},
            },
          },
        }
      }
      if(heading === 'action') {
        if(!canEdit) return undefined;
        return  {
          name: heading,
          label: USER_FIELDS[heading].display,
          options: {filter: false, download: false}
        }
      }
      return {name: heading, label: USER_FIELDS[heading].display}
    }), undefined)
  )
};

export const processUserData = (userList, canEdit, handleEditClick) => {
  if(isEmpty(userList)) { return; }
  return (
    sortBy(userList.map((user) => {
      let userDisplayObj = clone(user);
      // if isDisabled = true then not active
      userDisplayObj['isDisabled'] = user['isDisabled'] === false ? 'Active' : 'Inactive';
      if(canEdit) {
        userDisplayObj['action'] =
          <Button
            aria-label={`Edit record for ${user['fullName']}`}
            onClick={(e) => handleEditClick(e, user)}
            sx={buttonLinkStyle}
          >
            Edit
          </Button>
      }
      return userDisplayObj;
    }), ['fullName'])
  );
};