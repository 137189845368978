import getStepsOfType from './getStepsOfType';
import mapSortedOnboardStepsAndProgress from './mapSortedOnboardStepsAndProgress';
import { ONBOARD_ACTION_TYPES } from '../../../constants/joinSession';

export const getProgressIdsByStepActionType = (flowAssignment, type) => {
  return getStepsOfType(flowAssignment, type).map(flowStep => flowStep.progress.id);
};

export const getIdentityProgressIdsFromPreMappedData = (flowAssignmentDetails) => {
  return {
    idCaptureIds: getProgressIdsByStepActionType(flowAssignmentDetails, ONBOARD_ACTION_TYPES.ID_VERIFICATION_ID.mapper),
    faceCaptureIds: getProgressIdsByStepActionType(flowAssignmentDetails, ONBOARD_ACTION_TYPES.ID_VERIFICATION_FACE.mapper),
  };
};

const getIdentityProgressIds = (flowAssignment, flow) => {
  const flowAssignmentDetails = mapSortedOnboardStepsAndProgress(flow?.onboardFlowSteps, flowAssignment?.onboardSlotProgress);
  return getIdentityProgressIdsFromPreMappedData(flowAssignmentDetails);
};


export default getIdentityProgressIds;