import React, { createContext, useReducer } from 'react';
import { has, assign, isEmpty, some, merge } from 'lodash';
import GatewayService from '../../services/GatewayService';
import { ACTIONS, NOTICE_TYPES } from '../../constants/monitorSessions';
import { SCREEN_SHARE_TYPES } from '../../constants/mediaStates';

const initialState = {
  activeShift: undefined,
  activeConnection: undefined,
  examSessions: {},
  deviceInfo: undefined,
  avCheckStatus: undefined,
  pinnedSessions: [],
  authorisedMaterials: {},
  onboardIdentity: {},
};
const monitoringContext = createContext(initialState);
const { Provider } = monitoringContext;

/**
 * Helper function to initialise any logical fields inside a new exam details
 * object, beyond what the Core API provides.
 * @param {object} session An object containing the exam details returned from Core API
 * @param {object} origSession An object containing the exam details to be replaced
 * @returns {object} Modified session object with additional fields added
 */
const initSessionForState = (session, origSession) => {
  let initialisedSession = {...origSession};
  assign(initialisedSession, session);
  // not using data from the api here as it doesn't refresh on restart
  initialisedSession['screenShareType'] = initialisedSession?.screenShareType || SCREEN_SHARE_TYPES.NONE.mapper;
  if (!has(initialisedSession, 'connectionDetails')) {
    assign(initialisedSession, {
      connectionDetails: {
        studentOnline: undefined,
        connectionErrors: undefined,
      }
    });
  }
  if (!has(initialisedSession, 'chat')) {
    assign(initialisedSession, {
      chat: {
        messages: [],
        unreadMessages: 0,
      }
    });
  }
  // always ensure the default studentMedia properties are present if they're not set
  assign(initialisedSession, {
    studentMedia: merge(GatewayService.defaultUserProperties(), initialisedSession?.studentMedia),
  });
  if (!has(initialisedSession, 'meetingStateProperties')) {
    assign(initialisedSession, {
      meetingStateProperties: {
        peers: {},
        videoOverride: {
          peer: null,
          stream: null,
          ready: false,
        },
      }
    });
  }
  return initialisedSession;
};

const MonitoringProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case ACTIONS.SET_ACTIVE_SHIFT:
        return {...state, activeShift: action.value}
      case ACTIONS.SET_ACTIVE_CONNECTION:
        return {...state, activeConnection: action.value};
      case ACTIONS.UPDATE_DEVICES:
        const { devices, status } = action.value;
        return {...state, deviceInfo: devices, avCheckStatus: status};
      case ACTIONS.SET_SERVICES:
        return {...state, [action.value.examSlot]: action.value.services};
      case ACTIONS.PIN_SESSIONS:
        return {...state, pinnedSessions: action.value};
      case ACTIONS.ADD_EXAM_SESSION:
        return {...state,
          examSessions: {...state.examSessions,
            [action.value.id]: initSessionForState(action.value, state.examSessions[action.value.id])
          }
        };
      case ACTIONS.REMOVE_EXAM_SESSION:
        // Warning: Be careful with modifications around this feature (especially the services) because
        //          they can actually be updated outside of the normal dispatch method (via direct calls
        //          to member functions). Eventually we need to look at a more immutable state approach
        //          that doesn't allow this.
        if (has(state.examSessions, action.value.id)) {

          // First, start exiting any services that might be active for the removed slot id
          if (has(state, action.value.id)) {
            for (const serviceName in state[action.value.id]) {
              switch (serviceName) {
                case 'audioService':
                  state[action.value.id][serviceName].exitAudio()
                    .catch((err) => console.warn(`Error on exiting audio service for slot ${action.value.id}: `, err));
                  break;
                case 'videoService':
                  state[action.value.id][serviceName].unregisterDeviceHandlers('all');
                  state[action.value.id][serviceName].close('video');
                  state[action.value.id][serviceName].close('screenshare');
                  break;
                case 'gatewayService':
                default:
                  state[action.value.id][serviceName].unregisterHandler('all');
                  state[action.value.id][serviceName].close();
              }
            }
          }

          // Now determine the new state value
          let updatedState = {...state};

          // Delete the services reference
          delete updatedState[action.value.id];

          // Remove the exam details key from the sessions object
          updatedState.examSessions = {...state.examSessions};
          delete updatedState.examSessions[action.value.id];

          // If the currently active student is being removed, unset the active connection
          // flag back to the default.
          if (state.activeConnection === action.value.id) {
            updatedState.activeConnection = undefined;
          }

          // Return the new state
          return updatedState;
        }
        return state;
      case ACTIONS.UPDATE_EXAM_SESSION:
        if (has(state.examSessions, action.value.id)) {
          let updatedSession = {...state.examSessions[action.value.id]};
          merge(updatedSession, action.value);
          return {...state,
            examSessions: {...state.examSessions,
              [action.value.id]: updatedSession
            }
          };
        }
        return state;
      case ACTIONS.SET_EXAM_SESSIONS:
        let stateChanges = {};
        stateChanges.examSessions = Object.fromEntries(action.value.map(session => {
          return [session.id, initSessionForState(session, state.examSessions[session.id])];
        }));
        if (!has(stateChanges.examSessions, state.activeConnection)) {
          // If the currently active student is being removed, unset the active connection
          // flag back to the default.
          stateChanges.activeConnection = undefined;
        }
        return {...state, ...stateChanges};
      case ACTIONS.ADD_NOTICE:
        const { slotId, notice } = action.value;
        if (has(state.examSessions, slotId)) {
          if(has(state.examSessions[slotId], 'notices')) {
            // There can be multiple notices of type flag (and only 1 per flag type). Only one of all other types of notice.
            if(notice.type !== NOTICE_TYPES.FLAG && some(state.examSessions[slotId].notices, ['type', notice.type])) {
              return state;
            }
            if(notice.type === NOTICE_TYPES.FLAG && some(state.examSessions[slotId].notices.flag, ['id', notice.flag.id])) {
              return state;
            }
          }
          const existingNoticesRef = state.examSessions[slotId].notices;
          let updatedNotices = isEmpty(existingNoticesRef) ? [] : [...existingNoticesRef];
          updatedNotices.push(notice);
          return {...state,
            examSessions: {...state.examSessions,
              [slotId]: {...state.examSessions[slotId],
                notices: updatedNotices,
              },
            }
          };
        }
        return state;
      case ACTIONS.CLEAR_NOTICES:
        // Clear the notices for a given exam slot before a set time
        const { id: examSlotId, types } = action.value;
        if (has(state.examSessions, examSlotId + '.notices')) {
          let updatedNotices = state.examSessions[examSlotId].notices.filter(n => {
            // Only notices remaining will be those where the type doesn't match
            // what was cleared
            return !isEmpty(types) && !types.includes(n.type);
          });
          return {...state,
            examSessions: {...state.examSessions,
              [examSlotId]: {...state.examSessions[examSlotId],
                notices: updatedNotices
              },
            }
          };
        }
        return state;
      case ACTIONS.ADD_CHAT_MESSAGE:
        // Add a new chat message received from the gateway
        const { id: messageSlotId, value: newMessage } = action;
        if (has(state.examSessions, messageSlotId)) {
          let updatedChatStorage = {
            messages: [],
            unreadMessages: 0,
          }
          if (has(state.examSessions[messageSlotId], 'chat')) {
            updatedChatStorage.messages.push(...state.examSessions[messageSlotId].chat.messages);
            updatedChatStorage.unreadMessages = state.examSessions[messageSlotId].chat.unreadMessages;
          }

          updatedChatStorage.messages.push(newMessage);
          if (true || !newMessage.read) {
            // For now, always assume messages coming from the gateway are unread. This needs to be
            // enhanced when the read flag is actually reflective of whether the supervisor has already
            // read the message.
            updatedChatStorage.unreadMessages += 1;
          }
          return {...state,
            examSessions: {...state.examSessions,
              [messageSlotId]: {...state.examSessions[messageSlotId],
                chat: updatedChatStorage,
              }
            }
          };
        }
        return state;
      case ACTIONS.ADD_AUTH_MATERIALS:
        const { id, lookupType, value } = action;
        return { ...state, authorisedMaterials:
                  { ...state.authorisedMaterials,
                    [lookupType]: {
                      ...state.authorisedMaterials?.[lookupType],
                      [id]: value
                    }
                  }
                };
      case ACTIONS.SET_ONBOARD_IDENTITY:
        return { ...state, onboardIdentity: { ...state.onboardIdentity, [action.value?.slotId]: action.value?.onboardDetails }};

      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const MonitoringConsumer = monitoringContext.Consumer;

export { monitoringContext, MonitoringProvider, MonitoringConsumer };
