import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, MenuItem, Snackbar } from '@mui/material';
import { isEmpty } from 'lodash';
import { authContext } from '../../authContext';
import { CanThey } from '../Can';
import ActionMenu from './ActionMenu';
import FormPopup from '../popup/FormPopup';
import WarningPopup from '../popup/WarningPopup';
import ExamSessionMisconductForm from './ExamSessionMisconductForm';
import ExamSessionResetMisconductForm from './ExamSessionResetMisconductForm';
import ProcessRecordingRequestForm from './ProcessRecordingRequestForm';
import { EXAM_SESSION_CAPABILITIES as CAPABILITIES } from '../../constants/examSessions';
import Routing from '../../utils/Routing';

function ExamSessionReviewActionMenu(props) {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [editMisconductPopupOpen, setEditMisconductPopupOpen] = useState(false);
  const [resetExamMisconductPopupOpen, setResetExamMisconductPopOpen] = useState(false);
  const [processRecordingPopupOpen, setProcessRecordingPopupOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const { capabilityContextAccess } = useContext(authContext);

  const { examSession, processRecordingFlag, hasProcessRecordingFlag, setHasSlotUpdated, updateSlot, updateSlotFlagCount } = props;
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  }

  const canPerformWithCapabilityInContext = (contextId, capability) => {
    if (isEmpty(contextId)) {
      return false;
    }
    return CanThey(capabilityContextAccess, true, capability, { id: contextId });
  }

  const handleReviewClick = (_e) => {
    navigate(Routing.path(
      Routing.REVIEW_SESSION,
      examSession.id
    ));
  };

  const handleMenuItemClickWithExam = (func) => {
    handleCloseMenu();
    func(examSession);
  }

  const editMisconduct = () => {
    if (!examSession) {
      return undefined;
    }
    setEditMisconductPopupOpen(true);
  }

  const closeMisconductPopup = () => {
    setEditMisconductPopupOpen(false);
  }

  const openResetExamMisconductPopup = () => {
    if (!examSession) {
      return undefined;
    }
    setResetExamMisconductPopOpen(true);
  }

  const closeResetExamMisconductPopup = (requestSuccessful) => {
    if (requestSuccessful) {
      setSuccessSnackbarOpen(true);
    }
    setResetExamMisconductPopOpen(false);
  }

  const openProcessRecordingPopup = () => {
    setProcessRecordingPopupOpen(true);
  }

  const closeProcessRecordingPopup = (requestSuccessful) => {
    if (requestSuccessful) {
      setSuccessSnackbarOpen(true);
    }
    setProcessRecordingPopupOpen(false);
  }

  const closeSuccessSnackbar = () => {
    setSuccessSnackbarOpen(false);
  }

  const canManageIntegrityInContext = canPerformWithCapabilityInContext(examSession.context.id, CAPABILITIES.manageIntegrity);
  const canReviewExam = CanThey(capabilityContextAccess, false, CAPABILITIES.reviewSession);

  return (
    <>
      <ActionMenu
        iconId={`exam-session-action-menu-toggle-${examSession.id}`}
        menuId={`exam-session-action-menu-${examSession.id}`}
        ariaLabel={`Actions for ${examSession?.context?.name}: ${examSession.student?.fullName} ${examSession.student?.externalId}`}
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
      >
        <MenuItem
          id={`review-exam-session-${examSession.id}`}
          key={`review-exam-session-${examSession.id}`}
          onClick={handleReviewClick}>
          Review
        </MenuItem>
        {canManageIntegrityInContext &&
          <MenuItem
            id={`edit-exam-session-misconduct-${examSession.id}`}
            key={`edit-exam-session-misconduct-${examSession.id}`}
            onClick={() => handleMenuItemClickWithExam(editMisconduct)}>
            Edit expiry date
          </MenuItem>
        }
        {canManageIntegrityInContext && examSession.integrityReview &&
          <MenuItem
            id={`reset-exam-session-misconduct-${examSession.id}`}
            key={`reset-exam-session-misconduct-${examSession.id}`}
            onClick={() => handleMenuItemClickWithExam(openResetExamMisconductPopup)}
          >Reset record</MenuItem>
        }
        {canReviewExam && !examSession.recordingsAvailable && !hasProcessRecordingFlag && processRecordingFlag !== undefined &&
          <MenuItem
            id={`request-exam-session-recording-processing-${examSession.id}`}
            key={`request-exam-session-recording-processing-${examSession.id}`}
            onClick={() => handleMenuItemClickWithExam(openProcessRecordingPopup)}
          >Process recording</MenuItem>
        }
      </ActionMenu>
      <FormPopup
        title="Edit record expiry date"
        open={editMisconductPopupOpen}
        onClose={closeMisconductPopup}
        stretch={true}
        maxWidth="sm"
        content={<ExamSessionMisconductForm
          examSession={examSession}
          setHasSlotUpdated={setHasSlotUpdated}
          updateSlot={updateSlot} />}
      />
      <WarningPopup
        open={resetExamMisconductPopupOpen}
        title="Are you sure?"
        onClose={closeResetExamMisconductPopup}
        content={<ExamSessionResetMisconductForm
          examSessionId={examSession.id}
          onClose={closeResetExamMisconductPopup}
          setHasSlotUpdated={setHasSlotUpdated}
          updateSlot={updateSlot} />}
      />
      <WarningPopup
        open={processRecordingPopupOpen}
        title="Are you sure?"
        onClose={closeProcessRecordingPopup}
        content={<ProcessRecordingRequestForm
          examSessionId={examSession.id}
          onClose={closeProcessRecordingPopup}
          processRecordingFlag={processRecordingFlag}
          updateSlotFlagCount={updateSlotFlagCount} />}
      />
      <Snackbar 
        open={successSnackbarOpen}
        autoHideDuration={5000}
        onClose={closeSuccessSnackbar}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
        <Alert severity="success" variant="filled" >
          Record saved
        </Alert>
      </Snackbar>
    </>
  );
}

ExamSessionReviewActionMenu.propTypes = {
  examSession: PropTypes.object,
  setHasSlotUpdated: PropTypes.func,
  updateSlot: PropTypes.func,
  updateSlotFlagCount: PropTypes.func,
  processRecordingFlag: PropTypes.object,
  hasProcessRecordingFlag: PropTypes.bool,
}

export default ExamSessionReviewActionMenu;
