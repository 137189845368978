import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import ActionMenu from './ActionMenu';

function AllocationPoolActionMenu(props) {

  const { allocationPool, openEditAllocationPoolPopup } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuClick = () => {
    setMenuAnchorEl(null);
    openEditAllocationPoolPopup(allocationPool);
  }

  return (
    <ActionMenu
      iconId={`allocation-pool-action-menu-toggle-${allocationPool.id}`}
      menuId={`allocation-pool-action-menu-${allocationPool.id}`}
      ariaLabel={`Actions for ${allocationPool?.name}`}
      menuAnchorEl={menuAnchorEl}
      setMenuAnchorEl={setMenuAnchorEl}
    >
      <MenuItem
        id={`edit-allocation-pool-${allocationPool.id}`}
        key={`edit-allocation-pool-${allocationPool.id}`}
        onClick={handleMenuClick}
      >
        Edit pool
      </MenuItem>
    </ActionMenu>
  );
}

AllocationPoolActionMenu.propTypes = {
  allocationPool: PropTypes.object,
  openEditAllocationPoolPopup: PropTypes.func,
}

export default AllocationPoolActionMenu;
