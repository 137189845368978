import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import AppTheme from '../AppTheme';
import EditSessionSelectorButtons from '../form/EditSessionSelectorButtons';
import ExamSessionTable from '../table/ExamSessionTable';
import Notice from '../notification/Notice';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import ScrollableBox from './ScrollableBox';
import ShiftService from '../../services/ShiftService';
import { themeSubTableObject } from '../../config/themeSubTable';
import { MSG_404 } from '../../constants/login';

class ShiftAssignmentsWrapper extends React.Component {
  state = {
    examSlots: undefined,
    selectedExamSlots: [],
    hasSlotUpdated: false,
    isRequestPending: true,
    hasRequestErrored: false,
    errorMessage: '',
  }

  controller = new AbortController();

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  loadData = async () => {
    this.setState({
      examSlots: undefined,
      selectedExamSlots: [],
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: '',
    });
    try {
      const response = await ShiftService.getShiftAssignments(this.props.shiftId, this.controller.signal);
      this.setState({
        examSlots: response,
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            examSlots: [],
            isRequestPending: false,
            hasRequestErrored: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  setHasSlotUpdated = (hasSlotUpdated) => {
    this.setState({
      hasSlotUpdated: hasSlotUpdated
    });
  };

  setSelectedExamSlots = (_contextId, examSessions) => {
    this.setState({ selectedExamSlots: examSessions });
  };

  refreshSlots = () => {
    if (this.state.hasSlotUpdated) {
      this.props.setHasShiftUpdated(true);
    }
  };

  render() {
    const {
      errorMessage,
      examSlots,
      hasRequestErrored,
      isRequestPending,
      selectedExamSlots,
    } = this.state;
    const { canManageAssignments } = this.props;
    const permissions = { canEdit: canManageAssignments };

    return (
      <ScrollableBox>
        <div>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </div>
        {isEmpty(examSlots) && !isRequestPending && !hasRequestErrored &&
          <Notice noticeType="notice">There are no exam sessions assigned for this session</Notice>
        }
        {!isEmpty(examSlots) &&
          <AppTheme themeObjOverride={themeSubTableObject}>
            {selectedExamSlots.length > 0 &&
              <EditSessionSelectorButtons
                selectedExamSlots={selectedExamSlots}
                setHasSlotUpdated={this.setHasSlotUpdated}
                refreshSlots={this.refreshSlots}
              />
            }
            <ExamSessionTable
              permissions={permissions}
              contextId=''
              displayJoinButton={false}
              displayActionButton={true}
              examSessionList={examSlots}
              forSupervisorSessions={true}
              refreshSlots={this.refreshSlots}
              setHasSlotUpdated={this.setHasSlotUpdated}
              setSelectedExamSlots={this.setSelectedExamSlots}
              statusColourOverride={{ cellHeader: '#dadada' }}
            />
          </AppTheme>
        }
      </ScrollableBox>
    )
  }
}

ShiftAssignmentsWrapper.propTypes = {
  canManageAssignments: PropTypes.bool,
  setHasShiftUpdated: PropTypes.func.isRequired,
  shiftId: PropTypes.string.isRequired,
};

export default ShiftAssignmentsWrapper;
