import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { has } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import VideoStream from './VideoStream';
import logToGateway from "../../utils/logToGateway";

function DisplayVideo(props) {

  const { state } = useContext(monitoringContext);
  const { 
    sessionId, 
    studentMedia, 
    videoType, 
    showWaitText, 
    remoteStream, 
    connectionDetails
  } = props;
  const deviceInfo = useRef(state.deviceInfo);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    deviceInfo.current = state.deviceInfo
  }, [state.deviceInfo]);

  useEffect(() => {
    const mediaType = videoType === 'desktop' ? 'screenshare' : 'video';
    if (connectionDetails?.connectionSetUpComplete
      && (studentMedia?.[mediaType]?.ready || (videoType !== 'webcam' && videoType !== 'desktop'))
    ) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
      if (!connectionDetails?.connectionSetUpComplete) {
        console.debug(`[DisplayVideo]: current session does not have connection details for ${videoType} stream`)
      } else {
        console.debug(`[DisplayVideo]: current session does not have student media for ${videoType} stream`);
      }
    }
  }, [
    connectionDetails, 
    studentMedia,
    videoType,
  ]);

  const getVideoDisplay = () => {
    const userId = has(connectionDetails, 'connectionProps.userId') ?
      connectionDetails.connectionProps.userId : 'no_user_id';

    if (!showVideo && showWaitText) {
      return (
        <Box flexGrow={1}>
          <Typography variant="h2" component="h3">
            <strong>Waiting for student {videoType}</strong>
          </Typography>
        </Box>
      );
    }

    if (showVideo) {
      switch (videoType) {
        case 'webcam':
          return (
            <VideoStream
              streamService={state[sessionId].videoService}
              streamId={studentMedia?.video?.stream}
              isLocal={false}
              manageStream={false}
              onVideoError={(err) => {
                logToGateway(
                  state[sessionId].gatewayService,
                  `assisted_supervisor_${videoType}_view`,
                  {error: err, source: videoType, stream: studentMedia?.video?.stream},
                  userId,
                  sessionId
                );
                console.error("[DisplayVideo]: onVideoError callback from student webcam VideoStream", err)
              }}
            />
          );
        case 'desktop':
          return (
            <VideoStream
              slotId={sessionId}
              streamService={state[sessionId].videoService}
              streamId={studentMedia?.screenshare?.stream}
              isLocal={false}
              manageStream={false}
              onVideoError={(err) => {
                logToGateway(
                  state[sessionId].gatewayService,
                  `assisted_supervisor_${videoType}_view`,
                  {error: err, source: videoType, stream: studentMedia?.screenshare?.stream},
                  userId,
                  sessionId
                );
                console.error("[DisplayVideo]: onVideoError callback from student screenshare VideoStream", err)
              }}
            />
          );
        case 'supervisor': 
          return (
            <VideoStream
              streamService={state[sessionId].videoService}
              streamId={userId}
              isLocal={true}
              onVideoStarted={() => {
                state[sessionId].gatewayService
                && state[sessionId].gatewayService.shareMedia('webcam', userId)
              }}
              onVideoStopped={() => {
                state[sessionId].gatewayService
                && state[sessionId].gatewayService.unshareMedia('webcam', userId)
              }}
              onVideoError={(err) => {
                logToGateway(
                  state[sessionId].gatewayService,
                  'assisted_supervisor_webcam_share',
                  {error: err, source: 'selfVideo', stream: userId},
                  userId,
                  sessionId
                );
                console.error("[DisplayVideo]: onVideoError callback from supervisor webcam VideoStream", err)
              }}
              videoConstraints={{
                video: {deviceId: deviceInfo.current.videoinput},
                audio: false,
              }}
            />
          );
        case 'peer':
          return (
            <VideoStream
              streamService={state[sessionId].videoService}
              streamId={remoteStream}
              isLocal={false}
              onVideoError={(err) => {
                logToGateway(
                  state[sessionId].gatewayService,
                  'assisted_supervisor_webcam_peer',
                  {error: err, source: 'peerVideo', stream: remoteStream},
                  userId,
                  sessionId
                );
                console.error("[DisplayVideo]: onVideoError callback from peer webcam VideoStream", err)
              }}
            />
          );
        default:
          return null;
      }
    } 
  };

  return (
    <>
      {getVideoDisplay()}
    </>
  );
}

DisplayVideo.propTypes = {
  sessionId: PropTypes.string.isRequired,
  connectionDetails: PropTypes.object.isRequired,
  studentMedia: PropTypes.object,
  videoType: PropTypes.string.isRequired,
  remoteStream: PropTypes.string,
  showWaitText: PropTypes.bool,
};

export default DisplayVideo;
