import React from 'react';

const VerificationFailed = (props) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>icon_personal information</title>
    <g id="VerificationFailed" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="VerificationFailed-Artboard" transform="translate(-632.000000, -191.000000)" fill="#3C4043" fillRule="nonzero">
            <g id="VerificationFailed-icon" transform="translate(632.000000, 191.000000)">
                <rect id="VerificationFailed-Rectangle" opacity="0" x="0" y="0" width="15.7773919" height="15.7773919"></rect>
                <path d="M13.8841049,1.50260876 L1.89328703,1.50260876 C0.847653477,1.50260876 0,2.36035335 0,3.41843492 L0,12.358957 C0,13.4170386 0.847653477,14.2747832 1.89328703,14.2747832 L13.8841049,14.2747832 C14.9297385,14.2747832 15.7773919,13.4170386 15.7773919,12.358957 L15.7773919,3.41843492 C15.7773919,2.36035335 14.9297385,1.50260876 13.8841049,1.50260876 Z M2.80837576,13.4765223 C2.92167732,12.15638 4.01377009,11.1431004 5.32329204,11.1431004 C6.63281398,11.1431004 7.72490675,12.15638 7.83820831,13.4765223 L2.80837576,13.4765223 Z M14.9885223,12.358957 C14.9885223,12.9761713 14.4940578,13.4765223 13.8841049,13.4765223 L8.62707791,13.4765223 C8.51914086,11.7102965 7.07207289,10.3337552 5.32329204,10.3337552 C3.57451119,10.3337552 2.12744322,11.7102965 2.01950617,13.4765223 L1.89328703,13.4765223 C1.60037732,13.4765223 1.31946442,13.3587791 1.11234597,13.149195 C0.905227528,12.9396108 0.788869597,12.6553537 0.788869597,12.358957 L0.788869597,3.41843492 C0.788869597,2.80122067 1.28333412,2.30086966 1.89328703,2.30086966 L13.8841049,2.30086966 C14.4940578,2.30086966 14.9885223,2.80122067 14.9885223,3.41843492 L14.9885223,12.358957 Z" id="VerificationFailed-Shape"></path>
                <path d="M5.15844037,3.75652189 C3.55431427,3.75652189 2.25391313,5.02390537 2.25391313,6.58730213 C2.25391313,8.15069889 3.55431427,9.41808237 5.15844037,9.41808237 C6.76256646,9.41808237 8.0629676,8.15069889 8.0629676,6.58730213 C8.0629676,5.02390537 6.76256646,3.75652189 5.15844037,3.75652189 L5.15844037,3.75652189 Z M5.15844037,8.65300663 C3.98786186,8.65300663 3.03892049,7.72815922 3.03892049,6.58730213 C3.03892049,5.44644504 3.98786186,4.52159763 5.15844037,4.52159763 C6.32901887,4.52159763 7.27796024,5.44644504 7.27796024,6.58730213 C7.27623095,7.72746039 6.32830183,8.65132125 5.15844037,8.65300663 L5.15844037,8.65300663 Z M13.1278351,6.5536388 L10.1071268,6.5536388 C9.89035297,6.5536388 9.71462309,6.72490684 9.71462309,6.93617667 C9.71462309,7.1474465 9.89035297,7.31871454 10.1071268,7.31871454 L13.1309751,7.31871454 C13.3477489,7.31871454 13.5234788,7.1474465 13.5234788,6.93617667 C13.5234788,6.72490684 13.3477489,6.5536388 13.1309751,6.5536388 L13.1278351,6.5536388 Z M13.1278351,9.00188117 L10.1071268,9.00188117 C9.89035297,9.00188117 9.71462309,9.17314921 9.71462309,9.38441904 C9.71462309,9.59568887 9.89035297,9.76695691 10.1071268,9.76695691 L13.1309751,9.76695691 C13.3477489,9.76695691 13.5234788,9.59568887 13.5234788,9.38441904 C13.5234788,9.17314921 13.3477489,9.00188117 13.1309751,9.00188117 L13.1278351,9.00188117 Z" id="VerificationFailed-Shape2"></path>
            </g>
        </g>
    </g>
  </svg>
);

VerificationFailed.defaultProps = {
  width: "16px",
  height: "16px",
}

export default VerificationFailed;
