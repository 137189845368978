import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';

/**
 * Simple container for an in-line icon with text content
 * @param {Object} props Additional style props and child components to pass through
 * @returns 
 */
function IconContainer(props) {
  if (props?.icon === undefined) {
    return props?.children;
  }

  return (
    <Stack direction="row" alignItems="center" gap={1} mb={2} sx={props.sx}>
      {props.icon}
      <Box>
        {props.children}
      </Box>
    </Stack>
  );
}

IconContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.node,
  sx: PropTypes.object,
}

export default IconContainer;
