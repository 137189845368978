import { Tooltip } from "@mui/material";
import { capitalise } from "../utils/capitalise";

export const ALLOWED_USER_TYPES = ['ONBOARDER', 'SUPERVISOR'];

export const ALLOCATION_POOLS_DISPLAY = {
  name: {
    display: 'Name',
    options: {
      filter: false,
    },
  },
  description: {
    display: 'Description',
    options: {
      filter: false,
      customHeadLabelRender: (value) => {
        return (
          <Tooltip title={'Please note, the description is displayed on the supervisor dashboard page'} placement='top'>
            <div>
              {value.label}
            </div>
          </Tooltip>
        )
      },
    },
  },
  availableSlotsAny: {
    display: 'Any slots available',
    options: {
      filter: false,
      customHeadLabelRender: (value) => {
        return (
          <Tooltip title={'Controls the maximum number of students allowed in the onboarding stage at once'} placement='top'>
            <div>
              {value.label}
            </div>
          </Tooltip>
        )
      },
    },
  },
  availableSlotsUnlocked: {
    display: 'Unlocked slots available',
    options: {
      filter: false,
    },
  },
  allowedUserTypes: {
    display: 'Allowed user types',
    options: {
      filter: true,
      customFilterListOptions: {
        render: v => `Allowed user type: ${capitalise(v)}`,
      },
      filterOptions: {
        names: ALLOWED_USER_TYPES,
        renderValue: v => `${capitalise(v)}`,
        logic(userTypes, filters) {
          const allowedUserTypes = userTypes.split(',');
          return !filters.some(ut => allowedUserTypes.includes(ut));
        },
      },
    },
  },
  actions: {
    display: 'Actions',
    options: {
      filter: false,
      download: false,
      sort: false,
    },
  },
};
