import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ExpandMore } from '@mui/icons-material';
import { flatten, groupBy, sortBy, toUpper } from 'lodash';
import { authContext } from '../../authContext';
import { CanThey } from '../Can';
import EditSessionSelectorButtons from '../form/EditSessionSelectorButtons';
import ExamDetailsPopup from '../popup/ExamDetailsPopup';
import ExamSessionParticipantStatusLegend from '../content/ExamSessionParticipantStatusLegend';
import ExamSessionTable from '../table/ExamSessionTable';
import {
  EXAM_SESSION_CAPABILITIES as CAPABILITIES
} from '../../constants/examSessions';

class ExamSessionTableContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedExamSlots: [], // this has context and associated slots so it can easily be updated from multi tables
      expand: true
    }
  }

  handleExpansionClick = () => {
    this.setState({ expand: !this.state.expand });
  };

  groupList = (examSessionList, groupByField) => {
    const sortedList = sortBy(examSessionList, [
      'examStartStandard',
      (examSession) => { return toUpper(examSession.context.name) },
      'context.id'
    ])
    return groupByField !== 'none'
      ? groupBy(sortedList, (examSession) => {
        return examSession.context.id;
      })
      : { ungroupedSessions: sortedList };
  };

  setSelectedExamSlots = (contextId, examSessions) => {
    const slotsToAdd = {}
    slotsToAdd[contextId] = examSessions;
    this.setState({ selectedExamSlots: { ...this.state.selectedExamSlots, ...slotsToAdd } });
  };

  getTableDetails = (capabilityContextAccess, displayActionButton, groupByField, firstExamSessionInTheList) => {
    let tableDetails = {};
    switch (groupByField) {
      case 'none':
        tableDetails = {
          additionalFields: { unit: { display: 'Unit', }, },
          displayName: 'All matching sessions (not grouped)',
          showExamInfo: false,
          permissions: {
            canEdit: displayActionButton && CanThey(capabilityContextAccess, false, CAPABILITIES.manageAssignments),
            canManageExam: CanThey(capabilityContextAccess, false, CAPABILITIES.manageExam),
            canViewIntegrity: CanThey(capabilityContextAccess, false, CAPABILITIES.manageIntegrity) || CanThey(capabilityContextAccess, false, CAPABILITIES.viewIntegrity),
          }
        }
        break;
      case 'context':
      default:
        tableDetails = {
          displayName: firstExamSessionInTheList.context.name,
          showExamInfo: true,
          permissions: {
            canEdit: displayActionButton && CanThey(capabilityContextAccess, true, CAPABILITIES.manageAssignments, { id: firstExamSessionInTheList.context.id }),
            canManageExam: CanThey(capabilityContextAccess, true, CAPABILITIES.manageExam, { id: firstExamSessionInTheList.context.id }),
            canViewIntegrity: CanThey(capabilityContextAccess, true, CAPABILITIES.manageIntegrity, { id: firstExamSessionInTheList.context.id })
              || CanThey(capabilityContextAccess, true, CAPABILITIES.viewIntegrity, { id: firstExamSessionInTheList.context.id }),
          }
        }
        break;
    }
    return tableDetails;
  }

  render() {
    const {
      examSessionList,
      displayJoinButton,
      displayActionButton,
      filters,
      handleRefreshByUser,
      setHasSlotUpdated,
      refreshSlots,
      isSupervisor
    } = this.props;
    const { selectedExamSlots } = this.state;
    const { capabilityContextAccess } = this.context;
    const groupedSessionList = this.groupList(examSessionList, filters?.groupBy);
    const slotsToEdit = flatten(Object.keys(selectedExamSlots).map(context => selectedExamSlots[context]));

    const bulkUpdateButton =
      <EditSessionSelectorButtons
        selectedExamSlots={slotsToEdit}
        setHasSlotUpdated={setHasSlotUpdated}
        refreshSlots={refreshSlots}
      />

    return (
      <>
        {slotsToEdit.length > 0 && bulkUpdateButton}
        {Object.keys(groupedSessionList).map((headingKey) => {
          const firstExamSessionInTheList = groupedSessionList[headingKey][0];
          const tableDetails = this.getTableDetails(capabilityContextAccess, displayActionButton, filters?.groupBy, firstExamSessionInTheList)

          return (
            <Box key={headingKey} mb={0.5}> {/* accordion needs to be wrapped or elements outside the accordion move inside it */}
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ flexDirection: 'row' }}>
                  <Typography variant="body1">{tableDetails.displayName}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                  <Box display="flex" justifyContent="space-between">
                    {tableDetails.showExamInfo &&
                      <ExamDetailsPopup
                        examContextName={tableDetails.displayName}
                        examSession={firstExamSessionInTheList}
                        useFullButton={true}
                      />
                    }
                    <ExamSessionParticipantStatusLegend />
                  </Box>
                  <ExamSessionTable
                    additionalFields={tableDetails.additionalFields}
                    contextId={firstExamSessionInTheList.context.id}
                    examSessionList={groupedSessionList[headingKey]}
                    displayJoinButton={displayJoinButton}
                    displayActionButton={displayActionButton}
                    setHasSlotUpdated={setHasSlotUpdated}
                    setSelectedExamSlots={this.setSelectedExamSlots}
                    refreshSlots={refreshSlots}
                    isSupervisor={isSupervisor}
                    permissions={tableDetails.permissions}
                    handleRefreshByUser={handleRefreshByUser}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </>
    )
  }

}

ExamSessionTableContainer.propTypes = {
  classes: PropTypes.object,
  examSessionList: PropTypes.array.isRequired,
  displayJoinButton: PropTypes.bool.isRequired,
  displayActionButton: PropTypes.bool.isRequired,
  filters: PropTypes.object,
  isSupervisor: PropTypes.bool,
  setHasSlotUpdated: PropTypes.func,
  refreshSlots: PropTypes.func,
  handleRefreshByUser: PropTypes.func
};

export default ExamSessionTableContainer;
ExamSessionTableContainer.contextType = authContext;
