import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Notice from '../notification/Notice';
import ExamSessionService from '../../services/ExamSessionService';
import { EXAM_SESSION_STATES as states } from '../../constants/examSessions';
import { buttonLinkStyle } from '../../config/theme';

class OpenExamGateButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasRequestErrored: false,
      errorMessage: '',
    }
    this.controller = new AbortController();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  updateExamGateStatus = async (slotId) => {
    const { examGateStatus, setExamStatus } = this.props;
    const stateToUpdate = (examGateStatus === states.pending) ? states.canStart : states.pending;
    try {
      await ExamSessionService.updateExamSessionState(slotId, { 'state': stateToUpdate }, this.controller.signal);
      this.setState({
        hasRequestErrored: false,
        errorMessage: '',
      });
      setExamStatus(stateToUpdate);
    } catch (error) {
      if (!error.aborted) {
        this.setState({
          hasRequestErrored: true,
          errorMessage: 'Error: unable to update exam gate',
        });
      }
    }
  };

  render() {
    const { slotId, examGateStatus } = this.props;
    const { hasRequestErrored, errorMessage } = this.state;
    const buttonLabel = examGateStatus === states.pending ? 'Open exam gate' : 'Close exam gate';
    return (
      <>
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        <Button
          aria-label={buttonLabel}
          onClick={() => this.updateExamGateStatus(slotId)}
          sx={buttonLinkStyle}
        >
          {buttonLabel}
        </Button>
      </>
    )
  }
}

OpenExamGateButton.propTypes = {
  slotId: PropTypes.string.isRequired,
  examGateStatus: PropTypes.string.isRequired,
  onExamUnlocked: PropTypes.func,
};

export default OpenExamGateButton;
