import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';

export default class AllocationPoolService {

  static async getAllocationPools(abortSignal) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ALLOCATION_POOLS);
    }

    const response = await NetworkService.get('/allocation-pools', abortSignal);
    return await response.json();
  }

  static async patchAllocationPools(allocationPoolId, allocationPool) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ALLOCATION_POOLS[0]);
    }

    return await NetworkService.patch(`/allocation-pool/${allocationPoolId}`, allocationPool);
  }

  static async overrideAllocationPoolAssignments(examSlotId, requestBody, abortSignal) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest(MOCK.ALLOCATION_POOLS);
    }

    const response = await NetworkService.post(`/examslot/${examSlotId}/pool-assignments-override`,
      requestBody, 'application/json', abortSignal);
    return response.json();
  }

  static async deleteAllocationPoolAssignments(examSlotId, abortSignal) {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
      return mockRequest('deleted');
    }

    return await NetworkService.delete(`/examslot/${examSlotId}/pool-assignments`, abortSignal);
  }

 }
