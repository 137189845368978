import { isEmpty } from 'lodash';

/**
 * Retrieves the current step that the student is up to. 
 * IMPORTANT: This assumes that the completion status is reset for steps that must be recomplete whenever the student reloads the page.
 * @param {Array} onboardFlowAssignmentDetails Mapped, ordered by position, flow assignment details list produced by {@link mapSortedOnboardStepsAndProgress}
 * @returns {Array} The step that is currently active for the provided flow assignmen details.
 */
export const getActiveStepFromMappedAssignmentDetails = (onboardFlowAssignmentDetails) => {
    return onboardFlowAssignmentDetails.find(flowAssignmentDetails => !flowAssignmentDetails.progress.complete && isEmpty(flowAssignmentDetails.progress.skippedBy));
}