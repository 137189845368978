const EXAM_SESSION_CHATS = [
  {
    "id": "f140959d-583a-407d-961d-e98cbf7136cb",
    "examSlotId": "5d0fe647-5bdb-4520-9be5-ad753c3ea854",
    "sender": {
      "id": "5b78749a-675a-412c-831d-6725dfe5be32",
      "accountId": "hello-world",
      "fullName": "Test Student",
      "userName": "test.student@monash.edu",
      "legalName": "Test Student",
      "externalId": "test.student@monash.edu",
      "isDisabled": false,
      "shiftStaff": true
    },
    "senderUserType": "STUDENT",
    "createdAt": "2024-06-11T17:41:28.474703+10:00",
    "chatMessage": "Hello world"
  },
  {
    "id": "78dec24c-a003-49dd-bd13-77ec165c536e",
    "examSlotId": "5d0fe647-5bdb-4520-9be5-ad753c3ea854",
    "sender": {
      "id": "4817c366-f6f1-40c7-912d-1c1afcaf309c",
      "accountId": "hello-world",
      "fullName": "Test Supervisor",
      "userName": "test.supervisor@monash.edu",
      "legalName": "Test Supervisor",
      "externalId": "test.supervisor@monash.edu",
      "isDisabled": false,
      "shiftStaff": true
    },
    "senderUserType": "SUPERVISOR",
    "createdAt": "2024-06-11T17:42:17.555083+10:00",
    "chatMessage": "Hello there"
  },
]

export default EXAM_SESSION_CHATS;