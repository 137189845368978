const DETECTION_CONFIG = [{
  id: '7a98f29b-5cf9-460a-b2b0-6c7ae803b6b2',
  type: 'COMPARE_FACES',
  inferenceSettings: [{
    inferenceType: 'CompareFaces',
    qualityFilter: 'HIGH',
    similarityThreshold: 50,
    comparisonType: '',
    comparisonImageLocation: '',
    confidenceThreshold: 50,
  }],
  'maxRetries': 3,
}];

export default DETECTION_CONFIG;