import { CONFERENCE_WARNINGS } from '../../../constants/errors';
import { FEATURE_TOGGLES } from '../../../constants/featureToggles';
import { SHARED_MEDIA_STATES, SHARED_MEDIA_TYPES } from '../../../constants/mediaStates';
import { formatErrorForDisplay } from '../../../utils/formatErrorForDisplay';

const onVideoError = (addError, getState, getUserId, logToGateway, updateMediaState, updateSharedMediaWarnings) => {
  /**
   * Callback function for whenever the video management component encounters an error sharing media.
   * @param {string} mediaType Can be either 'webcam' or 'desktop'
   * @param {string} err Error message encountered
   * @param {boolean} disconnected True if the stream was disconnected entirely
   * @param {string} source The source code for what type of error this represents
   */
  return (mediaType, err, disconnected, source, features) => {
    const state = getState();
    let streamId = mediaType === SHARED_MEDIA_TYPES.DESKTOP ? mediaType : getUserId();

    if (disconnected) {
      updateMediaState(mediaType, SHARED_MEDIA_STATES.DISCONNECTED);
      state?.gatewayService && state.gatewayService.unshareMedia(mediaType, streamId);
      addError(mediaType, formatErrorForDisplay(err, mediaType, source));

      // Clear any warnings from the previous connection attempt, if we were disconnected
      updateSharedMediaWarnings({ ...state?.sharedMediaWarnings, [mediaType]: undefined });
    } else {
      // Special check for partial screenshare - if a warning message is configured, then
      // pass this through to the button components
      if (mediaType === SHARED_MEDIA_TYPES.DESKTOP && source === CONFERENCE_WARNINGS.PARTIAL_SCREEN
        && features?.[FEATURE_TOGGLES.WARN_PARTIAL_SCREENSHARE]) {
        updateSharedMediaWarnings({ ...state.sharedMediaWarnings, [mediaType]: "Please check that you have shared your entire desktop" })
      }
    }
    logToGateway(`student_${mediaType}_share`, { error: err, source: 'registeredDeviceHandler', stream: streamId });
    console.error(`Error while streaming outgoing ${mediaType} video.`, { err });
  }
};

export default onVideoError;