import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { parseISO } from 'date-fns/parseISO';
import { format as dateFnsFormat } from 'date-fns/format';
import { isValid as dateFnsIsValid } from 'date-fns/isValid';
import { has, isEmpty } from 'lodash';
import { PhotoCamera, Videocam } from '@mui/icons-material';
import VideoTime from './VideoTime';
import ImageViewer from '../popup/ImageViewer';
import { default as FLAGS_DISPLAY } from '../../constants/flags';
import FlagService from '../../services/FlagService';
import NetworkRequestError from '../../utils/NetworkRequestError';

function ReviewFlags(props) {
  const [flagFrameViewer, setFlagFrameViewer] = useState({
    flagId: null,
    imageSource: null,
    imageAlt: '',
    imageText: null,
    title: '',
    open: false,
  });
  const [frameErrors, setFrameErrors] = useState({});
  const downloadedFrames = {};
  const { flags, withImageLinks, meetings } = props;

  const styles = {
    mockButtonContainer: {
      fontSize: '0.8em',
      minWidth: { md: '98px' },
      padding: { sx: '3px 4px 3px 0px', md: '3px 4px 3px 16px' },
      '& svg': {
        marginRight: 1,
      },
    },
    chipButton: {
      fontSize: '0.8em',
      fontWeight: 'normal',
      textTransform: 'none',
      padding: '1px 4px',
    },
    downloadFrameButtonError: {
      borderColor: 'error.main',
      color: 'error.main',
    },
    iconFlexStart: {
      '&.MuiListItemIcon-alignItemsFlexStart': {
        marginTop: 1,
      },
    },
    noFlagWarning: {
      paddingTop: 2,
      paddingBottom: 2,
    },
    gridItem: {
      mb: { xs: 1, md: 0 },
    },
    timestampContainer: {
      justifyContent: { md: 'flex-end', xs: 'flex-start' }
    },
  }

  const controller = new AbortController();

  const handleFlagFrameButtonClick = (evt, flag) => {
    const { examSlotId } = props;
    evt.preventDefault();
    // If we are re-opening the same image again, no need to send another GET request
    if (has(downloadedFrames, flag.id)) {
      showFlagFrame(flag);
      return;
    }

    // Otherwise, try to request the frame jpg data from the core API
    FlagService.getFlagFrame(examSlotId, flag.id, controller.signal, 'image/jpeg')
      .then(response => response.blob())
      .then((blobData) => {
        downloadedFrames[flag.id] = URL.createObjectURL(blobData);
        if (has(frameErrors, flag.id)) {
          // Clear the previous error
          let updatedFrameErrors = { ...frameErrors };
          delete updatedFrameErrors[flag.id];
          setFlagFrameViewer({ ...flagFrameViewer, frameErrors: updatedFrameErrors }, () => {
            showFlagFrame(flag);
          });
        } else {
          showFlagFrame(flag);
        }

      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(`Unable to download flag frame image: ${err instanceof NetworkRequestError ? err.getDetailedMessage() : err.message}`);
          setFrameErrors({
            ...frameErrors,
            [flag.id]: 'Unable to download frame',
          });
        }
      });
  }

  const showFlagFrame = (flag) => {
    const displayDate = flag.timestamp ? dateFnsFormat(parseISO(flag.timestamp), 'HH:mm:ss') : 'unknown';
    setFlagFrameViewer({
      open: true,
      title: `Flagged image for '${flag.flag.name}' taken at ${displayDate}`,
      flagId: flag.id,
      imageSource: downloadedFrames[flag.id],
      imageAlt: 'flagged image',
      imageText: flag.note ? flag.note : null,
    });
  }

  const hideFlagFrame = () => {
    setFlagFrameViewer({
      ...flagFrameViewer,
      open: false,
    });
  }

  const IconWrapper = ({ icon, ...otherProps }) => {
    return React.cloneElement(icon, { ...otherProps });
  }

  return (
    <>
      {withImageLinks && flagFrameViewer.imageSource &&
        <ImageViewer
          imageSource={flagFrameViewer.imageSource}
          imageAlt={flagFrameViewer.imageAlt}
          imageText={flagFrameViewer.imageText}
          title={flagFrameViewer.title}
          isOpen={flagFrameViewer.open}
          onClose={hideFlagFrame}
        />
      }
      {isEmpty(flags) &&
        <Typography variant="body1" sx={styles.noFlagWarning}>No flags raised.</Typography>
      }
      {!isEmpty(flags) &&
        <List>
          {flags.map((flag, idx) => {
            const flagTime = flag.timestamp && parseISO(flag.timestamp);
            const frameButtonClass = withImageLinks && flag.frameUrl && has(frameErrors, flag.id) ? { ...styles.chipButton, ...styles.downloadFrameButtonError } : styles.chipButton;
            const flagTimeValid = dateFnsIsValid(flagTime);

            return (
              <ListItem key={flag.id} divider={idx + 1 !== flags.length} alignItems={isEmpty(flag.note) ? 'center' : 'flex-start'}>
                <ListItemIcon sx={styles.iconFlexStart}>
                  {has(FLAGS_DISPLAY[flag.flag.eventType], 'icon') &&
                    <Box>
                      <IconWrapper
                        icon={FLAGS_DISPLAY[flag.flag.eventType].icon}
                        width="1.5rem"
                        height="1.5rem"
                        sx={{ fontSize: '1.5rem' }}
                      />
                    </Box>
                  }
                </ListItemIcon>
                <ListItemText>
                  <Grid container>
                    <Grid item xs={12} sm={2} sx={styles.gridItem} >
                      {flagTimeValid &&
                        <Box pr={2} display="flex" alignItems="center">{dateFnsFormat(flagTime, 'HH:mm')}</Box>}
                    </Grid>
                    <Grid item xs={12} sm={4} sx={styles.gridItem} >
                      <Typography variant="body1" component="h2">{flag.flag.name}</Typography>
                      {flag.note && <Typography variant="body2">{flag.note}</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={3} sx={styles.gridItem} >
                      <Typography variant="body1" component="h2">Flagged by</Typography>
                      <Typography variant="body2" >{flag.flaggingUser?.fullName || 'Unknown'}</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={3} sx={styles.timestampContainer} >
                      <Box flexDirection="column">
                        {flagTimeValid && meetings &&
                          <Box display="flex" alignItems="center" sx={styles.mockButtonContainer}>
                            <Videocam fontSize="small" />
                            <VideoTime meetings={meetings} timeStamp={flagTime} />
                          </Box>}
                        {withImageLinks && flag.frameUrl &&
                          <Box pl={{ sx: 0, md: 2 }}>
                            <Button
                              sx={frameButtonClass}
                              startIcon={<PhotoCamera />}
                              color="primary"
                              variant="outlined"
                              size="small"
                              aria-label={`View frame for flag '${flag.flag.name}' at ${dateFnsFormat(flagTime, 'HH:mm')}`}
                              onClick={evt => handleFlagFrameButtonClick(evt, flag)}>
                              View frame
                            </Button>
                          </Box>}
                      </Box>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      }
    </>
  );
}

ReviewFlags.propTypes = {
  flags: PropTypes.array,
  meetings: PropTypes.array,
  examSlotId: PropTypes.string,
  withImageLinks: PropTypes.bool,
}

export default ReviewFlags;
