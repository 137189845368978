import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import { authContext } from '../../authContext';
import { CanThey } from '../Can';
import ActionMenu from './ActionMenu';
import ContextExpiryForm from './ContextExpiryForm';
import ExamSessionForm from './ExamSessionForm';
import FormPopup from '../popup/FormPopup';
import { EXAM_SESSION_CAPABILITIES as CAPABILITIES } from '../../constants/examSessions';

function ExamSessionSummaryActionMenu(props) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [addExamPopupOpen, setAddExamPopupOpen] = useState(false);
  const [editContextPopupOpen, setEditContextPopupOpen] = useState(false);
  const { capabilityContextAccess } = useContext(authContext);

  const { exam, setHasSlotUpdated, refreshSlots } = props;

  const addExamFormTitle = exam?.name ? `Add session in ${exam.name}` : 'Add session';

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  }

  const canPerformWithCapabilityInContext = (contextId, capability) => {
    if (isEmpty(contextId)) {
      return false;
    }
    return CanThey(capabilityContextAccess, true, capability, { id: contextId });
  }

  const handleMenuItemClickWithExam = (func) => {
    handleCloseMenu();
    func(exam);
  }

  const addSlot = () => {
    if (!exam) {
      return undefined;
    }
    openAddSlotPopup();
  };

  const openAddSlotPopup = () => {
    setAddExamPopupOpen(true);
  };

  const closeAddSlotPopup = () => {
    setAddExamPopupOpen(false);
    refreshSlots();
  };

  const editContext = () => {
    if (!exam) {
      return undefined;
    }
    setEditContextPopupOpen(true);
  }

  const closeContextPopup = () => {
    setEditContextPopupOpen(false);
    refreshSlots();
  }

  return (
    <>
      {(canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageExam) || canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageIntegrity)) &&
        <>
          <ActionMenu
            iconId={`exam-action-menu-toggle-${exam.id}`}
            menuId={`exam-action-menu-${exam.id}`}
            ariaLabel={`Actions for ${exam.name} (${exam.numberOfExams} sessions)`}
            menuAnchorEl={menuAnchorEl}
            setMenuAnchorEl={setMenuAnchorEl}
          >
            {canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageExam) &&
              <MenuItem
                id={`add-exam-session-${exam.id}`}
                key={`add-exam-session-${exam.id}`}
                onClick={() => handleMenuItemClickWithExam(addSlot)}>
                Add session
              </MenuItem>}
            {canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageIntegrity) &&
              <MenuItem
                id={`edit-context-${exam.id}`}
                key={`edit-context-${exam.id}`}
                onClick={() => handleMenuItemClickWithExam(editContext)}>
                Edit expiry date
              </MenuItem>
            }
          </ActionMenu>
          <FormPopup
            title={addExamFormTitle}
            open={addExamPopupOpen}
            onClose={closeAddSlotPopup}
            content={<ExamSessionForm
              referenceExam={exam}
              setHasSlotUpdated={setHasSlotUpdated}
            />}
          />
          <FormPopup
            title="Edit record expiry date"
            open={editContextPopupOpen}
            onClose={closeContextPopup}
            stretch={true}
            maxWidth="sm"
            content={<ContextExpiryForm
              selectedContext={exam}
              setHasSlotUpdated={setHasSlotUpdated}
            />}
          />
        </>
      }
    </>
  );
}

ExamSessionSummaryActionMenu.propTypes = {
  exam: PropTypes.object,
  setHasSlotUpdated: PropTypes.func.isRequired,
  refreshSlots: PropTypes.func.isRequired,
}

export default ExamSessionSummaryActionMenu;
