import React, { useContext } from 'react';
import { joinContext } from '../context/JoinContext';
import ExamInProgressText from '../content/ExamInProgressText';
import ExamSubmittedText from '../content/ExamSubmittedText';
import GateOpenFailedText from '../content/GateOpenFailedText';
import { EXAM_SESSION_STATES } from '../../constants/examSessions';

function ExamStartStep(_props) {
  const { state } = useContext(joinContext);

  return (
    <>
      {(!state.examState || state.examState === EXAM_SESSION_STATES.pending) &&
        <GateOpenFailedText />
      }
      {(state.examState === EXAM_SESSION_STATES.canStart || state.examState === EXAM_SESSION_STATES.started) &&
        <ExamInProgressText />
      }
      {state.examState === EXAM_SESSION_STATES.submitted &&
        <ExamSubmittedText />
      }
    </>
  )
}

export default ExamStartStep;