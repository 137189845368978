import { isEmpty } from "lodash";

/**
 * Render the supervisor's display name to be shown to other supervisors via any
 * popup alerts. If no user name is available, a generic string is shown instead.
 * @param {string} userName The users full name to display, if available
 */
const displaySupervisorPeerName = (userName) => {
  return !isEmpty(userName) ? userName + " (supervisor)" : 'Another authorised supervisor';
};

export default displaySupervisorPeerName;