import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

function AudioConnecting(_props) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
      <Typography variant="body1" gutterBottom>Please wait a moment while your audio connection is tested.</Typography>
      <CircularProgress color="secondary"/>
    </Box>
  )
}

export default AudioConnecting;