export const CAPABILITIES = {
  completeStep: 'COMPLETE_STEP',
  manageAllocationPools: 'MANAGE_ALLOCATION_POOLS',
  manageAssignments: 'MANAGE_ASSIGNMENTS',
  manageExam: 'MANAGE_EXAM',
  manageIntegrity: 'MANAGE_INTEGRITY',
  onboardExam: 'ONBOARD_EXAM',
  reviewSession: 'REVIEW_EXAM',
  sitExam: 'SIT_EXAM',
  skipByAdmin: 'SKIP_BY_ADMIN',
  skipByAny: 'SKIP_BY_ANY',
  skipByOnboarder: 'SKIP_BY_ONBOARDER',
  superviseExam: 'SUPERVISE_EXAM',
  viewIntegrity: 'VIEW_INTEGRITY',
  viewExam: 'VIEW_EXAM',
}
