import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import { AuthConsumer } from '../../authContext';
import { CONTACT_NUMBERS_MAPPER } from '../../constants/contentOverrides';

const ITSupportNumbers = (props) => {
  const { contentOverrides } = props;

  const numbers = isEmpty(contentOverrides?.[CONTACT_NUMBERS_MAPPER])
    ? { Australia: '+61 3 9905 4300', Malaysia: '+60 3 5514 5600' }
    : contentOverrides[CONTACT_NUMBERS_MAPPER];

  return (
    <>
      {Object.entries(numbers).map(([name, number]) => {
        return (
          <Box component="span" display="block" key={name}>
            {name}: {number}
          </Box>
        )
      })}
    </>
    );
}

const ContactNumbers = (props) => {
  return (
    <AuthConsumer>
      {({ contentOverrides }) => (
        <>
          {props.itSupport &&
            <Box component="section" display="flex" alignItems="flex-start" sx={{ py: 1, ...props.sxProps }}>
              <HeadsetMicOutlinedIcon sx={{ mr: 2, fontSize: '1.6rem' }}/>
              <div>
                <Typography variant="h3" component="h2" sx={{ mb: 0 }}>IT support</Typography>
                <Typography variant="h6" component="p">
                  <ITSupportNumbers contentOverrides={contentOverrides}/>
                </Typography>
              </div>
            </Box>
          }
        </>
      )}
    </AuthConsumer>
  );
};

ContactNumbers.propTypes = {
  itSupport: PropTypes.bool,
};

ContactNumbers.defaultProps = {
  itSupport: true,
};

export default (ContactNumbers);
