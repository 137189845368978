import { cloneDeep, has } from 'lodash';
import { ACTIONS } from '../../../constants/joinSession';
import { MEDIA_STREAM_CONNECTION_STATES, VIDEO_MEDIA_EVENTS } from '../../../constants/mediaStates';

const onRemoteParticipantVideoChange = (dispatch, getConnectionState, getParticipantMedia) => {

  const processStartRequest = (updatedParticipant, updatedParticipantMedia, userId) => {
    if (!has(updatedParticipant, 'video.stream')) {
      console.error(`Unable to update participant media for ${userId}, participant stream details missing`);
      return;
    }
    // We always take the latest supervisor to start streaming to us
    updatedParticipantMedia.video.stream = updatedParticipant.video.stream;
    if (getConnectionState() === MEDIA_STREAM_CONNECTION_STATES.CONNECTED) {
      updatedParticipantMedia.video.streaming = true;
    }
    updatedParticipantMedia.video.activeUser = userId;
    updatedParticipantMedia.video.participants[userId] = {
      ...updatedParticipant.video,
      userName: updatedParticipant.userName,
      userType: updatedParticipant.userType,
    };

    return updatedParticipantMedia
  };

  const processStopRequest = (updatedParticipantMedia, userId) => {
    if (userId === updatedParticipantMedia.video.activeUser) {
      // Only if the supervisor who is currently streaming to us stops their video do we
      // unset the display. If another supervisor stops, we don't really care.
      updatedParticipantMedia.video.streaming = false;
      updatedParticipantMedia.video.stream = null;
      updatedParticipantMedia.video.activeUser = null;

      // Check if there is another active supervisor video (shouldn't really happen)
      const fallbackUsers = Object.keys(updatedParticipantMedia.video.participants)
        .filter(activeUser => activeUser !== userId);

      if (fallbackUsers.length) {
        // Go back to other active supervisor stream
        updatedParticipantMedia.video.streaming = true;
        updatedParticipantMedia.video.stream = updatedParticipantMedia.video.participants[fallbackUsers[0]].stream;
        updatedParticipantMedia.video.activeUser = fallbackUsers[0];
      }
    }

    // Remove the participant who stopped streaming from the video participant list
    if (updatedParticipantMedia.video.participants.hasOwnProperty(userId)) {
      delete updatedParticipantMedia.video.participants[userId];
    }
  }

  /**
   * Handle an event received that indicates a remote participant in our meeting had a change to
   * their video streams, either starting a new stream or stopping an existing one.
   * @param {string} type Type of change, either 'start' or 'stop'.
   * @param {string} userId The eVig user ID who is sharing their video stream.
   * @param {object} updatedParticipant The updated user and media data for this change from the gateway.
   */
  return (type, userId, updatedParticipant) => {
    let updatedParticipantMedia = cloneDeep(getParticipantMedia());
    console.info(`Received video ${type} request for ${userId}`);
    if (type === VIDEO_MEDIA_EVENTS.START) {
      processStartRequest(updatedParticipant, updatedParticipantMedia, userId);
    } else {
      // This is a video stop message
      processStopRequest(updatedParticipantMedia, userId);
    }
    dispatch({
      type: ACTIONS.SET_PARTICIPANT_MEDIA,
      value: updatedParticipantMedia,
    });
  }
};

export default onRemoteParticipantVideoChange;