import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, has, isEmpty } from 'lodash';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Notice from '../notification/Notice';
import Section from '../Section';
import UserLookup from '../search/UserLookup';
import ExamSessionService from '../../services/ExamSessionService';
import { getLastActiveOnboarder, getLastActiveSupervisor } from '../../utils/getExamSlotUser';
import { capitalise } from '../../utils/capitalise';
import { fieldsetContainerStyle } from '../../config/theme';
import { USER_TYPES } from '../../constants/users';

class AssignSessionForm extends React.Component {
  state = {
    updated: {
      SUPERVISOR: undefined,
      ONBOARDER: undefined,
    },
    updatedExamSlots: this.props.selectedExamSlots,
    isRequestSuccessful: false,
    isRequestPending: false,
    hasRequestErrored: false,
    errorMessage: '',
    onlyUseOnboarder: false,
  };

  setUpdatedSlots = (response) => {
    const updatedExamSlots = cloneDeep(this.state.updatedExamSlots);
    return updatedExamSlots.map(slot => {
      response.filter(r => r.id === slot.id).forEach(r => {
        if(r.status === 200) {
          slot.updated = {
            SUPERVISOR: this.state.updated.SUPERVISOR,
            ONBOARDER: this.state.updated.ONBOARDER,
          }
          slot.status = 'Success';
        } else {
          slot.status = 'Failed';
        }
      });
      return slot;
    });
  };

  controller = new AbortController();

  setUser = (fieldName, user) => {
    let updateObj = { [fieldName]: user?.[0] };
    if (this.state.onlyUseOnboarder) {
      updateObj[USER_TYPES.SUPERVISOR] = user?.[0];
    }
    this.setState({ updated: { ...this.state.updated, ...updateObj }});
  };

  handleChange = (event) => {
    this.setState({
      updated: {...this.state.updated, SUPERVISOR: this.state.updated.ONBOARDER},
      onlyUseOnboarder: event.target.checked,
    });
  };

  saveSession = async () => {
    const { updated } = this.state;
    if (!updated.ONBOARDER && !updated.SUPERVISOR) {
      return;
    }

    const requestDto = this.props.selectedExamSlots.map((exam) => {
      const reqDto = {'id': exam.id};
      if (updated.SUPERVISOR) {
        reqDto.supervisorUsername = updated.SUPERVISOR.user.userName;
      }

      if (updated.ONBOARDER) {
        reqDto.onboarderUsername = updated.ONBOARDER.user.userName;
      }
      return reqDto;
    });

    try {
      const response = await ExamSessionService.updateSupervisors(requestDto, this.controller.signal);
      const isPartialSuccess = response.some(r => r.status !== 200);
      const errorMessage = response.map(r => {
          if(r.status === 500) {
            return r.errorMessage
          }
          return undefined;
        }).join('');

      this.setState({
        hasRequestErrored: errorMessage === "" ? false : true,
        isRequestSuccessful: !isPartialSuccess,
        isPartialSuccess: isPartialSuccess,
        errorMessage: errorMessage,
        updatedExamSlots: this.setUpdatedSlots(response),
      });
      this.props.setHasSlotUpdated(true);
      if (this.props.setRequestResult) {
        this.props.setRequestResult(isPartialSuccess ? 'partial' : 'success');
      }
    } catch(error) {
      if (this.props.setRequestResult) {
        this.props.setRequestResult('error');
      }
      this.setState({
        hasRequestErrored: true,
        isRequestSuccessful: false,
        errorMessage: error.message,
      });
    }
  };

  changeUserForm = (userType, updatedExamSlots) => {
    if (!has(this.state, `updated.${userType}`)) {
      throw new Error('Unsupported user type');
    }

    const userLabel = userType.toLowerCase();
    const { onlyUseOnboarder } = this.state;
    const updatedUser = this.state.updated[userType];

    return (
      <>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Current {userLabel}</TableCell>
                <TableCell>Updated {userLabel}</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedExamSlots.map((examSession) => {
                let user;
                switch (userType) {
                  case USER_TYPES.ONBOARDER:
                    user = getLastActiveOnboarder(examSession);
                    break;
                  case USER_TYPES.SUPERVISOR:
                    user = getLastActiveSupervisor(examSession);
                    break;
                  default:
                    throw new Error('Unsupported user type');
                }
                return (
                  <TableRow key={'row' + examSession.id}>
                    <TableCell key={'student_exam_id' + examSession.id}>
                      {has(examSession, 'student.fullName') ? examSession.student.fullName : '-'}
                    </TableCell>
                    <TableCell key={userLabel + '_exam_id' + examSession.id}>
                      {has(user, 'fullName') ? user.fullName : '-'}
                    </TableCell>
                    <TableCell key={`updated_${userLabel}_exam_id${examSession.id}`}>
                      {has(examSession, `updated.${userType}.user.fullName`) ? examSession.updated[userType].user.fullName : '-'}
                    </TableCell>
                    <TableCell key={'status' + examSession.id}>
                      {has(examSession, 'status') && !isEmpty(this.state.updated[userType]) ? examSession.status : '-'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box pt={3} pb={3}>
          <UserLookup
            dataProps={{ 'data-name': `update-${userType}-user-input-container` }}
            fieldAriaLabel={`Select ${userLabel} (Enter three or more characters to search for a person)`}
            fieldLabel={`${capitalise(userLabel)} name, username or ID`}
            fieldName={userType}
            inputDataProps={{ 'data-name': `update-${userType}-user-input-box` }}
            isDisabled={userType !== USER_TYPES.ONBOARDER && onlyUseOnboarder}
            multiple={false}
            setUserValue={this.setUser}
            userValue={[updatedUser]}
          />
          <FormHelperText>Enter 3 or more characters to search for a person</FormHelperText>
          {userType === USER_TYPES.ONBOARDER && isEmpty(updatedExamSlots.filter(ses => ses.humanSupervised === false)) &&
            <FormControlLabel
              control={
                <Checkbox
                  fontSize="small"
                  checked={onlyUseOnboarder}
                  onChange={(event) => this.handleChange(event)}
                  name="onlyUseOnboarder"
                  id="onlyUseOnboarder"
                />}
              label="Set onboarder as supervisor"
            />
          }
        </Box>
      </>
    );
  };

  render() {
    const { updatedExamSlots, isRequestSuccessful, isPartialSuccess, hasRequestErrored, errorMessage } = this.state;
    return (
      <Section>
        <FormControl component="fieldset" sx={fieldsetContainerStyle}>
          <Notice noticeType="notice">
            <Typography variant="body1">
              Please ensure the onboarder/supervisor you're assigning has an active account and an appropriate shift in the system.
            </Typography>
          </Notice>
          {isRequestSuccessful &&
            <Notice noticeType="success">The session updated successfully</Notice>
          }
          {isPartialSuccess &&
            <Notice noticeType="warning">Unable to update all slots. Please try again.</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          <Box p={1} pb={3}>
            <Typography variant="h3" color="primary">Change onboarder</Typography>
            {this.changeUserForm(USER_TYPES.ONBOARDER, updatedExamSlots)}
            {isEmpty(updatedExamSlots.filter(ses => ses.humanSupervised === false)) &&
              <>
                <Typography variant="h3" color="primary">Change supervisor</Typography>
                {this.changeUserForm(USER_TYPES.SUPERVISOR, updatedExamSlots)}
              </>
            }
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={this.saveSession}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

AssignSessionForm.propTypes = {
  selectedExamSlots: PropTypes.array.isRequired,
  setHasSlotUpdated:PropTypes.func,
  setRequestResult:PropTypes.func,
};

export default AssignSessionForm;
