import React from 'react';
import { Button } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

function RefreshPageButton(_props) {

  const onRefreshClick = () => {
    window.location.reload();
  };

  return <Button
    variant="outlined"
    startIcon={<RefreshRoundedIcon />}
    onClick={onRefreshClick}
  >Refresh Page</Button>
}

export default RefreshPageButton;
