export const httpErrorMessage = {
  400: 'Sorry, the request was incorrect or corrupted. Please check the submission and try again.',
  401: 'Sorry, there is an error with your session and you were not authorised. Please try logging in again.',
  403: 'Sorry, this information is restricted.',
  404: 'Sorry, we cannot find the information you are looking for.',
  413: 'Sorry, the size of the data exceeded the maximum allowed.',
  415: 'Sorry, the media type is not supported.',
};

export const popupErrorMessages = {
  allocationHandlerDisconnection: 'Due to a connection error, you are required to refresh your eVigilation page'
};

export const STUDENT_ERROR_DISPLAY = {
  default: {
    headerBackgroundColour: 'grey.300',
    contentComponent: 'defaultError',
    headerIcon: 'GenericError',
    headerIconStyle: { fontSize: '7rem', fill: 'secondary.dark' },
    headerText: 'Oops, something went wrong',
    headerTextColour: 'secondary.dark',
    optionalSupportLinkOverrideKey: 'default',
  },
  audio: {
    headerBackgroundColour: 'grey.300',
    contentComponent: 'audioError',
    headerIcon: 'NoMicrophone',
    headerIconStyle: { fontSize: '7rem', fill: 'secondary.dark' },
    headerText: 'Uh oh, it looks like you haven\'t shared your microphone',
    headerTextColour: 'secondary.dark',
    optionalSupportLinkOverrideKey: 'audio',
  },
  webcam: {
    headerBackgroundColour: 'grey.300',
    contentComponent: 'webcamError',
    headerIcon: 'NoWebcam',
    headerIconStyle: { fontSize: '7rem', fill: 'secondary.dark' },
    headerText: 'Uh oh, it looks like you haven\'t shared your webcam',
    headerTextColour: 'secondary.dark',
    optionalSupportLinkOverrideKey: 'webcam',
  }, 
  desktop: {
    headerBackgroundColour: 'grey.300',
    contentComponent: 'screenshareError',
    headerIcon: 'NoScreenshare',
    headerIconStyle: { fontSize: '7rem', fill: 'secondary.dark' },
    headerText: 'Uh oh, it looks like you haven\'t shared your screen',
    headerTextColour: 'secondary.dark',
    optionalSupportLinkOverrideKey: 'screenshare',
  }
};

export const CONFERENCE_ERROR_CATEGORIES = {
  AUDIO: 'audio',
  CONNECT: 'connection',
  DESKTOP: 'desktop',
  WEBCAM: 'webcam',
};

export const CONFERENCE_WARNINGS = {
  PARTIAL_SCREEN: 'partialScreenshare',
};

export const CONFERENCE_ERROR_DISPLAY_ORDER = [
  {type: "connection"},
  {type: "audio"},
  {type: "webcam"},
  {type: "desktop"},
];