import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import AudioCheck from '../audioVisual/AudioCheck';
import AudioConnecting from '../content/AudioConnecting';
import ContactNumbers from '../content/ContactNumbers';
import ScreenShareAdvice from '../content/ScreenShareAdvice';
import VideoCheck from '../audioVisual/VideoCheck';
import { joinContext } from '../context/JoinContext';
import addConferenceError from '../audioVisual/helper/addConferenceError';
import endEchoTest from '../audioVisual/helper/endEchoTest';
import startSharingVideo from '../audioVisual/helper/startSharingVideo';
import updateDevices from '../audioVisual/helper/updateDevices';
import { formatErrorForDisplay } from '../../utils/formatErrorForDisplay';
import { ACTIONS, ONBOARD_ACTION_TYPES } from '../../constants/joinSession';
import { MEDIA_STREAM_CONNECTION_STATES } from '../../constants/mediaStates';
import gatewayConfig from '../../config/gateway';

function MediaConnectStepPopup(props) {
  const { state, dispatch } = useContext(joinContext);
  const { mediaType, onClose, open, stepInfo } = props;

  const unmuteConference = () => {
    if (gatewayConfig.autoMuteOnJoin) {
      state.gatewayService.updateMedia('audio', 'muted', false);
    }
  };

  const setVideoStates = (mediaType, connectionState) => {
    dispatch({ type: ACTIONS.UPDATE_SHARED_MEDIA_STATUS, value:
        { mediaType: mediaType, status: connectionState }
    });
  };

  const handleConfirmAudio = () => {
    const logDetails = {
      gatewayService:  state.gatewayService,
      logType: 'student_audio_transfer',
      userId: state.examSession?.connectionDetails?.connectionProps?.user.id || 'no_user_id',
      slotId: state.examSession?.id,
    };
    const setEndEchoTestError = () => {
      dispatchConferenceError(addConferenceError(
        'audio',
        formatErrorForDisplay('SIP_ECHOTEST_TRANSFER_FAIL', 'audio', 'transferToConference'),
        state.examSession?.connectionDetails?.connectionErrors
      ));
    }
    const finaliseStepCallback = () => {
      unmuteConference();
    };
    endEchoTest(state.audioConnectionState, state.audioService, logDetails, setEndEchoTestError, finaliseStepCallback);
  };
  const handleConfirmVideo = () => {
    startSharingVideo('webcam', state.sharedMediaStatus, setVideoStates);
  };
  const handleConfirmScreen = () => {
    startSharingVideo('desktop', state.sharedMediaStatus, setVideoStates);
  };

  const dispatchConferenceError = useCallback((conferenceErrors) => {
    dispatch({
      type: ACTIONS.UPDATE_EXAM_SESSION,
      value: {
        connectionDetails: {
          ...state.examSession?.connectionDetails,
          connectionErrors: conferenceErrors,
        }
      }
    });
  }, [dispatch, state.examSession?.connectionDetails]);

  const onVideoTroubleshoot = (err) => {
    // put the cam into a connecting state, so that reconnect can occur if they fix the issue
    handleConfirmVideo()
    // If there was an error, fall out to error page
    if (err?.webcam) {
      dispatchConferenceError(addConferenceError(
        'webcam',
        formatErrorForDisplay(err.webcam, 'webcam', 'videoPreviewFailed'),
        state.examSession?.connectionDetails?.connectionErrors
      ));
    }
    onClose();
  }

  const updateDevicesWrapper = useCallback((updatedDevices) => {

    const setDevices = (updatedDevices) => {
      dispatch({ type: ACTIONS.UPDATE_DEVICES, value: {...state.deviceInfo, ...updatedDevices}});
    };

    updateDevices(
      state.deviceInfo,
      updatedDevices,
      state.audioService,
      setDevices,
      (status) => dispatch({ type: ACTIONS.SET_AUDIO_STATE, status }),
      (_errorText, rawError) => {
        dispatchConferenceError(addConferenceError(
          'audio',
          formatErrorForDisplay(rawError, 'audio', 'setInputDevice'),
          state.examSession?.connectionDetails?.connectionErrors
        ));
      }
    );
  }, [dispatch, dispatchConferenceError, state.audioService, state.deviceInfo,
    state.examSession?.connectionDetails?.connectionErrors]);

  useEffect(() => {
    if (mediaType === ONBOARD_ACTION_TYPES.MEDIA_CHECK_AUDIO.mediaTypeName && state.audioConnectionState === null) {
      dispatch({ type: ACTIONS.SET_AUDIO_STATE, value: MEDIA_STREAM_CONNECTION_STATES.CONNECTING});
    }
  }, [dispatch, mediaType, state.audioConnectionState]);

  useEffect(() => {
    if (mediaType === ONBOARD_ACTION_TYPES.MEDIA_CHECK_SCREEN.mediaTypeName && state.screenShareConnectionState === null) {
      dispatch({ type: ACTIONS.SET_SCREEN_SHARE_STATE, value: MEDIA_STREAM_CONNECTION_STATES.CONNECTING});
    }
  }, [dispatch, mediaType, state.screenShareConnectionState]);

  return (
    <Dialog
      disableEscapeKeyDown
      aria-labelledby="screen-check-title"
      open={open}
      maxWidth="sm">
      <DialogTitle id="screen-check-title">{stepInfo?.flowStep?.onboardStep?.displayName || 'Connect media' }</DialogTitle>
      <DialogContent>
        {mediaType === ONBOARD_ACTION_TYPES.MEDIA_CHECK_AUDIO.mediaTypeName && state.audioConnectionState !== null &&
          <>
            {state.audioConnectionState === MEDIA_STREAM_CONNECTION_STATES.CONNECTING
              ? <AudioConnecting/>
              : <AudioCheck
                  devices={state.deviceInfo}
                  handleConfirm={handleConfirmAudio}
                  onConfirmAudio={() => {/* do nothing */}}
                  updateDevices={updateDevicesWrapper}
                />
            }
          </>
        }
        {mediaType === ONBOARD_ACTION_TYPES.MEDIA_CHECK_VIDEO.mediaTypeName &&
          <VideoCheck
            connectionState={state?.sharedMediaStatus?.webcam}
            devices={state.deviceInfo}
            handleConfirm={handleConfirmVideo}
            onClose={onVideoTroubleshoot}
            updateDevices={updateDevicesWrapper}
          />
        }
        {mediaType === ONBOARD_ACTION_TYPES.MEDIA_CHECK_SCREEN.mediaTypeName &&
          <ScreenShareAdvice connectionState={state?.sharedMediaStatus?.desktop} handleConfirm={handleConfirmScreen} />
        }
        <Divider/>
        <Box m={2}>
          <Typography variant="h4" component="h2">Need help?</Typography>
          <ContactNumbers examSupport={false} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

MediaConnectStepPopup.propTypes = {
  mediaType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stepInfo: PropTypes.object,
};

export default (MediaConnectStepPopup);
