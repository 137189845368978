import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import { mapUrlParams } from '../utils/mapParams';

export default class AuthMaterialsService {

    static async getAuthMaterials(searchData, abortSignal) {
        if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
            return mockRequest(MOCK.AUTH_MATERIALS);
        }

        const response = await NetworkService.get(
          `/auth-materials?${mapUrlParams(searchData)}`,
          abortSignal
        );
        return await response.json();
    }

}
